import React from "react";
import { certificatesRepository } from "../../../repository/certificates.repository";
import TileItem from "../TileItem/TileItem";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import { languages } from "../../../dummyData/dummyData";

const CertificateTile = ({ data, skipTeam }) => {
  const { selectableId, course_title, prepared_teams, course_id, profile_image_small, user, prepared_date, isChecked } = data;

  return (
    <TileItem title={course_title} image={profile_image_small} selectableId={selectableId} isChecked={isChecked} buttons={[
      {
        icon: "download",
        onClick: () => certificatesRepository.downloadCertificate(user.username, course_id),
        tooltip: `${languages.EN.labels.download} ${languages.EN.labels.certificate}`,
      },
    ]}>
      <TileItemImgInfo header={languages.EN.labels.user} value={`${user.profile.name} ${user.profile.surname}`} image={user.profile.profile_image_small} />
      <TileItemInfo header={languages.EN.labels.jobPosition} value={user.profile.user_job_position?.name} />
      {!skipTeam && <TileItemInfo header={languages.EN.labels.team} value={prepared_teams} />}
      <TileItemInfo header={languages.EN.labels.obtained} value={prepared_date} />
    </TileItem>
  );
};

export default CertificateTile;
