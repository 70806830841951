import React, { useContext } from "react";
import styles from "./WorkgroupMemberTile.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import TileInformation from "../../atoms/TileInformation/TileInformation";
import Button from "../../atoms/Button/Button";
import userPhoto from "../../../assets/member.png";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { UserContext } from "../../../contexts/UserContext";

const {
  careerPath,
  phoneNumber,
  jobPosition,
  workgroupMember,
} = languages.EN.labels;

const teamStr = languages.EN.labels.team;
const emailStr = languages.EN.labels.email;
const { empty } = languages.EN.placeholders;

const WorkgroupMemberTile = ({ data, id, state }) => {
  const { email, profile } = data.user;
  const { teams } = data;

  const {
    name,
    surname,
    profile_image_medium,
    phone_number,
    user_job_position,
    user_career_path,
    user,
  } = profile;

  const { username } = user;
  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);

  return (
    <div className={styles.workgroupMemberTile}>
      <div className={styles.workgroupMemberTile__box}>
        <div className={styles.workgroupMemberTile__nameContainer}>
          <Text s20 lh32 w800 secondary>
            {name ? name : empty}
          </Text>
          <Text s20 lh32 w800 secondary>
            {surname ? surname : empty}
          </Text>
        </div>
        <img
          className={styles.workgroupMemberTile__img}
          src={profile_image_medium ? profile_image_medium : userPhoto}
          alt=""
        />

        <div className={styles.workgroupMemberTile__information}>
          <Text s12 w600 secondary>
            {teamStr}
          </Text>
          <div className={styles.workgroupMemberTile__information}>
            <TileInformation lineClamp={5} data={teams} />
          </div>
        </div>
        <div className={styles.workgroupMemberTile__information}>
          <Text s12 lh14 w600 secondary>
            {emailStr}
          </Text>
          <TextTruncated lineClamp={1}>
            <Text s12 lh24 dark-2>
              {email ? email : empty}
            </Text>
          </TextTruncated>
        </div>
        <div className={styles.workgroupMemberTile__information}>
          <Text s12 lh14 w600 secondary>
            {jobPosition}
          </Text>
          <Text s12 lh24 dark-2>
            {user_job_position ? user_job_position.name : empty}
          </Text>
        </div>
        <div className={styles.workgroupMemberTile__information}>
          <Text s12 w600 secondary>
            {phoneNumber}
          </Text>
          <Text w400 s12 dark-2>
            {phone_number ? phone_number : empty}
          </Text>
        </div>
        <div className={styles.workgroupMemberTile__information}>
          <Text s12 w600 secondary>
            {careerPath}
          </Text>
          <TextTruncated lineClamp={1}>
            <Text w400 s12 dark-2>
              {user_career_path ? user_career_path.name : empty}
            </Text>
          </TextTruncated>
        </div>
      </div>
      {(state || [2, 3].includes(userContextLevel)) && (
        <div className={styles.buttonsContainer}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() =>
              callPopup({
                type: ACTIONS.DELETE,
                payload: {
                  type: workgroupMember,
                  elementName: name ? `${name} ${surname}` : email,
                  action: () =>
                    workgroupsRepository.unassignFromWorkgroup({ user: username, workgroup: id }),
                },
              })
            }
          />
        </div>
      )}
    </div>
  );
};
export default WorkgroupMemberTile;
