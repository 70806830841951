import useFetch from "../../../hooks/useFetch";
import ProfessionTemplate from "../../templates/ProfessionTemplate/ProfessionTemplate";
import { professionRepository } from "../../../repository/profession.repository";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";

const Profession = ({ match }) => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(professionRepository.getProfession(match.params.username));
    }
  }, [isResponseOk]);

  return data ? (
    <ProfessionTemplate data={data} />
  ) : (
    <Loader />
  );
};

export default Profession;
