import styles from "./Reviews.module.scss";
import Review from "../../molecules/Review/Review";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss";
import { languages } from "../../../dummyData/dummyData";
import SwiperNavigation from "../../molecules/SwiperNavigation/SwiperNavigation";

const Reviews = ({ reviews }) => {
  return (
    <div className={styles.reviews}>
      {reviews.length > 0 ? (
        <>
          <Swiper
            navigation={{
              prevEl: '#reviewsPrev',
              nextEl: '#reviewsNext',
            }}
            spaceBetween={40}
            slidesPerView={'auto'}
            allowTouchMove={true}
            className={styles.reviews__swiper}
          >
            {reviews.map((item, i) => (
              <SwiperSlide key={i}>
                <Review componentProps={item} />
              </SwiperSlide>
            ))}
            <SwiperNavigation id={'reviews'} visible={true} />
          </Swiper>
        </>
      ) :  languages.EN.labels.noData}
    </div>
  );
};

export default Reviews;
