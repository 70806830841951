import { idp_comment_endpoint, idp_delete_endpoint, idp_endpoint, learning_plan_endpoint, } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getIdp = (username, id) => {
  return fetch(idp_endpoint + username + "/" + (id ? "?" + new URLSearchParams({ idp_id: id }) : ""), {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addIdp = (data) => {
  return fetch(idp_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editIdp = (username, data) => {
  return fetch(idp_endpoint + username + "/", {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteIdp = (id) => {
  return fetch(`${idp_delete_endpoint}${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const editLearningPlan = (data) => {
  return fetch(learning_plan_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const changeStatusPlan = (data) => {
  return fetch(idp_comment_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const idpRepository = {
  getIdp,
  addIdp,
  editIdp,
  deleteIdp,
  editLearningPlan,
  changeStatusPlan,
};
