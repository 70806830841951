import styles from "./RoundImage.module.scss";
import React from "react";

const RoundImage = ({ image, size }) => {
  return (
    <div className={styles.roundImage} style={{ ['--image-size']: size + 'px' }}>
      {React.isValidElement(image) ? image : <img src={image} alt="" />}
    </div>
  );
};

export default RoundImage;
