import PieChartCourseRating from "../../PieChartMultipleContainer/PieChartCourseRating";
import PieChartTotalContainer from "../../PieChartTotalContainer/PieChartTotalContainer";
import styles from "./LearningReportCourses.module.scss";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import CourseListTable from "../../Table/LearningReport/Courses/CourseListTable";
import CourseProgressTable from "../../Table/LearningReport/Courses/UserCoursesTable";
import { languages } from "../../../../dummyData/dummyData";

const LearningReportCourses = ({ data }) => {
  const coursesWithRatingCount = data.course_reports.filter(report => report.course_rating > 0).length;
  const totalRating = coursesWithRatingCount > 0 ? data.course_reports.reduce((acc, curr) => acc + curr.course_rating, 0) / coursesWithRatingCount : 0;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.pieChartsBox}>
        <PieChartTotalContainer
          header={languages.EN.labels.coursesOnPlatform}
          config={[
            {
              name: languages.EN.enums.learningAreas[0],
              value: data.num_course_0 + data.num_internal_course_0,
              color: "#B4E7E3",
            },
            {
              name: languages.EN.enums.learningAreas[1],
              value: data.num_course_1 + data.num_internal_course_1,
              color: "#4B8A85",
            },
            {
              name: languages.EN.enums.learningAreas[2],
              value: data.num_course_2 + data.num_internal_course_2,
              color: "#6CEBE1",
            },
          ]}
        />
        <PieChartTotalContainer
          header={languages.EN.labels.totalCoursesHours}
          config={[
            {
              name: languages.EN.enums.learningAreas[0],
              value: Math.round((data.course_duration_0 + data.internal_course_duration_0) / 60 / 60),
              color: "#B4E7E3",
            },
            {
              name: languages.EN.enums.learningAreas[1],
              value: Math.round((data.course_duration_1 + data.internal_course_duration_1) / 60 / 60),
              color: "#4B8A85",
            },
            {
              name: languages.EN.enums.learningAreas[2],
              value: Math.round((data.course_duration_2 + data.internal_course_duration_2) / 60 / 60),
              color: "#6CEBE1",
            },
          ]}
        />
        <PieChartTotalContainer
          header={languages.EN.labels.totalCoursesWatched}
          config={[
            {
              name: languages.EN.enums.learningAreas[0],
              value: Math.round((data.usage_0 + data.internal_usage_0) / 60 / 60),
              color: "#B4E7E3",
            },
            {
              name: languages.EN.enums.learningAreas[1],
              value: Math.round((data.usage_1 + data.internal_usage_1) / 60 / 60),
              color: "#4B8A85",
            },
            {
              name: languages.EN.enums.learningAreas[2],
              value: Math.round((data.usage_2 + data.internal_usage_2) / 60 / 60),
              color: "#6CEBE1",
            },
          ]}
        />
        <PieChartTotalContainer
          header={languages.EN.labels.totalAssignedCourses}
          config={[
            {
              name: languages.EN.labels.done,
              value: data.num_finished,
              color: "#B4E7E3",
            },
            {
              name: languages.EN.labels.inProgress,
              value: data.num_in_progress,
              color: "#4B8A85",
            },
            {
              name: languages.EN.labels.notStarted,
              value: data.num_enrolled,
              color: "#6CEBE1",
            },
          ]}
        />
      </div>
      <PieChartCourseRating
        header={languages.EN.labels.averageCourseRating}
        data={[
          {
            name: languages.EN.labels.overall,
            filledAmount: totalRating,
            emptyAmount: 5 - totalRating,
          },
          {
            name: languages.EN.enums.learningAreas[0],
            filledAmount: data.avg_course_rating_0,
            emptyAmount: 5 - data.avg_course_rating_0,
          },
          {
            name: languages.EN.enums.learningAreas[1],
            filledAmount: data.avg_course_rating_1,
            emptyAmount: 5 - data.avg_course_rating_1,
          },
          {
            name: languages.EN.enums.learningAreas[2],
            filledAmount: data.avg_course_rating_2,
            emptyAmount: 5 - data.avg_course_rating_2,
          },
        ]}
      />
      <TableWrapper
        Table={CourseListTable}
        reports={{ data: data.course_reports }}
        title={languages.EN.labels.courseList}
      />
      <TableWrapper
        Table={CourseProgressTable}
        reports={{ data: data.user_reports }}
        title={languages.EN.labels.courseProgress}
      />
    </div>
  );
};

export default LearningReportCourses;
