import { useEffect, useState } from "react";
import Label from "../Label/Label";
import styles from "./Input.module.scss";

export const inputType = {
  email: "email",
  password: "password",
  text: "text",
  number: "number",
  tel: "tel",
  file: "file",
};
const Input = ({
  variant,
  placeholder,
  searchBar,
  value,
  handleInput,
  name,
  required,
  error,
  disabled,
  onBlur,
  onFocus,
  onKeyPress,
  selectSearch,
  reference,
  onKeyDown,
  borderError,
  noMargin,
  autoComplete,
  darkBorder,
  onWheel,
  autoFocus,
}) => {
  const [isValue, setIsValue] = useState(false);
  useEffect(() => {
    if (value === null || value === "" || value === undefined) {
      setIsValue(false);
    } else {
      setIsValue(true);
    }
  }, [value]);

  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => {
    setIsFocus(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocus(false);
    if (onBlur) {
      onBlur();
    }
  };
  return (
    <Label
      searchBar={searchBar}
      isFocus={isFocus}
      isValue={isValue}
      required={required}
      selectSearch={selectSearch}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
      htmlFor={styles.input}
      noMargin={noMargin}
    >
      <input
        className={`${styles.input} ${
          error || borderError ? styles.input__error : ""
        } ${disabled ? styles.input__disabled : ""}`}
        data-dark-border={darkBorder}
        ref={reference}
        type={inputType[variant]}
        name={name}
        onKeyDown={onKeyDown}
        disabled={disabled}
        value={value}
        onKeyPress={onKeyPress}
        autoComplete={autoComplete}
        onChange={(e) => {
          handleInput && handleInput(e);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onWheel={onWheel && onWheel}
        autoFocus={autoFocus}
      />
    </Label>
  );
};

export default Input;
