const findItemValueByPath = (obj, key, arr) => {
  if (key) {
    let arrForStrings = [];
    if (Array.isArray(key)) {
      let finalOb;
      key.forEach((singleKey) => {
        const singleOb = findItemValueByPath(obj, singleKey, arr);
        arrForStrings.push(singleOb);
        finalOb = arrForStrings.join(" ");
      });
      return finalOb;
    } else {
      let paths = key.split("."),
        current = obj,
        i;

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === null) {
          current = "--none--"; // TODO: for some reason it's displayed in PopupShare
        } else {
          current = current[paths[i]];
        }
      }
      if (Array.isArray(current)) {
        current.forEach((el) => {
          arr && arr.push(el);
        });
      } else {
        arr && arr.push(current);
      }
      return current;
    }
  } else {
    arr && arr.push(undefined);
  }
};

export default findItemValueByPath;
