import React, { useContext, useEffect, useMemo, useState } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import { coursesRepository } from "../../../../../repository/courses.repository";
import Table from "../../Table";
import { TYPE_AUTOCOMPLETE, TYPE_CHECKBOX, TYPE_MULTICOMPLETE, TYPE_NUMBER } from "../../../../molecules/Form/Form";
import useFetch from "../../../../../hooks/useFetch";
import { referencesRepository } from "../../../../../repository/references.repository";
import Html from "../../../../atoms/Html/Html";
import { getReferenceName } from "../../../../../utils/referenceNameHelper";

const popupFields = (referencesItems = [], unitsItems = [], data = {}) => [
  {
    name: "reference_id",
    label: languages.EN.labels.position,
    required: true,
    type: TYPE_NUMBER,
    value: data.reference_id,
  },
  {
    name: "document_id",
    label: languages.EN.labels.reference,
    required: true,
    type: TYPE_AUTOCOMPLETE,
    value: data.document?.id,
    items: referencesItems.map(reference => ({
      id: reference.id,
      label: getReferenceName(reference, true),
      inactive: !reference.active,
    })),
  },
  {
    name: "unit_ids",
    label: languages.EN.labels.units,
    required: true,
    type: TYPE_MULTICOMPLETE,
    value: data.units?.map(unit => unit.id),
    items: unitsItems.map(unit => ({
      id: unit.block_id,
      label: unit.title,
    })),
  },
  [
    {
      name: "useful_doc",
      label: languages.EN.labels.usefulDocument,
      type: TYPE_CHECKBOX,
      value: data.useful_doc ? 1 : 0,
    },
    {
      name: "is_link",
      label: languages.EN.labels.showAsLink,
      type: TYPE_CHECKBOX,
      value: data.is_link ? 1 : 0,
    },
  ],
];

const ReferencesTable = ({ reports }) => {
  const { data, units } = reports;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const [editedReference, setEditedReference] = useState();

  const items = data.sort((a, b) => a.reference_id - b.reference_id).map((item) => ({
    ...item,
    referenceName: getReferenceName(item.document, true),
    referenceNumber: !!item.document.number ? item.document.number : languages.EN.placeholders.empty,
    referenceVersion: !!item.document.version ? item.document.version : languages.EN.placeholders.empty,
  }));
  const assignedReferences = items.map(item => item.document.id);

  const fetchReferencesForAddPopup = useFetch();
  useEffect(() => {
    if (fetchReferencesForAddPopup.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.reference}`,
          fields: popupFields(
            fetchReferencesForAddPopup.data.filter(reference => !assignedReferences.includes(reference.id)),
            units,
            { reference_id: data.length + 1 }
          ),
          postAction: coursesRepository.addUnitReference,
        },
      });
    }
  }, [fetchReferencesForAddPopup.isReady]);

  const fetchReferencesForEditPopup = useFetch();
  useEffect(() => {
    if (fetchReferencesForEditPopup.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.reference}`,
          fields: popupFields(
            fetchReferencesForEditPopup.data.filter(reference => !assignedReferences.includes(reference.id) || reference.id === editedReference.document.id),
            units,
            editedReference,
          ),
          postAction: (formData) => coursesRepository.editUnitReference(editedReference.id, formData),
        },
      });
    }
  }, [fetchReferencesForEditPopup.isReady]);

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.position,
      accessor: "reference_id",
      rowSpan: 2,
      headerStyle: { width: "110px" },
    },
    {
      Header: languages.EN.labels.reference,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.id,
          accessor: "document.aidify_id",
        },
        {
          Header: languages.EN.labels.name,
          accessor: "referenceName",
          Cell: (data) => <Html>{getReferenceName(data.row.original.document)}</Html>,
          cellStyle: { width: "55%" },
        },
      ],
    },
    {
      Header: languages.EN.labels.units,
      accessor: (data) => data.units.map(unit => unit.title),
      Cell: (data) => <ul style={{ paddingLeft: "15px" }}>{data.row.original.units.map((unit, i) => <li key={i}>{unit.title}</li>)}</ul>,
      rowSpan: 2,
      headerStyle: { minWidth: "130px" },
    },
    {
      Header: languages.EN.labels.usefulDocument,
      accessor: "useful_doc",
      Cell: (data) => data.row.original.useful_doc ? "✔️" : languages.EN.placeholders.empty,
      rowSpan: 2,
      headerStyle: { width: "120px" },
    },
    {
      Header: languages.EN.labels.showAsLink,
      accessor: "is_link",
      Cell: (data) => data.row.original.is_link ? "✔️" : languages.EN.placeholders.empty,
      rowSpan: 2,
      headerStyle: { width: "120px" },
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      rowSpan: 2,
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={() => {
              setIsDataActive(false);
              setEditedReference(data.row.original);
              fetchReferencesForEditPopup.fetchData(referencesRepository.getReferences());
              callPopup({ type: ACTIONS.LOADING });
            }}
            tooltip={languages.EN.labels.edit}
          />
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.reference,
                elementName: data.row.original.document.name,
                action: () => coursesRepository.deleteUnitReference(data.row.original.id),
              },
            })}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ], []);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => {
        setIsDataActive(false);
        fetchReferencesForAddPopup.fetchData(referencesRepository.getReferences());
        callPopup({ type: ACTIONS.LOADING });
      },
    },
  ]} columns={columns} data={items} pagination={true} />;
};

export default ReferencesTable
