import styles from "./Text.module.scss";
import { concatClassNames } from "../../../utils";

const Text = ({ children, ...props }) => {
  props = { ...props };

  return <span className={concatClassNames(props, styles)}>{children}</span>;
};

export default Text;
