import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import TileItem from "../TileItem/TileItem";
import TimeBar from "../../molecules/TimeBar/TimeBar";
import { languages } from "../../../dummyData/dummyData";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const CoursesItemTile = ({ data }) => {
  const {
    id,
    selectableId,
    profile_image_small,
    display_name,
    subtitle,
    topic,
    duration,
    instructors,
    visibility,
    visibilityImg,
    user_progress,
    active,
    canEdit,
    canSelect,
    isChecked,
  } = data;

  const { userContextLevel } = useContext(UserContext);
  let history = useHistory();

  return (
    <TileItem
      title={display_name}
      subtitle={subtitle}
      image={profile_image_small}
      inactive={!active}
      bar={userContextLevel === 0 && user_progress !== null ? <TimeBar userProgress={user_progress} duration={duration} /> : undefined}
      buttons={(canEdit ? [
        {
          icon: "edit",
          onClick: () => history.push(generatePath(Routes.courseEdit.conspect.base, { id: id })),
          tooltip: languages.EN.labels.edit,
        },
      ] : []).concat([
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.courseDetails.statistics.base, { id: id })),
          tooltip: languages.EN.labels.details,
        },
      ])}
      id={id}
      selectableId={canSelect ? selectableId : undefined}
      isChecked={isChecked}
    >
      <TileItemInfo header={languages.EN.labels.learningArea} value={languages.EN.enums.learningAreas[topic]} />
      <TileItemImgInfo header={languages.EN.labels.visibility} value={visibility} image={visibilityImg} />
      <TileItemInfo header={languages.EN.labels.duration} value={`${duration} ${languages.EN.labels.minutes}`} />
      {instructors.map((instructor, i) => (
        <TileItemImgInfo key={i} header={languages.EN.labels.instructor} value={instructor.name} image={instructor.img} fullRow={instructors.length === 1}/>
      ))}
    </TileItem>
  );
};

export default CoursesItemTile;
