import styles from "./CourseAssign.module.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import Icons from "../../../assets/icons";
import { languages } from "../../../dummyData/dummyData";

const CourseAssign = ({ name, id }) => {
  const history = useHistory();

  return (
    <div className={styles.courseAssign}>
      {Icons.greenCheckMark}
      <Text s12 lh24 w400 dark-2>
        {name}
      </Text>
      <Button
        variant="iconButton"
        onlyIcon
        icon="dots"
        onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: id }))}
        tooltip={languages.EN.labels.details}
      />
    </div>
  );
};

export default CourseAssign;
