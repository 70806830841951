import { languages } from "../../../../dummyData/dummyData";
import Button from "../../../atoms/Button/Button";
import styles from "../AidifyChatbot.module.scss";
import Text from "../../../atoms/Text/Text";
import { useEffect, useState } from "react";

const TypeSelector = ({ selectedType, state, setState, actions }) => {
  const lastMessageWithWidget = state.messages.findLast(message => message.widget === 'typeSelector');
  const delay = lastMessageWithWidget.delay ?? lastMessageWithWidget.payload?.delay ?? 0;
  const loading = lastMessageWithWidget.loading ?? lastMessageWithWidget.payload?.loading ?? false;

  const [show, setShow] = useState(!delay);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setShow(true), delay + (loading ? 750 : 0));
    }
  }, [delay]);

  return show ? (
    <div className={styles.categories}>
      <div className={styles.types}>
        <Text s14 lh22>{languages.EN.labels.aidify} {languages.EN.labels.courses}:</Text>
        {Object.entries(languages.EN.enums.chatbotCourseTypes).map(([id, label]) => (
          <Button
            key={id}
            variant={selectedType === id ? "primary" : "primaryLight"}
            onClick={() => {
              if (selectedType !== id) {
                setState((state) => ({ ...state, selectedType: id }));
                actions.handleTypeSelect(languages.EN.enums.chatbotCourseTypes[id]);
              }
            }}
          >
            {label}
          </Button>
        ))}
      </div>
      <div className={styles.types}>
        <Text s14 lh22>{languages.EN.labels.references}:</Text>
        {Object.entries(languages.EN.enums.chatbotReferenceTypes).map(([id, label]) => (
          <Button
            key={id}
            variant={selectedType === id ? "primary" : "primaryLight"}
            onClick={() => {
              if (selectedType !== id) {
                setState((state) => ({ ...state, selectedType: id }));
                actions.handleTypeSelect(languages.EN.enums.chatbotReferenceTypes[id]);
              }
            }}
          >
            {label}
          </Button>
        ))}
      </div>
    </div>
  ) : <></>;
}

export default TypeSelector;