import CoursePlayerTemplate from "../../templates/CoursePlayerTemplate/CoursePlayerTemplate";

const CoursePlayer = () => {
  const vimeoId = (new URLSearchParams(window.location.search)).get('vimeoId');

  return vimeoId && (
    <CoursePlayerTemplate
      vimeoId={vimeoId}
    />
  );
};

export default CoursePlayer;
