import styles from "./Agenda.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import SearchInput from "../../atoms/SearchInput/SearchInput";
import React, { useContext, useEffect, useState } from "react";
import AgendaSection from "./Section/AgendaSection";
import { FilterContext } from "../../../contexts/FilterContext";

const filterDefinitions = [
  {
    keyPath: "unit.title",
  }
];

const Agenda = ({ courseId, data, activeUnitId, trailerSection }) => {
  const [preparedFilterData, setPreparedFilterData] = useState(null);
  const [preparedRenderData, setPreparedRenderData] = useState(null);

  const { useOriginalData, useFilterDefinitions, useProcessedData } = useContext(FilterContext);
  const { setOriginalData } = useOriginalData;
  const { setFilterDefinitions } = useFilterDefinitions;
  const { processedData } = useProcessedData;

  useEffect(() => {
    if (data) {
      setPreparedFilterData(data.reduce((acc, section) => [...acc, ...section.unit_data.map(unit => ({
        section: section,
        unit: unit,
      }))], []));
    }
  }, [data]);

  useEffect(() => {
    if (preparedFilterData) {
      setOriginalData(preparedFilterData);
    }
  }, [preparedFilterData]);

  useEffect(() => {
    if (filterDefinitions) {
      setFilterDefinitions(filterDefinitions);
    }
  }, [filterDefinitions]);

  useEffect(() => {
    if (processedData) {
      let result = {};

      processedData.forEach((item) => {
        if (result[item.section.block_id] === undefined) {
          result[item.section.block_id] = {
            ...item.section,
            unit_data: [ item.unit ],
          };
        } else {
          result[item.section.block_id].unit_data.push(item.unit);
        }
      });

      setPreparedRenderData(Object.entries(result).map(([, value]) => value));
    }
  }, [processedData]);

  const [completedUnits, setCompletedUnits] = useState([]);
  useEffect(() => {
    setCompletedUnits(data.reduce((acc, curr) => acc.concat(curr.unit_data.filter((unit) => unit.completion === 1).map((unit) => unit.block_id)), []));
  }, [data]);

  return (
    <div id="agenda" className={styles.agenda}>
      <div className={styles.title}>
        <Text s14 w800 lh16 dark-4>
          {languages.EN.labels.agenda}
        </Text>
      </div>
      <div className={styles.searchBar}>
        <SearchInput placeholder={`${languages.EN.labels.search}...`} minWidth="100px" />
      </div>
      {trailerSection && <AgendaSection courseId={courseId} section={trailerSection} expandable={false} />}
      {preparedRenderData && preparedRenderData.map((section, index) => (
        <React.Fragment key={index}>
          <AgendaSection courseId={courseId} section={section} activeUnitId={activeUnitId} completedUnits={completedUnits} expandable={true} />
        </React.Fragment>
      ))}
    </div>
  );
}

export default Agenda;
