import React, { useContext } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import { generatePath } from "react-router-dom";
import { Route, Switch } from "react-router";
import CareerPathsList from "../../organisms/CareerPathsList/CareerPathsList";
import RelatedCompetencesAndCoursesList from "../../organisms/RelatedCompetencesAndCoursesList/RelatedCompetencesAndCoursesList";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { popupFields } from "../../organisms/JobPositionsList/JobPositionsList";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { UserContext } from "../../../contexts/UserContext";
import { SCOPE_COMPETENCE_FRAMEWORK_EDIT } from "../../../constants/scopes";

const JobPositionDetailsTemplate = ({ data }) => {
  const { id, name, profile_image_medium, topic, level, country, description, organization } = data.job_position;
  const links = [
    {
      content: languages.EN.labels.keyCompetences,
      href: generatePath(Routes.jobPositionDetails.keyCompetences.base, { id: id }),
    },
    {
      content: languages.EN.labels.complementaryCompetences,
      href: generatePath(Routes.jobPositionDetails.complementaryCompetences.base, { id: id }),
    },
    {
      content: languages.EN.labels.relatedCareerPaths,
      href: generatePath(Routes.jobPositionDetails.careerPaths.base, { id: id }),
    },
  ];
  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel, userData } = useContext(UserContext);

  useDocumentTitle(`${name} | ${languages.EN.labels.jobPositionDetails}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={name}
        path={[
          {
            name: languages.EN.labels.jobPositions,
            link: Routes.jobPositions.base,
          },
          {
            name: languages.EN.labels.jobPositionDetails,
            link: "#",
          },
        ]}
        img={profile_image_medium}
        infos={[
          {
            label: languages.EN.labels.learningArea,
            value: languages.EN.enums.learningAreas[topic],
          },
          {
            label: languages.EN.labels.experienceLevel,
            value: languages.EN.enums.experienceLevels[level],
          },
          {
            label: languages.EN.labels.region,
            value: country,
          },
          {
            label: languages.EN.labels.visibility,
            value: languages.EN.enums.visibility[organization !== null ? 2 : 3],
          },
          {
            label: languages.EN.labels.description,
            value: description,
          },
        ]}
        buttons={[
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            visible: userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT) && (
              (userContextLevel === 2 && organization !== null) ||
              (userContextLevel === 3 && organization === null)
            ),
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.jobPosition}`,
                fields: popupFields(data.job_position),
                postAction: (formData) => jobPositionsRepository.editJobPosition(id, formData),
              },
            }),
          },
          {
            icon: "clone",
            name: languages.EN.labels.clone,
            visible: [2, 3].includes(userContextLevel) && userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT),
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.clone} ${languages.EN.labels.jobPosition}`,
                fields: popupFields(data.job_position),
                postAction: jobPositionsRepository.addJobPosition,
              },
            }),
          },
        ]}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={links[0].href}>
            <FilterContextProvider key={0}>
              <RelatedCompetencesAndCoursesList
                key={"required"}
                data={data.required_competences}
                id={"jobPositionRequired"}
                withManagement={
                  (data.job_position.organization !== null && userContextLevel === 2) ||
                  (data.job_position.organization === null && userContextLevel === 3)
                }
                managementData={{ priority: 0, jobPositionId: data.job_position.id, otherPriorityCompetences: data.recommended_competences.map((item) => `${item.competence.id}-${item.level}`) }}
              />
            </FilterContextProvider>
          </Route>
          <Route path={links[1].href}>
            <FilterContextProvider key={1}>
              <RelatedCompetencesAndCoursesList
                key={"recommended"}
                data={data.recommended_competences}
                id={"jobPositionRecommended"}
                withManagement={
                  (data.job_position.organization !== null && userContextLevel === 2) ||
                  (data.job_position.organization === null && userContextLevel === 3)
                }
                managementData={{ priority: 1, jobPositionId: data.job_position.id, otherPriorityCompetences: data.required_competences.map((item) => `${item.competence.id}-${item.level}`) }}
              />
            </FilterContextProvider>
          </Route>
          <Route path={links[2].href}>
            <FilterContextProvider key={2}>
              <CareerPathsList key={"careerPaths"} data={data.career_paths} />
            </FilterContextProvider>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default JobPositionDetailsTemplate;
