import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import styles from "./HoverPreview.module.scss";
import playVideo2 from "../../../assets/icons/playVideo2.svg";

const HoverPreview = ({ url, allowPlaying }) => {
  const [isInteractionNeeded, setIsInteractionNeeded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [hasEnded, setHasEnded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(10);

  useEffect(() => {
    if (!allowPlaying) {
      setShouldPlay(false);
    } else {
      setShouldPlay(true);
    }
  }, [allowPlaying]);

  useEffect(() => {
    if (url && isHovered && allowPlaying && !hasEnded) {
      setPreviewUrl(url);
    }
  }, [isHovered, allowPlaying]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!shouldPlay) {
        if (volume > 0) {
          setVolume(volume - 1);
        } else {
          setIsPlaying(false);
        }
      } else {
        if (!isPlaying) {
          setIsPlaying(true);
        }
        if (volume < 10) {
          setVolume(volume + 1);
        }
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [shouldPlay, volume]);

  return (
    <div
      className={styles.wrapper}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={() => {
        setIsInteractionNeeded(false);
        setIsPlaying(true);
      }}
    >
      {previewUrl && (
        <ReactPlayer
          url={previewUrl}
          width="100%"
          height="fit-content"
          playing={isPlaying}
          volume={volume / 10}
          style={{ visibility: isReady ? 'visible' : 'hidden', aspectRatio: "16 / 9"}}
          onReady={() => setIsReady(true)}
          onEnded={() => {
            setPreviewUrl(null);
            setHasEnded(true);
          }}
          onPause={() => {
            if (isPlaying) {
              setIsInteractionNeeded(true);
              setIsPlaying(false);
            }
          }}
        />
      )}
      {url && isInteractionNeeded && !hasEnded && (
        <div className={styles.overlay} data-clickable={isHovered && isInteractionNeeded}>
          <img src={playVideo2} alt="" className={styles.playImg} />
        </div>
      )}
    </div>
  );
};

export default HoverPreview
