import React, { useEffect, useState } from "react";
import BadgeTile from "../../molecules/BadgeTile/BadgeTile";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";

const filterDefinitions = [
  {
    keyPath: "badge.title",
    header: languages.EN.labels.badge,
    sortId: 1,
  },
  {
    keyPath: "level",
    header: languages.EN.labels.level,
    typeOfFilter: "range",
    sortId: 2,
  },
  {
    keyPath: "badge.description",
  },
];

const BadgesList = ({ data }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map(badge => ({
    ...badge,
  }))), [data]);

  return (
    <FilteredList
      id={`badges`}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderTileComponent={BadgeTile}
    />
  );
};

export default BadgesList;
