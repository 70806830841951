import styles from "./Footer.module.scss";
import logo from "../../../assets/aidify-logo.svg";
import FooterNavList from "../../molecules/FooterNavList/FooterNavList";
import { v4 as uuidv4 } from "uuid";

const Footer = ({ data, minusTopMargin }) => {
  const { navLists, socialMedias } = data;
  return (
    <div
      style={{ marginTop: minusTopMargin ? -minusTopMargin : 0 }}
      className={styles.footerLayout}
    >
      <div className={styles.footer}>
        {navLists.map(({ header, items }) => (
          <FooterNavList header={header} items={items} key={header} />
        ))}
        <div className={styles.aside}>
          <div className={styles.logo}>
            <img src={logo} alt="Aidify Logo" />
          </div>
          <div className={styles.socialMedias}>
            {socialMedias.map((socialMedia) => (
              <a
                key={uuidv4()}
                href={`${socialMedia.link}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {socialMedia.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
