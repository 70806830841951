import Button from "../../../../atoms/Button/Button";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import { languages } from "../../../../../dummyData/dummyData";
import { coursesRepository } from "../../../../../repository/courses.repository";
import useFetch from "../../../../../hooks/useFetch";
import Table from "../../Table";
import styles from "../../Table.module.scss";
import { TYPE_AUTOCOMPLETE } from "../../../../molecules/Form/Form";
import { competencesRepository } from "../../../../../repository/competences.repository";
import icons from "../../../../../assets/icons";
import CompetenceComponent from "../../../../molecules/CompetenceComponent/CompetenceComponent";
import { ReactComponent as AidifyLogo } from "../../../../../assets/logoIcon.svg";
import TableWrapper from "../../../../molecules/TableWrapper/TableWrapper";
import UnitsTable from "../UnitsTable/UnitsTable";
import { PostContext } from "../../../../../contexts/PostContext";

const popupFields = (competenceItems = [], data = {}) => [
  {
    name: "competence",
    label: languages.EN.labels.competence,
    required: true,
    type: TYPE_AUTOCOMPLETE,
    value: data.competence,
    items: competenceItems.reduce((acc, curr) => acc.concat(curr.competence_levels.map((competenceLevel) => ({
      id: `${curr.id}-${competenceLevel.level}`,
      label: `${curr.name} (${languages.EN.labels.level} ${competenceLevel.level}, ${languages.EN.enums.visibility[curr.organization !== null ? 2 : 3]})`,
      photo: icons[`level${competenceLevel.level}`],
    }))), []),
  },
  {
    name: "title",
    label: languages.EN.labels.name,
    required: true,
    value: data.title,
  },
];

function SectionsTable({ reports }) {
  const { data, course } = reports;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);
  const [editedSection, setEditedSection] = useState();

  const items = data.map((item) => ({
    id: item.block_id,
    ...item,
    subRow: (
      <TableWrapper
        title={languages.EN.labels.units}
        Table={UnitsTable}
        reports={{ data: item.unit_data, course: course, sectionId: item.block_id }}
        transparent
      />
    )
  }));

  const fetchCompetencesForAddPopup = useFetch();
  useEffect(() => {
    if (fetchCompetencesForAddPopup.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.section}`,
          fields: popupFields(fetchCompetencesForAddPopup.data),
          postAction: (formData) => {
            const competenceLevel = formData.competence.split("-");

            return coursesRepository.addSection({
              course_id: course.id,
              title: formData.title,
              competence: competenceLevel[0],
              level: competenceLevel[1],
              position: data.length + 1,
            })
          },
        },
      });
    }
  }, [fetchCompetencesForAddPopup.isReady]);

  const fetchCompetencesForEditPopup = useFetch();
  useEffect(() => {
    if (fetchCompetencesForEditPopup.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.section}`,
          fields: popupFields(fetchCompetencesForEditPopup.data, {
            ...editedSection,
            competence: `${editedSection.competence_level.competence.id}-${editedSection.competence_level.level}`
          }),
          postAction: (formData) => {
            const competenceLevel = formData.competence.split("-");

            return coursesRepository.editSection(editedSection.block_id, {
              course_id: course.id,
              title: formData.title,
              competence: competenceLevel[0],
              level: competenceLevel[1],
              position: editedSection.position,
            })
          },
        },
      });
    }
  }, [fetchCompetencesForEditPopup.isReady]);

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.position,
      accessor: "position",
    },
    {
      Header: languages.EN.labels.name,
      accessor: "title",
    },
    {
      Header: languages.EN.labels.competence,
      accessor: (data) => (
        <CompetenceComponent
          name={data.competence_level.competence.name}
          level={data.competence_level.level}
          img={data.competence_level.competence.organization !== null ? data.competence_level.competence.organization.profile_image_small : <AidifyLogo />}
        />
      ),
    },

    {
      Header: languages.EN.labels.unitsCount,
      accessor: "unit_data.length",
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={(event) => {
              event.stopPropagation();
              setIsDataActive(false);
              setEditedSection(data.row.original);
              fetchCompetencesForEditPopup.fetchData(competencesRepository.getCompetences());
              callPopup({ type: ACTIONS.LOADING });
            }}
            tooltip={languages.EN.labels.edit}
          />
          {data.row.original.position > 1 && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="topArrow"
              onClick={(event) => {
                event.stopPropagation();
                postData(() => coursesRepository.moveSection({ id: data.row.original.block_id, position: data.row.original.position - 1 }));
              }}
              tooltip={languages.EN.labels.moveUp}
            />
          )}
          {data.row.original.position < data.rows.length && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="bottomArrow"
              onClick={(event) => {
                event.stopPropagation();
                postData(() => coursesRepository.moveSection({ id: data.row.original.block_id, position: data.row.original.position + 1 }));
              }}
              tooltip={languages.EN.labels.moveDown}
            />
          )}
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={(event) => {
              event.stopPropagation();
              callPopup({
                type: ACTIONS.DELETE,
                payload: {
                  type: languages.EN.labels.section,
                  elementName: data.row.original.title,
                  action: () => coursesRepository.deleteSection(data.row.original.block_id),
                },
              })
            }}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ], []);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => {
        setIsDataActive(false);
        fetchCompetencesForAddPopup.fetchData(competencesRepository.getCompetences());
        callPopup({ type: ACTIONS.LOADING });
      },
    },
  ]} columns={columns} data={items} expand={false} />;
}

export default SectionsTable;
