import React from "react";
import styles from "./TimeBar.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";

const TimeBar = ({ userProgress, duration }) => {
  const progress = Math.ceil(duration - duration * userProgress);
  const hours = Math.trunc(progress / 60);
  const minutes = Math.trunc(progress % 60);

  return (
    <div className={styles.timeBar}>
      {userProgress !== null && (
        <div
          className={styles.timeBar__background}
          style={{ width: `calc(${Math.max(userProgress, 0.01) * 100} * 1%)` }}
          data-progress={userProgress}
        />
      )}
      {userProgress === 1 ? (
        <Text s12 lh14 w600 light>
          {languages.EN.labels.completed}
        </Text>
      ) : (
        <Text s12 lh14 w600 secondary stroke>
          {userProgress === null && `${languages.EN.labels.duration}: `}
          {hours === 1
            ? `${hours} ${languages.EN.labels.hour} `
            : hours > 0
              ? `${hours} ${languages.EN.labels.hours} `
              : null}
          {minutes === 1
            ? `${minutes} ${languages.EN.labels.minute} `
            : minutes > 0
              ? `${minutes} ${languages.EN.labels.minutes} `
              : null}
          {userProgress >= 0 && (hours || minutes) && languages.EN.labels.left}
        </Text>
      )}
    </div>
  );
};

export default TimeBar;
