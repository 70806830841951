import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { Route, Switch } from "react-router";
import IdpCareerContainer from "../../organisms/IdpCareerContainer/IdpCareerContainer";
import IdpLearningPlanEdit from "../../organisms/IdpLearningPlan/IdpLearningPlanEdit";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath, matchPath, useLocation } from "react-router-dom";
import { MODE_ADD, MODE_EDIT } from "../../pages/IdpEdit/IdpEdit";
import Text from "../../atoms/Text/Text";
import ProgressBar, { VARIANT_TRANSPARENT } from "../../molecules/ProgressBar/ProgressBar";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { STATUS_STYLES } from "../IdpDetailsTemplate/IdpDetailsTemplate";
import IdpComments from "../../molecules/IdpComments/IdpComments";
import { dateFormat } from "../../../utils/formatters";

const IdpEditTemplate = ({ idp, courses, careerPaths, jobPositions, params }) => {
  const location = useLocation();

  const paths = [
    generatePath(Routes.idpEdit.career.base, { id: params.id, username: params.username, mode: params.mode }),
    generatePath(Routes.idpEdit.learningPlan.base, { id: params.id, username: params.username, mode: params.mode }),
  ]

  const links = [
    {
      content: languages.EN.labels.career,
      href: paths[0],
      disabled: params.mode === MODE_ADD && matchPath(location.pathname, { path: paths[1] }) !== null,
    },
    {
      content: languages.EN.labels.learningPlan,
      href: paths[1],
      disabled:
        (params.mode === MODE_ADD && matchPath(location.pathname, { path: paths[0] }) !== null) ||
        (params.mode === MODE_EDIT && idp.learning_plan.length === 0),
    },
  ];

  const commentsSorted = (params.mode === MODE_EDIT ? idp.comments : []).sort((a, b) => (new Date(a.created)) < (new Date(b.created)) ? 1 : -1);
  const team = idp.user_team.find(team => !team.is_manager);
  const idp_status = params.mode === MODE_EDIT ? idp.idp_status : null;

  useDocumentTitle(`${idp.user_profile.name} ${idp.user_profile.surname} | ${languages.EN.labels.individualDevelopmentPlan}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={idp.user_profile.name + " " + idp.user_profile.surname}
        path={[
          {
            name: languages.EN.labels.profile,
            link: generatePath(Routes.profile.statistics.base, { username: params.username }),
          },
          {
            name: languages.EN.labels.individualDevelopmentPlan,
            link: generatePath(Routes.idps.base, { username: params.username }),
          },
        ].concat(params.mode === MODE_ADD ? [
          {
            name: languages.EN.labels.add,
            link: "#",
          }
        ] : [
          {
            name: `${languages.EN.labels.version} ${idp.version}${idp.archived ? ` (${languages.EN.labels.archivedOn} ${dateFormat(idp.archived)})` : ''}`,
            link: generatePath(Routes.idpDetails.career.base, { id: params.id, username: params.username }),
          },
          {
            name: languages.EN.labels.edit,
            link: "#",
          }
        ])}
        img={idp.user_profile.profile_image_medium}
        imgSquare={true}
        infos={[
          {
            label: languages.EN.labels.team,
            value: team?.name,
          },
          {
            label: languages.EN.labels.idpStatus,
            value: (
              <div className={STATUS_STYLES[idp_status]}>
                <Text s14 w600 lh20>
                  {idp_status === null ? languages.EN.labels.noPlan : languages.EN.enums.idpStatus[idp_status]}
                </Text>
              </div>
            ),
          },
          {
            label: languages.EN.labels.idpProgress,
            value: (
              <ProgressBar
                variant={VARIANT_TRANSPARENT}
                progress={params.mode === MODE_EDIT ? idp.idp_progress * 100 : null}
                information={params.mode === MODE_EDIT ? `${Math.round(idp.idp_minutes_left)} ${languages.EN.labels.minutesLeft}` : null}
              />
            ),
          },
          {
            label:languages.EN.labels.managerComments,
            value: commentsSorted.length > 0 ? <IdpComments comments={commentsSorted} /> : undefined
          },
        ]}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate {...{ [matchPath(location.pathname, { path: paths[1] }) !== null ? "padBottom0" : ""]: true }}>
        <Switch>
          <Route path={links[0].href}>
            <IdpCareerContainer
              idp={{id: params.id, ...idp}}
              careerPaths={careerPaths}
              jobPositions={jobPositions}
              params={params}
            />
          </Route>
          <Route path={links[1].href}>
            {idp.learning_plan.length > 0 && (
              <IdpLearningPlanEdit
                idp={idp}
                courses={courses}
                params={params}
              />
            )}
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default IdpEditTemplate;
