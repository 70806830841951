import React, { useState, useEffect } from "react";
import styles from "./ItemsWithPagination.module.scss";
import Text from "../../../components/atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import SortPanel from "../../molecules/SortPanel/SortPanel";
import ReactPaginate from "react-paginate";
import { TableLoader } from "../../atoms/Loader/Loader";
import { useRef } from "react";

const ItemsWithPagination = ({ items, RenderComponent, isGrid, gridSize, id, state, view, sortPanel, noMargin, onPageChange }) => {
  const [currentListItems, setCurrentListItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [offset, setOffset] = useState(0);
  const [isPaginatedDataReady, setIsPaginatedDataReady] = useState(false);

  useEffect(() => {
    setCurrentPage(0);
  }, [items]);
  const changeItemsLimit = (e) => {
    const newItemsLimit = parseInt(e.target.value);

    setPageSize(newItemsLimit);
    if (onPageChange) {
      onPageChange(offset, newItemsLimit);
    }

  };
  const getPaginationResultsString = () => {
    if (items) {
      return `${languages.EN.labels.results} ${offset + 1} - ${offset + pageSize > items.length ? items.length : offset + pageSize} ${languages.EN.labels.of} ${items.length}`;
    }
  };

  useEffect(() => {
    setOffset(0);
  }, [items]);

  useEffect(() => {
    setPageCount(Math.ceil(items.length / pageSize));
  }, [items, pageSize]);

  useEffect(() => {
    setIsPaginatedDataReady(false);
    setCurrentListItems(items.slice(offset, offset + pageSize));
  }, [offset, items, pageSize]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (currentListItems) {
        setIsPaginatedDataReady(true);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [currentListItems]);

  const handlePageClick = (event) => {
    setCurrentPage(() => event.selected);
    const newItemsOffset = (event.selected * pageSize) % items.length;
    setOffset(newItemsOffset);

    if (onPageChange) {
      onPageChange(newItemsOffset, pageSize);
    }
  };

  const wrapperRef = useRef();

  return (
    <>
      <div ref={wrapperRef} className={styles.wrapper}>
        <div
          className={styles.items}
          data-isgrid={isGrid}
          data-nomargin={noMargin}
          data-fixed-layout={isGrid === undefined && !noMargin}
        >
          {isPaginatedDataReady ? (
            <>
              {sortPanel && (
                <SortPanel
                  items={sortPanel}
                  isGrid={isGrid || !!currentListItems?.length === false}
                />
              )}
              {currentListItems.length === 0 ? (
                <div className={styles.noDataInformation}>
                  <Text s18 dark-2>
                    {languages.EN.labels.noData}
                  </Text>
                </div>
              ) : (
                isGrid ? (
                  <div className={styles.gridWrapper} data-grid-size={gridSize ?? 4}>
                    {currentListItems.map((data, i) => (
                      <RenderComponent
                        key={i}
                        data={data}
                        view={view}
                        id={id}
                        state={state}
                      />
                    ))}
                  </div>
                ) : (
                  currentListItems.map((data, i) => (
                    <RenderComponent
                      key={i}
                      data={data}
                      view={view}
                      id={id}
                      state={state}
                    />
                  ))
                )
              )}
            </>
          ) : (
            <div className={styles.loaderWrapper}>
              <TableLoader background={"transparent"} />
            </div>
          )}
        </div>
      </div>

      {!!items.length && (
        <div className={styles.pagination} data-nomargin={noMargin}>
          <div className={styles.select}>
            <select onChange={changeItemsLimit}>
              <option value={8}>8</option>
              <option value={12}>12</option>
              <option value={16}>16</option>
            </select>
            <span className={styles.selectArrow} />
          </div>

          <div className={styles.resultsString}>
            <Text w500 s12>
              {getPaginationResultsString()}
            </Text>
          </div>

          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            forcePage={pageCount ? currentPage : -1}
            initialSelected={currentPage}
            pageCount={pageCount}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='previousButton'
            previousLinkClassName='previousLink'
            nextClassName='nextButton'
            nextLinkClassName='nextLink'
            breakLabel='···'
            breakClassName='page-item'
            breakLinkClassName='breakLink'
            containerClassName='reactPagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};

export default ItemsWithPagination;
