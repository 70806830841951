import Text from "../../../atoms/Text/Text";
import Form from "../../Form/Form";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../../contexts/PostContext";
import { languages } from "../../../../dummyData/dummyData";
import { settingsRepository } from "../../../../repository/settings.repository";
import styles from "./MfaConfirmPopup.module.scss";

export const popupFields = [
  {
    name: "otp",
    label: languages.EN.labels.oneTimePassword,
    required: true,
  },
];

const MfaConfirmPopup = ({ qrCode }) => {
  const { callPopup } = useContext(PopupsContext);
  const { postData, reset, isPostFinish, isResponseOk } = useContext(PostContext);

  useEffect(() => {
    if (isPostFinish && isResponseOk) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish, isResponseOk]);

  return (
    <>
      <Text>{languages.EN.labels.enable} {languages.EN.labels.twoFactorAuthentication.toLowerCase()}</Text>
      <div className={styles.content}>
        <div className={styles.qrCodeWrapper}>
          <div className={styles.descriptionWrapper}>
            <Text s24 w800 secondary>
              1.
            </Text>
            <Text s14 lh24 dark-6>
              {languages.EN.messages.addMfaStep1}
            </Text>
          </div>
          <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} alt={languages.EN.labels.qrCode} />
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.descriptionWrapper}>
            <Text s24 w800 secondary>
              2.
            </Text>
            <Text s14 lh24 dark-6>
              {languages.EN.messages.addMfaStep2}
            </Text>
          </div>
          <Form
            fields={popupFields}
            onSave={(formData) => postData(() => settingsRepository.confirmMfa(formData), undefined, undefined, 'confirmMfa')}
            onBack={() => {
              reset();
              callPopup({ type: ACTIONS.REMOVE })
            }}
            autoFocus={true}
          />
        </div>
      </div>
    </>
  );
};

export default MfaConfirmPopup;
