import styles from "./PopupDelete.module.scss";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { languages } from "../../../../dummyData/dummyData";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../../contexts/PostContext";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";

const PopupDelete = ({ action, type, elementName, onSuccess, redirectOnSucces }) => {
  const { postData, isPostFinish } = useContext(PostContext);
  const { callPopup } = useContext(PopupsContext);

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish]);

  return (
    <>
      <Text s18 w700 lh22 bold mb27>
        {languages.EN.labels.delete}
      </Text>
      <div className={styles.Content}>
        <Text s12 w600 dark-4>
          {languages.EN.messages.areYouSureDelete}
          <Text s12 w800 dark-4>
            {`${type}: `}
          </Text>
          <Text s12 w800 dark-4>
            {elementName}
          </Text>
          ?
        </Text>
      </div>
      <SaveBackButtons onSave={() => postData(action, onSuccess, redirectOnSucces)} saveLabel={languages.EN.labels.confirm} onBack={() => callPopup({ type: ACTIONS.REMOVE })} />
    </>
  );
};

export default PopupDelete;
