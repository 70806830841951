import CareerPathsTemplate from "../../templates/CareerPathsTemplate/CareerPathsTemplate";
import useFetch from "../../../hooks/useFetch";
import { careerPathsRepository } from "../../../repository/careerPaths.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";
import { FilterContextProvider } from "../../../contexts/FilterContext";

const CareerPaths = () => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(careerPathsRepository.getCareerPaths());
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <CareerPathsTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default CareerPaths;
