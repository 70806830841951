import styles from "./HomepageInfoBig.module.scss"
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";

const HomepageInfoBig = ({ image, textBig, textSmall, button}) => {
  return (
    <div className={styles.idpInfo} style={{ ['--image-url']: `url("${image}")` }}>
      <div className={styles.idpInfo__photo} />
      <div className={styles.idpInfo__shadow} />
      <div className={styles.idpInfo__info}>
        <Text s24 lh20 w600 dark-6 shadow20>
          {textSmall}
        </Text>
        <Text s32 w600 secondary shadow20 montserrat>
          {textBig}
        </Text>
        <Button variant={"primaryH40"} icon={button.icon} onClick={button.onClick} withIcon useIconsFile>
          {button.text}
        </Button>
      </div>
    </div>
  );
};

export default HomepageInfoBig
