import moment from "moment";
import { languages } from "../dummyData/dummyData";

export const dateFormat = (date) =>
  new Date(date)
    .toISOString()
    .slice(0, 10)
    .replace(/T.*/, "")
    .split("-")
    .join("/");

export const dateHourFormat = (date) =>
  moment(date).format("YYYY/MM/DD, hh:mm:ss");

export const dateDiffFormat = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  const years = endDate.diff(startDate, "years");
  const months = endDate.diff(startDate.add(years, "years"), "months");
  const days = endDate.diff(startDate.add(months, "months"), "days");

  return (years > 0 ? `${years} years ` : '') + (months > 0 ? `${months} months ` : '') + (days > 0 ? `${days} days` : '');
};

export const durationFormat = (duration) => {
  const isZero = duration === 0;

  const days = Math.floor(duration / 60 / 60 / 24);
  duration = duration - days * 24 * 60 * 60;
  const hours = Math.floor(duration / 60 / 60);
  duration = duration - hours * 60 * 60;
  const minutes = Math.floor(duration / 60);
  duration = duration - minutes * 60;
  const seconds = Math.round(duration);

  return (days > 0 ? `${days} ${languages.EN.labels.days.toLowerCase()} ` : '') +
    (hours > 0 ? `${hours} ${languages.EN.labels.hourShort} ` : '') +
    (minutes > 0 ? `${minutes} ${languages.EN.labels.min} ` : '') +
    ((seconds > 0 || isZero) ? `${seconds} ${languages.EN.labels.sec}` : '');
}

export const durationInHours = (durationInSeconds) => {
  return `~${Math.round(durationInSeconds / 60 / 60)} ${languages.EN.labels.hours}`;
}

export const timeAgoFormat = (milisecondsBetweenDates) => {
  const seconds = Math.floor(milisecondsBetweenDates / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (seconds < 60) {
    return seconds !== 1 ? seconds + " " + languages.EN.labels.secsAgo : seconds + " " + languages.EN.labels.secAgo;
  } else if (minutes < 60) {
    return minutes !== 1 ? minutes + " " + languages.EN.labels.minsAgo : minutes + " " + languages.EN.labels.minAgo;
  } else if (hours < 24) {
    return hours !== 1 ? hours + " " + languages.EN.labels.hoursAgo : hours + " " + languages.EN.labels.hourAgo;
  }
}

export const dateParamFormat = (value) => value.toISOString().slice(0, 10).replace(/T.*/, "");

export const currencyFormat = (value) => new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(value);

export const percentageFormat = (value) => `${(value * 100).toFixed(2)}%`;
