import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import { generatePath } from "react-router-dom";
import ListItem from "../ListItem/ListItem";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import { languages } from "../../../dummyData/dummyData";

const TeamListItem = ({ data }) => {
  const { name, description, profile_image_small, num_members, id, preparedManager } = data;
  const history = useHistory();

  return (
    <ListItem title={name} image={profile_image_small} buttons={[
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.team.statistics.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ]}>
      <ListItemInfo value={preparedManager} />
      <ListItemInfo value={description} />
      <ListItemInfo value={num_members} />
    </ListItem>
  );
};

export default TeamListItem;
