import styles from "../QuestionsTable.module.scss";
import React from "react";

const FillHolesAnswer = ({ answer, correct }) => {
  return (
    <div className={`${styles.fillOption} ${correct ? styles.correct : styles.incorrect}`}>
      {answer}
    </div>
  );
};

export default FillHolesAnswer
