import React, { useMemo } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { generatePath, useHistory } from "react-router-dom";
import CourseAssign from "../../../../atoms/CourseAssign/CourseAssign";
import { v4 as uuidv4 } from "uuid";
import { Routes } from "../../../../../routes";

function UserCompetencesTable({ reports }) {
  const { data } = reports;
  const history = useHistory();

  const items = data.map((item) => ({
    learningArea: languages.EN.enums.learningAreas[item.topic],
    ...item,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.competence,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.organization,
      accessor: "organization.name",
    },
    {
      Header: languages.EN.labels.learningArea,
      accessor: "learningArea",
    },
    {
      Header: languages.EN.labels.jobPositions,
      accessor: (data) => data.job_positions.length > 0 ? data.job_positions.map((item) => item.name) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.noOfEmployees,
      accessor: "num_students",
    },
    {
      Header: languages.EN.labels.assignedCourse,
      notPlaceholder: true,
      accessor: (data) => data.related_courses.length > 0 ? data.related_courses.map((item) => (
        <CourseAssign key={uuidv4()} name={item.display_name} id={item.id} />
      )) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(generatePath(Routes.competenceDetails.base, { id: data.row.original.id }))}
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} />;
}

export default UserCompetencesTable;
