import ReactPlayer from "react-player/vimeo";
import React from "react";
import styles from "../CourseViewTemplate/CourseViewTemplate.module.scss";

const CoursePlayerTemplate = ({ vimeoId }) => {
  return (
    <ReactPlayer
      className={styles.reactPlayer}
      url={`https://vimeo.com/${vimeoId}`}
      controls
      playing={true}
    />
  );
}

export default CoursePlayerTemplate;