import React, { useContext } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { Redirect, Route, Switch } from "react-router";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import OrganizationStatistics from "../../organisms/OrganizationStatistics/OrganizationStatistics";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { generatePath, useHistory } from "react-router-dom";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import RewardEndorsementsList from "../../organisms/RewardEndorsementsList/RewardEndorsementsList";
import BadgesHistoryList from "../../organisms/BadgesHistoryList/BadgesHistoryList";
import CertificatesList from "../../organisms/CerificatesList/CertificatesList";
import { UserContext } from "../../../contexts/UserContext";
import MembersList from "../../organisms/MembersList/MembersList";
import TeamsList from "../../organisms/TeamsList/TeamsList";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { TableLoader } from "../../atoms/Loader/Loader";

const OrganizationTemplate = ({ organization, certificates, badges, rewards }) => {
  const { name, profile_image, teams, members, street, postcode, city, contact_email, contact_phone } = organization;
  const { id } = organization;
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const withManagement = userData.current.context_type === 2 && userData.current.context_id === id;
  const links = [
    {
      content: languages.EN.labels.statistics,
      href: generatePath(Routes.organization.statistics.base, { id: id }),
    },
    {
      content: languages.EN.labels.employees,
      href: generatePath(Routes.organization.employees.base, { id: id }),
    },
    {
      content: languages.EN.labels.teamList,
      href: generatePath(Routes.organization.teams.base, { id: id }),
    },
    {
      content: languages.EN.labels.certificates,
      href: generatePath(Routes.organization.certificates.base, { id: id }),
    },
    {
      content: languages.EN.labels.badges,
      href: generatePath(Routes.organization.badges.base, { id: id }),
    },
    {
      content: languages.EN.labels.rewardsAndEndorsements,
      href: generatePath(Routes.organization.rewards.base, { id: id }),
    },
  ];

  useDocumentTitle(`${name} | ${languages.EN.labels.organization}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={name}
        path={[
          {
            name: languages.EN.labels.organization,
            link: "#",
          },
        ]}
        img={profile_image}
        infos={[
          {
            label: languages.EN.labels.address,
            value: `${street}, ${postcode} ${city}`,
          },
          {
            label: languages.EN.labels.email,
            value: contact_email,
          },
        ].concat(contact_phone ? [
          {
            label: languages.EN.labels.phoneNumber,
            value: contact_phone,
          },
        ] : [])}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={generatePath(Routes.organization.base, { id: id })}>
            <Redirect to={generatePath(Routes.organization.statistics.base, { id: id })} />
          </Route>
          <Route path={links[0].href}>
            <OrganizationStatistics data={organization} />
          </Route>
          <Route path={links[1].href}>
            <FilterContextProvider key={0}>
              <MembersList id="organization" data={members} managementLevel={withManagement ? 2 : 0} onAdd={() => history.push({
                pathname: Routes.organizationLicenses.base,
                state: { scrollToLicenses: true, showInformation: true },
              })} />
            </FilterContextProvider>
          </Route>
          <Route path={links[2].href}>
            <FilterContextProvider key={1}>
              <TeamsList data={teams} withManagement={withManagement} />
            </FilterContextProvider>
          </Route>
          <Route path={links[3].href}>
            <FilterContextProvider key={2}>
              {certificates ? <CertificatesList id="organization" data={certificates.certificates} /> : <TableLoader background={"transparent"} />}
            </FilterContextProvider>
          </Route>
          <Route path={links[4].href}>
            <FilterContextProvider key={3}>
              {badges ? (
                <BadgesHistoryList 
                  id="organization" 
                  data={badges.badges} 
                  withManagement={withManagement}
                  viewMoreRoute={generatePath(Routes.learningReport.competitions.base, { type: 2, id: id })}
                />) : <TableLoader background={"transparent"} />}
            </FilterContextProvider>
          </Route>
          <Route path={links[5].href}>
            <FilterContextProvider key={4}>
              {rewards ? <RewardEndorsementsList id="organization" data={rewards} withManagement={withManagement} /> : <TableLoader background={"transparent"} />}
            </FilterContextProvider>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default OrganizationTemplate;
