import styles from "./LegendBox.module.scss";
import LegendItem from "../../atoms/LegentItem/LegendItem";

const LegendBox = ({ data }) => {
  return (
    <div className={styles.Wrapper}>
      {data.map((item, idx) => (
        <LegendItem name={item.name} color={item.color} key={idx} />
      ))}
    </div>
  );
};

export default LegendBox;
