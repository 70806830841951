import { useContext, useEffect } from "react";
import { PostContext } from "../../../../contexts/PostContext";
import useFlashMessages from "../../../../hooks/useFlashMessages";
import InfoBaner, { VARIANT_ERROR } from "../../../atoms/InfoBaner/InfoBaner";
import { languages } from "../../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../../hooks/useDocumentTitle";
import LandingTemplate from "../LandingTemplate/LandingTemplate";
import Form, { TYPE_PASSWORD, TYPE_PASSWORD_WITH_REQUIREMENTS } from "../../../molecules/Form/Form";

export const formFields = [
  {
    name: "new_password1",
    label: languages.EN.labels.password,
    required: true,
    type: TYPE_PASSWORD_WITH_REQUIREMENTS,
  },
  {
    name: "new_password2",
    label: languages.EN.labels.repeatPassword,
    required: true,
    validation: (formData) => formData.new_password1 !== formData.new_password2 ? languages.EN.messages.mustMatchPassword : undefined,
    type: TYPE_PASSWORD,
  },
];

const NewPasswordTemplate = ({ onSave, onBack }) => {
  const { isPostFinish, isResponseOk, postResponse } = useContext(PostContext);
  const { flashMessages, addFlashMessage } = useFlashMessages();

  useEffect(() => {
    if (isPostFinish && isResponseOk === false && postResponse?.status) {
      addFlashMessage(VARIANT_ERROR, postResponse.status);
    }
  }, [isPostFinish, isResponseOk, postResponse]);

  const pageHeader = languages.EN.labels.setNewPassword;
  useDocumentTitle(pageHeader);

  return (
    <LandingTemplate header={pageHeader}>
      {flashMessages.map((message, i) => <InfoBaner key={i} variant={message.type} text={message.content} />)}
      <Form
        fields={formFields}
        onSave={onSave}
        saveLabel={languages.EN.labels.send}
        onBack={onBack}
        autoFocus={true}
      />
    </LandingTemplate>
  );
};

export default NewPasswordTemplate
