import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import ListItem from "../ListItem/ListItem";
import TimeBar from "../../molecules/TimeBar/TimeBar";
import { Routes } from "../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { languages } from "../../../dummyData/dummyData";
import ListItemImgMultiInfo from "../../molecules/ListItemImgMultiInfo/ListItemImgMultiInfo";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const CoursesItem = ({ data }) => {
  const {
    id,
    selectableId,
    profile_image_small,
    display_name,
    subtitle,
    topic,
    duration,
    instructors,
    visibilityImg,
    visibility,
    user_progress,
    active,
    canEdit,
    canSelect,
    isChecked,
  } = data;

  const { userContextLevel } = useContext(UserContext);
  let history = useHistory();

  return (
    <ListItem
      title={display_name}
      subtitle={subtitle}
      image={profile_image_small}
      inactive={!active}
      bar={userContextLevel === 0 && user_progress !== null ? <TimeBar userProgress={user_progress} duration={duration} /> : undefined}
      buttons={(canEdit ? [
        {
          icon: "edit",
          onClick: () => history.push(generatePath(Routes.courseEdit.conspect.base, { id: id })),
          tooltip: languages.EN.labels.edit,
        },
      ] : []).concat([
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.courseDetails.statistics.base, { id: id })),
          tooltip: languages.EN.labels.details,
        },
      ])}
      id={id}
      selectableId={canSelect ? selectableId : undefined}
      isChecked={isChecked}
    >
      <ListItemInfo value={
        <TextTruncated lineClamp={1}>{languages.EN.enums.learningAreas[topic]}</TextTruncated>
      } />
      <ListItemInfo value={`${duration} ${languages.EN.labels.minutes}`} />
      <ListItemImgMultiInfo infos={instructors} />
      <ListItemImgInfo value={visibility} image={visibilityImg} />
    </ListItem>
  );
};

export default CoursesItem;
