import styles from "./InstructorDetailsTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import Text from "../../atoms/Text/Text";
import Html from "../../atoms/Html/Html";
import React, { useContext } from "react";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { instructorRepository } from "../../../repository/instructor.repository";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import CourseCarousel from "../../organisms/CourseCarousel/CourseCarousel";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import Rating from "../../molecules/Rating/Rating";
import Reviews from "../../organisms/Reviews/Reviews";
import { UserContext } from "../../../contexts/UserContext";
import { TYPE_STARPICKER, TYPE_TEXTAREA } from "../../molecules/Form/Form";

const popupFields = (data = {}) => [
  {
    name: "rating",
    label: languages.EN.labels.rating,
    required: true,
    type: TYPE_STARPICKER,
    value: data.rating,
  },
  {
    name: "review",
    label: languages.EN.labels.review,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.review,
  },
];

const InstructorDetailsTemplate = ({ data }) => {
  const { instructor, instructor_courses, instructor_reviews, instructor_rating, instructor_enrollments } = data;
  const { callPopup } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);
  const myReview = instructor_reviews.reduce((acc, curr) => curr.user.username === userData.username ? curr : acc, undefined);

  useDocumentTitle(`${instructor.name} | ${languages.EN.labels.instructorDetails}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={instructor.name}
        path={[
          {
            name: languages.EN.labels.instructorDetails,
            link: "#",
          },
        ]}
        img={instructor.profile_image_medium}
        imgSquare={true}
        infos={[
          {
            label: languages.EN.labels.position,
            value: instructor.position,
          },
          {
            label: languages.EN.labels.students,
            value: instructor_enrollments,
          },
          {
            label: languages.EN.labels.location,
            value: instructor.location,
          },
          {
            label: languages.EN.labels.averageRating,
            value: <Rating rating={instructor_rating} reviews={instructor_reviews.length} />,
          }
        ]}
        buttons={[
          {
            icon: "linkedin2",
            name: languages.EN.labels.viewLinkedInProfile,
            onClick: () => { window.open(instructor.linkedin_url); }
          },
        ]}
      />
      <MainTemplate padTop90>
        <div className={styles.instructorDetails}>
          <div className={styles.instructorDetails__box}>
            <Text s24 w800 secondary>
              {languages.EN.labels.about}
            </Text>
            <Html s12 lh24 dark-2>
              {instructor.about}
            </Html>
          </div>
          <div className={styles.instructorDetails__box}>
            <Text s24 w800 secondary>
              {languages.EN.labels.courses}
            </Text>
            <div className={styles.instructorDetails__courses}>
              <CourseCarousel courses={instructor_courses} hMargins={1} />
            </div>
          </div>
          <div className={styles.instructorDetails__box}>
            <HeaderWithButtons header={languages.EN.labels.instructorReviews} buttons={myReview !== undefined ? [
              {
                icon: "edit",
                label: languages.EN.labels.edit,
                onClick: () => callPopup({
                  type: ACTIONS.FORM,
                  payload: {
                    header: `${languages.EN.labels.edit} ${languages.EN.labels.instructorReview}`,
                    fields: popupFields(myReview),
                    postAction: (formData) => instructorRepository.addReview(instructor.id, formData),
                  },
                }),
              },
              {
                icon: "garbage",
                label: languages.EN.labels.delete,
                onClick: () => callPopup({
                  type: ACTIONS.DELETE,
                  payload: {
                    type: languages.EN.labels.instructorReview,
                    elementName: instructor.name,
                    action: () => instructorRepository.deleteReview(instructor.id),
                  },
                }),
              },
            ] : [
              {
                icon: "plus",
                label: languages.EN.labels.add,
                onClick: () => callPopup({
                  type: ACTIONS.FORM,
                  payload: {
                    header: `${languages.EN.labels.add} ${languages.EN.labels.instructorReview}`,
                    fields: popupFields(),
                    postAction: (formData) => instructorRepository.addReview(instructor.id, formData),
                  },
                }),
              },
            ]} />
            <Reviews reviews={instructor_reviews} />
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default InstructorDetailsTemplate;
