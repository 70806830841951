import React from "react";
import styles from "./TableHeader.module.scss";
import Icons from "../../../assets/icons";

const TableHeader = ({ children, column }) => {
  return (
    <div
      className={styles.wrapperRow}
      data-is-sorted-desc={column.isSortedDesc}
      data-is-sorted={column.isSorted}
      data-is-sortable={!column.isSorted && !column.disableSortBy}
      data-full-width={column.fullWidth}
      style={column.headerStyle}
    >
      <div className={styles.innerWrapper} data-is-sortable={!column.disableSortBy}>
        {children}
      </div>
      {column.headerMarkerColor && (
        <div className={styles.markerColor} style={{ ['--header-marker-color']: column.headerMarkerColor }}/>
      )}
      {!column.disableSortBy ? Icons.topArrowSortPanel : null}
    </div>
  );
};

export default TableHeader;
