import styles from "./NotificationItem.module.scss";
import Text from "../../atoms/Text/Text";
import badge from "../../../assets/icons/notifiactions/badge.svg";
import chatMessage from "../../../assets/icons/notifiactions/chatMessage.svg";
import courseAssignment from "../../../assets/icons/notifiactions/courseAssignment.svg";
import courseDeadline from "../../../assets/icons/notifiactions/courseDeadline.svg";
import courseFeedback from "../../../assets/icons/notifiactions/courseFeedback.svg";
import courseRecommendation from "../../../assets/icons/notifiactions/courseRecommendation.svg";
import courseUpdate from "../../../assets/icons/notifiactions/courseUpdate.svg";
import endorsement from "../../../assets/icons/notifiactions/endorsement.svg";
import idpReview from "../../../assets/icons/notifiactions/idpReview.svg";
import internalTraining from "../../../assets/icons/notifiactions/internalTraining.svg";
import learningPlan from "../../../assets/icons/notifiactions/learningPlan.svg";
import lottery from "../../../assets/icons/notifiactions/lottery.svg";
import managerChange from "../../../assets/icons/notifiactions/managerChange.svg";
import myCompetition from "../../../assets/icons/notifiactions/myCompetition.svg";
import newCompetition from "../../../assets/icons/notifiactions/newCompetition.svg";
import newCourse from "../../../assets/icons/notifiactions/newCourse.svg";
import newLearningEvent from "../../../assets/icons/notifiactions/newLearningEvent.svg";
import newQa from "../../../assets/icons/notifiactions/newQa.svg";
import newRequestedCourse from "../../../assets/icons/notifiactions/newRequestedCourse.svg";
import newRiArticle from "../../../assets/icons/notifiactions/newRiArticle.svg";
import observedReply from "../../../assets/icons/notifiactions/observedReply.svg";
import payment from "../../../assets/icons/notifiactions/payment.svg";
import recommendationCompletion from "../../../assets/icons/notifiactions/recommendationCompletion.svg";
import registration from "../../../assets/icons/notifiactions/registration.svg";
import regulatoryDocument from "../../../assets/icons/notifiactions/regulatoryDocument.svg";
import unfinishedCourse from "../../../assets/icons/notifiactions/unfinishedCourse.svg";
import { dateFormat, timeAgoFormat } from "../../../utils/formatters";
import NotificationButton from "../../atoms/NotificationButton/NotificationButton";

export const notificationTypeImages = {
  badge: badge,
  chat_message: chatMessage,
  course_assignment: courseAssignment,
  course_deadline: courseDeadline,
  course_feedback: courseFeedback,
  course_recommendation: courseRecommendation,
  course_update: courseUpdate,
  endorsement: endorsement,
  idp_review: idpReview,
  internal_training: internalTraining,
  learning_plan: learningPlan,
  lottery: lottery,
  manager_change: managerChange,
  my_competition: myCompetition,
  new_competition: newCompetition,
  new_course: newCourse,
  new_learning_event: newLearningEvent,
  new_qa: newQa,
  new_requested_course: newRequestedCourse,
  new_ri_article: newRiArticle,
  observed_reply: observedReply,
  payment: payment,
  recommendation_completion: recommendationCompletion,
  registration: registration,
  regulatory_document: regulatoryDocument,
  unfinished_course: unfinishedCourse,
};

export const prepareDate = (date) => {
  const now = new Date();
  const then = new Date(date);
  const milisecondsBetweenDates = Math.abs(then.getTime() - now.getTime());

  return milisecondsBetweenDates < 24 * 60 * 60 * 1000 ? timeAgoFormat(milisecondsBetweenDates) : (
    <>
      {dateFormat(then)}, {then.toLocaleTimeString("pl-PL").slice(0, 5)}
    </>
  );
};

const NotificationItem = ({ data }) => {
  const { name, text, created, unread, type, extra } = data;

  return (
    <div className={styles.notification}>
      <div className={styles.notificationImage}>
        <img src={notificationTypeImages[type]} alt={name} />
      </div>
      <div className={styles.notificationContent}>
        <Text s12 lh14 {...(unread ? { "w700": true } : { "w400": true, "dark-4": true })}>
          {name}
        </Text>
        <Text s12 lh14 {...(unread ? { "w700": true } : { "w400": true, "dark-4": true })}>
          {text}
        </Text>
        <Text s10 lh12 {...(unread ? { "w700": true, "primary": true } : { "w400": true, "dark-4": true })}>
          {prepareDate(created)}
        </Text>
      </div>
      <NotificationButton type={type} extra={extra} />
    </div>
  );
};

export default NotificationItem;
