import React from "react";
import styles from "./VerificationBar.module.scss";
import Text from "../../atoms/Text/Text";

export const VARIANT_PRIMARY = "primary";

const VerificationBar = ({ progress, text, variant = VARIANT_PRIMARY, inTile, showProgressValue = true }) => {
  return (
    <div className={styles.timeBar__wrapper} data-in-tile={inTile}>
      <div className={styles.timeBar} data-in-tile={inTile}>
        <div
          className={styles.timeBar__background}
          style={{ width: `${progress ? progress * 100 : 0}%` }}
        >
          {showProgressValue && (
            <Text light s12 w600 lh14>
              {Math.ceil(progress * 100)}%
            </Text>
          )}
        </div>
      </div>
      <div className={styles.textContainer}>
        <Text {...{"s12": true, "lh14": true, "w600": true, [variant]: true}}>
          {text}
        </Text>
      </div>
    </div>
  );
};

export default VerificationBar;
