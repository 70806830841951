import React, { useContext, useEffect, useState } from "react";
import styles from "./SearchTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import ItemsWithPagination from "../../organisms/ItemsWithPagination/ItemsWithPagination";
import SearchItem from "../../organisms/SearchItem/SearchItem";
import FilterPanel from "../../organisms/FilterPanel/FilterPanel";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { FilterContext } from "../../../contexts/FilterContext";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import MainTemplate from "../generic/MainTemplate/MainTemplate";

const filterDefinitions = [
  {
    keyPath: "typeStr",
    header: languages.EN.labels.category,
    typeOfFilter: "checkbox",
  },
];

const SearchTemplate = ({ data }) => {
  const pageHeader = languages.EN.labels.searchResults;

  useDocumentTitle(pageHeader);

  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => {
    if (data) {
      setPreparedData(data.map((item) => ({
        ...item,
        typeStr: languages.EN.enums.searchDescription[item.type],
      })));
    }
  }, [data]);

  const { useOriginalData, useFilterDefinitions, useProcessedData } = useContext(FilterContext);
  const { setOriginalData } = useOriginalData;
  const { setFilterDefinitions } = useFilterDefinitions;
  const { processedData } = useProcessedData;

  useEffect(() => {
    if (preparedData) {
      setOriginalData(preparedData);
    }
  }, [preparedData]);

  useEffect(() => {
    if (filterDefinitions) {
      setFilterDefinitions(filterDefinitions);
    }
  }, [filterDefinitions]);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <div className={styles.mainContent}>
          {preparedData && (
            <FilterPanel />
          )}
          <div className={styles.itemsWrapper}>
            {processedData && (
              <ItemsWithPagination
                items={processedData}
                RenderComponent={SearchItem}
                lightBcg="light"
                noMargin
              />
            )}
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default SearchTemplate;
