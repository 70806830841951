import React from "react";
import styles from "./PieChartTestReport.module.scss";
import PieChartBox from "../../molecules/PieChart/PieChartBox";
import { v4 as uuidv4 } from "uuid";
import { languages } from "../../../dummyData/dummyData";

const PieChartTestReport = ({ data }) => {
  const items = data.reduce((acc, curr) => ({
    num_total: (acc.num_total ?? 0) + curr.num_total,
    num_incorrect: (acc.num_incorrect ?? 0) + curr.num_incorrect,
    num_correct: (acc.num_correct ?? 0) + curr.num_correct,
  }), {});

  const pieConfig = [
    {
      name: languages.EN.labels.answersGiven,
      filledAmount: items.num_incorrect + items.num_correct,
      emptyAmount: items.num_total - items.num_incorrect - items.num_correct,
    },
    {
      name: languages.EN.labels.correctAnswersGiven,
      filledAmount: items.num_correct,
      emptyAmount: items.num_total - items.num_correct,
    },
  ];

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.ChartWrapper}>
          {pieConfig.map((item) => (
            <PieChartBox
              key={uuidv4()}
              name={item.name}
              filledAmount={item.filledAmount}
              emptyAmount={item.emptyAmount}
              bigSize={true}
              singleValue={`${item.emptyAmount + item.filledAmount > 0 ? (100 * item.filledAmount / (item.emptyAmount + item.filledAmount)).toFixed() : 0}%`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PieChartTestReport;
