import { Switch, Route, Redirect } from "react-router";
import { Routes } from "../../../routes";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import NavBar from "../../organisms/NavBar/NavBar";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import LearningReportCompetences from "../../organisms/LearningReportTabs/LearningReportCompetences/LearningReportCompetences";
import LearningReportCourses from "../../organisms/LearningReportTabs/LearningReportCourses/LearningReportCourses";
import LearningReportInstructors from "../../organisms/LearningReportTabs/LearningReportInstructors/LearningReportInstructors";
import LearningReportCompetitions from "../../organisms/LearningReportTabs/LearningReportCompetitions/LearningReportCompetitions";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import {  useCurrentRoute } from "../../../hooks/useCurrentRoute";
import { TYPE_DATEPICKER, TYPE_SUBMENU } from "../../molecules/TopBannerButtons/TopBannerButtons";
import { dateParamFormat } from "../../../utils/formatters";

const LearningReportTemplate = ({ data, contexts }) => {
  const { competence_reports, course_reports, instructor_reports, rivalisation_reports } = data;
  const history = useHistory();
  const params = useParams();
  const currentRoute = useCurrentRoute(Routes.learningReport);

  const links = [
    {
      content: languages.EN.labels.competences,
      href: generatePath(Routes.learningReport.competences.base, { type: params.type, id: params.id, date: params.date  }),
    },
    {
      content: languages.EN.labels.courses,
      href: generatePath(Routes.learningReport.courses.base, { type: params.type, id: params.id, date: params.date  }),
    },
    {
      content: languages.EN.labels.instructors,
      href: generatePath(Routes.learningReport.instructors.base, { type: params.type, id: params.id, date: params.date  }),
    },
    {
      content: languages.EN.labels.competitions,
      href: generatePath(Routes.learningReport.competitions.base, { type: params.type, id: params.id, date: params.date  }),
    },
  ];

  const contextOptions = contexts.map((item) => ({
    id: `${item.context_type}_${item.context_id}`,
    name: item.name,
    onClick: () => history.push(generatePath(currentRoute.base, { type: item.context_type, id: item.context_id, date: params.date })),
  }));

  const selectedContext = contexts.find(context => context.context_type === parseInt(params.type) && context.context_id === parseInt(params.id));

  useDocumentTitle(languages.EN.labels.learningReport);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={languages.EN.labels.learningReport}
        path={[
          {
            name: languages.EN.labels.learningReport,
            link: "#",
          },
        ]}
        infos={[
          {
            label: languages.EN.labels.reportContext,
            value: selectedContext?.name,
          },
          {
            label: languages.EN.labels.reportDate,
            value: params.date ?? competence_reports[0].date,
          },
        ]}
        buttons={[
          {
            icon: "eye",
            name: languages.EN.labels.reportContext,
            onHoverType: TYPE_SUBMENU,
            options: contextOptions,
          },
          {
            icon: "calendar2",
            name: languages.EN.labels.reportDate,
            onHoverType: TYPE_DATEPICKER,
            onSelect: (date) => history.push(generatePath(currentRoute.base, { type: params.type, id: params.id, date: dateParamFormat(date) })),
            maxDate: new Date(),
          },
        ]}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={Routes.learningReport.base}>
            <Redirect to={Routes.learningReport.competences.base} />
          </Route>
          <Route exact path={Routes.learningReport.competences.base}>
            <LearningReportCompetences data={competence_reports[0]} />
          </Route>
          <Route path={Routes.learningReport.courses.base}>
            <LearningReportCourses data={course_reports[0]} />
          </Route>
          <Route path={Routes.learningReport.instructors.base}>
            <LearningReportInstructors data={instructor_reports[0]} />
          </Route>
          <Route path={Routes.learningReport.competitions.base}>
            <LearningReportCompetitions data={rivalisation_reports[0]} />
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default LearningReportTemplate;
