import styles from "./WorkgroupDetailsTemplate.module.scss";
import React, { useState, useEffect, useContext } from "react";
import ItemsWithPagination from "../../organisms/ItemsWithPagination/ItemsWithPagination";
import SwitchLayoutButton from "../../atoms/SwitchLayoutButton/SwitchLayoutButton";
import SearchInput from "../../atoms/SearchInput/SearchInput";
import Button from "../../atoms/Button/Button";
import FilterPanel from "../../organisms/FilterPanel/FilterPanel";
import WorkgroupMemberTile from "../../organisms/WorkgroupMemberTile/WorkgroupMemberTile";
import WorkgroupMember from "../../organisms/WorkgroupMember/WorkgroupMember";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { languages } from "../../../dummyData/dummyData";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { UserContext } from "../../../contexts/UserContext";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { UserConfigContext } from "../../../contexts/UserConfigContext";
import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import { FilterContext } from "../../../contexts/FilterContext";
import { Loader } from "../../atoms/Loader/Loader";
import { popupFields } from "../../organisms/CoursesList/CoursesList";

const {
  name,
  careerPath,
  team,
  email,
  phoneNumber,
  members,
  careerPaths,
  workgroups,
  workgroupDetails,
  editWorkgroup,
  selectUser,
  add,
} = languages.EN.labels;

const {
  jobPosition: jobPositionStr,
  workgroup: workgroupStr,
} = languages.EN.labels;
const { visibility } = languages.EN.enums;

const items = [
  {
    id: 1,
    content: name,
    sortKey: ["user.profile.name", "user.profile.surname"],
  },
  {
    id: 2,
    content: jobPositionStr,
    sortKey: "job_position_name",
  },
  {
    id: 3,
    content: careerPath,
    sortKey: "career_path_name",
  },
  {
    id: 4,
    content: team,
    sortKey: "prepared_teams",
  },
  {
    id: 5,
    content: email,
    sortKey: "user.email",
  },
  {
    id: 6,
    content: phoneNumber,
    sortKey: "phoneNumber",
  },
];

const links = [
  {
    content: members,
    href: `#`,
  },
];

const path = [
  {
    name: workgroups,
    link: "workgroups",
  },
  {
    name: workgroupDetails,
    link: "#",
  },
];

const dataToFind = [
  {
    keyPath: "user.profile.user_career_path.name",
    header: careerPaths,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "user.profile.user_job_position.name",
    header: jobPositionStr,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "prepared_teams",
    header: team,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "user.profile.phone_number",
  },
  {
    keyPath: "user.email",
  },
  {
    keyPath: ["user.profile.name", "user.profile.surname"],
  },
];

const WorkgroupDetailsTemplate = ({ workgroupDetails }) => {
  const { workgroup, workgroup_members } = workgroupDetails;
  const [userConfig, setUserConfig] = useContext(UserConfigContext);
  const [editPopupActive, setEditPopupActive] = useState(false);
  const [deletePopupActive, setDeletePopupActive] = useState(false);
  const [isGrid, setIsGrid] = useState(
    userConfig?.workgroupDetails?.templateGridView ? true : false
  );
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    setCurrentData({
      workgroup: workgroup,
      workgroup_members: workgroup_members.map((item) => ({
        ...item,
        prepared_teams: item.teams.map((team) => team.name),
        phoneNumber: item.user.profile.phone_number
          ? item.user.profile.phone_number
          : "",
        job_position_name: item.user.profile.user_job_position
          ? item.user.profile.user_job_position.name
          : "",
        career_path_name: item.user.profile.user_career_path
          ? item.user.profile.user_career_path.name
          : "",
      })),
    });
  }, [workgroup, workgroup_members]);

  useEffect(() => {
    setUserConfig((prev) => ({
      ...prev,
      workgroupDetails: { ...prev?.workgroupDetails, templateGridView: isGrid },
    }));
  }, [isGrid, setUserConfig]);

  const { callPopup } = useContext(PopupsContext);

  useEffect(() => {
    if (editPopupActive) {
      // TODO: use valid popup
      // callPopup({
      //   type: ACTIONS.WORKGROUP,
      //   payload: {
      //     header: editWorkgroup,
      //     content: currentData.workgroup,
      //     image: currentData.workgroup.profile_image,
      //   },
      // });
    }
    setEditPopupActive(false);
  }, [callPopup, currentData, editPopupActive, workgroup]);

  const history = useHistory();

  useEffect(() => {
    if (deletePopupActive) {
      callPopup({
        type: ACTIONS.DELETE,
        payload: {
          type: workgroupStr,
          elementName: currentData?.workgroup?.name,
          action: () => workgroupsRepository.deleteWorkgroup(workgroup.id),
          onSuccess: () => {
            callPopup({ type: ACTIONS.REMOVE });
            history.push(Routes.workgroups.base);
          },
          redirectOnSucces: true,
        },
      });
    }
    setDeletePopupActive(false);
  }, [
    callPopup,
    currentData?.workgroup?.name,
    deletePopupActive,
    history,
    workgroup,
  ]);

  const { userData, userContextLevel } = useContext(UserContext);

  const isOwner = userData.username === workgroup.owner.profile.user.username ? true : false;

  const { useProcessedData } = useContext(FilterContext);
  const { filteredData } = useProcessedData;

  const bannerInformation = {
    type: visibility[currentData?.workgroup?.visibility],
    description: currentData?.workgroup?.description,
    isOwner: isOwner,
    users: filteredData,
  };

  useDocumentTitle(`${workgroup.name} | ${languages.EN.labels.workgroupDetails}`);

  return (
    <>
      <NavBar />
      <TopBanner
        header={currentData?.workgroup?.name}
        path={path}
        img={currentData?.workgroup?.profile_image}
        infos={[
          {
            label: languages.EN.labels.visibility,
            value: visibility[currentData?.workgroup?.visibility],
          },
          {
            label: languages.EN.labels.description,
            value: currentData?.workgroup?.description,
          },
        ]}
        buttons={[
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            visible: isOwner,
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.workgroup}`,
                fields: popupFields(),
                postAction: workgroupsRepository.editWorkgroup(), // TODO: pass valid data to method
              },
            }),
          },
          {
            icon: "garbage",
            name: languages.EN.labels.delete,
            visible: isOwner,
            onClick: () => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.workgroup,
                elementName: currentData?.workgroup?.name,
                action: () => workgroupsRepository.deleteWorkgroup(workgroup.id),
                onSuccess: () => {
                  callPopup({ type: ACTIONS.REMOVE });
                  history.push(Routes.workgroups.base);
                },
                redirectOnSucces: true,
              },
            }),
          },
          {
            icon: "notes",
            name: languages.EN.labels.assignCourses,
            // TODO: visible: selected_users.length > 0,
            // TODO: add onClick action from TopBannerWorkgroup
          },
          {
            icon: "message",
            name: languages.EN.labels.sendMessage,
            // TODO: visible: selected_users.length > 0 && userData.scopes.includes(SCOPE_CHAT),
            // TODO: add onClick action from TopBannerWorkgroup
          },
        ]}
      />
      <SwitchMenu links={links} />
      <div className={styles.background}>
        {currentData ? (
          <section className={styles.wrapper}>
            <FilterPanel />
            <div className={styles.tilesWrapper}>
              <div className={styles.userPanel}>
                <SearchInput />
                <SwitchLayoutButton isGrid={isGrid} setIsGrid={setIsGrid} />
                {(isOwner || [2, 3].includes(userContextLevel)) && (
                  <div className={styles.button}>
                    <Button
                      variant="primaryW100"
                      withIcon
                      icon="smallPlus"
                      onClick={() => {
                        // callPopup({
                        //   type: ACTIONS.SHARE_COURSE,
                        //   payload: {
                        //     header: selectUser,
                        //     id: workgroup.id,
                        //     actionOnSave: "workgroup",
                        //     currentUsers: currentData.workgroup_members,
                        //   },
                        // });
                      }}
                    >
                      {add}
                    </Button>
                  </div>
                )}
              </div>

              {filteredData && (
                <ItemsWithPagination
                  state={isOwner}
                  items={filteredData}
                  RenderComponent={
                    isGrid ? WorkgroupMemberTile : WorkgroupMember
                  }
                  isGrid={isGrid}
                  id={workgroup.id}
                  sortPanel={items}
                />
              )}
            </div>
          </section>
        ) : (
          <Loader />
        )}
      </div>
      <Footer data={languages.EN.footer} />
    </>
  );
};

export default WorkgroupDetailsTemplate;
