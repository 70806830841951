import React, { useContext } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import CareerPathDetailsStatistics from "../../organisms/CareerPathDetailsStatistics/CareerPathDetailsStatistics";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import { Route, Switch } from "react-router";
import profileImage from "../../../assets/certificate.png";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import RelatedCompetencesAndCoursesList from "../../organisms/RelatedCompetencesAndCoursesList/RelatedCompetencesAndCoursesList";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath } from "react-router-dom";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { popupFields } from "../../organisms/CareerPathsList/CareerPathsList";
import { UserContext } from "../../../contexts/UserContext";
import { careerPathsRepository } from "../../../repository/careerPaths.repository";
import CareerPathDetailsJobPositionChart from "../../organisms/CareerPathDetailsJobPositionChart/CareerPathDetailsJobPositionChart";
import { SCOPE_COMPETENCE_FRAMEWORK_EDIT } from "../../../constants/scopes";

const CareerPathDetailsTemplate = ({ data }) => {
  const { id, name, profile_image_medium, country, description, topic, organization } = data.career_path;
  const links = [
    {
      content: languages.EN.labels.statistics,
      href: generatePath(Routes.careerPathDetails.statistics.base, { id: id }),
    },
    {
      content: languages.EN.labels.jobPositionChart,
      href: generatePath(Routes.careerPathDetails.jobChart.base, { id: id }),
    },
    {
      content: languages.EN.labels.careerPathCompetences,
      href: generatePath(Routes.careerPathDetails.competences.base, { id: id }),
    },
  ];
  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel, userData } = useContext(UserContext);

  useDocumentTitle(`${name} | ${languages.EN.labels.careerPathDetails}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={name}
        path={[
          {
            name: languages.EN.labels.careerPaths,
            link: Routes.careerPaths.base,
          },
          {
            name: languages.EN.labels.careerPathDetails,
            link: "#",
          },
        ]}
        img={profile_image_medium ? profile_image_medium : profileImage}
        infos={[
          {
            label: languages.EN.labels.learningArea,
            value: languages.EN.enums.learningAreas[topic],
          },
          {
            label: languages.EN.labels.region,
            value: country,
          },
          {
            label: languages.EN.labels.visibility,
            value: languages.EN.enums.visibility[organization ? 2 : 3],
          },
          {
            label: languages.EN.labels.description,
            value: description,
          },
        ]}
        buttons={[
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            visible: userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT) && (
              (userContextLevel === 2 && organization !== null) ||
              (userContextLevel === 3 && organization === null)
            ),
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.careerPath}`,
                fields: popupFields(data.career_path),
                postAction: (formData) => careerPathsRepository.editCareerPath(id, formData),
              },
            }),
          },
        ]}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route path={links[0].href}>
            <CareerPathDetailsStatistics data={data} />
          </Route>
          <Route path={links[1].href}>
            <CareerPathDetailsJobPositionChart data={data.career_path} />
          </Route>
          <Route path={links[2].href}>
            <FilterContextProvider key={0}>
              <RelatedCompetencesAndCoursesList data={data.job_competences} id="careerPath" withJobPositions={true} />
            </FilterContextProvider>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CareerPathDetailsTemplate;
