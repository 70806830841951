import { getAuthorizationHeader } from "./repositoryHelper";
import { search } from "../constants/endpoints";

const getSearch = (searchValue) => {
  return fetch(`${search}?q=${searchValue}`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const searchRepository = {
  getSearch,
};
