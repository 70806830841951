import { languages } from "../dummyData/dummyData";

const {
  fieldRequired,
  confirmationRequired,
  passwordRequired,
  minimumPasswordLength,
  mustMatchPassword,
  invalidEmail,
  invalidDate,
} = languages.EN.messages;

export const validationForm = (dataForm, errorArray) => {
  const formValues = Object.values(dataForm);

  const validArr = errorArray.map((item) => ({
    name: item.name,
    isError: false,
    type: item.type,
  }));

  let arr = [...validArr];
  for (let i = 0; i < arr.length; i++) {
    switch (arr[i].type) {
      case "text":
        if (formValues[i] !== "" || false || undefined || null) {
          arr[i].error = false;
        } else {
          arr[i].error = fieldRequired;
        }
        break;
      case "postcode":
        if (formValues[i].toString().trim() === "") {
          arr[i].error = fieldRequired;
        } else {
          arr[i].error = false;
        }
        break;
      case "checkbox":
        if (formValues[i] === false) {
          arr[i].error = confirmationRequired;
        } else {
          arr[i].error = false;
        }
        break;
      case "password":
        if (formValues[i].toString().trim() === "") {
          arr[i].error = passwordRequired;
        } else if (formValues[i].length < 8) {
          arr[i].error = minimumPasswordLength;
        } else {
          arr[i].error = false;
        }
        break;
      case "repeatPassword":
        if (dataForm.password !== dataForm.repeatPassword) {
          arr[i].error = mustMatchPassword;
        } else {
          arr[i].error = false;
        }
        break;
      case "email":
        if (formValues[i].toString().trim() === "") {
          arr[i].error = fieldRequired;
        } else if (
          !String(formValues[i].toString().trim())
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          arr[i].error = invalidEmail;
        } else {
          arr[i].error = false;
        }
        break;
      case "phone":
        if (formValues[i].toString().trim() === "") {
          arr[i].error = fieldRequired;
        } else {
          arr[i].error = false;
        }
        break;
      case "array":
        if (formValues[i].length > 0) {
          arr[i].error = false;
        } else {
          arr[i].error = fieldRequired;
        }
        break;
      case "date":
        if (Date.parse(formValues[i])) {
          arr[i].error = false;
        } else {
          arr[i].error = invalidDate;
        }
        break;
      default:
        break;
    }
  }

  const newArr = arr.map((item) => ({
    [item.name]: { error: item.error },
  }));
  return Object.assign({}, ...newArr);
};
