import React from "react";
import styles from "./SideImage.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import background from "../../../assets/background.png";
import Html from "../../atoms/Html/Html";

const SideImage = () => {
  return (
    <div className={styles.container}>
      <img src={background} alt="" />
      <div className={styles.content}>
        <div className={styles.header}>
          <Text s48 lh58 w600 white>{languages.EN.labels.whatIsAidify}</Text>
        </div>
        <div className={styles.description}>
          <span className={styles.description__subheader}>
            <Text s32 w600 lh30 white>{languages.EN.labels.aboutUs}</Text>
          </span>
          <Html s18 w300 lh30 white>{languages.EN.help.aboutUs}</Html>

          <span className={styles.description__subheader}>
            <Text s32 w600 lh30 white>{languages.EN.labels.ourMission}</Text>
          </span>
          <Html s18 w300 lh30 white>{languages.EN.help.ourMission}</Html>
        </div>
      </div>
    </div>
  );
};

export default SideImage;
