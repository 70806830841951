import React, { useContext } from "react";
import styles from "./CourseEditTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { Routes } from "../../../routes";
import { Route, Switch } from "react-router";
import SectionsTable from "../../organisms/Table/CourseEdit/SectionsTable/SectionsTable";
import QuestionsTable from "../../organisms/Table/CourseEdit/QuestionsTable/QuestionsTable";
import FilesTable from "../../organisms/Table/CourseEdit/FilesTable/FilesTable";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath } from "react-router-dom";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { UserContext } from "../../../contexts/UserContext";
import { coursesRepository } from "../../../repository/courses.repository";
import { popupFields } from "../../organisms/CoursesList/CoursesList";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import ReferencesTable from "../../organisms/Table/CourseEdit/ReferencesTable/ReferencesTable";

const CourseEditTemplate = ({ courseContent, courseDetails }) => {
  const { userContextLevel } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);

  const course = courseDetails.course;
  const instructors = course.course_instructor.length > 0 ? course.course_instructor.map(instructor => instructor.name) : [course.instructor_text];

  useDocumentTitle(`${course.display_name} | ${languages.EN.labels.edit} ${languages.EN.labels.course.toLowerCase()}`);

  const links = [
    {
      content: languages.EN.labels.conspect,
      href: generatePath(Routes.courseEdit.conspect.base, { id: course.id }),
    },
    {
      content: languages.EN.labels.competenceTest,
      href: generatePath(Routes.courseEdit.competence.base, { id: course.id }),
    },
    {
      content: languages.EN.labels.addittionalResources,
      href: generatePath(Routes.courseEdit.additional.base, { id: course.id }),
    },
    {
      content: languages.EN.labels.references,
      href: generatePath(Routes.courseEdit.references.base, { id: course.id }),
    },
  ];

  return (
    <section>
      <NavBar />
      <TopBanner
        header={course.display_name}
        path={[
          {
            name: languages.EN.labels.courses,
            link: Routes.courses.base,
          },
          {
            name: `${languages.EN.labels.edit} ${languages.EN.labels.course.toLowerCase()}`,
            link: "#",
          },
        ]}
        img={course.course_image_url}
        inactive={!course.active}
        infos={[
          {
            label: languages.EN.labels.learningArea,
            value: languages.EN.enums.learningAreas[course.topic],
          },
          {
            label: instructors.length > 1 ? languages.EN.labels.instructors : languages.EN.labels.instructor,
            value: instructors.join(", "),
          },
          {
            label: languages.EN.labels.shortDescription,
            value: course.short_description,
          },
        ]}
        buttons={course.course_organization && [2, 3].includes(userContextLevel) ? [
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.course}`,
                fields: popupFields(course),
                postAction: (formData) => coursesRepository.editCourse(course.id, formData),
              },
            }),
          },
        ] : []}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={links[0].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.sections}
                Table={SectionsTable}
                reports={{ data: courseContent.section_data, course: course }}
              />
            </div>
          </Route>
          <Route path={links[1].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.questions}
                Table={QuestionsTable}
                reports={{ data: courseContent.section_data}}
              />
            </div>
          </Route>
          <Route path={links[2].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.files}
                Table={FilesTable}
                reports={{ data: courseContent.additional_materials, courseId: course.id }}
              />
            </div>
          </Route>
          <Route path={links[3].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.references}
                Table={ReferencesTable}
                reports={{ data: courseDetails.references, units: courseContent.section_data.map(section => section.unit_data).flat(1) }}
              />
            </div>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
);
};

export default CourseEditTemplate;
