import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import React, { useContext } from "react";
import { Chatbot, createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import TypeSelector from "./Widgets/TypeSelector";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import { UserContext } from "../../../contexts/UserContext";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import { languages } from "../../../dummyData/dummyData";
import HtmlMessage from "./Messages/HtmlMessage";

const AidifyChatbot = ({ onClose }) => {
  const { userData } = useContext(UserContext);

  return (
    <Chatbot
      config={{
        initialMessages: [
          createChatBotMessage(languages.EN.messages.chatbot.welcome, {}),
          createChatBotMessage(languages.EN.messages.chatbot.disclaimer, {
            delay: 1000,
          }),
          createCustomMessage('', 'html', {
            payload: {
              message: languages.EN.messages.chatbot.firstSelectContext,
              delay: 2000,
              loading: true,
              withAvatar: false,
            },
            widget: "typeSelector",
          }),
        ],
        state: {
          selectedType: undefined,
        },
        widgets: [
          {
            widgetName: "typeSelector",
            widgetFunc: (props) => <TypeSelector {...props} />,
            mapStateToProps: ["selectedType"]
          },
        ],
        customComponents: {
          header: () => (
            <div className="react-chatbot-kit-chat-header">AIDIFY AI Assistant<br/><span onClick={onClose}>✕</span></div>
          ),
          botAvatar: () => (
            <div className="react-chatbot-kit-chat-bot-avatar">
              <div className="react-chatbot-kit-chat-bot-avatar-container">
                <AidifyLogo />
              </div>
            </div>
          ),
          userAvatar: () => (
            <div className="react-chatbot-kit-user-avatar">
              <div className="react-chatbot-kit-user-avatar-container">
                <RoundImage size={40} image={userData.profile_image_small} />
              </div>
            </div>
          ),
        },
        customMessages: {
          html: (props) => <HtmlMessage {...props} />,
        },
      }}
      messageParser={MessageParser}
      actionProvider={ActionProvider}
      validator={(input) => input.length > 0}
    />
  )
    ;
};

export default AidifyChatbot;