import React, { useEffect, useState } from "react";
import BadgesHistoryItem from "../BadgesHistoryItem/BadgesHistoryItem";
import BadgesHistoryItemTile from "../BadgesHistoryItemTile/BadgesHistoryItemTile";
import { languages } from "../../../dummyData/dummyData";
import { dateFormat } from "../../../utils/formatters";
import FilteredList from "../FilteredList/FilteredList";
import { useHistory } from "react-router-dom";

const filterDefinitions = [
  {
    keyPath: "badge.title",
    typeOfFilter: "checkbox",
    header: languages.EN.labels.badge,
    sortId: 1,
  },
  {
    keyPath: "level",
    typeOfFilter: "range",
    header: languages.EN.labels.level,
  },
  {
    keyPath: "userName",
    header: languages.EN.labels.user,
    sortId: 3,
  },
  {
    keyPath: "created",
    typeOfFilter: "datePicker",
    header: languages.EN.labels.date,
    sortId: 4,
  },
  {
    keyPath: "badge.description",
  },
];

const BadgesHistoryList = ({ id, data, withManagement, viewMoreRoute }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map(userBadges => userBadges.badges
    .filter(badgeLevels => badgeLevels.levels.length > 0)
    .map(badgeLevels => badgeLevels.levels.map(level => ({
      userName: `${userBadges.profile.name} ${userBadges.profile.surname}`,
      badge: badgeLevels.badge,
      profile: userBadges.profile,
      level: level.level,
      created: dateFormat(level.created),
    })))).flat(2)), [data]);

  let history = useHistory();

  return (
    <FilteredList
      id={`badgesHistory${id[0].toUpperCase() + id.slice(1)}`}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={BadgesHistoryItem}
      RenderTileComponent={BadgesHistoryItemTile}
      buttons={withManagement ? [
        {
          icon: "lookEye",
          label: languages.EN.labels.viewMore,
          onClick: () => history.push(viewMoreRoute),
        },
      ] : []}
    />
  );
};

export default BadgesHistoryList;
