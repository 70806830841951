import styles from "./TopBanner.module.scss";
import Text from "../../atoms/Text/Text";
import IMAGES from "../../../assets/topBanner";
import { Link } from "react-router-dom";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { languages } from "../../../dummyData/dummyData";
import Html from "../../atoms/Html/Html";
import RedBar from "../../atoms/ClickBars/RedBar/RedBar";
import TopBannerButtons from "../TopBannerButtons/TopBannerButtons";

const TopBanner = ({ path, header, desc, img, imgSquare, inactive, infos, buttons, alert }) => {
  const { handleSwitchHomepage } = useContext(UserContext);
  const [counter, setCounter] = useState(Math.floor(Math.random() * IMAGES.length));

  const preparedButtons = (buttons ?? []).filter(button => button.visible !== false);
  const preparedInfos = (infos ?? []).filter(info => info.visible !== false);

  useEffect(() => {
    let interval = setInterval(() => {
      setCounter((prev) => (prev === IMAGES.length - 1 ? 0 : prev + 1));
    }, 5000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <div className={styles.topBanner}>
      {IMAGES.map((img, idx) => (
        <img
          className={styles.topBanner__bgImage}
          key={`img${idx}`}
          src={img}
          alt="Top Banner Img"
          data-isvisible={idx === counter}
        />
      ))}
      <div className={styles.topBanner__content}>
        <div className={styles.topBanner__textData}>
          <div className={styles.topBanner__path}>
            {path && (
              <>
                <span onClick={() => handleSwitchHomepage()}>
                  <Text s14 lh16 w300 light-t>
                    {languages.EN.labels.homepage}&nbsp;
                  </Text>
                </span>
                {path.map((item, i) => (
                  <Link key={i} to={item.link}>
                    <Text s14 lh16 w300 light-t>
                      &gt; {item.name}&nbsp;
                    </Text>
                  </Link>
                ))}
              </>
            )}
          </div>

          <div className={styles.topBanner__header}>
            <TextTruncated lineClamp={1} topBanner>
              <Text s48 w600 white>
                {header}
              </Text>
            </TextTruncated>
            {preparedButtons.length > 0 && (
              <div className={styles.topBanner__buttons}>
                <TopBannerButtons buttons={preparedButtons} />
              </div>
            )}
            {alert && (
              <div>
                <RedBar text={alert.text} buttonText={alert.buttonText} onClick={alert.onClick}/>
              </div>
            )}
          </div>

          {(preparedInfos.length > 0 || alert) && (
            <div className={styles.topBanner__informations}>
              {preparedInfos.map((info, i) => (
                <div className={styles.topBanner__informations__information} key={i}>
                  <Text s14 w600 lh16 light>
                    {info.label}
                  </Text>
                  <TextTruncated lineClamp={1} topBanner>
                    <Text s12 w300 lh24 light-t>
                      {info.value ?? languages.EN.placeholders.empty}
                    </Text>
                  </TextTruncated>
                </div>
              ))}
            </div>
          )}

          {desc && (
            <div className={styles.topBanner__description}>
              <TextTruncated lineClamp={4} topBanner>
                <Html s18 w300 white lh30>
                  {desc}
                </Html>
              </TextTruncated>
            </div>
          )}
        </div>

        {img && (
          <div className={`${styles.topBanner__image} ${imgSquare ? styles.square : ""} ${inactive ? styles.inactive : ""}`}>
            <img src={img} alt="" />
            {inactive && (<div className={styles.topBanner__image__imgInactiveCover}>
              <Text s32 stroke w800>
                {languages.EN.labels.inactive.toUpperCase()}
              </Text>
            </div>)}
          </div>
        )}

      </div>
    </div>
  );
};

export default TopBanner;
