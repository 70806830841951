import React from "react";
import styles from "./BadgeLevelsTypeCell.module.scss";
import ProportionsBar from "../../../../atoms/CompetenceVerificationTypeBar/ProportionsBar";
import Text from "../../../../atoms/Text/Text";
import { languages } from "../../../../../dummyData/dummyData";

export const TYPE_ALL = "all";
export const TYPE_LEARNING = "learning";
export const TYPE_ACTIVITY = "activity";
export const TYPE_SOCIAL = "social";

export const getValueFromData = (data, type) => {
  switch (type) {
    case TYPE_LEARNING:
      return data.learningBadgesCount;
    case TYPE_ACTIVITY:
      return data.activityBadgesCount;
    case TYPE_SOCIAL:
      return data.socialBadgesCount;
    case TYPE_ALL:
    default:
      return data.total_badge_levels;
  }
}

const BadgeLevelsTypeCell = ({ data, barConfig, max }) => {
  return (
    <div className={styles.wrapper}>
      <Text dark-2 s12 w600 lh24>
        {languages.EN.labels.total}: {getValueFromData(data, TYPE_ALL)}
      </Text>
      <ProportionsBar
        config={barConfig}
        getValue={(type) => getValueFromData(data, type)}
        max={max}
      />
    </div>
  );
};

export default BadgeLevelsTypeCell;
