import { languages } from "../../../dummyData/dummyData";
import TileItem from "../TileItem/TileItem";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import VerificationBar from "../../molecules/VerificationBar/VerificationBar";
import { PROGRESS_BAR_SETTINGS } from "../CourseAuditsList/CourseAuditsList";

const RelatedCourseAuditItemTile = ({ data }) => {
  const { course, instructors, actionRequiredText, dateText, status, statusText } = data;

  let history = useHistory();

  return (
    <TileItem
      title={course.display_name}
      image={course.profile_image_small}
      bar={(
        <VerificationBar
          text={statusText}
          progress={PROGRESS_BAR_SETTINGS[status].progress}
          variant={PROGRESS_BAR_SETTINGS[status].variant}
          inTile
        />
      )}
      buttons={[
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.courseDetails.audits.base, { id: course.id })),
          tooltip: languages.EN.labels.details,
        },
      ]}
    >
      <TileItemInfo header={languages.EN.labels.actionRequired} value={actionRequiredText} />
      <TileItemInfo header={languages.EN.labels.deadlineDate} value={dateText} />
      {instructors.map((instructor, i) => (
        <TileItemImgInfo key={i} header={languages.EN.labels.instructor} value={instructor.name} image={instructor.img} />
      ))}
    </TileItem>
  );
};

export default RelatedCourseAuditItemTile;
