import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import styles from "./PopupAssignCompetition.module.scss";
import { homePageRepository } from "../../../../repository/homePage.repository";
import { languages } from "../../../../dummyData/dummyData";
import { useContext } from "react";
import { PostContext } from "../../../../contexts/PostContext";

const PopupAssignCompetition = ({
  header,
  competitionType,
  setIsSignedup,
}) => {
  const { postData } = useContext(PostContext);

  const handleOnSave = () => {
    return homePageRepository.assignToCompetition(competitionType.id);
  };

  const handleSignedUp = () => {
    setIsSignedup(true);
  };

  return (
    <>
      <Text>{header}</Text>
      <div className={styles.Wrapper}>
        <Text dark-4 s12>
          {languages.EN.labels.areYueSureYouWantToAssignTo}
        </Text>
        <Text dark-4 s12 w700>
          {competitionType.name}
        </Text>
        <Text s12 dark-4>
          ?
        </Text>
      </div>
      <SaveBackButtons onSave={() => postData(handleOnSave, handleSignedUp)} saveLabel={languages.EN.labels.confirm} />
    </>
  );
};

export default PopupAssignCompetition;
