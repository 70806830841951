import styles from "./Button.module.scss";
import filterIcon from "../../../assets/icons/filter.svg";
import workgroup from "../../../assets/icons/workgroup.svg";
import arrow from "../../../assets/icons/Arrow.svg";
import hamburger from "../../../assets/icons/hamburger.svg";
import topArrow from "../../../assets/icons/topArrow.svg";
import edit from "../../../assets/icons/edit.svg";
import settings from "../../../assets/icons/settings.svg";
import garbage from "../../../assets/icons/garbage.svg";
import plus from "../../../assets/icons/plus2.svg";
import plane from "../../../assets/icons/plane.svg";
import send from "../../../assets/icons/send.svg";
import play from "../../../assets/icons/play.svg";
import save from "../../../assets/icons/save.svg";
import share from "../../../assets/icons/share.svg";
import download from "../../../assets/icons/download.svg";
import badge from "../../../assets/icons/badge.svg";
import like from "../../../assets/icons/like.svg";
import starRecommend from "../../../assets/icons/starRecommend.svg";
import smallPlus from "../../../assets/icons/smallPlus.svg";
import smallMinus from "../../../assets/icons/smallMinus.svg";
import check from "../../../assets/icons/check.svg";
import downloadWhite from "../../../assets/icons/downloadWhite.svg";
import lookEye from "../../../assets/icons/lookEye.svg";
import form from "../../../assets/icons/form.svg";
import svg from "../../../assets/icons";
import checkMark from "../../../assets/icons/checkMark.svg";
import notes from "../../../assets/icons/notes.svg";
import Text from "../Text/Text";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";

const btnClassNames = {
  primary: styles.btnPrimary,
  primaryLight: styles.btnPrimaryLight,
  primaryLow: styles.btnPrimaryLow,
  primaryLowLight: styles.btnPrimaryLowLight,
  text: styles.btnText,
  navBarButton: styles.navBarButton,
  iconButton: styles.iconButton,
  iconButtonOn: styles.iconButtonOn,
  iconButtonOff: styles.iconButtonOff,
  strokeIcon: styles.strokeIcon,
  warningBtn: styles.warningBtn,
  primaryW100: styles.btnPrimaryW100,
  primaryH40: styles.btnPrimaryH40,
  primaryLightW100: styles.btnPrimaryLightW100,
  primaryLightH40: styles.btnPrimaryLightH40,
  primaryInnerText: styles.primaryInnerText,
  crossButton: styles.crossButton,
  primaryDisabled: styles.primaryDisabled,
};

const Icons = {
  filterIcon: filterIcon,
  workgroup: workgroup,
  arrow: arrow,
  hamburger: hamburger,
  topArrow: topArrow,
  edit: edit,
  settings: settings,
  garbage: garbage,
  add: plus,
  form: form,
  plane: plane,
  cancel: svg.cancel,
  send: svg.send,
  send2: send,
  play: play,
  save: save,
  share: share,
  download: download,
  play2: svg.play2,
  like: like,
  reward: badge,
  starRecommend: starRecommend,
  smallPlus: smallPlus,
  smallMinus: smallMinus,
  check: check,
  downloadWhite: downloadWhite,
  lookEye: lookEye,
  checkMark: checkMark,
  notes: notes,

};

const Button = ({
  children,
  variant,
  withIcon,
  onlyIcon,
  icon,
  isOpen,
  onClick,
  showQuantity,
  disabled,
  tooltip,
  useIconsFile, // TODO: remove direct files, migrate everything to icons file
}) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <a data-tip={true} data-tooltip-id={id} className={styles.link}>
      <button disabled={disabled} className={btnClassNames[variant]} onClick={onClick}>
        {withIcon ? (
          <>
            {children}
            {showQuantity && showQuantity.length > 0 ? ` (${showQuantity.length})` : ""}
            {useIconsFile ? svg[icon] : (<img src={Icons[icon]} alt={`${icon} Icon`} data-isopen={isOpen} />)}
          </>
        ) : (
          <>
            {onlyIcon && svg[icon]}
            {children}
          </>
        )}
      </button>
      {tooltip && (
        <Tooltip id={id} place="top" variant="light" float={true} className={styles.tooltip}>
          <Text s12 lh18 dark-2>
            {tooltip}
          </Text>
        </Tooltip>
      )}
    </a>
  );
}
export default Button;
