import styles from "./SelectPopup.module.scss";
import React, { useContext, useEffect, useState } from "react";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { languages } from "../../../../dummyData/dummyData";
import FilterPanel from "../../../organisms/FilterPanel/FilterPanel";
import Checkbox from "../../../atoms/Checkbox/Checkbox";
import SelectedItem from "../../../atoms/SelectedItem/SelectedItem";
import { FilterContext } from "../../../../contexts/FilterContext";
import RadioButton from "../../../atoms/RadioButton/RadioButton";
import SearchInput from "../../../atoms/SearchInput/SearchInput";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { PostContext } from "../../../../contexts/PostContext";

const SelectPopup = ({ header, filters, singleSelect, items, RenderItem, onSave, postAction }) => {
  const { callPopup } = useContext(PopupsContext);
  const { postData, isPostFinish } = useContext(PostContext);

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish]);

  const [selected, setSelected] = useState(items.filter((item) => item.checked).map((item) => item.id));

  const handleCheckbox = (e) => {
    const id = e.target.value;
    if (selected.includes(id)) {
      handleDeleteSelected(id);
    } else if (singleSelect) {
      setSelected([id]);
    } else {
      setSelected([...selected, id]);
    }
  };
  const handleDeleteSelected = (deleted) => {
    setSelected([...(selected.filter((item) => item !== deleted.toString()))]);
  };

  const { useOriginalData, useFilterDefinitions, useProcessedData } = useContext(FilterContext);
  const { setOriginalData } = useOriginalData;
  const { setFilterDefinitions } = useFilterDefinitions;
  const { processedData } = useProcessedData;

  useEffect(() => {
    if (items) {
      setOriginalData(items);
    }
  }, [items]);

  useEffect(() => {
    if (filters) {
      setFilterDefinitions(filters);
    }
  }, [filters]);

  return (
    <>
      <Text s18 w700 lh21>
        {header}
      </Text>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <FilterPanel inPopup />
        </div>
        <div className={styles.rightWrapper}>
          <SearchInput />
          <div className={styles.selectedWrapper}>
            {selected.map((selectedId, i) => {
              const item = items.find(item => item.id.toString() === selectedId);

              return (
                <SelectedItem
                  key={i}
                  text={<RenderItem item={item} />}
                  item={item}
                  onClick={handleDeleteSelected}
                />
              )
            })}
          </div>
          {processedData?.length > 0 ? (
            <div className={styles.listWrapper}>
              {processedData && processedData.map((item, i) => (
                <label key={i} className={`${item.disabled ? styles.disabled : ''}`}>
                  {singleSelect ?
                    <RadioButton variant="primary" checked={selected.includes(item.id.toString())} handleChange={handleCheckbox} value={item.id} /> :
                    <Checkbox checked={selected.includes(item.id.toString())} disabled={item.disabled} handleChange={handleCheckbox} value={item.id} />
                  }
                  <RenderItem item={item} />
                </label>
              ))}
            </div>
          ) : (
            <div className={styles.information}>
              <Text s16 lh21 dark-2>
                {languages.EN.labels.thereAreNoResults}
              </Text>
            </div>
          )}
        </div>
      </div>
      <SaveBackButtons onSave={() => onSave ? onSave(selected) : postData(() => postAction(selected))} isError={selected.length <= 0} onBack={() => callPopup({ type: ACTIONS.REMOVE })} />
    </>
  );
};

export default SelectPopup;
