import useFetch from "../../../hooks/useFetch";
import { idpRepository } from "../../../repository/idp.repository";
import { useContext, useEffect } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import IdpsTemplate from "../../templates/IdpsTemplate/IdpsTemplate";
import { PostContext } from "../../../contexts/PostContext";

const Idps = ({ match }) => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);
  const { userData } = useContext(UserContext);
  const username = match.params.username ?? userData.username;

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(idpRepository.getIdp(username));
    }
  }, [isResponseOk]);

  return data ? (
    <IdpsTemplate idp={data} username={username} />
  ) : (
    <Loader />
  );
};

export default Idps;
