import { workgroup_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getWorkgroups = () => {
  return fetch(workgroup_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getWorkgroup = (id) => {
  return fetch(`${workgroup_endpoint}${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addWorkgroup = (data) => {
  return fetch(workgroup_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editWorkgroup = (id, name, description, img) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("name", name);
  formData.append("description", description);
  formData.append("profile_image", img);

  return fetch(`${workgroup_endpoint}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: formData,
  });
};

const deleteWorkgroup = (id) => {
  return fetch(`${workgroup_endpoint}${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const assignToWorkgroup = (data) => {
  return fetch(`${workgroup_endpoint}assign/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const unassignFromWorkgroup = (data) => {
  return fetch(`${workgroup_endpoint}unassign/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const workgroupsRepository = {
  getWorkgroups,
  getWorkgroup,
  addWorkgroup,
  editWorkgroup,
  deleteWorkgroup,
  assignToWorkgroup,
  unassignFromWorkgroup,
};
