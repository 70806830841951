import {
  learning_report_endpoint,
  learning_report_context_endpoint,
} from "../constants/endpoints";
import { getAuthorizationHeader } from "./repositoryHelper";

const getLearningReport = (data) => {
  return fetch(`${learning_report_endpoint}?${new URLSearchParams(data)}`,
    {
      method: "GET",
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );
};

const getLearningReportContext = (id) => {
  return fetch(learning_report_context_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const learningReportRepository = {
  getLearningReport,
  getLearningReportContext,
};
