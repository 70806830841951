import correct from "../../../assets/icons/correct.svg";
import incorrect from "../../../assets/icons/incorrect.svg";
import styles from "./ConditionFulfillment.module.scss";
import Text from "../Text/Text";

const ConditionFulfillment = ({ condition, isFulfilled }) => {
  return (
    <span className={styles.requirement}>
      <img src={isFulfilled ? correct : incorrect} alt="" />
      <Text s12 lh14>
        {condition}
      </Text>
    </span>
  );
};

export default ConditionFulfillment
