export const TYPE_REGISTRATION = "registration";
export const TYPE_LOGIN = "login";
export const TYPE_USER_RANK = "user_rank";
export const TYPE_ADD_COURSE_SUGGESTION = "add_course_suggestion";
export const TYPE_ADD_REVIEW = "add_review";
export const TYPE_SPEND_MINUTES = "spend_minutes";
export const TYPE_COURSES_COMPLETED = "courses_completed";
export const TYPE_COURSES_COMPLETED_TIME = "courses_completed_time";
export const TYPE_COURSE_QAS_JOINED = "course_qas_joined";
export const TYPE_COMPETENCE_GROUP = "competence_group";
export const TYPE_PROTEGE = "protege";
export const TYPE_CORRECT_ANSWERS = "correct_answers";
export const TYPE_REGISTER_LE = "register_le";
export const TYPE_IDP_PREMATURE = "idp_premature";
export const TYPE_IDPS = "idps";
export const TYPE_SEND_MESSAGE = "send_message";
export const TYPE_FORUM_POST_ADDED = "forum_post_added";
export const TYPE_ENDORSEMENTS = "endorsements";
export const TYPE_SOCIAL_STARS = "social_stars";

export const TYPES_LEARNING = [TYPE_COURSES_COMPLETED, TYPE_COURSES_COMPLETED_TIME, TYPE_COURSE_QAS_JOINED, TYPE_COMPETENCE_GROUP, TYPE_CORRECT_ANSWERS, TYPE_REGISTER_LE, TYPE_IDP_PREMATURE, TYPE_PROTEGE, TYPE_IDPS];
export const TYPES_ACTIVITY = [TYPE_REGISTRATION, TYPE_LOGIN, TYPE_USER_RANK, TYPE_ADD_COURSE_SUGGESTION, TYPE_ADD_REVIEW, TYPE_SPEND_MINUTES];
export const TYPES_SOCIAL = [TYPE_SEND_MESSAGE, TYPE_FORUM_POST_ADDED, TYPE_ENDORSEMENTS, TYPE_SOCIAL_STARS];
