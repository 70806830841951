import React, { useContext } from "react";
import employees from "../../../assets/icons/statistics/employees.svg";
import licence from "../../../assets/icons/statistics/licence.svg";
import certificate from "../../../assets/icons/statistics/certificate.svg";
import timeOnPlatform from "../../../assets/icons/statistics/timeOnPlatform.svg";
import laptop from "../../../assets/icons/statistics/laptop.svg";
import dollar from "../../../assets/icons/statistics/dollar.svg";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";
import { currencyFormat, durationFormat, durationInHours } from "../../../utils/formatters";
import Statistics from "../Statistics/Statistics";
import { UserContext } from "../../../contexts/UserContext";
import { SCOPE_LEARNING_REPORT } from "../../../constants/scopes";

const TeamStatistics = ({ data, teamId }) => {
  const { avg_user_time, num_licenses, num_certificates, team_members, total_user_time, savings, name } = data;
  const { userData } = useContext(UserContext);
  const history = useHistory();

  return (
    <Statistics
      data={[
        {
          icon: employees,
          content: languages.EN.labels.employees,
          value: team_members && team_members.length,
        },
        {
          icon: licence,
          content: languages.EN.labels.activeLicenses,
          value: num_licenses,
        },
        {
          icon: certificate,
          content: languages.EN.labels.obtainedCertificates,
          value: num_certificates,
        },
        {
          icon: timeOnPlatform,
          content: languages.EN.labels.timeSpentOnPlatformByEmployees,
          value: durationInHours(total_user_time),
        },
        {
          icon: laptop,
          content: languages.EN.labels.averageTimeSpentOnPlatformByEmployee,
          value: durationFormat(avg_user_time),
        },
        {
          icon: dollar,
          content: languages.EN.labels.aproximateSavingsForOrganization,
          value: currencyFormat(savings),
        },
      ]}
      buttons={userData.scopes.includes(SCOPE_LEARNING_REPORT) ? [
        {
          label: languages.EN.labels.viewMore,
          icon: "lookEye",
          onClick: () => history.push(generatePath(Routes.learningReport.competences.base, { type: 1, id: teamId })),
        },
      ] : []}
    />
  );
};

export default TeamStatistics;
