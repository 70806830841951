import StudentCompetencesTemplate from "../../templates/StudentCompetencesTemplate/StudentCompetencesTemplate";
import useFetch from "../../../hooks/useFetch";
import { UserContext } from "../../../contexts/UserContext";
import { useContext, useEffect } from "react";
import { competencesRepository } from "../../../repository/competences.repository";
import { userRepository } from "../../../repository/user.repository";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { PostContext } from "../../../contexts/PostContext";

const StudentCompetences = () => {
  const { isResponseOk } = useContext(PostContext);
  const { userData } = useContext(UserContext);

  const fetchStudentCompetences = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchStudentCompetences.data) {
      fetchStudentCompetences.fetchData(userRepository.getCompetences(userData.username));
    }
  }, [isResponseOk]);

  const fetchCompetences = useFetch();
  useEffect(() => {
    fetchCompetences.fetchData(competencesRepository.getCompetences());
  }, [])

  useDocumentTitleLoading();

  return fetchStudentCompetences.data && fetchCompetences.data ? (
    <FilterContextProvider>
      <StudentCompetencesTemplate userCompetences={fetchStudentCompetences.data} allCompetences={fetchCompetences.data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default StudentCompetences;
