import styles from "./MainTemplate.module.scss";
import { concatClassNames } from "../../../../utils";

const MainTemplate = ({ children, ...props }) => {
  props = { main: true, ...props };

  return (
    <div className={concatClassNames(props, styles)}>
      {children}
    </div>
  );
};

export default MainTemplate
