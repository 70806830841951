import { useContext, useMemo } from "react";
import FilterCheckbox from "../FilterCheckbox/FilterCheckbox";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import { FilterContext } from "../../../contexts/FilterContext";
import findItemValueByPath from "../../../utils/FiltersMethods/FilterByPathName";
import handleFilter from "../../../utils/FiltersMethods/handleFilter";
import { createCheckboxCriterion } from "../../../utils/FiltersMethods/generateCheckboxes";

const FilterCheckboxes = ({ definition }) => {
  const { typeOfFilter, keyPath, header } = definition;
  const { useFilterCriteria, useOriginalData } = useContext(FilterContext);
  const { originalData } = useOriginalData;
  const { filterCriteria, setFilterCriteria } = useFilterCriteria;

  const originalDataChoices = useMemo(() => {
    const result = [];
    originalData && originalData.forEach((item) => {
      findItemValueByPath(item, keyPath, result);
    });
    return result;
  }, [originalData])

  const customFilteredDataChoices = useMemo(() => {
    const criteriaExcludingCurrent = filterCriteria.filter(criterion => criterion.group !== header);
    const criteriaPathsCount = criteriaExcludingCurrent.map(criterion => criterion.path).filter((value, index, array) => array.indexOf(value) === index).length;
    const customFilteredData = handleFilter(originalData, criteriaExcludingCurrent, criteriaPathsCount);

    const result = [];
    customFilteredData && customFilteredData.forEach((item) => {
      findItemValueByPath(item, keyPath, result);
    });
    return result;
  }, [originalData, filterCriteria]);

  const items = useMemo(() => {
    const criteriaForCurrent = filterCriteria.filter(criterion => criterion.group === header);

    let counts = Object.fromEntries(originalDataChoices
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(choice => [choice, 0])
    );
    customFilteredDataChoices.forEach(function (choice) {
      counts[choice] = counts[choice] + 1;
    });

    return Object
      .entries(counts)
      .map(([choice, count]) => ({
        name: choice === "undefined" ? null : choice,
        number: count,
        isChecked: criteriaForCurrent.find(criterion => criterion.name === choice) !== undefined,
        criterion: createCheckboxCriterion(keyPath, header, typeOfFilter, choice),
      }));
  }, [originalData, filterCriteria]);

  const handleOnChange = (item, e) => {
    let newCriteria = filterCriteria;
    if (e.target.checked) {
      newCriteria.push(item.criterion);
    } else {
      newCriteria = newCriteria.filter((el) => el.type_id !== item.criterion.type_id);
    }
    setFilterCriteria([...newCriteria]);
  };

  return (
    <>
      <Text s14 w700 dark-4>
        {header}
      </Text>
      {items.length > 0 ? items.map((item) => (
        <FilterCheckbox key={item.name} item={item} handleChange={(e) => handleOnChange(item, e)}>
          {item.name}
        </FilterCheckbox>
      )) : (
        <Text s12 dark-4>
          {languages.EN.labels.noData}
        </Text>
      )}
    </>
  );
};

export default FilterCheckboxes;
