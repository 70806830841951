import React, { useContext } from "react";
import { languages } from "../../../dummyData/dummyData";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { Routes } from "../../../routes";
import InfoPage from "../../organisms/InfoPage/InfoPage";
import Text from "../../atoms/Text/Text";
import Html from "../../atoms/Html/Html";
import Button from "../../atoms/Button/Button";
import { generatePath, useHistory } from "react-router-dom";
import Level from "../../atoms/Level/Level";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { learningEventsRepository } from "../../../repository/learningEvents.repository";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { defaultUserPhoto } from "../../../constants/assets";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { currencyFormat, dateFormat } from "../../../utils/formatters";
import LinkedinButton from "../../atoms/LinkedinButton/LinkedinButton";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";

const LearningEventDetailsTemplate = ({ data }) => {
  const { id, name, profile_image_medium, date, price, duration, location, language, topic, level, details_url, description, competence_levels, job_positions } = data;
  const organizator = data.organization !== null ? data.organization : {
    name: data.organization_text,
    profile_image: data.organization_image,
  };
  const instructor = data.instructor !== null ? data.instructor : {
    name: data.instructor_text,
    profile_image: undefined,
  };

  const getRelatedItemType = (item) => {
    return item.organization !== null ? 2 : 3;
  }

  const getRelatedItemImg = (type, item) => {
    return type === 2 ? item.organization.profile_image_small : <AidifyLogo />;
  }

  let history = useHistory();

  useDocumentTitle(`${name} | ${languages.EN.labels.learningEventDetails}`);

  const { callPopup } = useContext(PopupsContext);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={name}
        path={[
          {
            name: languages.EN.labels.learningEvents,
            link: Routes.learningEvents.base,
          },
          {
            name: languages.EN.labels.learningEventDetails,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <InfoPage
          image={profile_image_medium}
          columnHeader={`${languages.EN.labels.date}: ${dateFormat(date)}`}
          columnHeaderButtons={[
            {
              icon: "calendar2",
              onClick: () => callPopup({
                type: ACTIONS.POST_CONFIRM,
                payload: {
                  header: languages.EN.labels.confirmation,
                  content: languages.EN.messages.addToCalendar,
                  action: () => learningEventsRepository.sendCalendarEvent(id),
                },
              }),
              tooltip: languages.EN.labels.addToCalendar,
            },
          ]}
          columnItems={[
            { header: languages.EN.labels.price, value: currencyFormat(price) },
            { header: languages.EN.labels.duration, value: `${duration} ${languages.EN.labels.minutes}` },
            { header: languages.EN.labels.location, value: location },
            { header: languages.EN.labels.language, value: languages.EN.enums.languages[language] },
            { header: languages.EN.labels.learningArea, value: languages.EN.enums.learningAreas[topic] },
            { header: languages.EN.labels.experienceLevel, value: languages.EN.enums.experienceLevels[level] },
          ]}
          columnFooter={[
            {
              header: languages.EN.labels.instructor,
              image: instructor.profile_image ?? defaultUserPhoto,
              value: `${instructor.name}<br />${instructor.position ?? ''}`,
              buttons: []
                .concat(instructor.linkedin_url ? [(<LinkedinButton url={instructor.linkedin_url} />)] : [])
                .concat(instructor.id ? [(
                  <Button
                    variant="iconButton"
                    onlyIcon
                    icon="dots"
                    key="dots"
                    onClick={() => history.push(generatePath(Routes.instructorDetails.base, { id: instructor.id }))}
                    tooltip={languages.EN.labels.details}
                  />
                )] : [])
            },
            {
              header: languages.EN.labels.organizer,
              image: organizator.profile_image,
              value: organizator.name,
            }
          ]}
          mainFooter={[
            {
              header: languages.EN.labels.relatedCompetences, list: competence_levels.map((competence_level) => (
                <>
                  <Text s12 lh24 dark-2>{competence_level.competence.name}</Text>
                  <Level level={competence_level.level} small withoutNumber />
                  <RoundImage image={getRelatedItemImg(getRelatedItemType(competence_level.competence), competence_level.competence)} size={20} />
                </>
              ))
            },
            {
              header: languages.EN.labels.relatedJobPositions, list: job_positions.map((job_position) => (
                <>
                  <Text s12 lh24 dark-2>{job_position.name}</Text>
                  <RoundImage image={getRelatedItemImg(getRelatedItemType(job_position), job_position)} size={20} />
                </>
              ))
            },
          ]}
        >
          <HeaderWithButtons header={languages.EN.labels.about} buttons={[
            { icon: "form", label: languages.EN.labels.register, onClick: () => window.open(details_url, '_blank') }
          ]} />
          <Html s12 lh24 dark-6>
            {description}
          </Html>
        </InfoPage>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default LearningEventDetailsTemplate;
