import React from "react";
import styles from "./CompoundTableHeader.module.scss";
import TableHeader from "../TableHeader/TableHeader";

const CompoundTableHeader = ({ config, type, setType, isSorted, isSortedDesc, toggle }) => {
  const handleClick = (item) => {
    new Promise((res) => {
      setType(item);
      return res();
    }).then(() => {
      toggle();
    });
  };

  return (
    <div className={styles.wrapper} style={{ gridTemplateColumns: config.gridTemplateColumns, gridTemplateAreas: config.gridTemplateAreas }}>
      {config.items.map((item, i) => (
        <div key={i} className={styles.header} style={{ gridArea: item.type }} onClick={() => handleClick(item.type)}>
          <TableHeader column={{
            isSortedDesc: type === item.type && isSortedDesc,
            isSorted: type === item.type && isSorted,
            headerStyle: item.headerStyle,
            headerMarkerColor: item.headerMarkerColor,
          }}>
            {item.label}
          </TableHeader>
        </div>
      ))}
    </div>
  );
};

export default CompoundTableHeader;
