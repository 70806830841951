import { useState, useEffect } from "react";
import Text from "../Text/Text";
import styles from "./TestSelect.module.scss";
import Icons from "../../../assets/icons";

const TestSelect = ({
  answers,
  disabled,
  dataActive,
  setOurAnswer,
  ourAnswer,
  id,
  currentAnswer,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [displayName, setDisplayName] = useState(null);

  const handleIsActive = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  const handleActiveData = (idx) => {
    setOurAnswer({ ...ourAnswer, [id]: idx });
  };

  useEffect(() => {
    setDisplayName(answers[currentAnswer]);
  }, [answers, currentAnswer]);

  return (
    <>
      <div
        data-active={dataActive}
        onClick={handleIsActive}
        className={styles.select}
      >
        <Text s14 w400 dark-4>
          {displayName}
        </Text>

        <div className={styles.sortArrow}>
          {isActive ? Icons.topArrowSortPanel : Icons.bottomArrowSortPanel}
        </div>

        {isActive && (
          <div className={styles.table}>
            {answers.map((item, idx) => (
              <div
                key={idx}
                onClick={() => {
                  setIsActive(false);
                  setDisplayName(item);
                  handleActiveData(idx);
                }}
              >
                <Text s14 w400 dark-4>
                  {item}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TestSelect;
