import React, { createContext, useCallback, useEffect, useState, } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { viewContextRepository } from "../repository/viewContext.repository";
import { Routes } from "../routes";
import Cookies from "universal-cookie/es6";
import { verifyResponseCode } from "../utils/errorHandling";

export const UserContext = createContext();

const cookies = new Cookies();

const getStoredContextType = () => {
  return parseInt(localStorage.getItem("context_type"));
}

const getStoredContextId = () => {
  return parseInt(localStorage.getItem("context_id"));
}

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isContextViewReload, setIsContextViewReload] = useState(false);
  const history = useHistory();
  const [userContextLevel, setUserContextLevel] = useState(null);
  const [isWaitingForContext, setIsWaitingForContext] = useState(true);
  const [flashMessages, setFlashMessages] = useState([]);
  const [flashMessagesAwaiting, setFlashMessagesAwaiting] = useState(false);

  const hasToken = () => {
    const token = cookies.get("jwttoken");

    return !!token;
  };

  const switchHomepage = (contextType, contextId) => {
    let path;

    switch (contextType) {
      case 0:
        path = Routes.homepage.base;
        break;
      case 1:
        path = generatePath(Routes.team.base, { id: contextId });
        break;
      case 2:
        path = generatePath(Routes.organization.base, { id: contextId });
        break;
      case 3:
        path = Routes.platformDashboard.base;
        break;
      case 4:
        path = Routes.instructorDashboard.base;
        break;
      default:
        path = Routes.mainPage.base;
        break;
    }

    history.push(generatePath(path));
  };

  const handleSwitchHomepage = (contextType = getStoredContextType(), contextId = getStoredContextId()) => {
    switchHomepage(contextType, contextId);
  };

  const handleIsWaitingForContext = useCallback((boolean) => {
    setIsWaitingForContext(boolean);
  }, []);

  useEffect(() => {
    if (userData) {
      handleIsWaitingForContext(false);
    } else {
      handleIsWaitingForContext(true);
    }
  }, [handleIsWaitingForContext, userData]);

  const getUserContext = (afterContextRefresh) => {
    setIsContextViewReload(true);
    return viewContextRepository
      .getViewContext()
      .then(verifyResponseCode)
      .then((data) => {
        if (afterContextRefresh) {
          afterContextRefresh(data);
        } else if (getStoredContextType() !== data.current.context_type || getStoredContextId() !== data.current.context_id) {
          switchHomepage(data.current.context_type, data.current.context_id);
        }
        setUserData(data);
        setUserContextLevel(data.current.context_type);
        localStorage.setItem("context_type", data.current.context_type);
        localStorage.setItem("context_id", data.current.context_id);
        setIsContextViewReload(false);
      })
      .catch((error) => {
        setIsContextViewReload(false);
        history.push({
          pathname: Routes.error.base,
          state: { message: error.message, cause: error.cause }
        });
      });
  };

  const sendUserContext = (contextType, contextId, afterContextRefresh) => {
    setIsContextViewReload(true);
    viewContextRepository
      .setViewContext({ context_type: contextType, context_id: contextId })
      .then(verifyResponseCode)
      .then(() => {
        getUserContext(afterContextRefresh);
      })
      .catch((error) => {
        setIsContextViewReload(false);
        history.push({
          pathname: Routes.error.base,
          state: { message: error.message, cause: error.cause }
        });
      });
  };

  const handleLogout = useCallback(() => {
    cookies.remove("jwttoken", { path: Routes.mainPage.base });
    history.push(Routes.mainPage.base);
  }, []);

  const isManagerOfTeam = (teamId) => {
    return userData.current.context_type === 1 && userData.current.context_id === teamId;
  }
  const addFlashMessage = useCallback((type, content) => {
    setFlashMessages((flashMessagesAwaiting ? [...flashMessages] : []).concat([{
      type: type,
      content: content,
    }]));
    setFlashMessagesAwaiting(true);
  }, []);

  const getFlashMessages = () => {
    let result = flashMessagesAwaiting ? flashMessages : [];

    setFlashMessagesAwaiting(false);

    return result;
  };

  useEffect(() => {
  }, [flashMessagesAwaiting]);

  return (
    <UserContext.Provider
      value={{
        userData,
        isContextViewReload,
        userContextLevel,
        isWaitingForContext,
        hasToken,
        handleLogout,
        setUserData,
        sendUserContext,
        getUserContext,
        handleIsWaitingForContext,
        handleSwitchHomepage,
        setIsWaitingForContext,
        isManagerOfTeam,
        useFlashMessages: { getFlashMessages, addFlashMessage, flashMessagesAwaiting },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
