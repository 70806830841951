import styles from "./IdpHistoryTable.module.scss";
import Button from "../../../atoms/Button/Button";
import React, { useContext, useMemo } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import { languages } from "../../../../dummyData/dummyData";
import Table from "../Table";
import { dateFormat } from "../../../../utils/formatters";
import Status, { VARIANT_GRAY, VARIANT_GREEN, VARIANT_RED, VARIANT_YELLOW } from "../../../atoms/Status/Status";
import { MODE_ADD } from "../../../pages/IdpEdit/IdpEdit";
import { UserContext } from "../../../../contexts/UserContext";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { idpRepository } from "../../../../repository/idp.repository";

const IdpHistoryTable = ({ reports }) => {
  const { data, username } = reports;
  const { userData } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);

  const statusToVariant = {
    0: VARIANT_GRAY,
    1: VARIANT_YELLOW,
    2: VARIANT_GREEN,
    3: VARIANT_RED,
  }

  const history = useHistory();

  const items = data.map((item) => ({
    ...item,
    createdBy: `${item.user.profile.name} ${item.user.profile.surname}`,
    period: item.status !== 0 ? `${dateFormat(item.start_date)} - ${dateFormat(item.end_date)}` : languages.EN.placeholders.empty,
    statusText: languages.EN.enums.idpStatus[item.status],
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.createdOn,
      accessor: "created",
      Cell: (data) => dateFormat(data.row.original.created),
    },
    {
      Header: languages.EN.labels.createdBy,
      accessor: "createdBy",
    },
    {
      Header: languages.EN.labels.period,
      accessor: "period",
    },
    {
      Header: languages.EN.labels.archivedOn,
      accessor: "archived",
      Cell: (data) => data.row.original.archived ? dateFormat(data.row.original.archived) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.jobPosition,
      accessor: "job_position.name",
    },
    {
      Header: languages.EN.labels.status,
      accessor: "statusText",
      Cell: (data) => (
        <Status
          variant={statusToVariant[data.row.original.status]}
          text={data.row.original.statusText.toUpperCase()}
        />
      ),
      cellStyle: { position: "relative" },
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(generatePath(Routes.idpDetails.career.base, { username: username, id: data.row.original.id }))}
            tooltip={languages.EN.labels.details}
          />
          {username === userData.username && data.row.original.status === 0 && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="garbage"
              onClick={() =>
                callPopup({
                  type: ACTIONS.DELETE,
                  payload: {
                    type: languages.EN.labels.individualDevelopmentPlan,
                    elementName: `${languages.EN.labels.createdOn} ${dateFormat(data.row.original.created)} ${languages.EN.labels.by} ${data.row.original.createdBy}`,
                    action: () => idpRepository.deleteIdp(data.row.original.id),
                  },
                })
              }
              tooltip={languages.EN.labels.details}
            />
          )}
        </div>
      ),
    },
  ], []);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => history.push(generatePath(Routes.idpEdit.career.base, { username: username, mode: MODE_ADD })),
    },
  ]} columns={columns} data={items} />;
}

export default IdpHistoryTable;
