import styles from "./InstructorTile.module.scss";
import Text from "../../atoms/Text/Text";
import React, { useState } from "react";
import Button from "../../atoms/Button/Button";
import { languages } from "../../../dummyData/dummyData";
import LinkedinButton from "../../atoms/LinkedinButton/LinkedinButton";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";

const InstructorTile = ({ instructor }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [name, surname] = instructor.name.split(" ");
  const history = useHistory();

  return (
    <div className={styles.instructorSwiper__slide} style={{ ['--image-url']: `url("${instructor.profile_image}")` }} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className={styles.instructorSwiper__slide__name} data-hovered={isHovered}>
        <Text s24 w800 secondary stroke>
          {name}
        </Text>
        <Text s32 w800 secondary stroke>
          {surname}
        </Text>
        <div className={styles.instructorSwiper__slide__buttons} data-visible={isHovered}>
          <Button
            icon="dots"
            variant="iconButton"
            onlyIcon
            onClick={() => history.push(generatePath(Routes.instructorDetails.base, { id: instructor.id }))}
            tooltip={languages.EN.labels.details}
          />
          <LinkedinButton url={instructor.linkedin_url} />
        </div>
      </div>
    </div>
  )
};

export default InstructorTile
