import styles from "./PopupDetailsGiftShop.module.scss";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { useContext } from "react";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { languages } from "../../../../dummyData/dummyData";

const PopupDetailsGiftShop = ({ gift, handleBonusPoints, cost }) => {
  const { callPopup } = useContext(PopupsContext);

  return (
    <>
      <Text s18 lh22 mb30>
        {languages.EN.labels.giftDetails}
      </Text>
      <div className={styles.wrapper}>
        <img className={styles.img} src={gift.profile_image} alt="" />
        <Text s20 w800 secondary>
          {gift.name}
        </Text>
        <Text s12 w600 lh36 dark-4>
          {gift.description}
        </Text>
      </div>
      <SaveBackButtons
        onSave={() => {
          callPopup({
            type: ACTIONS.BUY,
            payload: {
              header: languages.EN.labels.buy,
              elementType: languages.EN.labels.gift,
              elementName: gift.name,
              elementId: gift.id,
              cost: cost,
              handleBonusPoints: handleBonusPoints,
              status: "giftShop",
            },
          });
        }}
        saveLabel={languages.EN.labels.buy}
        onBack={() => callPopup({ type: ACTIONS.REMOVE })}
      />
    </>
  );
};

export default PopupDetailsGiftShop;
