import ListItem from "../ListItem/ListItem";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import VerificationBar, { VARIANT_PRIMARY } from "../../molecules/VerificationBar/VerificationBar";
import { languages } from "../../../dummyData/dummyData";
import Level from "../../atoms/Level/Level";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { useContext } from "react";
import { VARIANT_RED } from "../../atoms/Status/Status";
import ListItemImgMultiInfo from "../../molecules/ListItemImgMultiInfo/ListItemImgMultiInfo";
import { certificatesRepository } from "../../../repository/certificates.repository";
import { UserContext } from "../../../contexts/UserContext";
import { SCOPE_LEARNING } from "../../../constants/scopes";

const RelatedCompetenceAndCourse = ({ data, withJobPosition }) => {
  const { competence, level, job_position, visibility, visibilityImg, course, instructors, user_progress, user_verified, user_verification_type, job_competence_id, can_delete } = data;
  const { callPopup } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);
  let history = useHistory();

  return (
    <ListItem
      title={competence.name}
      subtitle={`${languages.EN.labels.region}: ${competence.country}`}
      image={competence.profile_image_small}
      bar={user_verified || user_progress > 0 ? (
        <VerificationBar
          progress={user_progress}
          text={user_verification_type !== null ? languages.EN.enums.verificationTypes[user_verification_type] : languages.EN.labels.notVerified}
          variant={user_verification_type !== null ? VARIANT_PRIMARY : VARIANT_RED}
        />
      ) : undefined}
      buttons={(userData.scopes.includes(SCOPE_LEARNING) && !userData.is_freemium && user_verification_type === 0 ? [
        {
          icon: "download",
          onClick: () => certificatesRepository.downloadCertificate(userData.username, course.id),
          tooltip: `${languages.EN.labels.download} ${languages.EN.labels.certificate}`,
        },
      ] : []).concat(can_delete ? [
        {
          icon: "garbage",
          onClick: () => callPopup({
            type: ACTIONS.DELETE,
            payload: {
              type: languages.EN.labels.jobPositionCompetence,
              elementName: `${competence.name} (${level})`,
              action: () => jobPositionsRepository.deleteJobPositionCompetence(job_competence_id),
            },
          }),
          tooltip: languages.EN.labels.delete,
        },
      ] : []).concat([
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.competenceDetails.jobPositions.base, { id: competence.id })),
          tooltip: languages.EN.labels.details,
        },
      ])}>
      <ListItemInfo value={(
        <Level small level={level} />
      )} />
      <ListItemInfo value={competence.group.name} />
      {withJobPosition && <ListItemInfo value={job_position.name} />}
      <ListItemImgInfo value={visibility} image={visibilityImg} />
      <ListItemInfo value={course.name} />
      <ListItemImgMultiInfo infos={instructors} />
      <ListItemInfo value={course.duration !== undefined ? `${course.duration} ${languages.EN.labels.minutes}` : undefined} />
    </ListItem>
  );
};

export default RelatedCompetenceAndCourse;
