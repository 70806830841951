import React from "react";
import IdpLearningPlanTable from "../Table/IdpLearningPlanTable/IdpLearningPlanTable";
import styles from "./IdpLearningPlan.module.scss";
import TimelineChart from "../../atoms/TimelineChart/TimelineChart";

const IdpLearningPlan = ({ data }) => {
  const tableData = data.learning_plan_items.map((item) => ({
    plannedNumberOfMinutes: Math.round(item.planned_duration / 60),
    recommendedNumberOfMinutes: Math.round(item.duration / 60),
    ...item,
  }));

  return (
    <div className={styles.wrapper}>
      <IdpLearningPlanTable data={tableData} />
      <TimelineChart data={data} />
    </div>
  );
};

export default IdpLearningPlan;
