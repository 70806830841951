import React from "react";
import styles from "./CompetenceComponent.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import Level from "../../atoms/Level/Level";
import { languages } from "../../../dummyData/dummyData";
import RoundImage from "../../atoms/RoundImage/RoundImage";

const CompetenceComponent = ({ name, level, question, inTable, img, disabled }) => {
  return (
    <div className={`${inTable ? styles.wrapperInTable : styles.wrapper} ${disabled ? styles.disabled : ''}`}>
      <TextTruncated lineClamp={1}>
        <Text s12 w400 lh24 dark-1>
          {name}
        </Text>
      </TextTruncated>
      <Level small withoutNumber level={level} />
      <RoundImage image={img} size={25} />
      {question > 0 && (
        <Text s12 w400 lh24 nowrap>
          {`${question} ${languages.EN.labels.questions}`}
        </Text>
      )}
    </div>
  );
};

export default CompetenceComponent;
