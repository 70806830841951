import styles from "./LearningReportCompetitions.module.scss";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import BadgesTable from "../../Table/LearningReport/Competitions/BadgesTable";
import employees from "../../../../assets/icons/statistics/employees.svg";
import laptop from "../../../../assets/icons/statistics/laptopWithOutClock.svg";
import badge from "../../../../assets/icons/statistics/badge.svg";
import { languages } from "../../../../dummyData/dummyData";
import { durationFormat } from "../../../../utils/formatters";
import Statistics from "../../Statistics/Statistics";
import React from "react";
import { TYPES_ACTIVITY, TYPES_LEARNING, TYPES_SOCIAL } from "../../../../constants/badges";
import BadgesSummaryTable from "../../Table/LearningReport/Competitions/BadgesSummaryTable";

const LearningReportCompetitions = ({ data }) => {
  return (
    <div className={styles.Wrapper}>
      <Statistics
        data={[
          {
            icon: employees,
            content: languages.EN.labels.students,
            value: parseFloat((Math.round(data && data.num_users * 100) / 100).toFixed(2)),
          },
          {
            icon: laptop,
            content: languages.EN.labels.averageTimeSpendStudent,
            value: durationFormat(data.avg_user_usage),
          },
          {
            icon: badge,
            content: languages.EN.labels.averageBadgeLevels,
            value: parseFloat((Math.round(data && data.avg_user_badges * 100) / 100).toFixed(2)),
          },
        ]}
      />
      <div className={styles.competitions}>
        <TableWrapper
          title={languages.EN.labels.badgesSummary}
          Table={BadgesSummaryTable}
          reports={{ data: data.rivalisation_reports }}
        />
      </div>
      <div className={styles.competitions}>
        <TableWrapper
          title={languages.EN.labels.learningBadges}
          Table={BadgesTable}
          reports={{ data: data.rivalisation_reports, types: {
            [languages.EN.labels.learningBadges]: TYPES_LEARNING,
          } }}
        />
      </div>
      <div className={styles.competitions}>
        <TableWrapper
          title={languages.EN.labels.activityAndSocialBadges}
          Table={BadgesTable}
          reports={{ data: data.rivalisation_reports, types: {
            [languages.EN.labels.activityBadges]: TYPES_ACTIVITY,
            [languages.EN.labels.socialBadges]: TYPES_SOCIAL,
          } }}
        />
      </div>
    </div>
  );
};

export default LearningReportCompetitions;
