import styles from "./StepsCircle.module.scss";

const StepsCircle = ({ currentStep }) => {
  return (
    <div className={styles.steps}>
      <div data-active={true} className={styles.steps__item}>1</div>
      <div data-active={currentStep > 1} className={styles.steps__item}>2</div>
      <div data-active={currentStep > 2} className={styles.steps__item}>3</div>
    </div>
  );
};

export default StepsCircle;
