import React, { useContext, useState } from "react";
import styles from "./FixedButtons.module.scss";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import icons from "../../../assets/icons";
import { useCurrentRoute } from "../../../hooks/useCurrentRoute";
import { Routes } from "../../../routes";
import AidifyChatbot from "../Chatbot/AidifyChatbot";

const FixedButtons = () => {
  const { callPopup } = useContext(PopupsContext);
  const [chatbotVisible, setChatbotVisible] = useState(false);
  const forbiddenReportRoute = [
    useCurrentRoute(Routes.coursePlayer),
  ].filter(route => route !== undefined).length > 0;
  const forbiddenChatbotRoute = [
    useCurrentRoute(Routes.coursePlayer),
    useCurrentRoute(Routes.mainPage),
    useCurrentRoute(Routes.error),
    useCurrentRoute(Routes.passwordReset),
    useCurrentRoute(Routes.passwordResetConfirm),
    useCurrentRoute(Routes.passwordChange),
    useCurrentRoute(Routes.registration),
    useCurrentRoute(Routes.competenceTest),
  ].filter(route => route !== undefined).length > 0;

  return (
    <div className={styles.fixedButtons}>
      {!forbiddenReportRoute && (
        <div className={styles.button} onClick={() => callPopup({ type: ACTIONS.REPORT_PROBLEM })}>
          {icons['reportProblem']}
        </div>
      )}
      {!forbiddenChatbotRoute && (
        <div className={styles.button} onClick={() => setChatbotVisible(prev => !prev)}>
          {icons['chatbot']}
        </div>
      )}
      {!forbiddenChatbotRoute && chatbotVisible && (
        <div className={styles.chatbotContainer}>
          <AidifyChatbot onClose={() => setChatbotVisible(false)}/>
        </div>
      )}
    </div>
  );
};

export default FixedButtons;