import React from "react";
import styles from "./PopupLearningOutcomes.module.scss";
import Text from "../../../atoms/Text/Text";
import { languages } from "../../../../dummyData/dummyData";

const PopupLearningOutcomes = ({ learningOutcomes }) => {
  return (
    <div className={styles.outcomesPopup}>
      {learningOutcomes ? (
        <>
          <Text dark-1 s10 lh12 w600>
            {languages.EN.labels.learningOutcomes}
          </Text>
          <Text dark-1 s10 lh18 w400>
            <ul>
              {learningOutcomes.split("|").map((outcome, i) => <li key={i}>{outcome}</li>)}
            </ul>
          </Text>
        </>
      ) : (
        <Text dark-6 s14 lh18>{languages.EN.labels.competenceDoesntHaveGivenLevel}</Text>
      )}
    </div>
  );
};

export default PopupLearningOutcomes;
