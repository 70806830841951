import React from "react";
import employees from "../../../assets/icons/statistics/employees.svg";
import licence from "../../../assets/icons/statistics/licence.svg";
import certificate from "../../../assets/icons/statistics/certificate.svg";
import timeOnPlatform from "../../../assets/icons/statistics/timeOnPlatform.svg";
import laptop from "../../../assets/icons/statistics/laptop.svg";
import dollar from "../../../assets/icons/statistics/dollar.svg";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";
import { currencyFormat, durationFormat, durationInHours } from "../../../utils/formatters";
import Statistics from "../Statistics/Statistics";

const PlatformDashboardStatistics = ({ data }) => {
  const { num_users, num_licenses, num_certs, total_spent, avg_spent, savings } = data;
  const history = useHistory();

  return (
    <Statistics
      data={[
        {
          icon: employees,
          content: languages.EN.labels.users,
          value: num_users,
        },
        {
          icon: licence,
          content: languages.EN.labels.activeLicenses,
          value: num_licenses,
        },
        {
          icon: certificate,
          content: languages.EN.labels.obtainedCertificates,
          value: num_certs,
        },
        {
          icon: timeOnPlatform,
          content: languages.EN.labels.timeSpentOnPlatformByUsers,
          value: durationInHours(total_spent),
        },
        {
          icon: laptop,
          content: languages.EN.labels.averageTimeSpentOnPlatformByUser,
          value: durationFormat(avg_spent),
        },
        {
          icon: dollar,
          content: languages.EN.labels.aproximateSavingsForOrganizations,
          value: currencyFormat(savings),
        },
      ]}
      buttons={[
        {
          label: languages.EN.labels.viewMore,
          icon: "lookEye",
          onClick: () => history.push(generatePath(Routes.learningReport.competences.base, { type: 3, id: 0})),
        }
      ]}
    />
  );
};

export default PlatformDashboardStatistics;
