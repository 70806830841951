import React, { useContext } from "react";
import styles from "./WorkgroupMember.module.scss";
import Text from "../../atoms/Text/Text";
import TileInformation from "../../atoms/TileInformation/TileInformation";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import Button from "../../atoms/Button/Button";
import userPhoto from "../../../assets/member.png";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { UserContext } from "../../../contexts/UserContext";
const { workgroupMember } = languages.EN.labels;

const { empty } = languages.EN.placeholders;
const WorkgroupMember = ({ data, id, state }) => {
  const { email, profile } = data.user;
  const { teams } = data;

  const {
    name,
    surname,
    profile_image,
    phone_number,
    user_job_position,
    user_career_path,
    user,
  } = profile;

  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);
  const { username } = user;

  return (
    <div className={styles.user}>
      <div className={styles.imgContainer}>
        <img
          src={profile_image ? profile_image : userPhoto}
          alt="user"
        />
      </div>

      <div className={styles.user__nameContainer}>
        <Text s20 lh24 w800 secondary>
          {name ? `${name} ${surname}` : empty}
        </Text>
      </div>
      <div className={styles.user__information}>
        <Text s12 lh24 dark-2>
          {user_job_position ? user_job_position.name : empty}
        </Text>
      </div>
      <div className={styles.user__information}>
        <Text s12 lh24 dark-2>
          {user_career_path ? user_career_path.name : empty}
        </Text>
      </div>
      <div className={styles.user__informationTeam}>
        <TileInformation lineClamp={5} data={teams} />
      </div>
      <div className={styles.user__information}>
        <TextTruncated lineClamp={1}>
          <Text s12 lh24 dark-2>
            {email}
          </Text>
        </TextTruncated>
      </div>
      <div className={styles.user__information}>
        <Text s12 lh24 dark-2>
          {phone_number ? phone_number : empty}
        </Text>
      </div>
      <div className={styles.buttonsContainer}>
        {([2, 3].includes(userContextLevel) || state) && (
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() =>
              callPopup({
                type: ACTIONS.DELETE,
                payload: {
                  type: workgroupMember,
                  elementName: name ? `${name} ${surname}` : email,
                  action: () =>
                    workgroupsRepository.unassignFromWorkgroup({ user: username, workgroup: id }),
                },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default WorkgroupMember;
