import { regulatory_intelligence_endpoint, regulatory_intelligence_likes_endpoint, regulatory_intelligence_share_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getArticles = () => {
  return fetch(regulatory_intelligence_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getArticle = (id) => {
  return fetch(`${regulatory_intelligence_endpoint + id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const sendLike = (id) => {
  return fetch(regulatory_intelligence_likes_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody({ article_id: id }),
  });
};

const shareArticle = (data) => {
  return fetch(regulatory_intelligence_share_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const addArticle = (data) => {
  return fetch(regulatory_intelligence_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editArticle = (id, data) => {
  return fetch(`${regulatory_intelligence_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteArticle = (id) => {
  return fetch(`${regulatory_intelligence_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const regulatoryIntelligenceRepository = {
  getArticles,
  getArticle,
  sendLike,
  shareArticle,
  addArticle,
  editArticle,
  deleteArticle,
};
