import styles from "./NotificationDropdownItem.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import { dateFormat, timeAgoFormat } from "../../../utils/formatters";
import { notificationTypeImages } from "../NotificationItem/NotificationItem";
import NotificationButton from "../../atoms/NotificationButton/NotificationButton";

const prepareDate = (date) => {
  const now = new Date();
  const then = new Date(date);
  const milisecondsBetweenDates = Math.abs(then.getTime() - now.getTime());

  return milisecondsBetweenDates < 24 * 60 * 60 * 1000 ? timeAgoFormat(milisecondsBetweenDates) : (
    <>
      {dateFormat(then)}
      <br/>
      {then.toLocaleTimeString("pl-PL").slice(0, 5)}
    </>
  );
};

const NotificationDropdownItem = ({ data }) => {
  const { id, name, text, created, type, unread, extra } = data;

  return (
    <NotificationButton id={id} type={type} extra={extra} className={styles.item}>
      <img alt="" src={notificationTypeImages[type]} className={styles.item__image} />
      <div className={styles.item__wrapper}>
        <Text s12 lh12 dark {...(unread ? { "w800": true } : {})}>
          {name}
        </Text>
        <TextTruncated lineClamp={4}>
          <Text s12 lh12 dark {...(unread ? { "w800": true } : {})}>
            {text}
          </Text>
        </TextTruncated>
      </div>
      <div className={styles.item__time}>
        <Text s10 lh12 {...(unread ? { "w800": true, "primary": true } : { "dark-2": true })}>
          {prepareDate(created)}
        </Text>
      </div>
    </NotificationButton>
  );
};

export default NotificationDropdownItem;
