import SearchTemplate from "../../templates/SearchTemplate/SearchTemplate";
import { Loader } from "../../atoms/Loader/Loader";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FilterContextProvider } from "../../../contexts/FilterContext";

const SearchResults = () => {
  const location = useLocation();
  const [currentData, setCurrentData] = useState(location.state.data);

  useEffect(() => {
    setCurrentData(location.state.data);
  }, [location.state]);

  return currentData ? (
    <FilterContextProvider>
      <SearchTemplate data={currentData} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default SearchResults;
