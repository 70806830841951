import { career_path, career_path_job_position } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getCareerPaths = () => {
  return fetch(career_path, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getCareerPathDetails = (id) => {
  return fetch(`${career_path}${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addCareerPath = (data) => {
  return fetch(career_path, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const sendJobPositionChart = (id, body) => {
  return fetch(`${career_path_job_position}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(body),
  });
};

const deleteCareerPath = (id) => {
  return fetch(`${career_path}${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const editCareerPath = (id, data) => {
  return fetch(`${career_path}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const careerPathsRepository = {
  getCareerPaths,
  getCareerPathDetails,
  addCareerPath,
  sendJobPositionChart,
  deleteCareerPath,
  editCareerPath,
};
