import styles from "./CourseCarouselBig.module.scss";
import { defaultUserPhoto } from "../../../constants/assets";
import React, { useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import SwiperNavigation from "../../molecules/SwiperNavigation/SwiperNavigation";
import CourseBig from "../../molecules/CourseBig/CourseBig";

SwiperCore.use([Navigation, Pagination]);

const CourseCarouselBig = ({ courses }) => {
  const [isHovered, setIsHovered] = useState(false);

  const preparedCourses = useMemo(() => courses.map(course => ({
    ...course,
    instructors: course.course_instructor.length > 0 ? course.course_instructor.map(instructor => ({
      name: instructor.name,
      img: instructor.profile_image_small,
    })) : [{
      name: course.instructor_text,
      img: defaultUserPhoto,
    }],
  })), [courses]);

  return (
    <div onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Swiper
        className={styles.mainSwiper}
        pagination={{ clickable: true }}
        navigation={isHovered ? {
          prevEl: "#mainSliderPrev",
          nextEl: "#mainSliderNext",
        } : false}
      >
        {preparedCourses.map(course => (
          <SwiperSlide key={course.id}>
            <CourseBig course={course} />
          </SwiperSlide>
        ))}
        <SwiperNavigation id={"mainSlider"} visible={isHovered} />
      </Swiper>
    </div>
  );
};

export default CourseCarouselBig
