import NotificationsTemplate from "../../templates/NotificationsTemplate/NotificationsTemplate";
import { notificationsRepository } from "../../../repository/notifications.repository";
import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import useFetch from "../../../hooks/useFetch";

const Notifications = () => {
  const { data, fetchData } = useFetch();

  useDocumentTitleLoading();

  useEffect(() => {
    fetchData(notificationsRepository.getUserNotifications());
  }, []);

  return data ? (
    <FilterContextProvider>
      <NotificationsTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default Notifications;
