import Label from "../Label/Label";
import Checkbox from "../Checkbox/Checkbox";
import styles from "./CheckboxInput.module.scss";
import Text from "../Text/Text";
import React from "react";

const CheckboxInput = ({ name, label, value, required, error, handleInput, noMargin }) => {
  return (
    <Label noMargin={noMargin} error={error}>
      <fieldset name={name} className={styles.hook}>
        <Checkbox
          checked={value}
          error={error}
          handleChange={handleInput}
          value={value}
        />
        <span className={`${styles.checkboxLabel} ${error ? styles.checkboxLabel__error : ""}`} data-required={required}>
          <Text s12 lh30>
            {label}
          </Text>
        </span>
      </fieldset>
    </Label>
  );
};

export default CheckboxInput
