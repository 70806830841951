import { getAuthorizationHeader } from "./repositoryHelper";
import { chat, chatroom, chat_search, chatbot_endpoint } from "../constants/endpoints";

const getAllChatrooms = () => {
  return fetch(`${chat}1/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getChatroom = (id) => {
  return fetch(`${chat}${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const sendMessage = (id, msg) => {
  return fetch(`${chat}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `msg=${msg}`,
  });
};

const createChatroom = (users, name) => {
  return fetch(chatroom, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `name=${name}&users=${users}`,
  });
};

const searchChat = (query) => {
  return fetch(`${chat_search}?q=${query}`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const chatRepository = {
  getAllChatrooms,
  getChatroom,
  sendMessage,
  createChatroom,
  searchChat,
};
