import React, { useContext, useEffect, useState } from "react";
import styles from "./Step1.module.scss";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import defaultPhoto from "../../../assets/icons/photo.svg";
import { languages } from "../../../dummyData/dummyData";
import Form, { TYPE_AUTOCOMPLETE, TYPE_CHECKBOX, TYPE_PASSWORD, TYPE_PASSWORD_WITH_REQUIREMENTS } from "../../molecules/Form/Form";
import useFetch from "../../../hooks/useFetch";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { Loader } from "../../atoms/Loader/Loader";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { userRepository } from "../../../repository/user.repository";
import { PostContext } from "../../../contexts/PostContext";
import { UserContext } from "../../../contexts/UserContext";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import MainTemplate from "../../templates/generic/MainTemplate/MainTemplate";

const formFields = (jobPositionItems = [], data = {}, termsOnClick, privacyOnClick) => [
  {
    name: "email",
    label: languages.EN.labels.email,
    disabled: true,
    value: data.user.email,
  },
  {
    name: "organization",
    label: languages.EN.labels.organization,
    disabled: true,
    value: data.organization.name,
  },
  {
    name: "team",
    label: languages.EN.labels.team,
    disabled: true,
    value: data.team?.name,
  },
  {
    name: "name",
    label: languages.EN.labels.firstName,
    required: true,
  },
  {
    name: "surname",
    label: languages.EN.labels.secondName,
    required: true,
  },
  {
    name: "password",
    label: languages.EN.labels.password,
    required: true,
    type: TYPE_PASSWORD_WITH_REQUIREMENTS,
  },
  {
    name: "password2",
    label: languages.EN.labels.repeatPassword,
    required: true,
    validation: (formData) => formData.password !== formData.password2 ? languages.EN.messages.mustMatchPassword : undefined,
    type: TYPE_PASSWORD,
  },
  {
    name: "phone_number",
    label: languages.EN.labels.phoneNumber,
  },
  {
    name: "job_position",
    label: languages.EN.labels.jobPosition,
    type: TYPE_AUTOCOMPLETE,
    items: jobPositionItems.map((jobPosition) => ({
      id: jobPosition.id,
      label: jobPosition.name,
      photo: jobPosition.profile_image_small,
    }))
  },
  {
    name: "terms",
    label: languages.EN.labels.acceptance,
    descripiton: <>
      {languages.EN.labels.iAcceptThe}
      <a type="button" className={styles.step__checkboxLink} onClick={termsOnClick}>
        {languages.EN.labels.termsAndConditions}
      </a>
    </>,
    required: true,
    type: TYPE_CHECKBOX,
    value: "",
  },
  {
    name: "privacy_policy",
    label: languages.EN.labels.acceptance,
    descripiton: <>
      {languages.EN.labels.iAgreeToThe}
      <a type="button" className={styles.step__checkboxLink} onClick={privacyOnClick}>
        {languages.EN.labels.privacyPolicy}
      </a>
    </>,
    required: true,
    type: TYPE_CHECKBOX,
    value: "",
  },
];

const Step1 = ({ onNextStep }) => {
  const [photo, setPhoto] = useState();
  const { callPopup } = useContext(PopupsContext);
  const { userData, getUserContext } = useContext(UserContext);
  const { postData, postResponse } = useContext(PostContext);

  useDocumentTitle(languages.EN.labels.registration);

  const fetchRegistrationData = useFetch();
  useEffect(() => {
    fetchRegistrationData.fetchData(userRepository.getRegistrationData());
  }, [userData]);

  const fetchJobPositions = useFetch();
  useEffect(() => {
    fetchJobPositions.fetchData(jobPositionsRepository.getJobPositions());
  }, []);

  const handleSave = (formData) => {
    return userRepository.saveRegistrationData(userData.username, { ...Object.fromEntries(Object.entries(formData).filter(([, value]) => value !== undefined)), profile_image: photo });
  }

  return fetchRegistrationData.isReady && fetchJobPositions.isReady ? (
    <MainTemplate>
      <section className={styles.step}>
        <div className={styles.uploadImgWrapper}>
          <div className={styles.uploadImg}>
            <img src={photo ? URL.createObjectURL(photo) : defaultPhoto} alt="" />
            <div className={styles.uploadImg__content}>
              <Text s10 lh12 w600 dark-2>
                {languages.EN.labels.uploadPhotoFromPC}
              </Text>
              <Button
                variant="iconButton"
                onlyIcon
                icon="upload"
                tooltip={languages.EN.labels.uploadFile}
              >
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    setPhoto(e.target.files[0]);
                  }}
                />
              </Button>
            </div>
            {postResponse?.profile_image && (
              <span className={styles.errorMsg}>
                <Text s10 lh12>
                  {postResponse.profile_image}
                </Text>
              </span>
            )}
          </div>
        </div>

        <div className={styles.step__form}>
          <Form
            fields={formFields(
              fetchJobPositions.data,
              fetchRegistrationData.data,
              () => callPopup({
                type: ACTIONS.INFORMATION,
                payload: {
                  header: languages.EN.labels.termsAndConditions,
                  content: languages.EN.registerPage.termsAndConditions[userData.is_b2c ? 'b2c' : 'b2b'],
                },
              }),
              () => callPopup({
                type: ACTIONS.INFORMATION,
                payload: {
                  header: languages.EN.labels.privacyPolicy,
                  content: languages.EN.registerPage.privacyPolicy,
                },
              }),
              () => callPopup({
                type: ACTIONS.INFORMATION,
                payload: {
                  header: languages.EN.labels.cookiesPolicy,
                  content: languages.EN.registerPage.cookiesPolicy,
                },
              })
            )}
            onSave={(formData) => {
              if (!formData.job_position && fetchRegistrationData.data.is_student) {
                callPopup({
                  type: ACTIONS.CONFIRM,
                  payload: {
                    header: languages.EN.labels.warning,
                    content: languages.EN.messages.noJobPositionSelected,
                    action: () => postData(
                      () => {
                        callPopup({ type: ACTIONS.REMOVE });
                        return handleSave(formData);
                      },
                      onNextStep
                    ),
                  },
                });
              } else {
                return postData(
                  () => handleSave(formData),
                  fetchRegistrationData.data.is_student ? (response) => onNextStep() : (response) => getUserContext());
              }
            }}
            saveLabel={languages.EN.labels.next}
            autoFocus={true}
          />
        </div>
      </section>
    </MainTemplate>
  ) : (
    <Loader />
  );
};
export default Step1;
