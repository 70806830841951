import Cookies from "universal-cookie/es6";
import { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Routes } from "../routes";
import { login_endpoint } from "../constants/endpoints";
export const PostContext = createContext();
export const PostContextProvider = ({ children }) => {
  const [isPostStart, setIsPostStart] = useState(false);
  const [lastUsedPromise, setLastUsedPromise] = useState("");
  const [isPostFinish, setIsPostFinish] = useState(false);
  const [postResponse, setPostResponse] = useState(null);
  const [isResponseOk, setIsResponseOk] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const cookies = new Cookies();

  const postData = (promise, onSuccess, redirectOnSucces, id) => {
    reset();
    setIsPostStart(true);
    setLastUsedPromise(id);
    promise()
      .then((res) => {
        setIsPostStart(false);
        // Not sure why sometimes the resonse is just a Response object, and sometimes it's a one-element array with Response object
        if (!Array.isArray(res)) {
          return handleSingleResponse(res, onSuccess, redirectOnSucces);
        } else {
          return handleMultipleResponses(res, onSuccess, redirectOnSucces);
        }
      })
      .then((data) => {
        if (Array.isArray(data)) {
          data = Object.assign({}, ...data)
        }
        setPostResponse(data);
      })
      .catch(() => {
        setIsPostFinish(false);
      });
  };

  const handleSingleResponse = (response, onSuccess, redirectOnSuccess) => {
    if (response.status === 200 || response.ok) {
      setIsPostFinish(!redirectOnSuccess);
      onSuccess && onSuccess(response);
      setIsResponseOk(true);
      return response.json();
    } else {
      setIsResponseOk(false);
      setIsPostFinish(true);

      if (response.status === 401 && response.url !== login_endpoint) {
        cookies.remove("jwttoken", { path: Routes.mainPage.base });
        history.push(Routes.mainPage.base);
      } else {
        return response.json();
      }
    }
  }

  const handleMultipleResponses = (responses, onSuccess, redirectOnSuccess) => {
    if (responses.filter(response => response.status === 200 || response.ok).length === responses.length) {
      setIsPostFinish(!redirectOnSuccess);
      onSuccess && onSuccess(responses);
      setIsResponseOk(true);
      return Promise.all(responses.map(response => response.json()));
    } else {
      setIsResponseOk(false);
      setIsPostFinish(true);
      return Promise.all(responses.filter(response => !response.ok).map(response => response.json()));
    }
  };

  useEffect(() => {
    if (postResponse) {
      setIsPostFinish(false);
    }
  }, [postResponse]);

  const reset = () => {
    setIsPostStart(false);
    setLastUsedPromise("");
    setIsPostFinish(false);
    setPostResponse(null);
    setIsResponseOk(null);
    setIsActive(false);
  }

  return (
    <PostContext.Provider
      value={{
        postData,
        reset,
        setIsActive,
        isResponseOk,
        isPostFinish,
        isPostStart,
        lastUsedPromise,
        postResponse,
        isActive,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
