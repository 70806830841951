import CourseEditTemplate from "../../templates/CourseEditTemplate/CourseEditTemplate";
import useFetch from "../../../hooks/useFetch";
import { coursesRepository } from "../../../repository/courses.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const CourseEdit = ({ match }) => {
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  const fetchCourseDetails = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchCourseDetails.data) {
      fetchCourseDetails.fetchData(coursesRepository.getCourseDetails(match.params.id));
    }
  }, [isResponseOk]);

  const fetchCourseContent = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchCourseContent.data) {
      fetchCourseContent.fetchData(coursesRepository.getEditableCourseContent(match.params.id));
    }
  }, [isResponseOk]);

  return fetchCourseDetails.data && fetchCourseContent.data ? (
    <CourseEditTemplate courseDetails={fetchCourseDetails.data} courseContent={fetchCourseContent.data} />
  ) : (
    <Loader />
  );
};

export default CourseEdit;
