import Html from "../../../atoms/Html/Html";
import { useEffect, useState } from "react";
import Loader from "../Components/Loader";

const HtmlMessage = ({ payload }) => {
  const { message, delay, loading, withAvatar = true } = payload;
  const [show, setShow] = useState(!delay);
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setShow(true), delay);
    }
  }, [delay]);

  useEffect(() => {
    if (show && loading) {
      setTimeout(() => setIsLoading(false), 750);
    }
  }, [show]);

  return show ? (
    <div className="react-chatbot-kit-chat-bot-message-container">
      <div className="react-chatbot-kit-chat-bot-message">
        {!isLoading ? <Html>{message}</Html> : <Loader />}
        {withAvatar && <div className="react-chatbot-kit-chat-bot-message-arrow"></div>}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default HtmlMessage;