import React, { useContext, useEffect } from "react";
import styles from "./RegistrationTestReport.module.scss";
import { useLocation } from "react-router-dom";
import { Loader } from "../../atoms/Loader/Loader";
import useFetch from "../../../hooks/useFetch";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import CompetenceTestReportCharts from "../CompetenceTestReportCharts/CompetenceTestReportCharts";
import Button from "../../atoms/Button/Button";
import { languages } from "../../../dummyData/dummyData";
import { UserContext } from "../../../contexts/UserContext";

const RegistrationTestReport = () => {
  const { data, isReady, fetchData } = useFetch();
  const { getUserContext } = useContext(UserContext);
  const location = useLocation();
  const { id } = location.state;

  useEffect(() => {
    fetchData(competenceTestRepository.getTestSummary(id));
  }, [fetchData, id]);

  return (
    <>
      {isReady ? (
        <section className={styles.wrapper}>
          <CompetenceTestReportCharts data={data} />
          <div className={styles.homeButton}>
            <Button variant="primary" onClick={() => getUserContext()}>
              {languages.EN.labels.homepage}
            </Button>
          </div>
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default RegistrationTestReport;
