import styles from "./PickValidOrder.module.scss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DragDropBoard from "../DragDropBoard";
import DragDropCard from "../DragDropCard";
import Text from "../../../atoms/Text/Text";
import { CompetenceTestContext } from "../../../../contexts/CompetenceTestContext";
import { shuffle } from "../../../../utils/arrayHelpers";

const PickValidOrder = ({ data }) => {
  const { answers, question } = data;
  const { savedAnswers, currentPosition, setCurrentAnswer } = useContext(CompetenceTestContext);

  const shuffledAnswersOrder = useMemo(() => shuffle(Array.from(answers.keys())), [answers]);

  const getDefaultLocalData = () => {
    return Object.fromEntries(answers.map((answer, i) => [i, ]));
  }

  const parseSavedAnswer = () => {
    let startFromIndex = {};

    return Object.fromEntries(
      savedAnswers[currentPosition]
        .split("|")
        .map((answer, i) => {
          const foundIndex = answers.indexOf(answer, startFromIndex[answer] ?? 0);
          let cardId;

          if (foundIndex !== -1) {
            startFromIndex[answer] = foundIndex + 1;
            cardId = shuffledAnswersOrder.indexOf(foundIndex).toString();
          } else {
            cardId = undefined;
          }

          return [i, cardId]
        })
    );
  }

  const [localData, setLocalData] = useState(getDefaultLocalData());

  useEffect(() => {
    setLocalData(savedAnswers[currentPosition] ? parseSavedAnswer() : getDefaultLocalData());
  }, [savedAnswers, currentPosition]);

  const handleMoveAnswer = (cardId, position) => {
    const newData = Object.fromEntries(Object.entries(localData).map(([i, id]) => id === cardId ? [i, undefined] : i === position ? [i, cardId] : [i, id]));
    setLocalData(newData);

    setCurrentAnswer(Object.values(newData).map(cardId => answers[shuffledAnswersOrder[cardId]]).join("|"));
  };

  return (
    <div className={styles.wrapper}>
      <Text s14 lh24 dark-4>
        {question}
      </Text>

      {answers.map((_, id) => (
        <div className={styles.pairRow} key={id}>
          <span className={styles.validOrderNumber}>{id + 1}</span>
          <DragDropBoard
            id={`board-${id}`}
            type="answer"
            onDrop={(cardId) => handleMoveAnswer(cardId, id.toString())}
          >
            {localData[id] && (
              <DragDropCard id={localData[id]}>
                <Text s14 dark-4>
                  {answers[shuffledAnswersOrder[localData[id]]]}
                </Text>
              </DragDropCard>
            )}
          </DragDropBoard>
        </div>
      ))}

      <DragDropBoard
        id="board-home"
        type="container"
        onDrop={(cardId) => handleMoveAnswer(cardId, null)}
        minWidth={answers?.length * 160 + 5}
      >
        {shuffledAnswersOrder.map((originalId, shuffledId) => {
          return !Object.values(localData).includes(shuffledId.toString()) ? (
            <DragDropCard id={shuffledId} key={shuffledId}>
              <Text s14 dark-4>
                {answers[originalId]}
              </Text>
            </DragDropCard>
          ) : null;
        })}
      </DragDropBoard>
    </div>
  );
};

export default PickValidOrder;
