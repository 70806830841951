import PulseLoader from "react-spinners/PulseLoader";
import styles from "./Loader.module.scss";

const speed = 0.4;

export const Loader = ({ size }) => {
  return (
    <div className={styles.Wrapper}>
      <PulseLoader
        speedMultiplier={speed}
        size={size ? size : 25}
        color={"#00a094"}
      />
    </div>
  );
};

export const TableLoader = ({ size, background }) => {
  return (
    <div className={styles.TableLoader} data-color={background}>
      <PulseLoader
        speedMultiplier={speed}
        size={size ? size : 25}
        color={"#00a094"}
      />
    </div>
  );
};

export const TableSubrowLoader = () => {
  return (
    <div className={styles.TableSubrowLoader}>
      <TableLoader background={"transparent"} />
    </div>
  );
};

export const PopupLoader = ({ size }) => {
  return (
    <div className={styles.PopupLoader}>
      <PulseLoader
        speedMultiplier={speed}
        size={size ? size : 25}
        color={"#00a094"}
      />
    </div>
  );
};

export const LoaderOnly = ({ size }) => {
  return (
    <PulseLoader
      speedMultiplier={speed}
      size={size ? size : 10}
      color={"#00a094"}
    />
  );
};

export const ContextLoader = () => {
  return (
    <div className={styles.contextLader}>
      <PulseLoader speedMultiplier={speed} size={25} color={"#00a094"} />
    </div>
  );
};

export const VideoLoader = () => {
  return (
    <div className={styles.videoLoader}>
      <PulseLoader speedMultiplier={speed} size={25} color={"#00a094"} />
    </div>
  );
};
