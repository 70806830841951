import React, { useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import MarketItem from "../../organisms/MarketItem/MarketItem";
import MarketItemTile from "../../organisms/MarketItemTile/MarketItemTile";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { TYPE_TEXTAREA } from "../../molecules/Form/Form";
import TopBanner from "../../molecules/TopBanner/TopBanner";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "description",
    header: languages.EN.labels.description,
    sortId: 2,
  },
  {
    keyPath: "category",
    header: languages.EN.labels.category,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
  {
    keyPath: "price",
    header: languages.EN.labels.price,
    typeOfFilter: "range",
    sortId: 4,
  },
];

export const popupFields = (data = {}, userData = {}) => {
  return [
    {
      name: "subject",
      label: languages.EN.labels.name,
      disabled: true,
      value: data.name,
    },
    {
      name: "category",
      label: languages.EN.labels.category,
      disabled: true,
      value: data.category,
    },
    {
      name: "organization",
      label: languages.EN.labels.organization,
      disabled: true,
      value: userData.job_title,
    },
    {
      name: "user",
      label: languages.EN.labels.user,
      disabled: true,
      value: userData.name,
    },
    {
      name: "message",
      label: languages.EN.labels.additionalDetails,
      type: TYPE_TEXTAREA,
    },
  ]
};

const MarketplaceTemplate = ({ data }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => ({
    ...item,
  }))), [data]);
  const pageHeader = languages.EN.labels.marketplace;

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <FilteredList
          id="marketplace"
          data={preparedData}
          filterDefinitions={filterDefinitions}
          RenderListComponent={MarketItem}
          RenderTileComponent={MarketItemTile}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default MarketplaceTemplate;
