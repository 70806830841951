import Text from "../../atoms/Text/Text";
import styles from "./ListItemInfo.module.scss";
import { languages } from "../../../dummyData/dummyData";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import { v4 as uuidv4 } from "uuid";
import Button from "../../atoms/Button/Button";

const ListItemInfo = ({ value, button }) => {
  const text = (
    <Text s12 lh24 dark-6>
      {Array.isArray(value) && value.length > 0 ?
        value.map((item) => <span key={uuidv4()}>{item}<br /></span>) :
        ([undefined, null].includes(value) || value.length === 0) ? languages.EN.placeholders.empty : value
      }
    </Text>
  );

  return (
    <span className={styles.listItemInfo}>
      <TextTruncated lineClamp={4} secondary>
        {text}
        {button && (
          <span className={styles.listItemInfo__buttonWrapper}>
            <Button
              variant="iconButton"
              onlyIcon
              icon={button.icon}
              key={button.icon}
              onClick={button.onClick}
              tooltip={button.tooltip}
            />
          </span>
        )}
      </TextTruncated>
    </span>
  );
};

export default ListItemInfo;
