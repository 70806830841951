import { platform_dashboard } from "../constants/endpoints";
import { getAuthorizationHeader } from "./repositoryHelper";

const getPlatformDashboard = () => {
  return fetch(platform_dashboard, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const PlatformDashboardRepository = {
  getPlatformDashboard,
};
