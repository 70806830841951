import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";
import { view_context_endpoint } from "../constants/endpoints";

const getViewContext = () => {
  return fetch(view_context_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const setViewContext = (data) => {
  return fetch(view_context_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const viewContextRepository = {
  getViewContext,
  setViewContext,
};
