import React, { useContext, useEffect, useState } from "react";
import Member from "../Member/Member";
import MemberTile from "../MemberTile/MemberTile";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { Routes } from "../../../routes";
import { useHistory } from "react-router-dom";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import { popupFields } from "../../templates/WorkgroupsTemplate/WorkgroupsTemplate";
import { TYPE_MULTICOMPLETE } from "../../molecules/Form/Form";
import { SCOPE_CHAT, SCOPE_WORKGROUP } from "../../../constants/scopes";
import { UserContext } from "../../../contexts/UserContext";

const filterDefinitions = (skipTeam, withManagement) => [
  {
    keyPath: ["user.profile.name", "user.profile.surname"],
    header: languages.EN.labels.name,
    sortId: 1,
  },
].concat(!skipTeam ? [
  {
    keyPath: "preparedTeams",
    header: languages.EN.labels.team,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
] : [
  {
    keyPath: "user.profile.user_career_path.name",
    header: languages.EN.labels.careerPath,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
]).concat([
  {
    keyPath: "user.profile.user_job_position.name",
    header: languages.EN.labels.jobPosition,
    sortId: 4,
  },
]).concat(withManagement ? [
  {
    keyPath: "preparedLicense",
    header: languages.EN.labels.licenses,
    sortId: 5,
  },
  {
    keyPath: "licenseType",
    header: languages.EN.labels.licenseType,
    typeOfFilter: "checkbox",
  },
] : []).concat([
  {
    keyPath: "user.email",
    header: languages.EN.labels.email,
    sortId: 6,
  },
  {
    keyPath: "user.profile.phone_number",
    header: languages.EN.labels.phoneNumber,
    sortId: 7,
  },
]);

const createChatName = (selectedTable) => {
  const usersNames = selectedTable.map(user => user.user.profile.name ? `${user.user.profile.name} ${user.user.profile.surname}` : user.user.email);

  return `${usersNames.slice(0, 10).join(", ")}${usersNames.length > 10 ? `, +${usersNames.length - 10}` : ''}`;
}

const MembersList = ({ id, data, teamId, managementLevel, onAdd }) => {
  const [preparedData, setPreparedData] = useState(null);
  const { userData } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  useEffect(() => setPreparedData(data.map((item) => ({
    ...item,
    isChecked: false,
    // selectableId: item.user.username,
    licenseType: item.licenses.map(license => license.type === 1 ? languages.EN.labels.administrative : languages.EN.enums.learningAreas[license.topic]),
    preparedLicense: item.licenses.map((license) => license.number),
    preparedTeams: item.teams?.length > 0 ? item.teams.map(team => team.name) : languages.EN.placeholders.empty,
  }))), [data]);

  const RenderListComponent = ({ data }) => (
    <Member data={data} teamId={teamId} managementLevel={managementLevel} />
  );

  const RenderTileComponent = ({ data }) => (
    <MemberTile data={data} teamId={teamId} managementLevel={managementLevel} />
  );

  return (
    <FilteredList
      id={`members${id[0].toUpperCase() + id.slice(1)}`}
      data={preparedData}
      filterDefinitions={filterDefinitions(teamId !== undefined, managementLevel > 0)}
      RenderListComponent={RenderListComponent}
      RenderTileComponent={RenderTileComponent}
      buttons={[].concat(userData.scopes.includes(SCOPE_WORKGROUP) ? [
        {
          icon: "workgroups",
          label: languages.EN.labels.newWorkgroup,
          forSelectedOnly: true,
          onClick: (selectedTable) => {
            const fields = popupFields();
            fields.splice(2, 0, {
              name: "members",
              label: languages.EN.labels.members,
              disabled: true,
              type: TYPE_MULTICOMPLETE,
              value: selectedTable.map(item => item.user.username),
              items: selectedTable.map(item => ({
                id: item.user.username,
                label: item.user.profile.name ? `${item.user.profile.name} ${item.user.profile.surname}` : item.user.email,
              })),
            })

            callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.add} ${languages.EN.labels.workgroup}`,
                fields: fields,
                postAction: workgroupsRepository.addWorkgroup,
              },
            })
          },
        },
      ] : []).concat(userData.scopes.includes(SCOPE_CHAT) ? [
        {
          icon: "message",
          label: languages.EN.labels.sendMessage,
          forSelectedOnly: true,
          onClick: (selectedTable) => history.push({
            pathname: Routes.chat.base,
            state: {
              users: selectedTable.map((item) => item.user.username),
              chatName: createChatName(selectedTable),
            },
          }),
        },
      ] : []).concat(managementLevel > 0 ? [
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: onAdd,
        }
      ] : [])}
    />
  );
};

export default MembersList;
