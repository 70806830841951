import useFetch from "../../../hooks/useFetch";
import IdpEditTemplate from "../../templates/IdpEditTemplate/IdpEditTemplate";
import { idpRepository } from "../../../repository/idp.repository";
import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { careerPathsRepository } from "../../../repository/careerPaths.repository";
import { coursesRepository } from "../../../repository/courses.repository";

export const MODE_ADD = 'add';
export const MODE_EDIT = 'edit';

const IdpEdit = ({ match }) => {
  const fetchIdp = useFetch();
  useEffect(() => {
    fetchIdp.fetchData(idpRepository.getIdp(match.params.username, match.params.id));
  }, [match.params.username, match.params.id]);

  const fetchCareerPaths = useFetch();
  useEffect(() => {
    fetchCareerPaths.fetchData(careerPathsRepository.getCareerPaths());
  }, []);

  const fetchJobPositions = useFetch();
  useEffect(() => {
    fetchJobPositions.fetchData(jobPositionsRepository.getJobPositions());
  }, []);

  const fetchCourses = useFetch();
  useEffect(() => {
    fetchCourses.fetchData(coursesRepository.getCourses());
  }, []);

  useDocumentTitleLoading();

  return fetchIdp.isReady && fetchCareerPaths.data && fetchJobPositions.data && fetchCourses.data ? (
    <IdpEditTemplate
      idp={fetchIdp.data}
      courses={fetchCourses.data}
      careerPaths={fetchCareerPaths.data}
      jobPositions={fetchJobPositions.data}
      params={match.params}
    />
  ) : (
    <Loader />
  );
};

export default IdpEdit;
