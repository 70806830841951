import styles from "./CustomInput.module.scss";

const CustomInput = ({ value, onChange, onBlur, onFocus, name, placeholder, reference, customStyle }) => {
  return (
    <input
      style={customStyle && customStyle}
      type="text"
      name={name && name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      className={styles.input}
      placeholder={placeholder && placeholder}
      ref={reference}
    />
  );
};

export default CustomInput;
