import { sizeFormat } from "../../../utils/fileHelpers";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import ListItem from "../ListItem/ListItem";
import { languages } from "../../../dummyData/dummyData";
import { filesRepository } from "../../../repository/files.repository";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { useContext } from "react";
import { dateFormat } from "../../../utils/formatters";

const File = ({ data }) => {
  const { id, selectableId, fileImg, name, description, category, target_name, filesize, visibility, visibilityImg, created, extension, isChecked } = data;
  const { callPopup } = useContext(PopupsContext);

  return (
    <ListItem title={name} image={fileImg} selectableId={selectableId} isChecked={isChecked} imageFit={false} buttons={[
      {
        icon: "garbage",
        onClick: () => callPopup({
          type: ACTIONS.DELETE,
          payload: {
            type: languages.EN.labels.file,
            elementName: name,
            action: () => filesRepository.deleteFile(id),
          },
        }),
        tooltip: languages.EN.labels.delete,
      },
      {
        icon: "download",
        onClick: () => filesRepository.downloadFile(id, name, extension),
        tooltip: languages.EN.labels.download,
      },
      // { icon: "edit", onClick: () => callPopup({
      //     type: ACTIONS.FORM,
      //     payload: {
      //       header: `${languages.EN.labels.edit} ${languages.EN.labels.file}`,
      //       fields: popupFields(data),
      //       postAction: filesRepository.postFile,
      //     },
      //   }),
      // },
    ]}>
      <ListItemInfo value={description} />
      <ListItemInfo value={languages.EN.enums.fileCategories[category]} />
      <ListItemInfo value={target_name} />
      <ListItemInfo value={sizeFormat(filesize)} />
      <ListItemInfo value={dateFormat(created)} />
      <ListItemImgInfo value={visibility} image={visibilityImg} />
    </ListItem>
  );
};

export default File;
