import ChatTemplate from "../../templates/ChatTemplate/ChatTemplate";
import { chatRepository } from "../../../repository/chat.repository";
import useFetch from "../../../hooks/useFetch";
import { Loader } from "../../atoms/Loader/Loader";
import { useEffect } from "react";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";

const ChatPage = () => {
  const { data, isReady, fetchData } = useFetch();

  useDocumentTitleLoading();

  useEffect(() => {
    if (!isReady) {
      fetchData(chatRepository.getAllChatrooms());
    }
  }, [fetchData, isReady]);

  return isReady ? <ChatTemplate chatRooms={data} /> : <Loader />;
};

export default ChatPage;
