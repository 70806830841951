import { useCallback, useEffect, useRef, useState } from "react";
import Text from "../Text/Text";
import styles from "./Select.module.scss";
import Icons from "../../../assets/icons";
import Option from "./Option";
import Label from "../Label/Label";

const Select = ({
  items,
  placeholder,
  disabled,
  handleSelect,
  required,
  name,
  currentName,
  isEditable,
  error,
  disableHoverSelect,
  noMargin,
}) => {
  const [isTableActive, setIsTableActive] = useState(true);
  const [isClickable, setIsClickable] = useState(false);
  const [activeElement, setActiveElement] = useState(-1);
  const [enterCounter, setEnterCounter] = useState(0);
  const selectRef = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isArrowActive, setIsArrowActive] = useState(false);
  const [displayName, setDisplayName] = useState(currentName ?? "");
  const [isNotEmpty, setIsNotEmpty] = useState(false);

  useEffect(() => {
    setDisplayName(currentName);
  }, [currentName]);

  useEffect(() => {
    if (displayName) {
      setIsNotEmpty(true);
    }
  }, [displayName]);

  const handleIsActive = () => {
    if (!disabled) {
      setIsTableActive(!isTableActive);
      setIsNotEmpty(true);
    }
  };

  useEffect(() => {
    if (!displayName && !isTableActive) {
      setIsNotEmpty(false);
    }
  }, [displayName, isTableActive]);

  const hangleKeyDown = (e) => {
    if (isFocus) {
      selectRef.current.addEventListener("keydown", (e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
          e.preventDefault();
        }
      });
      if ((e.keyCode === 40 || e.keyCode === 39) && activeElement < items.length - 1) {
        setActiveElement(activeElement + 1);
      } else if ((e.keyCode === 38 || e.keyCode === 37) && activeElement > 0) {
        setActiveElement(activeElement - 1);
      }
    }
  };

  const handleKeyPress = (event) => {
    if ((event.keyCode === 13 || event.code === "Enter") && isClickable) {
      setIsTableActive(true);
      setEnterCounter(enterCounter + 1);
      event.preventDefault();
    }
  };

  const handleOnChange = useCallback((item, name) => {
    selectRef.current.blur();
    setIsFocus(false);
    setDisplayName(item.label);
    setIsTableActive(false);
    handleSelect(name, item.id);
    setIsClickable(false);
  }, [handleSelect]);

  useEffect(() => {
    if (isFocus) {
      setIsClickable(true);
      setEnterCounter(0);
    } else {
      selectRef.current.blur();
      setIsClickable(false);
      setEnterCounter(0);
      setIsTableActive(false);
    }
  }, [isFocus]);

  useEffect(() => {
    if (isTableActive) {
      setEnterCounter(1);
    }
  }, [isTableActive]);

  useEffect(() => {
    if (isFocus) {
      setIsTableActive(true);
    }
  }, [isFocus, isTableActive]);

  return (
    <Label
      placeholder={placeholder}
      isValue={isNotEmpty}
      isFocus={isFocus}
      required={required}
      error={error}
      disabled={disabled}
      noMargin={noMargin}
    >
      <div
        className={styles.Wrapper}
        tabIndex="0"
        onFocus={() => {
          if (!disabled) {
            setIsFocus(true);
          }
        }}
        onBlur={() => setIsFocus(false)}
        onKeyPress={handleKeyPress}
        onKeyDown={hangleKeyDown}
        ref={selectRef}
        name={name}
      >
        <div
          onClick={handleIsActive}
          data-active={isTableActive}
          className={`${styles.select} ${isEditable ? styles.select__white : ''} ${error ? styles.select__error : ''} ${isClickable ? styles.select__white : ''} ${styles.menuoutertop} ${disabled ? styles.select__disabled : ''}`}
        >
          <Text s12 w600 dark-4>
            {displayName}
          </Text>

          {isTableActive && (
            <div
              onMouseMove={() => setIsArrowActive(false)}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={styles.table}
            >
              {items.map((item, i) => (
                <Option
                  setActiveElement={setActiveElement}
                  isHover={isHover}
                  activeElement={activeElement}
                  isArrowActive={isArrowActive}
                  order={i}
                  key={i}
                  name={name}
                  handleOnChange={handleOnChange}
                  item={item}
                  enterCounter={enterCounter}
                  isClickable={isClickable}
                  disableHoverSelect={disableHoverSelect}
                />
              ))}
            </div>
          )}
          <div data-active={isTableActive} className={styles.arrow}>
            {Icons.bottomArrowSortPanel}
          </div>
        </div>
      </div>
    </Label>
  );
};

export default Select;
