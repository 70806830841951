import { competence_group_endpoint, competence_endpoint, competence_level_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getCompetences = () => {
  return fetch(competence_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getCompetenceDetails = (id) => {
  return fetch(`${competence_endpoint + id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addCompetence = (data) => {
  return fetch(competence_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editCompetence = (id, data) => {
  return fetch(`${competence_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const getCompetenceGroups = () => {
  return fetch(competence_group_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addCompetenceLevel = (data) => {
  return fetch(competence_level_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editCompetenceLevel = (id, data) => {
  return fetch(`${competence_level_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteCompetenceLevel = (id) => {
  return fetch(`${competence_level_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const competencesRepository = {
  getCompetences,
  getCompetenceDetails,
  addCompetence,
  editCompetence,
  getCompetenceGroups,
  addCompetenceLevel,
  editCompetenceLevel,
  deleteCompetenceLevel,
};
