import React, { useCallback, useContext, useEffect, useState } from "react";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import styles from "./PopupAddQuestion.module.scss";
import TextArea from "../../../atoms/TextArea/TextArea";
import Select from "../../../atoms/Select/Select";
import SelectSearch from "../../../atoms/SelectSearch/SelectSearch";
import SingleChoiceForm from "../../QuestionForm/SingleChoiceForm/SingleChoiceForm";
import MultipleChoiceForm from "../../QuestionForm/MultipleChoiceForm/MultipleChoiceForm";
import FillHolesForm from "../../QuestionForm/FillHolesForm/FillHolesForm";
import PickValidOrderForm from "../../QuestionForm/PickValidOrderForm/PickValidOrderForm";
import PairUpForm from "../../QuestionForm/PairUpForm/PairUpForm";
import { languages } from "../../../../dummyData/dummyData";
import { convertItemsForSelect } from "../../../../hooks/convertItemsForSelect";
import { validationForm } from "../../../../utils/validation";
import { PostContext } from "../../../../contexts/PostContext";

const PopupAddQuestion = ({ header, questionData, units, sendHandler }) => {
  const defaultData = {
    unit_id: "",
    question: "",
    correct: "",
    answers: "",
    type: "",
  };

  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    setFormData(questionData ?? defaultData);
  }, [questionData]);

  const [errors, setErrors] = useState(null);
  const { postData, postResponse } = useContext(PostContext);

  const requiredArray = [
    { name: "unit_id", type: "text" },
    { name: "question", type: "text" },
    { name: "correct", type: "text" },
    { name: "answers", type: "text" },
    { name: "type", type: "text" },
  ];

  const addQuestion = () => {
    let catchErrors = validationForm(formData, requiredArray);
    setErrors(catchErrors);

    if (Object.values(catchErrors).every((v) => v.error === false)) {
      return sendHandler({ ...formData });
    } else {
      return false;
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSelect = useCallback((name, id) => {
    setFormData((prev) => ({
      ...prev,
      unit_id: "",
      question: "",
      correct: "",
      answers: "",
      type: id,
    }));
  }, []);
  const handleSelectSearch = useCallback((name, id) => {
    setFormData((prev) => ({ ...prev, [name]: id }));
  }, []);

  const [showQuestionField, setShowQuestionField] = useState(formData.correct.length > 0);
  const [error] = useState(false);

  return (
    <>
      <Text>{header}</Text>
      <div className={styles.inputWrapper}>
        <Select
          name="type"
          currentName={languages.EN.enums.questionTypes[formData.type]}
          required={true}
          handleSelect={handleSelect}
          items={convertItemsForSelect(languages.EN.enums.questionTypes)}
          placeholder={languages.EN.labels.questionType}
          error={errors?.type.error || postResponse?.type}
          disableHoverSelect
        />
        <SelectSearch
          name="unit_id"
          currName={units.find((item) => item.id === formData.unit_id)?.label ?? ""}
          required={true}
          items={units}
          handleSelect={handleSelectSearch}
          placeholder={languages.EN.labels.courseUnit}
          error={errors?.unit_id.error || postResponse?.unit_id}
        />
        {(formData.type !== "2" || formData.correct.length === 0 || showQuestionField) && (
          <TextArea
            name={formData.type !== "2" ? "question" : "correct"}
            required={true}
            value={formData.type !== "2" ? formData.question : formData.correct}
            handleTextArea={handleOnChange}
            onFocus={() => setShowQuestionField(true)}
            onBlur={() => setShowQuestionField(false)}
            placeholder={languages.EN.labels.question}
            error={errors?.question.error || postResponse?.question}
          />
        )}
        {formData.type === "0" && (
          <SingleChoiceForm
            formData={formData}
            setFormData={setFormData}
            error={errors?.answers.error || postResponse?.answers}
          />
        )}
        {formData.type === "1" && (
          <MultipleChoiceForm
            formData={formData}
            setFormData={setFormData}
            error={errors?.answers.error || postResponse?.answers}
          />
        )}
        {formData.type === "2" && (
          <FillHolesForm
            formData={formData}
            setFormData={setFormData}
            showQuestionField={showQuestionField}
            setShowQuestionField={setShowQuestionField}
            error={errors?.answers.error || postResponse?.answers}
          />
        )}
        {formData.type === "3" && (
          <PickValidOrderForm
            formData={formData}
            setFormData={setFormData}
            error={errors?.answers.error || postResponse?.answers}
          />
        )}
        {formData.type === "4" && (
          <PairUpForm
            formData={formData}
            setFormData={setFormData}
            error={errors?.answers.error || postResponse?.answers}
          />
        )}
      </div>
      <SaveBackButtons onSave={() => postData(addQuestion)} isError={error} />
    </>
  );
};
export default PopupAddQuestion;
