import React, { useContext, useEffect, useCallback } from "react";
import styles from "./StudentLearningReportTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { languages } from "../../../dummyData/dummyData";
import PieChartTotalContainer from "../../organisms/PieChartTotalContainer/PieChartTotalContainer";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import StudentCourseProgressTable from "../../organisms/Table/StudentCourseProgressTable/StudentCourseProgressTable";
import StudentCompetenceListTable from "../../organisms/Table/StudentCompetenceListTable/StudentCompetenceListTable";
import StudentCompletedCourseTable from "../../organisms/Table/StudentCompletedCourseTable/StudentCompletedCourseTable";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../routes";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { TYPE_DATEPICKER } from "../../molecules/TopBannerButtons/TopBannerButtons";
import { dateParamFormat } from "../../../utils/formatters";

const StudentLearningReportTemplate = ({ data, userData }) => {
  const { user_reports } = data;
  const history = useHistory();
  const params = useParams();

  const pageHeader = userData.name + " " + userData.surname;
  useDocumentTitle(pageHeader);

  const {
    course_reports,
    competence_reports,
    done_course_reports,
    num_completed,
    num_in_progress,
    num_scheduled,
    num_user_competence_course,
    num_user_competence_manager,
    num_user_competence_test,
    num_user_competence,
  } = user_reports.length > 0 ? user_reports[0] : {};

  useDocumentTitle(pageHeader);
  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: languages.EN.labels.profile,
            link: generatePath(Routes.profile.statistics.base, { username: params.username }),
          },
          {
            name: languages.EN.labels.studentLearningReport,
            link: "#",
          },
        ]}
        img={userData.profile_image}
        imgSquare={true}
        infos={[
          {
            label: languages.EN.labels.reportDate,
            value: params.date ?? user_reports[0].date,
          },
        ].concat(userData.job_position ? [
          {
            label: languages.EN.labels.jobPosition,
            value: userData.job_position.name,
          }
        ] : []).concat(userData.organization ? [
          {
            label: languages.EN.labels.organization,
            value: userData.organization.name,
          }
        ] : [])}
        buttons={[
          {
            icon: "calendar2",
            name: languages.EN.labels.reportDate,
            onHoverType: TYPE_DATEPICKER,
            onSelect: (date) => history.push(generatePath(Routes.studentLearningReport.base, { username: params.username, date: dateParamFormat(date) })),
            maxDate: new Date(),
          },
        ]}
      />
      <MainTemplate padTop90>
        <div className={styles.Wrapper}>
          <div className={styles.pieChartTotalWrapper}>
            <PieChartTotalContainer
              header={languages.EN.labels.totalAssignedCourses}
              config={[
                {
                  name: languages.EN.labels.done,
                  value: num_completed,
                  color: "#B4E7E3",
                },
                {
                  name: languages.EN.labels.inProgress,
                  value: num_in_progress,
                  color: "#4B8A85",
                },
                {
                  name: languages.EN.labels.notStarted,
                  value: num_scheduled,
                  color: "#6CEBE1",
                },
              ]}
              width100
            />
            <PieChartTotalContainer
              header={languages.EN.labels.totalAssignedCompetences}
              config={[
                {
                  name: languages.EN.enums.verificationTypes[0],
                  value: num_user_competence_course,
                  color: "#B4E7E3",
                },
                {
                  name: languages.EN.enums.verificationTypes[1],
                  value: num_user_competence_test,
                  color: "#00A094",
                },
                {
                  name: languages.EN.enums.verificationTypes[2],
                  value: num_user_competence_manager,
                  color: "#6CEBE1",
                },
                {
                  name: languages.EN.labels.notVerified,
                  value: num_user_competence - (num_user_competence_course + num_user_competence_manager + num_user_competence_test),
                  color: "#4B8A85",
                },
              ]}
              width100
            />
          </div>
          <div className={styles.tablesContainer}>
            <TableWrapper
              Table={StudentCourseProgressTable}
              reports={(course_reports ?? []).filter(course => course.progress !== null)}
              title={languages.EN.labels.courseInProgress}
            />
            <TableWrapper
              Table={StudentCompletedCourseTable}
              reports={done_course_reports ?? []}
              title={languages.EN.labels.completedCourses}
            />
            <TableWrapper
              Table={StudentCompetenceListTable}
              reports={competence_reports ?? []}
              title={languages.EN.labels.competenceList}
            />
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default StudentLearningReportTemplate;
