import OrganizationTemplate from "../../templates/OrganizationTemplate/OrganizationTemplate";
import useFetch from "../../../hooks/useFetch";
import { organizationRepository } from "../../../repository/organization.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";
import { useCurrentRoute } from "../../../hooks/useCurrentRoute";
import { Routes } from "../../../routes";

const Organization = ({ match }) => {
  const { isResponseOk } = useContext(PostContext);
  const currentRoute = useCurrentRoute(Routes.organization);

  useDocumentTitleLoading();

  const fetchOrganization = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchOrganization.data) {
      fetchOrganization.fetchData(organizationRepository.getOrganization(match.params.id));
    }
  }, [isResponseOk]);

  const fetchCertificates = useFetch();
  useEffect(() => {
    if (currentRoute === Routes.organization.certificates && (isResponseOk || !fetchCertificates.data)) {
      fetchCertificates.fetchData(organizationRepository.getOrganizationCertificates(match.params.id));
    }
  }, [isResponseOk, currentRoute]);

  const fetchBadges = useFetch();
  useEffect(() => {
    if (currentRoute === Routes.organization.badges && (isResponseOk || !fetchBadges.data)) {
      fetchBadges.fetchData(organizationRepository.getOrganizationBadges(match.params.id));
    }
  }, [isResponseOk, currentRoute]);

  const fetchRewards = useFetch();
  useEffect(() => {
    if (currentRoute === Routes.organization.rewards && (isResponseOk || !fetchRewards.data)) {
      fetchRewards.fetchData(organizationRepository.getOrganizationRewards(match.params.id));
    }
  }, [isResponseOk, currentRoute]);

  return fetchOrganization.data ? (
    <OrganizationTemplate organization={fetchOrganization.data} certificates={fetchCertificates.data} badges={fetchBadges.data} rewards={fetchRewards.data} />
  ) : (
    <Loader />
  );
};

export default Organization;
