import { document_audit_endpoint, documents_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";
import { mimeTypes } from "./files.repository";

const getReferences = () => {
  return fetch(documents_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getReference = (id) => {
  return fetch(documents_endpoint + id + "/", {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addReference = (data) => {
  return fetch(documents_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editReference = (id, data) => {
  return fetch(`${documents_endpoint}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const addAudit = (data) => {
  return fetch(document_audit_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editAudit = (id, data) => {
  return fetch(`${document_audit_endpoint}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(Object.fromEntries(Object.entries(data).filter(([key, value]) => ['action_required', 'audit_date', 'audit_files', 'due_date', 'new_document', 'next_audit_date', 'revision_summary', 'status'].includes(key) && value !== null))),
  });
};

const downloadAuditFile = (id, name) => {
  const extension = name.substring(name.lastIndexOf('.') + 1);

  return fetch(`${document_audit_endpoint}download/${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.download = name;
      link.href = URL.createObjectURL(new Blob([blob], { type: mimeTypes[extension] ?? 'text/plain' }));
      link.click();
    });
};

export const referencesRepository = {
  getReferences,
  getReference,
  addReference,
  editReference,
  addAudit,
  editAudit,
  downloadAuditFile,
};
