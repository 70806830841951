import React, { useState, useEffect, useContext, useMemo } from "react";
import styles from "./MultipleChoice.module.scss";
import Text from "../../../atoms/Text/Text";
import { CompetenceTestContext } from "../../../../contexts/CompetenceTestContext";
import { shuffle } from "../../../../utils/arrayHelpers";

const MultipleChoice = ({ data }) => {
  const { answers, question } = data;
  const { savedAnswers, currentPosition, setCurrentAnswer } = useContext(CompetenceTestContext);

  const shuffledAnswersOrder = useMemo(() => shuffle(Array.from(answers.keys())), [answers]);

  const getDefaultLocalData = () => {
    return [];
  }

  const parseSavedAnswer = () => {
    return savedAnswers[currentPosition].split(",").map(answer => shuffledAnswersOrder.indexOf(parseInt(answer)));
  }

  const [localData, setLocalData] = useState(getDefaultLocalData());

  useEffect(() => {
    setLocalData(savedAnswers[currentPosition] ? parseSavedAnswer() : getDefaultLocalData());
  }, [savedAnswers, currentPosition]);

  const handleSelectAnswer = (id) => {
    let newData = localData.includes(id) ? localData.filter(answer => answer !== id) : [...localData, id];
    setLocalData(newData);

    let currentAnswer = newData.map(shuffledId => shuffledAnswersOrder[shuffledId]);
    currentAnswer.sort((a, b) => a - b);
    setCurrentAnswer(currentAnswer.join(","));
  }

  return (
    <div className={styles.registrationQuestion}>
      <Text s14 lh24 dark-4>
        {question}
      </Text>

      {shuffledAnswersOrder.map((originalId, shuffledId) => (
        <div key={shuffledId} className={styles.wrapper}>
          <label className={styles.innerWrapper} htmlFor={shuffledId}>
            <input
              type="checkbox"
              name="name"
              id={shuffledId}
              checked={localData.includes(shuffledId)}
              onChange={() => handleSelectAnswer(shuffledId)}
            />
            <span className={styles.checkbox}/>
            <span className={styles.label}>
              <Text s12>{answers[originalId]}</Text>
            </span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default MultipleChoice;
