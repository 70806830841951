import { useCallback, useEffect, useRef, useState } from "react";
import Text from "../Text/Text";
import styles from "./SelectSearch.module.scss";
import OutsideClickHandler from "react-outside-click-handler";
import Input from "../Input/Input";
import SelectSearchElement from "./SelectSearchElement";
import { languages } from "../../../dummyData/dummyData";

const SelectSearch = ({
  items,
  placeholder,
  disabled,
  handleSelect,
  required,
  name,
  withImg,
  resetInput,
  error,
  currName,
  noMargin,
  darkBorder,
  autoFocus,
}) => {
  const [isTableActive, setIsTableActive] = useState(false);
  const [inputValue, setInputValue] = useState(currName ?? "");
  const [enterCounter, setEnterCounter] = useState(0);
  const [activeElement, setActiveElement] = useState(-1);
  const [isHover, setIsHover] = useState(false);
  const [arrowTrigger, setArrowTrigger] = useState(false);
  const [isFocus, setIsfocus] = useState(false);

  useEffect(() => {
    setInputValue(currName ?? "");
  }, [currName]);

  const handleInput = (e) => {
    setInputValue(e.target.value);

    if (!e.target.value.length) {
      handleSelect(name, "", "");
    }
  };

  const inputRef = useRef(null);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsTableActive(true);
      setEnterCounter(enterCounter + 1);
    }
  };
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      if (resetInput) setInputValue("");
    }
    return () => {
      isMount = true;
    };
  }, [resetInput]);

  const handleOrder = (e) => {
    if (e.keyCode === 40 && activeElement < items.length - 1) {
      setActiveElement(activeElement + 1);
      setArrowTrigger(!arrowTrigger);
    } else if (e.keyCode === 38 && activeElement > 0) {
      setActiveElement(activeElement - 1);
      setArrowTrigger(!arrowTrigger);
    }
  };
  const handleOnChange = useCallback(
    (item, name) => {
      setInputValue(item.label);
      handleSelect(name, item.id, item);
      setActiveElement(0);
      setEnterCounter(0);
      setIsTableActive(false);
    },
    [handleSelect]
  );

  useEffect(() => {
    if (isFocus) {
      setIsTableActive(true);
    }
  }, [isFocus]);

  const handleArrows = (e) => {
    if (enterCounter === 0) {
      if (
        (e.keyCode === 40 || e.keyCode === 39) &&
        activeElement < items.length - 1
      ) {
        setActiveElement(activeElement + 1);
        e.preventDefault();
      } else if ((e.keyCode === 38 || e.keyCode === 37) && activeElement > 0) {
        setActiveElement(activeElement - 1);
        e.preventDefault();
      }
    }
  };

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const itemsLength = items?.filter((item) => {
      if (inputValue === "") {
        return item;
      } else if (
        item.label.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase())
      ) {
        return item;
      }
      return false;
    });
    if (itemsLength) setCurrentItems([...itemsLength]);
  }, [inputValue, items]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => setIsTableActive(false)}
      children={
        <div className={styles.Wrapper}>
          <Input
            reference={inputRef}
            name={name}
            noMargin={noMargin}
            placeholder={placeholder}
            disabled={disabled}
            handleInput={(e) => handleInput(e)}
            value={inputValue}
            searchBar={true}
            selectSearch
            error={error}
            required={required}
            onBlur={() => {
              setIsfocus(false);
            }}
            onFocus={() => setIsfocus(true)}
            onKeyPress={handleKeyPress}
            onKeyDown={(e) => {
              handleArrows(e);
              handleOrder(e);
            }}
            darkBorder={darkBorder}
            autoFocus={autoFocus}
          />
          {isTableActive && currentItems.length > 0 ? (
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={`${styles.tableWrapper}`}
            >
              <div className={`${styles.table}`}>
                {items
                  .filter((item) => {
                    if (inputValue === "") {
                      return item;
                    } else if (
                      item.label
                        .toLocaleLowerCase()
                        .includes(inputValue.toLocaleLowerCase())
                    ) {
                      return item;
                    }
                    return false;
                  })
                  .map((item, i) => (
                    <SelectSearchElement
                      img={withImg}
                      handleOnChange={handleOnChange}
                      name={name}
                      order={i}
                      enterCounter={enterCounter}
                      activeElement={activeElement}
                      setActiveElement={setActiveElement}
                      setIsTableActive={setIsTableActive}
                      setIsfocus={setIsfocus}
                      isHover={isHover}
                      item={item}
                      key={i}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <>
              {isTableActive && (
                <div
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  className={styles.tableWrapper}
                >
                  <Text s12 w600 dark-3>
                    {languages.EN.labels.thereAreNoResults}
                  </Text>
                </div>
              )}
            </>
          )}
        </div>
      }
    />
  );
};
export default SelectSearch;
