import styles from "./MultipleChoiceForm.module.scss";
import Button from "../../../atoms/Button/Button";
import { useState } from "react";
import CustomInput from "../../../atoms/CustomInput/CustomInput";
import { languages } from "../../../../dummyData/dummyData";
import Text from "../../../atoms/Text/Text";

const MultipleChoiceForm = ({ formData, setFormData, error }) => {
  const [localData, setLocalData] = useState(formData.answers.split("|").map((answer, i) => ({
    id: i,
    isValid: formData.correct.split(",").includes(i.toString()),
    answer: answer
  })));

  const addRow = (i) => {
    setLocalData([...localData, { id: i, isValid: false, answer: "" }]);
  };

  const handleAnswerChange = (e, id) => {
    const newData = [...localData].map((item) => item.id !== id ? item : {...item, answer: e.target.value});
    setLocalData(newData);
    setFormData({
      ...formData,
      answers: newData.map((item) => item.answer).join("|"),
    });
  };

  const handleInputChange = (id) => {
    const newData = [...localData].map((item) => item.id !== id ? item : {...item, isValid: !item.isValid});
    setLocalData(newData);
    setFormData({
      ...formData,
      correct: newData.filter((item) => item.isValid).map((item) => item.id).join(","),
    });
  };

  return (
    <>
      <div className={`${styles.wrapper} ${error ? styles.wrapper__error : ''}`}>
        <span data-active={true} className={`${styles.label} ${styles.label__white} ${error ? styles.label__error : ''}`}>
          {languages.EN.labels.answers}
        </span>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>
                <Text s12 w400 lh24>{`${languages.EN.labels.isValid.toUpperCase()}?`}</Text>
              </td>
              <td>
                <Text s12 w400 lh24>
                  {languages.EN.labels.answer.toUpperCase()}
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>
            {localData.map((row, i) => (
              <tr key={i}>
                <td>
                  <input
                    type='checkbox'
                    checked={row.isValid}
                    onChange={() => handleInputChange(row.id)}
                  />
                </td>
                <td>
                  <CustomInput
                    value={row.answer}
                    placeholder={languages.EN.labels.provideAnswer}
                    onChange={(e) => handleAnswerChange(e, row.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.errorMsg}>
        <Text s10 lh12>
          {error && error}
        </Text>
      </div>
      <div className={styles.addButton}>
        <Button
          withIcon
          icon='smallPlus'
          variant='primary'
          onClick={() => addRow(localData.length)}
        >
          {languages.EN.labels.add}
        </Button>
      </div>
    </>
  );
};

export default MultipleChoiceForm;
