import PlatformDashboardTemplate from "../../templates/PlatformDashboardTemplate/PlatformDashboardTemplate";
import useFetch from "../../../hooks/useFetch";
import { PlatformDashboardRepository } from "../../../repository/platformDashboard.repository";
import { useEffect, useContext } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useHistory } from "react-router";
import { UserContext } from "../../../contexts/UserContext";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";

const PlatformDashboard = () => {
  const { data, isReady, fetchData } = useFetch();

  const { userContextLevel, handleSwitchHomepage } = useContext(UserContext);

  const history = useHistory();
  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (userContextLevel !== undefined || null) {
        if (userContextLevel === 3) {
          fetchData(PlatformDashboardRepository.getPlatformDashboard());
        } else {
          handleSwitchHomepage();
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [fetchData, handleSwitchHomepage, history, userContextLevel]);

  useDocumentTitleLoading();

  return isReady ? <PlatformDashboardTemplate data={data} /> : <Loader />;
};

export default PlatformDashboard;
