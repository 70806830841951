import React, { useEffect, useRef, useState } from "react";
import styles from "./CurrentLevelSlider.module.scss";
import { ReactComponent as DotIcon } from "../../../assets/icons/dotIcon.svg";
import { ReactComponent as DotIcon2 } from "../../../assets/icons/dotIcon2.svg";
import { ReactComponent as DotIcon3 } from "../../../assets/icons/dotIcon3.svg";
import { ReactComponent as Elipse } from "../../../assets/icons/elipseIcon.svg";
import PopupLearningOutcomes from "../../molecules/Popups/PopupLearningOutcomes/PopupLearningOutcomes";

const widthOfGreenBars = {
  0: 25,
  1: 50,
  2: 75,
  3: 100,
};

const CurrentLevelSlider = ({ level, competenceLevels, onChange }) => {
  const [width, setWidth] = useState(25);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setWidth(widthOfGreenBars[level])
  }, [level]);

  const handlelevel = (level) => {
    setWidth(widthOfGreenBars[level]);
    onChange(level);
    setIsClicked(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsClicked(false);
    }, 100);
  }, [isClicked]);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const onDragStartCircle = () => setIsMouseDown(true);
  const onDragEndCircle = () => setIsMouseDown(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      document.addEventListener("mouseup", onDragEndCircle);
      return () => {
        document.removeEventListener("mouseup", onDragEndCircle);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [elementHovered, setElementHovered] = useState(null);

  return (
    <div ref={wrapperRef} onMouseUp={onDragEndCircle}>
      <div className={styles.range} onMouseUp={onDragEndCircle}>
        <div
          className={isClicked ? styles.background2 : styles.background}
          style={{
            width: width + "%",
          }}
        />
        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => isMouseDown && handlelevel(0)}
          onClick={() => handlelevel(0)}
        >
          <Elipse className={styles.range__button_dot} />
        </div>

        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => {
            isMouseDown && handlelevel(1);
            setElementHovered(1);
          }}
          onClick={() => {
            competenceLevels[1] && handlelevel(1);
          }}
          onMouseLeave={() => setElementHovered(null)}
        >
          <DotIcon
            className={isClicked ? styles.dotActive : styles.firstDot}
            data-active={width >= widthOfGreenBars[1]}
            data-blocked={!competenceLevels[1]}
          />
          {elementHovered === 1 && (
            <PopupLearningOutcomes learningOutcomes={competenceLevels[1]?.learning_outcomes} />
          )}
        </div>
        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => {
            isMouseDown && competenceLevels[2] && handlelevel(2);
            setElementHovered(2);
          }}
          onClick={() => {
            competenceLevels[2] && handlelevel(2);
          }}
          onMouseLeave={() => setElementHovered(null)}
        >
          <DotIcon2
            className={isClicked ? styles.dotActive : styles.secondDots}
            data-active={width >= widthOfGreenBars[2]}
            data-blocked={!competenceLevels[2]}
          />
          {elementHovered === 2 && (
            <PopupLearningOutcomes learningOutcomes={competenceLevels[2]?.learning_outcomes} />
          )}
        </div>
        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => {
            isMouseDown && competenceLevels.length >= 3 && handlelevel(3);
            setElementHovered(3);
          }}
          onClick={() => {
            competenceLevels[3] && handlelevel(3);
          }}
          onMouseLeave={() => setElementHovered(null)}
        >
          <DotIcon3
            className={isClicked ? styles.dotActive : styles.thirdDots}
            data-active={width >= widthOfGreenBars[3]}
            data-blocked={!competenceLevels[3]}
          />
          {elementHovered === 3 && (
            <PopupLearningOutcomes learningOutcomes={competenceLevels[3]?.learning_outcomes} />
          )}
        </div>
      </div>
    </div>
  );
};
export default CurrentLevelSlider;
