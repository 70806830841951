import { useContext } from "react";
import { authRepository } from "../../../repository/authRepository";
import { PostContext } from "../../../contexts/PostContext";
import { UserContext } from "../../../contexts/UserContext";
import NewPasswordTemplate from "../generic/NewPasswordTemplate/NewPasswordTemplate";

const PasswordChangeTemplate = () => {
  const { postData } = useContext(PostContext);
  const { handleSwitchHomepage, handleLogout } = useContext(UserContext);

  return (
    <NewPasswordTemplate
      onSave={(formData) => postData(() => authRepository.changePassword(formData), handleSwitchHomepage)}
      onBack={handleLogout}
    />
  );
};
export default PasswordChangeTemplate;
