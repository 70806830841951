import moment from "moment";

export const isValidDateString = (value) => {
  return value.substring(0, 10).match(/\d{4}[\/-]\d{2}[\/-]\d{2}/) !== null && !isNaN(Date.parse(value));
};

export const enumerateMonths = () => {
  moment.locale('en');
  return moment.months();
}

export const gettQuarterDatesRange = (quarter, year) => {
  const currentQuarter = moment(year).quarter(quarter);

  return {
    start_date: currentQuarter.startOf('quarter').format("YYYY-MM-DD"),
    end_date: currentQuarter.endOf('quarter').format("YYYY-MM-DD"),
  };
}
