import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./CourseViewTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Routes } from "../../../routes";
import Text from "../../atoms/Text/Text";
import { pdfjs } from "react-pdf";
import ReactPlayer from "react-player/vimeo";
import playVideo from "../../../assets/icons/playVideo.svg";
import parse from "html-react-parser";
import { coursesRepository } from "../../../repository/courses.repository";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { generatePath, Link, Route, Switch, useHistory, useParams } from "react-router-dom";
import Agenda from "../../molecules/Agenda/Agenda";
import PdfPlayer from "../../organisms/PdfPlayer/PdfPlayer";
import useFetch from "../../../hooks/useFetch";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import CompetenceTestReportCharts from "../../organisms/CompetenceTestReportCharts/CompetenceTestReportCharts";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { ContextLoader, Loader } from "../../atoms/Loader/Loader";
import { PROGRESS_TYPE_MATERIAL, PROGRESS_TYPE_PDF, PROGRESS_TYPE_VIDEO } from "../../pages/CourseView/CourseView";
import { useIdleTimer } from 'react-idle-timer'
import CourseMaterial from "../../organisms/CourseMaterial/CourseMaterial";
import Button from "../../atoms/Button/Button";
import ReferencesList from "../../organisms/ReferencesList/ReferencesList";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { useCurrentRoute } from "../../../hooks/useCurrentRoute";
import { ReportProblemContext } from "../../../contexts/ReportProblemContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const TEASER_BLOCK_ID = 'teaser';

const proposeBlockId = (sections, units) => {
  const firstNotCompletedUnit = units.find(unit => unit.completion < 1)

  if (firstNotCompletedUnit) {
    return firstNotCompletedUnit.block_id;;
  } else {
    const lastCompletedTest = sections.findLast(section => section.competence_test.status === 2)?.competence_test;

    if (lastCompletedTest) {
      return lastCompletedTest.id;
    } else {
      return units[units.length - 1].block_id;
    }
  }
}

const findSelectedBlock = (sections, selectedBlockId) => {
  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].unit_data.length; j++) {
      if (sections[i].unit_data[j].block_id === selectedBlockId) {
        return sections[i].unit_data[j];
      }
    }
  }

  return undefined;
};

const findNextBlock = (sections, selectedBlockId) => {
  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].unit_data.length; j++) {
      if (sections[i].unit_data[j].block_id === selectedBlockId && j+1 < sections[i].unit_data.length) {
        if (isValidNextBlock(sections[i].unit_data[j+1])) {
          return sections[i].unit_data[j+1].completion < 1 ? sections[i].unit_data[j+1] : findNextBlock(sections, sections[i].unit_data[j+1].block_id);
        } else if (isValidNextBlock(sections[i+1].unit_data[0])) {
          return sections[i+1].unit_data[0].completion < 1 ? sections[i+1].unit_data[0] : findNextBlock(sections, sections[i+1].unit_data[0].block_id);
        } else {
          return undefined;
        }
      }
    }
  }
};

const isValidNextBlock = (block) => {
  return block !== undefined && block.title !== "Competence test";
};

const CourseViewTemplate = ({ content, details, onProgress, playbackRate, onPlaybackRateChange }) => {
  const currentRoute = useCurrentRoute(Routes.courseView);
  const { setSubject } = useContext(ReportProblemContext)
  const params = useParams();
  const course = details.course;
  const instructors = course.course_instructor.length === 0 ? [{
    name: course.instructor_text
  }] : course.course_instructor;

  const links = [
    {
      content: languages.EN.labels.about,
      href: generatePath(Routes.courseView.about.base, { id: course.id, block: params.block }),
    },
    {
      content: languages.EN.labels.practiceActivities,
      href: generatePath(Routes.courseView.practice.base, { id: course.id, block: params.block }),
    },
    {
      content: languages.EN.labels.additionalMaterials,
      href: generatePath(Routes.courseView.materials.base, { id: course.id, block: params.block }),
    },
    {
      content: languages.EN.labels.references,
      href: generatePath(Routes.courseView.references.base, { id: course.id, block: params.block }),
    },
  ];

  useDocumentTitle(`${course.display_name} | ${languages.EN.labels.courseView}`);
  let history = useHistory();

  const preparedData = {
    additional_materials: content.additional_materials,
    section_data: content.section_data.map((item, idx) => ({
      ...item,
      prepared_unit_names: item.unit_data.map((item) => item.title),
      filter_id: idx,
      unit_data: [
        ...item.unit_data,
        {
          title: languages.EN.labels.competenceTest,
          block_id: item.competence_test.id,
          video_url: null,
          pdf_id: null,
        }
      ],
    })),
    units: content.section_data.reduce((acc, curr) => acc.concat(curr.unit_data), []),
  };

  const cpa = preparedData.additional_materials.filter(material => material.practical);
  const alm = preparedData.additional_materials.filter(material => !material.practical);

  const trailerSection = course.video_preview ? { video_url: course.video_preview, pdf_id: null, block_id: TEASER_BLOCK_ID, title: languages.EN.labels.teaser } : null;

  const [videoUrl, setVideoUrl] = useState(null);
  const [contentPdfUrl, setContentPdfUrl] = useState(null);
  const [materialPdfUrl, setMaterialPdfUrl] = useState(null);

  const [formData, setFormData] = useState();
  const [selectedBlock, setSelectedBlock] = useState();

  const [openedContentPdfId, setOpenedContentPdfId] = useState(null);
  const [openedMaterialPdfId, setOpenedMaterialPdfId] = useState(null);
  const [openedCompetenceTestId, setOpenedCompetenceTestId] = useState(null);
  const [openedCompetenceTestAttempt, setOpenedCompetenceTestAttempt] = useState(null);
  const [shouldResumeVideo, setShouldResumeVideo] = useState(false);

  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!params.block) {
      history.push(generatePath(currentRoute.base, { id: course.id, block: proposeBlockId(preparedData.section_data, preparedData.units) }));
    } else {
      let foundBlock = params.block.includes(TEASER_BLOCK_ID) ? trailerSection : findSelectedBlock(preparedData.section_data, params.block);

      if (foundBlock !== undefined) {
        handleBlockChange(foundBlock);
      }
    }
  }, [params.block]);

  const fetchCompetenceTestSummary = useFetch();
  const handleBlockChange = (block) => {
    setSelectedBlock({
      ...block,
      links: details.references.filter(reference => reference.is_link && block.document_ids.includes(reference.document.id.toString()))
    })

    setIsVideoReady(block.video_url === videoUrl);
    setVideoUrl(block.video_url);
    setOpenedContentPdfId(block.pdf_id);
    setOpenedCompetenceTestId(null);
    setOpenedCompetenceTestAttempt(null);

    if (block.video_url === null && block.pdf_id === null) {
      setOpenedCompetenceTestId(block.block_id);
      setOpenedCompetenceTestAttempt(content.section_data.find(section => section.competence_test.id === block.block_id).competence_test.attempts);
      fetchCompetenceTestSummary.fetchData(competenceTestRepository.getTestSummary(block.block_id))
    }
    setFormData({ ...formData, item_id: block.block_id, unitName: block.title });
  }

  useEffect(() => {
    if (openedContentPdfId) {
      coursesRepository.getCourseContentPdf(openedContentPdfId).then(url => {
        setContentPdfUrl(url);
      });
    }
  }, [openedContentPdfId]);

  useEffect(() => {
    if (openedMaterialPdfId) {
      coursesRepository.getCourseMaterialPdf(openedMaterialPdfId).then(url => {
        setMaterialPdfUrl(url);
        openAdditionalPlayer();
      });
    }
  }, [openedMaterialPdfId]);

  useEffect(() => {
    if (formData !== undefined) {
      setSubject(formData.unitName);
    }
  }, [formData]);

  // Statistics

  const videoPlayer = useRef(null);
  const idleTimer = useIdleTimer({ timeout: 10000 });

  useEffect(() => {
    if (openedContentPdfId !== null && openedMaterialPdfId === null) {
      const interval = setInterval(() => {
        if (!idleTimer.isIdle()) {
          onProgress(PROGRESS_TYPE_PDF, formData.item_id);
        }
      }, 10000);
      return () => clearTimeout(interval);
    }
  }, [openedContentPdfId, openedMaterialPdfId]);

  useEffect(() => {
    if (openedMaterialPdfId !== null) {
      const interval = setInterval(() => {
        if (!idleTimer.isIdle()) {
          onProgress(PROGRESS_TYPE_MATERIAL, openedMaterialPdfId);
        }
      }, 10000);
      return () => clearTimeout(interval);
    }
  }, [openedMaterialPdfId]);

  // Additional material

  const openAdditionalPlayer = () => {
    videoPlayer.current && videoPlayer.current.getInternalPlayer().getPaused().then((paused) => {
      setShouldResumeVideo(!paused);

      if (!paused) {
        videoPlayer.current.getInternalPlayer().pause();
      }
    })
  }

  const closeAdditionalPlayer = () => {
    setOpenedMaterialPdfId(null);
    if (shouldResumeVideo) {
      videoPlayer.current.getInternalPlayer().play();
    }
  }

  return (
    <section>
      <NavBar />
      <TopBanner
        header={course.display_name}
        img={course.profile_image_medium}
        path={[
          {
            name: languages.EN.labels.courses,
            link: Routes.courses.base,
          },
          {
            name: languages.EN.labels.courseDetails,
            link: generatePath(Routes.courseDetails.statistics.base, { id: course.id }),
          },
          {
            name: languages.EN.labels.courseView,
            link: "#",
          },
        ]}
        infos={instructors.map(instructor => (
          {
            label: languages.EN.labels.instructor,
            value: instructor.name,
          }
        ))}
        buttons={instructors.map(instructor => [].concat(instructor.linkedin_url ? [
          {
            icon: "linkedin2",
            name: instructors.length > 1 ?
              languages.EN.labels.instructorLinkedInProfile.replace("Instructor", instructor.name) :
              languages.EN.labels.instructorLinkedInProfile,
            onClick: () => {
              window.open(instructor.linkedin_url);
            }
          },
        ] : []).concat(instructor.id ? [
          {
            icon: "dots",
            name: instructors.length > 1 ?
              languages.EN.labels.instructorDetails.replace("Instructor", instructor.name) :
              languages.EN.labels.instructorDetails,
            onClick: () => {
              history.push(generatePath(Routes.instructorDetails.base, { id: instructor.id }));
            },
          },
        ] : [])).flat(1)}
      />
      <MainTemplate padTop90 padBottom0>
        <div className={styles.Wrapper}>
          <div className={styles.mainContent}>
            <FilterContextProvider>
              <Agenda
                courseId={course.id}
                data={preparedData.section_data}
                activeUnitId={formData?.item_id}
                trailerSection={trailerSection}
              />
            </FilterContextProvider>
            {(videoUrl !== null || openedContentPdfId !== null) && (
              <div className={styles.playerSection}>
                {videoUrl !== null && (
                  <>
                    <div className={styles.viedoWrapper}>
                      <ReactPlayer
                        className={styles.reactPlayer}
                        ref={videoPlayer}
                        url={videoUrl}
                        controls
                        playIcon={<img src={playVideo} width="200px" alt="" />}
                        playing={isPlaying}
                        playbackRate={playbackRate}
                        onPlay={() => {
                          setIsPlaying(true);
                        }}
                        onProgress={(progress) => {
                          setFormData({ ...formData });
                          if (!formData.item_id.includes(TEASER_BLOCK_ID)) {
                            onProgress(PROGRESS_TYPE_VIDEO, formData.item_id, progress);
                          }
                        }}
                        onReady={() => {
                          setIsVideoReady(true);
                          videoPlayer.current.player.seekTo(selectedBlock.completion < 1.0 ? parseFloat(Math.max(selectedBlock.completion * selectedBlock.duration - 10, 0)) : 0.0);
                        }}
                        onPlaybackRateChange={onPlaybackRateChange}
                        onEnded={() => {
                          const nextBlock = findNextBlock(preparedData.section_data, selectedBlock.block_id);

                          if (nextBlock !== undefined) {
                            history.push(generatePath(currentRoute.base, { id: course.id, block: nextBlock.block_id }));
                          }
                        }}
                      />
                      {!isVideoReady && <Loader />}
                    </div>
                    <Text s20 w800 lh32 secondary>
                      {formData.unitName}
                    </Text>
                    {selectedBlock.links.length > 0 && selectedBlock.links.map((link, i) => (
                      <Link key={i} to={{ pathname: link.document.indirect_link ?? link.document.link }} target="_blank"><Button key={i} variant="primary">{languages.EN.labels.link} {link.reference_id}</Button></Link>
                    ))}
                  </>
                )}

                {openedContentPdfId !== null && (
                  <div className={styles.pdfWrapper}>
                    <div className={styles.pdfInner}>
                      <PdfPlayer url={contentPdfUrl} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {openedCompetenceTestId && (
              fetchCompetenceTestSummary.isReady ? <CompetenceTestReportCharts data={fetchCompetenceTestSummary.data} courseId={course.id} attempt={openedCompetenceTestAttempt} /> : <ContextLoader />
            )}
          </div>
        </div>

        <SwitchMenu links={links} />
        <TabContentTemplate>
          <Switch>
            <Route exact path={Routes.courseView.about.base}>
              <div className={styles.box}>
                <div className={styles.description}>
                  {parse(details.course_about)}
                </div>
              </div>
            </Route>
            <Route path={Routes.courseView.practice.base}>
              <div className={styles.box}>
                <ul className={styles.list}>
                  {cpa.length > 0 ? cpa.map((material, id) => (
                    <CourseMaterial key={id} material={material} onClick={() => material.downloadable ? coursesRepository.downloadCourseMaterial(material.id, material.name) : setOpenedMaterialPdfId(material.id)} />
                  )) : languages.EN.labels.noData}
                </ul>
                {openedMaterialPdfId !== null && materialPdfUrl && (
                  <div className={styles.pdfAdditionalContainer}>
                    <PdfPlayer url={materialPdfUrl} closeHandler={closeAdditionalPlayer} />
                  </div>
                )}
              </div>
            </Route>
            <Route path={Routes.courseView.materials.base}>
              <div className={styles.box}>
                <ul className={styles.list}>
                  {alm.length > 0 ? alm.map((material, id) => (
                    <CourseMaterial key={id} material={material} onClick={() => material.downloadable ? coursesRepository.downloadCourseMaterial(material.id, material.name) : setOpenedMaterialPdfId(material.id)} />
                  )) : languages.EN.labels.noData}
                </ul>
                {openedMaterialPdfId !== null && materialPdfUrl && (
                  <div className={styles.pdfAdditionalContainer}>
                    <PdfPlayer url={materialPdfUrl} closeHandler={closeAdditionalPlayer} />
                  </div>
                )}
              </div>
            </Route>
            <Route path={Routes.courseView.references.base}>
              <div className={styles.box}>
                <ReferencesList references={details.references} activeReferences={selectedBlock?.document_ids ?? []} />
              </div>
            </Route>
          </Switch>
        </TabContentTemplate>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CourseViewTemplate;
