import WorkgroupsTemplate from "../../templates/WorkgroupsTemplate/WorkgroupsTemplate";
import useFetch from "../../../hooks/useFetch";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { useContext } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { FilterContextProvider } from "../../../contexts/FilterContext";

const Workgroups = () => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(workgroupsRepository.getWorkgroups());
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <WorkgroupsTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default Workgroups;
