import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import JobPositionsChart from "../JobPositionsChart/JobPositionsChart";
import styles from "../IdpCareerContainer/IdpCareerContainer.module.scss";

const IdpJobPositionsChart = ({ chartData, additionalChartData }) => {
  return (
    <>
      {chartData.length === 0 && additionalChartData.length === 0 && (
        <Text s16 lh32 dark-2>{languages.EN.labels.noData}</Text>
      )}
      {chartData.length > 0 && (
        <JobPositionsChart data={chartData} isIdp />
      )}
      {additionalChartData.length > 0 && (
        <span className={styles.additionalCharts}>
          {additionalChartData.map((chartData, i) => (
            <JobPositionsChart data={[chartData]} key={i} isIdp />
          ))}
        </span>
      )}
    </>
  );
};

export default IdpJobPositionsChart
