import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItem from "../ListItem/ListItem";
import { Routes } from "../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { PROGRESS_BAR_SETTINGS } from "../CourseAuditsList/CourseAuditsList";
import VerificationBar from "../../molecules/VerificationBar/VerificationBar";
import { dateFormat } from "../../../utils/formatters";
import { languages } from "../../../dummyData/dummyData";

const CourseAuditItem = ({ data }) => {
  const { created, user, document_audit, referenceAuditText, revision_summary, actionRequiredText, dateText, status, statusText, version, actionsSummaryText, buttons } = data;

  let history = useHistory();

  return (
    <ListItem
      title={dateFormat(created)}
      subtitle={`${languages.EN.labels.by} ${user.profile.name} ${user.profile.surname}`}
      image={user.profile.profile_image_small}
      bar={(
        <VerificationBar
          text={statusText.toUpperCase()}
          progress={PROGRESS_BAR_SETTINGS[status].progress}
          variant={PROGRESS_BAR_SETTINGS[status].variant}
        />
      )}
      buttons={buttons}
    >
      <ListItemInfo value={referenceAuditText} button={referenceAuditText ? {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.referenceAudit.relatedCourses.base, {
          reference: document_audit.previous_version.id,
          audit: document_audit.id,
        })),
        tooltip: languages.EN.labels.details,
      } : null} />
      <ListItemInfo value={revision_summary} />
      <ListItemInfo value={actionRequiredText} />
      <ListItemInfo value={actionsSummaryText} button={version !== null ? {
        icon: "dots",
        onClick: () => window.open(generatePath(Routes.courseEdit.conspect.base, {
          id: version.id,
        })),
        tooltip: languages.EN.labels.details,
      } : null} />
      <ListItemInfo value={dateText} />
    </ListItem>
  );
};

export default CourseAuditItem;
