import styles from "./MemberLicenseInfo.module.scss";
import Button from "../../atoms/Button/Button";
import React, { useContext } from "react";
import { Routes } from "../../../routes";
import { useHistory } from "react-router-dom";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { teamRepository } from "../../../repository/team.repository";

const MemberLicenseInfo = ({ license, username, canEdit }) => {
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const isExpiring = (new Date(license.expiry_date) - new Date()) / (1000 * 60 * 60 * 24) < 30;

  return (
    <span className={styles.info} data-expiring={isExpiring}>
      <span>
        {license.number}
        {canEdit && isExpiring && (
          <Button
            icon="edit"
            onlyIcon
            variant="iconButton"
            onClick={() => {
              history.push({
                pathname: Routes.organizationLicenses.base,
                state: {
                  scrollToLicenses: true,
                  licenseNumber: license.number,
                },
              });
            }}
            tooltip={languages.EN.labels.edit}
          />
        )}
        {!canEdit && isExpiring && (
          <Button
            icon="message"
            onlyIcon
            variant="iconButton"
            onClick={() => {
              callPopup({
                type: ACTIONS.POST_CONFIRM,
                payload: {
                  header: languages.EN.labels.confirmation,
                  content: languages.EN.messages.informOrganization,
                  action: () => teamRepository.sendLicenseRemind({ user: username, license_id: license.id }),
                },
              });
            }}
            tooltip={languages.EN.labels.informOrganization}
          />
        )}
      </span>
    </span>
  );
};

export default MemberLicenseInfo
