import {
  user_homepage,
  bonus_buy,
  course_suggestion,
  competition_entry,
  lottery_tickets,
} from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getUserHomepage = () => {
  return fetch(user_homepage, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const postBuyBonus = (bonus_id) => {
  return fetch(bonus_buy, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `bonus_id=${bonus_id}`,
  });
};

const sendNewCourseSuggestion = (data) => {
  return fetch(course_suggestion, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const assignToCompetition = (id) => {
  return fetch(competition_entry, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `competition_id=${id}`,
  });
};

const buyLotteryTicket = (lottery_id, number) => {
  return fetch(`${lottery_tickets}${lottery_id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `lottery_id=${lottery_id}&number=${number}`,
  });
};

export const homePageRepository = {
  getUserHomepage,
  postBuyBonus,
  sendNewCourseSuggestion,
  assignToCompetition,
  buyLotteryTicket,
};
