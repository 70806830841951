import { useContext, useEffect, useState } from "react";
import styles from "./FilterDateRangePicker.module.scss";
import Text from "../../atoms/Text/Text";
import findItemValueByPath from "../../../utils/FiltersMethods/FilterByPathName";
import { handleAddCriterrium, handleRemoveCriterrium } from "../../../utils/FiltersMethods/handleCriterriums";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import OutsideClickHandler from "react-outside-click-handler";
import { FilterContext } from "../../../contexts/FilterContext";
import { languages } from "../../../dummyData/dummyData";
import moment from "moment";

const FilterDateRangePicker = ({ definition }) => {
  const { keyPath, header } = definition;
  const { useOriginalData, useFilterCriteria, useFilterReset } = useContext(FilterContext);
  const { originalData } = useOriginalData;
  const { setFilterCriteria } = useFilterCriteria;
  const { filterReset } = useFilterReset;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const setInitialDates = () => {
    let arr = [];
    originalData && originalData.forEach((obj) => {
      findItemValueByPath(obj, keyPath, arr);
    });

    const sorted_data = arr.sort((a, b) => new Date(b) - new Date(a)).reverse();

    setStartDate(moment(sorted_data[0] && sorted_data[0].replaceAll("/", "-")).toDate());
    setEndDate(moment(sorted_data[sorted_data.length - 1] && sorted_data[sorted_data.length - 1].replaceAll("/", "-")).toDate());
  }

  useEffect(() => {
    if (originalData) {
      setInitialDates();
    }
  }, [keyPath, originalData]);

  useEffect(() => {
    if (filterReset) {
      setInitialDates();
    }
  }, [filterReset]);

  const [isActive, setIsActive] = useState(false);
  const [ranges, setRanges] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      setRanges([
        {
          startDate: startDate,
          endDate: endDate,
          key: "selection",
        },
      ]);
    }
  }, [endDate, startDate]);

  const handleSelect = (item) => {
    setRanges([item.selection]);
    handleRemoveCriterrium(setFilterCriteria, keyPath);
    handleAddCriterrium(setFilterCriteria, {
      typeOfFilter: "datePicker",
      type_id: keyPath,
      path: keyPath,
      startDate: item.selection.startDate,
      endDate: item.selection.endDate,
    });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsActive(false)}>
      <div className={styles.textWrapper}>
        <Text s14 w700 dark-4>
          {header && header}
        </Text>
      </div>
      {ranges.length > 0 ? (
        <div
          className={styles.calendar}
          onClick={() => setIsActive(true)}
          data-active={isActive}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => handleSelect(item)}
            moveRangeOnFirstSelection={false}
            ranges={ranges}
            monthPicker={false}
            dateDisplayFormat="yyyy/MM/dd"
            showMonthAndYearPickers={false}
            color="#d1e7e5"
            rangeColors={["#d1e7e5"]}
            minDate={startDate}
            maxDate={endDate}
            startDatePlaceholder="From"
            endDatePlaceholder="To"
            retainEndDateOnFirstSelection={true}
          />
        </div>
      ) : (
        <Text s12 dark-4>
          {languages.EN.labels.noData}
        </Text>
      )}
    </OutsideClickHandler>
  );
};

export default FilterDateRangePicker;
