import { createContext, useEffect, useState } from "react";
import handleFilter from "../utils/FiltersMethods/handleFilter";
import sortData from "../utils/FiltersMethods/handleSortData";
import filterBySearchValue from "../utils/FiltersMethods/handleSearchData";
import { createCheckboxCriterion } from "../utils/FiltersMethods/generateCheckboxes";

export const FilterContext = createContext([]);

export const FilterContextProvider = ({ children }) => {
  const [originalData, setOriginalData] = useState(null);
  const [filterDefinitions, setFilterDefinitions] = useState(null);

  const [isDataReady, setIsDataReady] = useState(true);
  const [dataAfterFilters, setDataAfterFilters] = useState(null);
  const [dataAfterSearch, setDataAfterSearch] = useState(null);
  const [dataAfterSort, setDataAfterSort] = useState(null);

  const [filterCriteria, setFilterCriteria] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [sortOrder, setSortOrder] = useState({ });
  const [selected, setSelected] = useState([]);
  const [filterReset, setFilterReset] = useState(false);


  /**
   * Flow:
   * originalData --applyFilterPanelCriteriums--> dataAfterFilters
   * dataAfterFilters --applySearchBarValue--> dataAfterSearch
   * dataAfterSearch --applySortOrder--> dataAfterSort
   *
   * dataAfterSort === processedData
   */

  /** originalData --applyFilterPanelCriteriums--> dataAfterFilters */
  useEffect(() => {
    setIsDataReady(false);
    if (originalData && filterDefinitions) {
      setDataAfterFilters(applyFilterPanelCriteriums(originalData));
    }
  }, [JSON.stringify(originalData), JSON.stringify(filterDefinitions), JSON.stringify(filterCriteria)]);

  const applyFilterPanelCriteriums = (data) => {
    const selectedCriteriaCounts = {};
    filterCriteria.forEach((criterion) => {
      selectedCriteriaCounts[criterion.path] = (selectedCriteriaCounts[criterion.path] || 0) + 1;
    });

    return handleFilter(data, filterCriteria, Object.keys(selectedCriteriaCounts).length);
  }

  /** dataAfterFilters --applySearchBarValue--> dataAfterSearch */
  useEffect(() => {
    if (dataAfterFilters) {
      setDataAfterSearch(applySearchBarValue(dataAfterFilters));
    }
  }, [dataAfterFilters, searchBarValue]);

  const applySearchBarValue = (data) => {
    return searchBarValue.length > 0 ? filterBySearchValue(searchBarValue, filterDefinitions, data) : data;
  }

  /** dataAfterSearch --applySortOrder--> dataAfterSort */
  useEffect(() => {
    if (dataAfterSearch) {
      setDataAfterSort(applySortOrder(dataAfterSearch));
      setIsDataReady(true);
    }
  }, [dataAfterSearch, sortOrder]);

  const applySortOrder = (data) => {
    return sortOrder.key ? sortData(sortOrder.key, data, sortOrder.ascending) : data;
  }

  /** filterReset */
  useEffect(() => {
    if (filterReset) {
      setFilterCriteria(getDefaultCriteria());
      setFilterReset(false);
    }
  }, [filterReset]);

  useEffect(() => {
    if (filterDefinitions) {
      setFilterCriteria(getDefaultCriteria());
      setSortOrder(getDefaultSort());
    }
  }, [filterDefinitions]);

  const getDefaultCriteria = () => {
    let criteria = [];

    filterDefinitions.forEach((criterion) => {
      if (criterion.defaultValues !== undefined) {
        criteria = [
          ...criteria,
          ...criterion.defaultValues.map(defaultValue => createCheckboxCriterion(criterion.keyPath, criterion.header, criterion.typeOfFilter, defaultValue)),
        ];
      }
    })

    return criteria;
  };

  const getDefaultSort = () => {
    const firstColumn = filterDefinitions.find(definition => definition.sortId !== undefined);

    return firstColumn ? {
      key: firstColumn.keyPath,
      ascending: true,
    } : {};
  };

  /** selectables */
  const toggleSelect = (selectableId) => {
    setSelected(selected.includes(selectableId) ? selected.filter(id => id !== selectableId) : [...selected, selectableId]);
  };

  return (
    <FilterContext.Provider
      value={{
        useOriginalData: { originalData, setOriginalData },
        useFilterDefinitions: { filterDefinitions, setFilterDefinitions },
        useProcessedData: { isDataReady, setIsDataReady, processedData: dataAfterSort },

        useFilterCriteria: { filterCriteria, setFilterCriteria },
        useSearchBar: { searchBarValue, setSearchBarValue },
        useSort: { sortOrder, setSortOrder },
        useFilterReset: { filterReset, setFilterReset },
        useSelectables: { selected, setSelected, toggleSelect },
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
