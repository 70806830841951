import useFetch from "../../../hooks/useFetch";
import { idpRepository } from "../../../repository/idp.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import IdpDetailsTemplate from "../../templates/IdpDetailsTemplate/IdpDetailsTemplate";
import { PostContext } from "../../../contexts/PostContext";

const IdpDetails = ({ match }) => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(idpRepository.getIdp(match.params.username, match.params.id));
    }
  }, [isResponseOk]);

  return data ? (
    <IdpDetailsTemplate idp={data} params={match.params} />
  ) : (
    <Loader />
  );
};

export default IdpDetails;
