import { useContext, useEffect, useRef, useState } from "react";
import styles from "./FilterRangeSlider.module.scss";
import Slider from "@material-ui/core/Slider";
import NumberInput from "./NumberInput/NumberInput";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import findItemValueByPath from "../../../utils/FiltersMethods/FilterByPathName";
import { FilterContext } from "../../../contexts/FilterContext";

const FilterRangeSlider = ({ definition }) => {
  const { keyPath, header } = definition;
  const { useOriginalData, useFilterCriteria, useFilterReset } = useContext(FilterContext);
  const { originalData } = useOriginalData;
  const { setFilterCriteria } = useFilterCriteria;
  const { filterReset } = useFilterReset;

  const [isSliderChanged, setIsSliderChanged] = useState(false);
  const [initialRange, setInitalRange] = useState({
    min: null,
    max: null,
  });
  const rangeRef = useRef();
  const [currentRange, setCurrentRange] = useState([
    initialRange.min,
    initialRange.max,
  ]);

  const [step, setStep] = useState();

  useEffect(() => {
      setCurrentRange([initialRange.min, initialRange.max]);
  }, [initialRange]);

  useEffect(() => {
    if (filterReset) {
      setCurrentRange([initialRange.min, initialRange.max]);
    }
  }, [filterReset]);

  const handleChange = (_, range) => {
    rangeRef.current = range;
    if (
      rangeRef.current[0] !== currentRange[0] ||
      rangeRef.current[1] !== currentRange[1]
    ) {
      setIsSliderChanged(true);
      if (range[0] <= range[1]) {
        setCurrentRange([...range]);
      } else if (range[1] >= range[0]) {
        setCurrentRange([...range]);
      }
    }
  };
  useEffect(() => {
    let arr = [];
    originalData && originalData.forEach((obj) => {
      findItemValueByPath(obj, keyPath, arr);
    });

    let sorted = arr.slice().sort(function (a, b) {
      return a - b;
    });

    setInitalRange({
      min: sorted[0],
      max: sorted[sorted.length - 1],
    });
  }, [keyPath, originalData]);

  useEffect(() => {
    if (initialRange.max > 100) {
      setStep(Math.round((20 / 100) * initialRange.max));
    } else {
      setStep(Math.max(Math.round((10 / 100) * initialRange.max), 1));
    }
  }, [initialRange]);

  const prepareNumbersRange = () => {
    const arr = [];
    for (let i = initialRange.min; i <= initialRange.max; i += step) {
      arr.push(i);
    }
    return arr;
  };

  useEffect(() => {
    if (!isSliderChanged) {
      setCurrentRange([initialRange.min ?? 0, initialRange.max ?? 0]);
    }
  }, [initialRange, isSliderChanged]);

  const numbers = prepareNumbersRange();
  return (
    <div className={styles.rangesWrapper}>
      <>
        <Text s14 w700 dark-4>
          {header && header}
        </Text>
        {initialRange?.min !== null && initialRange?.min !== undefined ? (
          <>
            <div className={styles.rangeInputs}>
              <NumberInput
                placeholder={languages.EN.labels.from}
                min={initialRange.min}
                max={initialRange.max}
                setCurrentRange={setCurrentRange}
                currentRange={currentRange}
                setCriterriums={setFilterCriteria}
                keyPath={keyPath}
                setIsSliderChanged={setIsSliderChanged}
              />
              <NumberInput
                placeholder={languages.EN.labels.to}
                min={initialRange.min}
                max={initialRange.max}
                setCurrentRange={setCurrentRange}
                currentRange={currentRange}
                setCriterriums={setFilterCriteria}
                keyPath={keyPath}
                setIsSliderChanged={setIsSliderChanged}
              />
            </div>
            <Slider
              step={step}
              min={initialRange.min}
              max={initialRange.max}
              value={currentRange}
              onChange={handleChange}
              valueLabelDisplay="auto"
            />
            {numbers && (
              <div className={styles.rangeNumbers}>
                {numbers?.map((number) => (
                  <Text s10 w500 key={number}>
                    {number}
                  </Text>
                ))}
              </div>
            )}
          </>
        ) : (
          <Text s12 dark-4>
            {languages.EN.labels.noData}
          </Text>
        )}
      </>
    </div>
  );
};

export default FilterRangeSlider;
