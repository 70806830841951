import { createContext, useState } from "react";

export const CompetenceTestContext = createContext();

export const CompetenceTestContextProvider = ({ children }) => {
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(1);
  const [savedAnswers, setSavedAnswers] = useState({});

  return (
    <CompetenceTestContext.Provider
      value={{
        currentAnswer,
        setCurrentAnswer,
        currentPosition,
        setCurrentPosition,
        savedAnswers,
        setSavedAnswers,
      }}
    >
      {children}
    </CompetenceTestContext.Provider>
  );
};
