import { trackingRepository } from "../repository/tracking.repository";
import * as sourceStackTrace from 'sourcemapped-stacktrace';
import Cookies from "universal-cookie/es6";
import { Routes } from "../routes";

const cookies = new Cookies();

export const verifyResponseCode = (response) => {
  if (!response.ok) {
    if ([401, 402].includes(response.status)) {
      cookies.remove("jwttoken", { path: Routes.mainPage.base });
    }
    return response
      .json()
      .then((data) => {
        throw Error(`${response.status}${response.statusText ? `: ${response.statusText}` : ''}`, { cause: data.status ?? data.detail });
      });
  } else {
    return response.json();
  }
};

export const reportError = (error, info) => {
  sourceStackTrace.mapStackTrace(info.componentStack, function(mappedStack) {
    trackingRepository
      .sendTrackingEvent({
        event_name: 'aidify.frontend.error',
        context: JSON.stringify({
          message: error.message,
          cause: error.cause,
          stack: mappedStack
            .filter(stack => !!stack)
            .map(stack => stack.replace(/( at \w+ \()((.+(?=\/src))|(.+(?=\/modules)))/, "$1")),
        }),
      });
  });
}
