import styles from "./TextArea.module.scss";
import { useEffect, useState } from "react";
import Label from "../Label/Label";
const TextArea = ({
  placeholder,
  value,
  handleTextArea,
  name,
  required,
  error,
  disabled,
  onBlur,
  onFocus,
  noMargin,
  autoFocus,
}) => {
  const [isValue, setIsValue] = useState(false);
  useEffect(() => {
    if (value === null || value === "" || value === undefined) {
      setIsValue(false);
    } else {
      setIsValue(true);
    }
  }, [value]);

  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => {
    setIsFocus(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <Label
      isFocus={isFocus}
      isValue={isValue}
      required={required}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
      textArea
      noMargin={noMargin}
    >
      <textarea
        className={`${styles.textArea} ${error ? styles.textArea__error : ""} ${disabled ? styles.textArea__disabled : ""}`}
        name={name}
        disabled={disabled}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => {
          handleTextArea && handleTextArea(e);
        }}
        autoFocus={autoFocus}
      />
    </Label>
  );
};

export default TextArea;
