import { useEffect } from "react";
import { languages } from "../dummyData/dummyData";

export const useDocumentTitle = (title) => {
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      document.title = `${title} | ${languages.EN.labels.aidify}`;
    }
    return () => {
      isMount = true;
    };
  }, []);
};

export const useDocumentTitleLoading = () => {
  useEffect(() => {
    let isMount = false;
    if (!isMount) {
      document.title = `${languages.EN.labels.loading} | ${languages.EN.labels.aidify}`;
    }
    return () => {
      isMount = true;
    };
  }, []);
};
