import styles from "./RadioButton.module.scss";

const btnClassNames = {
  primary: styles.btnPrimary,
  secondary: styles.btnSecondary,
};

const RadioButton = ({ checked, handleChange, variant, value }) => {
  return (
    <input
      type="radio"
      onChange={handleChange}
      checked={checked}
      value={value}
      className={btnClassNames[variant]}
    />
  );
};

export default RadioButton;
