import LoggedAndRegistredRoute from "./LoggedAndRegistredRoute";
import React, { useContext, useEffect, useState } from "react";
import { Routes } from "./routes";
import Cookies from "universal-cookie/es6";
import { UserContext } from "./contexts/UserContext";
import { Loader } from "./components/atoms/Loader/Loader";

const cookies = new Cookies();

const ProvidedTokenRoute = ({ match, ...params }) => {
  const [isReady, setIsReady] = useState(false);
  const { getUserContext, userData, hasToken } = useContext(UserContext);

  useEffect(() => {
    if (!hasToken()) {
      cookies.set("jwttoken", (new URLSearchParams(window.location.search)).get('jwt'), {
        path: Routes.mainPage.base,
      });
      getUserContext(() => {});
    } else {
      setIsReady(true);
    }
  }, [hasToken, userData]);

  return isReady ? (
    <LoggedAndRegistredRoute {...params} />
  ) : (
    <Loader />
  );
}

export default ProvidedTokenRoute;