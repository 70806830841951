import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import RegulatoryIntelligenceItemTile from "../../organisms/RegulatoryIntelligenceItemTile/RegulatoryIntelligenceItemTile";
import RegulatoryIntelligenceItem from "../../organisms/RegulatoryIntelligenceItem/RegulatoryIntelligenceItem";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { regulatoryIntelligenceRepository } from "../../../repository/regulatoryIntelligence.repository";
import { TYPE_DROPDOWN, TYPE_IMAGE, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { UserContext } from "../../../contexts/UserContext";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.title,
    sortId: 1,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "category",
    header: languages.EN.labels.category,
    sortId: 3,
  },
  {
    keyPath: "languageName",
    header: languages.EN.labels.language,
    typeOfFilter: "checkbox",
    sortId: 4,
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 7,
  },
  {
    keyPath: "created",
    header: languages.EN.labels.added,
    typeOfFilter: "datePicker",
    sortId: 6,
  },
  {
    keyPath: "likes",
    header: languages.EN.labels.likes,
    typeOfFilter: "range",
    sortId: 5,
  },
];

export const popupFields = (data = {}) => [
  {
    name: "title",
    label: languages.EN.labels.title,
    required: true,
    value: data.title,
  },
  {
    name: "author",
    label: languages.EN.labels.author,
    required: true,
    value: data.author,
  },
  {
    name: "topic",
    label: languages.EN.labels.learningArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.topic,
    items: languages.EN.enums.learningAreas,
  },
  {
    name: "category",
    label: languages.EN.labels.category,
    required: true,
    value: data.category,
  },
  {
    name: "language",
    label: languages.EN.labels.language,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.language,
    items: languages.EN.enums.languages,
  },
  {
    name: "url",
    label: languages.EN.labels.externalURL,
    required: true,
    value: data.url,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    required: true,
    type: TYPE_IMAGE,
    value: data.profile_image,
  },
];

const RegulatoryIntelligenceTemplate = ({ data }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.organization !== null ? 2 : 3;

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.topic],
      languageName: languages.EN.enums.languages[item.language],
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.organization.profile_image_small : <AidifyLogo />,
      canEdit: (type === 2 && userContextLevel === 2) || userContextLevel === 3,
    };
  })), [data]);

  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);
  const pageHeader = languages.EN.labels.regulatoryIntelligenceArticles;

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <FilteredList
          id="regulatoryIntelligence"
          data={preparedData}
          filterDefinitions={filterDefinitions}
          RenderListComponent={RegulatoryIntelligenceItem}
          RenderTileComponent={RegulatoryIntelligenceItemTile}
          buttons={userContextLevel === 3 ? [
            {
              icon: "plus",
              label: languages.EN.labels.add,
              onClick: () => callPopup({
                type: ACTIONS.FORM,
                payload: {
                  header: `${languages.EN.labels.add} ${languages.EN.labels.regulatoryIntelligenceArticle}`,
                  fields: popupFields(),
                  postAction: regulatoryIntelligenceRepository.addArticle,
                },
              }),
            },
          ] : []}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default RegulatoryIntelligenceTemplate;
