import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./FillHoles.module.scss";
import Text from "../../../atoms/Text/Text";
import TestSelect from "../../../atoms/TestSelect/TestSelect";
import { CompetenceTestContext } from "../../../../contexts/CompetenceTestContext";
import { permuteWithRepetition, shuffle } from "../../../../utils/arrayHelpers";

const FillHoles = ({ data }) => {
  const { answers, question } = data;
  const { savedAnswers, currentPosition, setCurrentAnswer } = useContext(CompetenceTestContext);

  if (question.match(/([^ ]___)|(___[^ ])/g) !== null) {
    throw Error("500: Internal Server Error", { cause: `Wrong question definition: "${question}"! Please contact administrator` });
  }

  const questionWords = useMemo(() => question.split(" "), [question]);
  const questionParts = useMemo(() => question.split("___").join("|___|").split("|").filter(part => part !== ""), [question]);
  const holeIds = useMemo(() => questionParts.map((part, i) => part === "___" ? i : null).filter((id) => id !== null), [questionParts]);
  const shuffledAnswersOrder = useMemo(() => shuffle(Array.from(answers.keys())), [answers]);
  const allPermutations = useMemo(() => permuteWithRepetition(["___", ...answers], holeIds.length), [holeIds]);

  const getDefaultLocalData = () => {
    return {};
  }

  const parseSavedAnswer = () => {
    const currentAnswer = savedAnswers[currentPosition];
    const currentAnswers = allPermutations.find(permutation => {
      let i = 0;
      return questionWords.map((item) => item === "___" ? permutation[i++] : item).join(" ") === currentAnswer;
    });

    if (currentAnswers === undefined) {
      throw Error("500: Internal Server Error", { cause: "No valid permutation found! Please contact administrator" });
    }

    return Object.fromEntries(holeIds.map((id, i) => [id, shuffledAnswersOrder.indexOf(answers.indexOf(currentAnswers[i]))]).filter((answer) => answer[1] !== -1));
  }

  const [localData, setLocalData] = useState(getDefaultLocalData());

  useEffect(() => {
    setLocalData(savedAnswers[currentPosition] ? parseSavedAnswer() : getDefaultLocalData());
  }, [savedAnswers, currentPosition]);

  const handleSelectAnswer = (data) => {
    const newData = { ...localData, ...data };
    setLocalData(newData);

    if (Object.values(newData).length > 0) {
      setCurrentAnswer(questionParts.map((part, i) => part === "___" && newData[i] !== undefined ? answers[shuffledAnswersOrder[newData[i]]] : part).join(""));
    }
  }

  return (
    <div className={styles.registrationQuestion}>
      <div className={styles.fillTheHoles}>
        {questionParts.map((part, i) => part !== "___" ? (
            <Text key={i} s14 lh32 w400 dark-4>
              {part}
            </Text>
          ) : (
            <TestSelect
              key={i}
              id={i}
              inTest
              answers={shuffledAnswersOrder.map(originalId => answers[originalId])}
              setOurAnswer={handleSelectAnswer}
              ourAnswer={localData}
              currentAnswer={localData[i]}
            />
          )
        )}
      </div>
    </div>
  );
};

export default FillHoles;
