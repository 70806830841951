import styles from "./SelectSearch.module.scss";
import Text from "../Text/Text";
import { useEffect, useRef, useState } from "react";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import TextTruncated from "../TextTruncated/TextTruncated";
import RoundImage from "../RoundImage/RoundImage";

const SelectSearchElement = ({
  order,
  name,
  handleOnChange,
  img,
  activeElement,
  item,
  enterCounter,
  isHover,
  setActiveElement,
}) => {
  const [isActive, setIsActive] = useState(
    activeElement === order ? true : false
  );
  const myRef = useRef(null);

  useEffect(() => {
    if (activeElement === order) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeElement, order]);

  useEffect(() => {
    if (enterCounter > 0 && isActive) {
      handleOnChange(item, name);
    }
  }, [enterCounter, handleOnChange, isActive, item, name]);

  useEffect(() => {
    if (isHover) {
      setIsActive(false);
    }
  }, [isHover]);

  return (
    <ScrollIntoViewIfNeeded active={isActive}>
      <div
        ref={myRef}
        onMouseMove={() => setActiveElement(order)}
        className={`${styles.tableElement} ${isActive ? styles.tableElement__active : ""}`}
        onClick={() => {
          handleOnChange(item, name);
        }}
      >
        {img && (
          <RoundImage image={item.photo} size={25} />
        )}
        <TextTruncated lineClamp={1}>
          <Text {...{"s12": true, "lh24": true, "w600": true, [item.inactive ? "dark-3" : "dark-4"]: true}}>
            {item.label}
          </Text>
        </TextTruncated>
      </div>
    </ScrollIntoViewIfNeeded>
  );
};

export default SelectSearchElement;
