import styles from "../QuestionsTable.module.scss";
import RadioButton from "../../../../../atoms/RadioButton/RadioButton";
import Text from "../../../../../atoms/Text/Text";
import React from "react";

const SingleChoiceAnswer = ({ index, answer, correct }) => {
  return (
    <div>
      <label className={styles.wrapper}>
        <div className={styles.radioButtonWrapper}>
          <RadioButton variant="primary" checked={index.toString() === correct[0]} handleChange={() => {}} />
        </div>
        <span className={styles.label}>
          <Text s14 lh17>
            {answer}
          </Text>
        </span>
      </label>
    </div>
  );
};

export default SingleChoiceAnswer
