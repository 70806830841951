import styles from "./DragDrop.module.scss";

const DragDropCard = (props) => {
  const dragStart = (e) => {
    e.dataTransfer.setData("card_id", e.target.id);
  };

  return (
    <div id={props.id} className={styles.card} draggable={true} onDragStart={dragStart}>
      {props.children}
    </div>
  );
};

export default DragDropCard;
