import styles from "./PreviewPlayer.module.scss";
import { VideoLoader } from "../../atoms/Loader/Loader";
import ReactPlayer from "react-player/vimeo";
import playVideo2 from "../../../assets/icons";
import React, { useState } from "react";

const PreviewPlayer = ({ url, onClose }) => {
  const [isReady, setIsReady] = useState(false);

  return (
    <div className={styles.previewPlayer} onClick={onClose}>
      {url && <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        playIcon={<img alt="" src={playVideo2} width="200px" />}
        className={styles.previewPlayer__player}
        style={{ visibility: isReady ? 'visible' : 'hidden', }}
        onReady={() => {
          setIsReady(true);
        }}
      />}
      {!isReady && <VideoLoader />}
    </div>
  );
};

export default PreviewPlayer;
