import PieChartTotal from "../../atoms/PieChartTotal/PieChartTotal";
import styles from "./PieChartTotalContainer.module.scss";
import LegendBox from "../../molecules/LegendBox/LegendBox";
import Text from "../../atoms/Text/Text";

const PieChartTotalContainer = ({ header, config, width100 }) => {
  return (
    <div className={styles.Wrapper}>
      <Text s20 w800 secondary lh32>
        {header}
      </Text>
      <div className={width100 ? styles.contentW100 : styles.content}>
        <div className={styles.legend}>
          <LegendBox data={config} />
        </div>
        <PieChartTotal config={config} />
      </div>
    </div>
  );
};

export default PieChartTotalContainer;
