import { mfa_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getMfa = () => {
  return fetch(mfa_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const enableMfa = () => {
  return fetch(`${mfa_endpoint}enable/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const confirmMfa = (data) => {
  return fetch(`${mfa_endpoint}confirm/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const disableMfa = () => {
  return fetch(`${mfa_endpoint}disable/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const settingsRepository = {
  getMfa,
  enableMfa,
  confirmMfa,
  disableMfa,
};
