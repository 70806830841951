import TopBanner from "../../molecules/TopBanner/TopBanner";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import CompetenceTestContainer from "../../organisms/CompetenceTestContainer/CompetenceTestContainer";
import styles from "./CompetenceTestTemplate.module.scss";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { CompetenceTestContextProvider } from "../../../contexts/CompetenceTestContext";
import { Routes } from "../../../routes";
import { generatePath } from "react-router-dom";
import MainTemplate from "../generic/MainTemplate/MainTemplate";

const CompetenceTestTemplate = ({ testId, questions, status, courseId }) => {
  const pageHeader = languages.EN.labels.competenceTest;
  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <div className={styles.main}>
          <CompetenceTestContextProvider>
            {questions && (
              <CompetenceTestContainer
                testId={testId}
                questions={questions}
                status={status}
                reportUrl={courseId === undefined ?
                  generatePath(Routes.competenceTest.report.base, { id: testId }) :
                  generatePath(Routes.courseView.about.base, { id: courseId, block: testId })
                }
              />
            )}
          </CompetenceTestContextProvider>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CompetenceTestTemplate;
