import React, { useContext, useMemo } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import { referencesRepository } from "../../../../../repository/references.repository";
import Status, { VARIANT_GREEN, VARIANT_ORANGE, VARIANT_RED, VARIANT_YELLOW } from "../../../../atoms/Status/Status";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import { TYPE_CHECKBOX, TYPE_DATE, TYPE_FILE, TYPE_TEXTAREA } from "../../../../molecules/Form/Form";
import moment from "moment";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { dateFormat } from "../../../../../utils/formatters";
import { Routes } from "../../../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { getDocumentForm, referencePopupFields } from "../ReferencesTable/ReferencesTable";

const NOT_EDITABLE_FIELDS = ['type', 'document_form_select', 'document_form_text'];
const FULL_LINE_FIELDS = ['name'];

const prepareReferencePopupFields = (fields) => fields.reduce((acc, curr) => {
  const prev = {
    name: `${curr.name}_prev`,
    label: curr.label,
    disabled: true,
    visible: curr.visible,
    type: curr.type,
    value: curr.value,
    items: curr.items,
  };
  const next = {
    ...curr,
    label: `${languages.EN.labels.new} ${curr.label.toLowerCase()}`,
  };

  let prepared = [prev].concat(!NOT_EDITABLE_FIELDS.includes(curr.name) ? [next] : []);
  if (!FULL_LINE_FIELDS.includes(curr.name)) {
    prepared = [prepared];
  }

  return acc.concat(prepared);
}, []);

const auditPopupFields = (data = {}, extended = false) => (extended ? [] : [
  {
    name: "name",
    label: languages.EN.labels.name,
    disabled: true,
    value: data.name,
  },
]).concat([
  {
    name: "revision_summary",
    label: languages.EN.labels.revisionSummary,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.revision_summary,
  },
  [
    {
      name: "audit_date",
      label: languages.EN.labels.auditDate,
      type: TYPE_DATE,
      value: data.audit_date,
    },
    {
      name: "next_audit_date",
      label: languages.EN.labels.nextAuditDate,
      type: TYPE_DATE,
      value: data.next_audit_date,
    },
  ],
]).concat(extended ? [
  {
    name: "course_audit_required",
    label: languages.EN.labels.isRelatedCoursesAuditRequired,
    type: TYPE_CHECKBOX,
    value: data.course_audit_required ?? 0,
  },
  {
    name: "due_date",
    label: languages.EN.labels.deadlineDate,
    visible: data.course_audit_required === 1,
    required: data.course_audit_required === 1,
    type: TYPE_DATE,
    value: data.due_date,
  },
  {
    name: "audit_files",
    label: languages.EN.labels.attachFile,
    type: TYPE_FILE,
    value: data.audit_files,
  },
] : []);

function ReferenceAuditsTable({ reports }) {
  const { reference, audits } = reports;

  const statusToVariant = {
    0: VARIANT_RED,
    1: VARIANT_YELLOW,
    2: VARIANT_ORANGE,
    3: VARIANT_GREEN,
  }

  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const items = audits.map((item) => ({
    ...item,
    author: `${item.user.profile.name} ${item.user.profile.surname}`,
    newVersion: item.version ? item.version.aidify_version : languages.EN.placeholders.empty,
    actionRequiredText: languages.EN.enums.yesNo[item.action_required ? 1 : 0],
    statusText: languages.EN.enums.referenceAuditStatuses[item.status],
  }));
  const isNewAuditForbidden = audits.filter(item => item.status !== 3).length > 0;

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.createdAt,
      accessor: "created",
      Cell: (data) => dateFormat(data.row.original.created)
    },
    {
      Header: languages.EN.labels.createdBy,
      accessor: "author",
    },
    {
      Header: languages.EN.labels.version,
      accessor: "previous_version.aidify_version",
    },
    {
      Header: languages.EN.labels.newVersion,
      accessor: "newVersion",
    },
    {
      Header: languages.EN.labels.revisionSummary,
      accessor: "revision_summary",
    },
    {
      Header: languages.EN.labels.actionRequired,
      accessor: "actionRequiredText",
    },
    {
      Header: languages.EN.labels.status,
      accessor: "statusText",
      Cell: (data) => (
        <Status
          variant={statusToVariant[data.row.original.status]}
          text={data.row.original.statusText.toUpperCase()}
        />
      ),
      cellStyle: { position: "relative" },
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={(event) => {
              event.stopPropagation();
              history.push(generatePath(Routes.referenceAudit.relatedCourses.base, { reference: reference.id, audit: data.row.original.id }));
            }}
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table buttons={reference.canEdit ? [
    {
      icon: "exam",
      label: languages.EN.labels.newVersion,
      variant: isNewAuditForbidden ? "primaryDisabled" : undefined,
      disabled: isNewAuditForbidden,
      tooltip: isNewAuditForbidden ? languages.EN.messages.newAuditForbidden : undefined,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.newVersion}`,
          fields: (formData) => [
            ...prepareReferencePopupFields(referencePopupFields({ ...reference, ...formData }, false)),
            ...auditPopupFields({
              ...formData,
              audit_date: moment().toISOString(),
              next_audit_date: moment().add(6, 'M').toISOString(),
            }, true),
          ],
          postAction: (formData) => referencesRepository.addAudit(Object.fromEntries(
            Object
              .entries({
                document: reference.id,
                type: formData.type_prev,
                document_form: getDocumentForm({type: formData.type_prev.toString(), document_form_select: formData.document_form_select_prev, document_form_text: formData.document_form_text_prev}),
                action_required: 1,
                ...formData
              })
              .filter(([key,]) => key.indexOf("_prev") === -1)
          ))
        },
      }),
    },
    {
      icon: "checkMark",
      label: languages.EN.labels.auditProof,
      variant: isNewAuditForbidden ? "primaryDisabled" : undefined,
      disabled: isNewAuditForbidden,
      tooltip: isNewAuditForbidden ? languages.EN.messages.newAuditForbidden : undefined,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: languages.EN.labels.auditProof,
          fields: auditPopupFields({
            name: reference.name,
            audit_date: moment().toISOString(),
            next_audit_date: moment().add(6, 'M').toISOString(),
          }, false),
          postAction: (formData) => {
            delete formData.name;
            return referencesRepository.addAudit({ document: reference.id, action_required: 0, ...formData })
          }
        },
      }),
    },
  ] : []} columns={columns} data={items} />;
}

export default ReferenceAuditsTable;
