import styles from "./RatingStars.module.scss";
import svg from "../../../assets/icons";
import Text from "../../atoms/Text/Text";

const SocialStars = ({ rating }) => {
  var socialStars = [];

  for (let i = 0; i < Math.round(rating * 1) / 1; i++) {
    socialStars.push(
      <span className={styles.starIcon} key={i}>
        {svg.socialStar}
      </span>
    );
  }

  return (
    <div className={styles.socialStars}>
      <Text s12 lh24 w600 dark-2>
        {Math.round(rating * 10) / 10}
      </Text>
      <div className={styles.socialStars__item}>
        {socialStars.map((item) => item)}
      </div>
    </div>
  );
};

export default SocialStars;
