import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { languages } from "../../../dummyData/dummyData";
import LandingTemplate from "../generic/LandingTemplate/LandingTemplate";
import InfoBaner, { VARIANT_ERROR } from "../../atoms/InfoBaner/InfoBaner";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import Form, { TYPE_PASSWORD } from "../../molecules/Form/Form";
import Button from "../../atoms/Button/Button";
import { Routes } from "../../../routes";
import { useHistory } from "react-router-dom";
import { PostContext } from "../../../contexts/PostContext";
import { authRepository } from "../../../repository/authRepository";
import { setAuthorizationHeader } from "../../../repository/repositoryHelper";
import useFlashMessages from "../../../hooks/useFlashMessages";
import { popupFields as otpFields } from "../../molecules/Popups/MfaConfirmPopup/MfaConfirmPopup";

const formFields = [
  {
    name: "email",
    label: languages.EN.labels.email,
    required: true,
  },
  {
    name: "password",
    label: languages.EN.labels.password,
    required: true,
    type: TYPE_PASSWORD,
  },
];

const SignInTemplate = () => {
  const { postData, isPostFinish, isResponseOk, postResponse } = useContext(PostContext);
  const { hasToken, getUserContext } = useContext(UserContext);
  const { flashMessages, addFlashMessage } = useFlashMessages();
  const [otpRequired, setOtpRequired] = useState(false);
  const [credentials, setCredentials] = useState({});
  const history = useHistory();

  const pageHeader = otpRequired ? languages.EN.labels.twoFactorAuthentication : languages.EN.labels.signIn;
  useDocumentTitle(pageHeader);

  useEffect(() => {
    if (isPostFinish) {
      if (isResponseOk === false && postResponse?.status) {
        if (postResponse.status.indexOf('provide two factor authentication code') !== -1) {
          setOtpRequired(true);
        } else {
          addFlashMessage(VARIANT_ERROR, postResponse.status);
        }
      } else if (isResponseOk && postResponse?.password_change) {
        addFlashMessage(VARIANT_ERROR, postResponse.status);
        history.push(Routes.passwordChange.base)
      } else if (isResponseOk && postResponse?.success && hasToken()) {
        getUserContext();
      }
    }
  }, [isPostFinish, isResponseOk, postResponse]);

  return (
    <LandingTemplate header={pageHeader}>
      {flashMessages.map((message, i) => <InfoBaner key={i} variant={message.type} text={message.content} />)}
      <Form
        fields={otpRequired ? otpFields : formFields}
        onSave={(formData) => {
          setCredentials(formData);

          return postData(
            () => authRepository.signIn({ ...credentials, ...formData }),
            (response) => setAuthorizationHeader(response)
          )
        }}
        saveLabel={languages.EN.labels.signIn}
        autoFocus={true}
      />
      <span>
        <Button variant="text" onClick={() => history.push(Routes.passwordReset.base)}>
          {`${languages.EN.labels.forgotPassword}?`}
        </Button>
      </span>
    </LandingTemplate>
  );
};
export default SignInTemplate;
