import { createContext, useState } from "react";
import { useEffect } from "react";

export const UserConfigContext = createContext();

export const UserConfigContextProvider = ({ children }) => {
  const [userConfig, setUserConfig] = useState(
    JSON.parse(localStorage.getItem("userConfig"))
  );

  useEffect(() => {
    let isMount = false;
    if (isMount === false) {
      localStorage.setItem("userConfig", JSON.stringify(userConfig));
    }
    return () => {
      isMount = true;
    };
  }, [userConfig]);

  return (
    <UserConfigContext.Provider value={[userConfig, setUserConfig]}>
      {children}
    </UserConfigContext.Provider>
  );
};
