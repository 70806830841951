import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import CoursesItemTile from "../../organisms/CoursesItemTile/CoursesItemTile";
import CoursesItem from "../../organisms/CoursesItem/CoursesItem";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { coursesRepository } from "../../../repository/courses.repository";
import { TYPE_DROPDOWN, TYPE_IMAGE, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { FilterContext } from "../../../contexts/FilterContext";
import Text from "../../atoms/Text/Text";
import { GROUPS } from "../../templates/HomepageTemplate/HomepageTemplate";

const filterDefinitions = [
  {
    keyPath: "fastFilters",
    header: languages.EN.labels.fastFilters,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "status",
    header: languages.EN.labels.status,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "display_name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "careerPaths",
    header: languages.EN.labels.careerPaths,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 8,
  },
  {
    keyPath: "duration",
    header: `${languages.EN.labels.duration} (${languages.EN.labels.minutes})`,
    typeOfFilter: "range",
    sortId: 6,
  },
  {
    keyPath: "preparedInstructorNames",
    header: languages.EN.labels.instructor,
    sortId: 7,
  },
  {
    keyPath: "isActive",
    header: languages.EN.labels.isActive,
    typeOfFilter: "checkbox",
    defaultValues: [languages.EN.enums.yesNo[1]],
  },
];

export const popupFields = (data = {}) => [
  {
    name: "display_name",
    label: languages.EN.labels.name,
    required: true,
    value: data.display_name,
  },
  {
    name: "topic",
    label: languages.EN.labels.learningArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.topic,
    items: languages.EN.enums.learningAreas,
  },
  {
    name: "language",
    label: languages.EN.labels.language,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.language,
    items: languages.EN.enums.languages,
  },
  {
    name: "level",
    label: languages.EN.labels.experienceLevel,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.level,
    items: languages.EN.enums.experienceLevels,
  },
  {
    name: "instructor_text",
    label: languages.EN.labels.instructor,
    required: true,
    value: data.instructor_text,
  },
  {
    name: "short_description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.short_description,
  },
  {
    name: "course_version",
    label: languages.EN.labels.version,
    required: true,
    value: data.course_version,
  },
  {
    name: "duration",
    label: `${languages.EN.labels.duration} (${languages.EN.labels.minutes})`,
    required: true,
    value: data.duration,
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    required: true,
    type: TYPE_IMAGE,
    value: data.profile_image,
  },
];

const getSubtitle = (relatedCompetenceType) => {
  switch (relatedCompetenceType) {
    case 0:
      return (
        <Text w600 red>
          {languages.EN.messages.finishCourse} {languages.EN.labels.keyCompetence.toLowerCase()} {languages.EN.labels.inYourJobPosition.toLowerCase()}
        </Text>
      );
      break;
    case 1:
      return (
        <Text w600 yellow>
          {languages.EN.messages.finishCourse} {languages.EN.labels.complementaryCompetence.toLowerCase()} {languages.EN.labels.inYourJobPosition.toLowerCase()}
        </Text>
      );
      break;
    default:
      return undefined;
  }
}

const CoursesList = ({ data, withManagement, onAddSelected, jobPositionId }) => {
  const { useSelectables, useProcessedData } = useContext(FilterContext);
  const { selected, setSelected } = useSelectables;
  const { processedData } = useProcessedData;
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.course_organization !== null ? 2 : 3;
    const instructors = item.course_instructor.length > 0 ? item.course_instructor.map(instructor => ({
      name: instructor.name,
      img: instructor.profile_image_small,
    })) : [{
      name: item.instructor_text,
      img: undefined,
    }];
    const relatedCompetenceType = jobPositionId !== undefined && item.job_positions.find(jobPositionData => jobPositionData.id === jobPositionId)?.type;

    return {
      ...item,
      fastFilters: Object
        .entries(GROUPS)
        .filter(([, groups]) => item.groups.filter(group => groups.includes(group)).length > 0)
        .map(([key]) => languages.EN.labels.coursesFastFiltersValues[key])
        .concat(item.included_in_license ? [languages.EN.labels.coursesFastFiltersValues.included] : []),
      status: []
        .concat(item.user_saved === true ? [languages.EN.labels.liked] : [])
        .concat(item.user_progress === 0 ? [languages.EN.labels.assigned] : [])
        .concat(item.user_progress > 0 && item.user_progress < 1 ? [languages.EN.labels.inProgress] : [])
        .concat(item.user_progress === 1 ? [languages.EN.labels.completed] : []),
      learningArea: languages.EN.enums.learningAreas[item.topic],
      careerPaths: item.career_paths.map((career_path) => career_path.name),
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.course_organization.profile_image_small : <AidifyLogo />,
      preparedInstructorNames: instructors.map(instructor => instructor.name),
      instructors: instructors,
      duration: Math.round(item.duration / 60),
      subtitle: getSubtitle(relatedCompetenceType),
      isActive: languages.EN.enums.yesNo[item.active ? 1 : 0],
      canEdit: type === 2 && withManagement === true,
      canSelect: onAddSelected !== undefined,
      selectableId: item.id,
    };
  })), [data]);

  const { callPopup } = useContext(PopupsContext);

  return (
    <FilteredList
      id={"courses"}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={CoursesItem}
      RenderTileComponent={CoursesItemTile}
      buttons={[].concat(withManagement ? [
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: () => callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.course}`,
              fields: popupFields(),
              postAction: (formData) => coursesRepository.addCourse({
                ...formData,
                duration: formData.duration * 60,
                enrollment_start: new Date().toISOString().slice(0, 10),
                passing_grade: 0.8,
                active: 1,
              }),
            },
          }),
        },
      ] : []).concat(onAddSelected !== undefined ? [
        {
          icon: "checkMark",
          label: languages.EN.labels.selectAll,
          onClick: () => setSelected(selected.length < processedData.length ? processedData.map(item => item.selectableId) : []),
        },
      ] : []).concat(onAddSelected !== undefined ? [
        {
          icon: "plus",
          label: languages.EN.labels.addToPlan,
          forSelectedOnly: true,
          onClick: onAddSelected,
        },
      ] : [])}
    />
  );
};

export default CoursesList;
