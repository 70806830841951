import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";
import { problem_feedback_endpoint, tracking_event_endpoint } from "../constants/endpoints";

const sendTrackingEvent = (data) => {
  return fetch(tracking_event_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const sendProblemFeedback = (data) => {
  return fetch(problem_feedback_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const trackingRepository = {
  sendTrackingEvent,
  sendProblemFeedback,
};
