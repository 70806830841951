import React, { useContext } from "react";
import styles from "./Workgroup.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import Button from "../../atoms/Button/Button";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { UserContext } from "../../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
const { visibility: visibilityTypes } = languages.EN.enums;
const { workgroup } = languages.EN.labels;
const { empty } = languages.EN.placeholders;
const Workgroup = ({ data }) => {
  const {
    name,
    profile_image,
    description,
    size,
    organization,
    visibility,
    owner,
    id,
  } = data;

  const history = useHistory();
  const { callPopup } = useContext(PopupsContext);
  const { userData, userContextLevel } = useContext(UserContext);
  const isUserOwnWorkgroup =
    owner.profile.user.username === userData.username ? true : false;

  return (
    <div className={styles.workgroup}>
      <div className={styles.workgroup__img}>
        <img src={profile_image} alt="" />
      </div>
      <div className={styles.workgroup__information}>
        <TextTruncated lineClamp={3} secondary>
          <Text s20 lh32 w800 secondary wordBreak>
            {name ? name : empty}
          </Text>
        </TextTruncated>
      </div>
      <div className={styles.workgroup__information}>
        <TextTruncated lineClamp={4}>
          <Text s12 lh24 dark-2 wordBreak>
            {description ? description : empty}
          </Text>
        </TextTruncated>
      </div>
      <div className={styles.workgroup__information}>
        <Text s12 lh24 dark-2>
          {size}
        </Text>
      </div>
      <div className={styles.workgroup__information}>
        <Text s12 lh24 dark-2>
          {visibilityTypes[visibility]}
        </Text>
        <div className={styles.visibilityImg}>
          {visibility === 2 ? (
            <img src={organization.profile_image} alt="" />
          ) : (
            <img src={owner.profile.profile_image_small} alt="" />
          )}
        </div>
      </div>
      <div className={styles.workgroup__informationBtn}>
        <div className={styles.buttonsContainer}>
          {(isUserOwnWorkgroup || [2, 3].includes(userContextLevel)) && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="garbage"
              onClick={() =>
                callPopup({
                  type: ACTIONS.DELETE,
                  payload: {
                    type: workgroup,
                    elementName: name,
                    action: () => workgroupsRepository.deleteWorkgroup(id),
                  },
                })
              }
            />
          )}
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(`${Routes.workgroupDetails.base}?id=${id}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Workgroup;
