import React from "react";
import styles from "./CourseProgressTypeCell.module.scss";
import Text from "../../../../atoms/Text/Text";
import { languages } from "../../../../../dummyData/dummyData";
import ProportionsBar from "../../../../atoms/CompetenceVerificationTypeBar/ProportionsBar";

export const TYPE_ALL = "all";
export const TYPE_DONE = "done";
export const TYPE_IN_PROGRESS = "inProgress";
export const TYPE_NOT_STARTED = "notStarted";

export const getValueFromData = (data, type) => {
  switch (type) {
    case TYPE_DONE:
      return data.num_finished;
    case TYPE_IN_PROGRESS:
      return data.num_in_progress;
    case TYPE_NOT_STARTED:
      return data.num_enrolled;
    case TYPE_ALL:
    default:
      return data.num_of_students;
  }
}

const CourseProgressTypeCell = ({ data, barConfig, max }) => {
  return (
    <div className={styles.wrapper}>
      <Text dark-2 s12 w600 lh24>
        {languages.EN.labels.total}: {getValueFromData(data, TYPE_ALL)}
      </Text>
      <ProportionsBar
        config={barConfig}
        getValue={(type) => getValueFromData(data, type)}
        max={max}
      />
    </div>
  );
};

export default CourseProgressTypeCell;
