import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItem from "../ListItem/ListItem";
import { Routes } from "../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import VerificationBar from "../../molecules/VerificationBar/VerificationBar";
import { PROGRESS_BAR_SETTINGS } from "../CourseAuditsList/CourseAuditsList";
import ListItemImgMultiInfo from "../../molecules/ListItemImgMultiInfo/ListItemImgMultiInfo";
import { languages } from "../../../dummyData/dummyData";

const RelatedCourseAuditItem = ({ data }) => {
  const { course, instructors, actionRequiredText, dateText, status, statusText } = data;

  let history = useHistory();

  return (
    <ListItem
      title={course.display_name}
      image={course.profile_image_small}
      bar={(
        <VerificationBar
          text={statusText}
          progress={PROGRESS_BAR_SETTINGS[status].progress}
          variant={PROGRESS_BAR_SETTINGS[status].variant}
        />
      )}
      buttons={[
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.courseDetails.audits.base, { id: course.id })),
          tooltip: languages.EN.labels.details,
        },
      ]}
    >
      <ListItemImgMultiInfo infos={instructors} />
      <ListItemInfo value={actionRequiredText} />
      <ListItemInfo value={dateText} />
    </ListItem>
  );
};

export default RelatedCourseAuditItem;
