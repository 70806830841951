import { useEffect, useRef } from "react";

export function useDidUpdateEffect(fn, inputs) {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      return fn();
    }
    isMounted.current = true;
  }, inputs);
}
