import styles from "./CheckBoxOnly.module.scss";
import Text from "../Text/Text";
import Icons from "../../../assets/icons";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import React, { useEffect, useState } from "react";
const CheckBoxOnly = ({
  text,
  handleCheckbox,
  item,
  checked,
  inNotification,
  selectedTable,
}) => {
  const [isChecked, setIsChecked] = useState(!!checked);

  useEffect(() => {
    if (!checked) {
      setIsChecked(false);
    }
  }, [checked]);

  useEffect(() => {
    if (selectedTable !== undefined) {
      selectedTable.forEach((el) => {
        if (el.selectId === item.selectId) {
          setIsChecked(true);
        }
      });
    }
  }, [item, selectedTable]);

  return (
    <div
      className={`${styles.Wrapper} ${isChecked && styles.Wrapper__active}`}
      data-notification={inNotification}
    >
      <div
        className={styles.wrapperInside}
        onClick={() => {
          setIsChecked(!isChecked);
          handleCheckbox(isChecked, item);
        }}
        data-notification={inNotification}
      >
        <div
          className={`${styles.checkbox} ${isChecked && styles.checkbox__active}`}
          data-notification={inNotification}
        >
          {isChecked ? (Icons.checkbox) : null}
        </div>

        <TextTruncated lineClamp={1}>
          <Text s12 dark-4 lh20>
            {text}
          </Text>
        </TextTruncated>
      </div>
    </div>
  );
};
export default CheckBoxOnly;
