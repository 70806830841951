import styles from "./ConditionsFulfillment.module.scss";
import Text from "../../atoms/Text/Text";
import ConditionFulfillment from "../../atoms/ConditionFulfillment/ConditionFulfillment";

const ConditionsFulfillment = ({ header, conditions }) => {
  return (
    <div className={styles.requirements}>
      <Text s12 lh16>
        {header}
      </Text>
      {conditions.map((condition, i) => (
        <ConditionFulfillment key={i} condition={condition.condition} isFulfilled={condition.isFulfilled} />
      ))}
    </div>
  );
};

export default ConditionsFulfillment
