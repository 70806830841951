import React from "react";
import Button from "../../../atoms/Button/Button";
import styles from "../Table.module.scss";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../molecules/ProgressBar/ProgressBar";
import { languages } from "../../../../dummyData/dummyData";
import Table, { ROW_VARIANT_ERROR } from "../Table";

function StudentCourseProgressTable({ reports }) {
  const history = useHistory();

  const items = reports.map((item) => ({
    ...item,
    visibility: languages.EN.enums.visibility[item.internal === true ? 2 : 3],
    category: item.video === false ? languages.EN.labels.pdf : languages.EN.labels.video,
    progress: item.progress * 100,
  }));

  const columns = React.useMemo(
    () => [
      {
        Header: languages.EN.labels.name,
        accessor: "name",
      },
      {
        Header: languages.EN.labels.visibility,
        accessor: "visibility",
      },
      {
        Header: languages.EN.labels.deadlineDate,
        accessor: "end_date",
        Cell: (data) => (data.cell.value ? data.cell.value : languages.EN.placeholders.empty),
      },
      {
        Header: languages.EN.labels.progress,
        accessor: "progress",
        Cell: (data) => <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value} />,
      },
      {
        Header: languages.EN.labels.actions,
        disableSortBy: true,
        headerStyle: { justifyContent: "center", maxWidth: "130px" },
        Cell: (data) => (
          <div className={styles.actionBox}>
            <Button
              variant="iconButton"
              onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: data.cell.row.original.course_id }))}
              onlyIcon
              icon="dots"
              tooltip={languages.EN.labels.details}
            />
          </div>
        ),
      },
    ],
    []
  );

  return <Table columns={columns} data={items} getRowProps={(row) => {
    return {
      [ROW_VARIANT_ERROR]: row.original.end_date !== null && new Date(row.original.end_date) < new Date(),
    }
  }} />;
}

export default StudentCourseProgressTable;
