import styles from "../../templates/CourseViewTemplate/CourseViewTemplate.module.scss";
import Button from "../../atoms/Button/Button";
import Text from "../../atoms/Text/Text";
import React from "react";
import { languages } from "../../../dummyData/dummyData";

const CourseMaterial = ({ material, onClick }) => {
  return (
    <>
      <li className={styles.additionalMaterial}>
        <div style={{ display: "flex", gap: "27px" }}>
          {material.name}
          <Button
            variant="iconButton"
            onlyIcon
            icon={material.downloadable ? "download" : "play2"}
            onClick={onClick}
            tooltip={languages.EN.labels[material.downloadable ? "download" : "play"]}
          />
        </div>
      </li>
      <div className={styles.materialDescription}>
        <Text s12 w400 lh23 light-5>
          {material.description}
        </Text>
      </div></>
  );
};

export default CourseMaterial
