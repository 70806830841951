import styles from "./PrivacySettingsTable.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";

export const PrivacySettingsTableHeader = () => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.tableHeader__primary}></div>
      {Object.entries(languages.EN.labels.privacySettingsTableHeaders).map(([key, value]) => (
        <div key={key} className={styles.tableHeader}>
          <Text light s12>
            {value}
          </Text>
        </div>
      ))}
    </div>
  );
};
