import { languages } from "../../../dummyData/dummyData";
import NavBar from "../../organisms/NavBar/NavBar";
import styles from "./ErrorTemplate.module.scss";
import Text from "../../atoms/Text/Text";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useHistory } from "react-router";
import TopBanner from "../../molecules/TopBanner/TopBanner";

const ErrorTemplate = ({ error, resetErrorBoundary }) => {
  const pageHeader = languages.EN.labels.error;
  useDocumentTitle(pageHeader);

  const history = useHistory();

  history.listen(() => {
    if (typeof resetErrorBoundary === 'function') {
      resetErrorBoundary();
    }
  });

  return (
    <>
      <NavBar simplified={true} refreshContextOnLogoClick={false} />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <main className={styles.main}>
        <Text s32 w800 dark>
          {error.message}
        </Text>
        {error.cause && (
          <Text s18 dark-4>
            {error.cause}
          </Text>
        )}
        <span onClick={() => history.go(-2)} className={styles.link}>
          <Text s16 dark-4>
            {languages.EN.messages.goBack}
          </Text>
        </span>
      </main>
    </>
  );
};

export default ErrorTemplate;
