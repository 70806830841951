import Text from "../Text/Text";
import styles from "./Label.module.scss";

const Label = ({
  children,
  placeholder,
  isValue,
  isFocus,
  error,
  disabled,
  required,
  noMargin,
  selectSearch,
  textArea,
  searchBar,
  absolute,
  transparent,
}) => {
  return (
    <label className={`
      ${styles.Wrapper} 
      ${noMargin ? styles.Wrapper__noMargin : ""} 
      ${textArea ? styles.Wrapper__textArea : ""} 
      ${searchBar ? styles.Wrapper__searchBar : ""} 
      ${absolute ? styles.Wrapper__absolute : ""}
    `}>
      {children}
      {placeholder && (
        <span
          data-required={required}
          className={`
            ${styles.placeholderIn} 
            ${isFocus || isValue ? styles.placeholderIn__onFocus : ""} 
            ${selectSearch ? styles.placeholderIn__animation : ""}
          `}
        >
          {placeholder}
        </span>
      )}
      {placeholder && (
        <span
          data-required={required}
          className={`
            ${styles.placeholderOut} 
            ${isFocus || isValue ? styles.placeholderOut__onFocus : ""} 
            ${disabled ? styles.placeholderOut__disabled : ""} 
            ${selectSearch ? styles.placeholderOut__animation : ""} 
            ${transparent ? styles.placeholderOut__transparent : ""}
          `}
        >
          {placeholder}
        </span>
      )}
      {error && (
        <span className={styles.errorMsg}>
          <Text s10 lh12>
            {error}
          </Text>
        </span>
      )}
    </label>
  );
};

export default Label;
