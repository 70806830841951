import styles from "./CareerPathDetailsJobPositionChart.module.scss";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";
import { languages } from "../../../dummyData/dummyData";
import JobPositionsChart from "../JobPositionsChart/JobPositionsChart";
import { careerPathsRepository } from "../../../repository/careerPaths.repository";
import React, { useContext, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { PostContext } from "../../../contexts/PostContext";

const CareerPathDetailsJobPositionChart = ({ data }) => {
  const [chart, setChart] = useState([]);
  const [isEdited, setIsEdited] = useState();
  const { userContextLevel } = useContext(UserContext);
  const { postData } = useContext(PostContext);

  return (
    <div className={styles.jobPositionChartWrapper}>
      {((data.organization && userContextLevel === 2) || userContextLevel === 3) && (
        <HeaderWithButtons buttons={[
          {
            icon: isEdited ? "checkMark" : "edit",
            label: isEdited ? languages.EN.labels.save : languages.EN.labels.edit,
            onClick: () => {
              if (isEdited) {
                setIsEdited(false);
                postData(() => careerPathsRepository.sendJobPositionChart(data.id, chart[0] ? chart[0] : {}))
              } else {
                setIsEdited(true);
              }
            }
          },
        ]} />
      )}
      <JobPositionsChart
        data={data.job_positions}
        isEdited={isEdited}
        onChartChange={setChart}
      />
    </div>
  );
};

export default CareerPathDetailsJobPositionChart
