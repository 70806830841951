// Fisher-Yates (aka Knuth) Shuffle
export const shuffle = (array) => {
  let newArray = [].concat(array);

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
};

export const permuteWithRepetition = (array, length) => {
  if (length <= 1) {
    return array.map(item => [item]);
  } else {
    return permuteWithRepetition(array, length - 1).map(item => array.map(newItem => item.concat([newItem]))).flat();
  }
};
