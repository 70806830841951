import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import RelatedCompetenceAndCourse from "../RelatedCompetenceAndCourse/RelatedCompetenceAndCourse";
import RelatedCompetenceAndCourseTile from "../RelatedCompetenceAndCourseTile/RelatedCompetenceAndCourseTile";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import useFetch from "../../../hooks/useFetch";
import { competencesRepository } from "../../../repository/competences.repository";
import CompetenceComponent from "../../molecules/CompetenceComponent/CompetenceComponent";

const filterDefinitions = (withJobPositions) => [
  {
    keyPath: "competence.name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "level",
    header: languages.EN.labels.level,
    sortId: 2,
  },
  {
    keyPath: "assignment",
    header: languages.EN.labels.yourAssignments,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 5,
  },
  {
    keyPath: "competence.group.name",
    header: languages.EN.labels.group,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
].concat(withJobPositions ? [
  {
    keyPath: "job_position.name",
    header: languages.EN.labels.jobPosition,
    sortId: 4,
  },
] : []).concat([
  {
    keyPath: "course.name",
    header: languages.EN.labels.course,
    sortId: 6,
  },
  {
    keyPath: "preparedInstructorNames",
    header: languages.EN.labels.instructor,
    sortId: 7,
  },
  {
    keyPath: "course.duration",
    header: languages.EN.labels.duration,
    sortId: 8,
  },
]);

const popupFilterDefinitions = [
  {
    keyPath: "name",
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "group.name",
    header: languages.EN.labels.group,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "country",
    header: languages.EN.labels.region,
    typeOfFilter: "checkbox",
  },
];

const PopupListItem = ({ item, thisPriorityLabel, otherPriorityLabel }) => (
  <CompetenceComponent
    name={`${item.label}${item.alreadyWithThisPriority ? ` (${thisPriorityLabel})` : ''}${item.alreadyWithOtherPriority ? ` (${otherPriorityLabel})` : ''}`}
    level={item.competenceLevel.level}
    img={item.photo}
    disabled={item.disabled}
  />
);

export const RequiredPopupListItem = ({ item }) => (
  <PopupListItem item={item} thisPriorityLabel={languages.EN.labels.keyCompetence} otherPriorityLabel={languages.EN.labels.complementaryCompetence} />
);

export const RecommendedPopupListItem = ({ item }) => (
  <PopupListItem item={item} thisPriorityLabel={languages.EN.labels.complementaryCompetence} otherPriorityLabel={languages.EN.labels.keyCompetence} />
);

export const CompetenceWithCourseAndJobPosition = ({ data }) => (
  <RelatedCompetenceAndCourse data={data} withJobPosition={true} />
);

export const CompetenceWithCourseAndJobPositionTile = ({ data }) => (
  <RelatedCompetenceAndCourseTile data={data} withJobPosition={true} />
);

const RelatedCompetencesAndCoursesList = ({ data, id, withJobPositions, withManagement, managementData }) => {
  const { jobPositionId, priority, otherPriorityCompetences } = managementData ?? {};
  const thisPriorityCompetences = data.map((item) => `${item.competence.id}-${item.level}`);

  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.competence.organization !== null ? 2 : 3;
    const course = item.related_courses[0];

    let assignment;
    switch (item.user_assigned === true & item.user_verified === true) {
      case 3:
        assignment = languages.EN.labels.yourAssignmentsValues.assignedAndVerified; break;
      case 2:
        assignment = languages.EN.labels.yourAssignmentsValues.assignedNotVerified; break;
      default:
        assignment = languages.EN.labels.yourAssignmentsValues.notAssigned; break;
    }
    const instructors = course?.course_instructor.length > 0 ? course.course_instructor.map(instructor => ({
      name: instructor.name,
      img: instructor.profile_image_small,
    })) : [{
      name: course?.instructor_text,
      img: undefined,
    }];

    return {
      ...item,
      assignment: assignment,
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.competence.organization.profile_image_small : <AidifyLogo />,
      course: course ? {
        id: course.id,
        name: course.display_name,
        duration: Math.round(course.duration / 60),
      } : {},
      preparedInstructorNames: instructors.map(instructor => instructor.name),
      instructors: instructors,
      can_delete: withManagement,
    };
  })), [data]);

  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const fetchCompetences = useFetch();

  useEffect(() => {
    if (fetchCompetences.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: `${languages.EN.labels.add} ${(priority === 0 ? languages.EN.labels.keyCompetences : languages.EN.labels.complementaryCompetences).toLowerCase()}`,
          items: fetchCompetences.data.reduce((acc, curr) => acc.concat(curr.competence_levels.map((competenceLevel) => {
            const type = curr.organization !== null ? 2 : 3;
            const id = `${curr.id}-${competenceLevel.level}`;
            const thisPriorityIncludes = thisPriorityCompetences.includes(id);
            const otherPriorityIncludes = otherPriorityCompetences.includes(id);

            return {
              ...curr,
              competenceLevel: competenceLevel,
              id: id,
              label: curr.name,
              photo: type === 2 ? curr.organization.profile_image_small : <AidifyLogo />,
              alreadyWithThisPriority: thisPriorityIncludes,
              alreadyWithOtherPriority: otherPriorityIncludes,
              disabled: thisPriorityIncludes || otherPriorityIncludes,

              learningArea: languages.EN.enums.learningAreas[curr.topic],
              visibility: languages.EN.enums.visibility[type],
            }
          })), []),
          RenderItem: priority === 0 ? RequiredPopupListItem : RecommendedPopupListItem,
          filters: popupFilterDefinitions,
          singleSelect: false,
          postAction: (formData) => jobPositionsRepository.addJobPositionCompetences({
            job_position: jobPositionId,
            competence_levels: formData.join(),
            type: priority,
          }),
        },
      });
    }
  }, [fetchCompetences.isReady]);

  return (
    <FilteredList
      id={`relatedCompetencesAndCourses${id[0].toUpperCase() + id.slice(1)}`}
      data={preparedData}
      filterDefinitions={filterDefinitions(withJobPositions)}
      RenderListComponent={withJobPositions ? CompetenceWithCourseAndJobPosition : RelatedCompetenceAndCourse}
      RenderTileComponent={withJobPositions ? CompetenceWithCourseAndJobPositionTile : RelatedCompetenceAndCourseTile}
      buttons={[].concat(withManagement ? [
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: () => {
            setIsDataActive(false);
            fetchCompetences.fetchData(competencesRepository.getCompetences());
            callPopup({ type: ACTIONS.LOADING });
          }
        }
      ] : [])}
    />
  );
};

export default RelatedCompetencesAndCoursesList;
