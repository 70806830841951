import React, { useContext, useEffect, useMemo } from "react";
import Table from "../Table";
import { languages } from "../../../../dummyData/dummyData";
import ProgressBar, { VARIANT_TABLE_LIGHT, VARIANT_TABLE_PRIMARY } from "../../../molecules/ProgressBar/ProgressBar";
import Status from "../../../atoms/Status/Status";
import Button from "../../../atoms/Button/Button";
import { competenceTestRepository } from "../../../../repository/competenceTest.repository";
import { ReactComponent as AidifyLogo } from "../../../../assets/logoIcon.svg";
import CompetenceComponent from "../../../molecules/CompetenceComponent/CompetenceComponent";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import useFetch from "../../../../hooks/useFetch";
import Text from "../../../atoms/Text/Text";
import { RESET_PARAM } from "../../../pages/CourseView/CourseView";
import ConditionsFulfillment from "../../../molecules/ConditionsFulfillment/ConditionsFulfillment";

const CompetenceTestReportTable = ({ reports }) => {
  const {testId, data, courseId, attempt} = reports;

  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const items = courseId ?
    data.map((section) => ({
      id: section.section_id,
      ...section,
      name: section.section_name,
      should_repeat: section.should_repeat || section.unit_data.filter(unit => unit.should_repeat).length > 0,
      subRows: section.unit_data.map((unit) => ({
        ...unit,
        name: (
          <ConditionsFulfillment
            header={unit.unit_name}
            conditions={unit.unit_items.map(item => ({
              condition: `${item.position}. ${item.question}`,
              isFulfilled: item.correct,
            }))}
          />
        ),
        is_subrow: true,
      })),
    })) : data.map((section) => ({
      ...section,
      name: (
        <>
          <CompetenceComponent
            inTable
            name={section.competence_level.competence.name}
            level={section.competence_level.level}
            img={section.competence_level.competence.organization !== null ? section.competence_level.competence.organization.profile_image_small : <AidifyLogo />}
          />
          <ConditionsFulfillment
            header={''}
            conditions={section.unit_data.map(unit => unit.unit_items).flat(1).map(item => ({
              condition: `${item.position}. ${item.question}`,
              isFulfilled: item.correct,
            }))}
          />
        </>
      ),
    }));

  const columns = useMemo(() => [
    {
      Header: courseId ? languages.EN.labels.coursePart : languages.EN.labels.competence,
      rowSpan: 2,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.questions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.count,
          accessor: "num_total",
        },
        {
          Header: languages.EN.labels.answerGiven,
          accessor: (data) => (
            <ProgressBar
              variant={data.is_subrow ? VARIANT_TABLE_LIGHT : VARIANT_TABLE_PRIMARY}
              progress={(data.num_correct + data.num_incorrect) / data.num_total * 100}
              information={`${data.num_correct + data.num_incorrect}/${data.num_total}`}
            />
          ),
        },
        {
          Header: languages.EN.labels.answeredCorrectly,
          accessor: (data) => (
            <ProgressBar
              variant={data.is_subrow ? VARIANT_TABLE_LIGHT : VARIANT_TABLE_PRIMARY}
              progress={data.num_correct / data.num_total * 100}
              information={`${data.num_correct}/${data.num_total}`}
            />
          ),
        },
      ],
    },
    {
      Header: languages.EN.labels.result,
      rowSpan: 2,
      accessor: (data) => (
        !data.is_subrow ? (
          <Status
            variant={data.passed ? "green" : "red"}
            text={data.passed ? languages.EN.labels.positive : languages.EN.labels.negative}
          />
        ) : <>{languages.EN.placeholders.empty}</>
      ),
      cellStyle: { position: "relative" },
    }
  ].concat(courseId ? [{
    Header: languages.EN.labels.actions,
    disableSortBy: true,
    headerStyle: { justifyContent: "center" },
    rowSpan: 2,
    accessor: (data) => data.should_repeat && !data.is_subrow ? (
      <Button
        variant="iconButton"
        onlyIcon
        icon="repeat"
        onClick={(event) => {
          event.stopPropagation();
          callPopup({
            type: ACTIONS.CONFIRM,
            payload: {
              header: languages.EN.labels.confirmation,
              content: (
                <>
                  {languages.EN.messages.resetCourse}
                  {attempt % 3 === 0 && (
                    <>
                      <br /><br />
                      {languages.EN.messages.removeProgress}:
                      <ul>
                        {items.map(section => section.unit_data).flat(1).filter(unit => unit.should_repeat).sort((a, b) => a.unit_name.localeCompare(b.unit_name)).map((unit, i) => (
                          <li key={i}>{unit.unit_name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  <br /><br />
                  <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
                </>
              ),
              action: () => {
                fetchTestReset.fetchData(competenceTestRepository.postTestReset(testId));
              },
            },
          })
        }}
        tooltip={languages.EN.labels.tryAgain}
      />
    ) : null,
    cellStyle: { paddingLeft: "55px" },
  }] : []), []);

  const fetchTestReset = useFetch();
  useEffect(() => {
    if (fetchTestReset.isReady) {
      callPopup({ type: ACTIONS.REMOVE });
      history.push(attempt % 3 === 0 ?
        generatePath(Routes.courseView.about.base, { id: courseId, reset: RESET_PARAM }) :
        generatePath(Routes.competenceTest.test.base, { id: testId, course: courseId })
      );
    }
  }, [fetchTestReset.isReady])

  return <Table columns={columns} data={items} expand={courseId !== undefined ? true : undefined} />;
}

export default CompetenceTestReportTable;
