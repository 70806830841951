import React, { useContext, useEffect } from "react";
import TeamTemplate from "../../templates/TeamTemplate/TeamTemplate";
import useFetch from "../../../hooks/useFetch";
import { teamRepository } from "../../../repository/team.repository";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const Team = ({ match }) => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(teamRepository.getTeam(match.params.id));
    }
  }, [isResponseOk]);

  return data ? (
    <TeamTemplate data={data} teamId={parseInt(match.params.id)} />
  ) : (
    <Loader />
  );
};

export default Team;
