import { ReactComponent as Linkedin } from "../../../assets/icons/linkedin.svg";
import { Link } from "react-router-dom";
import styles from "../Button/Button.module.scss";
import Text from "../Text/Text";
import { languages } from "../../../dummyData/dummyData";
import { Tooltip } from "react-tooltip";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

const LinkedinButton = ({ url }) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <>
      <Link to={{ pathname: url }} rel="noreferrer" target="_blank" data-tip={true} data-tooltip-id={id}>
        <Linkedin />
      </Link>
      <Tooltip id={id} place="top" variant="light" float={true} className={styles.tooltip}>
        <Text s12 lh18 dark-2>
          {languages.EN.labels.viewLinkedInProfile}
        </Text>
      </Tooltip>
    </>
  );
};

export default LinkedinButton
