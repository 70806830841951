import { useEffect, useState } from "react";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import styles from "./PopupFilterPanel.module.scss";
import { languages } from "../../../dummyData/dummyData";
import CheckBoxOnly from "../../atoms/CheckBoxOnly/CheckBoxOnly";

const PopupFilterPanel = ({ careerPaths, setLearningArea, setCareerPaths }) => {
  const leaningAreaHandler = (_, items) => {
    setLearningArea((prev) => prev.includes(items) ? prev.filter((cur) => cur !== items) : [...prev, items]);
  };

  const careerPathsHandler = (_, items) => {
    setCareerPaths((prev) => prev.includes(items.id) ? prev.filter((cur) => cur !== items.id) : [...prev, items.id]);
  };

  const [isLocalReset, setIsLocalReset] = useState(false);

  const handleResetFilteredData = () => {
    setLearningArea([]);
    setCareerPaths([]);
    setIsLocalReset(true);
  };

  useEffect(() => {
    setIsLocalReset(false);
  }, [isLocalReset]);

  return (
    <div className={styles.filterPanelWrapper}>
      <div className={styles.filterPanelPopup}>
        <div className={styles.filterPanelHeader}>
          <Text w800 s14 dark-4>
            {languages.EN.labels.filterResults}
          </Text>
        </div>

        <div className={styles.filters}>
          <div>
            <Text s14 w700 dark-4>
              {languages.EN.labels.learningArea}
            </Text>
            {careerPaths &&
              Object.values(languages.EN.enums.learningAreas)
                .sort((a, b) => a.localeCompare(b))
                .map((item, idx) => (
                  <CheckBoxOnly
                    text={item}
                    handleCheckbox={leaningAreaHandler}
                    key={`${item}-${idx}`}
                    item={idx}
                  />
                ))}
          </div>
          <div>
            <Text s14 w700 dark-4>
              {languages.EN.labels.careerPaths}
            </Text>
            {careerPaths &&
              careerPaths
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => (
                  <CheckBoxOnly
                    text={item.name}
                    handleCheckbox={careerPathsHandler}
                    key={`${item.name}-${item.id}`}
                    item={item}
                  />
                ))}
          </div>
        </div>

        <div className={styles.buttons}>
          <Button
            variant="primaryInnerText"
            withIcon
            icon="filterIcon"
            onClick={handleResetFilteredData}
          >
            {languages.EN.labels.resetFilters}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PopupFilterPanel;
