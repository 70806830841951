import { education_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const addEducation = (data) => {
  return fetch(education_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editEducation = (id, data) => {
  return fetch(`${education_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteEducation = (id) => {
  return fetch(`${education_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const educationRepository = {
  addEducation,
  editEducation,
  deleteEducation,
};
