import RegulatoryIntelligenceTemplate from "../../templates/RegulatoryIntelligenceTemplate/RegulatoryIntelligenceTemplate";
import useFetch from "../../../hooks/useFetch";
import { regulatoryIntelligenceRepository } from "../../../repository/regulatoryIntelligence.repository";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";

const RegulatoryIntelligence = () => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(regulatoryIntelligenceRepository.getArticles());
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <RegulatoryIntelligenceTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default RegulatoryIntelligence;
