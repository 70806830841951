import styles from "./ProgressBar.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import { Tooltip } from "react-tooltip";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export const VARIANT_TABLE_PRIMARY = "tablePrimary";
export const VARIANT_TABLE_LIGHT = "tableLight";
export const VARIANT_SQUARED = "squared";
export const VARIANT_TRANSPARENT = "transparent";

const VARIANT_STYLES = {
  [VARIANT_TABLE_PRIMARY]: styles.progressBarTablePrimary,
  [VARIANT_TABLE_LIGHT]: styles.progressBarTableLight,
  [VARIANT_SQUARED]: styles.progressBarSquared,
  [VARIANT_TRANSPARENT]: styles.progressBarTransparent,
};

const ProgressBar = ({ variant, progress, information, userEnrolled }) => {
  const displayProgress = progress > 0 ? parseFloat(progress).toFixed() : 0;
  const id = useMemo(() => uuidv4(), []);

  return (
    <>
      <div className={VARIANT_STYLES[variant]} data-tip={true} data-tooltip-id={id}>
        {userEnrolled === false ? (
          <div className={styles.informationSingUp}>
            <Text s12 lh24 dark-2 nowrap>
              {languages.EN.labels.signUpToStartProgressing}
            </Text>
          </div>
        ) : (
          <div className={styles.background} style={{ width: `calc(${displayProgress} * 1%)` }}>
            <div className={styles.information}>
              <Text s12 lh24 w400 light>
                {displayProgress}%
              </Text>
            </div>
          </div>
        )}
      </div>
      {information && (
        <Tooltip id={id} place="top" variant="light" float={true} className={styles.tooltip}>
          <Text s12 lh18 dark-4>
            {Math.round(progress)}%
          </Text>
          <Text s12 lh18 dark-4>
            ({information})
          </Text>
        </Tooltip>
      )}
    </>
  );
};

export default ProgressBar;
