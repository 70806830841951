import { invoice_endpoint, platform_invoices_endpoint, } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const addNewInvoice = (data) => {
  return fetch(`${platform_invoices_endpoint}add/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const markPaidInvoice = (id) => {
  return fetch(`${platform_invoices_endpoint}mark_paid/${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const downloadInvoice = (id, type) => {
  return fetch(invoice_endpoint + id + "/", {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};

export const invoicesRepository = {
  addNewInvoice,
  markPaidInvoice,
  downloadInvoice,
};
