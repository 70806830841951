import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../../contexts/PostContext";

const PostConfirmPopup = ({header, content, action}) => {
  const { callPopup } = useContext(PopupsContext);
  const { postData, isPostFinish } = useContext(PostContext);

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish]);

  return (
    <ConfirmPopup
      header={header}
      content={content}
      action={() => postData(action, () => callPopup({ type: ACTIONS.REMOVE }))}
    />
  );
};

export default PostConfirmPopup
