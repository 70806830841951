import React, { useEffect, useState, useRef } from "react";
import styles from "./GoalLevelSlider.module.scss";
import { ReactComponent as DotIcon } from "../../../assets/icons/dotIcon.svg";
import { ReactComponent as DotIcon2 } from "../../../assets/icons/dotIcon2.svg";
import { ReactComponent as DotIcon3 } from "../../../assets/icons/dotIcon3.svg";
import PopupLearningOutcomes from "../../molecules/Popups/PopupLearningOutcomes/PopupLearningOutcomes";

const widthOfGreenBars = {
  1: 33.33,
  2: 66.66,
  3: 100,
};

const GoalLevelSlider = ({ level, competenceLevels, onChange, showPopup }) => {
  const [width, setWidth] = useState(33.33);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setWidth(widthOfGreenBars[level]);
  }, [level]);

  const handlelevel = (level) => {
    if (competenceLevels[level] !== undefined) {
      setWidth(widthOfGreenBars[level]);
      onChange(level);
      setIsClicked(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsClicked(false);
    }, 100);
  }, [isClicked]);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const onDragStartCircle = () => setIsMouseDown(true);
  const onDragEndCircle = () => setIsMouseDown(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      document.addEventListener("mouseup", onDragEndCircle);
      return () => {
        document.removeEventListener("mouseup", onDragEndCircle);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [elementHovered, setElementHovered] = useState(null);

  return (
    <div ref={wrapperRef} onMouseUp={onDragEndCircle}>
      <div className={styles.range}>
        <div
          className={isClicked ? styles.background2 : styles.background}
          style={{ width: width + "%" }}
        />
        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => {
            isMouseDown && handlelevel(1);
            setElementHovered(1);
          }}
          onClick={() => handlelevel(1)}
          onMouseLeave={() => setElementHovered(null)}
        >
          <DotIcon
            className={isClicked ? styles.dotActive : styles.firstDot}
            data-active={width >= widthOfGreenBars[1]}
            data-blocked={competenceLevels[1] === undefined}
          />
          {showPopup && elementHovered === 1 && (
            <PopupLearningOutcomes learningOutcomes={competenceLevels[1]?.learning_outcomes} />
          )}
        </div>
        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => {
            isMouseDown && handlelevel(2);
            setElementHovered(2);
          }}
          onClick={() => handlelevel(2)}
          onMouseLeave={() => setElementHovered(null)}
        >
          <DotIcon2
            className={isClicked ? styles.dotActive : styles.secondDots}
            data-active={width >= widthOfGreenBars[2]}
            data-blocked={competenceLevels[2] === undefined}
          />

          {showPopup && elementHovered === 2 && (
            <PopupLearningOutcomes learningOutcomes={competenceLevels[2]?.learning_outcomes} />
          )}
        </div>
        <div
          className={styles.range__button}
          onMouseDown={onDragStartCircle}
          onMouseUp={onDragEndCircle}
          onMouseEnter={() => {
            isMouseDown && handlelevel(3);
            setElementHovered(3);
          }}
          onClick={() => handlelevel(3)}
          onMouseLeave={() => setElementHovered(null)}
        >
          <DotIcon3
            className={isClicked ? styles.dotActive : styles.thirdDots}
            data-active={width >= widthOfGreenBars[3]}
            data-blocked={competenceLevels[3] === undefined}
          />
          {showPopup && elementHovered === 3 && (
            <PopupLearningOutcomes learningOutcomes={competenceLevels[3]?.learning_outcomes} />
          )}
        </div>
      </div>
    </div>
  );
};
export default GoalLevelSlider;
