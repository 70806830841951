import styles from "./ChatMessage.module.scss";
import Text from "../../../atoms/Text/Text";

const classNames = {
  sendedMessage: styles.sendedMessage,
  receivedMessage: styles.receivedMessage,
};

const ChatMessage = ({ variant, message }) => {
  const { created, msg, sender } = message;

  const date = created.split("-").join("/").slice(0, 10);
  const time = created.slice(11, 16);

  return (
    <div className={classNames[variant]}>
      <img src={sender.profile.profile_image_medium} alt="" />
      <div>
        <Text dark-4 s12 w700>
          {msg}
        </Text>
        <Text dark-4 s10>
          {`${date} ${time}`}
        </Text>
      </div>
    </div>
  );
};

export default ChatMessage;
