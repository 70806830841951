import StudentLearningReportTemplate from "../../templates/StudentLearningReportTemplate/StudentLearningReportTemplate";
import useFetch from "../../../hooks/useFetch";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { userRepository } from "../../../repository/user.repository";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";

const StudentLearningReport = ({ match }) => {
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const fetchProfile = useFetch();
  useEffect(() => {
    fetchProfile.fetchData(userRepository.getUserProfile(match.params.username));
  }, []);

  const fetchLearningReport = useFetch();
  useEffect(() => {
    fetchLearningReport.fetchData(userRepository.getLearningReport(match.params.username, {
      ...(match.params.date ? {date: match.params.date} : {})
    }));
  }, [match.params.date]);

  useEffect(() => {
    if (fetchLearningReport.isReady && fetchLearningReport.data.user_reports.length === 0) {
      if (match.params.date !== undefined) {
        history.push(generatePath(Routes.studentLearningReport.base, { username: match.params.username }));
      } else {
        history.go(-1);
      }

      callPopup({
        type: ACTIONS.INFORMATION,
        payload: {
          header: languages.EN.labels.information,
          content: languages.EN.messages.dataDoesNotExist,
        },
      });
    }
  }, [fetchLearningReport.data]);


  useDocumentTitleLoading();

  return fetchProfile.data && fetchLearningReport.data && !fetchLearningReport.loading && fetchLearningReport.data.user_reports.length > 0 ? (
    <StudentLearningReportTemplate data={fetchLearningReport.data} userData={fetchProfile.data} />
  ) : (
    <Loader />
  );
};

export default StudentLearningReport;
