import React, { useContext, useMemo } from "react";
import Table from "../Table";
import { languages } from "../../../../dummyData/dummyData";
import styles from "../Table.module.scss";
import Button from "../../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { educationRepository } from "../../../../repository/education.repository";
import { TYPE_CHECKBOX, TYPE_DATE, TYPE_DROPDOWN } from "../../../molecules/Form/Form";
import { dateDiffFormat, dateFormat } from "../../../../utils/formatters";

const popupFields = (data = {}) => [
  {
    name: "school",
    label: languages.EN.labels.school,
    required: true,
    value: data.school,
  },
  {
    name: "degree",
    label: languages.EN.labels.degree,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.degree,
    items: languages.EN.enums.educationDegrees,
  },
  {
    name: "field",
    label: languages.EN.labels.field,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.field,
    items: languages.EN.enums.educationFields,
  },
  [
    {
      name: "start_date",
      label: languages.EN.labels.from,
      required: true,
      type: TYPE_DATE,
      value: data.start_date,
    },
    {
      name: "end_date",
      label: languages.EN.labels.to,
      required: data.ongoing !== 1,
      disabled: data.ongoing === 1,
      type: TYPE_DATE,
      value: data.end_date,
    },
    {
      name: "ongoing",
      label: languages.EN.labels.ongoing,
      type: TYPE_CHECKBOX,
      value: data.ongoing ?? 0,
    },
  ],
];

const EducationTable = ({ reports }) => {
  const { data, canEdit } = reports;
  const { callPopup } = useContext(PopupsContext);

  const items = data.map((item) => ({
    ...item,
    degreeName: languages.EN.enums.educationDegrees[item.degree],
    fieldName: languages.EN.enums.educationFields[item.field],
    period: `${dateFormat(item.start_date)} - ${item.end_date ? dateFormat(item.end_date) : languages.EN.labels.ongoing}`,
    duration: item.end_date ? dateDiffFormat(item.start_date, item.end_date) : languages.EN.placeholders.empty,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.school,
      accessor: "school",
    },
    {
      Header: languages.EN.labels.degree,
      accessor: "degreeName",
    },
    {
      Header: languages.EN.labels.field,
      accessor: "fieldName",
    },
    {
      Header: languages.EN.labels.period,
      accessor: "period",
    },
    {
      Header: languages.EN.labels.duration,
      accessor: "duration",
      sortType: (rowA, rowB) => Math.sign(
        (new Date(rowA.original.end_date) - new Date(rowA.original.start_date)) - (new Date(rowB.original.end_date) - new Date(rowB.original.start_date))
      ),
    },
  ].concat(canEdit ? [
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={() => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.education}`,
                fields: (formData) => popupFields({ ...data.row.original, ...formData }),
                postAction: (formData) => educationRepository.editEducation(data.row.original.id, {...formData, end_date: formData.ongoing !== 1 ? formData.end_date : ""}),
              },
            })}
            tooltip={languages.EN.labels.edit}
          />
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.educationEntry,
                elementName: `${languages.EN.labels.school} ${data.row.original.school}`,
                action: () => educationRepository.deleteEducation(data.row.original.id),
              },
            })}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ] : []), []);

  return <Table buttons={canEdit ? [
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.education}`,
          fields: (formData) => popupFields(formData),
          postAction: (formData) => educationRepository.addEducation({...formData, end_date: formData.ongoing !== 1 ? formData.end_date : ""}),
        },
      })
    },
  ] : []} columns={columns} data={items} />;
}

export default EducationTable;
