import styles from "./LearningReportCompetences.module.scss";
import PieChartTotalContainer from "../../PieChartTotalContainer/PieChartTotalContainer";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import CompetenceListTable from "../../Table/LearningReport/Competences/CompetenceListTable";
import ObtainingProgressTable from "../../Table/LearningReport/Competences/UserCompetencesTable";
import Statistic from "../../../molecules/Statistic/Statistic";
import arrowClock from "../../../../assets/icons/statistics/arrowClock.svg";
import { languages } from "../../../../dummyData/dummyData";
import { durationFormat } from "../../../../utils/formatters";

const LearningReportCompetences = ({ data }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.statisticsContainer}>
        <PieChartTotalContainer
          header={languages.EN.labels.competencesOnPlatform}
          config={[
            {
              name: languages.EN.enums.learningAreas[0],
              value: data.num_competence_0 + data.num_internal_competence_0,
              color: "#B4E7E3",
            },
            {
              name: languages.EN.enums.learningAreas[1],
              value: data.num_competence_1 + data.num_internal_competence_1,
              color: "#4B8A85",
            },
            {
              name: languages.EN.enums.learningAreas[2],
              value: data.num_competence_2 + data.num_internal_competence_2,
              color: "#6CEBE1",
            },
          ]}
        />
        <PieChartTotalContainer
          header={languages.EN.labels.totalAssignedCompetences}
          config={[
            {
              name: languages.EN.labels.notVerified,
              value: data.num_user_competence - data.num_user_competence_manager - data.num_user_competence_test - data.num_user_competence_course,
              color: "#B4E7E3",
            },
            {
              name: languages.EN.enums.verificationTypes[0],
              value: data.num_user_competence_course,
              color: "#4B8A85",
            },
            {
              name: languages.EN.enums.verificationTypes[1],
              value: data.num_user_competence_test,
              color: "#6CEBE1",
            },
            {
              name: languages.EN.enums.verificationTypes[2],
              value: data.num_user_competence_manager,
              color: "#00A094",
            },
          ]}
        />
        <Statistic
          icon={arrowClock}
          content={languages.EN.labels.timeCompetence}
          value={durationFormat(data.avg_competence_time)}
        />
      </div>
      <TableWrapper
        Table={CompetenceListTable}
        reports={{ data: data.competence_reports }}
        title={languages.EN.labels.competenceList}
        widthLimit
      />
      <TableWrapper
        Table={ObtainingProgressTable}
        reports={{ data: data.user_reports }}
        title={languages.EN.labels.competenceObtainingProgress}
        widthLimit
      />
    </div>
  );
};

export default LearningReportCompetences;
