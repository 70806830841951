import React from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { Route, Switch } from "react-router";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath } from "react-router-dom";
import styles from "./IdpsTemplate.module.scss";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import IdpHistoryTable from "../../organisms/Table/IdpHistoryTable/IdpHistoryTable";
import TopBanner from "../../molecules/TopBanner/TopBanner";

const IdpsTemplate = ({ idp, username }) => {
  const links = [
    {
      content: languages.EN.labels.idpHistory,
      href: generatePath(Routes.idps.base, { username: username }),
    }
  ];

  useDocumentTitle(`${idp.user_profile.name} ${idp.user_profile.surname} | ${languages.EN.labels.individualDevelopmentPlan}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={idp.user_profile.name + " " + idp.user_profile.surname}
        path={[
          {
            name: languages.EN.labels.profile,
            link: generatePath(Routes.profile.statistics.base, { username: username }),
          },
          {
            name: languages.EN.labels.individualDevelopmentPlan,
            link: "#",
          },
        ]}
        img={idp.user_profile.profile_image_medium}
        imgSquare={true}
        infos={(idp.user_profile.user_job_position ? [
          {
            label: languages.EN.labels.jobPosition,
            value: idp.user_profile.user_job_position.name,
          },
        ] : []).concat([
          {
            label: languages.EN.labels.organization,
            value: idp.user_profile.user_organization.name,
          },
        ])}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route path={links[0].href}>
            <div className={styles.idp__box}>
              <TableWrapper
                Table={IdpHistoryTable}
                reports={{ data: idp.idps, username: username }}
                title={languages.EN.labels.idpHistory}
              />
            </div>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default IdpsTemplate;
