import { notes_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

export const mimeTypes = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const getFiles = () => {
  return fetch(notes_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const downloadFile = (id, name, extension) => {
  return fetch(`${notes_endpoint}download/${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.download = name;
      link.href = URL.createObjectURL(new Blob([blob], { type: mimeTypes[extension] }));
      link.click();
    });
}

const downloadFiles = (ids) => {
  return fetch(`${notes_endpoint}bulk_download/?ids=${ids}`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.download = `files_${ids}.zip`;
      link.href = URL.createObjectURL(new Blob([blob]));
      link.click();
    });
}

const postFile = (data) => {
  return fetch(`${notes_endpoint}upload/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteFile = (id) => {
  return fetch(`${notes_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const filesRepository = {
  getFiles,
  downloadFile,
  downloadFiles,
  postFile,
  deleteFile,
};
