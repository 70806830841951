import Input, { inputType } from "../Input/Input";
import React, { useState } from "react";
import ConditionsFulfillment from "../../molecules/ConditionsFulfillment/ConditionsFulfillment";
import { languages } from "../../../dummyData/dummyData";
import styles from "./PasswordWithRequirementsInput.module.scss";

const REQUIREMENTS = [
  {
    text: "at least 12 characters",
    validator: (value) => value.length >= 12,
  },
  {
    text: "lowercase and uppercase letters",
    validator: (value) => /[a-z]/.test(value) && /[A-Z]/.test(value),
  },
  {
    text: "at least 1 digit",
    validator: (value) => /[0-9]/.test(value),
  },
  {
    text: "minimum 1 special character, such as ~ ! @ # $ % ^ & * ( ) _ + { } \\ \" : ; ' [ ] .",
    validator: (value) => /[~!@#$%^&*()_+{}\\":;'\[\].]/.test(value),
  },
];

const getIsFulfilled = (value) => REQUIREMENTS.map(requirement => requirement.validator(value));

const PasswordWithRequirementsInput = ({ name, value, required, disabled, handleInput, placeholder, error, autoFocus }) => {
  const [isFulfilled, setisFulfilled] = useState(getIsFulfilled(value));

  return (
    <>
      <Input
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        variant={inputType.password}
        handleInput={(e) => {
          setisFulfilled(getIsFulfilled(e.target.value));
          handleInput(e);
        }}
        placeholder={placeholder}
        error={error}
        autoFocus={autoFocus}
      />
      <div className={styles.requirements} data-input-has-error={!!error}>
        <ConditionsFulfillment header={languages.EN.messages.yourPasswordMustContain} conditions={REQUIREMENTS.map((requirement, i) => ({
          condition: requirement.text,
          isFulfilled: isFulfilled[i],
        }))} />
      </div>
    </>
  );
};

export default PasswordWithRequirementsInput
