import JobPositionsTemplate from "../../templates/JobPositionsTemplate/JobPositionsTemplate";
import useFetch from "../../../hooks/useFetch";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { PostContext } from "../../../contexts/PostContext";

const JobPositions = () => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(jobPositionsRepository.getJobPositions());
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <JobPositionsTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default JobPositions;
