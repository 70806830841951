import FilesTemplate from "../../templates/FilesTemplate/FilesTemplate";
import useFetch from "../../../hooks/useFetch";
import { filesRepository } from "../../../repository/files.repository";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { regulatoryIntelligenceRepository } from "../../../repository/regulatoryIntelligence.repository";
import { coursesRepository } from "../../../repository/courses.repository";
import { learningEventsRepository } from "../../../repository/learningEvents.repository";

const Files = () => {
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  const fetchFiles = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchFiles.data) {
      fetchFiles.fetchData(filesRepository.getFiles());
    }
  }, [isResponseOk]);

  const fetchCourses = useFetch();
  const fetchLearningEvents = useFetch();
  const fetchRegulatoryIntelligence = useFetch();
  useEffect(() => {
    fetchCourses.fetchData(coursesRepository.getCourses());
    fetchLearningEvents.fetchData(learningEventsRepository.getLearningEvents());
    fetchRegulatoryIntelligence.fetchData(regulatoryIntelligenceRepository.getArticles());
  }, []);

  return fetchFiles.data && fetchCourses.data && fetchLearningEvents.data && fetchRegulatoryIntelligence.data ? (
    <FilterContextProvider>
      <FilesTemplate data={fetchFiles.data} courses={fetchCourses.data} learningEvents={fetchLearningEvents.data} regulatoryIntelligence={fetchRegulatoryIntelligence.data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default Files;
