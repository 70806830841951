import LearningEventDetailsTemplate from "../../templates/LearningEventDetailsTemplate/LearningEventDetailsTemplate";
import useFetch from "../../../hooks/useFetch";
import { learningEventsRepository } from "../../../repository/learningEvents.repository";
import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";

const LearningEventDetails = ({ match }) => {
  const { data, fetchData, resetData } = useFetch();

  useDocumentTitleLoading();

  useEffect(() => {
    fetchData(learningEventsRepository.getLearningEventDetails(match.params.id));
  }, []);

  // Handle picking search result
  useEffect(() => {
    if (data) {
      resetData();
      fetchData(learningEventsRepository.getLearningEventDetails(match.params.id));
    }
  }, [match.params.id]);

  return data ? (
    <LearningEventDetailsTemplate data={data} />
  ) : (
    <Loader />
  );
};

export default LearningEventDetails;
