import React, { useMemo } from "react";
import styles from "./BadgesTable.module.scss";
import BadgePieChart from "../../../../atoms/BadgePieChart/BadgePieChart";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";

const BadgesTable = ({ reports }) => {
  const { data, types } = reports;

  const items = data.map((item) => ({
    student: `${item.user.profile.name} ${item.user.profile.surname}`,
    badges: Object.fromEntries(item.badge_reports.map(badge => [badge.badge.name, badge])),
    ...item,
  }));

  const columns = useMemo(
    () => [
      {
        Header: languages.EN.labels.student,
        accessor: "student",
        rowSpan: 2,
      },
      ...Object.entries(types).map(([header, subtypes]) => ({
        Header: header,
        disableSortBy: true,
        headerStyle: { justifyContent: "center" },
        columns: subtypes.map(type => ({
          Header: languages.EN.enums.badges[type],
          id: `badge_${type}`,
          accessor: (data) => data.badges[type].level,
          Cell: (data) => (
            <div className={styles.badgeWrapper}>
              <BadgePieChart
                inTable
                level={data.row.original.badges[type].level}
                name={data.row.original.badges[type].badge.name}
                progress={1}
              />
            </div>
          ),
        })),
      })),
    ],
    []
  );

  return <Table columns={columns} data={items} />;
};

export default BadgesTable;
