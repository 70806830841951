import { test_endpoint, problem_feedback_endpoint, problem_answer_endpoint, initial_test_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getTest = (testId) => {
  return fetch(`${test_endpoint}${testId}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const postTestAnswer = (testId, data) => {
  return fetch(`${problem_answer_endpoint}${testId}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const getTestStatus = (id) => {
  return fetch(`${test_endpoint}${id}/status/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const finishTest = (id) => {
  return fetch(`${test_endpoint}${id}/finish/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getTestSummary = (id) => {
  return fetch(`${test_endpoint}${id}/summary/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const postTestReset = (id) => {
  return fetch(`${test_endpoint}${id}/reset/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getInitialTests = () => {
  return fetch(initial_test_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const runManualTest = (data) => {
  return fetch(initial_test_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const competenceTestRepository = {
  getTest,
  postTestAnswer,
  getTestStatus,
  finishTest,
  getTestSummary,
  postTestReset,
  getInitialTests,
  runManualTest,
};
