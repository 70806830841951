import ListItem from "../ListItem/ListItem";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";

const CareerPath = ({ data }) => {
  const { id, name, description, profile_image_small, num_org_members, num_team_members, country, topic, visibility, visibilityImg, active } = data;
  let history = useHistory();

  return (
    <ListItem title={name} subtitle={`${languages.EN.labels.region}: ${country}`} image={profile_image_small} inactive={!active} buttons={[
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.careerPathDetails.statistics.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ]}>
      <ListItemInfo value={languages.EN.enums.learningAreas[topic]} />
      <ListItemInfo value={description} />
      <ListItemInfo value={num_org_members} />
      <ListItemInfo value={num_team_members} />
      <ListItemImgInfo value={visibility} image={visibilityImg} />
    </ListItem>
  );
};

export default CareerPath;
