import styles from "./CourseBig.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import Button from "../../atoms/Button/Button";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import HoverPreview from "../HoverPreview/HoverPreview";
import { useCourseButtons } from "../../../hooks/useCourseButtons";

const CourseBig = ({ course }) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState();
  const [allowPlaying, setAllowPlaying] = useState(false);
  const { icon, label, onClick } = useCourseButtons(course);
  let timer = undefined;

  useEffect(() => {
    if (isHovered) {
      timer = setTimeout(() => setAllowPlaying(true), 2000);
    } else {
      setAllowPlaying(false);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isHovered]);

  return (
    <div
      className={styles.slide}
      style={{ ['--image-url']: `url("${course.profile_image}")` }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <HoverPreview url={course.video_preview} allowPlaying={allowPlaying} />
      <div className={styles.slide__info}>
        <div className={styles.slide__info__titleBox}>
          <Text s16 lh20 w600 secondary shadow20>
            {languages.EN.enums.learningAreas[course.topic]}
          </Text>
          <Text s48 w800 secondary shadow20>
            {course.display_name}
          </Text>
        </div>
        <div className={styles.slide__info__instructorsBox}>
          <Text lh32 s24 w600 dark-6 montserrat>
            {course.instructors.length > 1 ? languages.EN.labels.instructors : languages.EN.labels.instructor}
          </Text>
          <div className={styles.slide__info__instructorsBox__list}>
            {course.instructors.map((instructor, i) => (
              <div key={i}>
                <Text lh32 s24 dark-4 montserrat>{instructor.name}</Text>
                <RoundImage image={instructor.img} size={64} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.slide__buttons}>
          <Button variant={"primaryH40"} onClick={onClick} icon={icon} withIcon useIconsFile>
            {label}
          </Button>
          <Button
            variant={"primaryLightH40"}
            onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: course.id }))}
            icon={"dots"}
            withIcon
            useIconsFile
          >
            {languages.EN.labels.details}
          </Button>
        </div>
      </div>
      <div className={styles.slide__bottomShadow} />
    </div>
  );
};

export default CourseBig
