import React, { useContext } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import TileItem from "../TileItem/TileItem";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { teamRepository } from "../../../repository/team.repository";
import MemberLicenseInfo from "../../molecules/MemberLicenseInfo/MemberLicenseInfo";
import MemberTeamInfo from "../../molecules/MemberTeamInfo/MemberTeamInfo";

const Member = ({ data, teamId, managementLevel }) => {
  const { selectableId, isChecked, teams, licenses } = data;
  const { email, username } = data.user;
  const { user_job_position, user_career_path, name, surname, phone_number, profile_image_small } = data.user.profile;
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  return (
    <TileItem
      title={name ? `${name} ${surname}` : undefined}
      image={profile_image_small}
      selectableId={selectableId}
      isChecked={isChecked}
      buttons={[
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.profile.statistics.base, { username: username })),
          tooltip: languages.EN.labels.details,
        }
      ].concat(teamId && managementLevel > 0 ? [
        {
          icon: "garbage",
          onClick: () => callPopup({
            type: ACTIONS.DELETE,
            payload: {
              type: languages.EN.labels.teamMember,
              elementName: name ? `${name} ${surname} (${email})` : email,
              action: () => teamRepository.postTeamUnassign(username, teamId),
            },
          }),
          tooltip: languages.EN.labels.delete,
        }
      ] : [])}
    >
      {teamId === undefined ? (
        <TileItemInfo header={languages.EN.labels.team} value={teams.map((team, i) => (
          <MemberTeamInfo key={i} team={team} />
        ))} />
      ) : (
        <TileItemInfo header={languages.EN.labels.careerPath} value={user_career_path?.name} />
      )}
      <TileItemInfo header={languages.EN.labels.jobPosition} value={user_job_position?.name} />
      {managementLevel > 0 && <TileItemInfo header={languages.EN.labels.licenses} fullRow={true} value={licenses
        .sort((a, b) => new Date(a.expiry_date) - new Date(b.expiry_date))
        .map((license, i) => (
          <MemberLicenseInfo key={i} license={license} username={username} canEdit={managementLevel === 2} />
        ))
      } />}
      <TileItemInfo header={languages.EN.labels.email} fullRow={true} value={email} />
      <TileItemInfo header={languages.EN.labels.phoneNumber} value={phone_number} />
    </TileItem>
  );
};

export default Member;
