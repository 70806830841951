import styles from "../QuestionsTable.module.scss";
import React from "react";

const PairUpAnswer = ({ answer1, answer2 }) => {
  return (
    <div className={styles.pairRow}>
      <span className={styles.pairAnswer}>
        {answer1}
      </span>
      <hr className={styles.arrows} />
      <span className={styles.pairAnswer}>
        {answer2}
      </span>
    </div>
  );
};

export default PairUpAnswer
