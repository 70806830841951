import React, { useRef, useState, useCallback } from "react";
import styles from "./TileInformation.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";

const TileInformation = ({ data, secondary, lineClamp, inTile }) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const ref = useRef();
  const truncatedRef = useRef();

  const resizeRef = useCallback(() => {
    if (ref.current !== null && truncatedRef.current !== null) {
      if (
        data.length > lineClamp - 1 ||
        truncatedRef.current.clientWidth > ref.current.clientWidth
      ) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }
    }
  }, [data, lineClamp]);

  const { empty } = languages.EN.placeholders;

  return (
    <>
      <div
        data-secondary={secondary}
        className={styles.TextWrapper}
        data-tile={inTile}
        onMouseEnter={resizeRef}
      >
        <div
          className={styles.lineClamp}
          ref={ref}
          style={{ WebkitLineClamp: lineClamp }}
        >
          {!!data.length ? (
            data.length > lineClamp - 1 && lineClamp === 1 ? (
              data.slice(0, lineClamp).map((item, idx) => (
                <Text
                  s12
                  lh24
                  dark-2
                  lineClamp1
                  wordBreak
                  key={`${item.id} ${idx}`}
                >
                  {item.number ? item.number : item.name} . . .
                </Text>
              ))
            ) : data.length > lineClamp - 1 ? (
              <>
                {data.slice(0, lineClamp - 1).map((item, idx) => (
                  <Text
                    s12
                    lh24
                    dark-2
                    lineClamp1
                    wordBreak
                    key={`${item.id} ${idx}`}
                  >
                    {item.number ? item.number : item.name}
                  </Text>
                ))}
                ...
              </>
            ) : (
              data.length <= lineClamp - 1 &&
              data.map((item, idx) => (
                <Text
                  s12
                  lh24
                  dark-2
                  lineClamp1
                  wordBreak
                  key={`${item.id} ${idx}`}
                >
                  {item.number ? item.number : item.name}
                </Text>
              ))
            )
          ) : (
            empty
          )}

          {isTruncated && (
            <div className={styles.truncated}>
              {!!data.length
                ? data.map((item, idx) => (
                    <Text s12 lh24 dark-2 key={`${item.id} ${idx}`}>
                      {item.number ? item.number : item.name}
                    </Text>
                  ))
                : empty}
            </div>
          )}
        </div>
      </div>
      <div className={styles.widthInformation} ref={truncatedRef}>
        {data.slice(0, lineClamp - 1).map((item, idx) => (
          <Text s12 lh24 dark-2 lineClamp1 key={`${item.id} ${idx}`}>
            {item.number ? item.number : item.name}
          </Text>
        ))}
      </div>
    </>
  );
};

export default TileInformation;
