import styles from "./PieChartTotal.module.scss";
import { ResponsivePie } from "@nivo/pie";
import Text from "../Text/Text";
import { languages } from "../../../dummyData/dummyData";

const countPercentage = (partial, sum) => {
  return sum !== 0 ? ((100 * partial) / sum).toFixed(2) : 0;
};

const PieChartTotal = ({ config }) => {
  const total = config.reduce((acc, curr) => acc + curr.value, 0);

  return total > 0 ? (
    <div className={styles.Chart}>
      <div className={styles.ChartBorderOutsideFilter}></div>
      <div className={styles.ChartInside}>
        <span>{total ? total : null}</span>
      </div>
      <ResponsivePie
        data={config.map((item) => {
          return {
            id: item.name,
            label: item.name,
            value: item.value,
            color: item.color,
          };
        })}
        innerRadius={0.66}
        padAngle={0}
        cornerRadius={0}
        startAngle={0}
        colors={config.map((item) => item.color)}
        borderWidth={0}
        arcLinkLabelsSkipAngle={10}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={10}
        arcLinkLabelsTextColor='#333333'
        arcLinkLabelsThickness={0}
        arcLabelsSkipAngle={10000}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 0]] }}
        tooltip={function (e) {
          return (
            <div className={styles.statisticBox}>
              <div style={{ backgroundColor: e.datum.data.color }}></div>
              <div>
                <Text s12 w400 dark-6>
                  {e.datum.label}: {countPercentage(e.datum.value, total)}%
                </Text>
                <Text s12 w400 dark-6>
                  {"(" + e.datum.value + "/" + total + ")"}
                </Text>
              </div>
            </div>
          );
        }}
        fill={config.map((item) => {
          return {
            match: {
              id: item.name,
            },
            id: "clean",
          };
        })}
      />
    </div>
  ) : (
    <div className={styles.chartPlaceholder}>
      <div className={styles.chartPlaceholder__fill} />
      <Text secondary w800 s24>
        {languages.EN.labels.noData}
      </Text>
    </div>
  );
};

export default PieChartTotal;
