import { marketplace_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getMarketplace = () => {
  return fetch(marketplace_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const sendRequest = (id, data) => {
  return fetch(`${marketplace_endpoint + id}/request/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const marketplaceRepository = {
  getMarketplace,
  sendRequest,
};
