import Text from "../../../atoms/Text/Text";
import Button from "../../../atoms/Button/Button";
import { PopupsContext } from "../../../../contexts/PopupsContext";
import { ACTIONS } from "../../../../contexts/PopupsContext";
import React, { useContext } from "react";
import styles from "./PopupInformation.module.scss";
import { languages } from "../../../../dummyData/dummyData";
import Html from "../../../atoms/Html/Html";

const PopupInformation = ({ header, content, action, buttonLabel }) => {
  const { callPopup } = useContext(PopupsContext);

  return (
    <>
      <Text>{header}</Text>
      <div className={styles.content}>
        {React.isValidElement(content) ? (
          <Text s12 dark-4>{content}</Text>
        ) : (
          <Html s12 dark-4>{content}</Html>
        )}
      </div>
      <div className={styles.Wrapper}>
        <Button
          onClick={() => {
            callPopup({ type: ACTIONS.REMOVE });
            action && action();
          }}
          variant={"primary"}
        >
          {buttonLabel ?? languages.EN.labels.ok}
        </Button>
      </div>
    </>
  );
};

export default PopupInformation;
