import { generatePath, useHistory } from "react-router-dom";
import TileItem from "../TileItem/TileItem";
import { languages } from "../../../dummyData/dummyData";
import VerificationBar, { VARIANT_PRIMARY } from "../../molecules/VerificationBar/VerificationBar";
import { VARIANT_ORANGE, VARIANT_RED } from "../../atoms/Status/Status";
import { Routes } from "../../../routes";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import Level from "../../atoms/Level/Level";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { userRepository } from "../../../repository/user.repository";

const CompetenceLevelTile = ({ data, isMine, withManagement, username }) => {
  const { competence, competence_level_id, level, learningArea, visibility, visibilityImg, verified, verification_type } = data;
  const { callPopup } = useContext(PopupsContext);
  const { isPostFinish, postResponse } = useContext(PostContext);

  let history = useHistory();

  useEffect(() => {
    if (isPostFinish && postResponse?.initial_test) {
      history.push(generatePath(Routes.competenceTest.test.base, { id: postResponse.initial_test }));
    }
  }, [postResponse]);

  return (
    <TileItem
      title={competence.name}
      subtitle={`${languages.EN.labels.region}: ${competence.country}`}
      image={competence.profile_image_small}
      bar={(
        <VerificationBar
          progress={verified > 0 ? 1 : 0}
          text={verified === 2 ? languages.EN.enums.verificationTypes[verification_type] : languages.EN.enums.verificationStatuses[verified]}
          variant={verified === 2 ? VARIANT_PRIMARY : (verified === 1 ? VARIANT_ORANGE : VARIANT_RED)}
          inTile
        />
      )}
      buttons={[
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.competenceDetails.jobPositions.base, { id: competence.id })),
          tooltip: languages.EN.labels.details,
        },
      ].concat(isMine && verified < 2 ? [
        {
          icon: "exam",
          onClick: () => callPopup({
            type: ACTIONS.POST_CONFIRM,
            payload: {
              header: languages.EN.labels.confirmation,
              content: languages.EN.messages.verifyManual,
              action: () => competenceTestRepository.runManualTest({ competence_levels: competence_level_id }),
            },
          }),
          tooltip: languages.EN.labels.verificationByTest,
        },
      ] : []).concat(withManagement && verified < 2 ? [
        {
          icon: "checkMark",
          onClick: () => callPopup({
            type: ACTIONS.POST_CONFIRM,
            payload: {
              header: languages.EN.labels.confirmation,
              content: languages.EN.messages.verifyManual,
              action: () => {
                callPopup({ type: ACTIONS.REMOVE });
                return userRepository.sendUserCompetenceVerify({
                  target_user: username,
                  competence: competence.id,
                  level: level,
                });
              },
            },
          }),
          tooltip: languages.EN.labels.manualVerification,
        },
      ] : [])}>
      <TileItemInfo header={languages.EN.labels.level} value={(
        <Level small level={level} />
      )} />
      <TileItemInfo header={languages.EN.labels.learningArea} value={learningArea} />
      <TileItemInfo header={languages.EN.labels.group} value={competence.group.name} />
      <TileItemImgInfo header={languages.EN.labels.visibility} value={visibility} image={visibilityImg} />
    </TileItem>
  );
};

export default CompetenceLevelTile
