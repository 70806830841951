import React, { useMemo, useState } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import { durationFormat } from "../../../../../utils/formatters";
import Table from "../../Table";
import CompoundTableHeader from "../../../../molecules/CompoundTableHeader/CompoundTableHeader";
import BadgeLevelsTypeCell, { getValueFromData, TYPE_ACTIVITY, TYPE_ALL, TYPE_LEARNING, TYPE_SOCIAL } from "./BadgeLevelsTypeCell";
import { TYPES_ACTIVITY, TYPES_LEARNING, TYPES_SOCIAL } from "../../../../../constants/badges";

export const COMPOUND_HEADER_CONFIG = {
  gridTemplateColumns: "1fr 1fr 1fr",
  gridTemplateAreas: `
    "${TYPE_ALL} ${TYPE_ALL} ${TYPE_ALL}" 
    "${TYPE_LEARNING} ${TYPE_ACTIVITY} ${TYPE_SOCIAL}" 
  `,
  items: [
    {
      type: TYPE_ALL,
      label: languages.EN.labels.noOfStudentsHaving,
      headerStyle: { justifyContent: "center" },
    },
    {
      type: TYPE_LEARNING,
      label: languages.EN.labels.learningBadges,
      headerMarkerColor: "#78c0f5",
    },
    {
      type: TYPE_ACTIVITY,
      label: languages.EN.labels.activityBadges,
      headerMarkerColor: "#f27f2a",
    },
    {
      type: TYPE_SOCIAL,
      label: languages.EN.labels.socialBadges,
      headerMarkerColor: "#ffdb70",
    },
  ]
};

const BadgesSummaryTable = ({ reports }) => {
  const { data } = reports;
  const [compoundColumnSort, setCompoundColumnSort] = useState(null);

  const items = data.map((item) => ({
    student: `${item.user.profile.name} ${item.user.profile.surname}`,
    learningBadgesCount: item.badge_reports.filter(badge => TYPES_LEARNING.includes(badge.badge.name)).reduce((acc, curr) => acc + curr.level, 0),
    activityBadgesCount: item.badge_reports.filter(badge => TYPES_ACTIVITY.includes(badge.badge.name)).reduce((acc, curr) => acc + curr.level, 0),
    socialBadgesCount: item.badge_reports.filter(badge => TYPES_SOCIAL.includes(badge.badge.name)).reduce((acc, curr) => acc + curr.level, 0),
    ...item,
  }));
  const maxBadgesLevels = Math.max(...items.map((item) => item.total_badge_levels));

  const columns = useMemo(
    () => [
      {
        Header: languages.EN.labels.student,
        accessor: "student",
        rowSpan: 2,
      },
      {
        Header: languages.EN.labels.totalTimeSpent,
        accessor: "total_usage",
        Cell: (data) => durationFormat(data.cell.value),
        rowSpan: 2,
      },
      {
        Header: (data) => (
          <CompoundTableHeader
            config={COMPOUND_HEADER_CONFIG}
            type={compoundColumnSort}
            setType={setCompoundColumnSort}
            isSorted={data.column.isSorted}
            isSortedDesc={data.column.isSortedDesc}
            toggle={() => data.toggleSortBy("numberOfBadgesLevels")}
          />
        ),
        id: "numberOfBadgesLevels",
        accessor: (data) => getValueFromData(data, compoundColumnSort),
        Cell: (data) => (
          <BadgeLevelsTypeCell data={data.cell.row.original} barConfig={COMPOUND_HEADER_CONFIG.items.slice(1)} max={maxBadgesLevels} />
        ),
        disableSortBy: true,
        fullWidth: true,
      },
    ],
    [compoundColumnSort]
  );

  return <Table columns={columns} data={items} />;
}
export default BadgesSummaryTable
