import styles from "./ListChatItem.module.scss";
import { useContext, useEffect, useState } from "react";
import Text from "../../../atoms/Text/Text";
import Icons from "../../../../assets/icons";
import { UserContext } from "../../../../contexts/UserContext";
import { languages } from "../../../../dummyData/dummyData";

const { you } = languages.EN.labels;
const ListChatItem = ({
  setSelectedChat,
  setCurrentMessages,
  selectedChat,
  handleGetMessagesAfterSendMessage,
  chat,
  isNextReqAvalible,
}) => {
  const { chatroom, created, msg, read, sender } = chat;
  const { id, name, profile_image } = chatroom;
  const { userData } = useContext(UserContext);
  const date = created.split("-").join("/").slice(0, 10);
  const time = created.slice(11, 16);

  const [isClicked, setIsClicked] = useState(false);
  const handleOnClick = () => {
    if (isNextReqAvalible) {
      setCurrentMessages(null);
      handleGetMessagesAfterSendMessage(id);
      setSelectedChat({
        id: id,
        name: name,
      });
    }
  };

  useEffect(() => {
    if (selectedChat) {
      if (selectedChat.id !== id) {
        setIsClicked(false);
      } else {
        setIsClicked(true);
      }
    }
  }, [selectedChat, id]);

  return (
    <div
      onClick={handleOnClick}
      className={`${styles.Wrapper} ${isClicked && styles.Wrapper__active}`}
    >
      <img className={styles.bigProfilePhoto} src={profile_image} alt="" />
      <div
        className={`${styles.infoArea} ${isClicked && styles.infoArea__active}`}
      >
        <Text s12 dark-4>
          {name}
        </Text>
        {chat.chatroom.num_users > 2 ? (
          <Text s12 dark-4>
            {userData.username === sender.username
              ? `${you}: `
              : `${sender.profile.name} ${sender.profile.surname}: `}
            {msg}
          </Text>
        ) : (
          <Text s12 dark-4>
            {userData.username === sender.username && `${you}: `}
            {msg}
          </Text>
        )}

        <Text s10 dark-4>
          {`${date} ${time}`}
        </Text>
      </div>
      <div className={styles.icon}>
        {userData.username !== sender.username && !read ? (
          Icons.chatIcons.greenDot
        ) : userData.username === sender.username && read ? (
          <img
            className={styles.smallPhoto}
            src={sender.profile.profile_image}
            alt=""
          />
        ) : userData.username === sender.username && !read ? (
          Icons.chatIcons.checkMark
        ) : (
          userData.username !== sender.username && read && null
        )}
      </div>
    </div>
  );
};

export default ListChatItem;
