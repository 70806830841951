import React, { useContext, useEffect, useMemo } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { Switch, Route, Redirect } from "react-router";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import BadgesList from "../../organisms/BadgesList/BadgesList";
import ProfileCourses from "../../organisms/ProfileCourses/ProfileCourses";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath, useHistory } from "react-router-dom";
import RewardEndorsementsList from "../../organisms/RewardEndorsementsList/RewardEndorsementsList";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import CompetenceLevelsList from "../../organisms/CompetenceLevelsList/CompetenceLevelsList";
import { UserContext } from "../../../contexts/UserContext";
import { TYPE_AUTOCOMPLETE, TYPE_DROPDOWN, TYPE_IMAGE } from "../../molecules/Form/Form";
import useFetch from "../../../hooks/useFetch";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import { userRepository } from "../../../repository/user.repository";
import ProfileStatistics from "../../organisms/ProfileStatistics/ProfileStatistics";
import { defaultUserPhoto } from "../../../constants/assets";
import moment from "moment";
import { dateFormat } from "../../../utils/formatters";
import { SCOPE_CHAT, SCOPE_USER_LEARNING_REPORT, SCOPE_WORKGROUP } from "../../../constants/scopes";
import { TYPE_SUBMENU } from "../../molecules/TopBannerButtons/TopBannerButtons";

const personalPopupFields = (data = {}) => [
  {
    name: "email",
    label: languages.EN.labels.email,
    disabled: true,
    value: data.email,
  },
  {
    name: "name",
    label: languages.EN.labels.firstName,
    required: true,
    value: data.name,
  },
  {
    name: "surname",
    label: languages.EN.labels.secondName,
    required: true,
    value: data.surname,
  },
  {
    name: "display_name_type",
    label: languages.EN.labels.displayName,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.display_name_type,
    items: {
      0: `${data.name} ${data.surname.slice(0, 1)}.`,
      1: `${data.name}`,
      2: `${data.name.slice(0, 1)}.${data.surname.slice(0, 1)}.`,
      3: `${data.name} ${data.surname}`,
    },
  },
  {
    name: "phone_number",
    label: languages.EN.labels.phoneNumber,
    value: data.phone_number,
  },
  {
    name: "linkedin_profile",
    label: languages.EN.labels.linkedinLink,
    value: data.linkedin_profile,
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    type: TYPE_IMAGE,
    square: true,
    value: data.profile_image,
  },
];

const workgroupPopupFields = (workgroupItems = [], data = {}) => [
  {
    name: "workgroup",
    label: languages.EN.labels.workgroup,
    required: true,
    type: TYPE_AUTOCOMPLETE,
    items: workgroupItems.map(workgroup => ({
      id: workgroup.id,
      label: workgroup.name,
      photo: workgroup.profile_image_small,
    })),
  },
];

const ProfileTemplate = ({ data, params }) => {
  const { name, surname, email, phone_number, profile_image, user_badges, user_competences, user_rewards, endorsements, active_courses, certificates, job_position, career_path, organization, linkedin_profile, licenses } = data;
  const { username } = data.user;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);
  const history = useHistory();
  const isMine = userData.username === username;
  const expiredLicense = useMemo(() => licenses ? licenses.find(license => moment(license.expiry_date).diff(moment(), "days") < 0) : undefined, []);
  const expiringLicense = useMemo(() => licenses ? licenses.find(license => moment(license.expiry_date).diff(moment(), "days") < 30) : undefined, []);
  const links = [
    {
      content: languages.EN.labels.statistics,
      href: generatePath(Routes.profile.statistics.base, { username: username, as: params.as }),
    },
    {
      content: languages.EN.labels.courses,
      href: generatePath(Routes.profile.courses.base, { username: username, as: params.as }),
      visible: active_courses !== null,
    },
    {
      content: languages.EN.labels.competences,
      href: generatePath(Routes.profile.competences.base, { username: username, as: params.as }),
      visible: user_competences !== null,
    },
    {
      content: languages.EN.labels.badges,
      href: generatePath(Routes.profile.badges.base, { username: username, as: params.as }),
      visible: user_badges !== null,
    },
    {
      content: languages.EN.labels.rewardsAndEndorsements,
      href: generatePath(Routes.profile.rewards.base, { username: username, as: params.as }),
      visible: user_rewards !== null || endorsements !== null,
    },
  ];

  const fetchWorkgroups = useFetch();
  useEffect(() => {
    if (fetchWorkgroups.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: languages.EN.labels.addToWorkgroup,
          fields: workgroupPopupFields(fetchWorkgroups.data),
          postAction: (formData) => workgroupsRepository.assignToWorkgroup({ users: username, ...formData }),
        },
      })
    }
  }, [fetchWorkgroups.isReady]);

  const team = data.teams.length > 0 ? data.teams?.find(team => !team.is_manager) : undefined;
  const withManagement =
    (userData.current.context_type === 2 && userData.org_id === organization.id) ||
    (userData.current.context_type === 1 && userData.team_id === team?.id);

  const displayName = name ? `${name} ${surname}` : email;
  useDocumentTitle(`${displayName} | ${languages.EN.labels.profile}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={displayName}
        path={[
          {
            name: languages.EN.labels.profile,
            link: "#",
          },
        ]}
        img={profile_image ?? defaultUserPhoto}
        imgSquare={true}
        infos={[
          {
            label: languages.EN.labels.organization,
            value: organization.name,
          },
          {
            label: languages.EN.labels.team,
            value: team?.name,
            visible: data.teams.length > 0,
          },
          {
            label: languages.EN.labels.email,
            value: email,
            visible: email !== null,
          },
          {
            label: languages.EN.labels.phoneNumber,
            value: phone_number,
            visible: !!phone_number,
          },
          {
            label: languages.EN.labels.careerPath,
            value: career_path?.name,
            visible: career_path !== null,
          },
          {
            label: languages.EN.labels.jobPosition,
            value: job_position?.name,
            visible: job_position !== null,
          },
        ]}
        buttons={[
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            visible: isMine && params.as === undefined,
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.personalData}`,
                fields: personalPopupFields(data),
                postAction: (formData) => userRepository.editUserProfile(username, formData),
              },
            })
          },
          {
            icon: "lock",
            name: languages.EN.labels.privacySettings,
            visible: isMine && params.as === undefined,
            onClick: () => history.push(Routes.settings.base),
          },
          {
            icon: "linkedin2",
            name: languages.EN.labels.viewLinkedInProfile,
            visible: linkedin_profile !== null,
            onClick: () => { window.open(linkedin_profile); }
          },
          {
            icon: "suitcase",
            name: languages.EN.labels.viewProfession,
            visible: (isMine && params.as === undefined) || withManagement,
            onClick: () => history.push(generatePath(Routes.profession.base, { username: username })),
          },
          {
            icon: "statistics",
            name: languages.EN.labels.viewLearningReport,
            visible: ((isMine && params.as === undefined) || withManagement) && userData.scopes.includes(SCOPE_USER_LEARNING_REPORT),
            onClick: () => history.push(generatePath(Routes.studentLearningReport.base, { username: username })),
          },
          {
            icon: "eye",
            name: languages.EN.labels.viewProfileAs,
            visible: isMine && params.as === undefined,
            onHoverType: TYPE_SUBMENU,
            options: [
              {
                name: languages.EN.labels.anyPerson,
                onClick: () => history.push(generatePath(Routes.profile.statistics.base, { username: username, as: "public" })),
              },
              {
                name: languages.EN.labels.personInMyOrganization,
                onClick: () => history.push(generatePath(Routes.profile.statistics.base, { username: username, as: "organization" })),
              },
              {
                name: languages.EN.labels.personInMyTeam,
                onClick: () => history.push(generatePath(Routes.profile.statistics.base, { username: username, as: "team" })),
              },
            ]
          },
          {
            icon: "eye",
            name: languages.EN.labels.closeViewAs,
            visible: params.as !== undefined,
            onClick: () => history.push(generatePath(Routes.profile.statistics.base, { username: username })),
          },
          {
            icon: "workgroups",
            name: languages.EN.labels.addToWorkgroup,
            visible: !isMine && params.as === undefined && userData.scopes.includes(SCOPE_WORKGROUP),
            onClick: () => {
              setIsDataActive(false);
              fetchWorkgroups.fetchData(workgroupsRepository.getWorkgroups());
              callPopup({ type: ACTIONS.LOADING });
            },
          },
          {
            icon: "message",
            name: languages.EN.labels.sendMessage,
            visible: !isMine && params.as === undefined && userData.scopes.includes(SCOPE_CHAT),
            onClick: () => history.push({ pathname: Routes.chat.base, state: {
                users: [username],
                chatName: `${data.user.name} ${data.user.surname}`,
              }})
          },
        ]}
        alert={expiredLicense || expiringLicense ? {
          text: expiredLicense ?
            `${languages.EN.labels.license} ${expiredLicense.number} ${languages.EN.labels.hasExpired} ${dateFormat(expiredLicense.expiry_date)}` :
            `${languages.EN.labels.license} ${expiringLicense.number} ${languages.EN.labels.willExpire} ${dateFormat(expiringLicense.expiry_date)}`,
          buttonText: userData.current.context_type === 2 && userData.org_id === organization.id ? languages.EN.labels.goToOrganizationLicenses : undefined,
          onClick: () => history.push(Routes.organizationLicenses.base),
        } : undefined}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={generatePath(Routes.profile.base, { username: username, as: params.as })}>
            <Redirect to={generatePath(Routes.profile.statistics.base, { username: username, as: params.as })} />
          </Route>
          <Route path={links[0].href}>
            <ProfileStatistics data={data} />
          </Route>
          <Route path={links[1].href}>
            <FilterContextProvider key={0}>
              <ProfileCourses courses={(certificates ?? []).concat(active_courses)} isMine={isMine} username={username} />
            </FilterContextProvider>
          </Route>
          <Route path={links[2].href}>
            <FilterContextProvider key={1}>
              <CompetenceLevelsList data={user_competences} isMine={isMine} withManagement={withManagement} username={username} />
            </FilterContextProvider>
          </Route>
          <Route path={links[3].href}>
            <FilterContextProvider key={2}>
              <BadgesList data={user_badges} />
            </FilterContextProvider>
          </Route>
          <Route path={links[4].href}>
            <FilterContextProvider key={3}>
              <RewardEndorsementsList id="profile" data={{ rewards: user_rewards, endorsements: endorsements }} withManagement={withManagement} username={!isMine ? username : undefined} />
            </FilterContextProvider>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default ProfileTemplate;
