import CompetenceDetailsTemplate from "../../templates/CompetenceDetailsTemplate/CompetenceDetailsTemplate";
import useFetch from "../../../hooks/useFetch";
import { competencesRepository } from "../../../repository/competences.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const CompetenceDetails = ({ match }) => {
  const { data, fetchData, resetData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(competencesRepository.getCompetenceDetails(match.params.id));
    }
  }, [isResponseOk]);

  // Handle picking search result
  useEffect(() => {
    if (data) {
      resetData();
    }
    fetchData(competencesRepository.getCompetenceDetails(match.params.id));
  }, [match.params.id]);

  return data ? (
    <CompetenceDetailsTemplate data={data} />
  ) : (
    <Loader />
  );
};

export default CompetenceDetails;
