import CompetencesTemplate from "../../templates/CompetencesTemplate/CompetencesTemplate";
import useFetch from "../../../hooks/useFetch";
import { competencesRepository } from "../../../repository/competences.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { PostContext } from "../../../contexts/PostContext";

const Competences = () => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(competencesRepository.getCompetences());
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <CompetencesTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default Competences;
