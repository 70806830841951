import styles from "./PopupInitialTest.module.scss";
import Text from "../../../atoms/Text/Text";
import { ACTIONS } from "../../../../contexts/PopupsContext";
import React, { useEffect, useContext, useState } from "react";
import { PopupsContext } from "../../../../contexts/PopupsContext";
import { competenceTestRepository } from "../../../../repository/competenceTest.repository";
import CompetenceComponent from "../../../molecules/CompetenceComponent/CompetenceComponent";
import { useHistory } from "react-router";
import { Routes } from "../../../../routes";
import { languages } from "../../../../dummyData/dummyData";
import { ReactComponent as AidifyLogo } from "../../../../assets/logoIcon.svg";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";

const PopupInitialTest = ({ competences, onNextStep }) => {
  const { callPopup } = useContext(PopupsContext);
  const [isPostFinish, setIsPostFinish] = useState(false);
  const [ourAnswer, setOurAnswer] = useState([]);

  const handleCheckboxChange = (id) => {
    ourAnswer.includes(id)
      ? setOurAnswer(ourAnswer.filter((item) => item !== id))
      : setOurAnswer([...ourAnswer, id]);
  };

  const handleRunTest = () => {
    competenceTestRepository
      .runManualTest({ competence_levels: Object.values(ourAnswer).join(",") })
      .then((response) => response.json())
      .then((data) => {
        onNextStep(data.initial_test);
      })
      .then(() => {
        setIsPostFinish(true)
      });
  };

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish, callPopup]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.textWrapper}>
        <Text s18 w700 lh22 dark>
          {languages.EN.labels.question}
        </Text>
      </div>
      <Text w400 s12 lh24>
        {languages.EN.messages.verifyTest}
      </Text>
      <div className={styles.Wrapper}>
        {competences && competences.map((item, id) => item.competence_level.competence && item.num_questions > 0 && (
          <label
            className={styles.wrapper}
            htmlFor={id}
            key={item.competence_level.id}
          >
            <input
              type="checkbox"
              name="name"
              id={id}
              checked={ourAnswer.includes(item.competence_level.id)}
              onChange={() =>
                handleCheckboxChange(item.competence_level.id)
              }
            />
            <span className={styles.checkbox}/>
            <CompetenceComponent
              name={item.competence_level.competence.name}
              level={item.competence_level.level}
              question={item.num_questions}
              img={item.competence_level.competence.organization ? item.competence_level.competence.organization.profile_image_small : <AidifyLogo />}
            />
          </label>
        ))}
      </div>
      <SaveBackButtons onSave={handleRunTest} saveLabel={languages.EN.labels.runTest} isError={ourAnswer.length <= 0} onBack={() => callPopup({ type: ACTIONS.REMOVE })} />
    </div>
  );
};

export default PopupInitialTest;
