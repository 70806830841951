import React, { useContext } from "react";
import styles from "./SaveBackButtons.module.scss";
import Button from "../../atoms/Button/Button";
import { PostContext } from "../../../contexts/PostContext";
import { LoaderOnly } from "../../atoms/Loader/Loader";
import { languages } from "../../../dummyData/dummyData";

const SaveBackButtons = ({ onSave, onBack, saveLabel, backLabel, isError }) => {
  const { isPostStart } = useContext(PostContext);

  return (
    <div className={styles.Wrapper}>
      {!isPostStart ? (
        <>
          {onBack && (
            <Button variant={"primaryLightW100"} onClick={onBack}>
              {backLabel ?? languages.EN.labels.cancel}
            </Button>
          )}
          <Button variant={isError ? "primaryDisabled" : "primaryW100"} onClick={isError ? null : onSave}>
            {saveLabel ?? languages.EN.labels.save}
          </Button>
        </>
      ) : (
        <LoaderOnly />
      )}
    </div>
  );
};

export default SaveBackButtons;
