import styles from "./IdpLearningPlanTableEdit.module.scss";
import CustomInput from "../../../atoms/CustomInput/CustomInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { languages } from "../../../../dummyData/dummyData";
import { dateFormat } from "../../../../utils/formatters";
import CalendarInput from "../../../atoms/CalendarInput/CalendarInput";
import { isValidDateString } from "../../../../utils/dateHelpers";
import TableNoDataInformation from "../TableNoDataInformation/TableNoDataInformation";
import React from "react";
import Button from "../../../atoms/Button/Button";

const headers = [
  languages.EN.labels.courseName,
  languages.EN.labels.plannedStart,
  languages.EN.labels.plannedNoOfMinutes,
  languages.EN.labels.recommendedNoOfMinutes,
  languages.EN.labels.plannedNoOfDays,
  languages.EN.labels.plannedEnding,
];

const IdpLeraningPlanTableEdit = ({ data, handleInput, handleDrag, handleDelete }) => {
  return (
    <div className={styles.wrapper}>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <>
              <table className={styles.table} ref={provided.innerRef}{...provided.droppableProps}>
                <thead>
                  <tr className={styles.greenRow}>
                    {headers.map((item, i) => {
                      return (
                        <th key={i} className={styles.tableHeader}>
                          {item}
                        </th>
                      );
                    })}
                    <th style={{ textAlign: "center", maxWidth: "130px" }}>
                      {languages.EN.labels.actions}
                    </th>
                  </tr>
                </thead>
                {data && data.length > 0 && (
                  <tbody>
                    {data.map((item, i) => (
                      <Draggable key={item.id} draggableId={item.id} index={i}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              boxShadow: snapshot.isDragging ? "0 0 .4rem #666" : "none",
                              backgroundColor: "white",
                            }}
                          >
                            <td>{item.courseName}</td>
                            <td style={{ width: "12%" }}>
                              <CalendarInput
                                name={"planStart"}
                                value={data[i].planStart && isValidDateString(data[i].planStart) ? dateFormat(data[i].planStart) : data[i].planStart ?? ""}
                                handleInput={(e) => handleInput(e, i)}
                                noLabel={true}
                              />
                            </td>
                            <td style={{ width: "12%" }}>
                              <CustomInput
                                name={"planMinutes"}
                                value={data[i].planMinutes}
                                onChange={(e) => handleInput(e, i)}
                              />
                            </td>
                            <td style={{ width: "12%" }}>{item.recMinutes}</td>
                            <td style={{ width: "12%" }}>{item.planDays.toFixed(2)}</td>
                            <td style={{ width: "12%" }}>{item.planEnd}</td>
                            <td style={{ textAlign: "center", maxWidth: "130px" }}>
                              <div className={styles.actionBox}>
                                <Button variant="iconButton" onlyIcon icon="garbage" onClick={() => handleDelete(i)}/>
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <tr className={styles.greenRow}>
                      <td>{languages.EN.labels.summary}</td>
                      <td>{data[0]?.planStart ?? languages.EN.placeholders.empty}</td>
                      <td>{data.reduce((acc, curr) => acc + (!!curr.planMinutes ? curr.planMinutes : 0), 0)}</td>
                      <td>{data.reduce((acc, curr) => acc + curr.recMinutes, 0)}</td>
                      <td>{data.reduce((acc, curr) => acc + curr.planDays, 0).toFixed(2)}</td>
                      <td>{data[data.length - 1]?.planEnd ?? languages.EN.placeholders.empty}</td>
                      <td/>
                    </tr>
                  </tbody>
                )}
              </table>
              <TableNoDataInformation data={data} />
            </>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default IdpLeraningPlanTableEdit;
