import findItemValueByPath from "./FilterByPathName";

const isValueMatching = (value, searchValue) => value.toString().toLowerCase().includes(searchValue.toLowerCase());

const filterBySearchValue = (searchValue, filterDefinitions, data) => {
  return data.filter(item => filterDefinitions.filter(filterDefinition => {
    const value = findItemValueByPath(item, filterDefinition.keyPath);

    return !Array.isArray(value) ? isValueMatching(value ?? "", searchValue) : value.filter(subvalue => isValueMatching(subvalue ?? "", searchValue)).length > 0;
  }).length > 0);
};

export default filterBySearchValue;
