import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Files from "./components/pages/Files/Files";
import Marketplace from "./components/pages/Marketplace/Marketplace";
import Courses from "./components/pages/Courses/Courses";
import LearningEvents from "./components/pages/LearningEvents/LearningEvents";
import RegulatoryIntelligence from "./components/pages/RegulatoryIntelligence/RegulatoryIntelligence";
import JobPositions from "./components/pages/JobPositions/JobPositions";
import Competences from "./components/pages/Competences/Competences";
import JobPositionDetails from "./components/pages/JobPositionDetails/JobPositionDetails";
import InstructorDetails from "./components/pages/InstructorDetails/InstructorDetails";
import Profession from "./components/pages/Profession/Profession";
import CompetenceDetails from "./components/pages/CompetenceDetails/CompetenceDetails";
import RegulatoryIntelligenceDetails from "./components/pages/RegulatoryIntelligenceDetails/RegulatoryIntelligenceDetails";
import LearningEventDetails from "./components/pages/LearningEventDetails/LearningEventDetails";
import CompetenceTest from "./components/pages/CompetenceTest/CompetenceTest";
import CompetenceTestReport from "./components/pages/CompetenceTestReport/CompetenceTestReport";
import ReferenceControl from "./components/pages/ReferenceControl/ReferenceControl";
import SignIn from "./components/pages/SignIn/SignIn";
import Registration from "./components/pages/Registration/Registration";
import Homepage from "./components/pages/HomePage/HomePage";
import { Routes } from "./routes";
import Profile from "./components/pages/Profile/Profile";
import Team from "./components/pages/Team/Team";
import Organization from "./components/pages/Organization/Organization";
import { PopupsContextProvider } from "./contexts/PopupsContext";
import { PostContextProvider } from "./contexts/PostContext";
import Workgroups from "./components/pages/Workgroups/Workgroups";
import WorkgroupDetails from "./components/pages/WorkgroupDetails/WorkgroupDetails";
import LearningReport from "./components/pages/LearningReport/LearningReport";
import StudentLearningReport from "./components/pages/StudentLearningReport/StudentLearningReport";
import PlatformDashboard from "./components/pages/PlatformDashboard/PlatformDashboard";
import StudentCompetences from "./components/pages/StudentCompetences/StudentCompetences";
import OrganizationLicenses from "./components/pages/OrganizationLicenses/OrganizationLicenses";
import Notifications from "./components/pages/Notifications/Notifications";
import CareerPaths from "./components/pages/CareerPaths/CareerPaths";
import CareerPathDetails from "./components/pages/CareerPathDetails/CareerPathDetails";
import LicenseControl from "./components/pages/LicenseControl/LicenseControl";
import IdpEdit from "./components/pages/IdpEdit/IdpEdit";
import Search from "./components/pages/SearchResults/SearchResults";
import CourseDetails from "./components/pages/CourseDetails/CourseDetails";
import CourseView from "./components/pages/CourseView/CourseView";
import CourseEdit from "./components/pages/CourseEdit/CourseEdit";
import ChatPage from "./components/pages/ChatPage/ChatPage";
import { Loader } from "./components/atoms/Loader/Loader";
import { UserContext } from "./contexts/UserContext";
import PasswordResetConfirm from "./components/pages/PasswordResetConfirm/PasswordResetConfirm";
import PasswordReset from "./components/pages/PasswordReset/PasswordReset";
import LoggedAndRegistredRoute from "./LoggedAndRegistredRoute";
import NotLoggedRoute from "./NotLoggedRoute";
import LoggedButNotRegistredRoute from "./LoggedButNotRegistredRoute";
import { UserConfigContextProvider } from "./contexts/UserConfigContext";
import Error from "./components/pages/Error/Error";
import ReferenceAudit from "./components/pages/ReferenceAudit/ReferenceAudit";
import Idps from "./components/pages/Idps/Idps";
import IdpDetails from "./components/pages/IdpDetails/IdpDetails";
import { ErrorBoundary } from 'react-error-boundary'
import { reportError } from "./utils/errorHandling";
import RegistrationLanding from "./components/pages/RegistrationLanding/RegistrationLanding";
import PasswordChange from "./components/pages/PasswordChange/PasswordChange";
import HasTokenRoute from "./HasTokenRoute";
import InstructorDashboard from "./components/pages/InstructorDashboard/InstructorDashboard";
import ErrorTemplate from "./components/templates/ErrorTemplate/ErrorTemplate";
import Settings from "./components/pages/Settings/Settings";
import FixedButtons from "./components/molecules/FixedButtons/FixedButtons";
import { ReportProblemContextProvider } from "./contexts/ReportProblemContext";
import CoursePlayer from "./components/pages/CoursePlayer/CoursePlayer";
import ProvidedTokenRoute from "./ProvidedTokenRoute";

const App = () => {
  const { isContextViewReload } = useContext(UserContext);

  return (
    <ErrorBoundary FallbackComponent={ErrorTemplate} onError={reportError}>
      <ReportProblemContextProvider>
        <PostContextProvider>
          <PopupsContextProvider>
            <UserConfigContextProvider>
              <Switch>
                {isContextViewReload && <Loader />}
                <Route
                  path={Routes.error.base}
                  component={Error}
                />
                <Route
                  exact
                  path={Routes.registration.init.base}
                  component={RegistrationLanding}
                />
                <LoggedButNotRegistredRoute
                  path={Routes.registration.base}
                  component={Registration}
                />
                <NotLoggedRoute
                  exact
                  path={Routes.mainPage.base}
                  component={SignIn}
                />
                <NotLoggedRoute
                  exact
                  path={Routes.passwordResetConfirm.base}
                  component={PasswordResetConfirm}
                />
                <NotLoggedRoute
                  exact
                  path={Routes.passwordReset.base}
                  component={PasswordReset}
                />
                <HasTokenRoute
                  path={Routes.passwordChange.base}
                  component={PasswordChange}
                />
                <LoggedAndRegistredRoute
                  path={Routes.homepage.base}
                  component={Homepage}
                />
                <LoggedAndRegistredRoute
                  path={Routes.profile.base}
                  component={Profile}
                />
                <LoggedAndRegistredRoute
                  path={Routes.settings.base}
                  component={Settings}
                />
                <LoggedAndRegistredRoute
                  path={Routes.organizationLicenses.base}
                  component={OrganizationLicenses}
                />
                <LoggedAndRegistredRoute
                  path={Routes.team.base}
                  component={Team}
                />
                <LoggedAndRegistredRoute
                  path={Routes.licenseControl.base}
                  component={LicenseControl}
                />
                <LoggedAndRegistredRoute
                  path={Routes.referenceControl.base}
                  component={ReferenceControl}
                />
                <LoggedAndRegistredRoute
                  path={Routes.referenceAudit.base}
                  component={ReferenceAudit}
                />
                <LoggedAndRegistredRoute
                  path={Routes.chat.base}
                  component={ChatPage}
                />
                <LoggedAndRegistredRoute
                  path={Routes.organization.base}
                  component={Organization}
                />
                <LoggedAndRegistredRoute
                  path={Routes.careerPaths.base}
                  component={CareerPaths}
                />
                <LoggedAndRegistredRoute
                  path={Routes.careerPathDetails.base}
                  component={CareerPathDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.workgroups.base}
                  component={Workgroups}
                />
                <LoggedAndRegistredRoute
                  path={Routes.workgroupDetails.base}
                  component={WorkgroupDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.notifications.base}
                  component={Notifications}
                />
                <LoggedAndRegistredRoute
                  path={Routes.studentLearningReport.base}
                  component={StudentLearningReport}
                />
                <LoggedAndRegistredRoute
                  path={Routes.studentCompetences.base}
                  component={StudentCompetences}
                />
                <LoggedAndRegistredRoute
                  path={Routes.platformDashboard.base}
                  component={PlatformDashboard}
                />
                <LoggedAndRegistredRoute
                  path={Routes.learningReport.base}
                  component={LearningReport}
                />
                <LoggedAndRegistredRoute
                  path={Routes.competenceTest.test.base}
                  component={CompetenceTest}
                />
                <LoggedAndRegistredRoute
                  path={Routes.competenceTest.report.base}
                  component={CompetenceTestReport}
                />
                <LoggedAndRegistredRoute
                  path={Routes.idps.base}
                  component={Idps}
                />
                <LoggedAndRegistredRoute
                  path={Routes.idpDetails.base}
                  component={IdpDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.idpEdit.base}
                  component={IdpEdit}
                />
                <LoggedAndRegistredRoute
                  path={Routes.courseEdit.base}
                  component={CourseEdit}
                />
                <LoggedAndRegistredRoute
                  path={Routes.courseView.base}
                  component={CourseView}
                />
                <LoggedAndRegistredRoute
                  path={Routes.courseDetails.base}
                  component={CourseDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.search.base}
                  component={Search}
                />
                <LoggedAndRegistredRoute
                  path={Routes.profession.base}
                  component={Profession}
                />
                <LoggedAndRegistredRoute
                  path={Routes.files.base}
                  component={Files}
                />
                <LoggedAndRegistredRoute
                  path={Routes.marketplace.base}
                  component={Marketplace}
                />
                <LoggedAndRegistredRoute
                  path={Routes.jobPositions.base}
                  component={JobPositions}
                />
                <LoggedAndRegistredRoute
                  path={Routes.jobPositionDetails.base}
                  component={JobPositionDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.competences.base}
                  component={Competences}
                />
                <LoggedAndRegistredRoute
                  path={Routes.competenceDetails.base}
                  component={CompetenceDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.courses.base}
                  component={Courses}
                />
                <LoggedAndRegistredRoute
                  path={Routes.instructorDetails.base}
                  component={InstructorDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.learningEvents.base}
                  component={LearningEvents}
                />
                <LoggedAndRegistredRoute
                  path={Routes.learningEventDetails.base}
                  component={LearningEventDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.regulatoryIntelligence.base}
                  component={RegulatoryIntelligence}
                />
                <LoggedAndRegistredRoute
                  path={Routes.regulatoryIntelligenceDetails.base}
                  component={RegulatoryIntelligenceDetails}
                />
                <LoggedAndRegistredRoute
                  path={Routes.instructorDashboard.base}
                  component={InstructorDashboard}
                />
                <ProvidedTokenRoute
                  path={Routes.coursePlayer.base}
                  component={CoursePlayer}
                />
                <Route path="*">
                  <Redirect to={Routes.mainPage.base} />
                </Route>
              </Switch>
              <FixedButtons />
            </UserConfigContextProvider>
          </PopupsContextProvider>
        </PostContextProvider>
      </ReportProblemContextProvider>
    </ErrorBoundary>
  );
};

export default App;
