import styles from "./PdfButtons.module.scss";
import Button from "../../atoms/Button/Button";

const PdfButtons = ({ previousPageHandler, nextPageHandler, resizePdfHandler, closeHandler }) => {
  return (
    <div className={closeHandler ? styles.pdfButtonsAdditional : styles.pdfButtons}>
      <Button onClick={() => previousPageHandler(!!closeHandler)} variant="primary" withIcon icon="pdfArrowLeft" useIconsFile />
      <Button onClick={() => nextPageHandler(!!closeHandler)} variant="primary" withIcon icon="pdfArrowRight" useIconsFile />
      {closeHandler && <Button onClick={() => closeHandler()} variant="primary">Close</Button>}
      <Button onClick={() => resizePdfHandler(true, !!closeHandler)} variant="primary" withIcon icon="plus" useIconsFile />
      <Button onClick={() => resizePdfHandler(false, !!closeHandler)} variant="primary" withIcon icon="minus" useIconsFile />
    </div>
  );
};

export default PdfButtons;
