import Text from "../../../atoms/Text/Text";
import Form from "../../Form/Form";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../../contexts/PostContext";

const FormPopup = ({ header, fields, onSave, postAction, variant }) => {
  const { callPopup } = useContext(PopupsContext);
  const { postData, reset, isPostFinish, isResponseOk } = useContext(PostContext);

  useEffect(() => {
    if (isPostFinish && isResponseOk) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish, isResponseOk]);

  return (
    <>
      <Text>{header}</Text>
      <Form
        fields={fields}
        onSave={(formData) => onSave ? onSave(formData) : postData(() => postAction(formData))}
        onBack={() => {
          reset();
          callPopup({ type: ACTIONS.REMOVE })
        }}
        variant={variant}
        autoFocus={true}
      />
    </>
  );
};

export default FormPopup;
