import TileItem from "../TileItem/TileItem";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";

const CareerPathTile = ({ data }) => {
  const { id, name, description, profile_image_small, num_org_members, num_team_members, country, topic, visibility, visibilityImg, active } = data;
  let history = useHistory();

  return (
    <TileItem title={name} subtitle={`${languages.EN.labels.region}: ${country}`} image={profile_image_small} inactive={!active} buttons={[
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.careerPathDetails.statistics.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ]}>
      <TileItemInfo header={languages.EN.labels.learningArea} value={languages.EN.enums.learningAreas[topic]} />
      <TileItemInfo header={languages.EN.labels.description} value={description} fullRow={true} />
      <TileItemInfo header={languages.EN.labels.noOfOrganizationMembers} value={num_org_members} />
      <TileItemInfo header={languages.EN.labels.noOfTeamMembers} value={num_team_members} />
      <TileItemImgInfo header={languages.EN.labels.visibility} value={visibility} image={visibilityImg} />
    </TileItem>
  );
};
export default CareerPathTile;
