import React, { useEffect, useRef, useState } from "react";
import styles from "./NavbarInput.module.scss";
import loupe from "../../../assets/icons/loupe.svg";
import Text from "../Text/Text";
import useFetch from "../../../hooks/useFetch";
import { searchRepository } from "../../../repository/search.repository";
import { Routes } from "../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { languages } from "../../../dummyData/dummyData";

export const searchLink = {
  0: Routes.courseDetails.statistics.base,
  1: Routes.learningEventDetails.base,
  2: Routes.competenceDetails.jobPositions.base,
  3: Routes.jobPositionDetails.keyCompetences.base,
  4: Routes.careerPathDetails.statistics.base,
  5: Routes.instructorDetails.base,
  6: Routes.regulatoryIntelligenceDetails.base,
};

const NavbarInput = ({ isScrollingDown, setInputIsActive }) => {
  const [searchValue, setSearchValue] = useState("");
  const [navBarActive, setNavBarActive] = useState(false);
  const { data, isReady, fetchData } = useFetch();

  const history = useHistory();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue.length > 0) {
        fetchData(searchRepository.getSearch(encodeURIComponent(searchValue)));
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue, fetchData]);

  let navBarInputRef = useRef();

  useEffect(() => {
    if (isScrollingDown === true) {
      setNavBarActive(false);
      setInputIsActive(false);
    }
  }, [isScrollingDown, setInputIsActive]);

  useEffect(() => {
    let handler = (event) => {
      if (!navBarInputRef.current.contains(event.target)) {
        setNavBarActive(false);
        setInputIsActive(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [setInputIsActive]);

  const handleSendMessageOnEnter = (e) => {
    if (
      data &&
      searchValue.length > 0 &&
      e.key === "Enter" &&
      isReady &&
      navBarActive
    ) {
      setNavBarActive(false);
      setInputIsActive(false);
      if (!!data.length)
        history.push({
          pathname: Routes.search.base,
          state: { data },
        });
    }
  };

  useEffect(() => {
    if (!!searchValue.length === false) {
      setInputIsActive(false);
    }
  }, [searchValue, setInputIsActive]);

  const [searchParam] = useState(history.location.search);
  const [locationParam] = useState(history.location.pathname);

  useEffect(() => {
    if (
      searchParam !== history.location.search &&
      history.location.pathname === locationParam
    ) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  return (
    <>
      <label
        className={styles.navBarInputWrapper}
        ref={navBarInputRef}
        onClick={() => {
          setNavBarActive(true);
          searchValue?.length > 0 && setInputIsActive(true);
        }}
      >
        <div className={styles.searchIcon}>
          <img src={loupe} alt="Search Icon" />
        </div>

        <input
          className={styles.navBarInput}
          type="text"
          placeholder={languages.EN.labels.searchForAnything}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setInputIsActive(true);
          }}
          value={searchValue}
          onKeyPress={handleSendMessageOnEnter}
        />
      </label>
      {isReady && searchValue.length >= 1 && (
        <ul className={styles.inputHints} data-active={navBarActive}>
          {!!data.length ? (
            data.slice(0, 6).map((item, i) => (
              <li
                key={i}
                onClick={() => {
                  setNavBarActive(false);
                }}
              >
                <div className={styles.hintItem} onClick={() => history.push(generatePath(searchLink[item.type], { id: item.item_id }))}>
                  <img src={item.profile_image_small} alt="" />
                  <Text s12 w700>
                    {item.name}
                  </Text>
                  <Text s12 light-8>
                    {languages.EN.enums.searchDescription[item.type]}
                  </Text>
                </div>
              </li>
            ))
          ) : (
            <div className={styles.hintWrapper}>
              <Text s12 dark-2>
                {languages.EN.labels.thereAreNoResults}
              </Text>
            </div>
          )}

          <div
            className={styles.allResults}
            onClick={() => {
              if (!!data.length) {
                history.push({
                  pathname: Routes.search.base,
                  state: { data },
                });
              }
            }}
          >
            {!!data.length ? (
              <Text s12 w700 primary>
                {languages.EN.labels.seeAllResults}
              </Text>
            ) : (
              <Text s12 w700 dark-2>
                {languages.EN.labels.seeAllResults}
              </Text>
            )}
          </div>
        </ul>
      )}
    </>
  );
};

export default NavbarInput;
