import React, { useState } from "react";
import Text from "../../atoms/Text/Text";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import SwiperCore, { Navigation } from 'swiper/core';
import styles from "./InstructorCarousel.module.scss";
import InstructorTile from "../../molecules/InstructorTile/InstructorTile";
import SwiperNavigation from "../../molecules/SwiperNavigation/SwiperNavigation";

SwiperCore.use([Navigation]);

const InstructorCarousel = ({ title, instructors }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={styles.instructorSwiperWrapper} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Text uppercase s24 secondary w800>
        {title}
      </Text>
      <Swiper
        className={styles.instructorSwiper}
        slidesPerView={'auto'}
        spaceBetween={40}
        navigation={isHovered ? {
          prevEl: "#instructorSliderPrev",
          nextEl: "#instructorSliderNext",
        } : false}
      >
        {instructors.map(instructor => (
          <SwiperSlide key={instructor.id}>
            <InstructorTile instructor={instructor} />
          </SwiperSlide>
        ))}
        <SwiperNavigation id={"instructorSlider"} visible={isHovered} />
      </Swiper>
    </div>
  );
};

export default InstructorCarousel
