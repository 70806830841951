import styles from "./PickValidOrderForm.module.scss";
import Button from "../../../atoms/Button/Button";
import { useState } from "react";
import CustomInput from "../../../atoms/CustomInput/CustomInput";
import { languages } from "../../../../dummyData/dummyData";
import Text from "../../../atoms/Text/Text";
import { shuffle } from "../../../../utils/arrayHelpers";

const PickValidOrderForm = ({ formData, setFormData, error }) => {
  const [localData, setLocalData] = useState(formData.correct.split("|").map((answer, i) => ({
    id: i,
    answer: answer
  })));

  const addRow = (i) => {
    setLocalData([...localData, { id: i, answer: "" }]);
  };

  const handleAnswerChange = (e, id) => {
    const newLocalData = [...localData].map((data, i) => i !== id ? data : { ...data, answer: e.target.value });

    setFormData({
      ...formData,
      correct: newLocalData.map(data => data.answer).join("|"),
      answers: shuffle(newLocalData.map(data => data.answer)).join("|"),
    });
    setLocalData(newLocalData);
  };

  return (
    <>
      <div className={`${styles.wrapper} ${error ? styles.wrapper__error : ''}`}>
        <span data-active={true} className={`${styles.label} ${styles.label__white} ${error ? styles.label__error : ''}`}>
          {languages.EN.labels.answers}
        </span>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>
                <Text s12 w400 lh24>
                  {languages.EN.labels.order.toUpperCase()}
                </Text>
              </td>
              <td>
                <Text s12 w400 lh24>
                  {languages.EN.labels.answer.toUpperCase()}
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>
            {localData.map((row, i) => (
              <tr key={i}>
                <td>
                  <span className={styles.validOrderNumber}>{i + 1}</span>
                </td>
                <td>
                  <CustomInput
                    value={row.answer}
                    placeholder={languages.EN.labels.provideAnswer}
                    onChange={(e) => handleAnswerChange(e, row.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.errorMsg}>
        <Text s10 lh12>
          {error && error}
        </Text>
      </div>
      <div className={styles.addButton}>
        <Button
          withIcon
          icon='smallPlus'
          variant='primary'
          onClick={() => addRow(localData.length)}
        >
          {languages.EN.labels.add}
        </Button>
      </div>
    </>
  );
};

export default PickValidOrderForm;
