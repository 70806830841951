import styles from "./FillHolesForm.module.scss";
import { languages } from "../../../../dummyData/dummyData";
import Text from "../../../atoms/Text/Text";
import Button from "../../../atoms/Button/Button";
import { useState } from "react";
import { shuffle } from "../../../../utils/arrayHelpers";
import CustomInput from "../../../atoms/CustomInput/CustomInput";

const FillHolesForm = ({ formData, setFormData, showQuestionField, setShowQuestionField, error }) => {
  const questionSplitted = formData.question.split(" ");
  const correctSplitted = formData.correct.split(" ");
  const defaultData = { indexes: [], answers: [] };

  const [localData, setLocalData] = useState({
    indexes: questionSplitted.map((word, i) => word === "___" ? i : null).filter(item => item !== null),
    answers: formData.answers.length > 0 ? formData.answers.split("|") : [],
  });

  const addRow = (i) => {
    setLocalData({
      ...localData,
      answers: [ ...localData.answers, "" ]
    });
  };

  const handleAnswerChange = (e, id) => {
    const newLocalAnswers = localData.answers.map((answer, i) => i !== id ? answer : e.target.value);

    setFormData({
      ...formData,
      answers: shuffle(newLocalAnswers).join("|"),
    });

    setLocalData({
      ...localData,
      answers: newLocalAnswers,
    });
  };

  const handleClickWord = (e, string, i) => {
    let indexes = localData.indexes;
    let answers = localData.answers;

    e.target.classList.toggle(styles.clickedAnswer);

    if (localData.indexes.includes(i)) {
      const removedIndex = indexes.indexOf(i);

      indexes = indexes.filter((id) => id !== i);
      answers = answers.filter((answer, id) => id !== removedIndex);
    } else {
      let tooltip = e.target.getElementsByTagName("div");

      if (tooltip[0]) {
        e.target.removeChild(tooltip[0]);
      }
      indexes.push(i);
      answers.push(string[i]);
    }

    setLocalData({ indexes: indexes, answers: answers });
    setFormData({
      ...formData,
      question: correctSplitted.map((word, i) => indexes.includes(i) ? "___" : word).join(" "),
      correct: correctSplitted.join(" "),
      answers: shuffle(answers).join("|"),
    });
  };

  const createFillHoles = (correctSplitted, i) => {
    return (
      <span
        key={i}
        id={i}
        onClick={(e) => handleClickWord(e, correctSplitted, i)}
        className={`${styles.clickableDiv} ${localData.indexes.includes(i) ? styles.clickedAnswer : ''}`}
      >
        {correctSplitted[i]}
        <div className={styles.tooltip}>
          <Text s12>{languages.EN.labels.clickToCreateAHole}</Text>
        </div>
      </span>
    );
  };

  return (
    <>
      {!showQuestionField && formData.correct.length > 0 && (
        <div className={styles.fillHolesWrapper}>
          <div className={styles.fillHolesPlaceholder} id="questionBlock">
            {correctSplitted.map((val, i, arr) => createFillHoles(arr, i))}
          </div>
          <Button
            variant="primary"
            withIcon
            icon="edit"
            onClick={() => {
              setLocalData(defaultData)
              setFormData({ ...formData, answers: "" });
              setShowQuestionField(true);
            }}
          >
            {languages.EN.labels.editText}
          </Button>
        </div>
      )}
      <div className={error ? `${styles.wrapper} ${styles.wrapper__error}` : styles.wrapper}>
        <span data-active={true} className={`${styles.label} ${styles.label__white} ${error ? styles.label__error : ''}`}>
          {languages.EN.labels.answers}
        </span>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>
                <Text s12 w400 lh24>
                  {languages.EN.labels.answers}
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>
            {localData.answers.length > 0 ? (
              localData.answers.map((answer, i) => (
                <tr key={i}>
                  <td>
                    {localData.indexes.map(index => correctSplitted[index]).includes(answer) ? (
                      <Text s12 w400 lh24>{answer}</Text>
                    ) : (
                      <CustomInput
                        value={answer}
                        placeholder={languages.EN.labels.provideAnswer}
                        onChange={(e) => handleAnswerChange(e, i)}
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles.noAnswers}>
                <td>
                  <Text s14 w400 lh24>
                    {languages.EN.labels.typeInQuestionAndSelect}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.errorMsg}>
        <Text s10 lh12>
          {error && error}
        </Text>
      </div>
      <div className={styles.addButton}>
        <Button
          withIcon
          icon='smallPlus'
          variant='primary'
          onClick={() => addRow(localData.length)}
        >
          {languages.EN.labels.add}
        </Button>
      </div>
    </>
  );
};

export default FillHolesForm;
