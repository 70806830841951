import React, { useContext, useEffect, useState } from "react";
import styles from "./CourseDetailsTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Routes } from "../../../routes";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { defaultUserPhoto } from "../../../constants/assets";
import { generatePath, Route, Switch, useHistory } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import InfoPage from "../../organisms/InfoPage/InfoPage";
import Footer from "../../organisms/Footer/Footer";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { coursesRepository } from "../../../repository/courses.repository";
import { UserContext } from "../../../contexts/UserContext";
import Text from "../../atoms/Text/Text";
import Level from "../../atoms/Level/Level";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import Rating from "../../molecules/Rating/Rating";
import { dateFormat } from "../../../utils/formatters";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import JobPositionsList from "../../organisms/JobPositionsList/JobPositionsList";
import CareerPathsList from "../../organisms/CareerPathsList/CareerPathsList";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";
import Reviews from "../../organisms/Reviews/Reviews";
import { TYPE_DATE, TYPE_STARPICKER, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import CourseAuditsList from "../../organisms/CourseAuditsList/CourseAuditsList";
import Html from "../../atoms/Html/Html";
import CourseDetailsStatistics from "../../organisms/CourseDetailsStatistics/CourseDetailsStatistics";
import { SCOPE_COMPETENCE_FRAMEWORK_EDIT, SCOPE_LEARNING } from "../../../constants/scopes";
import { organizationRepository } from "../../../repository/organization.repository";
import useFetch from "../../../hooks/useFetch";
import LinkedinButton from "../../atoms/LinkedinButton/LinkedinButton";
import { useCourseButtons } from "../../../hooks/useCourseButtons";

const reviewPopupFields = (data = {}) => [
  {
    name: "rating",
    label: languages.EN.labels.rating,
    required: true,
    type: TYPE_STARPICKER,
    value: data.rating,
  },
  {
    name: "review",
    label: languages.EN.labels.review,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.review,
  },
];

const deadlinePopupFields = (data = {}) => [
  {
    name: "deadline",
    label: languages.EN.labels.deadlineDate,
    required: true,
    type: TYPE_DATE,
  },
];

const assignPopupFilterDefinitions = [
  {
    keyPath: ["user.profile.name", "user.profile.surname", "user.email"],
  },
  {
    keyPath: "user.profile.user_career_path.name",
    header: languages.EN.labels.careerPath,
    typeOfFilter: "checkbox",
  },
];

const PopupRenderItem = ({ item }) => {
  const user = item.user;
  const profile = user.profile;

  return (
    <Text s12 dark-4>
      {profile.name ? `${profile.name} ${profile.surname} (${user.email})` : user.email}
    </Text>
  );
}

const CourseDetailsTemplate = ({ data }) => {
  const { course, course_rating, course_reviews, course_about } = data;
  const [isHeartClicked, setIsHeartClicked] = useState(course.user_saved);
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { userContextLevel, userData } = useContext(UserContext);
  const { icon, label, onClick } = useCourseButtons(course);

  const links = [
    {
      content: languages.EN.labels.statistics,
      href: generatePath(Routes.courseDetails.statistics.base, { id: course.id }),
    },
    {
      content: languages.EN.labels.courseReviews,
      href: generatePath(Routes.courseDetails.reviews.base, { id: course.id }),
    },
    {
      content: languages.EN.labels.relatedJobPositions,
      href: generatePath(Routes.courseDetails.jobPositions.base, { id: course.id }),
    },
    {
      content: languages.EN.labels.relatedCareerPaths,
      href: generatePath(Routes.courseDetails.careerPaths.base, { id: course.id }),
    },
  ].concat(userContextLevel === 3 ? [
    {
      content: languages.EN.labels.courseAudits,
      href: generatePath(Routes.courseDetails.audits.base, { id: course.id }),
    },
  ] : []);

  const fetchOrganizationMembers = useFetch();
  useEffect(() => {
    if (fetchOrganizationMembers.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: languages.EN.labels.selectUser,
          items: fetchOrganizationMembers.data.filter(item => userContextLevel === 2 || item.teams.filter(team => !team.is_manager && team.id === userData.team_id).length === 1).map(item => ({
            ...item,
            id: item.user.username,
            label: item.user.profile.name ? `${item.user.profile.name} ${item.user.profile.surname} (${item.user.email})` : item.user.email,
          })),
          RenderItem: PopupRenderItem,
          filters: assignPopupFilterDefinitions,
          singleSelect: false,
          onSave: (formData) => {
            callPopup({ type: ACTIONS.REMOVE });
            callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: languages.EN.labels.setDeadline,
                fields: deadlinePopupFields(),
                postAction: (deadlineFormData) => coursesRepository.assignToUsers({ courses: course.id, users: formData.join(","), ...deadlineFormData })
              }
            });
          },
        },
      });
    }
  }, [fetchOrganizationMembers.isReady]);

  const instructors = course.course_instructor.length > 0 ? course.course_instructor.map(instructor => ({
    id: instructor.id,
    image: instructor.profile_image_small,
    value: `${instructor.name}<br />${instructor.position}`,
    linkedinUrl: instructor.linkedin_url ?? undefined,
  })) : [
    {
      image: defaultUserPhoto,
      value: course.instructor_text,
    }
  ];
  const myReview = course_reviews.reduce((acc, curr) => curr.user.username === userData.username ? curr : acc, undefined);

  let history = useHistory();

  useDocumentTitle(`${course.display_name} | ${languages.EN.labels.courseDetails}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={course.display_name}
        path={[
          {
            name: languages.EN.labels.courses,
            link: Routes.courses.base,
          },
          {
            name: languages.EN.labels.courseDetails,
            link: "#",
          },
        ]}
        infos={[
          {
            label: languages.EN.labels.averageRating,
            value: <Rating rating={course_rating} reviews={course_reviews.length} />,
          },
          {
            label: languages.EN.labels.version,
            value: course.course_version,
          },
        ]}
        buttons={[].concat(userData.scopes.includes(SCOPE_LEARNING) && course.active ? [
          {
            icon: "heart",
            name: isHeartClicked ? languages.EN.labels.unlike : languages.EN.labels.like,
            marked: isHeartClicked,
            onClick: () => {
              setIsHeartClicked(!isHeartClicked);
              coursesRepository.sendLike({ course_id: course.id });
            },
          },
        ] : []).concat([1, 2].includes(userContextLevel) && course.active ? [
          {
            icon: "plus",
            name: languages.EN.labels.assignUsers,
            onClick: () => {
              setIsDataActive(false);
              fetchOrganizationMembers.fetchData(organizationRepository.getMyOrganizationMembers());
              callPopup({ type: ACTIONS.LOADING })
            }
          },
        ] : []).concat(course.active ? [
          {
            icon: "share",
            name: languages.EN.labels.shareWith,
            onClick: () => {
              callPopup({
                type: ACTIONS.SEARCH_BACKEND_USERS,
                payload: {
                  header: languages.EN.labels.shareWith,
                  singleSelect: false,
                  excludeMyself: true,
                  postAction: (formData) => coursesRepository.shareCourse({ courses: course.id, users: formData }),
                },
              });
            },
          },
        ]: []).concat(userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT) && (
          (userContextLevel === 3 && course.course_organization === null) ||
          (userContextLevel === 2 && userData.org_id === course.course_organization?.id)
        ) ? [
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            onClick: () => history.push(generatePath(Routes.courseEdit.conspect.base, { id: course.id })),
          },
        ] : [])}
      />
      <MainTemplate padBottom0>
        <InfoPage
          image={course.profile_image_medium}
          preview={course.video_preview}
          inactive={!course.active}
          columnHeader={`${languages.EN.labels.released}: ${dateFormat(course.enrollment_start)}`}
          columnItems={[
            { header: languages.EN.labels.learningArea, value: languages.EN.enums.learningAreas[course.topic] },
            { header: languages.EN.labels.duration, value: `${Math.round(course.duration / 60)} ${languages.EN.labels.minutes}` },
            { header: languages.EN.labels.experienceLevel, value: languages.EN.enums.experienceLevels[course.level] },
            { header: languages.EN.labels.language, value: languages.EN.enums.languages[course.language] },
          ]}
          columnFooter={instructors.map((instructor, i) => ({
            header: instructors.length > 1 ? (i === 0 ? languages.EN.labels.instructors : null) : languages.EN.labels.instructor,
            image: instructor.image,
            value: instructor.value,
            buttons: []
              .concat(instructor.linkedinUrl ? [(<LinkedinButton key={"linkedin"} url={instructor.linkedinUrl} />)] : [])
              .concat(instructor.id ? [(
                <Button
                  variant="iconButton"
                  onlyIcon
                  icon={"dots"}
                  key={"dots"}
                  onClick={() => history.push(generatePath(Routes.instructorDetails.base, { id: instructor.id }))}
                  tooltip={languages.EN.labels.details}
                />)] : [])
          }))}
          mainFooter={[]
            .concat(course.competences.length > 0 ? [
              {
                header: languages.EN.labels.competencesCovered, list: course.competences.map((competence_level) => (
                  <>
                    <Text s12 lh24 dark-6>{competence_level.competence.name}</Text>
                    <Level level={competence_level.level} small withoutNumber />
                    <RoundImage image={competence_level.competence.type === 2 ? competence_level.competence.organization.profile_image_small : <AidifyLogo />} size={20} />
                  </>
                ))
              },
              {
                header: languages.EN.labels.byAttendingThisCourse,
                list: course.competences.reduce((acc, curr) => acc.concat(curr.learning_outcomes.split("|")), []).map((outcome) => (
                  <Text s12 lh24 dark-6>{outcome}</Text>
                ))
              },
            ] : [])
          }
        >
          <HeaderWithButtons header={languages.EN.labels.aboutCourse} buttons={[].concat(userContextLevel !== 0 ? [
            { label: languages.EN.labels.startCourse, variant: "primaryDisabled", disabled: true, tooltip: languages.EN.messages.enrollOnlyForStudent },
          ] : !course.active && course.user_progress === 0  ? [
            { label: languages.EN.labels.startCourse, variant: "primaryDisabled", disabled: true, tooltip: languages.EN.messages.enrollToActive },
          ] : icon === 'cart' ? [
            { label: languages.EN.labels.startCourse, variant: "primaryDisabled", disabled: true, tooltip: languages.EN.messages.enrollWithLicence },
            { icon: icon, label: label, onClick: onClick },
          ] : [
            { icon: icon, label: label, onClick: onClick },
          ])} />
          <Html s12 lh24 dark-6>
            {course_about ? course_about : course.short_description}
          </Html>
        </InfoPage>
        <SwitchMenu links={links} />
        <TabContentTemplate>
          <Switch>
            <Route exact path={links[0].href}>
              <CourseDetailsStatistics data={data} />
            </Route>
            <Route path={links[1].href}>
              <div className={styles.courseDetails__box}>
                <HeaderWithButtons header={languages.EN.labels.courseReviews} buttons={myReview !== undefined ? [
                  {
                    icon: "edit",
                    label: languages.EN.labels.edit,
                    onClick: () => callPopup({
                      type: ACTIONS.FORM,
                      payload: {
                        header: `${languages.EN.labels.edit} ${languages.EN.labels.courseReview}`,
                        fields: reviewPopupFields(myReview),
                        postAction: (formData) => coursesRepository.addReview(course.id, formData),
                      },
                    }),
                  },
                  {
                    icon: "garbage",
                    label: languages.EN.labels.delete,
                    onClick: () => callPopup({
                      type: ACTIONS.DELETE,
                      payload: {
                        type: languages.EN.labels.courseReview,
                        elementName: course.display_name,
                        action: () => coursesRepository.deleteReview(course.id),
                      },
                    }),
                  },
                ] : [
                  {
                    icon: "plus",
                    label: languages.EN.labels.add,
                    onClick: () => callPopup({
                      type: ACTIONS.FORM,
                      payload: {
                        header: `${languages.EN.labels.add} ${languages.EN.labels.courseReview}`,
                        fields: reviewPopupFields(),
                        postAction: (formData) => coursesRepository.addReview(course.id, formData),
                      },
                    }),
                  },
                ]} />
                <Reviews reviews={course_reviews} />
              </div>
            </Route>
            <Route path={links[2].href}>
              <FilterContextProvider key={0}>
                <JobPositionsList data={data.related_job_positions} />
              </FilterContextProvider>
            </Route>
            <Route path={links[3].href}>
              <FilterContextProvider key={1}>
                <CareerPathsList data={data.related_career_paths} />
              </FilterContextProvider>
            </Route>
            {userContextLevel === 3 && <Route path={links[4].href}>
              <FilterContextProvider key={2}>
                <CourseAuditsList data={data.audit_trails} course={course} />
              </FilterContextProvider>
            </Route>}
          </Switch>
        </TabContentTemplate>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CourseDetailsTemplate;
