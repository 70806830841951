import { profession_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader } from "./repositoryHelper";

const getProfession = (username) => {
  return fetch(`${profession_endpoint + username}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const professionRepository = {
  getProfession,
};
