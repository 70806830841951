import { job_position_competence_endpoint, job_positions_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getJobPositions = () => {
  return fetch(job_positions_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addJobPosition = (data) => {
  return fetch(job_positions_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
}

const editJobPosition = (id, data) => {
  return fetch(`${job_positions_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
}

const getJobPositionDetails = (id) => {
  return fetch(`${job_positions_endpoint + id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addJobPositionCompetences = (data) => {
  return fetch(job_position_competence_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
}

const deleteJobPositionCompetence = (id) => {
  return fetch(`${job_position_competence_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
}

export const jobPositionsRepository = {
  getJobPositions,
  addJobPosition,
  editJobPosition,
  getJobPositionDetails,
  addJobPositionCompetences,
  deleteJobPositionCompetence,
};
