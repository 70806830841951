import React, { useContext, useEffect, useState } from "react";
import Certificate from "../Certificate/Certificate";
import CertificateTile from "../CertificateTile/CertificateTile";
import { languages } from "../../../dummyData/dummyData";
import { certificatesRepository } from "../../../repository/certificates.repository";
import FilteredList from "../FilteredList/FilteredList";
import { dateFormat } from "../../../utils/formatters";
import { FilterContext } from "../../../contexts/FilterContext";

const filterDefinitions = [
  {
    keyPath: "prepared_course_topic",
    typeOfFilter: "checkbox",
    header: languages.EN.labels.learningArea,
  },
  {
    keyPath: "course_title",
    header: languages.EN.labels.title,
    sortId: 1,
  },
  {
    keyPath: ["user.profile.name", "user.profile.surname"],
    header: languages.EN.labels.user,
    sortId: 2,
  },
  {
    keyPath: "user.profile.user_job_position.name",
    header: languages.EN.labels.jobPosition,
    sortId: 3,
  },
  {
    keyPath: "prepared_teams",
    header: languages.EN.labels.team,
    sortId: 4,
  },
  {
    keyPath: "prepared_date",
    typeOfFilter: "datePicker",
    header: languages.EN.labels.obtained,
    sortId: 5,
  },
];

const CertificateWithoutTeam = ({ data }) => (
  <Certificate data={data} skipTeam={true} />
);

const CertificateTileWithoutTeam = ({ data }) => (
  <CertificateTile data={data} skipTeam={true} />
);

const CertificatesList = ({ id, data, skipTeam }) => {
  const { useSelectables, useProcessedData } = useContext(FilterContext);
  const { selected, setSelected } = useSelectables;
  const { processedData } = useProcessedData;

  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => ({
    ...item,
    isChecked: false,
    selectableId: `${item.user.username},${item.course_id}`,
    prepared_course_topic: languages.EN.enums.learningAreas[item.course_topic],
    prepared_teams: item.user_teams.filter(team => !team.isManager).map(team => team.name),
    prepared_date: dateFormat(item.created),
  }))), [data]);

  return (
    <FilteredList
      id={`certificates${id[0].toUpperCase() + id.slice(1)}`}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={skipTeam ? CertificateWithoutTeam : Certificate}
      RenderTileComponent={skipTeam ? CertificateTileWithoutTeam : CertificateTile}
      buttons={[
        {
          icon: "download",
          label: languages.EN.labels.download,
          forSelectedOnly: true,
          onClick: (selectedTable) => certificatesRepository.downloadCertificates(selectedTable.map((item) => item.selectableId).join(";")),
        },
        {
          icon: "checkMark",
          label: languages.EN.labels.selectAll,
          onClick: () => setSelected(selected.length < processedData.length ? processedData.map(item => item.selectableId) : []),
        },
      ]}
    />
  );
};

export default CertificatesList;
