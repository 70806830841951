import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import CompetencesItem from "../../organisms/CompetencesItem/CompetencesItem";
import CompetencesItemTile from "../../organisms/CompetencesItemTile/CompetencesItemTile";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { UserContext } from "../../../contexts/UserContext";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { competencesRepository } from "../../../repository/competences.repository";
import { TYPE_AUTOCOMPLETE, TYPE_DROPDOWN, TYPE_IMAGE } from "../../molecules/Form/Form";
import useFetch from "../../../hooks/useFetch";
import { SCOPE_COMPETENCE_FRAMEWORK_EDIT } from "../../../constants/scopes";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "careerPaths",
    header: languages.EN.labels.careerPaths,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    sortId: 8,
  },
  {
    keyPath: "group.name",
    header: languages.EN.labels.group,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
  {
    keyPath: "country",
    header: languages.EN.labels.region,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "num_org_members",
    header: languages.EN.labels.noOfOrganizationMembers,
    typeOfFilter: "range",
    sortId: 6,
  },
  {
    keyPath: "num_team_members",
    header: languages.EN.labels.noOfTeamMembers,
    typeOfFilter: "range",
    sortId: 7,
  },
  {
    keyPath: "isActive",
    header: languages.EN.labels.isActive,
    typeOfFilter: "checkbox",
    defaultValues: [languages.EN.enums.yesNo[1]],
  },
];

export const popupFields = (groupItems = [], data = {}) => {
  return [
    {
      name: "name",
      label: languages.EN.labels.name,
      required: true,
      value: data.name,
    },
    {
      name: "topic",
      label: languages.EN.labels.learningArea,
      required: true,
      type: TYPE_DROPDOWN,
      value: data.topic,
      items: languages.EN.enums.learningAreas,
    },
    {
      name: "group_id",
      label: languages.EN.labels.group,
      required: true,
      type: TYPE_AUTOCOMPLETE,
      value: data.group?.id,
      items: groupItems.map((group) => ({ id: group.id, label: group.name })),
    },
    {
      name: "country",
      label: languages.EN.labels.region,
      required: true,
      value: data.country,
    },
    {
      name: "profile_image",
      label: languages.EN.labels.image,
      required: true,
      type: TYPE_IMAGE,
      value: data.profile_image,
    },
  ]
};

const CompetencesTemplate = ({ data }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.organization !== null ? 2 : 3;

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.topic],
      careerPaths: item.career_paths.map((career_path) => career_path.name),
      jobPositions: item.job_positions.map((job_position) => job_position.name),
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.organization.profile_image_small : <AidifyLogo />,
      isActive: languages.EN.enums.yesNo[item.active ? 1 : 0],
      canEdit: userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT) && (
        (type === 2 && userContextLevel === 2) ||
        (type === 3 && userContextLevel === 3)
      ),
    };
  })), [data]);

  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { userContextLevel, userData } = useContext(UserContext);
  const pageHeader = languages.EN.labels.competences;

  useDocumentTitle(pageHeader);

  const fetchGroups = useFetch();
  useEffect(() => {
    if (fetchGroups.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.competence}`,
          fields: popupFields(fetchGroups.data),
          postAction: competencesRepository.addCompetence,
        },
      });
    }
  }, [fetchGroups.isReady]);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <FilteredList
          id="competences"
          data={preparedData}
          filterDefinitions={filterDefinitions}
          RenderListComponent={CompetencesItem}
          RenderTileComponent={CompetencesItemTile}
          buttons={[2, 3].includes(userContextLevel) && userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT) ? [
            {
              icon: "plus",
              label: languages.EN.labels.add,
              onClick: () => {
                setIsDataActive(false);
                fetchGroups.fetchData(competencesRepository.getCompetenceGroups());
                callPopup({ type: ACTIONS.LOADING });
              }
            },
          ] : []}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CompetencesTemplate;
