import React, { useContext, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import { Routes } from "./routes";
import { Loader } from "./components/atoms/Loader/Loader";

const LoggedAndRegistredRoute = ({ component: Component, ...rest }) => {
  const [isReady, setIsReady] = useState(false);
  const { getUserContext, userData, hasToken } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!hasToken()) {
      history.push(Routes.mainPage.base);
    } else if (!userData) {
      getUserContext();
    } else if (userData?.registration_status < 3) {
      history.push(Routes.registration.base)
    } else {
      setIsReady(true);
    }
  }, [hasToken, userData]);

  return isReady ? (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  ) : (
    <Loader />
  );
};

export default LoggedAndRegistredRoute;
