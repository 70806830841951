import styles from "./SortPanel.module.scss";
import HeaderSortPanel from "./HeaderSortPanel";

const SortPanel = ({ items, isGrid }) => {
  return (
    <div data-isgrid={isGrid} className={styles.sortPanel}>
      {!isGrid && <div className={styles.box}/>}
      {items?.map((item, i) => (
        <HeaderSortPanel key={i} item={item} />
      ))}
    </div>
  );
};

export default SortPanel;
