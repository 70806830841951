import styles from "./Rating.module.scss";
import svg from "../../../assets/icons";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";

const Rating = ({ rating, reviews }) => {
  let socialStars = [];
  for (let i = 0; i < 5; i++) {
    socialStars.push(
      <span className={styles.rating__starIcon} key={i}>
        {i + 0.5 <= rating ? svg.avgRatingFill : svg.avgRatingUnfill}
      </span>
    );
  }

  return (
    <div className={styles.rating}>
      <Text s12 lh24 w300 light>
        {rating.toFixed(2)}
      </Text>
      <div className={styles.rating__item}>
        {socialStars.map((item) => item)}
      </div>
      <Text s12 lh24 w300 light>
        {`(${reviews} ${languages.EN.labels.reviews})`}
      </Text>
    </div>
  );
};

export default Rating;
