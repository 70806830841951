import useFetch from "../../../hooks/useFetch";
import { instructorRepository } from "../../../repository/instructor.repository";
import InstructorDetailsTemplate from "../../templates/InstructorDetailsTemplate/InstructorDetailsTemplate";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const InstructorDetails = ({ match }) => {
  const { data, fetchData, resetData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(instructorRepository.getInstructor(match.params.id));
    }
  }, [isResponseOk]);

  // Handle picking search result
  useEffect(() => {
    if (data) {
      resetData();
    }
    fetchData(instructorRepository.getInstructor(match.params.id));
  }, [match.params.id]);

  return data ? (
    <InstructorDetailsTemplate data={data} />
  ) : (
    <Loader />
  );
};

export default InstructorDetails;
