import React, { useEffect } from "react";
import styles from "./GlobalFilter.module.scss";
import loupe from "../../../assets/icons/loupe.svg";
import { languages } from "../../../dummyData/dummyData";

const { searchForAnything } = languages.EN.labels;

const GlobalFilter = ({ filter, setFilter, initialValue }) => {
  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  return (
    <div className={styles.searchInputWrapper}>
      <div className={styles.searchIcon}>
        <img src={loupe} alt="Search Icon" />
      </div>

      <input
        className={styles.searchInput}
        type="text"
        placeholder={searchForAnything}
        value={filter || ""}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
    </div>
  );
};

export default GlobalFilter;
