import styles from "./CompetenceDetailsTemplate.module.scss";
import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import { generatePath } from "react-router-dom";
import { Route, Switch } from "react-router";
import JobPositionsList from "../../organisms/JobPositionsList/JobPositionsList";
import Text from "../../atoms/Text/Text";
import TextWithHeader from "../../molecules/TextWithHeader/TextWithHeader";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import GoalLevelSlider from "../../atoms/GoalLevelSlider/GoalLevelSlider";
import CourseZoomTile from "../../organisms/CourseZoomTile/CourseZoomTile";
import useFetch from "../../../hooks/useFetch";
import { TYPE_TEXTAREA } from "../../molecules/Form/Form";
import Button from "../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { competencesRepository } from "../../../repository/competences.repository";
import { UserContext } from "../../../contexts/UserContext";
import { popupFields as competencePopupFields } from "../CompetencesTemplate/CompetencesTemplate";
import { SCOPE_COMPETENCE_FRAMEWORK_EDIT } from "../../../constants/scopes";

export const popupFields = (data = {}) => [
  {
    name: "name",
    label: `${languages.EN.labels.competence} ${languages.EN.labels.name.toLowerCase()}`,
    disabled: true,
    value: data.name,
  },
  {
    name: "level",
    label: languages.EN.labels.level,
    disabled: true,
    value: data.level,
  },
  {
    name: "learning_outcomes",
    label: languages.EN.labels.learningOutcomes,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.learning_outcomes,
  },
  {
    name: "other_information",
    label: languages.EN.labels.otherInformation,
    type: TYPE_TEXTAREA,
    value: data.other_information,
  },
];

const CompetenceDetailsTemplate = ({ data }) => {
  const { id, name, group, country, organization, topic, competence_levels } = data.competence;
  const links = [
    {
      content: languages.EN.labels.relatedJobPositions,
      href: generatePath(Routes.competenceDetails.jobPositions.base, { id: id }),
    },
  ];

  const [selectedLevel, setSelectedLevel] = useState(parseInt(Object.keys(competence_levels)[0]));
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { userContextLevel, userData } = useContext(UserContext);

  useDocumentTitle(`${name} | ${languages.EN.labels.competenceDetails}`);

  const fetchGroups = useFetch();
  useEffect(() => {
    if (fetchGroups.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.competence}`,
          fields: competencePopupFields(fetchGroups.data, data.competence),
          postAction: (formData) => competencesRepository.editCompetence(id, formData),
        },
      });
    }
  }, [fetchGroups.isReady]);

  const [relatedJobPositions, setRelatedJobPositions] = useState(data.job_positions.filter((jobPosition => jobPosition.competence_level.level === selectedLevel)));
  useEffect(() => {
    setRelatedJobPositions(data.job_positions.filter(jobPosition => jobPosition.competence_level.level === selectedLevel));
  }, [selectedLevel])

  const getLastLevel = () => competence_levels[Object.keys(competence_levels)[Object.keys(competence_levels).length - 1]].level;

  return (
    <section>
      <NavBar />
      <TopBanner
        header={name}
        path={[
          {
            name: languages.EN.labels.competences,
            link: Routes.competences.base,
          },
          {
            name: languages.EN.labels.competenceDetails,
            link: "#",
          },
        ]}
        infos={[
          {
            label: languages.EN.labels.learningArea,
            value: languages.EN.enums.learningAreas[topic],
          },
          {
            label: languages.EN.labels.group,
            value: group.name,
          },
          {
            label: languages.EN.labels.region,
            value: country,
          },
          {
            label: languages.EN.labels.visibility,
            value: languages.EN.enums.visibility[organization !== null ? 2 : 3],
          },
        ]}
        buttons={[
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            visible: userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT) && (
              (userContextLevel === 2 && organization !== null) ||
              (userContextLevel === 3 && organization === null)
            ),
            onClick: () => {
              setIsDataActive(false);
              fetchGroups.fetchData(competencesRepository.getCompetenceGroups());
              callPopup({ type: ACTIONS.LOADING });
            }
          },
        ]}
      />
      <MainTemplate padTop90 padBottom0>
        <div className={styles.competenceDetails}>
          <div className={styles.competenceDetails__box}>
            <Text s14 lh18 w800 secondary>
              {`${languages.EN.labels.level} ${competence_levels[selectedLevel].level}`}
            </Text>
            <div className={styles.competenceDetails__box__sliderWrapper}>
              <div className={styles.competenceDetails__box__sliderWrapper__slider}>
                <GoalLevelSlider
                  level={competence_levels[selectedLevel].level}
                  competenceLevels={competence_levels}
                  onChange={setSelectedLevel}
                />
              </div>
              {organization !== null && [2, 3].includes(userContextLevel) && (<>
                {Object.keys(competence_levels).length < 3 && (
                  <Button
                    variant="iconButton"
                    onlyIcon
                    icon="plus"
                    onClick={() => {
                      callPopup({
                        type: ACTIONS.FORM,
                        payload: {
                          header: `${languages.EN.labels.add} ${languages.EN.labels.competenceLevel}`,
                          fields: popupFields({ name: data.competence.name, level: getLastLevel() + 1 }),
                          postAction: (formData) => competencesRepository.addCompetenceLevel({ competence_id: id, ...formData })
                        },
                      });
                    }}
                    tooltip={languages.EN.labels.add}
                  />
                )}
                <Button
                  variant="iconButton"
                  onlyIcon
                  icon="edit"
                  onClick={() => {
                    callPopup({
                      type: ACTIONS.FORM,
                      payload: {
                        header: `${languages.EN.labels.edit} ${languages.EN.labels.competenceLevel}`,
                        fields: popupFields({ name: data.competence.name, ...competence_levels[selectedLevel] }),
                        postAction: (formData) => competencesRepository.editCompetenceLevel(competence_levels[selectedLevel].id, { competence_id: id, ...formData })
                      },
                    });
                  }}
                  tooltip={languages.EN.labels.edit}
                />
                {Object.keys(competence_levels).length > 1 && getLastLevel() === selectedLevel && (
                  <Button
                    variant="iconButton"
                    onlyIcon
                    icon="garbage"
                    onClick={() => {
                      callPopup({
                        type: ACTIONS.DELETE,
                        payload: {
                          type: languages.EN.labels.competenceLevel,
                          elementName: selectedLevel,
                          action: () => {
                            setSelectedLevel(selectedLevel - 1);
                            return competencesRepository.deleteCompetenceLevel(competence_levels[selectedLevel].id)
                          },
                        },
                      })
                    }}
                    tooltip={languages.EN.labels.delete}
                  />
                )}
              </>)}
            </div>
            <TextWithHeader header={languages.EN.labels.learningOutcomes} text={(
              <Text s12 lh24 dark-6>
                <ul>
                  {competence_levels[selectedLevel].learning_outcomes.split("|").map((outcome, i) => <li key={i}>{outcome}</li>)}
                </ul>
              </Text>
            )} />
            {competence_levels[selectedLevel].other_information && (
              <TextWithHeader header={languages.EN.labels.otherInformation} text={competence_levels[selectedLevel].other_information} />
            )}
          </div>
          {data.courses.length > 0 && (
            <div className={styles.competenceDetails__course}>
              <Text s18 w800 lh22 secondary>
                {languages.EN.labels.relatedCourse}
              </Text>
              <div className={styles.competenceDetails__course__tile}>
                <CourseZoomTile data={{
                  ...data.courses[0],
                  duration: data.courses[0].duration / 60,
                }} />
              </div>
            </div>
          )}
        </div>
        <SwitchMenu links={links} />
        <TabContentTemplate>
          <Switch>
            <Route exact path={links[0].href}>
              <FilterContextProvider key={0}>
                <JobPositionsList data={relatedJobPositions} />
              </FilterContextProvider>
            </Route>
          </Switch>
        </TabContentTemplate>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CompetenceDetailsTemplate;
