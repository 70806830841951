import { useState, useContext } from "react";
import styles from "./ContextSelect.module.scss";
import Button from "../../atoms/Button/Button";
import Text from "../../atoms/Text/Text";
import DropdownLink from "../../atoms/DropdownLink/DropdownLink";
import { UserContext } from "../../../contexts/UserContext";
import { languages } from "../../../dummyData/dummyData";

const CONTEXT_ICONS = {
  0: 'student',
  1: 'teamManager',
  2: 'administrator',
  3: 'platformOwner',
  4: 'instructor',
};

const ContextSelect = () => {
  const { sendUserContext, userData } = useContext(UserContext);
  const { contexts, current } = userData;
  const [isOpen, setIsOpen] = useState(false);
  const [currentContextName, setCurrentContextName] = useState(current.name);


  const handleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (name, contextType, contextId) => {
    if (current.context_id === contextId && current.context_type === contextType) {
      return null;
    } else {
      setCurrentContextName(name);
      sendUserContext(contextType, contextId);
    }
  };

  return (
    <>
      <div className={styles.dropDownNav}>
        <div className={styles.switchUserButton} onClick={handleDropdown}>
          <Button
            variant="navBarButton"
            withIcon
            icon="topArrow"
            isOpen={!isOpen}
          >
            <Text s14 lh17 w700 light>{currentContextName}</Text>
          </Button>
        </div>
        <ul className={`${styles.options} ${isOpen && styles.open}`}>
          {contexts.map(({ context_type, context_id, num_notifications, name }, i) => {
            return (
              <li key={i} className={current.context_id === context_id && current.context_type === context_type ? styles.currentOption : ''}>
                <DropdownLink
                  icon={CONTEXT_ICONS[context_type]}
                  onClick={() => handleOptionClick(name, context_type, context_id)}
                >
                  <span className={styles.optionContent}>
                    <span>{name}</span>
                    {num_notifications > 0 && (
                      <span className={styles.notification}>
                        <div className={styles.notification__dot} />
                        <Text w300>{num_notifications} {languages.EN.labels.notifications}</Text>
                      </span>
                    )}
                  </span>
                </DropdownLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ContextSelect;
