import { useHistory } from "react-router";
import { useState, useCallback } from "react";
import { Routes } from "../routes";
import { verifyResponseCode } from "../utils/errorHandling";

const useFetch = () => {
  let history = useHistory();
  const [data, setData] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    (repository) => {
      setLoading(true);
      setIsReady(false);
      repository
        .then(verifyResponseCode)
        .then((data) => {
          setData(data);
          setIsReady(true);
          setTimeout(() => {
            setLoading(false);
          }, 100);
        })
        .catch((error) => {
          setIsReady(false);
          history.push({
            pathname: Routes.error.base,
            state: { message: error.message, cause: error.cause }
          });
        });
    },
    [history]
  );

  const resetData = useCallback(() => {
    setData(null);
  }, []);

  return { data, isReady, loading, fetchData, resetData };
};

export default useFetch;
