import styles from "./Statistics.module.scss";
import Statistic from "../../molecules/Statistic/Statistic";
import React from "react";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";

const Statistics = ({ data, header, buttons }) => {
  return (
    <div className={styles.wrapper}>
      {(header || buttons) && (
        <HeaderWithButtons header={header} buttons={buttons} />
      )}
      <section className={styles.statistics}>
        {data.filter(statistic => statistic.visible !== false).map((statistic, i) => (
          <Statistic
            key={i}
            icon={statistic.icon}
            content={statistic.content}
            description={statistic.description}
            value={statistic.value}
            previousValue={statistic.previousValue}
            formatter={statistic.formatter}
            data={statistic.icon}
            link={statistic.link}
          />
        ))}
      </section>
    </div>
  );
};

export default Statistics
