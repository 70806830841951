import { useContext, useEffect } from "react";
import { authRepository } from "../../../repository/authRepository";
import { setAuthorizationHeader } from "../../../repository/repositoryHelper";
import { PostContext } from "../../../contexts/PostContext";
import { Routes } from "../../../routes";
import { useHistory } from "react-router-dom";
import { Buffer } from 'buffer';

const RegistrationLanding = ({ match }) => {
  const { postData, isResponseOk, postResponse } = useContext(PostContext);
  const history = useHistory();

  useEffect(() => {
    postData(
      () => authRepository.tokenLogin({
        email: Buffer.from(match.params.email, 'base64').toString('ascii'),
        token: Buffer.from(match.params.token, 'base64').toString('ascii')
      }),
      (response) => {
        setAuthorizationHeader(response);
        history.push(Routes.registration.base);
      }
    );
  }, []);

  useEffect(() => {
    if (!isResponseOk && postResponse) {
      history.push(Routes.mainPage.base);
    }
  }, [isResponseOk, postResponse]);

  return null;
};

export default RegistrationLanding
