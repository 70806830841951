import { ResponsiveBar } from "@nivo/bar";
import styles from "./ProportionsBar.module.scss";
import React from "react";

const ProportionsBar = ({ config, getValue, max }) => {
  const preparedData = [{ ...Object.fromEntries(config.map(item => [item.type, getValue(item.type)])) }];

  return (
    <div className={styles.responsiveBar} >
      <ResponsiveBar
        data={preparedData}
        keys={config.map(item => item.type)}
        maxValue={max}
        margin={{ top: 50, bottom: 50 }}
        layout="horizontal"
        colors={config.map(item => item.headerMarkerColor)}
        isInteractive={true}
        tooltipLabel={(bar) => config.find(item => item.type === bar.id).label}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={5}
        labelTextColor="black"
      />
    </div>
  );
};

export default ProportionsBar;
