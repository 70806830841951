import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";

const useFlashMessages = () => {
  const [messages, setMessages] = useState([]);
  const { useFlashMessages: { getFlashMessages, addFlashMessage, flashMessagesAwaiting } } = useContext(UserContext);

  useEffect(() => {
    if (flashMessagesAwaiting) {
      setMessages(getFlashMessages());
    }
  }, [flashMessagesAwaiting]);

  return { flashMessages: messages, addFlashMessage };
};

export default useFlashMessages
