import { useHistory, useLocation } from "react-router-dom";
import ErrorTemplate from "../../templates/ErrorTemplate/ErrorTemplate";
import { Routes } from "../../../routes";

const Error = () => {
  const location = useLocation();
  const history = useHistory();

  if (location.state === undefined) {
    history.push(Routes.mainPage.base);
  }

  return location.state && (
    <ErrorTemplate error={location.state} />
  );
}

export default Error;
