import PieChart from "../../atoms/PieChart/PieChart";
import styles from "./PieChartBox.module.scss";

const PieChartBox = ({
  name,
  filledLabel,
  emptyLabel,
  filledAmount,
  emptyAmount,
  minWidth,
  bigSize,
  singleValue
}) => {
  return (
    <div className={styles.ChartBox} style={{ minWidth: `${minWidth}%` }}>
      <PieChart
        filledAmount={filledAmount}
        emptyAmount={emptyAmount}
        filledLabel={filledLabel}
        emptyLabel={emptyLabel}
        bigSize={bigSize}
        singleValue={singleValue}
      />
      <p>{name}</p>
    </div>
  );
};

export default PieChartBox;
