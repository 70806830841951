import React, { useMemo, useState } from "react";
import CompoundTableHeader from "../../../../molecules/CompoundTableHeader/CompoundTableHeader";
import CompetenceVerificationTypeCell, { getValueFromData, TYPE_ALL, TYPE_NOT_VERIFIED, TYPE_VERIFIED, TYPE_VERIFIED_AFTER_COURSE, TYPE_VERIFIED_BY_MANAGER, TYPE_VERIFIED_BY_TEST } from "./CompetenceVerificationTypeCell";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../../molecules/ProgressBar/ProgressBar";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";

export const COMPOUND_HEADER_CONFIG = {
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridTemplateAreas: `
    "${TYPE_ALL} ${TYPE_ALL} ${TYPE_ALL} ${TYPE_ALL}" 
    "${TYPE_VERIFIED} ${TYPE_VERIFIED} ${TYPE_VERIFIED} ${TYPE_NOT_VERIFIED}" 
    "${TYPE_VERIFIED_AFTER_COURSE} ${TYPE_VERIFIED_BY_TEST} ${TYPE_VERIFIED_BY_MANAGER} ${TYPE_NOT_VERIFIED}"
  `,
  items: [
    {
      type: TYPE_ALL,
      label: languages.EN.labels.noOfStudentsHaving,
      headerStyle: { justifyContent: "center" },
    },
    {
      type: TYPE_VERIFIED,
      label: languages.EN.labels.verified,
      headerStyle: { justifyContent: "center" },
    },
    {
      type: TYPE_VERIFIED_AFTER_COURSE,
      label: languages.EN.labels.afterCourse,
      headerMarkerColor: "#78c0f5",
    },
    {
      type: TYPE_VERIFIED_BY_TEST,
      label: languages.EN.labels.byTest,
      headerMarkerColor: "#ef91e6",
    },
    {
      type: TYPE_VERIFIED_BY_MANAGER,
      label: languages.EN.labels.byManager,
      headerMarkerColor: "#f27f2a",
    },
    {
      type: TYPE_NOT_VERIFIED,
      label: languages.EN.labels.notVerified,
      headerMarkerColor: "#ffdb70",
    },
  ]
};

const CompetenceListTable = ({ reports }) => {
  const { data } = reports;
  const [compoundColumnSort, setCompoundColumnSort] = useState(null);

  const items = data.map((item) => ({
    visibility: languages.EN.enums.visibility[item.internal ? 2 : 3],
    notVerified: item.num_user_competence - item.num_user_competence_manager - item.num_user_competence_test - item.num_user_competence_course,
    ...item,
  }));
  const maxTotalCompetences = Math.max(...items.map((item) => item.num_user_competence));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.name,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.visibility,
      accessor: "visibility",
    },
    {
      Header: (data) => (
        <CompoundTableHeader
          config={COMPOUND_HEADER_CONFIG}
          type={compoundColumnSort}
          setType={setCompoundColumnSort}
          isSorted={data.column.isSorted}
          isSortedDesc={data.column.isSortedDesc}
          toggle={() => data.toggleSortBy("numberOfCompetences")}
        />
      ),
      id: "numberOfCompetences",
      accessor: (data) => getValueFromData(data, compoundColumnSort),
      Cell: (data) => (
        <CompetenceVerificationTypeCell data={data.cell.row.original} barConfig={COMPOUND_HEADER_CONFIG.items.slice(2)} max={maxTotalCompetences} />
      ),
      disableSortBy: true,
      fullWidth: true,
    },
    {
      Header: languages.EN.labels.passPercentageByCourse,
      accessor: "course_pass_rate",
      sortType: "basic",
      Cell: (data) => (
        <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
      ),
    },
    {
      Header: languages.EN.labels.passPercentageByTest,
      accessor: "initial_pass_rate",
      sortType: "basic",
      Cell: (data) => (
        <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
      ),
    },
  ], [compoundColumnSort]);

  return <Table columns={columns} data={items} />;
}

export default CompetenceListTable;
