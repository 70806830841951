import { useContext } from "react";
import { languages } from "../../../dummyData/dummyData";
import { authRepository } from "../../../repository/authRepository";
import { PostContext } from "../../../contexts/PostContext";
import { useHistory } from "react-router-dom";
import { VARIANT_SUCCESS } from "../../atoms/InfoBaner/InfoBaner";
import { Routes } from "../../../routes";
import useFlashMessages from "../../../hooks/useFlashMessages";
import NewPasswordTemplate from "../generic/NewPasswordTemplate/NewPasswordTemplate";

const PasswordResetConfirmTemplate = ({ uidb36, token}) => {
  const { postData } = useContext(PostContext);
  const { addFlashMessage } = useFlashMessages();
  const history = useHistory();

  return (
    <NewPasswordTemplate
      onSave={(formData) => postData(
        () => authRepository.resetPasswordConfirm(uidb36, token, formData),
        () => {
          history.push(Routes.mainPage.base);
          addFlashMessage(VARIANT_SUCCESS, languages.EN.messages.passwordChanged);
        }
      )}
      onBack={() => history.push(Routes.mainPage.base)}
    />
  );
};
export default PasswordResetConfirmTemplate;
