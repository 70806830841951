import styles from "./TabContentTemplate.module.scss";
import { concatClassNames } from "../../../../utils";

const TabContentTemplate = ({ children, ...props }) => {
  props = { tabContent: true, ...props };

  return <div className={concatClassNames(props, styles)}>{children}</div>;
};

export default TabContentTemplate
