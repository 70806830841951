import styles from "./MultiComplete.module.scss";
import SelectedItem from "../../atoms/SelectedItem/SelectedItem";
import SelectSearch from "../../atoms/SelectSearch/SelectSearch";
import React, { useState } from "react";

const MultiComplete = ({ name, value, required, disabled, items, withImg, handleSelect, handleDelete, label, selectedItems, errors, autoFocus }) => {
  const [resetInput, setResetInput] = useState(0);

  const handleSelectAndReset = (name, id, item) => {
    setResetInput(resetInput + 1);
    return handleSelect(name, id, item);
  };

  return (
    <>
      {selectedItems.length > 0 ? (
        <div className={styles.selectedWrapper}>
        {selectedItems.map((item, i) => (
          <SelectedItem
            key={i}
            text={item.label}
            item={item}
            onClick={!disabled ? (id) => handleDelete(name, id) : () => {}}
            withImg
          />
        ))}
      </div>
      ) : (<></>)}
      <SelectSearch
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        items={items}
        withImg={withImg}
        handleSelect={handleSelectAndReset}
        resetInput={resetInput}
        placeholder={label}
        error={errors}
        autoFocus={autoFocus}
      />
    </>
  );
};

export default MultiComplete;
