import styles from "./RadioInputs.module.scss";
import RadioButton from "../../atoms/RadioButton/RadioButton";
import Text from "../../atoms/Text/Text";
import React, { useEffect, useState } from "react";
import Label from "../../atoms/Label/Label";
import Input from "../../atoms/Input/Input";
import UploadFile from "../UploadFile/UploadFile";
import { TYPE_AUTOCOMPLETE, TYPE_FILE } from "../Form/Form";
import SelectSearch from "../../atoms/SelectSearch/SelectSearch";

const RadioInputs = ({ placeholder, inputs, handleInput, errors }) => {
  const inputsWithValues = inputs.filter(field => ![undefined, null].includes(field.value));

  const [selected, setSelected] = useState(inputsWithValues.length > 0 ? inputsWithValues[0].name : inputs[0].name);
  const [values, setValues] = useState(Object.fromEntries(Object.entries([].concat(...inputs)).map(
    ([, field]) => [field.name, field.value]
  )));

  const handleRadioChange = (event) => {
    const selectedName = event.target.value;

    setSelected(selectedName);
    handleInput(Object.fromEntries(inputs.map(input => [input.name, selectedName === input.name ? values[selectedName] : null])));
  }

  const handleDefault = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleSelect = (name, id) => {
    setValues({ ...values, [name]: id });
  }

  useEffect(() => {
    if (values[selected] !== undefined) {
      handleRadioChange({ target: { value: selected } });
    }
  }, [values]);

  // TODO: use Form.js function
  const renderInput = (data) => {
    switch (data.type) {
      case TYPE_FILE:
        return (
          <UploadFile
            key={data.name}
            name={data.name}
            disabled={selected !== data.name}
            setFormData={setValues}
            formData={values}
            error={errors[data.name] !== undefined}
            errorMessage={errors[data.name]}
            noMargin={true}
            isFile={true}
            allowedTypes={data.allowedTypes}
          />
        );
      case TYPE_AUTOCOMPLETE:
        return (
          <SelectSearch
            key={data.name}
            name={data.name}
            currName={data.items.find((item) => item.id === values[data.name])?.label}
            required={data.required}
            disabled={selected !== data.name}
            items={data.items}
            withImg={data.items[0]?.photo !== undefined}
            handleSelect={handleSelect}
            error={errors[data.name]}
            darkBorder
            noMargin
          />
        );
      default:
        return (
          <Input
            key={data.name}
            name={data.name}
            value={values[data.name] ?? ''}
            disabled={selected !== data.name}
            variant={data.type}
            handleInput={handleDefault}
            error={errors[data.name]}
            noMargin={true}
            darkBorder={true}
          />
        );
    }
  };

  return (
    <div className={styles.radioInputsWrapper}>
      <Label isFocus={true} placeholder={placeholder} absolute />
      {inputs.map((input, i) => (
        <React.Fragment key={i}>
          <RadioButton value={input.name} checked={selected === input.name} variant="secondary" handleChange={handleRadioChange} />
          <Text dark-4 s12>{input.label}</Text>
          {renderInput(input)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default RadioInputs;
