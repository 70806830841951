import React, { useContext, useEffect, useRef } from "react";
import styles from "./OrganizationLicensesTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { UserContext } from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import LicensesTable from "../../organisms/Table/OrganizationsTable/LicensesTable";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import InvoicesTable from "../../organisms/Table/InvoicesTable/InvoicesTable";
import moment from "moment";

const OrganizationLicensesTemplate = ({ licenses, collections }) => {
  const { callPopup } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);

  const pageHeader = languages.EN.labels.licenses;
  useDocumentTitle(pageHeader);

  const location = useLocation();
  const licensesTableRef = useRef(null);

  useEffect(() => {
    if (location.state?.scrollToLicenses) {
      licensesTableRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (location.state?.showInformation) {
      setTimeout(() => {
        callPopup({
          type: ACTIONS.INFORMATION,
          payload: {
            header: languages.EN.labels.information,
            content: languages.EN.labels.selectAvailableLicense,
          },
        });
      }, 700);
    }
  }, [location]);
  return (
    <div className={styles.wrapper}>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <div className={styles.main}>
          <div className={styles.licensesWrapper}>
            <TableWrapper
              title={languages.EN.labels.payments}
              Table={InvoicesTable}
              reports={{ data: licenses.invoices }}
              warning={licenses.invoices.filter(invoice => !invoice.paid && moment().diff(invoice.due_date, "days") > 0).length > 0 ? languages.EN.labels.youHaveOverduePayment : undefined}
            />
          </div>
          <div className={styles.licensesWrapper}>
            <div ref={licensesTableRef} />
            <TableWrapper
              title={languages.EN.labels.licenses}
              Table={LicensesTable}
              reports={{ data: licenses.licenses, organizationId: userData.org_id, collections: collections, filterInitialValue: location.state?.licenseNumber }}
            />
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </div>
  );
};

export default OrganizationLicensesTemplate;
