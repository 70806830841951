import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import { generatePath } from "react-router-dom";
import TileItem from "../TileItem/TileItem";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import { languages } from "../../../dummyData/dummyData";

const TeamListItemTile = ({ data }) => {
  const { name, description, profile_image_small, num_members, id, preparedManager } = data;
  const history = useHistory();

  return (
    <TileItem title={name} image={profile_image_small} buttons={[
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.team.statistics.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ]}>
      <TileItemInfo header={languages.EN.labels.manager} value={preparedManager} />
      <TileItemInfo header={languages.EN.labels.noOfTeamMembers} value={num_members} />
      <TileItemInfo header={languages.EN.labels.description} value={description} fullRow={true} />
    </TileItem>
  );
};
export default TeamListItemTile;
