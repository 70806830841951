import React, { useState, useEffect } from "react";
import styles from "./TablePagination.module.scss";
import ReactPaginate from "react-paginate";
import { languages } from "../../../dummyData/dummyData";
import Text from "../../atoms/Text/Text";

const TablePagination = ({
  data,
  pageIndex,
  gotoPage,
  pageSize,
  setPageSize,
  pageOptions,
  globalFilter,
}) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(0);

  useEffect(() => {
    setItemOffset(0);
  }, [data]);

  useEffect(() => {
    if (data) {
      setEndOffset(itemOffset + pageSize);
    }
  }, [data, itemOffset, pageOptions, pageSize]);

  const handlePageClick = (event) => {
    gotoPage(event.selected);
    const newOffset = (event.selected * pageSize) % data.length;
    setItemOffset(newOffset);
  };

  const getPaginationResultsString = () => {
    return `${languages.EN.labels.results}
      ${pageIndex === 0 ? 1 : itemOffset + 1}
      - ${endOffset > data?.length ? data?.length : endOffset} ${languages.EN.labels.of} ${
      data?.length
    }`;
  };

  useEffect(() => {
    gotoPage(0);
  }, [globalFilter, gotoPage]);

  return (
    <>
      {!!data.length && (
        <>
          <div className={styles.select}>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[8, 12, 16].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span className={styles.selectArrow} />
          </div>
          <div className={styles.pageIndex}>
            <Text w500 s12>
              {getPaginationResultsString()}
            </Text>
          </div>
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            forcePage={pageIndex}
            initialSelected={pageIndex}
            pageCount={pageOptions.length}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="previousButton"
            previousLinkClassName="previousLink"
            nextClassName="nextButton"
            nextLinkClassName="nextLink"
            breakLabel="···"
            breakClassName="page-item"
            breakLinkClassName="breakLink"
            containerClassName="reactPagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </>
      )}
    </>
  );
};

export default TablePagination;
