import React, { useMemo, useState } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../../routes";
import { percentageFormat } from "../../../../../utils/formatters";
import Text from "../../../../atoms/Text/Text";
import DateSelect from "../../../../atoms/DateSelect/DateSelect";
import { enumerateMonths } from "../../../../../utils/dateHelpers";

function InstructorStatisticsTable({ reports }) {
  const { data } = reports;

  const allMonths = useMemo(() => {
    const monthNames = enumerateMonths();

    return [...data.reduce((acc, instructorData) => new Set([
      ...acc,
      ...instructorData.course_separate_data.reduce((acc2, courseData) => new Set([
        ...acc2,
        ...courseData.items.map(monthlyStatistics => monthlyStatistics.date)
      ]), [])
    ]), [])]
      .sort()
      .map(startDate => ({
        startDate: startDate,
        name: `${monthNames[parseInt(startDate.slice(5, 7)) - 1]} ${startDate.slice(0, 4)}`,
      }))
  }, []);

  const allQuarters = useMemo(() => {
    let quarters = [];

    for (let i in allMonths) {
      const date = new Date(allMonths[i].startDate);
      const number = parseInt(date.getMonth() / 3) + 1;
      const name = `Q${number} ${date.getFullYear()}`;

      quarters[name] = {
        name: name,
        startDates: (quarters[name] ? [...quarters[name].startDates] : []).concat([allMonths[i].startDate]),
      }
    }

    return Object.entries(quarters).map(([, value]) => value);
  }, []);

  const [currentMonth, setCurrentMonth] = useState(allMonths[allMonths.length - 1]);
  const [currentQuarter, setCurrentQuarter] = useState(allQuarters[allQuarters.length - 1]);
  const history = useHistory();

  const items = data.map((instructorData) => {
    const instructorName = instructorData.instructor.name.split(" ");
    const subRowsData = instructorData.course_separate_data.map(courseData => {
      const monthlyMinutes = courseData.items
        .filter(item => item.date === currentMonth.startDate)
        .reduce((acc, curr) => ({
          usage: acc.usage + curr.video_usage,
          totalUsage: acc.totalUsage + curr.total_video_usage,
        }), { usage: 0, totalUsage: 0 });
      const quarterlyMinutes = courseData.items
        .filter(item => currentQuarter.startDates.includes(item.date))
        .reduce((acc, curr) => ({
          usage: acc.usage + curr.video_usage,
          totalUsage: acc.totalUsage + curr.total_video_usage,
        }), { usage: 0, totalUsage: 0 });
      const allTimeMinutes = courseData.items
        .reduce((acc, curr) => ({
          usage: acc.usage + curr.video_usage,
          totalUsage: acc.totalUsage + curr.total_video_usage,
        }), { usage: 0, totalUsage: 0 });

      return {
        courses: courseData.course.display_name,
        action: generatePath(Routes.courseDetails.statistics.base, { id: courseData.course.id }),
        monthlyMinutesPlayed: Math.round(monthlyMinutes.usage / 60),
        monthlyViewership: monthlyMinutes.totalUsage !== 0 ? monthlyMinutes.usage / monthlyMinutes.totalUsage : 0,
        quarterlyMinutesPlayed: Math.round(quarterlyMinutes.usage / 60),
        quarterlyViewership: quarterlyMinutes.totalUsage !== 0 ? quarterlyMinutes.usage / quarterlyMinutes.totalUsage : 0,
        allTimeMinutesPlayed: Math.round(allTimeMinutes.usage / 60),
        allTimeViewership: allTimeMinutes.totalUsage !== 0 ? allTimeMinutes.usage / allTimeMinutes.totalUsage : 0,
      };
    });

    return {
      ...instructorData,
      id: instructorData.instructor.id,
      instructor: {
        ...instructorData.instructor,
        name: instructorName[0],
        surname: instructorName[1],
      },
      monthlyMinutesPlayed: subRowsData.reduce((acc, curr) => acc + curr.monthlyMinutesPlayed, 0),
      monthlyViewership: subRowsData.reduce((acc, curr) => acc + curr.monthlyViewership, 0),
      quarterlyMinutesPlayed: subRowsData.reduce((acc, curr) => acc + curr.quarterlyMinutesPlayed, 0),
      quarterlyViewership: subRowsData.reduce((acc, curr) => acc + curr.quarterlyViewership, 0),
      allTimeMinutesPlayed: subRowsData.reduce((acc, curr) => acc + curr.allTimeMinutesPlayed, 0),
      allTimeViewership: subRowsData.reduce((acc, curr) => acc + curr.allTimeViewership, 0),
      courses: `${instructorData.course_separate_data.length} ${languages.EN.labels.courses}`,
      action: generatePath(Routes.instructorDetails.base, { id: instructorData.instructor.id }),
      subRows: subRowsData,
    }
  });

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.lastName,
      accessor: "instructor.surname",
      rowSpan: 3,
    },
    {
      Header: languages.EN.labels.firstName,
      accessor: "instructor.name",
      rowSpan: 3,
    },
    {
      Header: languages.EN.labels.courses,
      accessor: "courses",
      rowSpan: 3,
    },
    {
      Header: languages.EN.labels.statistics,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          id: "monthly",
          Header: () => (
            <div className={styles.dropDownHeader}>
              <Text s12 uppercase lh24 light>
                {languages.EN.labels.monthly}
              </Text>
              <DateSelect
                items={allMonths}
                setOurAnswer={setCurrentMonth}
                currName={currentMonth.name}
              />
            </div>
          ),
          disableSortBy: true,
          headerStyle: { justifyContent: "center" },
          columns: [
            {
              Header: languages.EN.labels.minutesPlayed,
              accessor: "monthlyMinutesPlayed",
            },
            {
              Header: languages.EN.labels.viewership,
              id: "monthlyViewership",
              accessor: (data) => percentageFormat(data.monthlyViewership),
            },
          ],
        },
        {
          id: "quarterly",
          Header: () => (
            <div className={styles.dropDownHeader}>
              <Text s12 uppercase lh24 light>
                {languages.EN.labels.quarterly}
              </Text>
              <DateSelect
                items={allQuarters}
                setOurAnswer={setCurrentQuarter}
                currName={currentQuarter.name}
              />
            </div>
          ),
          disableSortBy: true,
          headerStyle: { justifyContent: "center" },
          columns: [
            {
              Header: languages.EN.labels.minutesPlayed,
              accessor: "quarterlyMinutesPlayed",
            },
            {
              Header: languages.EN.labels.viewership,
              id: "quarterlyViewership",
              accessor: (data) => percentageFormat(data.quarterlyViewership),
            },
          ],
        },
        {
          Header: languages.EN.labels.allTime,
          disableSortBy: true,
          headerStyle: { justifyContent: "center" },
          columns: [
            {
              Header: languages.EN.labels.minutesPlayed,
              accessor: "allTimeMinutesPlayed",
            },
            {
              Header: languages.EN.labels.viewership,
              id: "allTimeViewership",
              accessor: (data) => percentageFormat(data.allTimeViewership),
            },
          ],
        },
      ],
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      rowSpan: 3,
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(data.row.original.action)}
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} expand={false} />;
}

export default InstructorStatisticsTable;
