import CompetenceTestTemplate from "../../templates/CompetenceTestTemplate/CompetenceTestTemplate";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import useFetch from "../../../hooks/useFetch";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const CompetenceTest = ({ match }) => {
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  const fetchTest = useFetch();
  useEffect(() => {
    fetchTest.fetchData(competenceTestRepository.getTest(match.params.id));
  }, []);

  const fetchTestStatus = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchTestStatus.data) {
      fetchTestStatus.fetchData(competenceTestRepository.getTestStatus(match.params.id));
    }
  }, [isResponseOk]);

  return fetchTest.isReady && fetchTestStatus.data ? (
    <CompetenceTestTemplate
      testId={match.params.id}
      questions={fetchTest.data}
      status={fetchTestStatus.data}
      courseId={match.params.course}
    />
  ) : (
    <Loader />
  );
};

export default CompetenceTest;
