import styles from "./TileItemImgInfo.module.scss";
import TileItemInfo from "../TileItemInfo/TileItemInfo";
import RoundImage from "../../atoms/RoundImage/RoundImage";

const TileItemImgInfo = ({ header, value, image, fullRow }) => {
  return (
    <div className={styles.tileItemImgInfo} data-fullrow={fullRow}>
      <TileItemInfo header={header} value={value} />
      {image !== undefined ? (
        <RoundImage image={image} size={30} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TileItemImgInfo;
