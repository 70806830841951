import { useEffect, useState, useRef } from "react";
import Text from "../Text/Text";
import styles from "./Select.module.scss";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

const Option = ({
  name,
  order,
  handleOnChange,
  item,
  activeElement,
  enterCounter,
  isArrowActive,
  setActiveElement,
  isHover,
  disableHoverSelect,
}) => {
  const myRef = useRef(null);
  const [isActive, setIsActive] = useState(
    activeElement === order ? true : false
  );

  useEffect(() => {
    if (isHover) {
      setIsActive(false);
    }
  }, [isHover]);

  useEffect(() => {
    if (activeElement === order) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeElement, order]);

  useEffect(() => {
    if (isActive && enterCounter > 1) {
      setTimeout(() => {
        handleOnChange(item, name);
      }, 0);
    }
  }, [enterCounter, handleOnChange, isActive, item, name]);

  return (
    <ScrollIntoViewIfNeeded active={isActive}>
      <div
        ref={myRef}
        onMouseMove={() => {
          if (!disableHoverSelect) {
            setActiveElement(order);
          }
        }}
        className={`${isActive && styles.option__active} ${styles.option} ${isArrowActive && styles.option__noHover}`}
        onClick={() => handleOnChange(item, name)}
      >
        <Text s12 w600 dark-4>
          {item.label}
        </Text>
      </div>
    </ScrollIntoViewIfNeeded>
  );
};

export default Option;
