import MarketplaceTemplate from "../../templates/MarketplaceTemplate/MarketplaceTemplate";
import useFetch from "../../../hooks/useFetch";
import { marketplaceRepository } from "../../../repository/marketplace.repository";
import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";

const Marketplace = () => {
  const { data, fetchData } = useFetch();

  useDocumentTitleLoading();

  useEffect(() => {
    fetchData(marketplaceRepository.getMarketplace());
  }, []);

  return data ? (
    <FilterContextProvider>
      <MarketplaceTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default Marketplace;
