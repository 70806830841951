import styles from "./ProfileCourses.module.scss";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import { coursesRepository } from "../../../repository/courses.repository";
import PreviewPlayer from "../PreviewPlayer/PreviewPlayer";
import useFetch from "../../../hooks/useFetch";
import CourseZoomTile from "../CourseZoomTile/CourseZoomTile";
import FilteredList from "../FilteredList/FilteredList";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";

const filterDefinitions = [
  {
    keyPath: "status",
    header: languages.EN.labels.status,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "display_name",
  },
  {
    keyPath: "preparedInstructorNames",
  },
];

const popupFilterDefinitions = [
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "duration",
    header: `${languages.EN.labels.duration} (${languages.EN.labels.minutes})`,
    typeOfFilter: "range",
  },
];

const PopupRenderItem = ({ item }) => {
  return (
    <span className={styles.popupItem}>
      <TextTruncated lineClamp={1}>
        <Text s12 dark-4>
          {item.display_name}
        </Text>
      </TextTruncated>
      <RoundImage image={item.course_organization ? item.course_organization.profile_image_small : <AidifyLogo />} size={20} />
    </span>
  );
}

const ProfileCourses = ({ courses, isMine, username }) => {
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const [isVideoClicked, setIsVideoClicked] = useState(false);
  const [clickedCourseId, setClickedCourseId] = useState(null);

  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(courses?.map((item) => {
    const instructors = item.course_instructor.length > 0 ? item.course_instructor.map(instructor => ({
      name: instructor.name,
      img: instructor.profile_image_small,
    })) : [{
      name: item.instructor_text,
      img: undefined,
    }];

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.topic],
      visibility: languages.EN.enums.visibility[item.course_organization !== null ? 2 : 3],
      preparedInstructorNames: instructors.map(instructor => instructor.name),
      duration: Math.round(item.duration / 60),
      status: []
        .concat(item.user_progress < 1 ? [languages.EN.labels.inProgress] : [])
        .concat(item.user_progress === 1 ? [languages.EN.labels.completed] : []),
    };
  })), [courses]);

  const fetchCourses = useFetch();
  useEffect(() => {
    if (fetchCourses.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: languages.EN.labels.recommend,
          items: fetchCourses.data.filter(item => item.active).map(item => ({
            ...item,
            id: item.id,
            label: item.display_name,
            learningArea: languages.EN.enums.learningAreas[item.topic],
            visibility: languages.EN.enums.visibility[item.course_organization !== null ? 2 : 3],
            duration: Math.round(item.duration / 60),
          })),
          RenderItem: PopupRenderItem,
          filters: popupFilterDefinitions,
          singleSelect: false,
          postAction: (formData) => coursesRepository.shareCourse({ users: username, courses: formData.join(",") }),
        },
      });
    }
  }, [fetchCourses.isReady]);

  const [previewUrl, setPreviewUrl] = useState(null);
  const fetchCourseView = useFetch();
  useEffect(() => {
    if (fetchCourseView.isReady) {
      setPreviewUrl(fetchCourseView.data.course.video_preview);
    }
  }, [fetchCourseView.isReady]);
  useEffect(() => {
    if (clickedCourseId) {
      setIsVideoClicked(true);
      return fetchCourseView.fetchData(coursesRepository.getCourseDetails(clickedCourseId));
    }
  }, [clickedCourseId]);

  const RenderTileComponent = ({ data }) => <CourseZoomTile data={data} isVisible={true} setClickedCourseId={setClickedCourseId} isMine={isMine} username={username} />

  return (
    <>
      <FilteredList
        id={"profileCourses"}
        data={preparedData}
        filterDefinitions={filterDefinitions}
        RenderTileComponent={RenderTileComponent}
        gridSize={3}
        buttons={!isMine ? [
          {
            icon: "star",
            label: languages.EN.labels.recommend,
            onClick: () => {
              setIsDataActive(false);
              fetchCourses.fetchData(coursesRepository.getCourses());
              callPopup({ type: ACTIONS.LOADING });
            }
          },
        ] : []}
      />
      {isVideoClicked && <PreviewPlayer url={previewUrl} onClose={() => {
        setIsVideoClicked(false);
        setPreviewUrl(null);
      }} />}
    </>
  );
};

export default ProfileCourses;
