import React, { useEffect, useContext, useState, useRef } from "react";
import Text from "../../atoms/Text/Text";
import SingleChoice from "../../molecules/CompetenceTestQuestions/SingleChoice/SingleChoice";
import MultipleChoice from "../../molecules/CompetenceTestQuestions/MultipleChoice/MultipleChoice";
import FillHoles from "../../molecules/CompetenceTestQuestions/FillHoles/FillHoles";
import PickValidOrder from "../../molecules/CompetenceTestQuestions/PickValidOrder/PickValidOrder";
import PairUp from "../../molecules/CompetenceTestQuestions/PairUp/PairUp";
import { CompetenceTestContext } from "../../../contexts/CompetenceTestContext";
import { languages } from "../../../dummyData/dummyData";

const CompetenceTestQuestion = ({ data }) => {
  const { type } = data;
  const { isReady } = useContext(CompetenceTestContext);
  const [height, setHeight] = useState(null);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.children[1].clientHeight)
    }
  }, [isReady]);

  return (
    <div ref={ref}>
      <Text s14 lh24 secondary>
        {languages.EN.enums.testProblemType[data.type]}
      </Text>

      {type === 0 ? (
        <SingleChoice data={data} />
      ) : type === 1 ? (
        <MultipleChoice data={data} />
      ) : type === 2 ? (
        <FillHoles data={data} />
      ) : type === 3 ? (
        <PickValidOrder data={data} />
      ) : type === 4 ? (
        <PairUp data={data} />
      ) : (
        <div style={{ minHeight: `${height}px` }}/>
      )}
    </div>
  );
};

export default CompetenceTestQuestion;
