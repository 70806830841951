import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./SetableCompetence.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import Button from "../../atoms/Button/Button";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import CurrentLevelSlider from "../../atoms/CurrentLevelSlider/CurrentLevelSlider";
import GoalLevelSlider from "../../atoms/GoalLevelSlider/GoalLevelSlider";
import { languages } from "../../../dummyData/dummyData";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { PostContext } from "../../../contexts/PostContext";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import { FilterContext } from "../../../contexts/FilterContext";

const SetableCompetence = ({ data, allowManualVerification }) => {
  const { verified, name, organization, group, country, competence_levels, competence_level_id, learningArea } = data;
  const [isManualVerificationUsed, setManualVerificationUsed] = useState(false);
  const { callPopup } = useContext(PopupsContext);
  const { postResponse } = useContext(PostContext);
  const { useSelectables, useProcessedData, useFilterReset } = useContext(FilterContext);
  const { selected, setSelected } = useSelectables;
  const { processedData, setIsDataReady } = useProcessedData;
  const { setFilterReset } = useFilterReset;
  const { level, goal, type } = selected.find(item => item.id === data.id);
  const levelsDataByLevel = useMemo(() => Object.fromEntries(competence_levels.map(levelData => [levelData.level, levelData])), [competence_levels]);
  const keyCompetenceLevel = useMemo(() => competence_levels.find(levelData => levelData.type === 0), [competence_levels]);
  const history = useHistory();

  const onChange = (newState) => {
    setSelected(selected.map(selectedItem => selectedItem.id === data.id ? { ...selectedItem, ...newState } : selectedItem));
  };

  useEffect(() => {
    if (isManualVerificationUsed && postResponse) {
      setManualVerificationUsed(false);
      if (postResponse.status === undefined) {
        history.push(generatePath(Routes.competenceTest.test.base, { id: postResponse.initial_test }));
      } else {
        callPopup({
          type: ACTIONS.INFORMATION,
          payload: {
            header: languages.EN.labels.information,
            content: postResponse.status,
          },
        });
      }
    }
  }, [isManualVerificationUsed, postResponse]);

  return (
    <div className={styles.competence}>
      <div className={styles.competence__box}>
        <div className={styles.competence__titleWrapper}>
          <RoundImage image={organization ? organization.profile_image_small : <AidifyLogo />} size={25} />
          <TextTruncated lineClamp={1} secondary>
            <Text s20 lh24 w800 secondary>
              {name}
            </Text>
          </TextTruncated>
        </div>
        <div className={styles.competence__verificationBox}>
          {level !== undefined && (verified ? (
            <Text s14 lh16 w400 primary>
              {languages.EN.labels.verified}
            </Text>
          ) : (
            <Text s10 lh12 w400 dark-2>
              {languages.EN.labels.notVerified}
            </Text>
          ))}
          <div className={styles.competence__verificationBox__buttons}>
            {allowManualVerification && !verified && !!competence_level_id && level > 0 && (
              <Button
                icon="exam"
                onlyIcon
                variant="iconButton"
                onClick={() => {
                  callPopup({
                    type: ACTIONS.POST_CONFIRM,
                    payload: {
                      header: languages.EN.labels.confirmation,
                      content: languages.EN.messages.verifyManual,
                      action: () => {
                        callPopup({ type: ACTIONS.REMOVE });
                        setManualVerificationUsed(true);
                        return competenceTestRepository.runManualTest({ competence_levels: competence_level_id });
                      },
                    },
                  })
                }}
                tooltip={languages.EN.labels.runTest}
              />
            )}
            {level !== undefined && (
              <Button
                variant="iconButton"
                onlyIcon
                icon="garbage"
                onClick={() => setSelected(selected.map(selectedItem => selectedItem.id === data.id ? { ...selectedItem, level: undefined, type: undefined } : selectedItem))}
                tooltip={languages.EN.labels.delete}
              />
            )}
            {level === undefined && (
              <Button
                variant="iconButton"
                onlyIcon
                icon="plus"
                onClick={() => {
                  setSelected(selected.map(selectedItem => selectedItem.id === data.id ? { ...selectedItem, level: 0, type: 0 } : selectedItem));
                  setIsDataReady(false);
                  if (processedData.length === 1) {
                    setFilterReset(true);
                  }
                }}
                tooltip={languages.EN.labels.add}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.competence__informationBox}>
        <span className={styles.competence__informationText}>
          <Text s10 lh12 dark-1>
            {`${languages.EN.labels.learningArea}:`}
          </Text>
          <Text s10 lh12 primary>
            {learningArea}
          </Text>
        </span>
        <span className={styles.competence__informationText}>
          <Text s10 lh12 dark-1>
            {`${languages.EN.labels.group}:`}
          </Text>
          <Text s10 lh12 primary>
            {group.name}
          </Text>
        </span>
        <span className={styles.competence__informationText}>
          <Text s10 lh12 dark-1>
            {`${languages.EN.labels.region}:`}
          </Text>
          <Text s10 lh12 primary>
            {country}
          </Text>
        </span>
      </div>
      <div className={styles.knowledgeLvl}>
        {keyCompetenceLevel !== undefined && (
          <>
            <div className={styles.keyCompetence} style={{ ['--key-competence-level']: keyCompetenceLevel.level }}>
              <div className={styles.keyCompetence__frame} />
              <div className={styles.keyCompetence__description}>
                <Text s10 lh24 red>
                  {languages.EN.labels.keyCompetence} {languages.EN.labels.inYourJobPosition.toLowerCase()}
                </Text>
              </div>
            </div>
          </>
        )}
        <div className={styles.knowledgeLvl__row}>
          <Text s12 lh12 dark-1>
            {languages.EN.labels.competenceLevel}:
          </Text>
        </div>
        <div className={styles.knowledgeLvl__row}>
          <Text s10 lh12 dark-1>
            {languages.EN.labels.goal}
          </Text>
          <div className={styles.lvlSlider}>
            <GoalLevelSlider
              level={goal}
              competenceLevels={levelsDataByLevel}
              onChange={(value) => onChange({ goal: value })}
              showPopup
            />
          </div>
        </div>
        {level !== undefined && (
          <div className={styles.knowledgeLvl__row}>
            <Text s10 lh12 dark-1>
              {languages.EN.labels.current}
            </Text>
            <div className={styles.currentLvlSlider}>
              <CurrentLevelSlider
                level={level}
                competenceLevels={levelsDataByLevel}
                onChange={(value) => onChange({ level: value })}
              />
            </div>
          </div>
        )}
      </div>
      {type !== undefined && (
        <div className={styles.competence__priority}>
          <Text s12 lh12 dark-1>
            {languages.EN.labels.importance}
          </Text>
          <div className={styles.buttons__container}>
            <span onClick={() => onChange({ type: 0 })}>
              <Button variant={type === 0 ? "primaryLow" : "primaryLowLight"}>
                {languages.EN.labels.required}
              </Button>
            </span>
            <span onClick={() => onChange({ type: 1 })}>
              <Button variant={type === 1 ? "primaryLow" : "primaryLowLight"}>
                {languages.EN.labels.recommended}
              </Button>
            </span>
            <span onClick={() => onChange({ type: 2 })}>
              <Button variant={type === 2 ? "primaryLow" : "primaryLowLight"}>
                {languages.EN.labels.personalInterest}
              </Button>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SetableCompetence;
