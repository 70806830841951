import styles from "./ConfirmPopup.module.scss";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { languages } from "../../../../dummyData/dummyData";
import { useContext } from "react";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";

const ConfirmPopup = ({ header, content, action }) => {
  const { callPopup } = useContext(PopupsContext);

  return (
    <>
      <Text s18 lh22 mb27>
        {header}
      </Text>
      <div className={styles.Content}>
        <Text s12 lh14 dark-1 className={styles.Content}>
          {content}
        </Text>
      </div>
      <SaveBackButtons onSave={action} saveLabel={languages.EN.labels.confirm} onBack={() => callPopup({ type: ACTIONS.REMOVE })} />
    </>
  );
};

export default ConfirmPopup;
