import React, { useState, createContext, useReducer } from "react";
import { PopupOpacityAnimation } from "../components/atoms/Animiations/Animiations";
import { FilterContextProvider } from "./FilterContext";
import PopupInitialTest from "../components/molecules/Popups/PopupInitialTest/PopupInitialTest";
import PopupWrapper from "../components/molecules/Popups/PopupWrapper/PopupWrapper";
import PopupNotifications from "../components/molecules/Popups/PopupNotifications/PopupNotifications";
import PopupDetailsGiftShop from "../components/molecules/Popups/PopupDetailsGiftShop/PopupDetailsGiftShop";
import PopupBuy from "../components/molecules/Popups/PopupBuy/PopupBuy";
import PopupDelete from "../components/molecules/Popups/PopupDelete/PopupDelete";
import ConfirmPopup from "../components/molecules/Popups/ConfirmPopup/ConfirmPopup";
import PopupInformation from "../components/molecules/Popups/PopupInformation/PopupInformation";
import PopupAddQuestion from "../components/molecules/Popups/PopupAddQuestion/PopupAddQuestion";
import PopupAssignCompetition from "../components/molecules/Popups/PopupAssignCompetition/PopupAssignCompetition";
import FormPopup from "../components/molecules/Popups/FormPopup/FormPopup";
import FilePopup from "../components/molecules/Popups/FilePopup/FilePopup";
import SearchBackendUsersPopup from "../components/molecules/Popups/SearchBackendUsersPopup/SearchBackendUsersPopup";
import SelectPopup from "../components/molecules/Popups/SelectPopup/SelectPopup";
import PostConfirmPopup from "../components/molecules/Popups/PostConfirmPopup/PostConfirmPopup";
import ReportProblemPopup from "../components/molecules/Popups/ReportProblemPopup/ReportProblemPopup";
import MfaConfirmPopup from "../components/molecules/Popups/MfaConfirmPopup/MfaConfirmPopup";

export const PopupsContext = createContext();

export const ACTIONS = {
  INITIAL_TEST: "registration/initial test",
  NOTIFICATION: "notification popup",
  GIFT_DETAILS: "homepage/gift details",
  BUY: "popup buy",
  ADD_QUESTION: "add question",
  ASSIGN_COMPETITION: "homepage/ assign to competition",

  INFORMATION: "Information",
  REMOVE: "remove popup",
  DELETE: "delete",
  CONFIRM: "confirm",
  POST_CONFIRM: "confirm with postData on save",
  MFA_CONFIRM: "confirm enabling MFA",
  FORM: "generic save/edit form",
  SELECT: "generic select form",
  SEARCH_BACKEND_USERS: "generic select users form",
  FILE: "add file form",
  REPORT_PROBLEM: "report problem form",
  LOADING: "loading",
};

export const PopupsContextProvider = ({ children }) => {
  const [style, setStyle] = useState({});
  const [isPopup, setIsPopup] = useState(false);
  const [isDataActive, setIsDataActive] = useState(true);

  const reducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.ASSIGN_COMPETITION:
        setIsPopup(true);
        return (
          <PopupAssignCompetition
            header={action.payload.header}
            competitionType={action.payload.competitionType}
            handleSignUp={action.payload.handleSignUp}
            setIsSignedup={action.payload.setIsSignedup}
          />
        );
      case ACTIONS.INITIAL_TEST:
        setIsPopup(true);
        setStyle({ padding: "25px" });
        return (
          <PopupInitialTest
            competences={action.payload.competences}
            onNextStep={action.payload.onNextStep}
          />
        );
      case ACTIONS.ADD_QUESTION:
        setIsPopup(true);
        return (
          <PopupAddQuestion
            header={action.payload.header}
            questionData={action.payload.questionData}
            units={action.payload.units}
            sendHandler={action.payload.sendHandler}
          />
        );

      case ACTIONS.INFORMATION:
        setIsPopup(true);
        return (
          <PopupInformation
            header={action.payload.header}
            content={action.payload.content}
            action={action.payload.action}
            buttonLabel={action.payload.buttonLabel}
          />
        );
      case ACTIONS.BUY:
        setIsPopup(true);
        return (
          <PopupBuy
            header={action.payload.header}
            elementType={action.payload.elementType}
            elementName={action.payload.elementName}
            elementId={action.payload.elementId}
            numberOfTickets={action.payload.numberOfTickets}
            cost={action.payload.cost}
            handleBonusPoints={action.payload.handleBonusPoints}
            setNumber={action.payload.setNumber}
            status={action.payload.status}
          />
        );
      case ACTIONS.GIFT_DETAILS:
        setIsPopup(true);
        return (
          <PopupDetailsGiftShop
            header={action.payload.header}
            gift={action.payload.giftshop}
            handleBonusPoints={action.payload.handleBonusPoints}
            cost={action.payload.cost}
          />
        );
      case ACTIONS.DELETE:
        setIsPopup(true);
        return (
          <PopupDelete
            action={action.payload.action}
            type={action.payload.type}
            elementName={action.payload.elementName}
            onSuccess={action.payload.onSuccess}
            redirectOnSucces={action.payload.redirectOnSucces}
          />
        );
      case ACTIONS.CONFIRM:
        setIsPopup(true);
        return (
          <ConfirmPopup
            header={action.payload.header}
            content={action.payload.content}
            action={action.payload.action}
          />
        );
      case ACTIONS.POST_CONFIRM:
        setIsPopup(true);
        return (
          <PostConfirmPopup
            header={action.payload.header}
            content={action.payload.content}
            action={action.payload.action}
          />
        );
      case ACTIONS.MFA_CONFIRM:
        setIsPopup(true);
        return (
          <MfaConfirmPopup
            qrCode={action.payload.qrCode}
          />
        );
      case ACTIONS.FORM:
        setIsPopup(true);
        return (
          <FormPopup
            header={action.payload.header}
            fields={action.payload.fields}
            onSave={action.payload.onSave}
            postAction={action.payload.postAction}
            variant={action.payload.variant}
          />
        );
      case ACTIONS.SELECT:
        setIsPopup(true);
        setStyle({ maxWidth: 880 });

        return (
          <FilterContextProvider>
            <SelectPopup
              header={action.payload.header}
              filters={action.payload.filters}
              singleSelect={action.payload.singleSelect}
              items={action.payload.items}
              RenderItem={action.payload.RenderItem}
              onSave={action.payload.onSave}
              postAction={action.payload.postAction}
            />
          </FilterContextProvider>
        );
      case ACTIONS.SEARCH_BACKEND_USERS:
        setIsPopup(true);
        setIsDataActive(false);
        setStyle({ maxWidth: 880 });
        return (
          <SearchBackendUsersPopup
            header={action.payload.header}
            singleSelect={action.payload.singleSelect}
            postAction={action.payload.postAction}
            excludeMyself={action.payload.excludeMyself}
            setIsDataActive={setIsDataActive}
          />
        );
      case ACTIONS.FILE:
        setIsPopup(true);
        return (
          <FilePopup
            header={action.payload.header}
            courses={action.payload.courses}
            learningEvents={action.payload.learningEvents}
            regulatoryIntelligence={action.payload.regulatoryIntelligence}
          />
        );
      case ACTIONS.NOTIFICATION:
        setIsPopup(true);
        return (
          <PopupNotifications
            header={action.payload.header}
            data={action.payload.data}
          />
        );
      case ACTIONS.REPORT_PROBLEM:
        setIsPopup(true);
        setStyle({ background: "#f7f8f9", padding: "28px 30px 40px 30px" });
        return (
          <ReportProblemPopup />
        );
      case ACTIONS.LOADING:
        setIsPopup(true);
        return <></>;
      case ACTIONS.REMOVE:
        setIsPopup(false);
        setStyle({});
        return (state = null);
      default:
        return state;
    }
  };

  const [popup, callPopup] = useReducer(reducer, null);

  // TODO: Remove FilterContextProvider and add it only to the Popups, that need it
  return (
    <PopupsContext.Provider value={{ callPopup, setIsDataActive }}>
      {
        <FilterContextProvider>
          <PopupOpacityAnimation trigger={isPopup}>
            <PopupWrapper
              trigger={isPopup}
              style={style}
              isDataActive={isDataActive}
            >
              {popup}
            </PopupWrapper>
          </PopupOpacityAnimation>
        </FilterContextProvider>
      }
      {children}
    </PopupsContext.Provider>
  );
};
