import Text from "../../atoms/Text/Text";
import styles from "./ListItem.module.scss";
import Button from "../../atoms/Button/Button";
import { useContext } from "react";
import { FilterContext } from "../../../contexts/FilterContext";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import { languages } from "../../../dummyData/dummyData";

const ListItem = ({ selectableId, title, subtitle, image, imageFit, bar, buttons, inactive, children }) => {
  const { useSelectables } = useContext(FilterContext);
  const { selected, toggleSelect } = useSelectables;
  const isSelected = selected.includes(selectableId);
  const componentAsImage = typeof image !== 'string';

  return (
    <div
      className={`${styles.listItem} ${inactive ? styles.inactive : ""} ${!!selectableId ? styles.selectable : ""}`}
      data-selected={isSelected}
      onClick={() => {
        if (!!selectableId) {
          toggleSelect(selectableId);
        }
      }}
    >
      <div className={styles.listItem__img} data-imagefit={`${imageFit ?? true}`} data-component={componentAsImage}>
        {!componentAsImage ? <img src={image} alt="" /> : image}
        {inactive && <div className={styles.listItem__imgInactiveCover}>
          <Text s32 stroke w800>
            {languages.EN.labels.inactive.toUpperCase()}
          </Text>
        </div>}
      </div>
      <span className={styles.listItem__name}>
        <TextTruncated lineClamp={subtitle ? 2 : 3} secondary>
          <Text s20 lh32 w800>
            {title ?? languages.EN.placeholders.empty}
          </Text>
        </TextTruncated>
        {subtitle && (
          <TextTruncated lineClamp={1}>
            <Text s12 lh24 w400 dark-6>
              {subtitle}
            </Text>
          </TextTruncated>
        )}
      </span>
      {children}
      <div className={styles.listItem__buttons}>
        {buttons && (
          <div>
            {buttons.map((button) => (
              <Button
                variant={button.statusVariant === undefined ? "iconButton" : (button.statusVariant ? "iconButtonOn" : "iconButtonOff")}
                onlyIcon
                icon={button.icon}
                key={button.icon}
                onClick={(event) => {
                  event.stopPropagation();
                  button.onClick();
                }}
                disabled={button.disabled}
                tooltip={button.tooltip}
              />
            ))}
          </div>
        )}
        {!!selectableId && (
          <div
            onClick={() => toggleSelect(selectableId)}
            className={`${styles.listItem__dot} ${isSelected && styles.listItem__dotSelected}`}
          />
        )}
      </div>
      {bar && (
        <div className={styles.listItem__bar}>
          {bar}
        </div>
      )}
    </div>
  );
};

export default ListItem;
