import useFetch from "../../../hooks/useFetch";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { referencesRepository } from "../../../repository/references.repository";
import ReferenceAuditTemplate from "../../templates/ReferenceAuditTemplate/ReferenceAuditTemplate";
import { PostContext } from "../../../contexts/PostContext";

const ReferenceAudit = ({ match }) => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(referencesRepository.getReference(match.params.reference));
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <ReferenceAuditTemplate data={data} auditId={parseInt(match.params.audit)} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default ReferenceAudit;
