import styles from "./ReferencesList.module.scss";
import Html from "../../atoms/Html/Html";
import { getReferenceName } from "../../../utils/referenceNameHelper";
import { languages } from "../../../dummyData/dummyData";
import React, { useMemo, useState } from "react";
import Text from "../../atoms/Text/Text";

const tagsColors = {
  [languages.EN.enums.referenceTypes[0]]: "#08b60f",
  [languages.EN.enums.referenceTypes[1]]: "#257824",
  [languages.EN.enums.referenceTypes[2]]: "#4cccd5",
  [languages.EN.enums.referenceTypes[3]]: "#393eb4",
  [languages.EN.enums.referenceTypes[4]]: "#af3df4",
  [languages.EN.enums.referenceTypes[5]]: "#ce4cc1",
  [languages.EN.enums.referenceTypes[6]]: "#cc5d64",
  [languages.EN.enums.referenceTypes[7]]: "#8c4110",
  [languages.EN.labels.inCurrentUnit]: "#ca3131",
  [languages.EN.labels.usefulDocument]: "#f27f2a",
  [languages.EN.labels.link]: "#deb522",
};

const ReferencesList = ({ references, activeReferences }) => {
  const [activeTags, setActiveTags] = useState([]);

  const preparedData = useMemo(() => references.map(item => {
    const isActive = activeReferences.includes(item.document.id.toString());

    return {
      ...item,
      active: isActive,
      tags: [
        languages.EN.enums.referenceTypes[item.document.type],
        item.useful_doc ? languages.EN.labels.usefulDocument : null,
        item.is_link ? languages.EN.labels.link : null,
        isActive ? languages.EN.labels.inCurrentUnit : null,
      ]
    };
  }), [activeReferences]);

  return (
    <div className={styles.references}>
      <Text w800 s14 dark-4>{languages.EN.labels.fastFilters}: </Text>
      <ul className={styles.references__tags}>
        {Object.entries(tagsColors).map(([tag, color]) => (
          <li key={tag} style={{ ['--tag-color']: color }} data-active={activeTags.includes(tag)} data-clickable={true} onClick={(event) => {
            const clickedTag = event.target.innerText;
            setActiveTags(activeTags.includes(clickedTag) ? activeTags.filter(tag => tag !== clickedTag) : [...activeTags, clickedTag]);
          }}>
            <span>{tag}</span>
          </li>
        ))}
      </ul>
      <ol>
        {preparedData.length > 0 ?
          preparedData
            .sort((a, b) => a.reference_id - b.reference_id)
            .map((item, i) => (
              <li key={i} value={item.reference_id} className={activeTags.filter(tag => item.tags.includes(tag)).length ? styles.active : ''}>
                <Html>{getReferenceName(item.document)}</Html>
                <ul className={styles.references__tags}>
                  {item.tags
                    .filter(tag => tag !== null)
                    .map(tag => (
                      <li key={tag} style={{ ['--tag-color']: tagsColors[tag] }} data-active={activeTags.includes(tag)}><span>{tag}</span></li>
                    ))}
                </ul>
              </li>
            )) :
          languages.EN.labels.noData
        }
      </ol>
    </div>
  );
};

export default ReferencesList
