import CourseDetailsTemplate from "../../templates/CourseDetailsTemplate/CourseDetailsTemplate";
import useFetch from "../../../hooks/useFetch";
import { coursesRepository } from "../../../repository/courses.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { PostContext } from "../../../contexts/PostContext";

const CourseDetails = ({ match }) => {
  const { data, fetchData, resetData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(coursesRepository.getCourseDetails(match.params.id));
    }
  }, [isResponseOk]);

  // Handle picking search result
  useEffect(() => {
    if (data) {
      resetData();
      fetchData(coursesRepository.getCourseDetails(match.params.id));
    }
  }, [match.params.id]);

  return data ? (
    <FilterContextProvider>
      <CourseDetailsTemplate data={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default CourseDetails;
