import styles from "./MemberTeamInfo.module.scss";
import Button from "../../atoms/Button/Button";
import React from "react";
import { Routes } from "../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { languages } from "../../../dummyData/dummyData";

const MemberTeamInfo = ({ team }) => {
  const history = useHistory();

  return (
    <span className={styles.info}>
      <span>
        {`${team.name} ${team.is_manager ? ` (${languages.EN.labels.manager})` : ''}`}
        <Button
          icon="edit"
          onlyIcon
          variant="iconButton"
          onClick={() => {
            history.push(generatePath(Routes.team.base, { id: team.id }))
          }}
          tooltip={languages.EN.labels.edit}
        />
      </span>
    </span>
  );
};

export default MemberTeamInfo
