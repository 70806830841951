import { certificates_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader } from "./repositoryHelper";

const downloadCertificate = (username, course_id) => {
  return fetch(`${certificates_endpoint}download/${username}/${course_id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.download = `certificate_${course_id}.pdf`;
      link.href = URL.createObjectURL(new Blob([blob]));
      link.click();
    });
};

const downloadCertificates = (query) => {
  fetch(`${certificates_endpoint}bulk_download/?ids=${query}`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((data) => {
      const link = document.createElement("a");
      link.download = `"certificates"_${query}.zip`;
      link.href = window.URL.createObjectURL(data);
      link.click();
    });
};


export const certificatesRepository = {
  downloadCertificate,
  downloadCertificates,
};
