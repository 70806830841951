import React from "react";
import styles from "./Statistic.module.scss";
import Text from "../../atoms/Text/Text";
import Html from "../../atoms/Html/Html";
import Button from "../../atoms/Button/Button";
import { useHistory } from "react-router-dom";
import { languages } from "../../../dummyData/dummyData";
import Icons from "../../../assets/icons";

const getTrendIcon = (value, previousValue) => {
  if (value > previousValue) {
    return Icons.trendUp;
  } else if (value < previousValue) {
    return Icons.trendDown;
  } else {
    return Icons.trendConst;
  }
}

const Statistic = ({ content, description, value, previousValue, formatter, icon, link }) => {
  const history = useHistory();

  return (
    <div className={styles.statistic}>
      <div className={styles.statistic__icon}>
        <img src={icon} alt="" />
      </div>
      <div className={styles.statistic__content}>
        <div className={styles.statistic__values}>
          <Text s48 w800 lh64 secondary>
            {formatter ? formatter(value) : value}
          </Text>
          {previousValue !== undefined && (
            <div className={styles.statistic__values__trend}>
              {getTrendIcon(value, previousValue)}
              <Text s12 lh16>
                ({formatter ? formatter(previousValue) : previousValue} {languages.EN.labels.previously})
              </Text>
            </div>
          )}
        </div>
        <div className={styles.statistic__header}>
          <Html s20 lh24>
            {content}
          </Html>
          {link && (
            <Button
              onClick={() => history.push(link)}
              variant="iconButton"
              onlyIcon
              icon="dots"
              tooltip={languages.EN.labels.details}
            />
          )}
        </div>
        {description ? (
          <div className={styles.statistic__description}>
            <Html s15 w400 lh18 dark-6>
              {description}
            </Html>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Statistic;
