import LearningReportTemplate from "../../templates/LearningReportTemplate/LearningReportTemplate";
import useFetch from "../../../hooks/useFetch";
import { learningReportRepository } from "../../../repository/learningReport.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";

const isValidData = (data) => data.competence_reports.length > 0 && data.course_reports.length > 0 && data.instructor_reports.length > 0 && data.rivalisation_reports.length > 0;

const LearningReport = ({ match }) => {
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const fetchLearningReport = useFetch();
  useEffect(() => {
    fetchLearningReport.fetchData(learningReportRepository.getLearningReport({
      context_type: match.params.type,
      context_id: match.params.id,
      ...(match.params.date ? {date: match.params.date} : {})
    }));
  }, [match.params.type, match.params.id, match.params.date]);

  useEffect(() => {
    if (fetchLearningReport.isReady && !isValidData(fetchLearningReport.data)) {
      if (match.params.date !== undefined) {
        history.push(generatePath(Routes.learningReport.competences.base, { type: match.params.type, id: match.params.id }));
      } else {
        history.go(-1);
      }

      callPopup({
        type: ACTIONS.INFORMATION,
        payload: {
          header: languages.EN.labels.information,
          content: languages.EN.messages.dataDoesNotExist,
        },
      });
    }
  }, [fetchLearningReport.data]);


  const fetchLearningReportContext = useFetch();
  useEffect(() => {
    fetchLearningReportContext.fetchData(learningReportRepository.getLearningReportContext());
  }, []);

  useDocumentTitleLoading();

  return fetchLearningReport.data && !fetchLearningReport.loading && isValidData(fetchLearningReport.data) && fetchLearningReportContext.data ? (
    <FilterContextProvider>
      <LearningReportTemplate
        data={fetchLearningReport.data}
        contexts={fetchLearningReportContext.data.contexts}
      />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default LearningReport;
