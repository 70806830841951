import CoursesTemplate from "../../templates/CoursesTemplate/CoursesTemplate";
import useFetch from "../../../hooks/useFetch";
import { coursesRepository } from "../../../repository/courses.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { PostContext } from "../../../contexts/PostContext";

const Courses = () => {
  const { data, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(coursesRepository.getCourses());
    }
  }, [isResponseOk]);

  return data ? (
    <FilterContextProvider>
      <CoursesTemplate data={data} isMine={false} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default Courses;
