import Text from "../../atoms/Text/Text";
import styles from "./HeaderWithButtons.module.scss";
import Button from "../../atoms/Button/Button";

const HeaderWithButtons = ({ header, buttons }) => {
  return (
    <div className={styles.headerWithButtons}>
      <Text s24 w800 secondary>
        {header}
      </Text>
      <div className={styles.headerWithButtons__buttons}>
        {buttons !== undefined ? buttons.map((button) => (
          <Button
            variant={button.variant ?? "primaryW100"}
            icon={button.icon}
            disabled={button.disabled}
            withIcon={!button.disabled}
            onClick={button.onClick}
            tooltip={button.tooltip}
            key={button.label}
            useIconsFile={true}
          >
            {button.label}
          </Button>
        )) : ''}
      </div>
    </div>
  );
};

export default HeaderWithButtons;
