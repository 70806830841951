import { useState } from "react";
import Text from "../Text/Text";
import styles from "./DateSelect.module.scss";
import Icons from "../../../assets/icons";
import OutsideClickHandler from "react-outside-click-handler";

const DateSelect = ({ items, setOurAnswer, currName }) => {
  const [isActive, setIsActive] = useState(false);
  const [displayName, setDisplayName] = useState(currName);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsActive(false)}>
      <div onClick={() => setIsActive(!isActive)} className={styles.select}>
        <Text s14 w400>
          {displayName}
        </Text>

        <div className={styles.sortArrow}>
          {isActive ? Icons.topArrowSortPanel : Icons.bottomArrowSortPanel}
        </div>

        {isActive && (
          <div className={styles.table}>
            {items.map((item, idx) => (
              <div
                key={idx}
                onClick={() => {
                  setDisplayName(item.name);
                  setIsActive(false);
                  setOurAnswer(item);
                }}
              >
                <Text s14 w400 dark-4>
                  {item.name}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default DateSelect;
