import { useEffect, useState } from "react";
import styles from "./PrivacySettingsTable.module.scss";
import Text from "../../atoms/Text/Text";
import RadioButton from "../../atoms/RadioButton/RadioButton";
export const PrivacySettingsTableRow = ({ dataSetting, handleSetFormData }) => {
  const { name, id, choices, value } = dataSetting;

  const [currentValue, setCurrentValue] = useState(value);
  const [showManager, setShowManager] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  const [showPublic, setShowPublic] = useState(false);
  const [isChecked, setIsChecked] = useState({
    manager: currentValue === 0 ? true : false,
    team: currentValue === 1 ? true : false,
    organization: currentValue === 2 ? true : false,
    public: currentValue === 3 ? true : false,
  });

  useEffect(() => {
    choices.forEach((item) => {
      if (item === 0) {
        setShowManager(true);
      } else if (item === 1) {
        setShowTeam(true);
      } else if (item === 2) {
        setShowOrganization(true);
      } else if (item === 3) {
        setShowPublic(true);
      }
    });
  }, [choices]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleOnChange = (e) => {
    setCurrentValue(Number(e.target.value));
  };

  useEffect(() => {
    setIsChecked({
      manager: currentValue === 0 ? true : false,
      team: currentValue === 1 ? true : false,
      organization: currentValue === 2 ? true : false,
      public: currentValue === 3 ? true : false,
    });
    handleSetFormData(id, currentValue);
  }, [currentValue, handleSetFormData, id]);

  return (
    <div className={styles.headerWrapper}>
      <div>
        <Text dark-6 s12>
          {name}
        </Text>
      </div>
      <div className={styles.tableRow}>
        {showManager && (
          <RadioButton
            value={0}
            checked={isChecked.manager}
            variant="primary"
            handleChange={handleOnChange}
          />
        )}
      </div>
      <div className={styles.tableRow}>
        {showTeam && (
          <RadioButton
            value={1}
            checked={isChecked.team}
            variant="primary"
            handleChange={handleOnChange}
          />
        )}
      </div>
      <div className={styles.tableRow}>
        {showOrganization && (
          <RadioButton
            value={2}
            checked={isChecked.organization}
            variant="primary"
            handleChange={handleOnChange}
          />
        )}
      </div>
      <div className={styles.tableRow}>
        {showPublic && (
          <RadioButton
            value={3}
            checked={isChecked.public}
            variant="primary"
            handleChange={handleOnChange}
          />
        )}
      </div>
    </div>
  );
};
