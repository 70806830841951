import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import ListItem from "../ListItem/ListItem";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { popupFields } from "../../templates/CompetencesTemplate/CompetencesTemplate";
import { popupFields as levelPopupFields } from "../../templates/CompetenceDetailsTemplate/CompetenceDetailsTemplate";
import { useContext, useEffect } from "react";
import { competencesRepository } from "../../../repository/competences.repository";
import useFetch from "../../../hooks/useFetch";

const CompetencesItem = ({ data }) => {
  const { id, profile_image_small, name, country, topic, group, num_org_members, num_team_members, competence_levels, visibility, visibilityImg, active, canEdit} = data;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  let history = useHistory();

  const getLastLevel = () => competence_levels.length > 0 ? competence_levels[Object.keys(competence_levels)[Object.keys(competence_levels).length - 1]].level : 0;

  const fetchGroups = useFetch();
  useEffect(() => {
    if (fetchGroups.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.competence}`,
          fields: popupFields(fetchGroups.data, data),
          postAction: (formData) => competencesRepository.editCompetence(id, formData),
        },
      });
    }
  }, [fetchGroups.isReady]);

  return (
    <ListItem title={name} subtitle={`${languages.EN.labels.region}: ${country}`} image={profile_image_small} inactive={!active} buttons={(canEdit ? [
      {
        icon: "edit",
        onClick: () => {
          setIsDataActive(false);
          fetchGroups.fetchData(competencesRepository.getCompetenceGroups());
          callPopup({ type: ACTIONS.LOADING });
        },
        tooltip: languages.EN.labels.edit,
      },
      {
        icon: "plus",
        onClick: () => {
          callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.competenceLevel}`,
              fields: levelPopupFields({ name: name, level: getLastLevel() + 1 }),
              postAction: (formData) => competencesRepository.addCompetenceLevel({ competence_id: id, ...formData })
            },
          });
        },
        tooltip: `${languages.EN.labels.add} ${languages.EN.labels.competenceLevel}`,
      },
    ] : []).concat(competence_levels.length > 0 ? [
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.competenceDetails.jobPositions.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ] : [])}>
      <ListItemInfo value={languages.EN.enums.learningAreas[topic]} />
      <ListItemInfo value={group.name} />
      <ListItemInfo value={num_org_members} />
      <ListItemInfo value={num_team_members} />
      <ListItemImgInfo value={visibility} image={visibilityImg} />
    </ListItem>
  );
};

export default CompetencesItem;
