import { matchPath, useLocation } from 'react-router-dom';

export const useCurrentRoute = (routeRoot) => {
  const location = useLocation();

  return Object.values(routeRoot).find(route =>
    (typeof route === "string" && location.pathname === route) ||
    (typeof route === "object" && route.base !== undefined && matchPath(location.pathname, { path: route.base }))
  );
};
