import React, { useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import { Routes } from "../../../routes";
import CompetenceLevel from "../CompetenceLevel/CompetenceLevel";
import { useHistory } from "react-router-dom";
import CompetenceLevelTile from "../CompetenceLevelTile/CompetenceLevelTile";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "experienceLevel",
    header: languages.EN.labels.level,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 5,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
  {
    keyPath: "competence.group.name",
    header: languages.EN.labels.group,
    typeOfFilter: "checkbox",
    sortId: 4,
  },
];

const CompetenceLevelsList = ({ data, isMine, withManagement, username }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.competence.organization !== null ? 2 : 3;

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.competence.topic],
      experienceLevel: languages.EN.enums.experienceLevels[item.level],
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.competence.organization.profile_image_small : <AidifyLogo />,
    };
  })), [data]);

  const RenderListComponent = ({ data }) => (
    <CompetenceLevel data={data} isMine={isMine} withManagement={withManagement} username={username} />
  );

  const RenderTileComponent = ({ data }) => (
    <CompetenceLevelTile data={data} isMine={isMine} withManagement={withManagement} username={username} />
  );

  let history = useHistory();

  return (
    <FilteredList
      id={"competencesLevels"}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={RenderListComponent}
      RenderTileComponent={RenderTileComponent}
      buttons={isMine ? [
        {
          icon: "edit",
          label: languages.EN.labels.edit,
          onClick: () => history.push(Routes.studentCompetences.base)
        }
      ] : []}
    />
  );
};

export default CompetenceLevelsList;
