import styles from "./LandingTemplate.module.scss";
import logo from "../../../../assets/aidify-logo.svg";
import posterPark from "../../../../assets/parp-poster.pdf";
import logosParp from "../../../../assets/parp-logos.png";
import SideImage from "../../../molecules/SideImage/SideImage";
import Text from "../../../atoms/Text/Text";
import React, { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";

const LandingTemplate = ({ header, description, children }) => {
  const { handleSwitchHomepage } = useContext(UserContext);

  return (
    <div className={styles.signIn}>
      <div className={styles.formWrapper}>
        <div className={styles.formWrapper__logo}>
          <img onClick={() => handleSwitchHomepage()} src={logo} alt="Aidify Logo" />
        </div>
        <div className={styles.formWrapper__box}>
          <span className={styles.formWrapper__title}>
            <Text s40 lh64 w800 secondary>{header}</Text>
          </span>
          {description && (
            <span className={styles.formWrapper__description}>
              <Text s12 lh14 w600 dark-2>{description}</Text>
            </span>
          )}
          {children}
        </div>
        <div className={styles.formWrapper__parpLogos}>
          <a href={posterPark} rel="noreferrer" target="_blank"><img src={logosParp} alt="" /></a>
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <SideImage />
      </div>
    </div>
  );
};

export default LandingTemplate
