import styles from "./IdpLearningPlanEdit.module.scss";
import IdpLeraningPlanTableEdit from "../Table/IdpLearningPlanTable/IdpLearningPlanTableEdit";
import Button from "../../atoms/Button/Button";
import Text from "../../atoms/Text/Text";
import React, { useContext, useEffect, useState } from "react";
import Input from "../../atoms/Input/Input";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { idpRepository } from "../../../repository/idp.repository";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";
import { dateFormat } from "../../../utils/formatters";
import CoursesList from "../CoursesList/CoursesList";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { isValidDateString } from "../../../utils/dateHelpers";
import CalendarInput from "../../atoms/CalendarInput/CalendarInput";
import IncrementInput from "../../atoms/IncrementInput/IncrementInput";
import moment from "moment";
import { PostContext } from "../../../contexts/PostContext";
import { MODE_EDIT } from "../../pages/IdpEdit/IdpEdit";
import { useDidUpdateEffect } from "../../../hooks/useDidUpdateEffect";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { Loader, LoaderOnly } from "../../atoms/Loader/Loader";

export const MINUTES_IN_DAY = 24 * 60;
export const DATE_FORMAT = 'YYYY-MM-DD';

const calculateTableData = (tableData, formData, courses, changedValues = {}) => {
  const { name, value, i } = changedValues;
  const { start_date, daily_pledge, absence_days } = formData;

  let planMinutesSum = 0;
  let lastFixedDate = start_date;

  return [...tableData].map((item, j) => {
    if (i === j) {
      if (name === "planStart" && isValidDateString(value)) {
        const valueDate = addMinutes(value, 0, daily_pledge, absence_days);
        const minDate = addMinutes(lastFixedDate, planMinutesSum, daily_pledge, absence_days);

        if ((new Date(valueDate)) > (new Date(minDate))) {
          item.planStartOverride = valueDate;
        } else {
          item.planStartOverride = undefined;
        }
      }

      if (name === "planMinutes") {
        if (!isNaN(parseInt(value))) {
          item.planMinutes = parseInt(value);
        } else if (value === "") {
          item.planMinutes = value;
        }
      }
    }

    if (item.planStartOverride !== undefined) {
      const overrideDate = addMinutes(item.planStartOverride, 0, daily_pledge, absence_days);
      const minDate = addMinutes(lastFixedDate, planMinutesSum, daily_pledge, absence_days);

      if ((new Date(overrideDate)) > (new Date(minDate))) {
        lastFixedDate = item.planStartOverride;
        planMinutesSum = 0;
      } else {
        item.planStartOverride = undefined;
      }
    }

    const calculatedItem = calculateItem({
      ...item,
      planStart: addMinutes(lastFixedDate, planMinutesSum, daily_pledge, absence_days),
      planEnd: addMinutes(lastFixedDate, planMinutesSum + Math.max(item.planMinutes, 1) - 1, daily_pledge, absence_days)
    }, courses, daily_pledge);

    planMinutesSum += (item.planMinutes !== "" ? parseInt(calculatedItem.planMinutes) : 0);

    return calculatedItem;
  });
}

const calculateItem = (item, courses, daily_pledge) => {
  const course = courses.reduce((acc, curr) => curr.id === item.id ? curr : acc);

  return {
    id: course.id,
    courseName: course.display_name,
    planStart: item.planStart,
    planStartOverride: item.planStartOverride,
    planMinutes: item.planMinutes,
    recMinutes: Math.round(course.duration / 60),
    planDays: (!!item.planMinutes ? item.planMinutes : 0) / daily_pledge,
    planEnd: item.planEnd,
  };
};

/**
 * Separate adding days and minutes is needed because of time changes
 */
export const addMinutes = (start, minutes, daily_pledge, absence_days) => {
  let wholeDays = Math.floor(minutes / daily_pledge);
  const restMinutes = minutes - wholeDays * daily_pledge;

  let calculatedDate = moment(start, DATE_FORMAT);
  while (absence_days.includes(calculatedDate.format(DATE_FORMAT))) {
    calculatedDate = calculatedDate.add(1, 'days');
  }
  while (wholeDays > 0) {
    calculatedDate = calculatedDate.add(1, 'days');
    if (!absence_days.includes(calculatedDate.format(DATE_FORMAT))) {
      wholeDays--;
    }
  }

  return calculatedDate.add(restMinutes, 'minutes').format(DATE_FORMAT);
};

const parseBackendData = (tableData, formData) => {
  const { start_date, daily_pledge, absence_days } = formData;

  let planMinutesSum = 0;
  let lastFixedDate = start_date;

  return tableData.map(item => {
    const valueDate = addMinutes(item.start_date, 0, daily_pledge, absence_days);
    const minDate = addMinutes(lastFixedDate, planMinutesSum, daily_pledge, absence_days);

    if ((new Date(valueDate)) > (new Date(minDate))) {
      item.planStartOverride = valueDate;
      lastFixedDate = item.planStartOverride;
      planMinutesSum = 0;
    }

    planMinutesSum += item.planned_duration;

    return parseBackendItem(item, daily_pledge);
  });
}

const parseBackendItem = (item, daily_pledge) => {
  return {
    id: item.course_id,
    courseName: item.name,
    planStart: item.start_date,
    planStartOverride: item.planStartOverride,
    planMinutes: Math.round(item.planned_duration / 60),
    recMinutes: Math.round(item.duration / 60),
    planDays: item.planned_duration / 60 / daily_pledge,
    planEnd: item.end_date,
  };
};

const IdpLearningPlanEdit = ({ idp, courses, params }) => {
  const [formData, setFormData] = useState({
    user: idp.user_profile.user.username,
    idp: params.id,
    start_date: idp.learning_plan[0].start_date,
    daily_pledge: idp.learning_plan[0].daily_pledge,
    absence_days: !!idp.learning_plan[0].absence_days ? idp.learning_plan[0].absence_days.split(",") : [],
  });

  const [stats, setStats] = useState({
    studyDays: 0,
    freeDays: formData.absence_days.length,
    endDate: formData.end_date ? dateFormat(formData.end_date) : "",
  });
  const [tableData, setTableData] = useState(params.mode === MODE_EDIT ? parseBackendData(
    idp.learning_plan_items,
    idp.learning_plan[0],
  ) : calculateTableData(
    idp.learning_plan_items.map((item) => ({ id: item.course_id, planMinutes: Math.round(item.duration / 60) })),
    idp.learning_plan[0],
    courses,
  ));
  const [courseIds, setCourseIds] = useState([]);
  const [addMore, setAddMore] = useState(true);
  const [isSendingForApproval, setIsSendingForApproval] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { postData } = useContext(PostContext);
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  useDidUpdateEffect(() => {
    setTableData(calculateTableData(tableData, formData, courses));
  }, [formData.start_date, formData.daily_pledge, formData.absence_days]);

  useEffect(() => {
    setStats({
      freeDays: formData.absence_days.length,
      ...tableData.reduce((acc, curr) => ({
        studyDays: (acc.studyDays ?? 0) + curr.planDays,
        endDate: curr.planEnd,
      }), {})
    });
    setCourseIds(tableData.map(data => data.id));
  }, [tableData]);

  const handleDefault = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectFreeDaysInCalendar = (date, { selected }) => {
    date = date.toISOString().slice(0, 10);

    setFormData({
      ...formData,
      absence_days: selected ?
        [...formData.absence_days.filter(day => day !== date)] :
        [...formData.absence_days, date]
    });
  };

  const handleItemsInput = (e, i) => {
    setTableData(calculateTableData(tableData, formData, courses, { name: e.target.name, value: e.target.value, i: i }));
  };

  const handleDrag = (e) => {
    if (e.destination) {
      const reducedTableData = [...tableData.slice(0, e.source.index), ...tableData.slice(e.source.index + 1)];
      const newTableData = [...reducedTableData.slice(0, e.destination.index), tableData[e.source.index], ...reducedTableData.slice(e.destination.index)];

      setTableData(calculateTableData(newTableData, formData, courses));
    }
  };

  const handleDelete = (i) => {
    setTableData(calculateTableData([...tableData.slice(0, i), ...tableData.slice(i + 1)], formData, courses));
  }

  const saveLearningPlan = () => idpRepository.editLearningPlan({ ...formData, absence_days: formData.absence_days.join(','), items: tableData.map(item => ({
    course: item.id,
    duration: item.recMinutes * 60,
    planned_duration: (!!item.planMinutes ? item.planMinutes : 0) * 60,
    start_date: item.planStart,
    end_date: item.planEnd,
  }))});

  const finishEdit = () => {
    setIsSendingForApproval(true);
    postData(
      () => idpRepository.editIdp(idp.user_profile.user.username, { idp_id: params.id, status: 1 }),
      () => {
        callPopup({ type: ACTIONS.REMOVE })
        history.push(generatePath(Routes.idpDetails.learningPlan.base, { username: params.username, id: params.id }))
      }
    );
  }

  return !isSendingForApproval ? (
    <>
      <div className={styles.wrapper}>
        <div className={styles.barWrapperLeft}>
          <CalendarInput
            name="start_date"
            value={formData.start_date && isValidDateString(formData.start_date) ? dateFormat(formData.start_date) : formData.start_date ?? ""}
            handleInput={handleDefault}
            placeholder={languages.EN.labels.startOfLearning}
          />
          <IncrementInput
            name="daily_pledge"
            value={formData.daily_pledge}
            handleInput={handleDefault}
            placeholder={languages.EN.labels.minutesPerDay}
            min={1}
            max={MINUTES_IN_DAY}
          />
          {/*<SelectMultiple*/}
          {/*  placeholder={languages.EN.labels.selectFreeDays}*/}
          {/*  items={languages.EN.enums.weekDays}*/}
          {/*  value={freeWeekDays}*/}
          {/*  handleSelect={setFreeWeekDays}*/}
          {/*/>*/}
        </div>
        <div className={styles.datePickerWrapper}>
          <div className={styles.label}>{languages.EN.labels.selectFreeDaysInCalendar}</div>
          <DayPicker
            showOutsideDays
            selectedDays={formData.absence_days.map((date) => new Date(date))}
            onDayClick={handleSelectFreeDaysInCalendar}
            month={new Date(formData.start_date)}
            disabledDays={[
              { before: new Date(formData.start_date) },
              { after: new Date(stats.endDate) },
            ]}
          />
        </div>
        <div className={styles.barWrapperRight}>
          <Input
            name="study_days"
            placeholder={languages.EN.labels.studyDays}
            variant="number"
            value={(stats.studyDays ?? 0).toFixed(2)}
            disabled={true}
          />
          <Input
            name="free_days"
            placeholder={languages.EN.labels.freeDays}
            variant="number"
            value={stats.freeDays}
            disabled={true}
          />
          <Input
            name="end_date"
            placeholder={languages.EN.labels.endDayOfLearning}
            variant="text"
            value={stats.endDate ?? ""}
            disabled={true}
          />
        </div>
        <div className={styles.table}>
          <IdpLeraningPlanTableEdit data={tableData} handleInput={handleItemsInput} handleDrag={handleDrag} handleDelete={handleDelete} />
          <div className={styles.buttons}>
            {!isSaving ? (
              <Button variant='primary' withIcon useIconsFile icon={'checkMark'} onClick={() => {
                setIsSaving(true);
                postData(saveLearningPlan, () => {
                  setIsSaving(false);
                })
              }}>
                {languages.EN.labels.save}
              </Button>
            ) : <LoaderOnly />}
            <Button variant='primary' withIcon useIconsFile icon='send2' onClick={() => callPopup({
              type: ACTIONS.CONFIRM,
              payload: {
                header: languages.EN.labels.saveAndSendForApproval,
                content: languages.EN.messages.areYouSureYouWantToSendLearningPlan,
                action: () => postData(saveLearningPlan, finishEdit),
              },
            })}>
              {languages.EN.labels.saveAndSendForApproval}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.moreWrapper}>
        <div className={styles.moreWrapper__innerWrapper}>
          <span className={styles.iconWrapper}>
            <Text s20 lh24 w800 secondary>
              {languages.EN.labels.addMore}
            </Text>
            <Button
              variant="strokeIcon"
              onlyIcon
              icon={addMore ? "topArrow" : "bottomArrow"}
              onClick={() => setAddMore(!addMore)}
            />
          </span>
          {addMore && (
            <FilterContextProvider>
              <CoursesList
                data={courses.filter(course => !courseIds.includes(course.id) && course.user_progress !== 1)}
                onAddSelected={(selectedTable) => {
                  setTableData(calculateTableData([
                    ...tableData,
                    ...selectedTable.map((item) => ({ id: item.id, planMinutes: item.duration }))
                  ], formData, courses));
                }}
                jobPositionId={idp.job_position.id}
              />
            </FilterContextProvider>
          )}
        </div>
      </div>
    </>
  ) : <Loader />;
};

export default IdpLearningPlanEdit;
