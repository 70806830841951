import styles from "./LegendItem.module.scss";

const LegendItem = ({ name, color }) => {
  return (
    <div className={styles.Wrapper}>
      <div
        className={styles.LegendSquare}
        style={{ backgroundColor: color }}
      ></div>
      <p className={styles.LegendText}>{name}</p>
    </div>
  );
};

export default LegendItem;
