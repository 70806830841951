import { useState, useEffect, useRef } from "react";
import { languages } from "../../../../dummyData/dummyData";
import {
  handleAddCriterrium,
  handleRemoveCriterrium,
} from "../../../../utils/FiltersMethods/handleCriterriums";
import styles from "./NumberInput.module.scss";

const { from } = languages.EN.labels;

const NumberInput = ({
  placeholder,
  min,
  max,
  setCurrentRange,
  currentRange,
  keyPath,
  setIsSliderChanged,
  setCriterriums,
}) => {
  const [value, setValue] = useState(placeholder === from ? min : max);
  const [isFocused, setIsFocused] = useState(false);
  const prevValue = useRef(value);

  const handleOnBlur = () => {
    setIsFocused(false);
    const currentValue = value;
    let arr = [...currentRange];
    if (placeholder === from) {
      if (+value <= +arr[1]) {
        if (+value <= max && +value >= min) {
          setCurrentRange([+value, +arr[1]]);
        } else if (+value > max) {
          setCurrentRange([max, +arr[1]]);
        } else if (+value < min) {
          setCurrentRange([min, +arr[1]]);
        }
      } else {
        setCurrentRange([+arr[1], +arr[1]]);
      }
    } else {
      if (+value >= +arr[0]) {
        if (+value <= max && +value >= min) {
          setCurrentRange([+arr[0], +value]);
        } else if (+value > max) {
          setCurrentRange([+arr[0], max]);
        } else if (+value < min) {
          setCurrentRange([+arr[0], min]);
        }
      } else {
        setCurrentRange([+arr[0], +arr[0]]);
      }
    }
    if (currentValue !== value) {
      setIsSliderChanged(true);
    }
  };

  useEffect(() => {
    if (placeholder === from) {
      setValue(currentRange[0]);
    } else {
      setValue(currentRange[1]);
    }
  }, [currentRange, placeholder]);

  useEffect(() => {
    if (
      prevValue.current !== value &&
      !isFocused &&
      prevValue.current !== undefined &&
      value !== undefined
    ) {
      handleRemoveCriterrium(setCriterriums, keyPath);
      handleAddCriterrium(setCriterriums, {
        typeOfFilter: "range",
        type_id: keyPath,
        path: keyPath,
        min: currentRange[0],
        max: currentRange[1],
      });
    }
    prevValue.current = value;
  }, [currentRange, isFocused, keyPath, setCriterriums, value]);

  useEffect(() => {
    if (
      prevValue.current !== value &&
      !isFocused &&
      prevValue.current !== undefined &&
      value !== undefined
    ) {
      handleRemoveCriterrium(setCriterriums, keyPath);
      handleAddCriterrium(setCriterriums, {
        typeOfFilter: "range",
        type_id: keyPath,
        path: keyPath,
        min: currentRange[0],
        max: currentRange[1],
      });
    }
    prevValue.current = value;
  }, [currentRange, isFocused, keyPath, setCriterriums, value]);

  return (
    <div className={styles.inputWrapper}>
      <input
        onBlur={handleOnBlur}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onFocus={() => setIsFocused(true)}
        type="number"
      />
      <span className={`${styles.placeholder}`}>{placeholder}</span>
    </div>
  );
};

export default NumberInput;
