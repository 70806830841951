import { useContext, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import { Loader } from "./components/atoms/Loader/Loader";
import { Routes } from "./routes";

const HasTokenRoute = ({ component: Component, ...rest }) => {
  const [isReady, setIsReady] = useState(false);
  const { hasToken } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!hasToken()) {
      history.push(Routes.mainPage.base);
    } else {
      setIsReady(true);
    }
  }, [hasToken]);

  return isReady ? (
    <Route
      {...rest}
      render={(props) => <Component {...rest} {...props} />}
    />
  ) : (
    <Loader />
  );
};

export default HasTokenRoute;
