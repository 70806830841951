import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./PopupNotifications.module.scss";
import Text from "../../../atoms/Text/Text";
import NotificationTableHeader from "../../NotificationTable/NotificationTableHeader/NotificationTableHeader";
import NotificationTableRow from "../../NotificationTable/NotificationTableRow/NotificationTableRow";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { notificationsRepository } from "../../../../repository/notifications.repository";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { PostContext } from "../../../../contexts/PostContext";

const PopupNotifications = ({ header, data }) => {
  const [formData, setFormData] = useState(Object.fromEntries(data.map(setting => [setting.id, setting.value])));
  const { callPopup } = useContext(PopupsContext);
  const { postData, isPostFinish } = useContext(PostContext);

  const preparedData = useMemo(() => data.sort((a, b) => a.description > b.description), []);

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish]);

  return (
    <>
      <Text>{header}</Text>
      <div className={styles.tableWrapper}>
        <NotificationTableHeader />
        {preparedData.map((row, i) => (
          <NotificationTableRow key={i} row={row} onChange={(value) => setFormData({ ...formData, [row.id]: value })} />
        ))}
      </div>
      <SaveBackButtons
        onSave={() => postData(() => notificationsRepository.sendNotificationSettings(formData))}
        onBack={() => callPopup({ type: ACTIONS.REMOVE })}
      />
    </>
  );
};

export default PopupNotifications;
