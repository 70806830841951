import ProfileTemplate from "../../templates/ProfileTemplate/ProfileTemplate";
import useFetch from "../../../hooks/useFetch";
import { userRepository } from "../../../repository/user.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const Profile = ({ match }) => {
  const { isResponseOk } = useContext(PostContext);
  const { data, fetchData, resetData } = useFetch();

  useDocumentTitleLoading();

  useEffect(() => {
    if (isResponseOk || !data) {
      fetchData(userRepository.getUserProfile(match.params.username, match.params.as));
    }
  }, [isResponseOk]);

  useEffect(() => {
    if (data) {
      resetData();
      fetchData(userRepository.getUserProfile(match.params.username, match.params.as));
    }
  }, [match.params.as, match.params.username]);

  return data ? (
    <ProfileTemplate data={data} params={match.params} />
  ) : (
    <Loader />
  );
};

export default Profile;
