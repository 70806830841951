import { useLocation } from "react-router-dom";
import WorkgroupDetailsTemplate from "../../templates/WorkgroupDetailsTemplate/WorkgroupDetailsTemplate";
import useFetch from "../../../hooks/useFetch";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { PostContext } from "../../../contexts/PostContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const WorkgroupDetails = () => {
  let query = useQuery();
  const { data, isReady, fetchData } = useFetch();
  const { isResponseOk } = useContext(PostContext);
  const id = query.get("id");

  useEffect(() => {
    if (!isReady) {
      fetchData(workgroupsRepository.getWorkgroup(id));
    }
  }, [fetchData, isReady, id]);

  useEffect(() => {
    if (isResponseOk) {
      fetchData(workgroupsRepository.getWorkgroup(id), false, true);
    }
  }, [fetchData, id, isResponseOk]);

  useDocumentTitleLoading();

  return isReady ? (
    <FilterContextProvider>
      <WorkgroupDetailsTemplate workgroupDetails={data} />
    </FilterContextProvider>
  ) : (
    <Loader />
  );
};

export default WorkgroupDetails;
