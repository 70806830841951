import React from "react";
import BadgePieChart from "../../atoms/BadgePieChart/BadgePieChart";
import TileItem from "../TileItem/TileItem";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import { languages } from "../../../dummyData/dummyData";

const BadgesHistoryItemTile = ({ data }) => {
  const { userName, level, created } = data;
  const { name, title, description } = data.badge;
  const { profile_image_small } = data.profile;

  return (
    <TileItem
      title={title}
      subtitle={description}
      image={<BadgePieChart level={level} name={name} progress={1} />}
      imageFit={false}
    >
      <TileItemImgInfo header={languages.EN.labels.user} value={userName} image={profile_image_small} />
      <TileItemInfo header={languages.EN.labels.date} value={created} />
    </TileItem>
  );
};
export default BadgesHistoryItemTile;
