import Statistics from "../Statistics/Statistics";
import onlineCourse from "../../../assets/icons/statistics/onlineCourse.svg";
import { languages } from "../../../dummyData/dummyData";
import search from "../../../assets/icons/statistics/search.svg";
import star from "../../../assets/icons/statistics/star.svg";
import timeOnPlatform from "../../../assets/icons/statistics/timeOnPlatform.svg";
import progress from "../../../assets/icons/statistics/progress.svg";
import competences from "../../../assets/icons/statistics/competences.svg";
import { currencyFormat, percentageFormat } from "../../../utils/formatters";
import cpa from "../../../assets/icons/statistics/cpa.svg";
import alm from "../../../assets/icons/statistics/alm.svg";
import dollar from "../../../assets/icons/statistics/dollar.svg";

const InstructorDashboardStatistics = ({ data, dataRangeName, currentSelected }) => {
  const {
    revenue,
    start_rating,
    end_rating,
    start_completion_rate,
    end_completion_rate,
    start_pass_rate,
    end_pass_rate,
    start_search_hits,
    end_search_hits,
    video_usage,
    total_video_usage,
    practical_usage,
    total_practical_usage,
    material_usage,
    total_material_usage
  } = data;
  const usage = video_usage + practical_usage + material_usage;
  const total_usage = total_video_usage + total_practical_usage + total_material_usage;

  return (
    <Statistics
      header={`${languages.EN.labels.instructorStatistics} ${languages.EN.labels.for.toLowerCase()}: ${dataRangeName}`}
      data={[
        {
          icon: onlineCourse,
          content: languages.EN.labels.totalViewership,
          description: `${languages.EN.labels.minutesPlayedOutOfTotal}: ${Math.round(usage / 60)}/${Math.round(total_usage / 60)}`,
          value: percentageFormat(total_usage > 0 ? usage / total_usage : 0),
        },
        {
          icon: dollar,
          content: languages.EN.labels.totalRevenue,
          value: currentSelected ? languages.EN.placeholders.empty : currencyFormat(revenue),
          description: currentSelected ? languages.EN.messages.currentQuarterRevenue : null,
        },
        {
          icon: star,
          content: languages.EN.labels.instructorRating,
          value: end_rating,
          previousValue: start_rating,
          formatter: (value) => value.toFixed(2),
        },
        {
          icon: progress,
          content: languages.EN.labels.coursesCompletionPercentage,
          value: end_completion_rate,
          previousValue: start_completion_rate,
          formatter: (value) => percentageFormat(value),
        },
        {
          icon: competences,
          content: languages.EN.labels.coursesPassingPercentage,
          value: end_pass_rate,
          previousValue: start_pass_rate,
          formatter: (value) => percentageFormat(value),
        },
        {
          icon: search,
          content: languages.EN.labels.searchPopularity,
          value: end_search_hits,
          previousValue: start_search_hits,
        },
        {
          icon: timeOnPlatform,
          content: languages.EN.labels.videoViewership,
          description: `${languages.EN.labels.minutesPlayedOutOfTotal}: ${Math.round(video_usage / 60)}/${Math.round(total_video_usage / 60)}`,
          value: percentageFormat(total_video_usage > 0 ? video_usage / total_video_usage : 0),
        },
        {
          icon: cpa,
          content: languages.EN.labels.cpaViewership,
          description: `${languages.EN.labels.minutesPlayedOutOfTotal}: ${Math.round(practical_usage / 60)}/${Math.round(total_practical_usage / 60)}`,
          value: percentageFormat(total_practical_usage > 0 ? practical_usage / total_practical_usage : 0),
        },
        {
          icon: alm,
          content: languages.EN.labels.almViewership,
          description: `${languages.EN.labels.minutesPlayedOutOfTotal}: ${Math.round(material_usage / 60)}/${Math.round(total_material_usage / 60)}`,
          value: percentageFormat(total_material_usage > 0 ? material_usage / total_material_usage : 0),
        },
      ]}
    />
  );
};

export default InstructorDashboardStatistics
