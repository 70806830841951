import HomepageTemplate from "../../templates/HomepageTemplate/HomepageTemplate";
import useFetch from "../../../hooks/useFetch";
import { homePageRepository } from "../../../repository/homePage.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { UserContext } from "../../../contexts/UserContext";
import { instructorRepository } from "../../../repository/instructor.repository";

const Homepage = () => {
  const { userData } = useContext(UserContext);

  useDocumentTitleLoading();

  const fetchHomepage = useFetch();
  useEffect(() => {
    fetchHomepage.fetchData(homePageRepository.getUserHomepage());
  }, [userData]);

  const fetchInstructors = useFetch();
  useEffect(() => {
    fetchInstructors.fetchData(instructorRepository.getInstructors());
  }, [userData]);

  return fetchHomepage.data && fetchInstructors.data ? (
    <HomepageTemplate homepage={fetchHomepage.data} instructors={fetchInstructors.data} />
  ) : (
    <Loader />
  );
};

export default Homepage;
