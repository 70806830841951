import { languages } from "../../../dummyData/dummyData";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import ListItem from "../ListItem/ListItem";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { useContext, useEffect } from "react";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { popupFields } from "../../templates/LearningEventsTemplate/LearningEventsTemplate";
import { learningEventsRepository } from "../../../repository/learningEvents.repository";
import { competencesRepository } from "../../../repository/competences.repository";
import useFetch from "../../../hooks/useFetch";
import { currencyFormat, dateFormat } from "../../../utils/formatters";

const LearningEvent = ({ data }) => {
  const { id, profile_image_small, name, category, date, location, learningArea, level, language, price, organizatorName, organizatorImg, details_url, canEdit } = data;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  let history = useHistory();

  const fetchCompetences = useFetch();
  useEffect(() => {
    if (fetchCompetences.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.learningEvent}`,
          fields: popupFields(fetchCompetences.data, {
            ...data,
            competences: data.competence_levels.map((competence_level) => `${competence_level.competence.id}-${competence_level.level}`)
          }),
          postAction: (formData) => learningEventsRepository.editLearningEvent(id, formData),
        },
      });
    }
  }, [fetchCompetences.isReady]);

  return (
    <ListItem title={name} subtitle={category} image={profile_image_small} buttons={[
      {
        icon: "form",
        onClick: () => window.open(details_url, '_blank'),
        tooltip: languages.EN.labels.goToPage,
      },
      {
        icon: "calendar2",
        onClick: () => callPopup({
          type: ACTIONS.POST_CONFIRM,
          payload: {
            header: languages.EN.labels.confirmation,
            content: languages.EN.messages.addToCalendar,
            action: () => learningEventsRepository.sendCalendarEvent(id),
          },
        }),
        tooltip: languages.EN.labels.addToCalendar,
      },
    ].concat(canEdit ? [
      {
        icon: "edit",
        onClick: () => {
          setIsDataActive(false);
          fetchCompetences.fetchData(competencesRepository.getCompetences());
          callPopup({ type: ACTIONS.LOADING });
        },
        tooltip: languages.EN.labels.edit,
      },
      {
        icon: "garbage",
        onClick: () => callPopup({
          type: ACTIONS.DELETE,
          payload: {
            type: languages.EN.labels.learningEvent,
            elementName: name,
            action: () => learningEventsRepository.deleteLearningEvent(id),
          },
        }),
        tooltip: languages.EN.labels.delete,
      },
    ] : []).concat([
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.learningEventDetails.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ])}>
      <ListItemInfo value={dateFormat(date)} />
      <ListItemInfo value={location} />
      <ListItemInfo value={learningArea} />
      <ListItemInfo value={languages.EN.enums.experienceLevels[level]} />
      <ListItemInfo value={languages.EN.enums.languages[language]} />
      <ListItemInfo value={currencyFormat(price)} />
      <ListItemImgInfo value={organizatorName} image={organizatorImg} />
    </ListItem>
  );
};

export default LearningEvent;
