import styles from "./TableCell.module.scss";

const cellClassNames = {
  primary: styles.Primary,
  lightText: styles.LightText,
  lightCheckbox: styles.LightCheckbox,
};

const TableCell = ({ variant, children }) => {
  return <div className={cellClassNames[variant]}>{children}</div>;
};

export default TableCell;
