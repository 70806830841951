import genericStyles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import React, { useContext, useMemo } from "react";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import { languages } from "../../../../../dummyData/dummyData";
import { coursesRepository } from "../../../../../repository/courses.repository";
import Table from "../../Table";
import { TYPE_CHECKBOX, TYPE_FILE, TYPE_NUMBER, TYPE_TEXTAREA } from "../../../../molecules/Form/Form";

const popupFields = (data = {}) => [
  {
    name: "content",
    label: languages.EN.labels.file,
    required: true,
    type: TYPE_FILE,
    value: data.content,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "duration",
    label: languages.EN.labels.duration,
    required: true,
    type: TYPE_NUMBER,
    value: data.duration,
  },
  [
    {
      name: "downloadable",
      label: languages.EN.labels.allowDownload,
      type: TYPE_CHECKBOX,
      value: data.downloadable ?? 0,
    },
    {
      name: "practical",
      label: languages.EN.labels.practicalTask,
      type: TYPE_CHECKBOX,
      value: data.practical ?? 0,
    },
  ],
];

const FilesTable = ({ reports }) => {
  const { data, courseId } = reports;
  const { callPopup } = useContext(PopupsContext);

  const items = data.map((item) => ({
    ...item,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.filename,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.description,
      accessor: "description",
    },
    {
      Header: languages.EN.labels.allowDownload,
      accessor: (data) => data.downloadable ? "✔️" : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.practicalTask,
      accessor: (data) => data.practical ? "✔️" : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={genericStyles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.file,
                elementName: data.row.original.name,
                action: () => coursesRepository.deleteResource(data.row.original.id),
              },
            })}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ], []);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.resource}`,
          fields: popupFields(),
          postAction: (formData) => coursesRepository.addResource({
            course_id: courseId,
            ...formData,
            name: formData.content.name,
          }),
        },
      })
    },
  ]} columns={columns} data={items} />;
}

export default FilesTable;
