import React, { useContext, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { Routes } from "../../../routes";
import InfoPage from "../../organisms/InfoPage/InfoPage";
import Text from "../../atoms/Text/Text";
import Html from "../../atoms/Html/Html";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import { regulatoryIntelligenceRepository } from "../../../repository/regulatoryIntelligence.repository";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { PostContext } from "../../../contexts/PostContext";
import { dateFormat } from "../../../utils/formatters";

const RegulatoryIntelligenceDetailsTemplate = ({ data }) => {
  const { id, title, profile_image_medium, author, url, created, topic, category, language, likes, user_liked, description } = data;
  const [userLiked, setUserLiked] = useState(user_liked);
  const { postData } = useContext(PostContext);
  const type = data.organization !== null ? 2 : 3;

  useDocumentTitle(`${title} | ${languages.EN.labels.regulatoryIntelligenceArticleDetails}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={title}
        path={[
          {
            name: languages.EN.labels.regulatoryIntelligenceArticles,
            link: Routes.regulatoryIntelligence.base,
          },
          {
            name: languages.EN.labels.regulatoryIntelligenceArticleDetails,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <InfoPage
          image={profile_image_medium}
          columnHeader={`${languages.EN.labels.author}: ${author}`}
          columnHeaderButtons={[
            {
              icon: "home",
              onClick: () => window.open(url, '_blank'),
              tooltip: languages.EN.labels.goToPage,
            },
            {
              icon: "heart",
              statusVariant: userLiked,
              onClick: () => {
                setUserLiked(!userLiked);
                postData(() => regulatoryIntelligenceRepository.sendLike(id));
              },
              tooltip: userLiked ? languages.EN.labels.unlike : languages.EN.labels.like,
            },
          ]}
          columnItems={[
            { header: languages.EN.labels.date, value: dateFormat(created) },
            { header: languages.EN.labels.language, value: languages.EN.enums.languages[language] },
            { header: languages.EN.labels.learningArea, value: languages.EN.enums.learningAreas[topic] },
            { header: languages.EN.labels.category, value: category },
            { header: languages.EN.labels.likes, value: likes },
            { header: languages.EN.labels.visibility, value: languages.EN.enums.visibility[type], image: type === 2 ? data.organization.profile_image_small : <AidifyLogo />, },
          ]}>
          <Text s24 w800 secondary>
            {languages.EN.labels.description}
          </Text>
          <Html s12 lh24 dark-6>
            {description}
          </Html>
        </InfoPage>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default RegulatoryIntelligenceDetailsTemplate;
