import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import ListItem from "../ListItem/ListItem";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { popupFields } from "../JobPositionsList/JobPositionsList";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const JobPosition = ({ data, withManagement }) => {
  const { id, profile_image_small, name, topic, country, level, num_org_members, num_team_members, visibility, visibilityImg, active, canEdit } = data;
  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);
  let history = useHistory();

  return (
    <ListItem title={name} subtitle={`${languages.EN.labels.region}: ${country}`} image={profile_image_small} inactive={!active} buttons={[].concat(canEdit ? [
      {
        icon: "edit",
        onClick: () => callPopup({
          type: ACTIONS.FORM,
          payload: {
            header: `${languages.EN.labels.edit} ${languages.EN.labels.jobPosition}`,
            fields: popupFields(data),
            postAction: (formData) => jobPositionsRepository.editJobPosition(id, formData),
          },
        }),
        tooltip: languages.EN.labels.edit,
      },
    ] : []).concat(withManagement === true && [2, 3].includes(userContextLevel) ? [
      {
        icon: "clone",
        onClick: () => callPopup({
          type: ACTIONS.FORM,
          payload: {
            header: `${languages.EN.labels.clone} ${languages.EN.labels.jobPosition}`,
            fields: popupFields(data),
            postAction: (formData) => {
              if (!(formData.profile_image instanceof File)) {
                formData.profile_image_url = formData.profile_image;
                delete formData.profile_image;
              }
              return jobPositionsRepository.addJobPosition(formData);
            },
          },
        }),
        tooltip: languages.EN.labels.clone,
      }
    ] : []).concat([
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.jobPositionDetails.keyCompetences.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ])}>
      <ListItemInfo value={languages.EN.enums.learningAreas[topic]} />
      <ListItemInfo value={languages.EN.enums.experienceLevels[level]} />
      <ListItemInfo value={num_org_members} />
      <ListItemInfo value={num_team_members} />
      <ListItemImgInfo value={visibility} image={visibilityImg} />
    </ListItem>
  );
};

export default JobPosition;
