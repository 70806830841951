import React, { useContext, useEffect, useState } from "react";
import styles from "./FilteredList.module.scss";
import SearchInput from "../../atoms/SearchInput/SearchInput";
import SwitchLayoutButton from "../../atoms/SwitchLayoutButton/SwitchLayoutButton";
import ItemsWithPagination from "../ItemsWithPagination/ItemsWithPagination";
import FilterPanel from "../FilterPanel/FilterPanel";
import Button from "../../atoms/Button/Button";
import { UserConfigContext } from "../../../contexts/UserConfigContext";
import { FilterContext } from "../../../contexts/FilterContext";

const FilteredList = ({ id, data, filterDefinitions, RenderListComponent, RenderTileComponent, gridSize, buttons }) => {
  const { useSelectables } = useContext(FilterContext);
  const { selected, setSelected } = useSelectables;
  const [userConfig, setUserConfig] = useContext(UserConfigContext);
  const [isGrid, setIsGrid] = useState(userConfig ? (userConfig[id]?.templateGridView ?? !RenderListComponent) : !RenderListComponent);
  const [sortPanelItems, setSortPanelItems] = useState();

  useEffect(() => {
    setUserConfig((prev) => ({
      ...prev,
      [id]: { ...prev?.[id], templateGridView: isGrid },
    }));
  }, [isGrid, setUserConfig]);

  const { useOriginalData, useFilterDefinitions, useProcessedData } = useContext(FilterContext);
  const { setOriginalData } = useOriginalData;
  const { setFilterDefinitions } = useFilterDefinitions;
  const { isDataReady, processedData } = useProcessedData;

  useEffect(() => {
    if (data) {
      setOriginalData(data);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (filterDefinitions) {
      setFilterDefinitions(filterDefinitions);
      setSortPanelItems(filterDefinitions
        .filter((filterDefinition) => filterDefinition.sortId !== undefined)
        .sort((filterDefinition1, filterDefinition2) => filterDefinition1.sortId - filterDefinition2.sortId)
        .map((filterDefinition) => ({
          id: filterDefinition.sortId,
          content: filterDefinition.header.toUpperCase(),
          sortKey: filterDefinition.keyPath,
        })));
    }
  }, [JSON.stringify(filterDefinitions)]);

  const [selectedTable, setSelectedTable] = useState([]);
  useEffect(() => {
    setSelectedTable(processedData?.filter((item) => selected.includes(item.selectableId)));
  }, [selected]);

  return data && (
    <div className={styles.Wrapper}>
      <div className={styles.mainContent}>
        <FilterPanel />
        <div className={styles.tileWrapper}>
          <div className={styles.userPanel}>
            <SearchInput />
            {RenderTileComponent && RenderListComponent && <SwitchLayoutButton isGrid={isGrid} setIsGrid={setIsGrid} />}
            {buttons && buttons.length > 0 && (
              <div className={styles.buttons}>
                {buttons.map((button) => (
                  <Button
                    key={button.label}
                    variant={button.forSelectedOnly && selected?.length === 0 ? "primaryDisabled" : "primaryW100"}
                    withIcon
                    useIconsFile
                    icon={button.icon}
                    disabled={button.forSelectedOnly && selected?.length === 0}
                    onClick={() => {
                      if (button.forSelectedOnly) {
                        button.onClick(selectedTable);
                        setSelected([]);
                      } else {
                        button.onClick();
                      }
                    }}
                    showQuantity={button.forSelectedOnly ? selected : false}
                  >
                    {button.label}
                  </Button>
                ))}
              </div>
            )}
          </div>
          <div className={styles.loaderWrapper}>
            {isDataReady && processedData && (
              <ItemsWithPagination
                items={processedData}
                RenderComponent={isGrid ? RenderTileComponent : RenderListComponent}
                isGrid={RenderTileComponent ? isGrid : undefined}
                gridSize={gridSize}
                sortPanel={sortPanelItems?.length > 0 ? sortPanelItems : undefined}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilteredList;
