import React from "react";
import styles from "./PieChartCourseRating.module.scss";
import PieChartBox from "../../molecules/PieChart/PieChartBox";
import { v4 as uuidv4 } from "uuid";

const PieChartCourseRating = ({ header, data }) => {
  return (
    <div className={styles.Wrapper}>
      <p className={styles.CompetencesTitle}>{header}</p>
      <div className={styles.ChartWrapper}>
        {data.map((item) => (
          <PieChartBox
            key={uuidv4()}
            name={item.name}
            filledAmount={item.filledAmount}
            emptyAmount={item.emptyAmount}
            singleValue={item.filledAmount.toFixed(2)}
          />
        ))}
      </div>
    </div>
  );
};

export default PieChartCourseRating;
