import React from "react";
import BadgePieChart from "../../atoms/BadgePieChart/BadgePieChart";
import ListItem from "../ListItem/ListItem";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";

const BadgesHistoryItem = ({ data }) => {
  const { userName, level, created } = data;
  const { name, title, description } = data.badge;
  const { profile_image_small } = data.profile;

  return (
    <ListItem
      title={title}
      subtitle={description}
      image={<BadgePieChart level={level} name={name} progress={1} />}
      imageFit={false}
    >
      <ListItemImgInfo value={userName} image={profile_image_small} />
      <ListItemInfo value={created} />
    </ListItem>
  );
};
export default BadgesHistoryItem;
