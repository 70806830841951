import styles from "./SelectedItem.module.scss";
import Icons from "../../../assets/icons";
import Text from "../Text/Text";
import Button from "../Button/Button";
import React from "react";

const SelectedItem = ({ item, text, onClick, withImg }) => {
  const handleDelete = () => {
    if (item.id) {
      onClick(item.id);
    } else if (item.user) {
      onClick(item.user.email);
    }
  };

  return (
    <div className={styles.Wrapper}>
      {withImg ? (React.isValidElement(item.photo) ? item.photo : <img src={item.photo} alt="" />) : ''}
      <Text s12 dark-4>
        {text}
      </Text>
      <Button onClick={item ? handleDelete : onClick} variant="crossButton">
        {Icons.cross}
      </Button>
    </div>
  );
};

export default SelectedItem;
