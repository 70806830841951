import { CSSTransition } from "react-transition-group";
import styles from "./Animiations.module.scss";

export const PopupOpacityAnimation = ({ trigger, children }) => {
  return (
    <CSSTransition
      in={trigger}
      timeout={300}
      classNames={{
        enter: styles.opacityEnter,
        enterActive: styles.opacityEnterActive,
        exit: styles.opacityExit,
        exitActive: styles.opacityExitActive,
      }}
      unmountOnExit
      mountOnEnter
    >
      {children}
    </CSSTransition>
  );
};

export const PopupScaleAnimation = ({ trigger, children }) => {
  return (
    <CSSTransition
      in={trigger}
      timeout={1300}
      classNames={{
        enter: styles.scaleEnter,
        enterActive: styles.scaleEnterActive,
        exit: styles.scaleExit,
        exitActive: styles.scaleExitActive,
      }}
      unmountOnExit
      mountOnEnter
    >
      {children}
    </CSSTransition>
  );
};

export const TableAnimation = ({ trigger, children }) => {
  return (
    <CSSTransition
      in={trigger}
      timeout={300}
      classNames={{
        enter: styles.translateEnter,
        enterActive: styles.translateEnterActive,
        exit: styles.translateExit,
        exitActive: styles.translateExitActive,
      }}
      unmountOnExit
      mountOnEnter
    >
      {children}
    </CSSTransition>
  );
};
