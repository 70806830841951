import { languages } from "../dummyData/dummyData";
import { dateFormat } from "./formatters";

export const TYPE_LEGISLATION = 0;
export const TYPE_GUIDELINE = 1;
export const TYPE_JOURNAL = 2;
export const TYPE_WEBSITE = 3;
export const TYPE_VIDEO = 4;
export const TYPE_PRESENTATION = 5;
export const TYPE_BOOK = 6;
export const TYPE_OTHER = 7;

const getLink = (data, skipHtmlTags) => {
  const link = data.link ?? data.indirect_link;

  return link ? `${languages.EN.labels.availableFrom}: ${!skipHtmlTags ? `<a href="${link}" rel="noreferrer" target="_blank">` : ''}${link}${!skipHtmlTags ? '</a>' : ''}` : '';
}

const getIfExists = (value, suffix = '.') => {
  return value ? `${value}${suffix} ` : '';
}

export const getReferenceName = (data, skipHtmlTags = false) => {
  switch(parseInt(data.type)) {
    case TYPE_LEGISLATION:
      return `${data.name}. ${getLink(data, skipHtmlTags)}`;
    case TYPE_GUIDELINE:
      return `${data.publisher}. ${data.name}. ${getIfExists(data.number)}${getIfExists(data.version)}${data.publication_date ? `${dateFormat(data.publication_date)}. ` : ''}${getLink(data, skipHtmlTags)}`;
    case TYPE_JOURNAL:
      return `${data.author}. ${data.name}. ${data.journal_name}. ${dateFormat(data.publication_date)}. ${getIfExists(data.volume)}${data.doi ? `DOI: ${data.doi}. ` : ''}${getLink(data, skipHtmlTags)}`;
    case TYPE_WEBSITE:
      return `${data.publisher}. ${getIfExists(data.author)}${data.name} ${getIfExists(data.version, '')}[${languages.EN.labels.internet}]. ${data.publication_date ? `${dateFormat(data.publication_date)} ` : ''}[${languages.EN.labels.cited}: ${dateFormat(data.citation_date)}]. ${getLink(data, skipHtmlTags)}`;
    case TYPE_VIDEO:
    case TYPE_PRESENTATION:
      return `${data.publisher}. ${getIfExists(data.author)}${data.name} ${getIfExists(data.version, '')}[${languages.EN.enums.referenceTypes[data.type]}]. ${data.publication_date ? `${dateFormat(data.publication_date)} ` : ''}[${languages.EN.labels.cited}: ${dateFormat(data.citation_date)}]. ${getLink(data, skipHtmlTags)}`;
    case TYPE_BOOK:
      let placePublisherYear = "";
      if (data.publication_place) {
        placePublisherYear += data.publication_place;
      }
      if (data.publisher) {
        placePublisherYear += `${placePublisherYear !== '' ? ': ' : ''}${data.publisher}`;
      }
      if (data.publication_date) {
        placePublisherYear += `${placePublisherYear !== '' ? '; ' : ''}${(new Date(data.publication_date)).getFullYear()}`;
      }

      return `${data.author}. ${data.name}. ${getIfExists(data.version)}${placePublisherYear}. ${getLink(data, skipHtmlTags)}`;
    case TYPE_OTHER:
      return `${getIfExists(data.publisher)}${getIfExists(data.author)}${[data.name, data.number, data.version].filter(info => info).join('. ')} [${languages.EN.labels.internet}]. ${data.publication_date ? `${dateFormat(data.publication_date)} ` : ''}[${languages.EN.labels.cited}: ${dateFormat(data.citation_date)}]. ${getLink(data, skipHtmlTags)}`;
    default:
      throw Error(`Invalid reference type: ${data.type}`);
  }
}
