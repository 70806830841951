import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { userRepository } from "../../../repository/user.repository";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import SetableCompetencesList from "../../organisms/SetableCompetencesList/SetableCompetencesList";
import { useContext } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { UserContext } from "../../../contexts/UserContext";

const StudentCompetencesTemplate = ({ userCompetences, allCompetences }) => {
  const { userData } = useContext(UserContext);
  const { postData } = useContext(PostContext);
  const { callPopup } = useContext(PopupsContext);

  const pageHeader = languages.EN.labels.studentCompetences;
  useDocumentTitle(pageHeader);

  return (
    <>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
        desc={languages.EN.help.studentCompetences}
      />
      <MainTemplate padBottom0>
        <SetableCompetencesList
          userCompetences={userCompetences.user_competences}
          allCompetences={allCompetences}
          onSave={(userCompetences) => postData(
            () => userRepository.saveCompetences(userData.username, { competences: userCompetences }),
            () => callPopup({
              type: ACTIONS.INFORMATION,
              payload: {
                header: languages.EN.labels.information,
                content: languages.EN.messages.competencesSuccessfullySaved,
              },
            })
          )}
          saveLabel={languages.EN.labels.save}
          allowManualVerification={true}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </>
  );
};

export default StudentCompetencesTemplate;
