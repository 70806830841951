import styles from "./PopupBuy.module.scss";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { homePageRepository } from "../../../../repository/homePage.repository";
import { useContext, useEffect, useState } from "react";
import { PostContext } from "../../../../contexts/PostContext";
import { PopupsContext, ACTIONS } from "../../../../contexts/PopupsContext";
import { languages } from "../../../../dummyData/dummyData";

const {
  areYouSureYouWantToBuy,
  areYouSureYouWantToBuyTickets,
} = languages.EN.messages;
const {
  buy,
  for: forStr,
  tickets,
  successfullyBought,
} = languages.EN.labels;

const PopupBuy = ({
  header,
  elementName,
  elementId,
  numberOfTickets,
  handleBonusPoints,
  cost,
  setNumber,
  status,
  elementType,
}) => {
  const onSave = () => {
    if (numberOfTickets > 0) {
      return homePageRepository.buyLotteryTicket(elementId, numberOfTickets);
    } else {
      return homePageRepository.postBuyBonus(elementId);
    }
  };
  const { postData, postResponse } = useContext(PostContext);
  const { callPopup } = useContext(PopupsContext);
  const [totalCost, setTotalCost] = useState(cost);

  useEffect(() => {
    if (numberOfTickets) {
      setTotalCost(numberOfTickets * cost);
    }
  }, [cost, numberOfTickets]);

  const handlePoints = () => {
    handleBonusPoints(totalCost);
    setNumber && setNumber(0);

    callPopup({
      type: ACTIONS.INFORMATION,
      payload: {
        header: languages.EN.labels.information,
        content:
          status === "lotery"
            ? `${tickets} (${numberOfTickets}) ${forStr} ${elementName} ${successfullyBought} `
            : `${elementName} ${successfullyBought} `,
      },
    });
  };

  return (
    <>
      <Text mb27>{header}</Text>
      <div className={styles.Wrapper}>
        {status === "lotery" ? (
          <div className={styles.content}>
            <Text dark-4 s12 w500>
              {`${areYouSureYouWantToBuyTickets} (${numberOfTickets}) ${forStr}`}
            </Text>
            <Text dark-4 s12 w700>
              {elementName}
            </Text>
            <Text dark-4 s12 w500>
              ?
            </Text>
          </div>
        ) : (
          <div className={styles.content}>
            <Text dark-4 s12 w500>
              {areYouSureYouWantToBuy}
            </Text>
            <Text dark-4 s12 w700>{`${elementType}: ${elementName}`}</Text>
            <Text dark-4 s12 w500>
              ?
            </Text>
          </div>
        )}

        {postResponse && postResponse?.status !== "ok" && (
          <Text s12 red>{`${postResponse?.status}!`}</Text>
        )}
      </div>
      <SaveBackButtons onSave={() => postData(onSave, handlePoints)} saveLabel={buy} />
    </>
  );
};

export default PopupBuy;
