import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import LearningEvent from "../../organisms/LearningEvent/LearningEvent";
import LearningEventTile from "../../organisms/LearningEventTile/LearningEventTile";
import Footer from "../../organisms/Footer/Footer";
import NavBar from "../../organisms/NavBar/NavBar";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { TYPE_DATE, TYPE_DROPDOWN, TYPE_IMAGE, TYPE_MULTICOMPLETE, TYPE_NUMBER, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { learningEventsRepository } from "../../../repository/learningEvents.repository";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { UserContext } from "../../../contexts/UserContext";
import useFetch from "../../../hooks/useFetch";
import { competencesRepository } from "../../../repository/competences.repository";
import icons from "../../../assets/icons";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "location",
    header: languages.EN.labels.location,
    sortId: 3,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 4,
  },
  {
    keyPath: "organizatorName",
    header: languages.EN.labels.organizer,
    typeOfFilter: "checkbox",
    sortId: 8,
  },
  {
    keyPath: "experienceLevel",
    header: languages.EN.labels.level,
    typeOfFilter: "checkbox",
    sortId: 5,
  },
  {
    keyPath: "language",
    header: languages.EN.labels.language,
    typeOfFilter: "checkbox",
    sortId: 6,
  },
  {
    keyPath: "price",
    header: languages.EN.labels.price,
    sortId: 7,
    typeOfFilter: "range",
  },
  {
    keyPath: "date",
    header: languages.EN.labels.date,
    sortId: 2,
    typeOfFilter: "datePicker",
  },
];

export const popupFields = (competenceItems = [], data = {}) => [
  {
    name: "topic",
    label: languages.EN.labels.learningArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.topic,
    items: languages.EN.enums.learningAreas,
  },
  {
    name: "name",
    label: languages.EN.labels.name,
    required: true,
    value: data.name,
  },
  {
    name: "category",
    label: languages.EN.labels.category,
    required: true,
    value: data.category,
  },
  {
    name: "language",
    label: languages.EN.labels.language,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.language,
    items: languages.EN.enums.languages,
  },
  {
    name: "level",
    label: languages.EN.labels.experienceLevel,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.level,
    items: languages.EN.enums.experienceLevels,
  },
  {
    name: "date",
    label: languages.EN.labels.date,
    required: true,
    type: TYPE_DATE,
    value: data.date,
  },
  {
    name: "duration",
    label: languages.EN.labels.duration,
    required: true,
    type: TYPE_NUMBER,
    value: data.duration,
  },
  {
    name: "location",
    label: languages.EN.labels.location,
    required: true,
    value: data.location,
  },
  {
    name: "instructor_text",
    label: languages.EN.labels.instructor,
    required: true,
    value: data.instructor_text,
  },
  {
    name: "details_url",
    label: languages.EN.labels.externalURL,
    required: true,
    value: data.details_url,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "competences",
    label: languages.EN.labels.competences,
    required: true,
    type: TYPE_MULTICOMPLETE,
    value: data.competences,
    items: competenceItems.reduce((acc, curr) => acc.concat(curr.competence_levels.map((competenceLevel) => ({
      id: `${curr.id}-${competenceLevel.level}`,
      label: curr.name,
      photo: icons[`level${competenceLevel.level}`],
    }))), []),
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    required: true,
    type: TYPE_IMAGE,
    value: data.profile_image,
  },
];

const LearningEventsTemplate = ({ data }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.organization !== null ? 2 : 3;

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.topic],
      visibility: languages.EN.enums.visibility[type],
      experienceLevel: languages.EN.enums.experienceLevels[item.level],
      organizatorName: type === 2 ? item.organization.name : item.organization_text,
      organizatorImg: type === 2 ? item.organization.profile_image_small : item.organization_image,
      canEdit: (type === 2 && userContextLevel === 2) || userContextLevel === 3,
    };
  })), [data]);

  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);
  const pageHeader = languages.EN.labels.learningEvents;

  useDocumentTitle(pageHeader);

  const fetchCompetences = useFetch();
  useEffect(() => {
    if (fetchCompetences.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.learningEvent}`,
          fields: popupFields(fetchCompetences.data),
          postAction: learningEventsRepository.addLearningEvent,
        },
      });
    }
  }, [fetchCompetences.isReady]);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <FilteredList
          id="learningEvents"
          data={preparedData}
          filterDefinitions={filterDefinitions}
          RenderListComponent={LearningEvent}
          RenderTileComponent={LearningEventTile}
          buttons={userContextLevel === 3 ? [
            {
              icon: "plus",
              label: languages.EN.labels.add,
              onClick: () => {
                setIsDataActive(false);
                fetchCompetences.fetchData(competencesRepository.getCompetences());
                callPopup({ type: ACTIONS.LOADING });
              }
            },
          ] : []}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default LearningEventsTemplate;
