import React, { useState, useEffect, useContext, useMemo } from "react";
import styles from "./SingleChoice.module.scss";
import Text from "../../../atoms/Text/Text";
import { CompetenceTestContext } from "../../../../contexts/CompetenceTestContext";
import { shuffle } from "../../../../utils/arrayHelpers";

const SingleChoice = ({ data }) => {
  const { answers, question } = data;
  const { savedAnswers, currentPosition, setCurrentAnswer } = useContext(CompetenceTestContext);

  const shuffledAnswersOrder = useMemo(() => shuffle(Array.from(answers.keys())), [answers]);

  const getDefaultLocalData = () => {
    return null;
  }

  const parseSavedAnswer = () => {
    return shuffledAnswersOrder.indexOf(parseInt(savedAnswers[currentPosition]));
  }

  const [localData, setLocalData] = useState(getDefaultLocalData());

  useEffect(() => {
    setLocalData(savedAnswers[currentPosition] ? parseSavedAnswer() : getDefaultLocalData());
  }, [savedAnswers, currentPosition]);

  const handleSelectAnswer = (id) => {
    setLocalData(id)
    setCurrentAnswer(shuffledAnswersOrder[id].toString());
  }

  return (
    <div className={styles.RegistrationSingleChoice}>
      <Text s14 lh24 dark-4>
        {question}
      </Text>

      {shuffledAnswersOrder.map((originalId, shuffledId) => (
        <div key={shuffledId} className={styles.wrapper}>
          <label className={styles.innerWrapper} htmlFor={shuffledId}>
            <input
              type="checkbox"
              name="name"
              id={shuffledId}
              checked={localData === shuffledId}
              onChange={() => handleSelectAnswer(shuffledId)}
            />
            <span className={styles.checkbox}/>
            <span className={styles.label}>
              <Text s12>{answers[originalId]}</Text>
            </span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default SingleChoice;
