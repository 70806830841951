import React, { useEffect } from 'react';
import { languages } from "../../../dummyData/dummyData";
import Loader from "./Components/Loader";
import { createCustomMessage } from "react-chatbot-kit";
import { generatePath } from "react-router-dom";
import { Routes } from "../../../routes";
import Cookies from "universal-cookie/es6";
import useWebSocket from "react-use-websocket";

const ActionProvider = ({ createChatBotMessage, setState, state, children }) => {
  const cookies = new Cookies();

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    `${process.env.REACT_APP_CHAT_BASE}?auth=${cookies.get("jwttoken")}`,
    {
      share: true,
      shouldReconnect: () => true,
    },
  );

  const handleTypeSelect = (context) => {
    const message = createChatBotMessage(`${languages.EN.messages.chatbot.selectedContext} ${context}. ${languages.EN.messages.chatbot.typeQuestion}`)
    setState((prev) => ({ ...prev, messages: [...prev.messages, message]}));
  }

  const handleMessage = (message) => {
    if (state.selectedType === undefined) {
      noContextResponse();
    } else {
      sendRequest(message);
    }
  };

  const noContextResponse = () => {
    const botMessage = createChatBotMessage(languages.EN.messages.chatbot.noContext, {
      widget: "typeSelector",
    });
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage]}));
  };

  useEffect(() => {
    if (lastJsonMessage) {
      backendResponse(lastJsonMessage);
    }
  }, [lastJsonMessage]);

  const sendRequest = (message) => {
    sendJsonMessage({
      search_term: message,
      search_type: parseInt(state.selectedType),
    })

    const botMessage = createChatBotMessage(<Loader />);
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage], }))
  };

  const backendResponse = (data) => {
    let timeout = 1500;
    setState((prev) => ({ ...prev, messages: prev.messages.slice(0, -1)}));

    backendAnswerResponse(data.response);

    if (Object.keys(languages.EN.enums.chatbotCourseTypes).includes(state.selectedType) && data.course_id) {
      setTimeout(() => backendCourseLinkResponse(data.course_id, data.course_title, data.unit_title), timeout);
      timeout += 1500;
    }
    if (Object.keys(languages.EN.enums.chatbotReferenceTypes).includes(state.selectedType) && data.ref_link) {
      setTimeout(() => backendReferenceLinkResponse(data.ref_link, data.page_num), timeout);
      timeout += 1500;
    }

    setTimeout(currentContextResponse, timeout);
  };

  const backendAnswerResponse = (response) => {
    const botMessage = createChatBotMessage(response);
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage]}));
  }

  const backendCourseLinkResponse = (id, name, unit) => {
    const botMessage = createCustomMessage('', 'html', {
      payload: {
        message: `${languages.EN.messages.chatbot.courseCheckMore} <a href="${generatePath(Routes.courseDetails.statistics.base, { id: id })}"><b>${name}</b></a>${unit ? ` ${languages.EN.labels.andChecking} "${unit}"` : ''}`,
      },
    });
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  }

  const backendReferenceLinkResponse = (referenceLink, page) => {
    const botMessage = createCustomMessage('', 'html', {
      payload: {
        message: `${languages.EN.messages.chatbot.referenceCheckMore} <a href="${referenceLink}"><b>${languages.EN.labels.here.toUpperCase()}</b></a>${page ? ` ${languages.EN.labels.onPage} ${page}` : ''}`,
      },
    });
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  }

  const currentContextResponse = () => {
    const botMessage = createChatBotMessage(`${languages.EN.messages.chatbot.currentContext} ${{...languages.EN.enums.chatbotCourseTypes, ...languages.EN.enums.chatbotReferenceTypes}[state.selectedType]}. ${languages.EN.messages.chatbot.changeOrAsk}`, {
      widget: "typeSelector",
    });
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage]}));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleTypeSelect,
            handleMessage,
          },
        });
      })}
    </div>);
};
export default ActionProvider;