import Text from "../../../atoms/Text/Text";
import tickIcon from "../../../../assets/icons/tickIcon.svg";
import React from "react";
import { useCurrentRoute } from "../../../../hooks/useCurrentRoute";
import { Routes } from "../../../../routes";
import { generatePath, useHistory } from "react-router-dom";

const AgendaUnit = ({ courseId, unit, isActive, isCompleted }) => {
  const history = useHistory();
  const currentRoute = useCurrentRoute(Routes.courseView);

  const getUnitNameTextProps = () => {
    let props = { s12: true, w400: true, lh14: true };

    if (isActive) {
      props['primary'] = true;
    } else if (isCompleted) {
      props['light-5'] = true;
    } else {
      props['dark-4'] = true;
    }

    return props;
  }

  // TODO: use some global func?
  const timeFormat = (duration) => {
    let hrs = ~~(duration / 3600);
    let mins = ~~((duration % 3600) / 60);
    let secs = ~~duration % 60;
    let ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  return (
    <div
      key={unit.block_id}
      onClick={() => history.push(generatePath(currentRoute.base, { id: courseId, block: unit.block_id }))}
      style={{ cursor: "pointer", marginBottom: "14px", }}
    >
      <Text {...getUnitNameTextProps(unit)}>
        {unit.title + " (" + timeFormat(unit.duration) + ")"}
        {isCompleted ? (<img src={tickIcon} alt="icon" style={{ marginLeft: "5px" }} />) : ''}
      </Text>
    </div>
  );
};

export default AgendaUnit;
