import styles from "./IdpComments.module.scss";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import { dateFormat } from "../../../utils/formatters";
import React from "react";

const IdpComments = ({ comments }) => {
  return (
    <>
      {comments.map((item, i) => (
        <span className={styles.idpComment} key={i}>
          <Text w800>
            {item.user.profile.name} {item.user.profile.surname} {languages.EN.labels.on} {dateFormat(item.created)}:
          </Text>
          <Text>{item.comment}</Text>
        </span>
      ))}
    </>
  );
};

export default IdpComments
