import BadgePieChart from "../../atoms/BadgePieChart/BadgePieChart";
import TileItem from "../../organisms/TileItem/TileItem";
import React from "react";

const BadgeTile = ({ data }) => {
  const { level, progress } = data;
  const { name, title, description } = data.badge;

  return (
    <TileItem
      title={title}
      subtitle={description}
      image={<BadgePieChart level={level} name={name} progress={progress} />}
      imageFit={false}
    >
    </TileItem>
  );
};

export default BadgeTile;
