const IMAGES = [
  require("./bannerImage1.png"),
  require("./bannerImage2.png"),
  require("./bannerImage3.png"),
  require("./bannerImage4.png"),
  require("./bannerImage5.png"),
  require("./bannerImage6.png"),
];

export default IMAGES;
