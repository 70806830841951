import { useContext, useEffect, useState } from "react";
import styles from "./NotificationDropdown.module.scss";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import svg from "../../../assets/icons";
import Icons from "../../../assets/icons";
import NotificationDropdownItem from "../../molecules/NotificationDropdownItem/NotificationDropdownItem";
import { Link, useHistory } from "react-router-dom";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import { notificationsRepository } from "../../../repository/notifications.repository";
import useFetch from "../../../hooks/useFetch";

const NotificationDropdown = ({ counter, data }) => {
  const [isShown, setIsShown] = useState(false);
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const history = useHistory();

  const handleMouseOver = () => {
    setIsShown(true);
  };

  const handleMouseOut = () => {
    setIsShown(false);
  };

  const fetchNotificationSettings = useFetch();
  useEffect(() => {
    if (fetchNotificationSettings.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.NOTIFICATION,
        payload: {
          header: languages.EN.labels.notificationsSettings,
          data: fetchNotificationSettings.data,
        },
      });
    }
  }, [fetchNotificationSettings.isReady]);

  return (
    <>
      <div
        className={styles.bellButton}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <Link to={Routes.notifications.base}>
          {counter > 0 && (
            <span className={styles.bellButton__counter}>{counter}</span>
          )}
          {svg.bell}
        </Link>
      </div>
      <div
        className={
          isShown
            ? styles.NotificationDropdown__wrapper
            : styles.NotificationDropdown__wrapperHidden
        }
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <div className={styles.NotificationDropdown}>
          <div className={styles.NotificationDropdown__titleWrapper}>
            <Text s12 lh20 w800 dark>
              {languages.EN.labels.notifications}
            </Text>
            <div className={styles.settingsButton} onClick={() => {
              setIsDataActive(false);
              fetchNotificationSettings.fetchData(notificationsRepository.getNotificationSettings());
              callPopup({ type: ACTIONS.LOADING });
            }}>
              {Icons.settings}
            </div>
          </div>
          <div className={styles.NotificationDropdown__itemsWrapper}>
            {data.length > 0 ? data.map((notification, i) =>
              i > 5 ? null : (
                <NotificationDropdownItem key={notification.id} data={notification} />
              )
            ) : (
              <div className={styles.NotificationDropdown__itemsWrapper__noData}>
                <Text s12 dark-4>
                  {languages.EN.labels.noData}
                </Text>
              </div>
            )}
          </div>

          <div className={styles.NotificationDropdown__buttonWrapper}>
              <Button
                variant='primary'
                className={styles.NotificationDropdown__button}
                onClick={() => history.push(Routes.notifications.base)}
              >
                {languages.EN.labels.showAll}
              </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationDropdown;
