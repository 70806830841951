import React from "react";
import styles from "./CompetenceVerificationTypeCell.module.scss";
import ProportionsBar from "../../../../atoms/CompetenceVerificationTypeBar/ProportionsBar";
import Text from "../../../../atoms/Text/Text";
import { languages } from "../../../../../dummyData/dummyData";

export const TYPE_ALL = "all";
export const TYPE_NOT_VERIFIED = "notVerified";
export const TYPE_VERIFIED = "verified";
export const TYPE_VERIFIED_AFTER_COURSE = "afterCourse";
export const TYPE_VERIFIED_BY_TEST = "byTest";
export const TYPE_VERIFIED_BY_MANAGER = "byManager";

export const getValueFromData = (data, type) => {
  switch (type) {
    case TYPE_NOT_VERIFIED:
      return data.notVerified;
    case TYPE_VERIFIED:
      return data.num_user_competence_course + data.num_user_competence_test + data.num_user_competence_manager
    case TYPE_VERIFIED_AFTER_COURSE:
      return data.num_user_competence_course;
    case TYPE_VERIFIED_BY_TEST:
      return data.num_user_competence_test;
    case TYPE_VERIFIED_BY_MANAGER:
      return data.num_user_competence_manager;
    case TYPE_ALL:
    default:
      return data.num_user_competence;
  }
}

const CompetenceVerificationTypeCell = ({ data, barConfig, max }) => {
  return (
    <div className={styles.wrapper}>
      <Text dark-2 s12 w600 lh24>
        {languages.EN.labels.total}: {getValueFromData(data, TYPE_ALL)}, {languages.EN.labels.verified}: {getValueFromData(data, TYPE_VERIFIED)}
      </Text>
      <ProportionsBar
        config={barConfig}
        getValue={(type) => getValueFromData(data, type)}
        max={max}
      />
    </div>
  );
};

export default CompetenceVerificationTypeCell;
