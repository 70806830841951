import React, { useContext, useEffect, useState } from "react";
import { PostContext } from "../../../../contexts/PostContext";
import { languages } from "../../../../dummyData/dummyData";
import styles from "../FormPopup/FormPopup.module.scss";
import TextArea from "../../../atoms/TextArea/TextArea";
import Select from "../../../atoms/Select/Select";
import UploadFile from "../../UploadFile/UploadFile";
import SelectSearch from "../../../atoms/SelectSearch/SelectSearch";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import { filesRepository } from "../../../../repository/files.repository";
import { validationForm } from "../../../../utils/validation";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";

// TODO: refactor if possible to use generic FormPopup - unfortunately I wasn't able to resolve issues with changing targets state
//       and passing it to input
const FilePopup = ({ header, courses, learningEvents, regulatoryIntelligence }) => {
  const [formData, setFormData] = useState({
    category: "",
    target: "",
    description: "",
    data_file: [],
  });
  const [targets, setTargets] = useState([]);
  const [targetDisabled, setTargetDisabled] = useState(true)
  const [targetResetInput, setTargetResetInput] = useState(false)

  const { isPostFinish, postData, postResponse } = useContext(PostContext);
  const { callPopup } = useContext(PopupsContext);

  const [errors, setErrors] = useState({
    category: undefined,
    target: undefined,
    description: undefined,
    data_file: undefined,
  });

  const handleDefault = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelect = (name, id) => {
    setFormData({ ...formData, [name]: id, target: "" });

    let data;

    switch (id) {
      case "0":
        data = courses.map(
          (field) => ({ id: field.id, label: field.display_name })
        );
        break;
      case "1":
        data = learningEvents.map(
          (field) => ({ id: field.id, label: field.name })
        );
        break;
      case "2":
        data = regulatoryIntelligence.map(
          (field) => ({ id: field.id, label: field.title })
        );
        break;
      default:
        data = [];
        break;
    }
    setTargets(data);
    setTargetDisabled(data === []);
    setTargetResetInput(!targetResetInput);
  };

  const handleSelectSearch = (name, id) => {
    setFormData({ ...formData, [name]: id });
  };

  const sendFormData = () => {
    let validationErrors = validationForm(formData, [
      { name: "category", type: "text" },
      { name: "target", type: "text" },
      { name: "description", type: "text" },
    ]);
    setErrors(validationErrors);
    if (Object.values(validationErrors).every((v) => v.error === false)) {
      return filesRepository.postFile({
        ...formData,
        name: formData.data_file.name,
        public: 0,
        organization: 0,
      });
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish]);

  return (
    <>
      <Text>{header}</Text>
      <div className={styles.inputWrapper}>
        <Select
          key={"category"}
          name={"category"}
          required={true}
          handleSelect={handleSelect}
          placeholder={languages.EN.labels.category}
          items={Object.entries(languages.EN.enums.fileCategories).map(([id, label]) => ({ id: id, label: label }))}
          error={errors.category?.error}
        />
        <SelectSearch
          key={"target"}
          name={"target"}
          value={formData["target"]}
          required={true}
          disabled={targetDisabled}
          items={targets}
          resetInput={targetResetInput}
          handleSelect={handleSelectSearch}
          placeholder={languages.EN.labels.linkedElement}
          error={errors.target?.error}
        />
        <TextArea
          key={"description"}
          name={"description"}
          value={formData["description"]}
          required={true}
          handleTextArea={handleDefault}
          placeholder={languages.EN.labels.description}
          error={errors.description?.error || postResponse?.description}
        />
        <UploadFile
          key={"data_file"}
          name={"data_file"}
          setFormData={setFormData}
          formData={formData}
          placeholder={languages.EN.labels.file}
          isFile={true}
          error={errors.data_file !== undefined || postResponse?.data_file !== undefined}
          errorMessage={errors.data_file?.error || postResponse?.data_file}
        />
      </div>
      <SaveBackButtons onSave={() => postData(sendFormData)} />
    </>
  );
};

export default FilePopup;
