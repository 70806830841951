import React from "react";
import styles from "./SwitchMenu.module.scss";
import { NavLink } from "react-router-dom";
import Text from "../../atoms/Text/Text";

const SwitchMenu = ({ links, style }) => {
  return (
    <div className={styles.switchMenu}>
      <ul
        className={styles.list}
        style={style ? { ...style } : null}
      >
        {links && links.filter(link => link.visible !== false).map((link) => (
          <li key={link.href}>
            {link.disabled ? (
              <span className={styles.link}>
                <Text s12 w400 lh14 uppercase dark-3>
                  {link.content}
                </Text>
              </span>
            ) : (
              <NavLink
                exact
                to={link.href}
                className={styles.link}
                activeClassName={styles.link__active}
              >
                <Text uppercase>{link.content}</Text>
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SwitchMenu;
