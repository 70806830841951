import Text from "../../atoms/Text/Text";
import styles from "./TextWithHeader.module.scss";

const TextWithHeader = ({ header, text, bigHeader }) => (
  <div className={styles.textWithHeader}>
    {bigHeader ? (
      <Text s20 lh32 w800 secondary>
        {header}
      </Text>
    ) : (
      <Text s14 lh18 secondary>
        {header}
      </Text>
    )}
    {typeof text === 'string' ? (
      <Text s12 lh24 dark-6>
        {text}
      </Text>
    ) : text}
  </div>
);

export default TextWithHeader;
