import { useContext, useEffect, useState } from "react";
import styles from "./ChatList.module.scss";
import ListChatItem from "../../../molecules/Chat/ListChatItem/ListChatItem";
import Button from "../../../atoms/Button/Button";
import SearchChat from "../../../atoms/SearchChat/SearchChat";
import Text from "../../../atoms/Text/Text";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { PostContext } from "../../../../contexts/PostContext";
import useFetch from "../../../../hooks/useFetch";
import { chatRepository } from "../../../../repository/chat.repository";
import { languages } from "../../../../dummyData/dummyData";

const {
  newChat,
  groupChatsString,
  min3characters,
  selectUsers,
  search,
  noResults,
} = languages.EN.labels;

const ChatList = ({
  setSelectedChat,
  selectedChat,
  currentPms,
  setCurrentMessages,
  groupChats,
  handleGetMessagesAfterSendMessage,
  isNextReqAvalible,
}) => {
  const { callPopup } = useContext(PopupsContext);
  const { postResponse } = useContext(PostContext);
  const [isTable, setIsTable] = useState(false);
  const [searchingRecentPms, setSearchingREcentPms] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTaping, setIsTaping] = useState(false);
  const { fetchData, isReady, data, loading } = useFetch();

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setIsTaping(true);
    const timeoutId = setTimeout(() => {
      setIsTaping(false);
      if (inputValue.length > 2) {
        fetchData(chatRepository.searchChat(inputValue));
      }
    }, 600);
    return () => clearTimeout(timeoutId);
  }, [fetchData, inputValue]);

  useEffect(() => {
    if (postResponse) {
      setSelectedChat({
        id: postResponse.room_id,
        name: postResponse.room_name,
      });
    }
  }, [postResponse, setSelectedChat]);

  useEffect(() => {
    if (isReady) {
      setSearchingREcentPms(data.recent_pms);
    }
  }, [data, isReady]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.inputWrapper}>
        <SearchChat
          value={inputValue}
          onChange={handleInput}
          placeholder={search}
        />
        <Button
          onClick={() => {
            // callPopup({
            //   type: ACTIONS.SHARE_COURSE,
            //   payload: {
            //     header: selectUsers,
            //     actionOnSave: "chat",
            //     handleGetMessages: handleGetMessagesAfterSendMessage,
            //     handleRestChoosenChat: setCurrentMessages,
            //   },
            // })
          }}
          variant="primaryW100"
          withIcon
          icon="smallPlus"
        >
          {newChat}
        </Button>
      </div>
      <div className={styles.chatUsers}>
        {!inputValue && currentPms !== undefined &&
          currentPms.map((chat, i) => (
            <ListChatItem
              isNextReqAvalible={isNextReqAvalible}
              key={i}
              setCurrentMessages={setCurrentMessages}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              handleGetMessagesAfterSendMessage={
                handleGetMessagesAfterSendMessage
              }
              chat={chat}
            />
          ))}
        {inputValue.length > 2 &&
          searchingRecentPms.map((chat, i) => (
            <ListChatItem
              isNextReqAvalible={isNextReqAvalible}
              key={i}
              setCurrentMessages={setCurrentMessages}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              handleGetMessagesAfterSendMessage={
                handleGetMessagesAfterSendMessage
              }
              chat={chat}
            />
          ))}
        <div className={styles.information}>
          {inputValue.length < 3 && inputValue && !loading && (
            <Text s14 dark-4>
              {min3characters}
            </Text>
          )}
          {inputValue.length > 2 && searchingRecentPms.length === 0 && (
            <div>
              {!loading && !isTaping && (
                <Text s14 dark-4>
                  {`${noResults}.`}
                </Text>
              )}
            </div>
          )}
          {inputValue.length === 0 && currentPms !== undefined && currentPms.length === 0 && (
            <div>
              {!loading && !isTaping && (
                <Text s14 dark-4>
                  {`${languages.EN.messages.messagesNotFound}.`}
                </Text>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.groupChats}>
        <Text secondary s20 w800>
          {groupChatsString}
        </Text>
        {groupChats.slice(0, 3).map((chat, i) => (
          <div
            key={i}
            onClick={() => {
              setSelectedChat({
                name: chat.name,
                id: chat.id,
              });
            }}
          >
            <img
              className={styles.groupChatItem}
              src={chat.profile_image}
              alt=""
            />
          </div>
        ))}
        <div
          onMouseLeave={() => setIsTable(false)}
          onMouseEnter={() => setIsTable(true)}
          className={styles.groupChatbutton}
        >
          {groupChats.length > 3 && (
            <Button icon="dots" onlyIcon variant="iconButton" />
          )}
        </div>
        {isTable && (
          <div
            onMouseLeave={() => setIsTable(false)}
            onMouseEnter={() => setIsTable(true)}
            className={styles.scrollWrapper}
          >
            <div className={styles.groupChatsTable}>
              {groupChats.map((chat, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setSelectedChat({
                      name: chat.name,
                      id: chat.id,
                    });
                  }}
                >
                  <img
                    className={styles.groupChatItemIntable}
                    src={chat.profile_image}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatList;
