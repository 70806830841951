import React, { useContext, useMemo } from "react";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import { coursesRepository } from "../../../../../repository/courses.repository";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import { TYPE_FILE, TYPE_NUMBER, TYPE_RADIO_INPUTS, TYPE_TEXTAREA } from "../../../../molecules/Form/Form";
import { PostContext } from "../../../../../contexts/PostContext";
import { UserContext } from "../../../../../contexts/UserContext";
import { mimeTypes } from "../../../../../repository/files.repository";

const popupFields = (data = {}, allowVideoUrl = false) => [
  {
    name: "title",
    label: languages.EN.labels.name,
    required: true,
    value: data.title,
  },
  {
    name: "duration",
    label: `${languages.EN.labels.duration} (${languages.EN.labels.inSeconds})`,
    required: true,
    type: TYPE_NUMBER,
    value: data.duration,
  },
  {
    name: "description",
    label: `${languages.EN.labels.additionalMaterials} (${languages.EN.labels.commaSeparated.toLowerCase()})`,
    type: TYPE_TEXTAREA,
    value: (data.links ?? []).join(", "),
  },
].concat(allowVideoUrl ? [
  {
    type: TYPE_RADIO_INPUTS,
    label: languages.EN.labels.content,
    inputs: [
      {
        name: "video_url",
        label: languages.EN.labels.provideVideoURL,
        value: data.video_url,
      },
      {
        name: "content",
        label: languages.EN.labels.uploadFile,
        type: TYPE_FILE,
        value: data.pdf_id,
        allowedTypes: mimeTypes.pdf,
        validation: (formData) => ![null, undefined, mimeTypes.pdf].includes(formData.content?.type) ? languages.EN.messages.attachedFileIncorrectType : undefined,
      },
    ],
  }] : [
  {
    name: "content",
    label: languages.EN.labels.content,
    required: !data.pdf_id,
    type: TYPE_FILE,
    value: data.content,
  },
]);

const UnitsTable = ({ reports }) => {
  const { data, course, sectionId } = reports;
  const { userContextLevel } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);

  const items = data.map((item) => ({
    ...item,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.position,
      accessor: "position",
    },
    {
      Header: languages.EN.labels.name,
      accessor: "title",
    },
    {
      Header: languages.EN.labels.duration,
      accessor: "duration",
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={() => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.unit}`,
                fields: popupFields(data.row.original, course.course_organization === null && userContextLevel === 3),
                postAction: (formData) => coursesRepository.editUnit(data.row.original.block_id, {
                  section_id: sectionId,
                  ...formData,
                  position: data.row.original.position,
                })
              },
            })}
            tooltip={languages.EN.labels.edit}
          />
          {data.row.original.position > 1 && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="topArrow"
              onClick={(event) => {
                event.stopPropagation();
                postData(() => coursesRepository.moveUnit({ id: data.row.original.block_id, position: data.row.original.position - 1 }));
              }}
              tooltip={languages.EN.labels.moveUp}
            />
          )}
          {data.row.original.position < data.rows.length && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="bottomArrow"
              onClick={(event) => {
                event.stopPropagation();
                postData(() => coursesRepository.moveUnit({ id: data.row.original.block_id, position: data.row.original.position + 1 }));
              }}
              tooltip={languages.EN.labels.moveDown}
            />
          )}
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.unit,
                elementName: data.row.original.title,
                action: () => coursesRepository.deleteUnit(data.row.original.block_id),
              },
            })}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ], []);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.unit}`,
          fields: popupFields({}, course.course_organization === null && userContextLevel === 3),
          postAction: (formData) => coursesRepository.addUnit({
            course_id: course.id,
            section_id: sectionId,
            ...formData,
            position: data.length + 1,
          })
        },
      }),
    },
  ]} columns={columns} data={items} searchBar={false} />;
}

export default UnitsTable;
