import styles from "./PairUpForm.module.scss";
import Button from "../../../atoms/Button/Button";
import { useState } from "react";
import CustomInput from "../../../atoms/CustomInput/CustomInput";
import { languages } from "../../../../dummyData/dummyData";
import Text from "../../../atoms/Text/Text";
import { shuffle } from "../../../../utils/arrayHelpers";

const PairUpForm = ({ formData, setFormData, error }) => {
  const correctSplitted = formData.correct.split("|");
  const answersSplitted = formData.answers.split("|");

  const [localData, setLocalData] = useState(answersSplitted.slice(0, answersSplitted.length / 2).map((answer, i) => ({
    id: i,
    answer1: answer,
    answer2: correctSplitted[i],
  })));

  const addRow = (i) => {
    setLocalData([...localData, { id: i, answer1: "", answer2: "" }]);
  };

  const handleAnswerChange = (e, id) => {
    const newLocalData = [...localData].map((data, i) => i !== id ? data : { ...data, [e.target.name]: e.target.value });

    setFormData({
      ...formData,
      correct: newLocalData.map(data => data.answer2).join("|"),
      answers: [ ...newLocalData.map(data => data.answer1), ...shuffle(newLocalData.map(data => data.answer2)) ].join("|"),
    });
    setLocalData(newLocalData);
  };

  return (
    <>
      <div className={`${styles.wrapper} ${error ? styles.wrapper__error : ''}`}>
        <span data-active={true} className={`${styles.label} ${styles.label__white} ${error ? styles.label__error : ''}`}>
          {languages.EN.labels.answers}
        </span>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>
                <Text s12 w400 lh24>{`${languages.EN.labels.element.toString()} 1`}</Text>
              </td>
              <td></td>
              <td>
                <Text s12 w400 lh24>{`${languages.EN.labels.element.toString()} 2`}</Text>
              </td>
            </tr>
          </thead>
          <tbody>
            {localData.map((row, i, arr) => (
              <tr key={i}>
                <td>
                  <CustomInput
                    type='text'
                    name='answer1'
                    value={row.answer1}
                    placeholder={languages.EN.labels.provideAnswer}
                    onChange={(e) => handleAnswerChange(e, row.id)}
                  />
                </td>
                <td>
                  <hr className={styles.arrows} />
                </td>
                <td>
                  <CustomInput
                    type='text'
                    name='answer2'
                    value={row.answer2}
                    placeholder={languages.EN.labels.provideAnswer}
                    onChange={(e) => handleAnswerChange(e, row.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.errorMsg}>
        <Text s10 lh12>
          {error && error}
        </Text>
      </div>
      <div className={styles.addButton}>
        <Button
          withIcon
          icon='smallPlus'
          variant='primary'
          onClick={() => addRow(localData.length)}
        >
          {languages.EN.labels.add}
        </Button>
      </div>
    </>
  );
};

export default PairUpForm;
