import React from "react";
import { certificatesRepository } from "../../../repository/certificates.repository";
import ListItem from "../ListItem/ListItem";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import { languages } from "../../../dummyData/dummyData";

const Certificate = ({ data, skipTeam }) => {
  const { selectableId, course_title, prepared_teams, course_id, profile_image_small, user, prepared_date, isChecked } = data;

  return (
    <ListItem title={course_title} image={profile_image_small} selectableId={selectableId} isChecked={isChecked} buttons={[
      {
        icon: "download",
        onClick: () => certificatesRepository.downloadCertificate(user.username, course_id),
        tooltip: `${languages.EN.labels.download} ${languages.EN.labels.certificate}`,
      },
    ]}>
      <ListItemImgInfo value={`${user.profile.name} ${user.profile.surname}`} image={user.profile.profile_image_small} />
      <ListItemInfo value={user.profile.user_job_position?.name} />
      {!skipTeam && <ListItemInfo value={prepared_teams} />}
      <ListItemInfo value={prepared_date} />
    </ListItem>
  );
};

export default Certificate;
