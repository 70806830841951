import { useContext } from "react";
import styles from "./ChatRoom.module.scss";
import Text from "../../../atoms/Text/Text";
import Button from "../../../atoms/Button/Button";
import Input from "../../../atoms/Input/Input";
import CheckBoxOnly from "../../../atoms/CheckBoxOnly/CheckBoxOnly";
import ChatMessage from "../../../molecules/Chat/ChatMessage/ChatMessage";
import { useEffect, useState } from "react";
import { chatRepository } from "../../../../repository/chat.repository";
import { TableLoader } from "../../../atoms/Loader/Loader";
import { UserContext } from "../../../../contexts/UserContext";
import { languages } from "../../../../dummyData/dummyData";
import { UserConfigContext } from "../../../../contexts/UserConfigContext";

const {
  typeSomething,
  pressEnterToSend,
  send,
} = languages.EN.labels;

const ChatRoom = ({
  selectedChat,
  handleGetMessagesAfterSendMessage,
  isInputDisable,
  setIsInputDisabled,
  currentMessages,
}) => {
  const [sendMessage, setSendMessage] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const { userData } = useContext(UserContext);

  const handleInput = (e) => {
    setInputMessage(e.target.value);
  };

  const handleSendMessageOnEnter = (e) => {
    if (e.key === "Enter" && inputMessage !== "" && isSendOnEnter) {
      setIsInputDisabled(true);
      setInputMessage("");
      setSendMessage(!sendMessage);
      chatRepository.sendMessage(selectedChat.id, inputMessage).then((res) => {
        if (res.status === 200) {
          handleGetMessagesAfterSendMessage(selectedChat.id);
        }
      });
    }
  };

  const handleSendMessageOnClick = () => {
    setInputMessage("");
    setSendMessage(!sendMessage);
    chatRepository.sendMessage(selectedChat.id, inputMessage).then((res) => {
      if (res.status === 200) {
        handleGetMessagesAfterSendMessage(selectedChat.id);
      }
    });
  };

  const [userConfig, setUserConfig] = useContext(UserConfigContext);
  const [isSendOnEnter, setIsSendOnEnter] = useState(
    userConfig?.chat?.isSendOnEnter ? true : false
  );

  useEffect(() => {
    setUserConfig((prev) => ({
      ...prev,
      chat: { ...prev?.chat, isSendOnEnter: isSendOnEnter },
    }));
  }, [isSendOnEnter, setUserConfig]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.person}>
        <Text s14 w700 dark-4>
          {selectedChat.name}
        </Text>
      </div>
      {currentMessages && !!currentMessages[0] && (
        <div className={styles.chat}>
          {currentMessages.map((message, i) => (
            <ChatMessage
              key={i}
              message={message}
              variant={
                userData.username === message?.sender.username
                  ? "sendedMessage"
                  : "receivedMessage"
              }
            />
          ))}
        </div>
      )}

      {currentMessages && !currentMessages[0] && !selectedChat && (
        <div className={styles.red}>
          <Text s14 w800 dark-4>
            {languages.EN.messages.chooseExistingConversation}
          </Text>
        </div>
      )}

      {!currentMessages && (
        <div className={styles.chat}>
          <TableLoader />
        </div>
      )}
      <div type="submit" className={styles.sendMessageForm}>
        <Input
          type="submit"
          value={inputMessage}
          handleInput={handleInput}
          placeholder={typeSomething}
          onKeyPress={handleSendMessageOnEnter}
          disabled={isInputDisable}
        />
        <Button
          onClick={handleSendMessageOnClick}
          variant="primaryW100"
          withIcon
          icon="send2"
        >
          {send}
        </Button>
        <CheckBoxOnly
          handleCheckbox={() => setIsSendOnEnter(!isSendOnEnter)}
          text={pressEnterToSend}
          checked={isSendOnEnter}
        />
      </div>
    </div>
  );
};

export default ChatRoom;
