import { createContext, useEffect, useState } from "react";
import { languages } from "../dummyData/dummyData";
import { useLocation } from "react-router-dom";

export const ReportProblemContext = createContext();

export const ReportProblemContextProvider = ({ children }) => {
  const [subject, setSubject] = useState();
  const location = useLocation();

  useEffect(() => {
    setSubject(undefined);
  }, [location]);
  const prepareTitle = (title) => {
    const matches = [...document.title.match(/^(([^\|]+) \| )?([^\|]+) \| AIDIFY$/)];

    return `${languages.EN.labels.view}: ${matches[3]}${matches[2] !== undefined ? ` (${matches[2]})` : ''}`
  };

  const getSubject = () => `${prepareTitle(document.title)}${subject !== undefined ? ` | ${subject}` : ''}`;

  return (
    <ReportProblemContext.Provider value={{ subject, setSubject, getSubject}}>
      {children}
    </ReportProblemContext.Provider>
  );
};
