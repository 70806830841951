import { SCOPE_AUDIT, SCOPE_CHAT, SCOPE_COMPETENCE_FRAMEWORK, SCOPE_COMPETENCE_FRAMEWORK_EDIT, SCOPE_FILES, SCOPE_IDP, SCOPE_LEARNING, SCOPE_LEARNING_EVENT, SCOPE_LEARNING_REPORT, SCOPE_MARKETPLACE, SCOPE_ORGANIZATION, SCOPE_PLATFORM, SCOPE_REGULATORY_INTELLIGENCE, SCOPE_USER_LEARNING_REPORT, SCOPE_TEAM, SCOPE_USER, SCOPE_WORKGROUP, SCOPE_INSTRUCTOR } from "./constants/scopes";

const routesData = {
  mainPage: { base: "/" },
  error: { base: "/error" },
  passwordReset: { base: "/password-forgot" },
  passwordResetConfirm: { base: "/password-reset-confirm/:uidb36/:token([a-z0-9]{6}-[0-9a-f]{32})" },
  passwordChange: { base: "/password-change" },
  registration: {
    base: "/registration",
    checkScopes: (scopes) => scopes.includes(SCOPE_USER),
    extend: {
      init: { base: "/:email/:token" },
      form: { base: "/form" },
      competences: { base: "/competences", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING) },
      test: { base: "/test", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING) },
      testReport: { base: "/test-report", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING) },
    }
  },

  homepage: { base: "/homepage", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING) },
  search: { base: "/search", checkScopes: (scopes) => scopes.includes(SCOPE_USER) },
  notifications: { base: "/notifications", checkScopes: (scopes) => scopes.includes(SCOPE_USER) },
  profile: {
    base: "/profile/:username/:as(public|organization|team)?",
    checkScopes: (scopes) => scopes.includes(SCOPE_USER),
    extend: {
      statistics: { base: "/statistics" },
      courses: { base: "/courses" },
      competences: { base: "/competences" },
      badges: { base: "/badges" },
      rewards: { base: "/rewards" },
    }
  },
  settings: { base: "/settings", checkScopes: (scopes) => scopes.includes(SCOPE_USER) },
  studentCompetences: { base: "/student-competences", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING) },
  profession: { base: "/profession/:username", checkScopes: (scopes) => scopes.includes(SCOPE_USER) },
  files: { base: "/files", checkScopes: (scopes) => scopes.includes(SCOPE_FILES) },
  marketplace: { base: "/marketplace", checkScopes: (scopes) => scopes.includes(SCOPE_MARKETPLACE) },
  chat: { base: "/chat", checkScopes: (scopes) => scopes.includes(SCOPE_CHAT) },

  organization: {
    base: "/organization/:id(\\d+)",
    checkScopes: (scopes) => scopes.includes(SCOPE_ORGANIZATION),
    extend: {
      statistics: { base: "/statistics" },
      employees: { base: "/employees" },
      teams: { base: "/teams" },
      certificates: { base: "/certificates" },
      badges: { base: "/badges" },
      rewards: { base: "/rewards" },
    }
  },
  team: {
    base: "/team/:id(\\d+)",
    checkScopes: (scopes) => scopes.includes(SCOPE_TEAM),
    extend: {
      statistics: { base: "/statistics" },
      members: { base: "/members" },
      certificates: { base: "/certificates" },
      badges: { base: "/badges" },
      rewards: { base: "/rewards" },
    }
  },
  organizationLicenses: { base: "/organization-licenses", checkScopes: (scopes) => scopes.includes(SCOPE_ORGANIZATION) },
  workgroups: { base: "/workgroups", checkScopes: (scopes) => scopes.includes(SCOPE_WORKGROUP) },
  workgroupDetails: { base: "/workgroup-details", checkScopes: (scopes) => scopes.includes(SCOPE_WORKGROUP) },

  platformDashboard: {
    base: "/platform-dashboard",
    checkScopes: (scopes) => scopes.includes(SCOPE_PLATFORM),
    extend: {
      statistics: { base: "/statistics" },
      instructor: { base: "/instructor" },
      user: { base: "/user" },
      competences: { base: "/competences" },
    }
  },
  licenseControl: { base: "/license-control", checkScopes: (scopes) => scopes.includes(SCOPE_PLATFORM) },
  referenceControl: { base: "/reference-control", checkScopes: (scopes) => scopes.includes(SCOPE_AUDIT) },
  referenceAudit: {
    base: "/reference-audit/:reference/:audit",
    checkScopes: (scopes) => scopes.includes(SCOPE_AUDIT),
    extend: {
      relatedCourses: { base: "/courses" },
    }
  },
  instructorDashboard: {
    base: "/instructor-dashboard/:quarter(\\d+)?/:year(\\d+)?",
    checkScopes: (scopes) => scopes.includes(SCOPE_INSTRUCTOR),
    extend: {
      instructorStatistics: { base: "/instructor-statistics" },
      coursesStatistics: { base: "/course-statistics" },
    }
  },

  idps: { base: "/idps/:username", checkScopes: (scopes) => scopes.includes(SCOPE_IDP) },
  idpDetails: {
    base: "/idp-details/:username/:id(\\d+)",
    checkScopes: (scopes) => scopes.includes(SCOPE_IDP),
    extend: {
      career: { base: "/career" },
      learningPlan: { base: "/learning-plan" },
    }
  },
  idpEdit: {
    base: "/idp-edit/u/:username/id/:id?/m/:mode(add|edit)?",
    checkScopes: (scopes) => scopes.includes(SCOPE_IDP),
    extend: {
      career: { base: "/career" },
      learningPlan: { base: "/learning-plan" },
    }
  },
  careerPaths: { base: "/career-paths", checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK) },
  careerPathDetails: {
    base: "/career-path/:id",
    checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK),
    extend: {
      jobChart: { base: "/job-chart/" },
      statistics: { base: "/statistics/" },
      competences: { base: "/competences/" },
    }
  },
  jobPositions: { base: "/job-positions", checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK) },
  jobPositionDetails: {
    base: "/job-position/:id",
    checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK),
    extend: {
      keyCompetences: { base: "/key-competences" },
      complementaryCompetences: { base: "/complementary-competences" },
      careerPaths: { base: "/career-paths" },
    }
  },
  competences: { base: "/competences", checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK) },
  competenceDetails: {
    base: "/competence/:id",
    checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK),
    extend: {
      jobPositions: { base: "/job-positions" },
    }
  },
  courses: { base: "/courses", checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK) },
  courseDetails: {
    base: "/course-details/:id",
    checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK),
    extend: {
      statistics: { base: "/statistics/" },
      reviews: { base: "/reviews/" },
      jobPositions: { base: "/job-positions/" },
      careerPaths: { base: "/career-paths/" },
      audits: { base: "/audits/", checkScopes: (scopes) => scopes.includes(SCOPE_AUDIT) },
    }
  },
  instructorDetails: { base: "/instructor-details/:id", checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK) },
  courseView: {
    base: "/course-view/:id/:reset(reset)?/:block(teaser|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?",
    checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING),
    extend: {
      about: { base: "/about/" },
      practice: { base: "/practice/" },
      materials: { base: "/materials/" },
      references: { base: "/references/" },
    }
  },
  coursePlayer: { base: "/player", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING) },
  courseEdit: {
    base: "/course-edit/:id",
    checkScopes: (scopes) => scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT),
    extend: {
      conspect: { base: "/conspect/" },
      competence: { base: "/competence/" },
      additional: { base: "/additional/" },
      references: { base: "/references/" },
    }
  },
  competenceTest: {
    base: "/competence-test/:id/:course([^//]+)?",
    checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING),
    extend: {
      test: { base: "/test/" },
      report: { base: "/report/" },
    }
  },
  learningEvents: { base: "/learning-events", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING_EVENT) },
  learningEventDetails: { base: "/learning-event-details/:id", checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING_EVENT) },
  regulatoryIntelligence: { base: "/regulatory-intelligence", checkScopes: (scopes) => scopes.includes(SCOPE_REGULATORY_INTELLIGENCE) },
  regulatoryIntelligenceDetails: { base: "/regulatory-intelligence-details/:id", checkScopes: (scopes) => scopes.includes(SCOPE_REGULATORY_INTELLIGENCE) },
  learningReport: {
    base: "/learning-report/:type/:id/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?",
    checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING_REPORT),
    extend: {
      competences: { base: "/competences" },
      courses: { base: "/courses" },
      instructors: { base: "/instructors" },
      competitions: { base: "/competitions" },
    }
  },
  studentLearningReport: {
    base: "/student-learning-report/:username/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?",
    checkScopes: (scopes) => scopes.includes(SCOPE_LEARNING_REPORT) || scopes.includes(SCOPE_USER_LEARNING_REPORT),
  },
};

const prepareData = (data, parent) => Object.fromEntries(Object.entries(data).map(([key, value]) => {
  let checkScopes;

  if (typeof value.checkScopes === 'function') {
    checkScopes = value.checkScopes;
  } else if (typeof parent?.checkScopes === 'function') {
    checkScopes = parent.checkScopes;
  } else {
    checkScopes = () => true;
  }

  return [key, {
    base: (parent?.base ?? '') + value.base,
    checkScopes: checkScopes,
    ...(value.extend ? prepareData(value.extend, value) : {}),
  }]
}));

export const Routes = prepareData(routesData);
