import React, { useContext, useEffect } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import TeamStatistics from "../../organisms/TeamStatistics/TeamStatistics";
import CertificatesList from "../../organisms/CerificatesList/CertificatesList";
import BadgesHistoryList from "../../organisms/BadgesHistoryList/BadgesHistoryList";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { UserContext } from "../../../contexts/UserContext";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { teamRepository } from "../../../repository/team.repository";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { generatePath } from "react-router-dom";
import RewardEndorsementsList from "../../organisms/RewardEndorsementsList/RewardEndorsementsList";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import MembersList from "../../organisms/MembersList/MembersList";
import Text from "../../atoms/Text/Text";
import useFetch from "../../../hooks/useFetch";
import { organizationRepository } from "../../../repository/organization.repository";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { popupFields } from "../../organisms/TeamsList/TeamsList";

const popupFilterDefinitions = [
  {
    keyPath: ["user.profile.name", "user.profile.surname", "user.email"],
  },
  {
    keyPath: "user.profile.user_career_path.name",
    header: languages.EN.labels.careerPath,
    typeOfFilter: "checkbox",
  },
];

const PopupRenderItem = ({ item }) => {
  const user = item.user;
  const profile = user.profile;

  return (
    <Text s12 dark-4>
      {profile.name ? `${profile.name} ${profile.surname} (${user.email})` : user.email}
    </Text>
  );
}

const TeamTemplate = ({ data, teamId }) => {
  const { name, profile_image, description, manager, certificates, badges } = data;

  const { userData, userContextLevel } = useContext(UserContext);
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const history = useHistory();

  const withManagement = (userData.current.context_type === 1 && userData.current.context_id === teamId) || userContextLevel === 2;
  const links = [
    {
      content: languages.EN.labels.statistics,
      href: generatePath(Routes.team.statistics.base, { id: teamId }),
      visible: withManagement,
    },
    {
      content: languages.EN.labels.teamMembers,
      href: generatePath(Routes.team.members.base, { id: teamId }),
      visible: true,
    },
    {
      content: languages.EN.labels.certificates,
      href: generatePath(Routes.team.certificates.base, { id: teamId }),
      visible: withManagement,
    },
    {
      content: languages.EN.labels.badges,
      href: generatePath(Routes.team.badges.base, { id: teamId }),
      visible: true,
    },
    {
      content: languages.EN.labels.rewardsAndEndorsements,
      href: generatePath(Routes.team.rewards.base, { id: teamId }),
      visible: true,
    },
  ];

  const fetchOrganizationMembersForAssignToTeam = useFetch();
  useEffect(() => {
    if (fetchOrganizationMembersForAssignToTeam.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: languages.EN.labels.selectUser,
          items: fetchOrganizationMembersForAssignToTeam.data.filter(item => item.teams.filter(team => !team.is_manager).length === 0).map(item => ({
            ...item,
            id: item.user.username,
            label: item.user.profile.name ? `${item.user.profile.name} ${item.user.profile.surname} (${item.user.email})` : item.user.email,
          })),
          RenderItem: PopupRenderItem,
          filters: popupFilterDefinitions,
          singleSelect: false,
          postAction: (formData) => teamRepository.postTeamAssign(formData.join(","), teamId),
        },
      });
    }
  }, [fetchOrganizationMembersForAssignToTeam.isReady]);

  const fetchOrganizationMembersForTeamEdit = useFetch();
  useEffect(() => {
    if (fetchOrganizationMembersForTeamEdit.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.team}`,
          fields: popupFields(fetchOrganizationMembersForTeamEdit.data, data, userContextLevel === 2),
          postAction: (formData) => teamRepository.editTeam(teamId, formData),
        },
      })
    }
  }, [fetchOrganizationMembersForTeamEdit.isReady]);

  useDocumentTitle(`${name} | ${languages.EN.labels.team}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={name}
        path={[
          {
            name: languages.EN.labels.team,
            link: "#",
          },
        ]}
        img={profile_image}
        infos={[
          {
            label: languages.EN.labels.manager,
            value: `${manager.name} ${manager.surname}`,
          },
          {
            label: languages.EN.labels.description,
            value: description,
          },
        ]}
        buttons={[].concat(withManagement ? [
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            onClick: () => {
              setIsDataActive(false);
              fetchOrganizationMembersForTeamEdit.fetchData(organizationRepository.getMyOrganizationMembers());
              callPopup({ type: ACTIONS.LOADING });
            },
          },
        ] : []).concat(userContextLevel === 2 ? [
          {
            icon: "garbage",
            name: languages.EN.labels.delete,
            onClick: () => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.team,
                elementName: name,
                action: () => teamRepository.deleteTeam(teamId),
                onSuccess: () => {
                  history.push(generatePath(Routes.organization.teams.base, { id: userData.current.context_id}));
                  callPopup({ type: ACTIONS.REMOVE });
                },
                redirectOnSucces: true,
              },
            }),
          },
        ] : [])}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={generatePath(Routes.team.base, { id: teamId })}>
            <Redirect to={generatePath(withManagement ? Routes.team.statistics.base : Routes.team.members.base, { id: teamId })} />
          </Route>

          <Route path={links[0].href}>
            <TeamStatistics data={data} teamId={teamId} />
          </Route>

          <Route path={links[1].href}>
            <FilterContextProvider key={0}>
              <MembersList
                id="team"
                data={data.team_members}
                teamId={teamId}
                managementLevel={withManagement ? (userContextLevel === 2 ? 2 : 1) : 0}
                onAdd={() => {
                  setIsDataActive(false);
                  fetchOrganizationMembersForAssignToTeam.fetchData(organizationRepository.getMyOrganizationMembers());
                  callPopup({ type: ACTIONS.LOADING })
                }}
              />
            </FilterContextProvider>
          </Route>

          <Route path={links[2].href}>
            <FilterContextProvider key={1}>
              <CertificatesList id="team" data={certificates} skipTeam={true} />
            </FilterContextProvider>
          </Route>

          <Route path={links[3].href}>
            <FilterContextProvider key={2}>
              <BadgesHistoryList
                id="team"
                data={badges}
                withManagement={withManagement}
                viewMoreRoute={generatePath(Routes.learningReport.competitions.base, { type: 1, id: teamId })}
              />
            </FilterContextProvider>
          </Route>

          <Route path={links[4].href}>
            <FilterContextProvider key={3}>
              <RewardEndorsementsList id="team" data={data} withManagement={withManagement} />
            </FilterContextProvider>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default TeamTemplate;
