import React, { useMemo, useState } from "react";
import StatisticBar from "../../../../molecules/StatisticBar/StatisticBar";
import CompoundTableHeader from "../../../../molecules/CompoundTableHeader/CompoundTableHeader";
import CompetenceVerificationTypeCell, { getValueFromData } from "./CompetenceVerificationTypeCell";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../../molecules/ProgressBar/ProgressBar";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import { COMPOUND_HEADER_CONFIG } from "./CompetenceListTable";

function UserCompetencesTable({ reports }) {
  const { data } = reports;
  const [compoundColumnSort, setCompoundColumnSort] = useState(null);

  const items = data.map((item) => ({
    notVerified: item.num_user_competence - item.num_user_competence_manager - item.num_user_competence_test - item.num_user_competence_course,
    testDiff: parseInt((item.test_diff * 100).toFixed(0)),
    ...item,
  }));

  const maxTotalCompetences = Math.max(...items.map((item) => item.num_user_competence));
  const maxTestDiff = Math.max(...items.map((item) => Math.abs(item.test_diff * 100)));

  const columns = useMemo(
    () => [
      {
        Header: languages.EN.labels.name,
        accessor: "name",
      },
      {
        Header: (data) => (
          <CompoundTableHeader
            config={COMPOUND_HEADER_CONFIG}
            type={compoundColumnSort}
            setType={setCompoundColumnSort}
            isSorted={data.column.isSorted}
            isSortedDesc={data.column.isSortedDesc}
            toggle={() => data.toggleSortBy("numberOfCompetences")}
          />
        ),
        id: "numberOfCompetences",
        accessor: (data) => getValueFromData(data, compoundColumnSort),
        Cell: (data) => (
          <CompetenceVerificationTypeCell data={data.cell.row.original} barConfig={COMPOUND_HEADER_CONFIG.items.slice(2)} max={maxTotalCompetences} />
        ),
        disableSortBy: true,
        fullWidth: true,
      },
      {
        Header: languages.EN.labels.passPercentageByCourse,
        accessor: "course_pass_rate",
        sortType: "basic",
        Cell: (data) => (
          <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
        ),
      },
      {
        Header: languages.EN.labels.passPercentageByTest,
        accessor: "initial_pass_rate",
        sortType: "basic",
        Cell: (data) => (
          <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
        ),
      },
      {
        Header: languages.EN.labels.testResoultsComparedToOrtger,
        accessor: "test_diff",
        sortType: "basic",
        Cell: (data) => <StatisticBar max={maxTestDiff} inTable value={data.cell.value * 100} />,
      },
    ],
    [compoundColumnSort]
  );

  return <Table columns={columns} data={items} />;
}

export default UserCompetencesTable;
