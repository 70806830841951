import styles from "./NotificationsTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { languages } from "../../../dummyData/dummyData";
import NotificationsBox from "../../organisms/NotificationsBox/NotificationsBox";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import MainTemplate from "../generic/MainTemplate/MainTemplate";

const NotificationsTemplate = ({ data }) => {
  const unreadData = data.notifications.filter((item) => item.unread);
  const readData = data.notifications.filter((item) => !item.unread);

  const pageHeader = languages.EN.labels.notifications;

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <div className={styles.notifications}>
          <div className={styles.notifications__tableContainer}>
            <NotificationsBox title={languages.EN.labels.unread} data={unreadData} showSettingsButton />
          </div>
          <div className={styles.notifications__tableContainer}>
            <NotificationsBox title={languages.EN.labels.read} data={readData} />
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default NotificationsTemplate;
