import Input from "../Input/Input";
import styles from "./IncrementInput.module.scss";
import arrowBottom from "../../../assets/icons/arrowBottom.svg";
import arrowTop from "../../../assets/icons/arrowTop.svg";
import { useEffect, useState } from "react";

const IncrementInput = ({
  placeholder,
  value,
  required,
  disabled,
  handleInput,
  error,
  name,
  noMargin,
  min = 0,
  max,
  autoFocus,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const parseInputValue = (value) => ["-", ""].includes(value) ? value : (isNaN(value) ? "" : adjustValueToRange(value));
  const adjustValueToRange = (value) => Math.max(max ? Math.min(value, max) : value, min);
  const countValueChangedBy = (change) => adjustValueToRange((isNaN(inputValue) || inputValue === "" ? 0 : parseInt(inputValue)) + change);

  useEffect(() => {
    if (!isNaN(inputValue) && inputValue !== "") {
      handleInput({ target: { name: name, value: adjustValueToRange(inputValue) } });
    }
  }, [inputValue]);

  return (
    <div className={styles.inputWrapper}>
      <Input
        variant="text"
        name={name}
        value={inputValue}
        error={error}
        handleInput={(e) => setInputValue(parseInputValue(e.target.value))}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        noMargin={noMargin}
        onWheel={(e) => e.target.blur()}
        autoFocus={autoFocus}
      />
      <span className={styles.arrowWrapper}>
        <img onClick={() => setInputValue(countValueChangedBy(1))} src={arrowTop} alt="" />
        <img onClick={() => setInputValue(countValueChangedBy(-1))} src={arrowBottom} alt="" />
      </span>
    </div>
  );
};

export default IncrementInput;
