import StarPicker from "react-star-picker";
import RatingStar from "../RatingStar/RatingStar";
import React, { useEffect, useState } from "react";
import Label from "../Label/Label";
import styles from "./StarPickerInput.module.scss";

const StarPickerInput = ({ name, value, required, disabled, placeholder, handleInput, error }) => {
  const [isValue, setIsValue] = useState(false);
  useEffect(() => {
    if (value === null || value === "" || value === undefined) {
      setIsValue(false);
    } else {
      setIsValue(true);
    }
  }, [value]);

  return (
    <Label
      isValue={isValue}
      isFocus={true}
      required={required}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
      transparent
    >
      <fieldset name={name} className={`${styles.hook}`}>
        <StarPicker
          onChange={handleInput}
          value={value}
          starRenderer={RatingStar}
        />
      </fieldset>
    </Label>
  );
}

export default StarPickerInput;
