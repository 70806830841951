import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import NavBar from "../../organisms/NavBar/NavBar";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import Footer from "../../organisms/Footer/Footer";
import CompetenceTestReportCharts from "../../organisms/CompetenceTestReportCharts/CompetenceTestReportCharts";
import styles from "./CompetenceTestReportTemplate.module.scss";
import Button from "../../atoms/Button/Button";
import { Routes } from "../../../routes";
import React from "react";
import { useHistory } from "react-router-dom";

const CompetenceTestReportTemplate = ({ data }) => {
  const pageHeader = languages.EN.labels.competenceTestReport;
  const history = useHistory();

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <CompetenceTestReportCharts data={data} />
        <div className={styles.homeButton}>
          <Button variant="primary" onClick={() => history.push(Routes.studentCompetences.base)}>
            {languages.EN.labels.studentCompetences}
          </Button>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default CompetenceTestReportTemplate;
