import NavBar from "../../organisms/NavBar/NavBar";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import Form from "../../molecules/Form/Form";
import { formFields } from "../generic/NewPasswordTemplate/NewPasswordTemplate";
import { authRepository } from "../../../repository/authRepository";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { PostContext } from "../../../contexts/PostContext";
import styles from "./SettingsTemplate.module.scss";
import PrivacySettingsTable from "../../molecules/PrivacySettingsTable/PrivacySettingsTable";
import SaveBackButtons from "../../molecules/SaveBackButtons/SaveBackButtons";
import { userRepository } from "../../../repository/user.repository";
import Text from "../../atoms/Text/Text";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { settingsRepository } from "../../../repository/settings.repository";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import correct from "../../../assets/icons/correct.svg";
import incorrect from "../../../assets/icons/incorrect.svg";
import Button from "../../atoms/Button/Button";

const SettingsTemplate = ({ privacySettings, mfa }) => {
  const { postData, isPostFinish, lastUsedPromise, postResponse } = useContext(PostContext);
  const { callPopup } = useContext(PopupsContext);
  const [formData, setFormData] = useState(null);

  const handleSetFormData = useCallback((name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    if (isPostFinish && lastUsedPromise === 'enableMfa' && postResponse) {
      callPopup({
        type: ACTIONS.MFA_CONFIRM,
        payload: {
          qrCode: postResponse.qr_code,
        },
      });
    }
  }, [isPostFinish, postResponse]);

  useDocumentTitle(languages.EN.labels.settings);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={languages.EN.labels.settings}
        path={[
          {
            name: languages.EN.labels.settings,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <div>
              <Text s24 w800 secondary>
                {languages.EN.labels.setNewPassword}
              </Text>
            </div>
            <div>
              <Form
                fields={formFields}
                onSave={(formData) => postData(() => authRepository.changePassword(formData))}
                saveLabel={languages.EN.labels.save}
              />
            </div>
            <div>
              <Text s24 w800 secondary>
                {languages.EN.labels.twoFactorAuthentication}
              </Text>
            </div>
            <div className={styles.mfa}>
              <Text s14 dark-6>
                {languages.EN.messages.addMfa}
              </Text>
              <TileItemImgInfo
                header={languages.EN.labels.status}
                value={mfa.active ? languages.EN.labels.enabled : languages.EN.labels.disabled}
                image={mfa.active ? correct : incorrect}
              />
              {mfa.active ? (
                <SaveBackButtons
                  onSave={() => callPopup({
                    type: ACTIONS.CONFIRM,
                    payload: {
                      header: languages.EN.labels.confirmation,
                      content: languages.EN.messages.areYouSureYouWantToDisableMfa,
                      action: () => {
                        postData(
                          () => settingsRepository.disableMfa(),
                          () => callPopup({ type: ACTIONS.REMOVE }),
                          undefined,
                          'disableMfa',
                        );
                      },
                    },
                  })}
                  saveLabel={languages.EN.labels.disable}
                />
              ) : (
                <SaveBackButtons
                  onSave={() => postData(() => settingsRepository.enableMfa(), undefined, undefined, 'enableMfa')}
                  saveLabel={languages.EN.labels.enable}
                />
              )}
            </div>
          </div>
          <div className={styles.box}>
            <Text s24 w800 secondary>
              {languages.EN.labels.privacySettings}
            </Text>
            <PrivacySettingsTable
              items={privacySettings}
              handleSetFormData={handleSetFormData}
            />
            <div className={styles.privacyButtonsWrapper}>
              <Button
                variant="primaryInnerText"
                onClick={() => postData(() => userRepository.resetPrivacySettings(), undefined, undefined, 'resetPrivacySettings')}
              >
                {languages.EN.labels.restoreDefaults}
              </Button>
              <SaveBackButtons onSave={() => postData(() => userRepository.sendUserPrivacySettings(formData), undefined, undefined, 'sendUserPrivacySettings')} />
            </div>
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default SettingsTemplate;
