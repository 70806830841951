import React from "react";
import StatisticBar from "../../../../molecules/StatisticBar/StatisticBar";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../../molecules/ProgressBar/ProgressBar";
import { languages } from "../../../../../dummyData/dummyData";
import { dateFormat } from "../../../../../utils/formatters";
import Table from "../../Table";

function UserCoursesTable({ reports }) {
  const { data } = reports;
  const items = data.map((item) => ({
    type: languages.EN.enums.visibility[item.internal !== false ? 2 : 3],
    category: item.video === false ? languages.EN.labels.pdf : languages.EN.labels.video,
    ...item,
  }));

  const maxDuration = Math.max(...items.map((item) => Math.abs(item.duration_diff * 100)));
  const maxDurUsers = Math.max(...items.map((item) => Math.abs(item.duration_diff_users * 100)));

  const columns = React.useMemo(
    () => [
      {
        Header: languages.EN.labels.student,
        accessor: "name",
      },
      {
        Header: languages.EN.labels.idpProgress,
        accessor: "course_progress",
        sortType: "basic",
        Cell: (data) => (
          <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
        ),
      },
      {
        Header: languages.EN.labels.overdueCourses,
        accessor: "num_overdue",
      },
      {
        Header: languages.EN.labels.estimatedIdpFinishDone,
        accessor: "planned_end",
        Cell: (data) => dateFormat(data.cell.value),
      },
      {
        Header: languages.EN.labels.coursesRealisationTimeComparedExpectTime,
        accessor: "duration_diff",
        sortType: "basic",
        Cell: (data) => (
          <StatisticBar max={maxDuration} reverse={true} value={data.cell.value * 100} />
        ),
      },
      {
        Header: languages.EN.labels.coursesRealisationTimeComparedOtherStudents,
        accessor: "duration_diff_users",
        sortType: "basic",
        Cell: (data) => (
          <StatisticBar max={maxDurUsers} reverse={true} value={data.cell.value * 100} />
        ),
      },
    ],
    [maxDuration, maxDurUsers]
  );

  return <Table columns={columns} data={items} />;
}

export default UserCoursesTable;
