import Text from "../../atoms/Text/Text";
import styles from "./TileItem.module.scss";
import Button from "../../atoms/Button/Button";
import { useContext } from "react";
import { FilterContext } from "../../../contexts/FilterContext";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import { languages } from "../../../dummyData/dummyData";

const TileItem = ({ selectableId, title, subtitle, image, imageFit, bar, buttons, inactive, children }) => {
  const { useSelectables } = useContext(FilterContext);
  const { selected, toggleSelect } = useSelectables;
  const isSelected = selected.includes(selectableId);
  const componentAsImage = typeof image !== 'string';

  return (
    <div
      className={`${styles.tileItem} ${inactive ? styles.inactive : ""} ${buttons === undefined && selectableId === undefined ? styles.noMargin : ""} ${!!selectableId ? styles.selectable : ""}`}
      data-selected={isSelected}
      onClick={() => {
        if (!!selectableId) {
          toggleSelect(selectableId);
        }
      }}
    >
      <div className={styles.tileItem__header}>
        {
          /* 2023-01-31, KW:
             The way how we display the name is weird, but this is the only way that we are able to handle long words in titles.
             There are two different problems here:

             1. On Firefox only lang="de" supports hyphenating words with uppercase letters and it seems they are not going to change that.
                Please refer to: https://bugzilla.mozilla.org/show_bug.cgi?id=656879

             2. On the other hand, we want to hyphenate only words that overflows container, not all words.
                This is still under discussion of CSS Working Group so there is no out-of-box support for that.
                That's why it uses separate <span> for each word.
                Please refer to: https://github.com/w3c/csswg-drafts/issues/616
          */
        }
        <div className={styles.tileItem__name} lang="de">
          <TextTruncated lineClamp={3} secondary>
            <Text s20 lh32 w800 secondary>
              {title ? title.split(" ").map((word, i) => (
                <span key={i}>{word} </span>
              )) : languages.EN.placeholders.empty}
            </Text>
          </TextTruncated>
          {subtitle && (
            <Text s12 lh24 w400 dark-6>
              {subtitle}
            </Text>
          )}
        </div>
        <div className={styles.tileItem__img} data-imagefit={`${imageFit ?? true}`}>
          {!componentAsImage ? <img src={image} alt="" /> : image}
          {inactive && <div className={styles.tileItem__imgInactiveCover}>
            <Text s24 stroke w800>
              {languages.EN.labels.inactive.toUpperCase()}
            </Text>
          </div>}
        </div>
      </div>
      <div className={styles.tileItem__informations}>
        {children}
      </div>
      {(buttons !== undefined || selectableId !== undefined) && (
        <div className={styles.tileItem__buttons}>
          {buttons && buttons.map((button) => (
            <Button
              variant={button.statusVariant === undefined ? "iconButton" : (button.statusVariant ? "iconButtonOn" : "iconButtonOff")}
              onlyIcon
              icon={button.icon}
              key={button.icon}
              onClick={(event) => {
                event.stopPropagation();
                button.onClick();
              }}
              disabled={button.disabled}
              tooltip={button.tooltip}
            />
          ))}
          {!!selectableId && (
            <div
              onClick={() => toggleSelect(selectableId)}
              className={`${styles.tileItem__dot} ${selected.includes(selectableId) && styles.tileItem__dotSelected}`}
            />
          )}
        </div>
      )}
      {bar && (
        <div className={styles.tileItem__bar}>
          {bar}
        </div>
      )}
    </div>
  );
};

export default TileItem;
