import Text from "../Text/Text";
import arrDownChart from "../../../assets/icons/arrDownChart.svg";
import incorrect from "../../../assets/icons/incorrect.svg";
import styles from "./InfoBaner.module.scss";

export const VARIANT_SUCCESS = "succes";
export const VARIANT_ERROR = "error";

const VARIANT_STYLES = {
  [VARIANT_SUCCESS]: styles.succes,
  [VARIANT_ERROR]: styles.deny,
}

const InfoBaner = ({ text, variant }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={VARIANT_STYLES[variant]}>
        <img src={variant === "succes" ? arrDownChart : incorrect} alt="" />
        <Text s14 secondary>
          {text}
        </Text>
      </div>
    </div>
  );
};

export default InfoBaner;
