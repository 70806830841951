import React, { useContext, useEffect, useState } from "react";
import { LoaderOnly } from "../../../atoms/Loader/Loader";
import styles from "./ReportProblemPopup.module.scss";
import { UserContext } from "../../../../contexts/UserContext";
import { languages } from "../../../../dummyData/dummyData";
import Text from "../../../atoms/Text/Text";
import { ReportProblemContext } from "../../../../contexts/ReportProblemContext";

const ReportProblemPopup = () => {
  const [scriptLoaded, setLoaded] = useState(false);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const { userData } = useContext(UserContext);
  const { getSubject } = useContext(ReportProblemContext);

  const name = userData ? userData.name : 'Guest';
  const email = userData ? userData.email : '-';
  const username = userData ? userData.username : '-';

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://app-cdn.clickup.com/assets/js/forms-embed/v1.js";
    script.addEventListener("load", () => setLoaded(true))
    document.body.appendChild(script);
  }, []);

  return scriptLoaded && (
    <>
      <Text>{languages.EN.labels.reportProblem} / {languages.EN.labels.askQuestion}</Text>
      <iframe
        className={styles.form}
        src={`https://forms.clickup.com/3707147/f/3h48b-7248/9UPYY4W0HFZALKKTAC?Subject=${getSubject()}&Name=${name}&Email=${email}&Username=${username}&URL=${window.location.href}&User%20Agent=${navigator.userAgent}&Resolution=${window.screen.width}x${window.screen.height}`}
        onLoad={() => setFrameLoaded(true)}
      />
      {!frameLoaded && (
        <div className={styles.loaderWrapper}>
          <LoaderOnly size={25} />
        </div>
      )}
    </>
  );
};

export default ReportProblemPopup
