import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import { Loader } from "./components/atoms/Loader/Loader";

const NotLoggedRoute = ({ component: Component, ...rest }) => {
  const [isReady, setIsReady] = useState(false);
  const { hasToken, userData, handleSwitchHomepage, getUserContext } = useContext(UserContext);

  useEffect(() => {
    if (hasToken() && userData) {
      handleSwitchHomepage();
    } else if (hasToken()) {
      getUserContext();
    } else {
      setIsReady(true);
    }
  }, [hasToken, userData]);

  return isReady ? (
    <Route
      {...rest}
      render={(props) => <Component {...rest} {...props} />}
    />
  ) : (
    <Loader />
  );
};

export default NotLoggedRoute;
