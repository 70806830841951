import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import CourseAuditItem from "../CourseAuditItem/CourseAuditItem";
import CourseAuditItemTile from "../CourseAuditItemTile/CourseAuditItemTile";
import { dateFormat } from "../../../utils/formatters";
import { coursesRepository } from "../../../repository/courses.repository";
import { TYPE_DATE, TYPE_DROPDOWN, TYPE_FILE, TYPE_TEXTAREA, VARIANT_MULTI_INPUTS_COLUMN } from "../../molecules/Form/Form";
import { VARIANT_GREEN, VARIANT_ORANGE, VARIANT_RED, VARIANT_YELLOW } from "../../atoms/Status/Status";
import Text from "../../atoms/Text/Text";
import { PostContext } from "../../../contexts/PostContext";

const filterDefinitions = [
  {
    keyPath: "created",
    header: languages.EN.labels.createdOn,
    sortId: 1,
  },
  {
    keyPath: "referenceAuditText",
    header: languages.EN.labels.relatedReferenceAudit,
    sortId: 2,
  },
  {
    keyPath: "revision_summary",
    header: languages.EN.labels.revisionSummary,
    sortId: 3,
  },
  {
    keyPath: "actionRequiredText",
    header: languages.EN.labels.actionRequired,
    typeOfFilter: "checkbox",
    sortId: 4,
  },
  {
    keyPath: "statusText",
    header: languages.EN.labels.status,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "actionsSummaryText",
    header: languages.EN.labels.actionsSummary,
    sortId: 5,
  },
  {
    keyPath: "dateText",
    header: languages.EN.labels.deadlineDate,
    typeOfFilter: "datePicker",
    sortId: 6,
  },
];

export const unitsPopupFields = (unitsItems = [], data = {}) => {
  return unitsItems
    .sort((a, b) => a.unit.position - b.unit.position)
    .map((unit, i) => [
      {
        name: 'unit_modified' + i,
        label: `${unit.unit.title} ${languages.EN.labels.shouldBeModified.toLowerCase()}`,
        validation: (formData) => !!formData.action_required && formData['unit_modified' + i] === '' ?
          languages.EN.messages.isRequiredWhenAction :
          undefined,
        required: !!data.action_required,
        requiredMessage: languages.EN.messages.isRequiredWhenAction,
        type: TYPE_DROPDOWN,
        value: unit.modified === true ? 1 : unit.modified === false ? 0 : "",
        items: { "": languages.EN.placeholders.empty, ...languages.EN.enums.yesNo },
        noMargin: true,
      },
      {
        name: 'unit_comment_' + i,
        label: `${unit.unit.title} ${languages.EN.labels.comment.toLowerCase()}`,
        type: TYPE_TEXTAREA,
        value: unit.comment,
        noMargin: true,
      },
    ])
    .concat([
      {
        name: `action_required`,
        label: languages.EN.labels.actionRequired,
        type: TYPE_DROPDOWN,
        value: "",
        items: { "": languages.EN.placeholders.empty, ...languages.EN.enums.courseAuditActions },
      },
    ]);
};

export const copyPopupFields = (data = {}) => [
  {
    name: "name",
    label: languages.EN.labels.name,
    disabled: true,
    value: data.name,
  },
  [
    {
      name: "previous_version",
      label: languages.EN.labels.previousVersion,
      disabled: true,
      value: data.previous_version,
    },
    {
      name: "version",
      label: languages.EN.labels.version,
      required: true,
      value: data.version,
    },
  ],
];

export const popupFields = (data = {}) => [
  {
    name: "revision_summary",
    label: languages.EN.labels.revisionSummary,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.revision_summary,
  },
  {
    name: "due_date",
    label: languages.EN.labels.deadline,
    required: true,
    type: TYPE_DATE,
    value: data.due_date,
  },
  {
    name: "audit_files",
    label: languages.EN.labels.attachFile,
    type: TYPE_FILE,
    value: data.audit_files,
  },
];

export const getItemButtons = (data, callPopup, postData) => {
  const { id, status, action_required, previous_version, courseVersion } = data;

  return [].concat(status !== 9 ? [
    {
      icon: "edit",
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.edit} ${languages.EN.labels.courseAudit}`,
          fields: popupFields(data),
          postAction: (formData) => coursesRepository.editAudit(id, formData),
        },
      }),
      tooltip: languages.EN.labels.edit,
    }
  ] : []).concat(status !== 9 ? [
    {
      icon: "form",
      statusVariant: status !== 0 ? true : undefined,
      disabled: status !== 0,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.fill} ${languages.EN.labels.unitData.toLowerCase()}`,
          fields: (formData) => unitsPopupFields([...data.units], formData),
          onSave: (formData) => {
            const actionRequired = formData.action_required ? { action_required: formData.action_required } : {};
            const payload = {
              ...actionRequired,
              units: data.units.map((unit, i) => ({
                unit_location: unit.unit.id,
                comment: formData['unit_comment_' + i],
                modified: formData['unit_modified' + i]
              })),
            };

            if (formData.action_required !== "") {
              callPopup({
                type: ACTIONS.POST_CONFIRM,
                payload: {
                  header: languages.EN.labels.setRequiredAction,
                  content: (
                    <>
                      {languages.EN.messages.areYouSureYouWantToSetRequiredAction}<br /><br />
                      <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
                    </>
                  ),
                  action: () => coursesRepository.editAuditUnits(id, payload),
                },
              })
            } else {
              return postData(() => coursesRepository.editAuditUnits(id, payload));
            }
          },
          variant: VARIANT_MULTI_INPUTS_COLUMN,
        },
      }),
      tooltip: `${languages.EN.labels.fill} ${languages.EN.labels.unitData.toLowerCase()}`,
    },
  ] : []).concat([3, 4, 5, 6, 7, 8].includes(status) && [2, 3].includes(action_required) ? [
    {
      icon: "play2",
      statusVariant: ![3, 4].includes(status) ? true : undefined,
      disabled: ![3, 4].includes(status),
      onClick: () => callPopup({
        type: ACTIONS.POST_CONFIRM,
        payload: {
          header: languages.EN.labels.startUpdating,
          content: (
            <>
              {languages.EN.messages.areYouSureYouWantToStartCourseUpdateProcess}<br /><br />
              <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
            </>
          ),
          action: () => coursesRepository.editAudit(id, { status: 5 }),
        },
      }),
      tooltip: languages.EN.labels.startUpdating,
    },
  ] : []).concat([5, 6].includes(status) ? [
    {
      icon: "lock",
      statusVariant: status === 6,
      onClick: () => callPopup({
        type: ACTIONS.POST_CONFIRM,
        payload: {
          header: languages.EN.labels.markAsBlocked,
          content: status === 5 ? languages.EN.messages.areYouSureYouWantToMarkThisAuditAsBlocked : languages.EN.messages.areYouSureYouWantToMarkThisAuditAsNotBlocked,
          action: () => coursesRepository.editAudit(id, { status: status === 5 ? 6 : 5 }),
        },
      }),
      tooltip: languages.EN.labels.markAsBlocked,
    },
  ] : []).concat([5, 7, 8].includes(status) && [2, 3].includes(action_required) ? [
    {
      icon: "upload",
      statusVariant: [7, 8].includes(status) ? true : undefined,
      disabled: status !== 5, onClick: () => callPopup({
        type: ACTIONS.POST_CONFIRM,
        payload: {
          header: languages.EN.labels.markAsReadyForUpload,
          content: (
            <>
              {languages.EN.messages.areYouSureYouWantToMarkThisAuditAsReadyForUpload}<br /><br />
              <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
            </>
          ),
          action: () => coursesRepository.editAudit(id, { status: 7 }),
        },
      }),
      tooltip: languages.EN.labels.markAsReadyForUpload,
    },
  ] : []).concat([2, 7, 8].includes(status) ? [
    {
      icon: "clone",
      statusVariant: status === 8 ? true : undefined,
      disabled: status === 8,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: languages.EN.labels.copyCourse,
          fields: copyPopupFields({
            name: previous_version.display_name,
            previous_version: courseVersion,
          }),
          onSave: (formData) => {
            callPopup({
              type: ACTIONS.POST_CONFIRM,
              payload: {
                header: languages.EN.labels.copyCourse,
                content: (
                  <>
                    {languages.EN.messages.areYouSureYouWantToCopyAllUnModifiedUnits}<br /><br />
                    <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
                  </>
                ),
                action: () => coursesRepository.copyAuditCourse(id, formData),
              },
            })
          },
        },
      }),
      tooltip: languages.EN.labels.copyCourse,
    },
  ] : []).concat([1, 8].includes(status) ? [
    {
      icon: "checkMark",
      onClick: () => callPopup({
        type: ACTIONS.POST_CONFIRM,
        payload: {
          header: languages.EN.labels.finalizeAudit,
          content: (
            <>
              {languages.EN.messages.areYouSureYouWantToFinalizeCourseAudit}<br /><br />
              <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
            </>
          ),
          action: () => coursesRepository.finalizeAudit(id),
        },
      }),
      tooltip: languages.EN.labels.finalizeAudit,
    },
  ] : []);
};

export const PROGRESS_BAR_SETTINGS = {
  0: { progress: 0.0, variant: VARIANT_RED }, // "To do"
  1: { progress: 0.5, variant: VARIANT_ORANGE }, // "Should be inactivated"
  2: { progress: 0.3, variant: VARIANT_ORANGE }, // "Should be version bumped"
  3: { progress: 0.2, variant: VARIANT_ORANGE }, // "Should be updated"
  4: { progress: 0.2, variant: VARIANT_ORANGE }, // "Should be inactivated and updated"
  5: { progress: 0.4, variant: VARIANT_YELLOW }, // "In progress"
  6: { progress: 0.2, variant: VARIANT_RED }, // "Blocked"
  7: { progress: 0.6, variant: VARIANT_YELLOW }, // "New course ready for upload"
  8: { progress: 0.8, variant: VARIANT_YELLOW }, // "New course created"
  9: { progress: 1.0, variant: VARIANT_GREEN }, // "Completed"
};

const getRelatedReferenceAuditText = (audit) => audit ?
    audit.previous_version.name + (audit.version ? ` (v ${audit.previous_version.aidify_version} ⇒ ${audit.version.aidify_version})` : '') :
    null;

const CourseAuditsList = ({ data, course }) => {
  const [preparedData, setPreparedData] = useState(null);
  const { callPopup } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);

  useEffect(() => {
    setPreparedData(data.map((item) => ({
      ...item,
      referenceAuditText: getRelatedReferenceAuditText(item.document_audit),
      actionRequiredText: item.status !== 0 ? languages.EN.enums.courseAuditActions[item.action_required] : '',
      dateText: dateFormat(item.due_date),
      statusText: languages.EN.enums.courseAuditStatuses[item.status],
      actionsSummaryText: item.actions_summary || item.version ? <>
        {item.actions_summary && item.actions_summary.split("\n").map((item, i) => <React.Fragment key={i}>
          {item
            .replace(/^Unit: /, '')
            .replace(/modified: (True|False)/, (match, group1) => `${languages.EN.labels.modified}: ${group1 === "True" ? "✔️" : "❌"}`)
            .replace(/comment: (.*)$/, (match, group1) => `${languages.EN.labels.comment}: ${group1 !== '' ? group1 : languages.EN.placeholders.empty}`)
          }<br/>
        </React.Fragment>)}
        {item.actions_summary && item.version && <br/>}
        {item.version && (<>
          <Text w600>{languages.EN.labels.newVersionCreated}:</Text><br/>
          {item.version.display_name} v{item.version.course_version}
        </>)}
      </> : '',
      buttons: getItemButtons({...item, courseVersion: course.course_version}, callPopup, postData),
    })));
  }, [data]);

  return (
    <FilteredList
      id={"coursesAudits"}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={CourseAuditItem}
      RenderTileComponent={CourseAuditItemTile}
      buttons={[
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: () => callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.courseAudit}`,
              fields: popupFields(),
              postAction: (formData) => coursesRepository.addAudit({
                course: course.id,
                ...formData
              }),
            },
          }),
        }
      ]}
    />
  );
};

export default CourseAuditsList;
