import styles from "./CompetenceTestReportCharts.module.scss";
import PieChartTestReport from "../PieChartTestReport/PieChartTestReport";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import CompetenceTestReportTable from "../Table/CompetenceTestReportTable/CompetenceTestReportTable";
import { languages } from "../../../dummyData/dummyData";

const CompetenceTestReportCharts = ({ data, courseId, attempt }) => {
  return (
    <div className={styles.competenceTestReport}>
      <div className={styles.competenceTestReport__box}>
        <PieChartTestReport data={data.section_data} />
      </div>
      <div className={styles.competenceTestReport__box}>
        <TableWrapper
          Table={CompetenceTestReportTable}
          reports={{ testId: data.id, data: data.section_data, courseId: courseId, attempt: attempt }}
          title={languages.EN.labels.details}
        />
      </div>
    </div>
  );
};

export default CompetenceTestReportCharts
