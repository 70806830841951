export const SCOPE_USER = "user";
export const SCOPE_LEARNING = "learning";
export const SCOPE_FILES = "files";
export const SCOPE_MARKETPLACE = "marketplace";
export const SCOPE_CHAT = "chat";
export const SCOPE_ORGANIZATION = "organization";
export const SCOPE_TEAM = "team";
export const SCOPE_WORKGROUP = "workgroup";
export const SCOPE_PLATFORM = "platform";
export const SCOPE_AUDIT = "audit";
export const SCOPE_IDP = "idp";
export const SCOPE_COMPETENCE_FRAMEWORK = "competence_framework";
export const SCOPE_COMPETENCE_FRAMEWORK_EDIT = "competence_framework_edit";
export const SCOPE_LEARNING_EVENT = "learning_event";
export const SCOPE_REGULATORY_INTELLIGENCE = "regulatory_intelligence";
export const SCOPE_LEARNING_REPORT = "learning_report";
export const SCOPE_USER_LEARNING_REPORT = "user_learning_report";
export const SCOPE_INSTRUCTOR = "instructor";
