import { useContext, useEffect } from "react";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import LandingTemplate from "../generic/LandingTemplate/LandingTemplate";
import { authRepository } from "../../../repository/authRepository";
import { PostContext } from "../../../contexts/PostContext";
import InfoBaner, { VARIANT_ERROR, VARIANT_SUCCESS } from "../../atoms/InfoBaner/InfoBaner";
import Form from "../../molecules/Form/Form";
import { Routes } from "../../../routes";
import { useHistory } from "react-router-dom";
import useFlashMessages from "../../../hooks/useFlashMessages";

const formFields = [
  {
    name: "email",
    label: languages.EN.labels.email,
    required: true,
  },
];

const PasswordResetTemplate = () => {
  const { postData, isPostFinish, isResponseOk } = useContext(PostContext);
  const { flashMessages, addFlashMessage } = useFlashMessages();
  const history = useHistory();

  useEffect(() => {
    if (isPostFinish && isResponseOk === false) {
      addFlashMessage(VARIANT_ERROR, languages.EN.messages.waitFewMoments);
    }
  }, [isPostFinish, isResponseOk]);

  const pageHeader = `${languages.EN.labels.forgotPassword}?`;
  useDocumentTitle(pageHeader);

  return (
    <LandingTemplate header={pageHeader} description={languages.EN.messages.enterEmailToReset}>
      {flashMessages.map((message, i) => <InfoBaner key={i} variant={message.type} text={message.content} />)}
      <Form
        fields={formFields}
        onSave={(formData) => postData(
          () => authRepository.resetPassword(formData),
          () => {
            history.push(Routes.mainPage.base);
            addFlashMessage(VARIANT_SUCCESS, languages.EN.messages.emailSendResetPassword);
          }
        )}
        saveLabel={languages.EN.labels.send}
        onBack={() => history.push(Routes.mainPage.base)}
        autoFocus={true}
      />
    </LandingTemplate>
  );
};
export default PasswordResetTemplate;
