import styles from "./SearchInput.module.scss";
import loupe from "../../../assets/icons/loupe.svg";
import { languages } from "../../../dummyData/dummyData";
import { useContext } from "react";
import { FilterContext } from "../../../contexts/FilterContext";

const SearchInput = ({ placeholder, minWidth }) => {
  const { useSearchBar, } = useContext(FilterContext);
  const { searchBarValue, setSearchBarValue } = useSearchBar;

  return (
    <div
      className={styles.searchInputWrapper}
      style={minWidth ? { minWidth: minWidth } : null}
    >
      <div className={styles.searchIcon}>
        <img src={loupe} alt="Search Icon" />
      </div>
      <input
        className={styles.searchInput}
        type="text"
        value={searchBarValue}
        placeholder={placeholder ? placeholder : languages.EN.labels.searchForAnything}
        onChange={(e) => {
          setSearchBarValue(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchInput;
