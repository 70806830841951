import DOMPurify from "dompurify";
import { concatClassNames } from "../../../utils";
import styles from "./Html.module.scss";

const Html = ({ children, ...props }) => {
  props = { "html": true, ...props };
  const escapedHTML = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: [
      "a", "b", "br", "caption", "code", "col", "colgroup", "div", "em", "i", "img", "li", "ol", "p", "pre",
      "s", "span", "span", "strike", "strong", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "u", "ul",
      "h1", "h2", "h3", "h4", "h5", "h6"
    ],
    ALLOWED_ATTR: ["style", "href", "colspan", "rowspan", "type", "target", "rel"],
  });

  return <span className={concatClassNames(props, styles)} dangerouslySetInnerHTML={{ __html: escapedHTML }} />;
};

export default Html;
