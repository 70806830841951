import React from "react";
import jobPositions from "../../../assets/icons/statistics/jobPositions.svg";
import courses from "../../../assets/icons/statistics/courses.svg";
import competences from "../../../assets/icons/statistics/competences.svg";
import timeOnPlatform from "../../../assets/icons/statistics/timeOnPlatform.svg";
import organizationMembers from "../../../assets/icons/statistics/organizationMembers.svg";
import teamMembers from "../../../assets/icons/statistics/teamMembers.svg";
import { languages } from "../../../dummyData/dummyData";
import Statistics from "../Statistics/Statistics";
import { durationInHours } from "../../../utils/formatters";

const CareerPathDetailsStatistics = ({ data }) => {
  const { num_positions, num_courses, num_org_members, num_competences, num_team_members, duration } = data.career_path;

  return (
    <Statistics
      data={[
        {
          icon: jobPositions,
          content: languages.EN.labels.jobPosition,
          value: num_positions,
        },
        {
          icon: courses,
          content: languages.EN.labels.courses,
          value: num_courses,
        },
        {
          icon: competences,
          content: languages.EN.labels.competences,
          value: num_competences,
        },
        {
          icon: timeOnPlatform,
          content: languages.EN.labels.totalDuration,
          value: durationInHours(duration),
        },
        {
          icon: organizationMembers,
          content: languages.EN.labels.orgMembersOnThisPath,
          value: num_org_members,
        },
        {
          icon: teamMembers,
          content: languages.EN.labels.teamMemnersOnThisPath,
          value: num_team_members,
        },
      ]}
    />
  );
};

export default CareerPathDetailsStatistics;
