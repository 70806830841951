import styles from "./DropdownLink.module.scss";
import Text from "../Text/Text";
import Icons from "../../../assets/icons";

const DropdownLink = ({ children, icon, onClick }) => {

  return (
    <span onClick={onClick} className={`${styles.DropDownLink}`}>
      {Icons[icon]}
      <Text s12 lh14 w600>
        {children}
      </Text>
    </span>
  );
};

export default DropdownLink;
