import styles from "./HomepageTemplate.module.scss"
import NavBar from "../../organisms/NavBar/NavBar";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CourseCarouselBig from "../../organisms/CourseCarouselBig/CourseCarouselBig";
import CourseCarousel from "../../organisms/CourseCarousel/CourseCarousel";
import InstructorCarousel from "../../organisms/InstructorCarousel/InstructorCarousel";
import HomepageInfoBig from "../../organisms/HomepageInfoBig/HomepageInfoBig";
import idp from "../../../assets/homepage/idp.jpg";
import newCourse from "../../../assets/homepage/newCourse.jpg";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { UserContext } from "../../../contexts/UserContext";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { TYPE_AUTOCOMPLETE, TYPE_DROPDOWN, TYPE_RADIO_INPUTS, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { homePageRepository } from "../../../repository/homePage.repository";
import { SCOPE_IDP } from "../../../constants/scopes";

export const GROUPS = {
  'urgent': ['overdue_lp_courses', 'overdue_manager_courses', 'near_overdue_lp_courses'],
  'continue': ['in_progress_lp_courses', 'in_progress_courses'],
  'development': ['next_lp_courses', 'user_development_courses', 'future_courses', 'competence_improvement_courses'],
  'recommended': ['recommended_courses'],
  'popular': ['popular_courses'],
  'new': ['new_courses'],
};

const removeCourseDuplicates = (courses) => {
  const courseIds = courses.map(course => course.id);

  return courses.filter((course, index) => courseIds.indexOf(course.id) === index);
};

const prepareCourseCarousels = (data) => Object.entries(GROUPS).map(([key, groups]) => ({
  title: languages.EN.labels.coursesFastFiltersValues[key],
  courses: removeCourseDuplicates(groups.map(name => data[name]).flat(1)).slice(0, 10),
}));

const popupFields = (instructorItems = [], data = {}) => [
  {
    name: "topic",
    label: languages.EN.labels.learningArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.topic,
    items: languages.EN.enums.learningAreas,
  },
  {
    name: "name",
    label: languages.EN.labels.courseName,
    required: true,
    value: data.name,
  },
  {
    name: "level",
    label: languages.EN.labels.experienceLevel,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.level,
    items: languages.EN.enums.experienceLevels,
  },
  {
    name: "reason",
    label: languages.EN.labels.whyThisCourseShouldBeOnThePlatform,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.reason,
  },
  {
    name: "type",
    label: languages.EN.labels.form,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.type,
    items: languages.EN.enums.courseReason,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "agenda",
    label: languages.EN.labels.agenda,
    type: TYPE_TEXTAREA,
    value: data.agenda,
  },
  {
    name: "learning_objectives",
    label: languages.EN.labels.learningGoals,
    type: TYPE_TEXTAREA,
    value: data.learning_objectives,
  },
  {
    name: "learning_outcomes",
    label: languages.EN.labels.learningOutcomes,
    type: TYPE_TEXTAREA,
    value: data.learning_outcomes,
  },
  {
    type: TYPE_RADIO_INPUTS,
    label: languages.EN.labels.lecturer,
    inputs: [
      {
        name: "instructor",
        label: languages.EN.labels.aidify,
        type: TYPE_AUTOCOMPLETE,
        value: data.instructor,
        items: instructorItems.map((instructor) => ({
          id: instructor.id,
          label: instructor.name,
          photo: instructor.profile_image_small,
        }))
      },
      {
        name: "instructor_text",
        label: languages.EN.labels.external,
        value: data.instructor_text,
      },
    ],
  },
];

const HomepageTemplate = ({ homepage, instructors }) => {
  const [transparentNavBar, setTransparentNavBar] = useState(true);
  const preparedCourseCarouselsData = useMemo(() => prepareCourseCarousels(homepage), [homepage]);
  const preparedInstructorData = useMemo(() => instructors.sort((a, b) => (a.name.split(" ")[1] > b.name.split(" ")[1]) ? 1 : -1), [instructors]);
  const { callPopup } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);
  const history = useHistory();

  useDocumentTitle(languages.EN.labels.homepage);

  useEffect(() => {
    const handleScroll = () => {
      setTransparentNavBar(window.pageYOffset / window.innerHeight < 0.9);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={styles.homepage}>
      <NavBar transparent={transparentNavBar} />
      <CourseCarouselBig courses={removeCourseDuplicates([...homepage.user_development_courses, ...homepage.popular_courses]).slice(0, 10)} />
      {preparedCourseCarouselsData.map((carouselData, i) => (
        <CourseCarousel key={i} id={i} title={carouselData.title} courses={carouselData.courses} hMargins={5} />
      ))}
      {userData.scopes.includes(SCOPE_IDP) && (
        <HomepageInfoBig textSmall={languages.EN.messages.doYouWantControlDevelopment} textBig={languages.EN.messages.createIDP} button={{
          text: languages.EN.labels.goToIdp,
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.idps.base, { username: userData.username })),
        }} image={idp} />
      )}
      <InstructorCarousel title={languages.EN.labels.instructors} instructors={preparedInstructorData} />
      <HomepageInfoBig textSmall={languages.EN.messages.newCourseIdea} textBig={languages.EN.messages.useSuggestionForm} button={{
        text: languages.EN.labels.clickHere,
        icon: "form",
        onClick: () => {
          callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: languages.EN.labels.suggestNewCourse,
              fields: popupFields(instructors),
              postAction: (formData) => homePageRepository.sendNewCourseSuggestion(formData),
            },
          });
        }
      }} image={newCourse} />
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default HomepageTemplate
