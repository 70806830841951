import { login_endpoint, registration_endpoint, password_reset_endpoint, password_set_endpoint, password_change_endpoint, registration_login_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const signIn = (data) => {
  return fetch(login_endpoint, {
    method: "POST",
    body: preparePostFormBody(data),
  });
};

const tokenLogin = (data) => {
  return fetch(registration_login_endpoint, {
    method: "POST",
    body: preparePostFormBody(data),
  });
};

const resetPassword = (data) => {
  return fetch(password_reset_endpoint, {
    method: "POST",
    body: preparePostFormBody(data),
  });
};

const resetPasswordConfirm = (uidb36, token, data) => {
  return fetch(`${password_set_endpoint}${uidb36}-${token}/`, {
    method: "POST",
    body: preparePostFormBody(data),
  });
};

const changePassword = (data) => {
  return fetch(password_change_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const authRepository = {
  signIn,
  tokenLogin,
  resetPassword,
  resetPasswordConfirm,
  changePassword,
};
