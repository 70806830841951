import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import NavBar from "../../organisms/NavBar/NavBar";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import styles from "./ReferenceControlTemplate.module.scss";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import ReferencesTable from "../../organisms/Table/ReferenceControl/ReferencesTable/ReferencesTable";

const ReferenceControlTemplate = ({ data, isReady }) => {
  const pageHeader = languages.EN.labels.referenceControl;
  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate padTop90>
        <div className={styles.references}>
          <TableWrapper
            title={languages.EN.labels.references}
            Table={ReferencesTable}
            reports={{ data: data, isReady: isReady }}
          />
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default ReferenceControlTemplate;
