import styles from "./TimelineChart.module.scss";
import Chart from "react-apexcharts";
import { languages } from "../../../dummyData/dummyData";
import Text from "../Text/Text";

const parseBackendItem = (item, i) => {
  const startDate = new Date(item.start_date);
  const endDate = new Date(item.end_date);

  return {
    x: `${i+1}. ${item.name}`,
    y: [
      startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000,
      endDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000,
    ],
    fillColor: "#00A094",
  };
};

const TimelineChart = ({ data }) => {
  const state = {
    options: {
      chart: {
        id: "gantt",
        height: data.learning_plan_items.length * 65,
        type: "rangeBar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
        },
      },
      grid: {
        row: {
          colors: ["#f3f4f5", "#fff"],
          opacity: 1,
        },
      },
      xaxis: {
        type: "datetime",
        min: data.learning_plan[0]?.start_date,
        max: data.learning_plan[0]?.end_date,
        labels: {
          format: "dd MMMM",
        },
      },
    },
    series: [
      {
        data: data.learning_plan_items.map((item, j) => parseBackendItem(item, j))
      },
    ],
  };

  return (
    <div className={data.learning_plan_items.length === 0 ? styles.chartWrapperNoData : styles.chartWrapper}>
      {data.length === 0 ? (
        <Text dark-2 s18>{languages.EN.labels.noData}</Text>
      ) : (
        <Chart
          options={state.options}
          series={state.series}
          type="rangeBar"
          height={data.learning_plan_items.length * 65}
        />
      )}
    </div>
  );
};

export default TimelineChart;
