import styles from "./QuestionsTable.module.scss";
import genericStyles from "../../Table.module.scss";
import React, { useContext, useMemo } from "react";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import { coursesRepository } from "../../../../../repository/courses.repository";
import Button from "../../../../atoms/Button/Button";
import SingleChoiceAnswer from "./Answers/SingleChoiceAnswer";
import MultipleChoiceAnswer from "./Answers/MultipleChoiceAnswer";
import FillHolesAnswer from "./Answers/FillHolesAnswer";
import PickValidOrderAnswer from "./Answers/PickValidOrderAnswer";
import PairUpAnswer from "./Answers/PairUpAnswer";

const renderAnswers = (data) => {
  const {question, answers, correct, type} = data;
  let correctSplitted;

  switch (type) {
    case 0:
      correctSplitted = correct.split(",");

      return answers.map((answer, index) => (
        <SingleChoiceAnswer key={index} index={index} answer={answer} correct={correctSplitted} />
      ));

    case 1:
      correctSplitted = correct.split(",");

      return answers.map((answer, index) => (
        <MultipleChoiceAnswer key={index} index={index} answer={answer} correct={correctSplitted} />
      ));

    case 2:
      let correctAnswers = [];
      const questionParts = question.split("___").filter(part => part !== "");

      for (let i = 0; i < questionParts.length; i++) {
        const start = correct.indexOf(questionParts[i]) + questionParts[i].length;
        const end = i + 1 < questionParts.length ? correct.indexOf(questionParts[i + 1]) : correct.length;

        if (start !== end) {
          correctAnswers.push(correct.substring(start, end));
        }
      }

      return [
        ...correctAnswers.map((answer, index) => (
          <FillHolesAnswer key={index} answer={answer} correct={true} />
        )),
        ...answers.filter(answer => correctAnswers.indexOf(answer) === -1).map((answer, index) => (
          <FillHolesAnswer key={correctAnswers.length + index} answer={answer} correct={false} />
        )),
      ];

    case 3:
      correctSplitted = correct.split("|");

      return correctSplitted.map((answer, index) => (
        <PickValidOrderAnswer key={index} index={index} answer={answer} />
      ));

    case 4:
      correctSplitted = correct.split("|");

      return (
        <div className={styles.pairWrapper}>
          {answers.slice(0, answers.length / 2).map((answer1, index) => (
            <PairUpAnswer key={index} answer1={answer1} answer2={correctSplitted[index]} />
          ))}
        </div>
      );
    default:
      return <></>;
  }
};

const getQuestionData = (data, id) => {
  const questions = data.slice().reduce((acc, section) => acc.concat(section.unit_data.reduce((acc, unit) => acc.concat(unit.problems.filter(problem => problem.block_id === id).map(problem => ({
    unit_id: unit.block_id,
    question: problem.question,
    correct: problem.correct,
    answers: problem.answers.join("|"),
    type: problem.type.toString(),
  }))), [])), []);

  return questions.length > 0 ? questions[0] : null;
}

const QuestionsTable = ({ reports }) => {
  const { callPopup } = useContext(PopupsContext);
  const { data } = reports;

  const items = data.reduce((acc, section) => acc.concat(section.unit_data.reduce((acc, unit) => acc.concat(unit.problems.map((problem) => ({
    ...problem,
    questionType: languages.EN.enums.questionTypes[problem.type],
    sectionName: section.title,
    unitName: unit.title,
  }))), [])), []);

  const units = data.map((section) => section.unit_data.map((unit) => ({
    label: section.title + ", " + unit.title,
    id: unit.block_id,
  }))).flat(1).reverse();

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.section,
      accessor: "sectionName",
    },
    {
      Header: languages.EN.labels.unit,
      accessor: "unitName",
    },
    {
      Header: languages.EN.labels.type,
      accessor: "questionType",
    },
    {
      Header: languages.EN.labels.question,
      accessor: "question",
    },
    {
      Header: languages.EN.labels.answers,
      accessor: "answers",
      Cell: (data) => renderAnswers(data.row.original),
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (cellData) => (
        <div className={genericStyles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={() => callPopup({
            type: ACTIONS.ADD_QUESTION,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.question}`,
                questionData: getQuestionData(data, cellData.row.original.block_id),
                units: units,
                sendHandler: (formData) => coursesRepository.editQuestion(cellData.row.original.block_id, formData),
              },
            })}
            tooltip={languages.EN.labels.edit}
          />
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() => callPopup({
            type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.question,
                elementName: cellData.row.original.question,
                action: () => coursesRepository.deleteQuestion(cellData.row.original.block_id),
              },
            })}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ], [data]);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.ADD_QUESTION,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.question}`,
          units: units,
          sendHandler: coursesRepository.addQuestion,
        },
      })
    },
  ]} columns={columns} data={items} />;
}

export default QuestionsTable;
