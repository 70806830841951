import styles from "./IdpLearningPlanTable.module.scss";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../molecules/ProgressBar/ProgressBar";
import { languages } from "../../../../dummyData/dummyData";
import TableNoDataInformation from "../TableNoDataInformation/TableNoDataInformation";
import React from "react";
import { dateFormat } from "../../../../utils/formatters";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import Button from "../../../atoms/Button/Button";
import TextTruncated from "../../../atoms/TextTruncated/TextTruncated";

const headers = [
  languages.EN.labels.courseName,
  languages.EN.labels.plannedStart,
  languages.EN.labels.actualStart,
  languages.EN.labels.plannedEnding,
  languages.EN.labels.actualEnding,
  languages.EN.labels.progress,
  languages.EN.labels.plannedNoOfMinutes,
  languages.EN.labels.recommendedNoOfMinutes,
];

const IdpLearningPlanTable = ({ data }) => {
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <table className={styles.table} id="table">
        <thead>
          <tr className={styles.greenRow}>
            {headers.map((item, i) => (
              <th key={i} className={styles.tableHeader}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td style={{ display: "flex", flexDirection: "row", gap: "20px"}}>
                <TextTruncated lineClamp={1}>{item.name}</TextTruncated>
                <Button
                  variant="iconButton"
                  onlyIcon
                  icon={"dots"}
                  key={"dots"}
                  onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: item.course_id }))}
                  tooltip={languages.EN.labels.details}
                />
              </td>
              <td>{dateFormat(item.start_date)}</td>
              <td>{item.actual_start !== null ? dateFormat(item.actual_start) : languages.EN.placeholders.empty}</td>
              <td>{dateFormat(item.end_date)}</td>
              <td>{item.actual_end !== null ? dateFormat(item.actual_end) : languages.EN.placeholders.empty}</td>
              <td><ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={item.progress * 100} /></td>
              <td>{item.plannedNumberOfMinutes}</td>
              <td>{item.recommendedNumberOfMinutes}</td>
            </tr>
          ))}
          {data.length > 0 && (
            <tr className={styles.greenRow}>
              <td>{languages.EN.labels.summary}</td>
              <td>{data.length > 0 ? dateFormat(data[0].start_date) : languages.EN.placeholders.empty}</td>
              <td>{data.actual_start ? data.actual_start : languages.EN.placeholders.empty}</td>
              <td>{data.length > 0 ? dateFormat(data[data.length - 1].end_date) : languages.EN.placeholders.empty}</td>
              <td>{data.actual_end ? data.actual_end : languages.EN.placeholders.empty}</td>
              <td>{(data.reduce((acc, curr) => acc + curr.progress, 0) * 100 / data.length).toFixed()}%</td>
              <td>{data.reduce((acc, curr) => acc + curr.plannedNumberOfMinutes, 0)}</td>
              <td>{data.reduce((acc, curr) => acc + curr.recommendedNumberOfMinutes, 0)}</td>
            </tr>
          )}
        </tbody>
      </table>
      <TableNoDataInformation data={data} />
    </div>
  );
};

export default IdpLearningPlanTable;
