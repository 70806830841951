import CourseViewTemplate from "../../templates/CourseViewTemplate/CourseViewTemplate";
import useFetch from "../../../hooks/useFetch";
import { coursesRepository } from "../../../repository/courses.repository";
import { useContext, useEffect, useState } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { UserConfigContext } from "../../../contexts/UserConfigContext";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";

export const PROGRESS_TYPE_VIDEO = 'video';
export const PROGRESS_TYPE_PDF = 'pdf';
export const PROGRESS_TYPE_MATERIAL = 'material';

export const RESET_PARAM = 'reset';

const CourseView = ({ match }) => {
  useDocumentTitleLoading();
  const [userConfig, setUserConfig] = useContext(UserConfigContext);
  const history = useHistory();

  const fetchCourseDetails = useFetch();
  useEffect(() => {
    fetchCourseDetails.fetchData(coursesRepository.getCourseDetails(match.params.id));
  }, []);

  const [lastVideoStatistics, setLastVideoStatistics] = useState(0);
  const [lastPdfStatistics, setLastPdfStatistics] = useState(0);
  const [lastMaterialStatistics, setLastMaterialStatistics] = useState(0);
  const [activeItemId, setActiveItemId] = useState();
  const [playbackRate, setPlaybackRate] = useState(userConfig?.courseView?.playbackRate ?? 1);

  const handleProgress = (type, itemId, progressData = {}) => {
    setActiveItemId(itemId);
    const now = Date.now()

    switch (type) {
      case PROGRESS_TYPE_VIDEO:
        if (progressData.played > 0 && (lastVideoStatistics === 0 || lastVideoStatistics + 10 * 1000 <= now)) {
          setLastVideoStatistics(now);
        }
        break;
      case PROGRESS_TYPE_PDF:
        if (lastPdfStatistics === 0 || lastPdfStatistics + 10 * 1000 <= now) {
          setLastPdfStatistics(now);
        }
        break;
      case PROGRESS_TYPE_MATERIAL:
        if (lastMaterialStatistics === 0 || lastMaterialStatistics + 10 * 1000 <= now) {
          setLastMaterialStatistics(now);
        }
        break;
    }
  };

  const handlePlaybackRateChange = (value) => {
    setPlaybackRate(value);
    setUserConfig((prev) => ({
      ...prev,
      courseView: { ...prev?.courseView, playbackRate: value },
    }));
  };

  const fetchVideoStatistics = useFetch();
  useEffect(() => {
    if (lastVideoStatistics > 0) {
      fetchVideoStatistics.fetchData(coursesRepository.sendUserVideoStatistics({ course: match.params.id, item_id: activeItemId, playback_rate: playbackRate }));
    }
  }, [lastVideoStatistics]);

  const fetchPdfStatistics = useFetch();
  useEffect(() => {
    if (lastPdfStatistics > 0) {
      fetchPdfStatistics.fetchData(coursesRepository.sendUserPdfStatistics({ course: match.params.id, item_id: activeItemId }));
    }
  }, [lastPdfStatistics]);

  const fetchMaterialStatistics = useFetch();
  useEffect(() => {
    if (lastMaterialStatistics > 0) {
      fetchMaterialStatistics.fetchData(coursesRepository.sendUserMaterialStatistics({ course: match.params.id, item_id: activeItemId }));
    }
  }, [lastMaterialStatistics]);

  const fetchCourseContent = useFetch();
  useEffect(() => {
    if (fetchCourseContent.data === null || fetchVideoStatistics.isReady || fetchPdfStatistics.isReady) {
      fetchCourseContent.fetchData(coursesRepository.getCourseContent(match.params.id));
    }
  }, [fetchVideoStatistics.isReady, fetchPdfStatistics.isReady])

  useEffect(() => {
    if (match.params.reset === RESET_PARAM) {
      fetchCourseContent.resetData();
      fetchCourseContent.fetchData(coursesRepository.getCourseContent(match.params.id));
      history.push(generatePath(Routes.courseView.about.base, { id: match.params.id }));
    }
  }, [match.params.reset])

  return fetchCourseDetails.isReady && fetchCourseContent.data && match.params.reset !== RESET_PARAM ? (
    <CourseViewTemplate
      details={fetchCourseDetails.data}
      content={fetchCourseContent.data}
      onProgress={handleProgress}
      playbackRate={playbackRate}
      onPlaybackRateChange={handlePlaybackRateChange}
    />
  ) : (
    <Loader />
  );
};

export default CourseView;
