import {
  profile_endpoint,
  profile_image,
  user_reward_endpoint,
  endorsement_endpoint,
  privacy_settings_endpoint,
  user_reward_mark_claimed,
  user_competence_endpoint,
  user_learning_report_endpoint, registration_endpoint,
} from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const editUserPhoto = (username, data) => {
  return fetch(`${profile_image}${username}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const getRegistrationData = () => {
  return fetch(registration_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const saveRegistrationData = (username, data) => {
  let promises = [];

  if (data.profile_image instanceof File) {
    promises.push(editUserPhoto(username, { profile_image: data.profile_image }))
    delete data.profile_image;
  }

  promises.push(
    fetch(registration_endpoint, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(),
      },
      body: preparePostFormBody(data),
    })
  );

  return Promise.all(promises);
};

const getUserProfile = (username, viewAs) => {
  return fetch(`${profile_endpoint}${username}/${viewAs ? `?view_as=${viewAs}` : ''}`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const editUserProfile = (username, data) => {
  let promises = [];

  if (data.profile_image instanceof File) {
    promises.push(editUserPhoto(username, { profile_image: data.profile_image }))
    delete data.profile_image;
  }

  promises.push(
    fetch(`${profile_endpoint}${username}/`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(),
      },
      body: preparePostFormBody(data),
    })
  );

  return Promise.all(promises);
};

const deleteUserPhoto = (id, profileImage) => {
  const formData = new FormData();

  formData.append("username", id);
  formData.append("profile_image", profileImage);

  return fetch(`${profile_image}${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getUserPrivacySettings = () => {
  return fetch(privacy_settings_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const sendUserPrivacySettings = (data) => {
  return fetch(privacy_settings_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const resetPrivacySettings = () => {
  return fetch(`${privacy_settings_endpoint}reset/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const sendUserCompetenceVerify = (data) => {
  return fetch(`${user_competence_endpoint}verify/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const sendClaimReward = (reward_id) => {
  return fetch(user_reward_mark_claimed, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `reward_id=${reward_id}`,
  });
};

const getCompetences = (username) => {
  return fetch(`${user_competence_endpoint}${username}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const saveCompetences = (username, data) => {
  return fetch(`${user_competence_endpoint}${username}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const getLearningReport = (username, data) => {
  return fetch(`${user_learning_report_endpoint}${username}/?${new URLSearchParams(data)}`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addReward = (data) => {
  return fetch(`${user_reward_endpoint}add/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const addEndorsement = (data) => {
  return fetch(`${endorsement_endpoint}add/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const userRepository = {
  deleteUserPhoto,
  sendUserCompetenceVerify,
  sendClaimReward,
  getCompetences,
  saveCompetences,

  editUserPhoto,
  getRegistrationData,
  saveRegistrationData,
  getUserProfile,
  editUserProfile,
  getUserPrivacySettings,
  resetPrivacySettings,
  sendUserPrivacySettings,
  getLearningReport,
  addReward,
  addEndorsement,
};
