import React, { useContext, useEffect } from "react";
import styles from "./NotificationsBox.module.scss";
import Text from "../../atoms/Text/Text";
import NotificationItem from "../../molecules/NotificationItem/NotificationItem";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import ItemsWithPagination from "../../organisms/ItemsWithPagination/ItemsWithPagination";
import { PostContext } from "../../../contexts/PostContext";
import { notificationsRepository } from "../../../repository/notifications.repository";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";
import useFetch from "../../../hooks/useFetch";

const NotificationsBox = ({ title, showSettingsButton, data }) => {
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);

  const onPageDisplay = (offset, limit) => {
    const ids = data.slice(offset, offset + limit).filter(notification => notification.unread).map(notification => notification.id);

    if (ids.length > 0) {
      postData(() => notificationsRepository.markReadNotification({ ids: ids }));
    }
  }

  useEffect(() => {
    onPageDisplay(0, 8)
  }, []);

  const fetchNotificationSettings = useFetch();
  useEffect(() => {
    if (fetchNotificationSettings.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.NOTIFICATION,
        payload: {
          header: languages.EN.labels.notificationsSettings,
          data: fetchNotificationSettings.data,
        },
      });
    }
  }, [fetchNotificationSettings.isReady]);

  return (
    <div className={styles.container}>
      <HeaderWithButtons header={title} buttons={showSettingsButton ? [
        {
          icon: "settings2", label: languages.EN.labels.notificationsSettings, variant: "primary", onClick: () => {
            setIsDataActive(false);
            fetchNotificationSettings.fetchData(notificationsRepository.getNotificationSettings());
            callPopup({ type: ACTIONS.LOADING });
          },
        },
      ] : []} />
      <div className={styles.notificationsContainer}>
        {data.length > 0 ? (
          <ItemsWithPagination
            noMargin
            items={data}
            RenderComponent={NotificationItem}
            onPageChange={onPageDisplay}
          />
        ) : (
          <div className={styles.notificationsInfo}>
            <Text s16 lh32 dark-2>
              {languages.EN.labels.noNotifications}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsBox;
