import styles from "./../../molecules/Popups/SelectPopup/SelectPopup.module.scss";
import React, { useState } from "react";
import Input from "../Input/Input";
import SelectedItem from "../SelectedItem/SelectedItem";
import { LoaderOnly } from "../Loader/Loader";
import Checkbox from "../Checkbox/Checkbox";
import Text from "../Text/Text";
import RadioButton from "../RadioButton/RadioButton";
import { languages } from "../../../dummyData/dummyData";

const SearchBackendUsersPopupContent = ({
  items,
  placeholder,
  handleCheckbox,
  handleDeleteSelected,
  singleSelect,
  setInput,
  selected,
  isReady,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInput = (e) => {
    setInputValue(e.target.value);
    setInput && setInput(e.target.value);
  };

  return (
    <div className={styles.rightWrapper}>
      <Input
        placeholder={placeholder}
        handleInput={handleInput}
        value={inputValue}
        searchBar={true}
        noMargin
      />
      <div className={styles.selectedWrapper}>
        {selected.map((selectedId, i) => {
          const item = items.find(item => item.id.toString() === selectedId);

          return (
            <SelectedItem
              key={i}
              text={item.label}
              item={item}
              onClick={handleDeleteSelected}
            />
          )
        })}
      </div>
      {isReady ? (
        <div className={styles.listWrapper}>
          {items.map((item, i) => (
            <label key={i}>
              {singleSelect ?
                <RadioButton variant="primary" checked={selected.includes(item.id.toString())} handleChange={handleCheckbox} value={item.id} /> :
                <Checkbox checked={selected.includes(item.id.toString())} disabled={item.disabled} handleChange={handleCheckbox} value={item.id} />
              }
              <Text s12 dark-4>
                {item.label}
              </Text>
            </label>
          ))}
          {items.length === 0 && (
            <div className={styles.information}>
              <Text s16 lh21 dark-2>
                {languages.EN.labels.thereAreNoResults}
              </Text>
            </div>
          )}
        </div>
      ) : (
        <LoaderOnly />
      )}
    </div>
  );
};

export default SearchBackendUsersPopupContent;
