import styles from "./SearchChat.module.scss";
import loupe from "../../../assets/icons/loupe.svg";
import { languages } from "../../../dummyData/dummyData";

const { searchForAnything } = languages.EN.labels;

const SearchChat = ({ minWidth, placeholder, onChange, value }) => {
  return (
    <div
      className={styles.searchInputWrapper}
      style={minWidth ? { minWidth: minWidth } : null}
    >
      <div className={styles.searchIcon}>
        <img src={loupe} alt="Search Icon" />
      </div>

      <input
        className={styles.searchInput}
        type="text"
        onChange={onChange}
        value={value}
        placeholder={placeholder ? placeholder : searchForAnything}
      />
    </div>
  );
};

export default SearchChat;
