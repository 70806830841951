import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import JobPosition from "../../organisms/JobPosition/JobPosition";
import JobPositionTile from "../../organisms/JobPositionTile/JobPositionTile";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { TYPE_DROPDOWN, TYPE_IMAGE, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { UserContext } from "../../../contexts/UserContext";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "careerPaths",
    header: languages.EN.labels.careerPaths,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 7,
  },
  {
    keyPath: "experienceLevel",
    header: languages.EN.labels.experienceLevel,
    typeOfFilter: "checkbox",
    sortId: 4,
  },
  {
    keyPath: "country",
    header: languages.EN.labels.region,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "num_org_members",
    header: languages.EN.labels.noOfOrganizationMembers,
    typeOfFilter: "range",
    sortId: 5,
  },
  {
    keyPath: "num_team_members",
    header: languages.EN.labels.noOfTeamMembers,
    typeOfFilter: "range",
    sortId: 6,
  },
  {
    keyPath: "isActive",
    header: languages.EN.labels.isActive,
    typeOfFilter: "checkbox",
    defaultValues: [languages.EN.enums.yesNo[1]],
  },
];

export const popupFields = (data = {}) => [
  {
    name: "name",
    label: languages.EN.labels.name,
    required: true,
    value: data.name,
  },
  {
    name: "topic",
    label: languages.EN.labels.learningArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.topic,
    items: languages.EN.enums.learningAreas,
  },
  {
    name: "level",
    label: languages.EN.labels.experienceLevel,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.level,
    items: languages.EN.enums.experienceLevels,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "country",
    label: languages.EN.labels.region,
    required: true,
    value: data.country,
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    required: true,
    type: TYPE_IMAGE,
    value: data.profile_image,
  },
];

const JobPositionWithManagement = ({ data }) => (
  <JobPosition data={data} withManagement={true} />
);

const JobPositionTileWithManagement = ({ data }) => (
  <JobPositionTile data={data} withManagement={true} />
);

const JobPositionsList = ({ data, withManagement }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.organization !== null ? 2 : 3;

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.topic],
      careerPaths: item.career_paths.map((career_path) => career_path.name),
      experienceLevel: languages.EN.enums.experienceLevels[item.level],
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.organization.profile_image_small : <AidifyLogo />,
      isActive: languages.EN.enums.yesNo[item.active ? 1 : 0],
      canEdit: withManagement === true && (
        (type === 2 && userContextLevel === 2) ||
        (type === 3 && userContextLevel === 3)
      ),
    };
  })), [data]);

  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);

  return (
    <FilteredList
      id="jobPositions"
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={withManagement ? JobPositionWithManagement : JobPosition}
      RenderTileComponent={withManagement ? JobPositionTileWithManagement : JobPositionTile}
      buttons={[2, 3].includes(userContextLevel) && withManagement === true ? [
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: () => callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.jobPosition}`,
              fields: popupFields(),
              postAction: jobPositionsRepository.addJobPosition,
            },
          }),
        }
      ] : []}
    />
  );
};

export default JobPositionsList;
