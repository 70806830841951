import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import styles from "./FilterPanel.module.scss";
import { languages } from "../../../dummyData/dummyData";
import { useContext } from "react";
import FiltersWithHeader from "../../molecules/FiltersWithHeader/FiltersWithHeader";
import React from "react";
import { FilterContext } from "../../../contexts/FilterContext";

const FilterPanel = ({ inPopup }) => {
  const { useFilterDefinitions, useFilterReset } = useContext(FilterContext);
  const { filterDefinitions } = useFilterDefinitions;
  const { setFilterReset } = useFilterReset;

  const handleResetFilteredData = () => {
    setFilterReset(true);
  };

  return (
    <div className={styles.filterPanelWrapper} data-popup={inPopup}>
      <div className={inPopup ? styles.filterPanelPopup : styles.filterPanel}>
        <div className={styles.filterPanelHeader}>
          <Text w800 s14 dark-4>
            {languages.EN.labels.filterResults}
          </Text>
        </div>

        <div className={styles.filters}>
          {filterDefinitions && filterDefinitions.filter(definition => definition.typeOfFilter !== undefined).map((definition, i) => (
            <FiltersWithHeader key={i} definition={definition} />
          ))}
        </div>

        <div className={styles.buttons}>
          <Button
            variant="primaryInnerText"
            withIcon
            icon="filterIcon"
            onClick={handleResetFilteredData}
          >
            {languages.EN.labels.resetFilters}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
