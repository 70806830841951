import React, { useEffect, useState } from "react";
import styles from "./DragDrop.module.scss";

const DragDropBoard = ({ id, type, onDrop, minWidth, children }) => {
  const [isDragEnter, setIsDragEnter] = useState(false);

  const drop = (e) => {
    if (type === "container" || e.target.children.length === 0) {
      e.preventDefault();
      const cardId = e.dataTransfer.getData("card_id");

      onDrop(cardId);
    }
  }

  const dragEnd = (e) => {
  //   // if (e.target.style.display === "none") {
  //   //   e.target.style.display = "flex";
  //   //   e.target.style.background = "";
  //   // }
    setIsDragEnter(false);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = () => {
    setIsDragEnter(true);
  };

  const dragLeave = () => {
    setIsDragEnter(false);
  };

  useEffect(() => {
    setIsDragEnter(false);
  }, [onDrop]);

  return (
    <div
      id={id}
      className={`${type === "answer" ? styles.answer : ''} ${type === "container" ? styles.container : ''}`}
      onDrop={drop}
      onDragEnd={dragEnd}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      data-drag-enter={isDragEnter}
      style={{ minWidth: minWidth && `${minWidth}px` }}
    >
      {children}
    </div>
  );
};

export default DragDropBoard;
