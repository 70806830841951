import styles from "../QuestionsTable.module.scss";
import React from "react";

const PickValidOrderAnswer = ({ index, answer }) => {
  return (
    <div className={styles.validOrderRow}>
      <span className={styles.validOrderNumber}>{index + 1}</span>
      <span className={styles.validOrderAnswer}>
        {answer}
      </span>
    </div>
  );
};

export default PickValidOrderAnswer
