import Input from "../Input/Input";
import styles from "./CalendarInput.module.scss";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import calendar from "../../../assets/icons/calendarGray.svg";
import { isValidDateString } from "../../../utils/dateHelpers";
import CustomInput from "../CustomInput/CustomInput";

const CustomInputWithLabel = forwardRef(({ customInputData, ...props }, ref) => {
  const handleInput = (e) => {
    const date = isValidDateString(e.target.value) ? new Date(e.target.value) : undefined;

    customInputData.handleInput({
      target: {
        name: e.target.name,
        value: date !== undefined ? (new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)).toISOString() : e.target.value,
      }
    })
  };

  return (
    <div className={styles.input}>
      {customInputData.noLabel ? (
        <CustomInput
          reference={ref}
          {...props}
          {...customInputData}
          onChange={handleInput}
        />
      ) : (
        <Input
          variant="text"
          reference={ref}
          {...props}
          {...customInputData}
          handleInput={handleInput}
        />
      )}
      <img src={calendar} className={styles.calendarIcon} onClick={props.onClick} alt="" />
    </div>
  );
});

const CalendarInput = (props) => {
  return (
    <div className={styles.inputDatePicker} data-no-label={props.noLabel}>
      <DatePicker
        onChange={(date) => props.handleInput({
          target: {
            name: props.name,
            value: (new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)).toISOString(),
          }
        })}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={isValidDateString(props.value) ? new Date(props.value) : undefined}
        customInput={<CustomInputWithLabel customInputData={props} />}
        dateFormat="yyyy/MM/dd"
      />
    </div>
  );
};

export default CalendarInput;
