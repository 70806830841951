import { instructor_dashboard_endpoint, instructor_endpoint, instructor_review_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getInstructors = () => {
  return fetch(instructor_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getInstructor = (id) => {
  return fetch(`${instructor_endpoint + id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getDashboard = (data) => {
  return fetch(`${instructor_dashboard_endpoint}?${(new URLSearchParams(data)).toString()}`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addReview = (id, data) => {
  return fetch(`${instructor_review_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteReview = (id) => {
  return fetch(`${instructor_review_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const instructorRepository = {
  getInstructors,
  getInstructor,
  getDashboard,
  addReview,
  deleteReview,
};
