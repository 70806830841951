import { notification_endpoint, notification_settings_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getUserNotifications = (start, limit) => {
  return fetch(notification_endpoint + (start !== undefined && limit !== undefined ? "?" + new URLSearchParams({ start: start, limit: limit }) : ""), {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getNotificationSettings = () => {
  return fetch(notification_settings_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const sendNotificationSettings = (data) => {
  return fetch(notification_settings_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const markReadNotification = (data) => {
  return fetch(`${notification_endpoint}mark_read/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const notificationsRepository = {
  getUserNotifications,
  getNotificationSettings,
  sendNotificationSettings,
  markReadNotification,
};
