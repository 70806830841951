import styles from "./FilterCheckbox.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import Checkbox from "../../atoms/Checkbox/Checkbox";

const FilterCheckbox = ({ item, handleChange }) => {
  return (
    <label className={styles.wrapper}>
      <Checkbox checked={item.isChecked} disabled={item.number === 0 && item.isChecked === false} handleChange={handleChange} />
      <span
        className={`${styles.label} ${
          item.number === 0 ? styles.label__disabled : ""
        }`}
      >
        <TextTruncated lineClamp={1}>
          <Text s12 dark-6 lh20>
            {`${item.name === null ? "--none--" : item.name} (${item.number})`}
          </Text>
        </TextTruncated>
      </span>
    </label>
  );
};

export default FilterCheckbox;
