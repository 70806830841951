import useFetch from "../../../hooks/useFetch";
import { useEffect } from "react";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { instructorRepository } from "../../../repository/instructor.repository";
import InstructorDashboardTemplate from "../../templates/InstructorDashboardTemplate/InstructorDashboardTemplate";
import { Loader } from "../../atoms/Loader/Loader";
import { gettQuarterDatesRange } from "../../../utils/dateHelpers";
import moment from "moment";

const InstructorDashboard = ({ match }) => {
  const { data, loading, fetchData } = useFetch();
  const currentQuarter = moment().quarter().toString();
  const currentYear = moment().year().toString();
  const selectedQuarter = match.params.quarter ?? currentQuarter;
  const selectedYear = match.params.year ?? currentYear;

  useDocumentTitleLoading();

  useEffect(() => {
    if (selectedQuarter && selectedYear) {
      fetchData(instructorRepository.getDashboard(selectedQuarter !== "0" && selectedYear !== "0" ? gettQuarterDatesRange(selectedQuarter, selectedYear) : {}));
    }
  }, [selectedQuarter, selectedYear]);

  return data && !loading ? (
    <InstructorDashboardTemplate data={data} quarter={selectedQuarter} year={selectedYear} currentSelected={selectedQuarter === currentQuarter && selectedYear === currentYear} />
  ) : (
    <Loader />
  );
};

export default InstructorDashboard
