import styles from "./StatisticBar.module.scss";
import Text from "../../atoms/Text/Text";

const StatisticBar = ({ value, reverse, max }) => {
  return (
    <div className={styles.statisticBar}>
      <div
        style={{ width: (Math.abs(value) / max) * 100 + "px", }}
        className={`
          ${styles.statisticBar__background} 
          ${((value > 0 && reverse) || (value < 0 && !reverse)) ? styles.statisticBar__red : styles.statisticBar__green}
          ${(value > 0) ? styles.statisticBar__right : styles.statisticBar__left}
        `}
      />
      <span className={value > 0 ? styles.textContainerLeft : styles.textContainerRight}>
        <Text s12 lh24 dark-2>
          {`${value > 0 ? '+' : ''}${value.toFixed(2)} %`}
        </Text>
      </span>
      <span className={styles.centerLine}/>
    </div>
  );
};

export default StatisticBar;
