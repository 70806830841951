import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import GlobalFilter from "../../molecules/TableGlobalFilter/GlobalFilter";
import styles from "./Table.module.scss";
import Button from "../../atoms/Button/Button";
import React, { useEffect, useState } from "react";
import TableHeader from "../../molecules/TableHeader/TableHeader";
import TableNoDataInformation from "./TableNoDataInformation/TableNoDataInformation";
import TablePagination from "../../molecules/TablePagination/TablePagination";
import { TableSubrowLoader } from "../../atoms/Loader/Loader";

export const ROW_VARIANT_ERROR = 'error';
export const ROW_VARIANT_WARNING = 'warning';
const Table = ({ buttons, columns, data, filterInitialValue, searchBar = true, pagination = true, footer = false, expand, getRowProps = () => ({}), isReady = true }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 8,
        sortBy: [
          {
            id: columns[0].accessor,
            desc: false,
          },
        ],
      },
      disableSortRemove: true,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;
  const { globalFilter } = state;
  const [expandedRows, setExpandedRows] = useState([]);

  const isExpanded = (row) => expandedRows.includes(row.original.id);
  const gotoPageByItemId = (id) => {
    const index = rows.map(row => row.original.id).indexOf(id);
    const page = parseInt(index / pageSize);
    gotoPage(page);
  }
  const changeExpandedRow = (id) => {
    setExpandedRows([id]);
    gotoPageByItemId(id);
  }

  useEffect(() => {
    if (isReady) {
      if (typeof expand === "function" && expandedRows.length === 1) {
        expand(expandedRows[0], changeExpandedRow);
      } else if (expand === true) {
        setExpandedRows(data.map(item => item.id));
      }
    }
  }, [isReady]);

  let renderedInPlaceholder = [];

  const findRealHeader = (column) => {
    if (column.placeholderOf !== undefined) {
      renderedInPlaceholder.push(column.placeholderOf.id);
      column = findRealHeader(column.placeholderOf);
    }
    return column;
  }

  return (
    <>
      <div className={styles.userPanel} data-condensed={!searchBar && buttons?.length > 0}>
        {searchBar && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} initialValue={filterInitialValue} />}
        {buttons !== undefined ? buttons.map((button, i) => (
          <div className={styles.userPanel__button} key={i}>
            <Button
              variant={button.variant ?? "primaryW100"}
              icon={button.icon}
              disabled={button.disabled}
              withIcon={!button.disabled}
              useIconsFile
              onClick={button.onClick}
              tooltip={button.tooltip}
            >
              {button.label}
            </Button>
          </div>
        )) : ''}
      </div>
      <table className={styles.table} {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              if (renderedInPlaceholder.indexOf(column.id) === -1) {
                column = findRealHeader(column);

                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    rowSpan={`${column.rowSpan ?? 1}`}
                    className={`${column.displayNone ? styles.displayNone : ''} ${styles.tableHeader}`}
                  >
                    <TableHeader column={column}>
                      {column.render("Header")}
                    </TableHeader>
                  </th>
                )
              } else {
                return null;
              }
            })}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {isReady ? page.map((row, i) => {
          prepareRow(row);
          const rowProps = getRowProps(row);

          return (
            <React.Fragment key={i}>
              <tr
                {...row.getRowProps()}
                className={`
                  ${expandedRows[row.index] === true ? styles.tableRowClicked : expand !== undefined ? styles.tableRowClickable : ''} 
                  ${rowProps[ROW_VARIANT_ERROR] ? styles.tableRowError : ''} 
                  ${rowProps[ROW_VARIANT_WARNING] ? styles.tableRowWarning : ''} 
                `}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    onClick={() => {
                      const isAlreadyExpanded = isExpanded(row);
                      if (typeof expand === "function") {
                        setExpandedRows(isAlreadyExpanded ? [] : [row.original.id]);
                        if (!isAlreadyExpanded) {
                          expand(row.original.id, changeExpandedRow);
                        }
                      } else if (typeof expand === "boolean") {
                        setExpandedRows(isAlreadyExpanded ? expandedRows.filter(id => id !== row.original.id) : [...expandedRows, row.original.id]);
                      }
                    }}
                    style={cell.column.cellStyle}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
              {isExpanded(row) && row.subRows.map((subRow, j) => {
                prepareRow(subRow);
                return (
                  <tr key={`${i}.${j}`} className={styles.tableSubRow}>
                    {subRow.cells.map((subCell, j) => (
                      <td {...subCell.getCellProps()} style={subCell.column.cellStyle}>
                        {j === 0 && <div className={styles.tableSubRow__leftBorder} />}
                        {subCell.render("Cell")}
                        {j === subRow.cells.length - 1 && <div className={styles.tableSubRow__rightBorder} />}
                      </td>
                    ))}
                  </tr>
                )
              })}
              {isExpanded(row) && row.original.subRow && (
                <tr key={`${i}.1`} className={styles.tableSubRow}>
                  <td colSpan={row.cells.length}>
                    <div className={`${styles.tableSubRow__leftBorder} ${styles.tableSubRow__borderH100}`} />
                    {row.original.subRow}
                    <div className={`${styles.tableSubRow__rightBorder} ${styles.tableSubRow__borderH100}`} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        }) : <TableSubrowLoader />}
        </tbody>
        {footer && <tfoot>
        {footerGroups.map((footerGroup) => (
          <tr {...footerGroup.getFooterGroupProps()}>
            {footerGroup.headers.map((column) => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
        </tfoot>}
      </table>
      <TableNoDataInformation data={page} />
      <br />
      {pagination && (
        <div className={styles.pagination}>
          <TablePagination
            data={data}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageOptions={pageOptions}
            globalFilter={globalFilter}
          />
        </div>
      )}
    </>
  );
};

export default Table;
