import RatingStars from "../../../../atoms/RatingStars/RatingStars";
import { languages } from "../../../../../dummyData/dummyData";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../../molecules/ProgressBar/ProgressBar";
import Table from "../../Table";
import React, { useMemo } from "react";

function InstructorListTable({ reports }) {
  const { data } = reports;

  const items = data.map((item) => ({
    id: item.name,
    ...item,
    rating: item.instructor_rating,
    subRows: item.course_reports.map((courseReport) => ({
      ...courseReport,
      name: courseReport.course_title,
      rating: courseReport.course_rating,
      is_subrow: true,
    })),
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.instructor,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.coursesPassPercentage,
      accessor: "pass_rate",
      sortType: "basic",
      Cell: (data) => (
        <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
      ),
    },
    {
      Header: languages.EN.labels.coursesFinishPercentage,
      accessor: "completion_rate",
      sortType: "basic",
      Cell: (data) => (
        <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} />
      ),
    },
    {
      Header: languages.EN.labels.coursesAttendantsNumber,
      accessor: "num_students",
    },
    {
      Header: languages.EN.labels.rating,
      accessor: "rating",
      sortType: "basic",
      Cell: (data) => data.cell.value > 0 ? <RatingStars rating={data.cell.value} /> : <hr/>,
    },
    {
      Header: languages.EN.labels.searchPopularity,
      accessor: "search_hits",
    },
  ], []);

  return <Table columns={columns} data={items} expand={false} />;
}

export default InstructorListTable;
