import styles from "./JobPositionsChart.module.scss";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import React, { useCallback, useContext, useEffect, useRef, useState, } from "react";
import { Tree } from "react-organizational-chart";
import JobPositionsChartSegment from "../JobPositionsChartSegment/JobPositionsChartSegment";
import JobPositionsChartElement from "../../molecules/JobPositionsChartElement/JobPositionsChartElement";
import { languages } from "../../../dummyData/dummyData";
import useFetch from "../../../hooks/useFetch";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";

const popupFilterDefinitions = [
  {
    keyPath: "label",
  },
  {
    keyPath: "learningArea",
    typeOfFilter: "checkbox",
    header: languages.EN.labels.learningArea,
  },
  {
    keyPath: "visibility",
    typeOfFilter: "checkbox",
    header: languages.EN.labels.visibility,
  },
  {
    keyPath: "country",
    typeOfFilter: "checkbox",
    header: languages.EN.labels.region,
  },
];

const PopupRenderItem = ({ item }) => {
  return (
    <Text s12 dark-4>
      {item.name}
    </Text>
  );
}

export const getPopupPayload = (jobPositions, usedJobPositions, callPopup, handleNewChartElement) => ({
  header: languages.EN.labels.selectJobPosition,
  items: jobPositions.filter(item => item.active && !usedJobPositions.includes(item.id)).map(item => ({
    ...item,
    id: item.id,
    item: item,
    label: item.name,
    learningArea: languages.EN.enums.learningAreas[item.topic],
    visibility: languages.EN.enums.visibility[item.organization !== null ? 2 : 3],
  })),
  RenderItem: PopupRenderItem,
  filters: popupFilterDefinitions,
  singleSelect: true,
  onSave: (formData) => {
    callPopup({ type: ACTIONS.REMOVE });
    handleNewChartElement({ id: formData[0], name: jobPositions.find(item => item.id === parseInt(formData[0])).name })
  },
});

const JobPositionsChart = ({ data, isIdp, isEdited, onChartChange }) => {
  const [chart, setChart] = useState(data ? data : []);
  const [usedJobPositions, setUsedJobPositions] = useState([]);
  const [clickedId, setClickedId] = useState(null);
  const jobChartWrapperRef = useRef(null);
  const [isScroll, setIsScroll] = useState(false);
  const { callPopup, setIsDataActive } = useContext(PopupsContext);

  const fetchJobPositions = useFetch();
  useEffect(() => {
    if (fetchJobPositions.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: getPopupPayload(fetchJobPositions.data, usedJobPositions, callPopup, handleNewChartElement),
      });
    }
  }, [fetchJobPositions.isReady]);

  // TODO: There is no need for local "chart" - it should be passed from CareerPathDetailsJobPositionChart
  useEffect(() => {
    onChartChange && onChartChange(chart);
  }, [chart]);

  const handleChartChange = useCallback(() => {
    !isIdp && setChart((prev) => [...prev]);
  }, [isIdp]);

  const handleNewChartElement = (newItem) => {
    setChart([
      ...chart,
      {
        id: newItem.id,
        name: newItem.name,
        children: [],
      },
    ]);
  };

  const handleAddUsedPositionId = useCallback((id) => {
    setUsedJobPositions((prev) => [...prev, id]);
  }, []);
  useEffect(() => {
    setIsScroll(jobChartWrapperRef.current.scrollWidth > jobChartWrapperRef.current.offsetWidth);
  }, [chart]);

  useEffect(() => {
    isIdp && setChart(data);
  }, [data, isIdp]);

  return (
    <div className={styles.Wrapper} data-idp={isIdp}>
      <div className={styles.chartWrapper}>
        <div
          key={isScroll}
          data-active={isScroll}
          data-idp={isIdp}
          ref={jobChartWrapperRef}
          className={styles.jobWrapper}
        >
          {chart.length === 0 && !isIdp && (
            <div className={styles.firstJob}>
              {isEdited ? (
                <Text light-5 s18>
                  {languages.EN.messages.createJobPosition}
                </Text>
              ) : (
                <Text light-5 s18>{languages.EN.messages.noPositionYet}</Text>
              )}
              {isEdited && (
                <Button
                  variant={"primaryW100"}
                  withIcon
                  icon={"plus"}
                  useIconsFile={true}
                  onClick={() => {
                    setIsDataActive(false);
                    fetchJobPositions.fetchData(jobPositionsRepository.getJobPositions());
                    callPopup({ type: ACTIONS.LOADING });
                  }}
                >
                  {languages.EN.labels.addJobPosition}
                </Button>
              )}
            </div>
          )}
          {chart.length > 0 && chart[0] !== null && (
            <div className={styles.chart}>
              <Tree
                className={styles.Tree}
                lineWidth={"1px"}
                lineHeight={"50px"}
                lineColor={"#00A094"}
                lineBorderRadius={"20px"}
                label={
                  <JobPositionsChartElement
                    isEdited={isEdited}
                    handleChartChange={handleChartChange}
                    setClickedId={setClickedId}
                    clickedId={clickedId}
                    usedJobPositions={usedJobPositions}
                    setUsedJobPositions={setUsedJobPositions}
                    first
                    setChart={setChart}
                    children={chart[0] !== null && chart[0].children ? chart[0].children.length : 0}
                    item={chart[0]}
                    isIdp={isIdp}
                    handleAddUsedPositionId={handleAddUsedPositionId}
                  />
                }
              >
                <JobPositionsChartSegment
                  usedJobPositions={usedJobPositions}
                  handleAddUsedPositionId={handleAddUsedPositionId}
                  setClickedId={setClickedId}
                  clickedId={clickedId}
                  parent={chart[0]}
                  data={chart[0].children}
                  handleChartChange={handleChartChange}
                  isIdp={isIdp}
                  isEdited={isEdited}
                />
              </Tree>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPositionsChart;
