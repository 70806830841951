import { team_endpoint, license_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getTeam = (id) => {
  return fetch(`${team_endpoint}${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addTeam = (data) => {
  return fetch(team_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editTeam = (id, data) => {
  return fetch(`${team_endpoint}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteTeam = (id) => {
  return fetch(`${team_endpoint}${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const postTeamAssign = (users, id, is_manager) => {
  return fetch(`${team_endpoint}assign/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `users=${users}&team=${id}`,
  });
};

const postTeamUnassign = (name, id) => {
  return fetch(`${team_endpoint}unassign/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
    body: `user=${name}&team=${id}`,
  });
};

const sendLicenseRemind = (data) => {
  return fetch(`${license_endpoint}remind/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const teamRepository = {
  getTeam,
  addTeam,
  editTeam,
  deleteTeam,

  postTeamAssign,
  postTeamUnassign,
  sendLicenseRemind,
};
