import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItem from "../TileItem/TileItem";
import { languages } from "../../../dummyData/dummyData";
import { useContext } from "react";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { marketplaceRepository } from "../../../repository/marketplace.repository";
import { popupFields } from "../../templates/MarketplaceTemplate/MarketplaceTemplate";
import { UserContext } from "../../../contexts/UserContext";
import { currencyFormat } from "../../../utils/formatters";

const MarketItemTile = ({ data }) => {
  const { id, profile_image_small, name, description, category, price } = data;
  const { callPopup } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);

  return (
    <TileItem title={name} image={profile_image_small} buttons={[
      {
        icon: "cart", onClick: () => callPopup({
          type: ACTIONS.FORM,
          payload: {
            header: languages.EN.labels.sendRequest,
            fields: popupFields(data, userData),
            postAction: (formData) => marketplaceRepository.sendRequest(id, formData),
          },
        }),
      },
    ]}>
      <TileItemInfo header={languages.EN.labels.description} value={description} fullRow={true} />
      <TileItemInfo header={languages.EN.labels.category} value={category} />
      <TileItemInfo header={languages.EN.labels.price} value={currencyFormat(price)} />
    </TileItem>
  );
};

export default MarketItemTile;
