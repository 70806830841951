import styles from "../QuestionsTable.module.scss";
import Checkbox from "../../../../../atoms/Checkbox/Checkbox";
import Text from "../../../../../atoms/Text/Text";
import React from "react";

const MultipleChoiceAnswer = ({ index, answer, correct }) => {
  return (
    <div>
      <label className={styles.wrapper}>
        <Checkbox checked={correct.includes(index.toString())} handleChange={() => {}} />
        <span className={styles.label}>
          <Text s14 lh17>
            {answer}
          </Text>
        </span>
      </label>
    </div>
  );
};

export default MultipleChoiceAnswer
