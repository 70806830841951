import styles from "./AgendaSection.module.scss";
import Text from "../../../atoms/Text/Text";
import AgendaUnit from "../Unit/AgendaUnit";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { languages } from "../../../../dummyData/dummyData";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import tickIcon from "../../../../assets/icons/tickIcon.svg";
import { FilterContext } from "../../../../contexts/FilterContext";
import { useCurrentRoute } from "../../../../hooks/useCurrentRoute";

const AgendaSection = ({ courseId, section, activeUnitId, completedUnits, expandable }) => {
  const { callPopup } = useContext(PopupsContext);
  const { useOriginalData } = useContext(FilterContext);
  const currentRoute = useCurrentRoute(Routes.courseView);
  const { originalData } = useOriginalData;
  const history = useHistory();

  const sectionCompleted = originalData?.reduce((acc, curr) => acc && (curr.unit.title === languages.EN.labels.competenceTest || completedUnits?.includes(curr.unit.block_id)), true);

  const getCompetenceTestTextProps = (competenceTestUnitId) => {
    let props = { s12: true, lh14: true };

    if (competenceTestUnitId === activeUnitId) {
      props['primary'] = true;
    } else if (sectionCompleted) {
      props['dark-4'] = true;
      props['w700'] = true;
    } else {
      props['light-5'] = true;
      props['w400'] = true;
    }

    return props;
  }

  const handleCompetenceTest = () => {
    if (section.competence_test.status === 2) {
      history.push(generatePath(currentRoute.base, { id: courseId, block: section.competence_test.id }));
    } else {
      callPopup({
        type: ACTIONS.CONFIRM,
        payload: {
          header: languages.EN.labels.confirmation,
          content: languages.EN.messages.verifyCourse,
          action: () => {
            callPopup({ type: ACTIONS.REMOVE });
            history.push(generatePath(Routes.competenceTest.test.base, { id: section.competence_test.id, course: courseId }));
          },
        },
      });
    }
  }

  const handleSectionNameClick = () => {
    if (!expandable) {
      history.push(generatePath(currentRoute.base, { id: courseId, block: section.block_id }));
    }
  }

  return (
    <>
      <div
        onClick={handleSectionNameClick}
        style={{ cursor: "pointer" }}
        className={styles.sectionTitle}
      >
        <Text s14 w700 lh16 dark-4>
          {section.title}
        </Text>
      </div>
      {expandable ? (
        <div className={styles.unitWrapper}>
          <div className={styles.line} />
          <div>
            {section.unit_data.length > 0 ? section.unit_data.map((unit) => (
              unit.title !== languages.EN.labels.competenceTest ? (
                <AgendaUnit
                  key={unit.block_id}
                  courseId={courseId}
                  unit={unit}
                  isActive={activeUnitId === unit.block_id}
                  isCompleted={completedUnits.indexOf(unit.block_id) !== -1}
                />
              ) : (
                <div
                  key={uuidv4()}
                  className={!sectionCompleted && section.competence_test.status !== 2 ? styles.testUnclickable : styles.testClickable}
                  onClick={handleCompetenceTest}
                >
                  <Text {...getCompetenceTestTextProps(unit.block_id)}>
                    {languages.EN.labels.competenceTest}
                    {section.competence_test.status === 2 ? (<img src={tickIcon} alt="icon" style={{ marginLeft: "5px" }} />) : ''}
                  </Text>
                </div>
              )
            )) : (
              <Text s14 dark-6>
                {languages.EN.labels.noData}
              </Text>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AgendaSection;
