import React, { useContext } from "react";
import { userRepository } from "../../../repository/user.repository";
import { languages } from "../../../dummyData/dummyData";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import TileItem from "../TileItem/TileItem";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import { TYPE_REWARD } from "../RewardEndorsementsList/RewardEndorsementsList";

const RewardEndorsementTile = ({ data, withManagement }) => {
  const { type, preparedType, description, profile_image_small, receiver, sender, preparedCreatedDate, preparedClaimedDate } = data;
  const { callPopup } = useContext(PopupsContext);

  return (
    <TileItem title={preparedType} subtitle={description} image={profile_image_small} buttons={withManagement && type === TYPE_REWARD && preparedClaimedDate === null ? [
      {
        icon: "checkMark", onClick: () => callPopup({
          type: ACTIONS.POST_CONFIRM,
          payload: {
            header: languages.EN.labels.confirmation,
            content: languages.EN.messages.areYouSureYouWantToConfirm,
            action: () => userRepository.sendClaimReward(data.id),
          },
        }),
      },
    ] : []}>
      <TileItemImgInfo header={languages.EN.labels.from} value={`${sender.profile.name} ${sender.profile.surname}`} image={sender.profile.profile_image_medium} />
      <TileItemImgInfo header={languages.EN.labels.for} value={`${receiver.profile.name} ${receiver.profile.surname}`} image={receiver.profile.profile_image_medium} />
      <TileItemInfo header={languages.EN.labels.received} value={preparedCreatedDate} />
      <TileItemInfo header={languages.EN.labels.collected} value={preparedClaimedDate} />
    </TileItem>
  );
};
export default RewardEndorsementTile;
