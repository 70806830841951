import React, { useMemo, useState } from "react";
import Text from "../../atoms/Text/Text";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss";
import SwiperCore, { Navigation } from 'swiper/core';
import styles from "./CourseCarousel.module.scss";
import SwiperNavigation from "../../molecules/SwiperNavigation/SwiperNavigation";
import CourseZoomTile from "../CourseZoomTile/CourseZoomTile";

SwiperCore.use([Navigation]);

const CourseCarousel = ({ id, title, courses, hMargins = 0 }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSlideChanging, setIsSlideChanging] = useState(false);

  const preparedCourses = useMemo(() => courses.map(course => ({
    ...course,
    duration: course.duration / 60,
  })), [courses]);

  return courses.length > 0 && (
    <div
      className={`${styles.courseSwiperWrapper} ${isHovered ? styles.courseSwiperWrapper__hovered : ''}`}
      style={{ ['--h-margins']: `${hMargins}vw` }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {title && (
        <Text uppercase s24 secondary w800>
          {title}
        </Text>
      )}
      <Swiper
        className={styles.courseSwiper}
        slidesPerView={'auto'}
        spaceBetween={40}
        navigation={isHovered ? {
          prevEl: `#courseSlider${id}Prev`,
          nextEl: `#courseSlider${id}Next`,
        } : false}
        onSlideChangeTransitionStart={() => setIsSlideChanging(true)}
        onSlideChangeTransitionEnd={() => setIsSlideChanging(false)}
      >
        {preparedCourses.map(course => (
          <SwiperSlide key={course.id}>
            <CourseZoomTile
              data={course}
              isHoverBlocked={isSlideChanging}
            />
          </SwiperSlide>
        ))}
        <SwiperNavigation id={`courseSlider${id}`} visible={isHovered} />
      </Swiper>
    </div>
  );
};

export default CourseCarousel
