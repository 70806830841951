import React, { useMemo } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import RatingStars from "../../../../atoms/RatingStars/RatingStars";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../../routes";

function UserStatisticsTable({ reports }) {
  const { data } = reports;
  const history = useHistory();

  const items = data.map((item) => ({
    ...item,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.lastName,
      accessor: "surname",
    },
    {
      Header: languages.EN.labels.firstName,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.organization,
      accessor: "user_organization.name",
    },
    {
      Header: languages.EN.labels.userRank,
      accessor: "user_rank",
    },
    {
      Header: languages.EN.labels.bonusPoints,
      accessor: "bonus_points",
    },
    {
      Header: languages.EN.labels.socialStars,
      accessor: "social_stars",
      Cell: (data) => <RatingStars rating={data.cell.value} />,
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(generatePath(Routes.profile.statistics.base, {username: data.row.original.user.username}))}
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} />;
}

export default UserStatisticsTable;
