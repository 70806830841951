import styles from "./RedBar.module.scss";
import Text from "../../Text/Text";
import Icons from "../../../../assets/icons";
import Button from "../../Button/Button";

const warning = Icons.redWarrnig;

const RedBar = ({ text, buttonText, onClick }) => {
  return (
    <div className={styles.Wrapper}>
      <div>
        {warning}
        <Text red s18>
          {text}
        </Text>
      </div>
      {buttonText && (
        <Button onClick={onClick} variant="warningBtn">
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default RedBar;
