import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import { Route, Switch } from "react-router";
import IdpCareerContainer from "../../organisms/IdpCareerContainer/IdpCareerContainer";
import IdpLearningPlan from "../../organisms/IdpLearningPlan/IdpLearningPlan";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath, matchPath, useHistory, useLocation } from "react-router-dom";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import React, { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { MODE_EDIT } from "../../pages/IdpEdit/IdpEdit";
import Text from "../../atoms/Text/Text";
import styles from "./IdpDetailsTemplate.module.scss";
import ProgressBar, { VARIANT_TRANSPARENT } from "../../molecules/ProgressBar/ProgressBar";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { idpRepository } from "../../../repository/idp.repository";
import { TYPE_DROPDOWN, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import IdpComments from "../../molecules/IdpComments/IdpComments";
import { dateFormat } from "../../../utils/formatters";

export const STATUS_STYLES = {
  null: styles.status__back,
  0: styles.status__created,
  1: styles.status__sent,
  2: styles.status__approved,
  3: styles.status__back,
}

const popupFields = (data = {}) => [
  {
    name: "status",
    label: languages.EN.labels.newStatus,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.status,
    items: {
      2: languages.EN.enums.idpStatus[2],
      3: languages.EN.enums.idpStatus[3],
    },
  },
  {
    name: "comment",
    label: languages.EN.labels.yourComment,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.comment,
  },
];

const IdpDetailsTemplate = ({ idp, params }) => {
  const { isManagerOfTeam, userData } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();
  const location = useLocation();

  const links = [
    {
      content: languages.EN.labels.career,
      href: generatePath(Routes.idpDetails.career.base, { id: params.id, username: params.username }),
    },
    {
      content: languages.EN.labels.learningPlan,
      href: generatePath(Routes.idpDetails.learningPlan.base, { id: params.id, username: params.username }),
    },
  ];

  const getEditPath = () => {
    return matchPath(location.pathname, {
      path: generatePath(Routes.idpDetails.learningPlan.base, {
        id: params.id,
        username: params.username
      })
    }) !== null && idp.learning_plan.length > 0 ?
      Routes.idpEdit.learningPlan.base :
      Routes.idpEdit.career.base
  }

  const commentsSorted = idp.comments.sort((a, b) => (new Date(a.created)) < (new Date(b.created)) ? 1 : -1);
  const idpUserTeam = idp.user_team.find(team => !team.is_manager);

  useDocumentTitle(`${idp.user_profile.name} ${idp.user_profile.surname} | ${languages.EN.labels.individualDevelopmentPlan}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={idp.user_profile.name + " " + idp.user_profile.surname}
        path={[
          {
            name: languages.EN.labels.profile,
            link: generatePath(Routes.profile.statistics.base, { username: params.username }),
          },
          {
            name: languages.EN.labels.individualDevelopmentPlan,
            link: generatePath(Routes.idps.base, { username: params.username }),
          },
          {
            name: `${languages.EN.labels.version} ${idp.version}${idp.archived ? ` (${languages.EN.labels.archivedOn} ${dateFormat(idp.archived)})` : ''}`,
            link: "#",
          },
        ]}
        img={idp.user_profile.profile_image_medium}
        imgSquare={true}
        infos={[
          {
            label: languages.EN.labels.team,
            value: idpUserTeam?.name,
          },
          {
            label: languages.EN.labels.idpStatus,
            value: (
              <div className={STATUS_STYLES[idp.idp_status]}>
                <Text s14 w600 lh20>
                  {idp.idp_status === null ? languages.EN.labels.noPlan : languages.EN.enums.idpStatus[idp.idp_status]}
                </Text>
              </div>
            ),
          },
          {
            label: languages.EN.labels.idpProgress,
            value: (
              <ProgressBar
                variant={VARIANT_TRANSPARENT}
                progress={idp.idp_progress * 100}
                information={`${Math.round(idp.idp_minutes_left)} ${languages.EN.labels.minutesLeft}`}
              />
            ),
          },
          {
            label:languages.EN.labels.managerComments,
            value: commentsSorted.length > 0 ? <IdpComments comments={commentsSorted} /> : undefined
          },
        ]}
        buttons={[].concat(userData.username === idp.user_profile.user.username && params.mode === undefined && [0, 3].includes(idp.idp_status) ? [
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            onClick: () => history.push(generatePath(
              getEditPath(),
              { id: params.id, username: params.username, mode: MODE_EDIT }
            )),
          }
        ] : []).concat([1, 2].includes(idp.idp_status) && isManagerOfTeam(idpUserTeam?.id) ? [
          {
            icon: "comment",
            name: languages.EN.labels.comment,
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: languages.EN.labels.comment,
                fields: popupFields(),
                postAction: (formData) => idpRepository.changeStatusPlan({ ...formData, idp: params.id }),
              },
            })
          }
        ] : [])}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route path={links[0].href}>
            <IdpCareerContainer idp={{id: params.id, ...idp}} params={params} />
          </Route>
          <Route path={links[1].href}>
            <IdpLearningPlan data={idp} />
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default IdpDetailsTemplate;
