import styles from "./LearningReportInstructors.module.scss";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import InstructorListTable from "../../Table/LearningReport/Instructors/InstructorListTable";
import employees from "../../../../assets/icons/statistics/employees.svg";
import onlineCourse from "../../../../assets/icons/statistics/onlineCourse.svg";
import star from "../../../../assets/icons/statistics/star.svg";
import { languages } from "../../../../dummyData/dummyData";
import Statistics from "../../Statistics/Statistics";

const LearningReportInstructors = ({ data }) => {
  return (
    <div className={styles.Wrapper}>
      <Statistics
        data={[
          {
            icon: employees,
            content: languages.EN.labels.instructorsOnPlatform,
            value: data.num_instructors,
          },
          {
            icon: onlineCourse,
            content: languages.EN.labels.averageNumberOfCoursesByInstructors,
            value: data.avg_num_courses_by_instructor.toFixed(2),
          },
          {
            icon: star,
            content: languages.EN.labels.averageInstrucorRating,
            value: data.avg_instructor_rating.toFixed(2),
          },
        ]}
      />
      <div className={styles.instructors}>
        <TableWrapper
          Table={InstructorListTable}
          reports={{ data: data.instructor_reports }}
        />
      </div>
    </div>
  );
};

export default LearningReportInstructors;
