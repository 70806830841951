import React, { useContext, useMemo } from "react";
import rank from "../../../assets/icons/statistics/rank.svg";
import bonusPoints from "../../../assets/icons/statistics/bonusPoints.svg";
import star from "../../../assets/icons/statistics/star.svg";
import progress from "../../../assets/icons/statistics/progress.svg";
import courses from "../../../assets/icons/statistics/onlineCourse.svg";
import competences from "../../../assets/icons/statistics/competences.svg";
import { generatePath } from "react-router-dom";
import { Routes } from "../../../routes";
import { languages } from "../../../dummyData/dummyData";
import Statistics from "../Statistics/Statistics";
import { SCOPE_IDP } from "../../../constants/scopes";
import { UserContext } from "../../../contexts/UserContext";

const ProfileStatistics = ({ data }) => {
  const { user_rank, bonus_points, social_stars, idp, idp_progress, active_courses, user_competences } = data;
  const { username } = data.user;
  const { userData, isManagerOfTeam } = useContext(UserContext);

  const isMine = userData.username === username;
  const teamId = data.teams.length > 0 ? data.teams.find(team => !team.is_manager).id : undefined;

  return (
    <Statistics
      data={[
        {
          icon: rank,
          content: languages.EN.labels.userRank,
          value: user_rank,
          visible: user_rank !== null,
        },
        {
          icon: bonusPoints,
          content: languages.EN.labels.bonusPoints,
          value: bonus_points,
          visible: bonus_points !== null,
        },
        {
          icon: star,
          content: languages.EN.labels.socialStars,
          value: social_stars,
          visible: social_stars !== null,
        },
        {
          icon: progress,
          content: languages.EN.labels.idpProgress,
          value: `${Math.round(idp_progress * 100)} %`,
          visible: userData.scopes.includes(SCOPE_IDP) && (idp !== null || isMine || isManagerOfTeam(teamId)),
          link: idp !== null ?
            generatePath(Routes.idpDetails.career.base, { username: username, id: idp }) :
            generatePath(Routes.idps.base, { username: username }),
        },
        {
          icon: courses,
          content: languages.EN.labels.activeCourses,
          value: active_courses?.length,
          visible: active_courses !== null,
          link: generatePath(Routes.profile.courses.base, { username: username })
        },
        {
          icon: competences,
          content: languages.EN.labels.verifiedCompetences,
          value: user_competences?.filter(competence => competence.verified === 2).length,
          visible: user_competences !== null,
          link: generatePath(Routes.profile.competences.base, { username: username })
        },
      ]}
    />
  );
};

export default ProfileStatistics;
