import useFetch from "../../../hooks/useFetch";
import { useContext, useEffect } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { userRepository } from "../../../repository/user.repository";
import SettingsTemplate from "../../templates/SettingsTemplate/SettingsTemplate";
import { settingsRepository } from "../../../repository/settings.repository";

const Settings = () => {
  const { isResponseOk, lastUsedPromise } = useContext(PostContext);

  useDocumentTitleLoading();

  const fetchPrivacySettings = useFetch();
  useEffect(() => {
    if ((isResponseOk && ['sendUserPrivacySettings', 'resetPrivacySettings'].includes(lastUsedPromise)) || !fetchPrivacySettings.data) {
      fetchPrivacySettings.fetchData(userRepository.getUserPrivacySettings());
    }
  }, [isResponseOk]);

  const fetchMfa = useFetch();
  useEffect(() => {
    if ((isResponseOk && ['confirmMfa', 'disableMfa'].includes(lastUsedPromise)) || !fetchMfa.data) {
      fetchMfa.fetchData(settingsRepository.getMfa());
    }
  }, [isResponseOk]);

  return fetchPrivacySettings.data && fetchMfa.data ? (
    <SettingsTemplate privacySettings={fetchPrivacySettings.data} mfa={fetchMfa.data} />
  ) : (
    <Loader />
  );
};

export default Settings;
