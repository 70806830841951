import React from "react";
import { TreeNode } from "react-organizational-chart";
import JobPositionsChartElement from "../../molecules/JobPositionsChartElement/JobPositionsChartElement";
import styles from "./JobPositionsChartSegment.module.scss";

const JobPositionsChartSegment = ({
  data,
  parent,
  usedJobPositions,
  setClickedId,
  clickedId,
  isEdited,
  handleNewChartElement,
  handleChartChange,
  handleAddUsedPositionId,
  handleId,
  isIdp,
}) => {
  const isLine = data?.length === 1 ? true : false;

  return (
    <>
      {data && data.map((item, i) => item && (
        <React.Fragment key={i}>
          <TreeNode
            key={item.id}
            className={`${styles.treeNode} ${isLine ? styles.treeNode__fromOne : ''}`}
            label={
              <JobPositionsChartElement
                handleAddUsedPositionId={handleAddUsedPositionId}
                item={item}
                children={item.children ? item.children.length : 0}
                parent={parent}
                usedJobPositions={usedJobPositions}
                setClickedId={setClickedId}
                clickedId={clickedId}
                handleNewChartElement={handleNewChartElement}
                handleChartChange={handleChartChange}
                isIdp={isIdp}
                isEdited={isEdited}
              />
            }
          >
            {item.children && (
              <JobPositionsChartSegment
                isEdited={isEdited}
                handleNewChartElement={handleNewChartElement}
                usedJobPositions={usedJobPositions}
                parent={item}
                data={item.children}
                setClickedId={setClickedId}
                clickedId={clickedId}
                handleChartChange={handleChartChange}
                handleId={handleId}
                isIdp={isIdp}
                handleAddUsedPositionId={handleAddUsedPositionId}
              />
            )}
          </TreeNode>
        </React.Fragment>
      ))}
    </>
  );
};
export default JobPositionsChartSegment;
