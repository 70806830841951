import arraySort from "array-sort";
import findItemValueByPath from "./FilterByPathName";

const sortData = (key, data, ascending) => {
  const preparedToSortTable = data.map((item) => ({
    ...item,
    sortName:
      typeof findItemValueByPath(item, key) === "string"
        ? findItemValueByPath(item, key).toLowerCase()
        : findItemValueByPath(item, key),
  }));

  return arraySort(preparedToSortTable, 'sortName', {reverse: !ascending});
};

export default sortData;
