import styles from "./IdpCareerContainer.module.scss";
import { useContext, useEffect, useState } from "react";
import Button from "../../atoms/Button/Button";
import { Routes } from "../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { careerPathsRepository } from "../../../repository/careerPaths.repository";
import { idpRepository } from "../../../repository/idp.repository";
import SelectSearch from "../../atoms/SelectSearch/SelectSearch";
import { Loader, LoaderOnly } from "../../atoms/Loader/Loader";
import { languages } from "../../../dummyData/dummyData";
import { PostContext } from "../../../contexts/PostContext";
import { validationForm } from "../../../utils/validation";
import useFetch from "../../../hooks/useFetch";
import { MODE_ADD, MODE_EDIT } from "../../pages/IdpEdit/IdpEdit";
import IdpJobPositionsChart from "../IdpJobPositionsChart/IdpJobPositionsChart";

const jobPositionsLabels = {
  job_position: languages.EN.labels.currentPosition,
  next_job_position: languages.EN.labels.nextPosition,
  target_job_position: languages.EN.labels.desiredFuturePosition,
};

const IdpCareerContainer = ({ idp, careerPaths = [], jobPositions = [], params }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);

  const [currentCarrerPathDetails, setCurrentCarrerPathDetails] = useState(null);

  const [chartData, setChartData] = useState([]);
  const [additionalChartData, setAdditionalChartData] = useState([]);

  const { postData, postResponse, isPostFinish } = useContext(PostContext);

  const history = useHistory();

  const career_path = idp.career_path ?? (idp.user_profile.user_career_path ?? null)
  const job_position = idp.job_position ?? (idp.user_profile.user_job_position ?? null)

  const [formData, setFormData] = useState({
    career_path: career_path ? { label: career_path.name, id: career_path.id } : null,
    job_position: job_position ? { label: job_position.name, id: job_position.id } : null,
    next_job_position: idp.next_job_position ? { label: idp.next_job_position.name, id: idp.next_job_position.id } : null,
    target_job_position: idp.target_job_position ? { label: idp.target_job_position.name, id: idp.target_job_position.id } : null,
  });

  const handleInput = (name, id, item) => {
    setFormData({
      ...formData,
      [name]: id !== "" ? { label: item.label, id: id } : null,
    });
  };

  useEffect(() => {
    if (formData.career_path !== null) {
      setIsChartLoading(true);
      fetchCareerPathDetails.fetchData(careerPathsRepository.getCareerPathDetails(formData.career_path.id));
    } else {
      setCurrentCarrerPathDetails(null)
    }
  }, [formData.career_path]);

  const fetchCareerPathDetails = useFetch();
  useEffect(() => {
    if (fetchCareerPathDetails.isReady) {
      setIsChartLoading(false);
      setCurrentCarrerPathDetails(fetchCareerPathDetails.data.career_path);
    }
  }, [fetchCareerPathDetails.isReady]);

  let jobPositionsInCurrentPath;
  const markPositionsOnChart = (chartData) => {
    return chartData.map(jobPosition => {
      const positionInFormData = Object.keys(jobPositionsLabels).filter(item => formData[item]?.id === jobPosition.id);

      jobPositionsInCurrentPath = jobPositionsInCurrentPath.concat(positionInFormData);

      return {
        ...jobPosition,
        caption: positionInFormData.length > 0 ? positionInFormData.map(item => jobPositionsLabels[item]).join(', ') : undefined,
        children: jobPosition.children ? markPositionsOnChart(jobPosition.children) : undefined,
      }
    });
  }

  useEffect(() => {
    jobPositionsInCurrentPath = [];

    setChartData(currentCarrerPathDetails !== null && currentCarrerPathDetails.job_positions.length > 0 ? markPositionsOnChart([currentCarrerPathDetails.job_positions[0]]) : []);

    setAdditionalChartData(Object.entries(jobPositionsLabels).filter(([key]) => formData[key] !== null && !jobPositionsInCurrentPath.includes(key)).map(([key, caption]) => ({
      id: formData[key].id,
      name: formData[key].label,
      caption: caption,
      career_path: [],
      children: [],
    })));
  }, [currentCarrerPathDetails, formData.job_position, formData.next_job_position, formData.target_job_position]);

  const [errors, setErrors] = useState([]);

  const handleNextButton = () => {
    const dataToSend = Object.fromEntries(Object.entries(formData).map(([key, value]) => [key, value?.id ?? '']));
    const requiredFields = Object.keys(formData).map(key => ({ name: key, type: "text" }));

    const validationErrors = validationForm(dataToSend, requiredFields);
    setErrors(validationErrors);

    if (Object.values(validationErrors).every((v) => v.error === false)) {
      setIsLoading(true);
      if (params.mode === MODE_EDIT) {
        postData(
          () => idpRepository.editIdp(params.username, {idp_id: params.id, ...dataToSend}),
          () => history.push(generatePath(Routes.idpEdit.learningPlan.base, { username: params.username, id: params.id, mode: params.mode }))
        );
      } else if (params.mode === MODE_ADD) {
        postData(() => idpRepository.addIdp({ user: params.username, ...dataToSend }));
      }
    }
  };

  useEffect(() => {
    if (params.mode === MODE_ADD && isPostFinish && postResponse?.idp_id) {
      history.push(generatePath(Routes.idpEdit.learningPlan.base, { username: params.username, id: postResponse.idp_id, mode: params.mode }));
    }
  }, [postResponse]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.inputsWrapper}>
          {Object.entries({ career_path: languages.EN.labels.careerPath, ...jobPositionsLabels }).map(([key, value]) => (
            <div className={styles.inputWrapper} key={key}>
              <SelectSearch
                disabled={!(params.mode !== undefined || isChartLoading)}
                name={key}
                currName={formData[key]?.label}
                value={formData[key]?.label}
                handleSelect={handleInput}
                placeholder={value}
                items={(key === 'career_path' ? careerPaths : jobPositions).map((item) => ({ label: item.name, id: item.id }))}
                error={errors[key]?.error || (postResponse ?? [])[key]}
              />
              <Button
                variant="iconButton"
                onlyIcon
                icon="dots"
                disabled={formData[key] === null}
                onClick={() => {
                  formData[key] && history.push(generatePath(key === 'career_path' ? Routes.careerPathDetails.statistics.base : Routes.jobPositionDetails.keyCompetences.base, { id: formData[key].id }))
                }}
                tooltip={languages.EN.labels.details}
              />
            </div>
          ))}
        </div>
        <div
          className={styles.chartContainer}
          style={{ justifyContent: chartData.length > 0 && chartData[0] !== null ? "initial" : "center" }}
        >
          {isChartLoading ? (
            <div className={styles.loaderWrapper}>
              <LoaderOnly />
            </div>
          ) : (
            <IdpJobPositionsChart chartData={chartData} additionalChartData={additionalChartData} />
          )}
        </div>
      </div>
      <div className={styles.buttons}>
        {params.mode !== undefined ? (
          <>
            <Button variant='primaryLight' onClick={() => history.push(generatePath(Routes.idps.base, { username: params.username }))}>{languages.EN.labels.cancel}</Button>
            <Button variant='primary' onClick={handleNextButton}>{languages.EN.labels.next}</Button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default IdpCareerContainer;
