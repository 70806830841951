import React, { useContext, useMemo } from "react";
import Table from "../Table";
import { languages } from "../../../../dummyData/dummyData";
import styles from "../Table.module.scss";
import Button from "../../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { experienceRepository } from "../../../../repository/jobExperience.repository";
import { TYPE_CHECKBOX, TYPE_DATE, TYPE_DROPDOWN } from "../../../molecules/Form/Form";
import { dateDiffFormat, dateFormat } from "../../../../utils/formatters";

const popupFields = (data = {}) => [
  {
    name: "company",
    label: languages.EN.labels.company,
    required: true,
    value: data.company,
  },
  {
    name: "business_area",
    label: languages.EN.labels.businessArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.business_area,
    items: languages.EN.enums.businessAreas,
  },
  {
    name: "job_title",
    label: languages.EN.labels.jobTitle,
    required: true,
    value: data.job_title,
  },
  {
    name: "country",
    label: languages.EN.labels.countryOfWorkplace,
    required: true,
    value: data.country,
  },
  [
    {
      name: "start_date",
      label: languages.EN.labels.from,
      required: true,
      type: TYPE_DATE,
      value: data.start_date,
    },
    {
      name: "end_date",
      label: languages.EN.labels.to,
      required: data.ongoing !== 1,
      disabled: data.ongoing === 1,
      type: TYPE_DATE,
      value: data.end_date,
    },
    {
      name: "ongoing",
      label: languages.EN.labels.ongoing,
      type: TYPE_CHECKBOX,
      value: data.ongoing ?? 0,
    },
  ],
];

const JobExperienceTable = ({ reports }) => {
  const { data, canEdit } = reports;
  const { callPopup } = useContext(PopupsContext);

  const items = data.map((item) => ({
    ...item,
    businessAreaName: languages.EN.enums.businessAreas[item.business_area],
    period: `${dateFormat(item.start_date)} - ${item.end_date ? dateFormat(item.end_date) : languages.EN.labels.ongoing}`,
    duration: item.end_date ? dateDiffFormat(item.start_date, item.end_date) : languages.EN.placeholders.empty,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.company,
      accessor: "company",
    },
    {
      Header: languages.EN.labels.businessArea,
      accessor: "businessAreaName",
    },
    {
      Header: languages.EN.labels.jobTitle,
      accessor: "job_title",
    },
    {
      Header: languages.EN.labels.countryOfWorkplace,
      accessor: "country",
    },
    {
      Header: languages.EN.labels.period,
      accessor: "period",
    },
    {
      Header: languages.EN.labels.duration,
      accessor: "duration",
      sortType: (rowA, rowB) => Math.sign(
        (new Date(rowA.original.end_date) - new Date(rowA.original.start_date)) - (new Date(rowB.original.end_date) - new Date(rowB.original.start_date))
      ),
    },
  ].concat(canEdit ? [
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={() => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.education}`,
                fields: (formData) => popupFields({ ...data.row.original, ...formData }),
                postAction: (formData) => experienceRepository.editExperience(data.row.original.id, {...formData, end_date: formData.ongoing !== 1 ? formData.end_date : ""}),
              },
            })}
            tooltip={languages.EN.labels.edit}
          />
          <Button
            variant="iconButton"
            onlyIcon
            icon="garbage"
            onClick={() => callPopup({
              type: ACTIONS.DELETE,
              payload: {
                type: languages.EN.labels.jobExperience,
                elementName: `${languages.EN.labels.company} ${data.row.original.company}`,
                action: () => experienceRepository.deleteExperience(data.row.original.id),
              },
            })}
            tooltip={languages.EN.labels.delete}
          />
        </div>
      ),
    },
  ] : []), []);

  return <Table buttons={canEdit ? [
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.jobExperience}`,
          fields: (formData) => popupFields(formData),
          postAction: (formData) => experienceRepository.addExperience({...formData, end_date: formData.ongoing !== 1 ? formData.end_date : ""}),
        },
      })
    },
  ] : []} columns={columns} data={items} pagination={false} />;
}

export default JobExperienceTable;
