import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import CareerPath from "../CareerPath/CareerPath";
import CareerPathTile from "../CareerPathTile/CareerPathTile";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { careerPathsRepository } from "../../../repository/careerPaths.repository";
import { TYPE_DROPDOWN, TYPE_IMAGE, TYPE_TEXTAREA } from "../../molecules/Form/Form";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "description",
    header: languages.EN.labels.description,
    sortId: 3,
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 7,
  },
  {
    keyPath: "country",
    header: languages.EN.labels.region,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "num_org_members",
    header: languages.EN.labels.noOfOrganizationMembers,
    typeOfFilter: "range",
    sortId: 5,
  },
  {
    keyPath: "num_team_members",
    header: languages.EN.labels.noOfTeamMembers,
    typeOfFilter: "range",
    sortId: 6,
  },
  {
    keyPath: "isActive",
    header: languages.EN.labels.isActive,
    typeOfFilter: "checkbox",
    defaultValues: [languages.EN.enums.yesNo[1]],
  },
];

export const popupFields = (data = {}) => [
  {
    name: "name",
    label: languages.EN.labels.name,
    required: true,
    value: data.name,
  },
  {
    name: "topic",
    label: languages.EN.labels.learningArea,
    required: true,
    type: TYPE_DROPDOWN,
    value: data.topic,
    items: languages.EN.enums.learningAreas,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "country",
    label: languages.EN.labels.region,
    required: true,
    value: data.country,
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    required: true,
    type: TYPE_IMAGE,
    value: data.profile_image,
  },
];

const CareerPathsList = ({ data, withManagement }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.organization !== null ? 2 : 3;

    return {
      ...item,
      learningArea: languages.EN.enums.learningAreas[item.topic],
      jobPositions: item.job_positions?.map((job_position) => job_position.name),
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.organization.profile_image_small : <AidifyLogo />,
      isActive: languages.EN.enums.yesNo[item.active ? 1 : 0],
    };
  })), [data]);

  const { callPopup } = useContext(PopupsContext);

  return (
    <FilteredList
      id="careerPaths"
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={CareerPath}
      RenderTileComponent={CareerPathTile}
      buttons={[].concat(withManagement ? [
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: () => callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.careerPath}`,
              fields: popupFields(),
              postAction: careerPathsRepository.addCareerPath,
            },
          }),
        }
      ] : [])}
    />
  );
};

export default CareerPathsList;
