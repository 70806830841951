import styles from "./FiltersWithHeader.module.scss";
import FilterRangeSlider from "../FilterRangeSlider/FilterRangeSlider";
import FilterDateRangePicker from "../FilterDateRangePicker/FilterDateRangePicker";
import FilterCheckboxes from "../FilterCheckboxes/FilterCheckboxes";

const FiltersWithHeader = ({ definition }) => {
  const { typeOfFilter } = definition;

  return (
    <div className={styles.checkboxesWrapper}>
      {typeOfFilter === "checkbox" && (
        <FilterCheckboxes definition={definition} />
      )}
      {typeOfFilter === "range" && (
        <FilterRangeSlider definition={definition} />
      )}
      {typeOfFilter === "datePicker" && (
        <FilterDateRangePicker definition={definition} />
      )}
    </div>
  );
};

export default FiltersWithHeader;
