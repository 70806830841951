import React from "react";
import styles from "./Status.module.scss";
import Text from "../Text/Text";

export const VARIANT_GREEN = "green";
export const VARIANT_YELLOW = "yellow";
export const VARIANT_ORANGE = "orange";
export const VARIANT_RED = "red";
export const VARIANT_GRAY = "gray";

const VARIANT_STYLES = {
  [VARIANT_GREEN]: styles.green,
  [VARIANT_YELLOW]: styles.yellow,
  [VARIANT_ORANGE]: styles.orange,
  [VARIANT_RED]: styles.red,
  [VARIANT_GRAY]: styles.gray,
}

const Status = ({ variant, text }) => {
  return (
    <span className={`${styles.status} ${VARIANT_STYLES[variant]}`}>
      <div className={styles.background} />
      <Text s12 lh24 w700>
        {text}
      </Text>
    </span>
  );
};

export default Status;
