import React, { useContext, useEffect, useState } from "react";
import File from "../../organisms/File/File";
import FileTile from "../../organisms/FileTile/FileTile";
import docImage from "../../../assets/fileTypes/doc.svg";
import jpgImage from "../../../assets/fileTypes/jpg.svg";
import pngImage from "../../../assets/fileTypes/png.svg";
import pdfImage from "../../../assets/fileTypes/pdf.svg";
import pptImage from "../../../assets/fileTypes/ppt.svg";
import txtImage from "../../../assets/fileTypes/txt.svg";
import xlsImage from "../../../assets/fileTypes/xls.svg";
import defaultImage from "../../../assets/fileTypes/default.svg";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { filesRepository } from "../../../repository/files.repository";
import TopBanner from "../../molecules/TopBanner/TopBanner";

const FILE_IMAGES = {
  doc: docImage,
  docx: docImage,
  jpg: jpgImage,
  jpeg: jpgImage,
  pdf: pdfImage,
  png: pngImage,
  ppt: pptImage,
  pptx: pptImage,
  txt: txtImage,
  xls: xlsImage,
  xlsx: xlsImage,
  default: defaultImage,
};

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "description",
    header: languages.EN.labels.description,
    sortId: 2,
  },
  {
    keyPath: "categoryName",
    header: languages.EN.labels.category,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
  {
    keyPath: "target_name",
    header: languages.EN.labels.linkedElementName,
    sortId: 4,
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 7,
  },
  {
    keyPath: "extensionUppercase",
    header: languages.EN.labels.extension,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "filesizeKB",
    header: languages.EN.labels.size,
    typeOfFilter: "range",
    sortId: 5,
  },
  {
    keyPath: "created",
    header: languages.EN.labels.added,
    typeOfFilter: "datePicker",
    sortId: 6,
  },
];

const FilesTemplate = ({ data, courses, learningEvents, regulatoryIntelligence }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => {
    const type = item.organization !== null ? 2 : 0;

    return {
      ...item,
      selectableId: item.id,
      fileImg: FILE_IMAGES[item.extension] ?? FILE_IMAGES['default'],
      categoryName: languages.EN.enums.fileCategories[item.category],
      visibility: languages.EN.enums.visibility[type],
      visibilityImg: type === 2 ? item.organization.profile_image_small : item.owner.profile['profile_image_medium'],
      extensionUppercase: item.extension.toUpperCase(),
      filesizeKB: Math.ceil(item.filesize / 1024)
    };
  })), [data]);

  const { callPopup } = useContext(PopupsContext);
  const pageHeader = languages.EN.labels.files;

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <FilteredList
          id="files"
          data={preparedData}
          filterDefinitions={filterDefinitions}
          RenderListComponent={File}
          RenderTileComponent={FileTile}
          buttons={[
            {
              icon: "plus",
              label: languages.EN.labels.add,
              onClick: () => callPopup({
                type: ACTIONS.FILE,
                payload: {
                  header: `${languages.EN.labels.add} ${languages.EN.labels.file}`,
                  courses: courses,
                  learningEvents: learningEvents,
                  regulatoryIntelligence: regulatoryIntelligence,
                },
              }),
            },
            {
              icon: "download",
              label: languages.EN.labels.download,
              forSelectedOnly: true,
              onClick: (selectedTable) => filesRepository.downloadFiles(selectedTable.map((item) => item.id).join(",")),
            },
          ]}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default FilesTemplate;
