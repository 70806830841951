import NavBar from "../../organisms/NavBar/NavBar";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { languages } from "../../../dummyData/dummyData";
import { Routes } from "../../../routes";
import React, { useContext, useEffect, useState } from "react";
import Footer from "../../organisms/Footer/Footer";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import { generatePath, Route, Switch, useHistory } from "react-router-dom";
import { dateFormat } from "../../../utils/formatters";
import { defaultUserPhoto } from "../../../constants/assets";
import Text from "../../atoms/Text/Text";
import InfoPage from "../../organisms/InfoPage/InfoPage";
import styles from "./ReferenceAuditTemplate.module.scss";
import ReactDOMServer from "react-dom/server";
import icons from "../../../assets/icons";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import RelatedCourseAuditItem from "../../organisms/RelatedCourseAuditItem/RelatedCourseAuditItem";
import RelatedCourseAuditItemTile from "../../organisms/RelatedCourseAuditItemTile/RelatedCourseAuditItemTile";
import Button from "../../atoms/Button/Button";
import { referencesRepository } from "../../../repository/references.repository";
import HeaderWithButtons from "../../molecules/HeaderWithButtons/HeaderWithButtons";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { getDocumentForm, referencePopupFields } from "../../organisms/Table/ReferenceControl/ReferencesTable/ReferencesTable";
import { PostContext } from "../../../contexts/PostContext";

const DOCUMENT_DETAILS_FIELDS = {
  'type': { header: languages.EN.labels.type, format: value => languages.EN.enums.referenceTypes[value] },
  'publisher': { header: languages.EN.labels.publisher, visible: type => [0, 1, 3, 4, 5, 6, 7].includes(type) },
  'author': { header: languages.EN.labels.authors, visible: type => [2, 3, 4, 5, 6, 7].includes(type) },
  'name': { header: languages.EN.labels.title },
  'version': { header: `${languages.EN.labels.version} / ${languages.EN.labels.edition}`, visible: type => [1, 3, 4, 5, 6, 7].includes(type) },
  'number': { header: languages.EN.labels.documentNumber, visible: type => [0, 1, 4, 5, 7].includes(type) },
  'publication_date': { header: languages.EN.labels.dateOfPublication, format: value => value ? dateFormat(value) : value },
  'indirect_link': { header: languages.EN.labels.indirectUrl, visible: type => [0, 1, 2, 5, 6, 7].includes(type) },
  'link': { header: languages.EN.labels.directUrl },
  'journal_name': { header: languages.EN.labels.journalName, visible: type => [2].includes(type) },
  'volume': { header: languages.EN.labels.volumePageRange, visible: type => [2].includes(type) },
  'doi': { header: languages.EN.labels.doi, visible: type => [2].includes(type) },
  'publication_place': { header: languages.EN.labels.placeOfPublication, visible: type => [6].includes(type) },
  'document_form': { header: languages.EN.labels.documentForm, visible: type => [0].includes(type) },
  'manual_audit': { header: languages.EN.labels.requiredPeriodicReconciliation, visible: type => [2, 3, 4, 5, 6, 7].includes(type), format: value => languages.EN.enums.yesNo[value ? 1 : 0], },
  'aidify_version': { header: languages.EN.labels.aidifyVersion, },
  'effective_date': { header: languages.EN.labels.effectiveDate, format: value => value ? dateFormat(value) : value },
  'expiry_date': { header: languages.EN.labels.expiryDate, format: value => value ? dateFormat(value) : value },
  'region': { header: languages.EN.labels.region, format: value => languages.EN.enums.regions[value] },
};

const filterDefinitions = [
  {
    keyPath: "previous_version.display_name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "preparedInstructorNames",
    header: languages.EN.labels.instructor,
    sortId: 2,
  },
  {
    keyPath: "actionRequiredText",
    header: languages.EN.labels.actionRequired,
    typeOfFilter: "checkbox",
    sortId: 3,
  },
  {
    keyPath: "statusText",
    header: languages.EN.labels.status,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "dateText",
    header: languages.EN.labels.deadlineDate,
    typeOfFilter: "datePicker",
    sortId: 4,
  },
];

const ReferenceAuditTemplate = ({ data, auditId }) => {
  const { document, document_audit, courses } = data;
  const { callPopup } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);
  const history = useHistory();
  const audit = document_audit.reduce((acc, curr) => curr.id === auditId ? curr : acc);
  const canFinalize = audit.status !== 3 && audit.course_audits.filter(courseAudit => courseAudit.status !== 9).length === 0;

  const links = [
    {
      content: languages.EN.labels.relatedCoursesAudits,
      href: generatePath(Routes.referenceAudit.relatedCourses.base, { reference: document.id, audit: auditId }),
    },
  ];

  useDocumentTitle(`${document.name} | ${languages.EN.labels.referenceAudit}`);

  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => {
    const coursesById = Object.fromEntries(courses.map(course => [course.id, course]));

    setPreparedData((audit.course_audits ?? []).map((item) => {
      const course = coursesById[item.previous_version.id];
      const instructors = course.course_instructor.length > 0 ? course.course_instructor.map(instructor => ({
        name: instructor.name,
        img: instructor.profile_image_small,
      })) : [{
        name: course.instructor_text,
        img: undefined,
      }];

      return {
        ...item,
        course: course,
        preparedInstructorNames: instructors.map(instructor => instructor.name),
        instructors: instructors,
        actionRequiredText: item.status !== 0 ? languages.EN.enums.courseAuditActions[item.action_required] : languages.EN.placeholders.empty,
        dateText: dateFormat(item.due_date),
        statusText: languages.EN.enums.courseAuditStatuses[item.status].toUpperCase(),
      };
    }));
  }, [audit]);

  const isEmpty = (value) => ["", null, undefined].includes(value);

  const renderValue = (value, format) => isEmpty(value) ? languages.EN.placeholders.empty : (format !== undefined ? format(value) : value);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={document.name}
        path={[
          {
            name: languages.EN.labels.referenceControl,
            link: Routes.referenceControl.base,
          },
          {
            name: languages.EN.labels.referenceAudit,
            link: "#",
          },
        ]}
        buttons={[
          {
            icon: "edit",
            name: languages.EN.labels.edit,
            visible: audit.status !== 3,
            onClick: () => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.reference}`,
                fields: (formData) => referencePopupFields({ ...audit.version, ...formData }, false),
                postAction: (formData) => referencesRepository.editReference(audit.version.id, {
                  ...formData,
                  document_form: getDocumentForm(formData),
                  status: audit.status,
                }),
              },
            }),
          },
        ]}
      />
      <MainTemplate padBottom0>
        <InfoPage
          columnHeader={`${languages.EN.labels.auditDate}: ${dateFormat(audit.audit_date)}`}
          columnItems={[
            { header: languages.EN.labels.createdOn, value: dateFormat(audit.created) },
            { header: languages.EN.labels.actionRequired, value: languages.EN.enums.yesNo[audit.action_required ? 1 : 0] },
            { header: languages.EN.labels.deadlineDate, value: audit.due_date ? dateFormat(audit.due_date) : audit.due_date},
            { header: languages.EN.labels.nextAuditDate, value: dateFormat(audit.next_audit_date) },
          ]}
          columnFooter={[
            {
              header: languages.EN.labels.createdBy,
              image: audit.user.profile.profile_image_small ?? defaultUserPhoto,
              value: `${audit.user.profile.name} ${audit.user.profile.surname}`,
            },
            {
              header: languages.EN.labels.status,
              image: 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup(icons.status[audit.status])),
              value: languages.EN.enums.referenceAuditStatuses[audit.status].toUpperCase(),
              buttons: []
                .concat(canFinalize ? [(
                  <Button
                    variant="iconButton"
                    onlyIcon
                    icon={"checkMark"}
                    key={"checkMark"}
                    onClick={() => callPopup({
                      type: ACTIONS.CONFIRM,
                      payload: {
                        header: languages.EN.labels.finalizeAudit,
                        content: (
                          <>
                            {languages.EN.messages.areYouSureYouWantToFinalizeReferenceAudit}<br /><br />
                            <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
                          </>
                        ),
                        action: () => {
                          postData(
                            () => referencesRepository.editAudit(auditId, { ...audit, action_required: audit.action_required ? 1 : 0, status: 3}),
                            () => {
                              callPopup({ type: ACTIONS.REMOVE });
                              history.push(generatePath(Routes.referenceAudit.relatedCourses.base, { reference: audit.version.id, audit: audit.id }));
                            }
                          );
                        },
                      },
                    })}
                    tooltip={languages.EN.labels.finalizeAudit}
                  />
                )] : []),
            }
          ]}
        >
          <HeaderWithButtons header={languages.EN.labels.revisionSummary} />
          <Text s12 lh24 dark-6>
            {audit.revision_summary}
          </Text>
          {audit.attachment && (
            <span className={styles.attchedFile}>
              <Button
                variant="iconButton"
                onlyIcon
                icon={"download"}
                onClick={() => referencesRepository.downloadAuditFile(audit.attachment.id, audit.attachment.filename)}
                tooltip={languages.EN.labels.download}
              />
              <Text s12 lh24 dark-6>
                {`${languages.EN.labels.download} ${languages.EN.labels.attachedFile.toLowerCase()}`}
            </Text>
            </span>
          )}
          <Text s20 lh32 w800 secondary>
            {languages.EN.labels.referenceDetails}
          </Text>
          <table className={styles.table}>
            <tbody>
            {Object.entries(DOCUMENT_DETAILS_FIELDS)
              .filter(([, data]) => data.visible === undefined || data.visible(document.type))
              .map(([field, data], i) => (
                <tr key={i}>
                  <th>{data.header}</th>
                  <td>
                    {(audit.version && audit.version[field] !== audit.previous_version[field]) ? (
                      <>
                        <Text light-5 strikethrough italic>{renderValue(audit.previous_version[field], data.format)}</Text>&nbsp;
                        <Text red w600>{renderValue(audit.version[field], data.format)}</Text>
                      </>
                    ) : (
                      renderValue(audit.previous_version[field], data.format)
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfoPage>
        <SwitchMenu links={links} />
        <TabContentTemplate>
          <Switch>
            <Route exact path={links[0].href}>
              <FilterContextProvider key={0}>
                <FilteredList
                  id={"coursesAudits"}
                  data={preparedData}
                  filterDefinitions={filterDefinitions}
                  RenderListComponent={RelatedCourseAuditItem}
                  RenderTileComponent={RelatedCourseAuditItemTile}
                />
              </FilterContextProvider>
            </Route>
          </Switch>
        </TabContentTemplate>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default ReferenceAuditTemplate;
