import React, { useContext, useState } from "react";
import styles from "./RegistrationTemplate.module.scss";
import Step1 from "../../organisms/Step1/Step1";
import Step2 from "../../organisms/Step2/Step2";
import Step3 from "../../organisms/Step3/Step3";
import RegistrationTestReport from "../../organisms/RegistrationTestReport/RegistrationTestReport";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import { Switch, Route } from "react-router";
import { Routes } from "../../../routes";
import { FilterContextProvider } from "../../../contexts/FilterContext";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { UserContext } from "../../../contexts/UserContext";
import { Redirect, useHistory } from "react-router-dom";
import NavBar from "../../organisms/NavBar/NavBar";
import StepsCircle from "../../molecules/StepsCircle/StepsCircle";
import MainTemplate from "../generic/MainTemplate/MainTemplate";

const STEP_PATHS = {
  1: Routes.registration.form.base,
  2: Routes.registration.competences.base,
  3: Routes.registration.test.base,
};

const RegistrationTemplate = () => {
  const { userData } = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(userData.registration_status + 1);
  const history = useHistory();

  return (
    <section>
      <NavBar simplified={true} />
      <TopBanner
        header={languages.EN.labels.registration}
        desc={currentStep === 2 ? languages.EN.help.registrationStep2 : undefined}
      />
      <MainTemplate padBottom0>
        <div className={styles.registration}>
          <div className={styles.stepsContainer}>
            <StepsCircle currentStep={currentStep} />
          </div>
        </div>
        <Switch>
          <Route exact path={Routes.registration.base}>
            <Redirect to={STEP_PATHS[currentStep]} />
          </Route>
          <Route exact path={Routes.registration.form.base}>
            {currentStep === 1 ? (
              <Step1 onNextStep={() => {
                history.push(Routes.registration.competences.base);
                setCurrentStep(2);
              }}/>
            ) : (
              <Redirect to={STEP_PATHS[currentStep]} />
            )}
          </Route>
          <Route exact path={Routes.registration.competences.base}>
            {currentStep === 2 ? (
              <FilterContextProvider>
                <Step2 onNextStep={(testId) => {
                  history.push({
                    pathname: Routes.registration.test.base,
                    state: {
                      testId: testId,
                    },
                  });
                  setCurrentStep(3)
                }}/>
              </FilterContextProvider>
            ) : (
              <Redirect to={STEP_PATHS[currentStep]} />
            )}
          </Route>
          <Route exact path={Routes.registration.test.base}>
            {currentStep === 3 ? (
              <Step3 />
            ) : (
              <Redirect to={STEP_PATHS[currentStep]} />
            )}
          </Route>
          <Route exact path={Routes.registration.testReport.base}>
            {currentStep === 3 ? (
              <RegistrationTestReport />
            ) : (
              <Redirect to={STEP_PATHS[currentStep]} />
            )}
          </Route>
        </Switch>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default RegistrationTemplate;
