import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import Workgroup from "../../organisms/Workgroup/Workgroup";
import WorkgroupTile from "../../organisms/WorkgroupTile/WorkgroupTile";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import FilteredList from "../../organisms/FilteredList/FilteredList";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { TYPE_IMAGE, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
import TopBanner from "../../molecules/TopBanner/TopBanner";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "description",
    header: languages.EN.labels.description,
    sortId: 2,
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
    sortId: 4,
  },
  {
    keyPath: "size",
    header: languages.EN.labels.size,
    typeOfFilter: "range",
    sortId: 3,
  },
];

export const popupFields = (data = {}) => [
  {
    name: "name",
    label: languages.EN.labels.name,
    required: true,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    type: TYPE_TEXTAREA,
    required: true,
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    type: TYPE_IMAGE,
    required: false,
  },
];

const WorkgroupsTemplate = ({ data }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => ({
    ...item,
    visibility: languages.EN.enums.visibility[item.visibility],
  }))), [data]);

  const { callPopup } = useContext(PopupsContext);
  const pageHeader = languages.EN.labels.workgroups;

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <FilteredList
          id="workgroups"
          data={preparedData}
          filterDefinitions={filterDefinitions}
          RenderListComponent={Workgroup}
          RenderTileComponent={WorkgroupTile}
          buttons={[
            {
              icon: "plus",
              label: languages.EN.labels.add,
              onClick: () => callPopup({
                type: ACTIONS.FORM,
                payload: {
                  header: `${languages.EN.labels.add} ${languages.EN.labels.workgroup}`,
                  fields: popupFields(),
                  postAction: workgroupsRepository.addWorkgroup,
                },
              }),
            },
          ]}
        />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default WorkgroupsTemplate;
