import styles from "./SearchItem.module.scss";
import Text from "../../atoms/Text/Text";
import { generatePath, Link } from "react-router-dom";
import { languages } from "../../../dummyData/dummyData";
import { searchLink } from "../../atoms/NavbarInput/NavbarInput";

const SearchItem = ({ data }) => {
  const { profile_image_small, name, type, item_id } = data;

  return (
    <Link className={styles.searchItem} to={generatePath(searchLink[type], { id: item_id })}>
      <img src={profile_image_small} alt="" />
      <Text s12 w700>
        {name}
      </Text>
      <Text s12 dark-2>
        {languages.EN.enums.searchDescription[type]}
      </Text>
    </Link>
  );
};

export default SearchItem;
