import LicenseControlTemplate from "../../templates/LicenseControlTemplate/LicenseControlTemplate";
import useFetch from "../../../hooks/useFetch";
import { licenseControlRepository } from "../../../repository/licenseControl.repository";
import { useEffect, useContext } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";

const LicenseControl = () => {
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  const fetchLicenses = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchLicenses.data) {
      fetchLicenses.fetchData(licenseControlRepository.getLicenseControl());
    }
  }, [isResponseOk]);

  const fetchCollections = useFetch();
  useEffect(() => {
    if (!fetchCollections.data) {
      fetchCollections.fetchData(licenseControlRepository.getCollections());
    }
  }, []);

  return fetchLicenses.data && fetchCollections.data ? (
    <LicenseControlTemplate licenses={fetchLicenses.data} collections={fetchCollections.data} />
  ) : (
    <Loader />
  );
};

export default LicenseControl;
