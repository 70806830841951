import OrganizationLicensesTemplate from "../../templates/OrganizationLicensesTemplate/OrganizationLicensesTemplate";
import useFetch from "../../../hooks/useFetch";
import { organizationLicensesRepository } from "../../../repository/organizationLicenses.repository";
import { useContext, useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";
import { licenseControlRepository } from "../../../repository/licenseControl.repository";

const OrganizationLicenses = () => {
  const { isResponseOk } = useContext(PostContext);

  useDocumentTitleLoading();

  const fetchLicenses = useFetch();
  useEffect(() => {
    if (isResponseOk || !fetchLicenses.data) {
      fetchLicenses.fetchData(organizationLicensesRepository.getUserOrganizationLicenses());
    }
  }, [isResponseOk]);

  const fetchCollections = useFetch();
  useEffect(() => {
    if (!fetchCollections.data) {
      fetchCollections.fetchData(licenseControlRepository.getCollections());
    }
  }, [isResponseOk]);

  return fetchLicenses.data && fetchCollections.data ? (
    <OrganizationLicensesTemplate licenses={fetchLicenses.data} collections={fetchCollections.data} />
  ) : (
    <Loader />
  );
};

export default OrganizationLicenses;
