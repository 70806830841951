import React, { useContext } from "react";
import styles from "./WorkgroupTile.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import Button from "../../atoms/Button/Button";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { UserContext } from "../../../contexts/UserContext";
import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import { workgroupsRepository } from "../../../repository/workgroups.repository";
const { numberOfParticipants, type, workgroup } = languages.EN.labels;
const descriptionStr = languages.EN.labels.description;
const { empty } = languages.EN.placeholders;
const visibilityArr = languages.EN.enums.visibility;

const WorkgroupTile = ({ data }) => {
  const {
    name,
    profile_image,
    description,
    size,
    organization,
    id,
    visibility,
    owner,
  } = data;
  const history = useHistory();
  const { callPopup } = useContext(PopupsContext);
  const { userData, userContextLevel } = useContext(UserContext);
  const isUserOwnWorkgroup =
    owner.profile.user.username === userData.username ? true : false;

  return (
    <div className={styles.workgroup}>
      <div className={styles.workgroup__box}>
        <div className={styles.workgroup__nameContainer}>
          <TextTruncated lineClamp={3} secondary>
            <Text s20 lh32 w800 secondary>
              {name ? name : empty}
            </Text>
          </TextTruncated>
        </div>

        <img className={styles.workgroup__img} src={profile_image} alt="" />
        <div className={styles.workgroup__description}>
          <Text s12 lh14 w600 secondary>
            {descriptionStr}
          </Text>

          <TextTruncated lineClamp={3}>
            <Text s12 lh24 dark-2>
              {description ? description : empty}
            </Text>
          </TextTruncated>
        </div>
        <div className={styles.workgroup__information}>
          <Text s12 lh14 w600 secondary>
            {numberOfParticipants}
          </Text>
          <Text s12 lh24 dark-2>
            {size}
          </Text>
        </div>
        <div className={styles.workgroup__information}>
          <Text s12 lh14 w600 secondary>
            {type}
          </Text>
          <div className={styles.workgroup__innerBox}>
            <Text s12 lh24 dark-2>
              {visibilityArr[visibility]}
            </Text>
            <div className={styles.visibilityImg}>
              {visibility === 2 ? (
                <img src={organization.profile_image} alt="" />
              ) : (
                <img src={owner.profile.profile_image_small} alt="" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          icon="dots"
          variant="iconButton"
          onlyIcon
          onClick={() => history.push(`${Routes.workgroupDetails.base}?id=${id}`)}
        />

        {(isUserOwnWorkgroup || [2, 3].includes(userContextLevel)) && (
          <Button
            icon="garbage"
            variant="iconButton"
            onlyIcon
            onClick={() =>
              callPopup({
                type: ACTIONS.DELETE,
                payload: {
                  type: workgroup,
                  elementName: name,
                  action: () => workgroupsRepository.deleteWorkgroup(id),
                },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default WorkgroupTile;
