import React from "react";
import styles from "./SwitchLayoutButton.module.scss";

const SwitchLayoutButton = ({ isGrid, setIsGrid }) => {
  return (
    <div className={styles.container}>
      <button
        data-isactive={`${!isGrid}`}
        className={styles.linearButton}
        onClick={() => setIsGrid(false)}
      >
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </button>
      <button
        data-isactive={`${isGrid}`}
        className={styles.gridButton}
        onClick={() => setIsGrid(true)}
      >
        <div className={styles.row}>
          <div className={styles.square} />
          <div className={styles.square} />
        </div>
        <div className={styles.row}>
          <div className={styles.square} />
          <div className={styles.square} />
        </div>
      </button>
    </div>
  );
};

export default SwitchLayoutButton;
