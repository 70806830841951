import React from "react";
import styles from "./PieChart.module.scss";
import Text from "../../atoms/Text/Text";
import { ResponsivePie } from "@nivo/pie";
import { languages } from "../../../dummyData/dummyData";

const countPercentage = (partial, sum) => {
  return partial === 0 && sum === 0 ? "0%" : ((100 * partial) / sum).toFixed(2) + "%";
};

const PieChart = ({
  filledLabel,
  emptyLabel,
  filledAmount,
  emptyAmount,
  bigSize,
  singleValue,
}) => {
  const chartData = [
    {
      name: emptyLabel,
      value: emptyAmount,
      color: "#00000000",
      label: "name1",
    },
    {
      name: singleValue ? "value" : filledLabel,
      value: filledAmount,
      color: "#00A094",
      label: "name2",
    },
  ];

  return (
    <div className={bigSize ? styles.ChartBig : styles.Chart}>
      <div className={styles.ChartBorderOutside} />
      <div className={styles.ChartBorderOutsideFilter} data-test={bigSize} />
      <div className={styles.ChartBorderInside}>
        <span className={styles.ChartNumber}>
          {singleValue ? singleValue : chartData[0].value + chartData[1].value}
        </span>
      </div>
      <ResponsivePie
        data={chartData.map((item) => {
          return {
            id: item.name,
            label: item.name,
            value: item.value,
            color: item.color,
          };
        })}
        innerRadius={bigSize ? 0.62 : 0.66}
        padAngle={0}
        startAngle={0}
        colors={chartData.map((event) => event.color)}
        borderWidth={0}
        activeOuterRadiusOffset={10}
        arcLinkLabelsSkipAngle={10}
        enableArcLinkLabels={false}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10000}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 0]] }}
        tooltip={(e) => !singleValue && (
          <div className={styles.statisticBox}>
            <Text s12 lh24 dark-2>
              {e.datum.label} {countPercentage(e.datum.value, filledAmount + emptyAmount)}
            </Text>
            <Text s12 lh24 dark-2>
              {e.datum.value}/{filledAmount + emptyAmount}
            </Text>
          </div>
        )}
        fill={chartData.map(item => ({
          match: { id: item.name, },
          id: "clean",
        }))}
      />
    </div>
  );
};

export default PieChart;
