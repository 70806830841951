import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import EducationTable from "../../organisms/Table/EducationTable/EducationTable";
import NavBar from "../../organisms/NavBar/NavBar";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import JobExperienceTable from "../../organisms/Table/JobExperienceTable/JobExperienceTable";
import styles from "./ProfessionTemplate.module.scss";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const ProfessionTemplate = ({ data }) => {
  const { userData } = useContext(UserContext);
  const isMine = userData.username === data.user.user.username;

  useDocumentTitle(`${data.user.name} ${data.user.surname} | ${languages.EN.labels.profession}`);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={`${data.user.name} ${data.user.surname}`}
        path={[
          {
            name: languages.EN.labels.profession,
            link: "#",
          },
        ]}
        img={data.user.profile_image_medium}
        imgSquare={true}
        infos={(data.job_position ? [
          {
            label: languages.EN.labels.jobPosition,
            value: data.job_position.name,
          },
        ] : []).concat([
          {
            label: languages.EN.labels.organization,
            value: data.user.user_organization.name,
          },
        ])}
      />
      <MainTemplate padTop90>
        <div className={styles.profession}>
          <div className={styles.profession__tableContainer}>
            <TableWrapper
              title={languages.EN.labels.education}
              Table={EducationTable}
              reports={{ data: data.education, canEdit: isMine }}
            />
          </div>
          <div className={styles.profession__tableContainer}>
            <TableWrapper
              title={languages.EN.labels.jobExperience}
              Table={JobExperienceTable}
              reports={{ data: data.job_experience, canEdit: isMine }}
            />
          </div>
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default ProfessionTemplate;
