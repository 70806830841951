import styles from "./Level.module.scss";
import Text from "../Text/Text";
import level1 from "../../../assets/icons/level1.svg";
import level2 from "../../../assets/icons/level2.svg";
import level3 from "../../../assets/icons/level3.svg";

const Level = ({ level, small, withoutNumber }) => {
  return (
    <div className={small ? styles.levelSmall : styles.level}>
      {withoutNumber ? null : (
        <Text s12 lh24 dark-2>
          {level}
        </Text>
      )}

      <img src={level === 3 ? level3 : level === 2 ? level2 : level1} alt="" />
    </div>
  );
};
export default Level;
