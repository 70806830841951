import React from "react";
import styles from "./TableWrapper.module.scss";
import Text from "../../atoms/Text/Text";

const TableWrapper = ({ Table, reports, title, warning, transparent = false }) => {
  return (
    <div className={`${styles.tableWrapper} ${transparent ? styles.tableWrapper__transparent : ''}`}>
      <span>
        <Text s20 lh32 w800 secondary>
          {title}
        </Text>
        {warning && (
          <Text s14 lh24 w800 red>
            {warning}
          </Text>
        )}
      </span>
      <Table reports={reports} />
    </div>
  );
};

export default TableWrapper;
