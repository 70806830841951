import React from "react";
import styles from "./TableNoDataInformation.module.scss";
import Text from "../../../atoms/Text/Text";
import { languages } from "../../../../dummyData/dummyData";

const TableNoDataInformation = ({ data }) => {
  return !data.length && (
    <div className={styles.noDataInformation}>
      <Text dark-6 s18>
        {languages.EN.labels.noData}
      </Text>
    </div>
  );
};

export default TableNoDataInformation;
