import NavBar from "../../organisms/NavBar/NavBar";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { languages } from "../../../dummyData/dummyData";
import React, { useMemo } from "react";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import { generatePath, matchPath, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import styles from "../InstructorDashboardTemplate/InstructorDashboardTemplate.module.scss";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import InstructorCoursesTable from "../../organisms/Table/InstructorCoursesTable/InstructorCoursesTable";
import InstructorDashboardStatistics from "../../organisms/InstructorDashboardStatistics/InstructorDashboardStatistics";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import moment from "moment";
import Footer from "../../organisms/Footer/Footer";
import { TYPE_SUBMENU } from "../../molecules/TopBannerButtons/TopBannerButtons";

const InstructorDashboardTemplate = ({ data, quarter, year, currentSelected }) => {
  const history = useHistory();
  const location = useLocation();
  const basePath = matchPath(location.pathname, {
    path: generatePath(Routes.instructorDashboard.instructorStatistics.base, { quarter: quarter, year: year }),
  }) !== null ? Routes.instructorDashboard.instructorStatistics.base : Routes.instructorDashboard.coursesStatistics.base;

  const dateRanges = useMemo(() => {
    let quarters = [];
    const startDate = moment("2023-07-01");
    const currentDate = moment();

    let processedDate = startDate;

    while (processedDate < currentDate) {
      const quarter = Math.ceil((processedDate.month() + 1) / 3);
      const nextDate = moment(processedDate).add(1, 'quarters');
      const year = processedDate.year();

      quarters.push({
        id: `${quarter}_${year}`,
        name: `Q${quarter} ${year}`,
        onClick: () => history.push(generatePath(basePath, { quarter: quarter, year: year })),
      });

      processedDate = nextDate;
    }

    return [
      ...quarters.reverse(),
      {
        id: '0_0',
        name: languages.EN.labels.allTime,
        onClick: () => history.push(generatePath(basePath, { quarter: 0, year: 0 })),
      },
    ];
  }, [basePath]);

  const dateRangeName = dateRanges.find(dataRange => dataRange.id === `${quarter}_${year}`).name;

  const links = [
    {
      content: languages.EN.labels.instructorStatistics,
      href: generatePath(Routes.instructorDashboard.instructorStatistics.base, { quarter: quarter, year: year }),
    },
    {
      content: languages.EN.labels.coursesStatistics,
      href: generatePath(Routes.instructorDashboard.coursesStatistics.base, { quarter: quarter, year: year }),
    },
  ];

  useDocumentTitle(languages.EN.labels.instructorDashboard);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={languages.EN.labels.instructorDashboard}
        path={[
          {
            name: languages.EN.labels.instructorDashboard,
            link: "#",
          },
        ]}
        buttons={[
          {
            icon: "calendar2",
            name: languages.EN.labels.viewStatisticsFor,
            onHoverType: TYPE_SUBMENU,
            options: dateRanges,
          },
        ]}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={Routes.instructorDashboard.base}>
            <Redirect to={links[0].href} />
          </Route>
          <Route path={links[0].href}>
            <InstructorDashboardStatistics data={data} dataRangeName={dateRangeName} currentSelected={currentSelected} />
          </Route>
          <Route path={links[1].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={`${languages.EN.labels.coursesStatistics} ${languages.EN.labels.for.toLowerCase()}: ${dateRangeName}`}
                Table={InstructorCoursesTable}
                reports={{ data: data.course_data }}
              />
            </div>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default InstructorDashboardTemplate
