import findItemValueByPath from "./FilterByPathName";

const prepareData = (data) => {
  if (data) {
    return data.map((item, idx) => ({
      ...item,
      counter: 0,
      filter_id: idx,
    }));
  }
};

const checkType = (obj) => typeof obj === "number" ? obj.toString() : obj;

const handleFilter = (data, criteria, currentCritCounter) => {
  if (criteria.length === 0) {
    return data;
  }

  let filteredArr = [];

  prepareData(data).forEach((item) => {
    criteria.forEach(criterion => {
      const value = findItemValueByPath(item, criterion.path);

      switch (criterion.typeOfFilter) {
        case "range":
          if (value >= criterion.min && value <= criterion.max) {
            item = { ...item, counter: item.counter + 1 };
            if (item.counter === currentCritCounter) {
              filteredArr.push(item);
            }
          }
          break;
        case "datePicker":
          const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
          const start = new Date(criterion.startDate.toLocaleDateString("en-GB", options));
          const end = new Date(criterion.endDate.toLocaleDateString("en-GB", options));
          const date = new Date((new Date(value)).toLocaleDateString("en-GB", options));

          if (date >= start && date <= end) {
            item = { ...item, counter: item.counter + 1 };
            if (item.counter === currentCritCounter) {
              filteredArr.push(item);
            }
          }
          break;
        default:
          const checkedValue = checkType(value);
          if (Array.isArray(checkedValue)) {
            checkedValue.forEach((checkbox) => {
              if (checkbox === criterion.name) {
                item = { ...item, counter: item.counter + 1 };
                if (item.counter === currentCritCounter) {
                  filteredArr.push(item);
                }
              }
            });
          } else if (checkedValue === criterion.name || (checkedValue === undefined && criterion.name === null)) {
            item = { ...item, counter: item.counter + 1 };
            if (item.counter === currentCritCounter) {
              filteredArr.push(item);
            }
          }
          break;
      }
    });
  });

  return filteredArr.filter((item, i, arr) => arr.findIndex((t) => t.filter_id === item.filter_id) === i);
};

export default handleFilter;
