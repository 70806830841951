import styles from "./FooterNavList.module.scss";
import Text from "../../atoms/Text/Text";
import { generatePath, useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";

const FooterNavList = ({ header, items }) => {
  const { userData, userContextLevel } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const preparedItems = typeof items === 'function' ? items(userContextLevel) : items;

  const handleOnClick = (item) => {
    if (item.action !== undefined) {
      item.action();
    } else if (item.popupPayload !== undefined) {
      callPopup({
        type: ACTIONS.INFORMATION,
        payload: item.popupPayload(languages.EN, userData),
      });
    } else {
      history.push(generatePath(item.route.base, item.params !== undefined ? item.params(userData ?? []) : undefined));
    }
  }

  return (
    <div className={styles.navigation}>
      <div className={styles.header}>
        <Text s10 w700 dark-6 montserrat>
          {header}
        </Text>
      </div>
      <ul className={styles.items}>
        {preparedItems
          .filter(item => item.action !== undefined || item.popupPayload !== undefined || item.route.checkScopes(userData?.scopes ?? []))
          .map((item) => (
            <li key={item.label} onClick={() => handleOnClick(item)}>
              <Text s10 dark-6 montserrat>
                {item.label}
              </Text>
            </li>
          ))
        }
      </ul>
    </div>
  );
};
export default FooterNavList;
