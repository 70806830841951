import styles from "./PopupWrapper.module.scss";
import React, { useContext, useEffect, useRef } from "react";
import { ACTIONS } from "../../../../contexts/PopupsContext";
import { PopupsContext } from "../../../../contexts/PopupsContext";
import { PopupScaleAnimation } from "../../../atoms/Animiations/Animiations";
import { PopupLoader } from "../../../atoms/Loader/Loader";

const PopupWrapper = ({ style, children, trigger, isDataActive }) => {
  const { callPopup } = useContext(PopupsContext);
  const popupRef = useRef();

  const closePopup = (e) => {
    if (popupRef.current === e.target) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  };

  useEffect(() => {
    if (trigger === true) {
      document.body.style.overflow = "hidden";
    } else if (trigger === false) {
      document.body.style.overflow = "unset";
    }
  }, [trigger]);

  return (
    <div ref={popupRef} onClick={closePopup} className={styles.Wrapper}>
      {!isDataActive && <PopupLoader size="20px" />}
      <PopupScaleAnimation trigger={trigger}>
        <div className={styles.Popup} style={style} data-visible={isDataActive}>
          {children}
        </div>
      </PopupScaleAnimation>
    </div>
  );
};

export default PopupWrapper;
