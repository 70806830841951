import { useState, useContext } from "react";
import styles from "./DropdownNav.module.scss";
import DropdownLink from "../../atoms/DropdownLink/DropdownLink";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../../contexts/UserContext";
import { languages } from "../../../dummyData/dummyData";
import Icons from "../../../assets/icons";
import { generatePath, Route, useHistory } from "react-router-dom";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import { Routes } from "../../../routes";

const DropdownNav = ({ profile, items }) => {
  const [isShown, setIsShown] = useState(false);
  const { userData, handleLogout } = useContext(UserContext);
  const history = useHistory();

  const handleMouseOver = () => {
    setIsShown(true);
  };

  const handleMouseOut = () => {
    setIsShown(false);
  };

  return (
    <div className={styles.dropDownNavWrapper}>
      {profile ? (
        <div
          className={styles.userButton}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseOut}
        >
          <RoundImage image={userData.profile_image_small} size={32} />
        </div>
      ) : (
        <div
          className={styles.menuButton}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseOut}
        >
          <Button variant='navBarButton' withIcon icon='hamburger'>
            <Text s14 lh17 w700>
              {languages.EN.labels.database}
            </Text>
          </Button>
        </div>
      )}

      <nav
        className={`${styles.dropDownMenu} ${isShown && styles.shown}`}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <ul>
          <div className={styles.dropDownMenu__left}>
            {profile && userData.name && (
              <div className={styles.name} data-profile={profile}>
                <RoundImage image={userData.profile_image_small} size={30} />
                <Text s12 lh14 w800>
                  {profile ? userData.name : languages.EN.labels.database}
                </Text>
              </div>
            )}
            {items[0].filter(link => link.route.checkScopes(userData.scopes) && (link.visible ? link.visible(userData) : true)).map((link) => (
              <li key={uuidv4()}>
                <DropdownLink onClick={() => history.push(generatePath(link.route.base, link.params ? link.params(userData) : undefined))} icon={link.icon}>
                  {link.label}
                </DropdownLink>
              </li>
            ))}
          </div>
          <div className={styles.dropDownMenu__right}>
            <div className={styles.menu_links}>
              {items[1].filter(link => link.route.checkScopes(userData.scopes) && (link.visible ? link.visible(userData) : true)).map((link) => (
                <li key={uuidv4()}>
                  <DropdownLink onClick={() => history.push(generatePath(link.route.base, link.params ? link.params(userData) : undefined))} icon={link.icon}>
                    {link.label}
                  </DropdownLink>
                </li>
              ))}
            </div>
            {profile && (
              <div className={styles.menu_links}>
                <li onClick={() => history.push(Routes.settings.base)} className={styles.link}>
                  {Icons.settings2}
                  <Text s12 lh14 w600>
                    {languages.EN.labels.settings}
                  </Text>
                </li>
                <li onClick={handleLogout} className={styles.link}>
                  {Icons.logout}
                  <Text s12 lh14 w600>
                    {languages.EN.labels.logout}
                  </Text>
                </li>
              </div>
            )}
          </div>
        </ul>
      </nav>
    </div>
  );
};
export default DropdownNav;
