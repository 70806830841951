import TopBanner from "../../molecules/TopBanner/TopBanner";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import CareerPathsList from "../../organisms/CareerPathsList/CareerPathsList";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { SCOPE_COMPETENCE_FRAMEWORK_EDIT } from "../../../constants/scopes";

const CareerPathsTemplate = ({ data }) => {
  const { userContextLevel, userData } = useContext(UserContext);

  const pageHeader = languages.EN.labels.careerPaths;
  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <CareerPathsList data={data} withManagement={[2, 3].includes(userContextLevel) && userData.scopes.includes(SCOPE_COMPETENCE_FRAMEWORK_EDIT)} />
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
}

export default CareerPathsTemplate;
