import styles from "./TopBannerButtons.module.scss";
import Icons from "../../../assets/icons";
import Text from "../../atoms/Text/Text";
import { ReactionBarSelector } from "@charkour/react-reactions";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export const TYPE_SUBMENU = "submenu";
export const TYPE_DATEPICKER = "datepicker";

const TopBannerButtons = ({ buttons }) => {
  const [hoveredButton, setHoveredButton] = useState(undefined);

  const renderHoveredAddon = (button) => {
    switch (button.onHoverType) {
      case TYPE_SUBMENU:
        return (
          <>
            <div className={styles.hoverSeparator} />
            <div className={styles.hoverSubmenuWrapper}>
              {button.options.map((option, i) => (
                <span key={i} onClick={option.onClick}>
              <Text w600 s9>
                {option.name}
              </Text>
            </span>
              ))}
            </div>
          </>
        );
      case TYPE_DATEPICKER:
        return (
          <>
            <div className={styles.hoverSeparator} />
            <div className={styles.hoverDatePickerWrapper}>
              <DatePicker
                onChange={(date) => button.onSelect(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000))}
                inline
                disabledKeyboardNavigation
                maxDate={button.maxDate}
              />
            </div>
          </>
        );
      default:
        return;
    }
  };

  return (
    <ReactionBarSelector
      reactions={buttons.map((button, i) => (
        {
          key: button.name,
          label: button.name,
          node: (
            <div className={styles.button} onMouseEnter={() => setHoveredButton(i)} onMouseLeave={() => setHoveredButton(undefined)}>
              <div className={`${styles.button__border} ${button.marked ? styles.button__border__marked : ''}`}>
                {Icons[button.icon]}
              </div>
              {hoveredButton === i && renderHoveredAddon(button)}
            </div>),
        }
      ))}
      style={{ backgroundColor: "transparent" }}
      onSelect={(label) => {
        const button = buttons.find(button => button.name === label);

        return (typeof button.onClick === "function") ? button.onClick() : () => {
        }
      }}
    />
  );
};

export default TopBannerButtons
