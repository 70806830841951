import styles from "./PrivacySettingsTable.module.scss";
import { PrivacySettingsTableHeader } from "./PrivacySettingsTableHeader";
import { PrivacySettingsTableRow } from "./PrivacySettingsTableRow";
const PrivacySettingsTable = ({ items, handleSetFormData }) => {
  return (
    <div>
      <PrivacySettingsTableHeader />
      <div className={styles.rowsWrapper}>
        {items &&
          Object.values(items).map((setting, i) => (
            <PrivacySettingsTableRow
              key={i}
              handleSetFormData={handleSetFormData}
              dataSetting={setting}
            />
          ))}
      </div>
    </div>
  );
};

export default PrivacySettingsTable;
