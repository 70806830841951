import PdfButtons from "../../molecules/PdfButtons/PdfButtons";
import styles from "./PdfPlayer.module.scss";
import { Document, Page } from "react-pdf";
import { Loader } from "../../atoms/Loader/Loader";
import Text from "../../atoms/Text/Text";
import { languages } from "../../../dummyData/dummyData";
import React, { useState } from "react";

const MIN_SCALE = 1.0;
const DEFAULT_SCALE = 1.8;
const MAX_SCALE = 5.0;

const PdfPlayer = ({ url, closeHandler }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfScale, setPdfScale] = useState(DEFAULT_SCALE);

  const nextPage = () => {
    pageNumber + 1 <= numPages && setPageNumber((prev) => prev + 1);
  };

  const previousPage = () => {
    pageNumber - 1 >= 1 && setPageNumber((prev) => prev - 1);
  };

  const resizePdf = (zoomIn) => {
    if (zoomIn) {
      pdfScale < MAX_SCALE && setPdfScale((pdfScale) => pdfScale + 0.2);
    } else {
      pdfScale > MIN_SCALE && setPdfScale((pdfScale) => pdfScale - 0.2);
    }
  };

  return (
    <>
      {!closeHandler && (
        <PdfButtons previousPageHandler={previousPage} nextPageHandler={nextPage} resizePdfHandler={resizePdf} />
      )}
      <div className={!!closeHandler ? styles.pdfDocumentAdditional : styles.pdfDocument}>
        <Document
          file={url}
          onLoadSuccess={({ numPages }) => {
            setPageNumber(1);
            setNumPages(numPages);
          }}
          onContextMenu={(e) => e.preventDefault()}
          loading={<Loader />}
          noData={<Loader />}
        >
          <Page scale={pdfScale} width={350} pageNumber={pageNumber} />
        </Document>
      </div>
      <PdfButtons previousPageHandler={previousPage} nextPageHandler={nextPage} resizePdfHandler={resizePdf} closeHandler={closeHandler} />
      {!closeHandler && (
        <Text s16 w800 lh32 secondary>
          {numPages && `${languages.EN.labels.page} ${pageNumber} ${languages.EN.labels.of} ${numPages}`}
        </Text>
      )}
    </>
  );
};

export default PdfPlayer;
