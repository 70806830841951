import React, { useContext, useMemo } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import styles from "../../Table.module.scss";
import Button from "../../../../atoms/Button/Button";
import { Routes } from "../../../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import { UserContext } from "../../../../../contexts/UserContext";

function RelatedCoursesTable({ reports }) {
  const { courses } = reports;
  const { userContextLevel } = useContext(UserContext);
  const history = useHistory();

  const items = courses.map((item) => ({
    ...item,
    canEdit: (userContextLevel === 2 && item.course_organization !== null) || (userContextLevel === 3 && item.course_organization === null),
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.courseId,
      accessor: "id",
    },
    {
      Header: languages.EN.labels.name,
      accessor: "display_name",
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      Cell: (data) => (
        <div className={styles.actionBox}>
          {data.row.original.canEdit && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="edit"
              onClick={(event) => {
                event.stopPropagation();
                history.push(generatePath(Routes.courseEdit.references.base, { id: data.row.original.id }));
              }}
              tooltip={languages.EN.labels.edit}
            />
          )}
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={(event) => {
              event.stopPropagation();
              history.push(generatePath(Routes.courseDetails.statistics.base, { id: data.row.original.id }));
            }}
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} />;
}

export default RelatedCoursesTable;
