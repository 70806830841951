import styles from "./BadgePieChart.module.scss";
import { ResponsivePie } from "@nivo/pie";
import Text from "../Text/Text";
import Icons from "../../../assets/icons";

const BadgePieChart = ({ level, name, progress, inTable }) => {
  const value = Math.abs(progress);

  const colors = {
    0: "#e6e6e6",
    1: "#08b60f",
    2: "#257824",
    3: "#4cccd5",
    4: "#393eb4",
    5: "#af3df4",
    6: "#ce4cc1",
    7: "#cc5d64",
    8: "#ca3131",
    9: "#f27f2a",
    10: "#deb522",
  };

  const icons = {
    registration: Icons.badges.novice,
    login: Icons.badges.scholar,
    user_rank: Icons.badges.pursuer,
    add_course_suggestion: Icons.badges.pioneer,
    add_review: Icons.badges.reviewer,
    spend_minutes: Icons.badges.netizen,
    courses_completed: Icons.badges.keenStudent,
    courses_completed_time: Icons.badges.fastTrack,
    course_qas_joined: Icons.badges.liveAction,
    competence_group: Icons.badges.holistic,
    protege: Icons.badges.protege,
    correct_answers: Icons.badges.infallible,
    register_le: Icons.badges.attendee,
    idp_premature: Icons.badges.speedRun,
    idps: Icons.badges.career,
    send_message: Icons.badges.messenger,
    forum_post_added: Icons.badges.wisdom,
    endorsements: Icons.badges.trendsetter,
    social_stars: Icons.badges.socialite,
  };

  const svgClassNames = {
    0: styles.lvl0,
    1: styles.lvl1,
    2: styles.lvl2,
    3: styles.lvl3,
    4: styles.lvl4,
    5: styles.lvl5,
    6: styles.lvl6,
    7: styles.lvl7,
    8: styles.lvl8,
    9: styles.lvl9,
    10: styles.lvl10,
  };

  const color = colors[level];

  const chartData = [
    {
      name: "fillIn",
      value: value,
      color: color,
      label: "name1",
    },
    {
      name: " fillRest",
      value: 1 - value,
      color: "#00000000",
      label: "name2",
    },
  ];

  return (
    <div className={styles.Chart} data-table={inTable}>
      <div
        className={styles.ChartBorderOutside}
        style={{ border: `3px solid ${color}` }}
        data-table={inTable}
      />
      <div
        className={styles.ChartBorderInside}
        style={{ border: `3px solid ${color}` }}
        data-table={inTable}
      >
        <div className={svgClassNames[level]}>{icons[name]}</div>
      </div>

      <div
        style={{ backgroundColor: `${color}` }}
        className={styles.level}
        data-table={inTable}
      >
        {inTable ? (
          <Text s16 w800>
            {level}
          </Text>
        ) : (
          <Text s18 w800>
            {level}
          </Text>
        )}
      </div>

      <ResponsivePie
        data={chartData.map((item) => {
          return {
            id: item.name,
            label: item.name,
            value: item.value,
            color: item.color,
          };
        })}
        innerRadius={inTable ? 0.75 : 0.72}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        startAngle={0}
        colors={chartData.map((event) => event.color)}
        borderWidth={0}
        arcLinkLabelsSkipAngle={0}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        animate={false}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={0}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={0}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 0]] }}
        isInteractive={false}
        motionConfig={{
          mass: 1,
          tension: 0,
          friction: 0,
          clamp: false,
          precision: 1,
          velocity: 0,
        }}
        fill={chartData.map((item) => {
          return {
            match: {
              id: item.name,
            },
            id: "clean",
          };
        })}
      />
    </div>
  );
};

export default BadgePieChart;
