import { useContext } from "react";
import Text from "../../atoms/Text/Text";
import svg from "../../../assets/icons";
import styles from "./SortPanel.module.scss";
import { FilterContext } from "../../../contexts/FilterContext";

const HeaderSortPanel = ({ item }) => {
  const { useSort } = useContext(FilterContext);
  const { sortOrder, setSortOrder } = useSort;

  return (
    <div className={styles.item}>
      <input
        id={item.id}
        name="input"
        type="checkbox"
        onChange={() => {
          setSortOrder({
            key: item.sortKey,
            ascending: item.sortKey === sortOrder.key ? !sortOrder.ascending : true,
        });
        }}
        checked={item.sortKey === sortOrder.key}
        data-asc={sortOrder.ascending}
      />
      <label className={styles.label} htmlFor={item.id}>
        <Text s12 lh14 w600>
          {item.content}
        </Text>
        <div className={styles.arrowWrapper} data-checked={item.sortKey === sortOrder.key}>
          {svg.topArrowSortPanel}
        </div>
      </label>
    </div>
  );
};

export default HeaderSortPanel;
