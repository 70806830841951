import React, { useContext, useState } from "react";
import RewardEndorsement from "../RewardEndorsement/RewardEndorsement";
import RewardEndorsementTile from "../RewardEndorsementTile/RewardEndorsementTile";
import { languages } from "../../../dummyData/dummyData";
import { useEffect } from "react";
import FilteredList from "../FilteredList/FilteredList";
import { dateFormat } from "../../../utils/formatters";
import rewardImage from "../../../assets/Reward.png";
import endorsementImage from "../../../assets/Endorsment.png";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { TYPE_TEXTAREA } from "../../molecules/Form/Form";
import { userRepository } from "../../../repository/user.repository";

export const TYPE_REWARD = 0;
export const TYPE_ENDORSEMENT = 1;

const filterDefinitions = [
  {
    keyPath: "preparedType",
    header: languages.EN.labels.type,
    typeOfFilter: "checkbox",
    sortId: 1,
  },
  {
    keyPath: "description",
  },
  {
    keyPath: ["sender.profile.name", "sender.profile.surname"],
    header: languages.EN.labels.from,
    sortId: 2,
  },
  {
    keyPath: ["receiver.profile.name", "receiver.profile.surname"],
    header: languages.EN.labels.for,
    sortId: 3,
  },
  {
    keyPath: "preparedCreatedDate",
    header: languages.EN.labels.received,
    typeOfFilter: "datePicker",
    sortId: 4,
  },
  {
    keyPath: "preparedClaimedDate",
    header: languages.EN.labels.collected,
    sortId: 5,
  },
];

const popupFields = [
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
  },
];

const RewardEndorsementWithManagement = ({ data }) => (
  <RewardEndorsement data={data} withManagement={true} />
);

const RewardEndorsementTileWithManagement = ({ data }) => (
  <RewardEndorsementTile data={data} withManagement={true} />
);

const RewardEndorsementsList = ({ id, data, withManagement, username }) => {
  const { callPopup } = useContext(PopupsContext);
  const [preparedData, setPreparedData] = useState(null);

  useEffect(() => setPreparedData([
    ...data.endorsements.map(item => ({
      ...item,
      type: TYPE_ENDORSEMENT,
      preparedType: languages.EN.labels.endorsement,
      profile_image_small: endorsementImage,
      preparedCreatedDate: dateFormat(item.created),
    })),
    ...data.rewards.map(item => ({
      ...item,
      profile_image_small: rewardImage,
      type: TYPE_REWARD,
      preparedType: languages.EN.labels.reward,
      preparedCreatedDate: dateFormat(item.created),
      preparedClaimedDate: item.claimed ? dateFormat(item.claimed) : null,
    })),
  ]), [data]);

  return (
    <FilteredList
      id={`rewardsEndorsements${id[0].toUpperCase() + id.slice(1)}`}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={withManagement ? RewardEndorsementWithManagement : RewardEndorsement}
      RenderTileComponent={withManagement ? RewardEndorsementTileWithManagement : RewardEndorsementTile}
      buttons={username ? (withManagement ? [
        {
          icon: "reward",
          label: `${languages.EN.labels.add} ${languages.EN.labels.reward}`,
          onClick: () => callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.reward}`,
              fields: popupFields,
              postAction: (formData) => userRepository.addReward( {...formData, receiver: username, claimed: 0 }),
            },
          }),
        },
      ] : []).concat([
        {
          icon: "endorsement",
          label: `${languages.EN.labels.add} ${languages.EN.labels.endorsement}`,
          onClick: () => callPopup({
            type: ACTIONS.FORM,
            payload: {
              header: `${languages.EN.labels.add} ${languages.EN.labels.endorsement}`,
              fields: popupFields,
              postAction: (formData) => userRepository.addEndorsement( {...formData, receiver: username}),
            },
          }),
        },
      ]): undefined}
    />
  );
};

export default RewardEndorsementsList;
