import React, { useMemo } from "react";
import Button from "../../../atoms/Button/Button";
import styles from "./StudentCompletedCourseTable.module.scss";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import StatisticBar from "../../../molecules/StatisticBar/StatisticBar";
import { languages } from "../../../../dummyData/dummyData";
import Table from "../Table";
import { dateFormat } from "../../../../utils/formatters";

function StudentCompletedCourseTable({ reports }) {
  const history = useHistory();

  const items = reports.map((item) => ({
    visibility: languages.EN.enums.visibility[item.internal === true ? 2 : 3],
    category: item.video === false ? languages.EN.labels.pdf : languages.EN.labels.video,
    durationDiff: +(item.duration_diff * 100).toFixed(0),
    durationDiffUsers: +(item.duration_diff_users * 100).toFixed(0),
    ...item,
  }));

  const maxDuration = Math.max(...items.map((item) => Math.abs(item.durationDiff)));
  const maxActualDuration = Math.max(...items.map((item) => Math.abs(item.durationDiffUsers)));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.name,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.visibility,
      accessor: "visibility",
    },
    {
      Header: languages.EN.labels.completedDate,
      accessor: (data) => dateFormat(data.actual_end_date),
    },
    {
      Header: languages.EN.labels.duration,
      accessor: (data) => `${Math.round(data.duration / 60)} ${languages.EN.labels.min}`,
    },
    {
      Header: languages.EN.labels.completedTime,
      accessor: (data) => `${Math.round(data.actual_duration / 60)} ${languages.EN.labels.min}`,
    },
    {
      Header: languages.EN.labels.completedTimeComparedDur,
      accessor: "durationDiff",
      Cell: (data) => <StatisticBar max={maxDuration} reverse={true} value={data.cell.value} />,
    },
    {
      Header: languages.EN.labels.completedTimeAverageTime,
      accessor: "durationDiffUsers",
      Cell: (data) => <StatisticBar max={maxActualDuration} reverse={true} value={data.cell.value} />,
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: data.cell.row.original.course_id }))}
            onlyIcon
            icon="dots"
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} />;
}

export default StudentCompletedCourseTable;
