import styles from "./SwiperNavigation.module.scss";
import Icons from "../../../assets/icons";
import React from "react";

const SwiperNavigation = ({ id, visible }) => {
  return (
    <>
      <div className={`${styles.navigation} ${styles.navigation__prev}`}>
        <button id={`${id}Prev`} data-hidden={!visible}>
          {Icons.arrowLeft}
        </button>
      </div>
      <div className={`${styles.navigation} ${styles.navigation__next}`}>
        <button id={`${id}Next`} data-hidden={!visible}>
          {Icons.arrowRight}
        </button>
      </div>
    </>
  );
};

export default SwiperNavigation
