import styles from "./Checkbox.module.scss";

const Checkbox = ({ checked, disabled, error, handleChange, value }) => {
  return (
    <span className={styles.wrapper}>
      <input
        className={styles.checkbox}
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <span className={`
        ${styles.checkbox} 
        ${checked ? styles.checkbox__checked : ""} 
        ${disabled ? styles.checkbox__disabled : ""}
        ${error ? styles.checkbox__error : ""}
      `} />
    </span>
  );
};

export default Checkbox;
