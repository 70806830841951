import styles from "./PlatformDashboardTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import { languages } from "../../../dummyData/dummyData";
import { Switch, Route, Redirect } from "react-router";
import { Routes } from "../../../routes";
import SwitchMenu from "../../molecules/SwitchMenu/SwitchMenu";
import PlatformDashboardStatistics from "../../organisms/PlatformDashboardStatistics/PlatformDashboardStatistics";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import React from "react";
import TabContentTemplate from "../generic/TabContentTemplate/TabContentTemplate";
import UserStatisticsTable from "../../organisms/Table/PlatformDashboard/UserStatisticsTable/UserStatisticsTable";
import InstructorStatisticsTable from "../../organisms/Table/PlatformDashboard/InstructorStatisticsTable/InstructorStatisticsTable";
import UserCompetencesTable from "../../organisms/Table/PlatformDashboard/UserCompetencesTable/UserCompetencesTable";

const links = [
  {
    content: languages.EN.labels.statistics,
    href: Routes.platformDashboard.statistics.base
  },
  {
    content: languages.EN.labels.instructorStatistics,
    href: Routes.platformDashboard.instructor.base,
  },
  {
    content: languages.EN.labels.userStatististics,
    href: Routes.platformDashboard.user.base
  },
  {
    content: languages.EN.labels.userCreatedCompetences,
    href: Routes.platformDashboard.competences.base,
  },
];

const PlatformDashboardTemplate = ({ data }) => {
  const { instructor_data, users, internal_competences } = data;
  const pageHeader = languages.EN.labels.platformDashboard;

  useDocumentTitle(pageHeader);

  return (
    <>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <SwitchMenu links={links} />
      <TabContentTemplate>
        <Switch>
          <Route exact path={Routes.platformDashboard.base}>
            <Redirect to={Routes.platformDashboard.statistics.base} />
          </Route>
          <Route path={links[0].href}>
            <PlatformDashboardStatistics data={data} />
          </Route>
          <Route path={links[1].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.instructorStatistics}
                Table={InstructorStatisticsTable}
                reports={{ data: instructor_data }}
              />
            </div>
          </Route>
          <Route path={links[2].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.userStatististics}
                Table={UserStatisticsTable}
                reports={{ data: users }}
              />
            </div>
          </Route>
          <Route path={links[3].href}>
            <div className={styles.tableContainer}>
              <TableWrapper
                title={languages.EN.labels.userCreatedCompetences}
                Table={UserCompetencesTable}
                reports={{ data: internal_competences }}
              />
            </div>
          </Route>
        </Switch>
      </TabContentTemplate>
      <Footer data={languages.EN.footer} />
    </>
  );
};

export default PlatformDashboardTemplate;
