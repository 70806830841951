import React, { useContext, useMemo } from "react";
import { languages } from "../../../../../dummyData/dummyData";
import Table from "../../Table";
import styles from "../../Table.module.scss";
import { referencesRepository } from "../../../../../repository/references.repository";
import Button from "../../../../atoms/Button/Button";
import TableWrapper from "../../../../molecules/TableWrapper/TableWrapper";
import ReferenceAuditsTable from "../ReferenceAuditsTable/ReferenceAuditsTable";
import useFetch from "../../../../../hooks/useFetch";
import Status, { VARIANT_GREEN, VARIANT_ORANGE, VARIANT_RED, VARIANT_YELLOW } from "../../../../atoms/Status/Status";
import { TYPE_DATE, TYPE_DROPDOWN } from "../../../../molecules/Form/Form";
import { dateFormat } from "../../../../../utils/formatters";
import { ACTIONS, PopupsContext } from "../../../../../contexts/PopupsContext";
import moment from "moment";
import { getReferenceName, TYPE_BOOK, TYPE_GUIDELINE, TYPE_JOURNAL, TYPE_LEGISLATION, TYPE_OTHER, TYPE_PRESENTATION, TYPE_VIDEO, TYPE_WEBSITE } from "../../../../../utils/referenceNameHelper";
import Html from "../../../../atoms/Html/Html";
import { TableSubrowLoader } from "../../../../atoms/Loader/Loader";
import RelatedCoursesTable from "../RelatedCoursesTable/RelatedCoursesTable";
import { UserContext } from "../../../../../contexts/UserContext";

const CHECK_EXPIRES_1_WEEK = 1;
const CHECK_EXPIRES_3_MONTHS = 2;
const CHECK_OUTDATED = 3;
const CHECK_LINK_BROKEN = 4;
const CHECK_NEXT_AUDIT_DATE_EXCEEDED = 5;
const CHECK_IN_AUDIT = 6;

export const getDocumentForm = (formData) => formData.type === "0" && formData.document_form_select === languages.EN.enums.referenceDocumentForms.Other ?
  formData.document_form_text :
  (formData.type === "0" ? formData.document_form_select : undefined);

const getChecks = (item) => {
  let checks = [];
  const days_left = item.expiry_date ? (new Date(item.expiry_date) - Date.now()) / 1000 / 3600 / 24 : undefined;

  if (days_left !== undefined && days_left <= 90) {
    if (days_left > 7) {
      checks.push(CHECK_EXPIRES_3_MONTHS);
    } else if (days_left > 0) {
      checks.push(CHECK_EXPIRES_1_WEEK);
    } else {
      checks.push(CHECK_OUTDATED);
    }
  }
  if (item.link_broken) {
    checks.push(CHECK_LINK_BROKEN);
  }
  if (item.next_audit_date !== null && (new Date(item.next_audit_date)) < Date.now()) {
    checks.push(CHECK_NEXT_AUDIT_DATE_EXCEEDED);
  }
  if (item.status === 3) {
    checks.push(CHECK_IN_AUDIT);
  }

  return checks;
}

const isViolated = (checks, actual) => checks.filter(check => actual.includes(check)).length > 0;

const getChecksScore = (checks) => {
  let score = 0

  if (isViolated([CHECK_OUTDATED, CHECK_IN_AUDIT], checks)) {
    score = 3;
  } else if (isViolated([CHECK_EXPIRES_1_WEEK, CHECK_LINK_BROKEN], checks)) {
    score = 2;
  } else if (isViolated([CHECK_EXPIRES_3_MONTHS, CHECK_NEXT_AUDIT_DATE_EXCEEDED], checks)) {
    score = 1;
  }

  return score + checks.length / 10;
}

const SCORE_VARIANTS = {
  3: VARIANT_RED,
  2: VARIANT_ORANGE,
  1: VARIANT_YELLOW,
  0: VARIANT_GREEN,
};

const getStatusText = (checks) => {
  return `${checks.length > 0 ? languages.EN.labels.actionRequired : languages.EN.labels.valid} ${checks.map(check => languages.EN.labels.referenceChecks[check]).join(',')}`;
}

export const referencePopupFields = (data = {}, showNextAuditDate = true) => {
  const type = parseInt(data.type);

  return [
    {
      name: "type",
      label: languages.EN.labels.type,
      required: true,
      type: TYPE_DROPDOWN,
      value: data.type,
      items: languages.EN.enums.referenceTypes,
    },
    {
      name: "publisher",
      label: languages.EN.labels.publisher,
      required: [TYPE_GUIDELINE, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION].includes(type),
      visible: [TYPE_LEGISLATION, TYPE_GUIDELINE, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_BOOK, TYPE_OTHER].includes(type),
      value: data.publisher,
    },
    {
      name: "author",
      label: languages.EN.labels.authors,
      required: [TYPE_JOURNAL, TYPE_BOOK].includes(type),
      visible: [TYPE_JOURNAL, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_BOOK, TYPE_OTHER].includes(type),
      value: data.author,
    },
    {
      name: "name",
      label: languages.EN.labels.title,
      required: true,
      value: data.name,
    },
    {
      name: "version",
      label: `${languages.EN.labels.version} / ${languages.EN.labels.edition}`,
      visible: [TYPE_GUIDELINE, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_BOOK, TYPE_OTHER].includes(type),
      value: data.version,
    },
    {
      name: "number",
      label: languages.EN.labels.documentNumber,
      visible: [TYPE_LEGISLATION, TYPE_GUIDELINE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_OTHER].includes(type),
      value: data.number,
    },
    {
      name: "publication_date",
      label: languages.EN.labels.dateOfPublication,
      required: [TYPE_JOURNAL].includes(type),
      type: TYPE_DATE,
      value: data.publication_date ?? undefined,
    },
    {
      name: "indirect_link",
      label: languages.EN.labels.indirectUrl,
      required: [TYPE_LEGISLATION, TYPE_GUIDELINE, TYPE_JOURNAL, TYPE_WEBSITE, TYPE_PRESENTATION, TYPE_OTHER].includes(type),
      visible: [TYPE_LEGISLATION, TYPE_GUIDELINE, TYPE_JOURNAL, TYPE_WEBSITE, TYPE_PRESENTATION, TYPE_BOOK, TYPE_OTHER].includes(type),
      value: data.indirect_link,
    },
    {
      name: "link",
      label: languages.EN.labels.directUrl,
      required: [TYPE_LEGISLATION, TYPE_GUIDELINE, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_OTHER].includes(type),
      value: data.link,
    },
    {
      name: "journal_name",
      label: languages.EN.labels.journalName,
      required: [TYPE_JOURNAL].includes(type),
      visible: [TYPE_JOURNAL].includes(type),
      value: data.journal_name,
    },
    {
      name: "volume",
      label: languages.EN.labels.volumePageRange,
      visible: [TYPE_JOURNAL].includes(type),
      value: data.volume,
    },
    {
      name: "doi",
      label: languages.EN.labels.doi,
      visible: [TYPE_JOURNAL].includes(type),
      value: data.doi,
    },
    {
      name: "publication_place",
      label: languages.EN.labels.placeOfPublication,
      visible: [TYPE_BOOK].includes(type),
      value: data.publication_place,
    },
    {
      name: "document_form_select",
      label: languages.EN.labels.documentForm,
      required: [TYPE_LEGISLATION].includes(type),
      visible: [TYPE_LEGISLATION].includes(type),
      type: TYPE_DROPDOWN,
      value: languages.EN.enums.referenceDocumentForms[data.document_form] !== undefined ?
        data.document_form :
        (data.document_form !== undefined ? languages.EN.enums.referenceDocumentForms.Other : undefined),
      items: languages.EN.enums.referenceDocumentForms,
    },
    {
      name: "document_form_text",
      label: languages.EN.labels.documentFormText,
      required: [TYPE_LEGISLATION].includes(type) && (
        data.document_form_select === languages.EN.enums.referenceDocumentForms.Other ||
        (data.document_form !== undefined && languages.EN.enums.referenceDocumentForms[data.document_form] === undefined)
      ),
      visible: [TYPE_LEGISLATION].includes(type) && (
        data.document_form_select === languages.EN.enums.referenceDocumentForms.Other ||
        (data.document_form !== undefined && languages.EN.enums.referenceDocumentForms[data.document_form] === undefined)
      ),
      value: languages.EN.enums.referenceDocumentForms[data.document_form] === undefined ? data.document_form : undefined,
    },
    {
      name: "manual_audit",
      label: languages.EN.labels.requiredPeriodicReconciliation,
      required: [TYPE_JOURNAL, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_BOOK, TYPE_OTHER].includes(type),
      visible: [TYPE_JOURNAL, TYPE_WEBSITE, TYPE_VIDEO, TYPE_PRESENTATION, TYPE_BOOK, TYPE_OTHER].includes(type),
      type: TYPE_DROPDOWN,
      value: data.manual_audit ? TYPE_GUIDELINE : TYPE_LEGISLATION,
      items: languages.EN.enums.yesNo,
    },
    {
      name: "next_audit_date",
      label: languages.EN.labels.nextAuditDate,
      visible: showNextAuditDate && ([TYPE_LEGISLATION, TYPE_GUIDELINE].includes(type) || parseInt(data.manual_audit) === TYPE_GUIDELINE),
      type: TYPE_DATE,
      value: data.next_audit_date,
    },
    {
      name: "aidify_version",
      label: languages.EN.labels.aidifyVersion,
      required: true,
      value: data.aidify_version,
    },
    {
      name: "effective_date",
      label: languages.EN.labels.effectiveDate,
      type: TYPE_DATE,
      value: data.effective_date ?? undefined,
    },
    {
      name: "expiry_date",
      label: languages.EN.labels.expiryDate,
      type: TYPE_DATE,
      value: data.expiry_date ?? undefined,
    },
    {
      name: "region",
      label: languages.EN.labels.region,
      type: TYPE_DROPDOWN,
      value: data.region,
      items: { "": languages.EN.placeholders.empty, ...languages.EN.enums.regions },
    },
  ]
};

const ReferencesTable = ({ reports }) => {
  const { data, isReady } = reports;
  const { callPopup } = useContext(PopupsContext);
  const { userContextLevel } = useContext(UserContext);

  const fetchReferenceAudit = useFetch();

  const items = data
    .filter((item) =>
      item.active === true &&
      [2, 3].includes(item.status) &&
      (userContextLevel === 3 || (userContextLevel === 2 && item.organization !== null))) // Turned off visibility of global references for organizations (too much outdated references)
    .map((item) => {
      const canEdit = (userContextLevel === 2 && item.organization !== null) || (userContextLevel === 3 && item.organization === null);
      const checks = getChecks(item);

      return {
        ...item,
        canEdit: canEdit,
        referenceName: getReferenceName(item, true),
        visibility: languages.EN.enums.visibility[item.organization !== null ? 2 : 3],
        checks: checks,
        checksScore: getChecksScore(checks),
        statusText: getStatusText(checks),
        subRow: fetchReferenceAudit.loading ? (
          <TableSubrowLoader />
        ) : (
          <>
            <TableWrapper
              title={languages.EN.labels.referenceAudits}
              Table={ReferenceAuditsTable}
              reports={{ reference: { ...item, canEdit }, audits: fetchReferenceAudit.data?.document_audit ?? [] }}
              transparent
            />
            <TableWrapper
              title={languages.EN.labels.relatedCourses}
              Table={RelatedCoursesTable}
              reports={{ reference: { ...item, canEdit }, courses: fetchReferenceAudit.data?.courses ?? [] }}
              transparent
            />
          </>
        )
      }
    });

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.type,
      accessor: (data) => languages.EN.enums.referenceTypes[data.type],
    },
    {
      Header: languages.EN.labels.id,
      accessor: "aidify_id",
    },
    {
      Header: languages.EN.labels.name,
      accessor: "referenceName",
      Cell: (data) => <Html>{getReferenceName(data.row.original)}</Html>,
      cellStyle: { wordBreak: "break-word", maxWidth: "800px" },
    },
    {
      Header: languages.EN.labels.version,
      accessor: "aidify_version",
    },
    {
      Header: languages.EN.labels.effectiveDate,
      accessor: "effective_date",
      Cell: (data) => data.row.original.effective_date ? dateFormat(data.row.original.effective_date) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.expiryDate,
      accessor: "expiry_date",
      Cell: (data) => data.row.original.expiry_date ? dateFormat(data.row.original.expiry_date) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.auditDate,
      accessor: "audit_date",
      Cell: (data) => data.row.original.audit_date ? dateFormat(data.row.original.audit_date) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.nextAuditDate,
      accessor: "next_audit_date",
      Cell: (data) => data.row.original.next_audit_date ? dateFormat(data.row.original.next_audit_date) : languages.EN.placeholders.empty,
    },
    {
      Header: languages.EN.labels.visibility,
      accessor: "visibility",
      Cell: (data) => data.row.original.visibility,
    },
    {
      Header: languages.EN.labels.status,
      accessor: "statusText",
      sortType: (rowA, rowB) => rowA.original.checksScore - rowB.original.checksScore,
        Cell: (data) => (
        <Status
          variant={SCORE_VARIANTS[parseInt(data.row.original.checksScore)]}
          text={
            <>
              {(data.row.original.checks.length > 0 ? languages.EN.labels.actionRequired : languages.EN.labels.valid).toUpperCase()}
              {data.row.original.checks.length > 0 && (
                <ul>
                  {data.row.original.checks.map(check => (
                    <li key={check}>{languages.EN.labels.referenceChecks[check]}</li>
                  ))}
                </ul>
              )}
            </>
          }
        />
      ),
      cellStyle: { position: "relative", minWidth: "170px" },
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      Cell: (data) => (
        <div className={styles.actionBox}>
          {data.row.original.indirect_link && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="home"
              onClick={(event) => {
                event.stopPropagation();
                window.open(data.row.original.indirect_link, '_blank')
              }}
              tooltip={languages.EN.labels.indirectUrl}
            />
          )}
          {data.row.original.link && (
            <Button
              variant="iconButton"
              onlyIcon
              icon="url"
              onClick={(event) => {
                event.stopPropagation();
                window.open(data.row.original.link, '_blank')
              }}
              tooltip={languages.EN.labels.directUrl}
            />
          )}
        </div>
      ),
    },
  ], []);

  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.reference}`,
          fields: (data) => referencePopupFields({
            ...data,
            aidify_version: "1.0",
            next_audit_date: moment().add(6, 'M').toISOString(),
          }),
          postAction: (formData) => referencesRepository.addReference({
            ...formData,
            citation_date: moment().toISOString(),
            document_form: getDocumentForm(formData),
          }),
        },
      })
    },
  ]} columns={columns} data={items} isReady={isReady} expand={(id, changeExpandedRow) => {
    let reference = items.find(item => item.id === id || (item.previous_version === id && item.active === true));

    if (reference !== undefined) {
      fetchReferenceAudit.fetchData(referencesRepository.getReference(reference.id))
      changeExpandedRow(reference.id);
    }
  }} />;
}

export default ReferencesTable;
