import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import useFetch from "../../../hooks/useFetch";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import { useDocumentTitleLoading } from "../../../hooks/useDocumentTitle";
import CompetenceTestReportTemplate from "../../templates/CompetenceTestReportTemplate/CompetenceTestReportTemplate";

const CompetenceTestReport = ({ match }) => {
  const { data, fetchData } = useFetch();

  useDocumentTitleLoading();

  useEffect(() => {
    fetchData(competenceTestRepository.getTestSummary(match.params.id));
  }, []);

  return data ? (
    <CompetenceTestReportTemplate data={data} />
  ) : (
    <Loader />
  );
};

export default CompetenceTestReport;
