import React, { useContext, useState } from "react";
import styles from "./CourseZoomTile.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import TimeBar from "../../molecules/TimeBar/TimeBar";
import { ReactComponent as AidifyLogo } from "../../../assets/logoIcon.svg";
import { defaultUserPhoto } from "../../../constants/assets";
import { languages } from "../../../dummyData/dummyData";
import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import Icons from "../../../assets/icons";
import Button from "../../atoms/Button/Button";
import { generatePath } from "react-router-dom";
import { coursesRepository } from "../../../repository/courses.repository";
import { dateFormat } from "../../../utils/formatters";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import { UserContext } from "../../../contexts/UserContext";
import { SCOPE_LEARNING } from "../../../constants/scopes";
import HoverPreview from "../../molecules/HoverPreview/HoverPreview";
import { certificatesRepository } from "../../../repository/certificates.repository";
import { useCourseButtons } from "../../../hooks/useCourseButtons";

const CourseZoomTile = ({ data, isVisible = true, isHoverBlocked = false, isMine = false, username = undefined }) => {
  const {
    display_name,
    course_instructor,
    instructor_text,
    user_progress,
    profile_image_medium,
    level,
    topic,
    course_organization,
    course_rating,
    course_rating_num,
    enrollment_start,
    user_match,
    user_saved,
    num_students,
    id,
    duration,
  } = data;
  const history = useHistory();
  const [isHeartClicked, setIsHeartClicked] = useState(user_saved);
  const [isHover, setIsHover] = useState(false);
  const { userData } = useContext(UserContext);
  const { icon, label, onClick } = useCourseButtons(data);

  const instructors = course_instructor.length > 0 ? course_instructor.map(instructor => ({
    name: instructor.name,
    img: instructor.profile_image_small,
  })) : [{
    name: instructor_text,
    img: defaultUserPhoto,
  }];

  const courseAdded = enrollment_start && enrollment_start.slice(0, 10);

  return (
    <div className={styles.wrapper}>
      <div
        onMouseMove={() => {
          if (!isHoverBlocked) {
            setIsHover(true);
          }
        }}
        className={`${styles.course} ${isVisible ? styles.course__visible : ""} ${isHover ? styles.smallSlideHover : ""} `}
      >
        <div className={styles.course__imgContainer}>
          <div className={styles.coursePhoto}>
            <img src={profile_image_medium} alt="" />
          </div>
          <div className={styles.course__imgContainer_timeBar}>
            <TimeBar userProgress={user_progress} duration={duration} />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.infoTopic}>
            <Text s12 lh14 w600 secondary>
              {languages.EN.enums.learningAreas[topic]}
            </Text>
          </div>
          <TextTruncated lineClamp={1} secondary>
            <Text secondary w800 s20>
              {display_name}
            </Text>
          </TextTruncated>
          <div className={`${styles.infoRow}`}>
            <TileItemInfo header={languages.EN.labels.instructor} value={course_instructor.length > 0 ? course_instructor.map(instructor => instructor.name).join(", ") : instructor_text} />
            <TileItemImgInfo header={languages.EN.labels.visibility} value={languages.EN.enums.visibility[course_organization ? 2 : 3]} image={course_organization ? course_organization.profile_image_small : <AidifyLogo />} />
          </div>
        </div>
      </div>
      {isHover && (
        <div
          onMouseLeave={() => setIsHover(false)}
          className={`${isVisible ? styles.course__visible : ""} ${styles.course__hover}`}
        >
          <div className={styles.course__imgContainer}>
            <div className={styles.coursePhoto}>
              <HoverPreview url={data.video_preview} allowPlaying={isHover} />
              <img src={profile_image_medium} alt="" />
            </div>
            <div className={styles.course__imgContainer_timeBar}>
              <TimeBar userProgress={user_progress} duration={duration} />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoTopic}>
              <Text s12 lh14 w600 secondary>
                {languages.EN.enums.learningAreas[topic]}
              </Text>
              <div className={styles.buttonsContainer}>
                {userData.scopes.includes(SCOPE_LEARNING) && !data.passed_test && (
                  <Button
                    variant="iconButton"
                    onlyIcon
                    icon={icon}
                    onClick={onClick}
                    tooltip={label}
                  />
                )}
                {userData.scopes.includes(SCOPE_LEARNING) && !userData.is_freemium && isMine && username && data.passed_test && (
                  <Button
                    onClick={() => certificatesRepository.downloadCertificate(username, id)}
                    variant="iconButton"
                    onlyIcon
                    icon="download"
                    tooltip={`${languages.EN.labels.download} ${languages.EN.labels.certificate}`}
                  />
                )}
                <Button
                  onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: id }))}
                  variant="iconButton"
                  onlyIcon
                  icon="dots"
                  tooltip={languages.EN.labels.details}
                />
                {userData.scopes.includes(SCOPE_LEARNING) && (
                  <Button
                    onClick={() => {
                      setIsHeartClicked(!isHeartClicked);
                      coursesRepository.sendLike({ course_id: id });
                    }}
                    variant={isHeartClicked ? "iconButtonOn" : "iconButtonOff"}
                    onlyIcon
                    icon="heart"
                    tooltip={languages.EN.labels[isHeartClicked ? "unlike" : "like"]}
                  />
                )}
              </div>
            </div>
            <Text secondary w800 s20>
              {display_name}
            </Text>
          </div>

          <div className={styles.activeWrapper}>
            <div className={styles.infoRow}>
              {instructors.map((instructor, i) => (
                <TileItemImgInfo key={i} header={languages.EN.labels.instructor} value={instructor.name} image={instructor.img} />
              ))}
            </div>
            <div className={styles.infoRow}>
              <TileItemImgInfo header={languages.EN.labels.visibility} value={languages.EN.enums.visibility[course_organization ? 2 : 3]} image={course_organization ? course_organization.profile_image_small : <AidifyLogo />} />
              <TileItemInfo header={languages.EN.labels.level} value={languages.EN.enums.experienceLevels[level]} />
              <TileItemInfo header={languages.EN.labels.added} value={dateFormat(courseAdded)} />
            </div>
            <div className={styles.infoRow}>
              {user_match > 0.8 && (
                <Text w800 secondary s20>
                  {`${parseInt(user_match)}% ${languages.EN.labels.match}`}
                </Text>
              )}
              <div className={styles.infoWithIcon}>
                <Text s12 dark-6>
                  {`${course_rating} (${course_rating_num} ${languages.EN.labels.reviewers})`}
                </Text>
                {Icons.emotyStar}
              </div>
              <div className={styles.infoWithIcon}>
                <Text s12 dark-6>
                  {`${num_students} ${languages.EN.labels.learners}`}
                </Text>
                {Icons.hat}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseZoomTile;
