import styles from "./JobPositionsChartElement.module.scss";
import Text from "../../atoms/Text/Text";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import aidifyLogoChart from "../../../assets/icons/aidifyLogoChart.svg";
import arrDownChart from "../../../assets/icons/arrDownChart.svg";
import btnXChart from "../../../assets/icons/btnXChart.svg";
import Button from "../../atoms/Button/Button";
import React, { useContext, useEffect, useState } from "react";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import useFetch from "../../../hooks/useFetch";
import { getPopupPayload } from "../../organisms/JobPositionsChart/JobPositionsChart";

const JobPositionsChartElement = ({
  item,
  children,
  usedJobPositions,
  setClickedId,
  clickedId,
  first,
  handleChartChange,
  isEdited,
  setUsedJobPositions,
  setChart,
  handleAddUsedPositionId,
  isIdp,
}) => {
  const [hoverButtons, setHoverButtons] = useState(false);
  const hideLine = children !== 1;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const [currentChildren, setCurrentChildren] = useState(
    item.children ? item.children : []
  );
  let history = useHistory();

  useEffect(() => {
    handleAddUsedPositionId(item.id);
  }, [handleAddUsedPositionId, item]);

  const handleNewChartElement = (newItem) => {
    setCurrentChildren([
      ...currentChildren,
      {
        id: newItem.id,
        name: newItem.name,
        children: [],
      },
    ]);
    setTimeout(() => {
      handleChartChange();
    }, [0]);
  };

  const fetchJobPositions = useFetch();
  useEffect(() => {
    if (fetchJobPositions.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: getPopupPayload(fetchJobPositions.data, usedJobPositions, callPopup, handleNewChartElement),
      });
    }
  }, [fetchJobPositions.isReady]);

  const handlePopup = () => {
    setIsDataActive(false);
    fetchJobPositions.fetchData(jobPositionsRepository.getJobPositions());
    callPopup({ type: ACTIONS.LOADING });
  };

  useEffect(() => {
    if (!isIdp) {
      // TODO: Dafuq is it?!
      // return (item.children = currentChildren);
      item.children = currentChildren;
    }
  }, [currentChildren, item, isIdp]);

  useEffect(() => {
    let arr = [...currentChildren];
    arr = arr.filter((element) => element.id !== clickedId);
    setCurrentChildren(arr);
    setTimeout(() => {
      handleChartChange();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedId]);

  const handleDelete = () => {
    if (first) {
      setChart([]);
      setUsedJobPositions([]);
    } else {
      setClickedId(item.id);
    }
  };

  return (
    <div
      onMouseOver={() => setHoverButtons(true)}
      onMouseOut={() => setHoverButtons(false)}
      className={`${styles.Wrapper} ${
        hideLine ? styles.Wrapper__noLine : null
      }`}
      style={
        isIdp && {
          transform: "translate(0px)",
        }
      }
    >
      {item.career_path && (
        <div className={styles.careerPath}>
          <TextTruncated lineClamp={1} style={{ backgroundColor: '#eaeaea'}}>
            <Text s10 lh12 w400 dark-4>
              {item.career_path.length > 0
                ? languages.EN.labels.careerPaths +
                  ": " +
                  item.career_path.map((item) => " " + item.name)
                : languages.EN.labels.careerPaths + ": -"}
            </Text>
          </TextTruncated>
        </div>
      )}
      <div
        data-active={hoverButtons}
        className={`${styles.element} ${
          hideLine ? styles.element__noLine : null
        }`}
      >
        {isIdp && !first && <div className={styles.bar}></div>}
        {isIdp && item.caption !== "" && item.caption !== undefined ? (
          <div className={styles.element__caption}>
            <TextTruncated lineClamp={1} style={{ backgroundColor: '#eaeaea'}}>
              <Text s18 w700>
                {item.name}
              </Text>
            </TextTruncated>

            <Text s10 lh12 w400 dark-4>
              {item.caption}
            </Text>
          </div>
        ) : (
          <TextTruncated lineClamp={1} style={{ backgroundColor: '#eaeaea'}}>
            <Text dark s18>
              {item.name}
            </Text>
          </TextTruncated>
        )}
        <img src={aidifyLogoChart} alt='' />
      </div>
      {!isIdp && (
        <div
          className={`${styles.hoverButtons} ${!isEdited && styles.hoverButtons__notEditable}`}
          style={{ opacity: hoverButtons ? "1" : "0" }}
        >
          <div className={`${styles.buttons} ${!isEdited ? styles.buttons__notEditable : ""}`}>
            <button disabled={!isEdited} onClick={handleDelete}>
              <img src={btnXChart} alt='' />
            </button>
            <button disabled={!isEdited} onClick={handlePopup}>
              <img src={arrDownChart} alt='' />
            </button>
          </div>
          <Button
            variant="iconButton"
            onlyIcon icon="dots"
            onClick={() => history.push(generatePath(Routes.jobPositionDetails.keyCompetences.base, { id: item.id }))}
            tooltip={languages.EN.labels.details}
          />
        </div>
      )}
    </div>
  );
};

export default JobPositionsChartElement;
