import { useContext, useEffect } from "react";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { UserContext } from "../../../contexts/UserContext";
import useFetch from "../../../hooks/useFetch";
import { competencesRepository } from "../../../repository/competences.repository";
import { userRepository } from "../../../repository/user.repository";
import { Loader } from "../../atoms/Loader/Loader";
import { PostContext } from "../../../contexts/PostContext";
import SetableCompetencesList from "../SetableCompetencesList/SetableCompetencesList";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";

const Step2 = ({ onNextStep }) => {
  const { userData, getUserContext } = useContext(UserContext);
  const { postData } = useContext(PostContext);
  const { callPopup, setIsDataActive } = useContext(PopupsContext);

  useDocumentTitle(languages.EN.labels.registration);

  const fetchUserCompetences = useFetch();
  useEffect(() => {
    fetchUserCompetences.fetchData(userRepository.getCompetences(userData.username));
  }, []);

  const fetchAllCompetences = useFetch();
  useEffect(() => {
    fetchAllCompetences.fetchData(competencesRepository.getCompetences());
  }, []);

  const fetchInitialTests = useFetch();
  useEffect(() => {
    if (fetchInitialTests.isReady) {
      setIsDataActive(true);
      if (fetchInitialTests.data.user_competences.length > 0) {
        callPopup({
          type: ACTIONS.INITIAL_TEST,
          payload: {
            competences: fetchInitialTests.data.user_competences,
            onNextStep: onNextStep,
          },
        })
      } else {
        callPopup({
          type: ACTIONS.CONFIRM,
          payload: {
            header: languages.EN.labels.information,
            content: languages.EN.messages.noCompetences,
            action: () => postData(
              () => competenceTestRepository.runManualTest({}),
              () => {
                callPopup({ type: ACTIONS.REMOVE });
                getUserContext();
              }
            ),
          },
        });
      }
    }
  }, [fetchInitialTests.isReady]);

  return fetchAllCompetences.data && fetchUserCompetences.data ? (
    <SetableCompetencesList
      userCompetences={fetchUserCompetences.data.user_competences}
      allCompetences={fetchAllCompetences.data}
      onSave={(userCompetences) => postData(
        () => userRepository.saveCompetences(userData.username, { competences: userCompetences }),
        () => {
          setIsDataActive(false);
          fetchInitialTests.fetchData(competenceTestRepository.getInitialTests());
          callPopup({ type: ACTIONS.LOADING });
        }
      )}
      saveLabel={languages.EN.labels.next}
    />
  ) : (
    <Loader />
  );
};

export default Step2;
