import React from "react";
import { languages } from "../../../dummyData/dummyData";
import Statistics from "../Statistics/Statistics";
import timeOnPlatform from "../../../assets/icons/statistics/timeOnPlatform.svg";
import studentHat from "../../../assets/icons/statistics/studentHat.svg";
import chart from "../../../assets/icons/statistics/chart.svg";

const CourseDetailsStatistics = ({ data }) => {
  const { usage, usage_month, num_students, course_certificates_num, viewership } = data;

  return (
    <Statistics
      data={[
        {
          icon: timeOnPlatform,
          content: languages.EN.labels.totalMinutesPlayed,
          value: Math.round(usage / 60),
          description: Math.round(usage_month / 60) + " " + languages.EN.labels.thisMonth,
        },
        {
          icon: studentHat,
          content: languages.EN.labels.students,
          value: num_students,
          description: course_certificates_num + " " + languages.EN.labels.issuedCertificates,
        },
        {
          icon: chart,
          content: languages.EN.labels.viewership,
          value: Math.round(viewership * 100) + "%",
        },
      ]}
    />
  );
};

export default CourseDetailsStatistics;
