import React from "react";
import styles from "./RatingStar.module.scss";
import starIcon from "../../../assets/icons/starIcon.svg";
import starIconFilled from "../../../assets/icons/starIconFilled.svg";

const RatingStar = ({ index, selectedIndex }) => {
  const selected = index <= selectedIndex;

  return (
    <div className={styles.starContainer}>
      {selected ? (
        <img src={starIconFilled} alt="" />
      ) : (
        <img src={starIcon} alt="" />
      )}
    </div>
  );
};

export default RatingStar;
