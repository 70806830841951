import styles from "./ChatTemplate.module.scss";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import NavBar from "../../organisms/NavBar/NavBar";
import ChatList from "../../organisms/Chat/ChatList/ChatList";
import ChatRoom from "../../organisms/Chat/ChatRoom/ChatRoom";
import { languages } from "../../../dummyData/dummyData";
import Footer from "../../organisms/Footer/Footer";
import { useCallback, useEffect, useRef, useState } from "react";
import { chatRepository } from "../../../repository/chat.repository";
import { useHistory, useLocation } from "react-router";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useContext } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { Routes } from "../../../routes";

const { chat } = languages.EN.labels;

const path = [
  {
    name: chat,
    link: "#",
  },
];

const ChatTemplate = ({ chatRooms }) => {
  const location = useLocation();
  const [selectedChat, setSelectedChat] = useState({
    id: chatRooms.recent_pms[0]?.chatroom.id,
    name: chatRooms.recent_pms[0]?.chatroom.name,
  });
  const [currentPms, setCurrentPms] = useState(chatRooms.recent_pms);
  const [groupChats, setGroupChats] = useState(chatRooms.chatrooms);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isNextReqAvalible, setIsNextReqAvalble] = useState(true);
  const [fromLocation] = useState(!!location?.state?.users.length);
  const history = useHistory();
  const intervall = 5000;

  const handleSetSelectedChat = useCallback((data) => {
    setSelectedChat(data);
  }, []);

  useDocumentTitle(chat);

  const handleGetMessagesAfterSendMessage = useCallback(
    (roomId) => {
      setIsNextReqAvalble(false);
      chatRepository
        .getChatroom(roomId)
        .then((res) => {
          if (res.status === 401) {
            history.push(Routes.mainPage.base);
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setCurrentMessages(data.messages);
          setCurrentPms(data.recent_pms);
          setIsInputDisabled(false);
          setIsNextReqAvalble(true);
        });
    },
    [history]
  );

  useEffect(() => {
    if (!fromLocation) {
      setIsNextReqAvalble(false);
      chatRepository
        .getChatroom(chatRooms.recent_pms[0]?.chatroom.id)
        .then((res) => {
          if (res.status === 401) {
            history.push(Routes.mainPage.base);
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setCurrentMessages(data.messages);
          setCurrentPms(data.recent_pms);
          setIsInputDisabled(false);
          setIsNextReqAvalble(true);
        });
    }
  }, [chatRooms.recent_pms, history, fromLocation]);

  const intervalll = useRef();
  useEffect(() => {
    clearInterval(intervalll.current);
    intervalll.current = setInterval(() => {
      chatRepository
        .getChatroom(selectedChat.id ? selectedChat.id : 1)
        .then((res) => {
          if (res.status === 401) {
            history.push(Routes.mainPage.base);
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setCurrentPms(data.recent_pms);
          setGroupChats(data.chatrooms);
        });
    }, intervall);
  }, [history, selectedChat]);

  useEffect(() => {
    return history.listen(() => {
      clearInterval(intervalll.current);
    });
  }, [history, selectedChat]);

  const { postResponse, postData } = useContext(PostContext);

  useEffect(() => {
    if (postResponse?.room_id) {
      setCurrentMessages(null);
      handleGetMessagesAfterSendMessage(postResponse.room_id);
      setSelectedChat((prev) => ({ ...prev, id: postResponse.room_id }));
    }
  }, [handleGetMessagesAfterSendMessage, postResponse]);

  useEffect(() => {
    if (location?.state?.users && location?.state?.chatName) {
      if (Array.isArray(location.state.users)) {
        const users = location.state.users;
        const chatName = location.state.chatName;
        postData(() => chatRepository.createChatroom(users, chatName));
      } else {
        postData(() => chatRepository.createChatroom(location.state.users));
      }
      history.replace({ ...location, state: null });
    }
  }, [history, location, postData]);

  return (
    <>
      <TopBanner header={chat} path={path} />
      <NavBar />
      <main className={styles.main}>
        <div className={styles.Wrapper}>
          <ChatList
            currentPms={currentPms}
            groupChats={groupChats}
            setCurrentMessages={setCurrentMessages}
            selectedChat={selectedChat}
            setSelectedChat={handleSetSelectedChat}
            isNextReqAvalible={isNextReqAvalible}
            handleGetMessagesAfterSendMessage={
              handleGetMessagesAfterSendMessage
            }
          />

          <ChatRoom
            isInputDisable={isInputDisabled}
            currentMessages={currentMessages}
            selectedChat={selectedChat}
            intervall={intervall}
            setIsInputDisabled={setIsInputDisabled}
            handleGetMessagesAfterSendMessage={
              handleGetMessagesAfterSendMessage
            }
          />
        </div>
      </main>
      <Footer data={languages.EN.footer} />
    </>
  );
};

export default ChatTemplate;
