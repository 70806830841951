import { calendar_event_endpoint, learning_event_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getLearningEvents = () => {
  return fetch(learning_event_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getLearningEventDetails = (id) => {
  return fetch(`${learning_event_endpoint + id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addLearningEvent = (data) => {
  return fetch(learning_event_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editLearningEvent = (id, data) => {
  return fetch(`${learning_event_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteLearningEvent = (id) => {
  return fetch(`${learning_event_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};


const sendCalendarEvent = (id) => {
  return fetch(calendar_event_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody({ event_id: id }),
  });
};

export const learningEventsRepository = {
  getLearningEvents,
  getLearningEventDetails,
  addLearningEvent,
  editLearningEvent,
  deleteLearningEvent,
  sendCalendarEvent,
};
