import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import Button from "../Button/Button";
import { languages } from "../../../dummyData/dummyData";
import React, { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { notificationsRepository } from "../../../repository/notifications.repository";
import { PostContext } from "../../../contexts/PostContext";

const NotificationButton = ({ id, type, extra, children, className }) => {
  const { sendUserContext, userData } = useContext(UserContext);
  const { postData } = useContext(PostContext);
  const history = useHistory();

  const actions = {
    badge: (extra, userData) => {
      history.push(generatePath(Routes.profile.badges.base, { username: userData.username }));
    },
    chat_message: undefined,
    course_assignment: (extra) => {
      history.push(generatePath(Routes.courseDetails.statistics.base, { id: extra.id }));
    },
    course_deadline: undefined,
    course_feedback: undefined,
    course_recommendation: (extra) => {
      history.push(generatePath(Routes.courseDetails.statistics.base, { id: extra.id }));
    },
    course_update: undefined,
    endorsement: (extra, userData) => {
      history.push(generatePath(Routes.profile.rewards.base, { username: userData.username }));
    },
    idp_review: (extra) => {
      if (userData.current.context_type === 0 && userData.contexts.find(context => context.context_type === 1 && context.context_id === extra.target_team_id) !== undefined) {
        sendUserContext(1, extra.target_team_id, () => history.push(generatePath(Routes.idpDetails.career.base, { id: extra.id, username: extra.user })));
      } else {
        history.push(generatePath(Routes.idpDetails.career.base, { id: extra.id, username: extra.user }))
      }
    },
    internal_training: undefined,
    learning_plan: undefined,
    lottery: undefined,
    manager_change: (extra) => {
      history.push(generatePath(Routes.team.base, { id: extra.id }));
    },
    my_competition: undefined,
    new_competition: undefined,
    new_course: (extra) => {
      history.push(generatePath(Routes.courseDetails.statistics.base, { id: extra.id }));
    },
    new_learning_event: (extra) => {
      history.push(generatePath(Routes.learningEventDetails.base, { id: extra.id }));
    },
    new_qa: undefined,
    new_requested_course: undefined,
    new_ri_article: (extra) => {
      history.push(generatePath(Routes.regulatoryIntelligenceDetails.base, { id: extra.id }));
    },
    observed_reply: undefined,
    payment: undefined,
    recommendation_completion: undefined,
    regulatory_document: undefined,
    unfinished_course: undefined,
  }

  return (
    <>
      {actions[type] && children === undefined && (
        <Button
          variant="iconButton"
          onlyIcon
          icon="dots"
          onClick={() => actions[type](extra, userData)}
          tooltip={languages.EN.labels.details}
        />
      )}
      {children !== undefined && (
        <div className={className} onClick={() => {
          postData(() => notificationsRepository.markReadNotification({ ids: [id] }), undefined, undefined, 'markReadNotification');
          if (actions[type]) {
            actions[type](extra, userData);
          }
        }}>
          {children}
        </div>
      )}
    </>
  )
};

export default NotificationButton
