import styles from "./PairUp.module.scss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DragDropBoard from "../DragDropBoard";
import DragDropCard from "../DragDropCard";
import Icons from "../../../../assets/icons";
import Text from "../../../atoms/Text/Text";
import { CompetenceTestContext } from "../../../../contexts/CompetenceTestContext";
import { shuffle } from "../../../../utils/arrayHelpers";

const PairUp = ({ data }) => {
  const { answers, question } = data;
  const { savedAnswers, currentPosition, setCurrentAnswer } = useContext(CompetenceTestContext);

  const answersGroup1 = useMemo(() => answers.slice(0, answers.length / 2), [answers]);
  const answersGroup2 = useMemo(() => answers.slice(-answers.length / 2), [answers]);

  const shuffledAnswersOrder = useMemo(() => shuffle(Array.from(answersGroup2.keys())), [answersGroup2]);

  const getDefaultLocalData = () => {
    return Object.fromEntries(answersGroup2.map((answer, i) => [i, ]));
  }

  const parseSavedAnswer = () => {
    let startFromIndex = {};

    return Object.fromEntries(
      savedAnswers[currentPosition]
        .split("|")
        .map((answer, i) => {
          const foundIndex = answersGroup2.indexOf(answer, startFromIndex[answer] ?? 0);
          let cardId;

          if (foundIndex !== -1) {
            startFromIndex[answer] = foundIndex + 1;
            cardId = shuffledAnswersOrder.indexOf(foundIndex).toString();
          } else {
            cardId = undefined;
          }

          return [i, cardId]
        })
    );
  }

  const [localData, setLocalData] = useState(getDefaultLocalData());

  useEffect(() => {
    setLocalData(savedAnswers[currentPosition] ? parseSavedAnswer() : getDefaultLocalData());
  }, [savedAnswers, currentPosition]);

  const handleMoveAnswer = (cardId, position) => {
    const newData = Object.fromEntries(Object.entries(localData).map(([i, id]) => id === cardId ? [i, undefined] : i === position ? [i, cardId] : [i, id]));
    setLocalData(newData);

    setCurrentAnswer(Object.values(newData).map(cardId => answersGroup2[shuffledAnswersOrder[cardId]]).join("|"));
  };

  return (
    <div className={styles.RegistrationPairUp}>
      <Text s14 lh24 dark-4>
        {question}
      </Text>

      {answersGroup1.map((_, id) => (
        <div className={styles.pairRow} key={id}>
          <span className={styles.pairAnswer}>
            <Text s14 dark-4>
              {answersGroup1[id]}
            </Text>
          </span>
          {Icons.pairUpArrow}
          <DragDropBoard
            type="answer"
            id={`board-${id}`}
            onDrop={(cardId) => handleMoveAnswer(cardId, id.toString())}
          >
            {localData[id] && (
              <DragDropCard id={localData[id]}>
                <Text s14 dark-4>
                  {answersGroup2[shuffledAnswersOrder[localData[id]]]}
                </Text>
              </DragDropCard>
            )}
          </DragDropBoard>
        </div>
      ))}

      <DragDropBoard
        id="board-home"
        type="container"
        onDrop={(cardId) => handleMoveAnswer(cardId, null)}
        minWidth={answersGroup2?.length * 160 + 5}
      >
        {shuffledAnswersOrder.map((originalId, shuffledId) => {
          return !Object.values(localData).includes(shuffledId.toString()) ? (
            <DragDropCard id={shuffledId} key={shuffledId}>
              <Text s14 dark-4>
                {answersGroup2[originalId]}
              </Text>
            </DragDropCard>
          ) : null;
        })}
      </DragDropBoard>
    </div>
  );
};

export default PairUp;
